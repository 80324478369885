import { modifiedAspectRatio, maxPreviewImageWidth } from "./enums";

export function shallowEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let index = 0; index < keys1.length; index++) {
    const val1 = object1[keys1[index]];
    const val2 = object2[keys2[index]];
    if (val1 !== val2) {
      return false;
    }
  }
  return true;
}

export function deepEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let index = 0; index < keys1.length; index++) {
    const val1 = object1[keys1[index]];
    const val2 = object2[keys2[index]];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !deepEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }
  return true;
}

function isObject(object) {
  return object != null && typeof object === "object";
}

// sort
export function bubbleSort(array) {
  const len = array.length;
  if (len < 2) return array;
  for (let i = 0; i < len; i++) {
    for (let j = 0; j < i; j++) {
      if (array[j].sort > array[i].sort) {
        const temp = array[j];
        array[j] = array[i];
        array[i] = temp;
      }
    }
  }
  return array;
}

export function bubbleSortForMultiPersonPreviewLinks(array) {
  const len = array.length;
  if (len < 2) return array;
  for (let i = 0; i < len; i++) {
    for (let j = 0; j < i; j++) {
      const trackingIdj = Number(array[j].id);
      const trackingIdi = Number(array[i].id);
      if (trackingIdj > trackingIdi) {
        const temp = array[j];
        array[j] = array[i];
        array[i] = temp;
      }
    }
  }
  return array;
}

export const createPreviewModelImage = async (url, shadow) => {
  const canvasWidth =
    document.body.clientWidth > maxPreviewImageWidth
      ? maxPreviewImageWidth
      : document.body.clientWidth;
  const canvasHeight = modifiedAspectRatio * canvasWidth;
  const modelWidth = canvasWidth / 3;
  const modelHeight = modelWidth;

  const drawImageX = canvasWidth / 2 - modelWidth / 2;
  const drawImageY = canvasHeight / 4;

  const canvas = document.createElement("canvas");
  canvas.width = canvasWidth;
  canvas.height = canvasHeight;

  if (!canvas.getContext)
    Promise.reject("Browsers do not support getContext()!");

  const context = canvas.getContext("2d");

  const modelImage = new Image();
  modelImage.src = url;

  return new Promise((resolve, reject) => {
    modelImage.onload = async () => {
      context.clearRect(0, 0, canvasWidth, canvasHeight);
      context.save();

      if (shadow) {
        context.translate(modelWidth / 2, modelHeight / 2);

        context.transform(1, 0, -0.5, 0.5, 0, 0);

        context.drawImage(
          modelImage,
          drawImageX,
          drawImageY,
          modelWidth,
          modelHeight
        );

        const imageData = context.getImageData(0, 0, canvasWidth, canvasHeight);
        // r g b a
        const len = imageData.data.length / 4;
        for (let i = 0; i < len; i++) {
          const indexR = i * 4 + 0;
          const indexG = i * 4 + 1;
          const indexB = i * 4 + 2;
          const indexA = i * 4 + 3;
          if (imageData.data[indexA] !== 0) {
            imageData.data[indexR] =
              imageData.data[indexG] =
              imageData.data[indexB] =
                0;
            imageData.data[indexA] = 150;
          }
        }

        context.putImageData(imageData, modelHeight / 5, modelHeight / 5);

        context.restore();
      }
      context.drawImage(
        modelImage,
        drawImageX,
        drawImageY,
        modelWidth,
        modelHeight
      );

      context.canvas.toBlob(
        async (blob) => {
          if (!blob) {
            console.error("can not capture the thumbnail");
            reject("can not capture the thumbnail");
          }
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = async () => {
            const base64data = reader.result;
            resolve(base64data);
          };
        },
        "image/png",
        1
      );
    };
  });
};

export function adjustColor(color, range) {
  let newColor = "#";
  for (let i = 0; i < 3; i++) {
    const hxStr = color.substr(i * 2 + 1, 2);
    let val = parseInt(hxStr, 16);
    val += range;
    if (val < 0) val = 0;
    else if (val > 255) val = 255;
    newColor += val.toString(16).padStart(2, "0");
  }
  return newColor;
}

export function RgbaToHex(color) {
  const values = color
    .replace(/rgba?\(/, "")
    .replace(/\)/, "")
    .replace(/[\s+]/g, "")
    .split(",");
  const a = parseFloat(values[3] || 1),
    r = Math.floor(a * parseInt(values[0]) + (1 - a) * 255),
    g = Math.floor(a * parseInt(values[1]) + (1 - a) * 255),
    b = Math.floor(a * parseInt(values[2]) + (1 - a) * 255);
  return (
    "#" +
    ("0" + r.toString(16)).slice(-2) +
    ("0" + g.toString(16)).slice(-2) +
    ("0" + b.toString(16)).slice(-2)
  );
}
export function jsonSafeParse(str) {
  try {
    return JSON.parse(str)
  } catch (e) {
    console.error(e)
    return undefined
  }
}
