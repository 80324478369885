import React from 'react'
import { Link, useHistory } from "react-router-dom"
import ApiUserManagementTab from './ApiUserManagement'
import CustomPackTab from './CustomPack'
import PortalUserManagementTab from './PortalUserManagement'
import ListJobsTab from './ListJobs'
import BulkUpdateTab from './BulkUpdate'
import GeneralSupportTab from './GeneralSupport'
import PromotionTab from './Promotion'
import * as Enums from '../../common/enums'

export const uiStates = Object.freeze({
  "mount":                  0,
  "apiInProgress":          1,
  "ready":                  2,
  "refreshInProgress":      3 // a state that indicates the data is about to refresh, switching the state to this one and back to "ready" to re-render the data 
})

export let user2DataIndexMap = new Map() // map index in the data table to user id (with additional information) 

export default function AdminAPIApp(props) {

  let history = useHistory()
  const [currentMode, setCurrentMode] = React.useState(Enums.currentDatabase.development)
  const [currentTab, setCurrentTab] = React.useState(Enums.activeTab.apiUser)
  const [errCode, setErrCode] = React.useState("")
  // API User Variables
  const [fullApiUserTable, setFullApiUserTable] = React.useState(null)
  const [unfilteredApiUserTable, setUnfilteredApiUserTable] = React.useState([])
  const [currApiUserTablePage, setCurrApiUserTablePage] = React.useState(1)
  const [numApiUserTablePages, setNumApiUserTablePages] = React.useState(1)
  const [apiUserTableRows, setApiUserTableRows] = React.useState(10)
  const [apiUserTableLoaded, setApiUserTableLoaded] = React.useState(false)
  const [apiUserTableUiState, setApiUserTableUiState] = React.useState(uiStates.mount)
  // Custom Pack Variables
  const [packTableType, setPackTableType] = React.useState(Enums.packTableType.minutePack)
  const [packTable, setPackTable] = React.useState([])
  const [unfilteredPackTable, setUnfilteredPackTable] = React.useState([])
  const [currPackTablePage, setCurrPackTablePage] = React.useState(1)
  const [numPackTablePages, setNumPackTablePages] = React.useState(1)
  const [packTableRows, setPackTableRows] = React.useState(10)
  const [packTableLoaded, setPackTableLoaded] = React.useState(false)
  // Portal User Variables
  const [portalUserTable, setPortalUserTable] = React.useState([])
  const [unfilteredPortalUserTable, setUnfilteredPortalUserTable] = React.useState([])
  const [currPortalUserTablePage, setCurrPortalUserTablePage] = React.useState(1)
  const [numPortalUserTablePages, setNumPortalUserTablePages] = React.useState(1)
  const [portalUserTableRows, setPortalUserTableRows] = React.useState(10)
  const [portalUserTableLoaded, setPortalUserTableLoaded] = React.useState(false)
  const [portalUserTableUiState, setPortalUserTableUiState] = React.useState(uiStates.mount)

  // constant variables
  const tableBottomPadding = "200px"

  function resetUser2DataIndexMap() {
    user2DataIndexMap.clear()
  }

  function updateUser2DataIndexMap(key, value) {
    user2DataIndexMap.set(key, value)
  }

  function processErrorResponse(err) {
    if (err.response) {
      setErrCode(err.response.status.toString())
    } else if (err.request) {
      setErrCode(err.request.toString())
    } else {
      setErrCode("Error")
    }
  }

  function buildTabGroupHeader() {
    return(
      <div className="tabs is-centered is-boxed">
        <ul>
          <li className={currentTab === Enums.activeTab.apiUser? "is-active" : ""}>
            <a onClick={() => setCurrentTab(Enums.activeTab.apiUser)}>{Enums.activeTab.apiUser}</a>
          </li>
          <li className={currentTab === Enums.activeTab.customPack? "is-active" : ""}>
            <a onClick={() => setCurrentTab(Enums.activeTab.customPack)}>{Enums.activeTab.customPack}</a>
          </li>
          <li className={currentTab === Enums.activeTab.portalUser? "is-active" : ""}>
            <a onClick={() => setCurrentTab(Enums.activeTab.portalUser)}>{Enums.activeTab.portalUser}</a>
          </li>
          <li className={currentTab === Enums.activeTab.listJobs? "is-active" : ""}>
            <a onClick={() => setCurrentTab(Enums.activeTab.listJobs)}>{Enums.activeTab.listJobs}</a>
          </li>
          <li className={currentTab === Enums.activeTab.listModels? "is-active" : ""}>
            <a onClick={() => setCurrentTab(Enums.activeTab.listModels)}>{Enums.activeTab.listModels}</a>
          </li>
          <li className={currentTab === Enums.activeTab.deactivateUsers? "is-active" : ""}>
            <a onClick={() => setCurrentTab(Enums.activeTab.deactivateUsers)}>{Enums.activeTab.bulkOperations}</a>
          </li>
          <li className={currentTab === Enums.activeTab.generalSupport? "is-active" : ""}>
            <a onClick={() => setCurrentTab(Enums.activeTab.generalSupport)}>{Enums.activeTab.generalSupport}</a>
          </li>
          <li className={currentTab === Enums.activeTab.promotion? "is-active" : ""}>
            <a onClick={() => setCurrentTab(Enums.activeTab.promotion)}>{Enums.activeTab.promotion}</a>
          </li>
        </ul>
      </div>
    )
  }

  function displayTabContent() {
    switch(currentTab) {
      case Enums.activeTab.apiUser:
        return(
          <ApiUserManagementTab 
            env={currentMode}
            errCode={errCode}
            setErrCode={(i) => setErrCode(i)}
            setLOADING={props.setLOADING}
            table={fullApiUserTable}
            setTable={(i) => setFullApiUserTable(i)}
            unfilteredTable={unfilteredApiUserTable}
            setUnfilteredTable={(i) => setUnfilteredApiUserTable(i)}
            currPage={currApiUserTablePage}
            setCurrPage={(i) => setCurrApiUserTablePage(i)}
            numPages={numApiUserTablePages}
            setNumPages={(i) => setNumApiUserTablePages(i)}
            numRows={apiUserTableRows}
            setNumRows={(i) => setApiUserTableRows(i)}
            isDataLoaded={apiUserTableLoaded}
            setDataLoaded={(i) => setApiUserTableLoaded(i)}
            loadState={apiUserTableUiState}
            setLoadState={(i) => setApiUserTableUiState(i)}
            tableBottomPadding={tableBottomPadding}
          />
        )
      
      case Enums.activeTab.customPack:
        return(
          <CustomPackTab
            env={currentMode}
            errCode={errCode}
            processError={(i) => processErrorResponse(i)}
            tableType={packTableType}
            setTableType={(i) => setPackTableType(i)}
            table={packTable}
            setTable={(i) => setPackTable(i)}
            unfilteredTable={unfilteredPackTable}
            setUnfilteredTable={(i) => setUnfilteredPackTable(i)}
            currPage={currPackTablePage}
            setCurrPage={(i) => setCurrPackTablePage(i)}
            numPages={numPackTablePages}
            setNumPages={(i) => setNumPackTablePages(i)}
            numRows={packTableRows}
            setNumRows={(i) => setPackTableRows(i)}
            isDataLoaded={packTableLoaded}
            setDataLoaded={(i) => setPackTableLoaded(i)}
          />
        )
      
      case Enums.activeTab.portalUser:
        return(
          <PortalUserManagementTab
            env={currentMode}
            errCode={errCode}
            processError={(i) => processErrorResponse(i)}
            table={portalUserTable}
            setTable={(i) => setPortalUserTable(i)}
            unfilteredTable={unfilteredPortalUserTable}
            setUnfilteredTable={(i) => setUnfilteredPortalUserTable(i)}
            currPage={currPortalUserTablePage}
            setCurrPage={(i) => setCurrPortalUserTablePage(i)}
            numPages={numPortalUserTablePages}
            setNumPages={(i) => setNumPortalUserTablePages(i)}
            numRows={portalUserTableRows}
            setNumRows={(i) => setPortalUserTableRows(i)}
            isDataLoaded={portalUserTableLoaded}
            setDataLoaded={(i) => setPortalUserTableLoaded(i)}
            loadState={portalUserTableUiState}
            setLoadState={(i) => setPortalUserTableUiState(i)}
            tableBottomPadding={tableBottomPadding}
            user2DataIndexMap = {user2DataIndexMap}
            updateUser2DataIndexMap = {(k,v) => updateUser2DataIndexMap(k,v)}
            resetUser2DataIndexMap = {() => resetUser2DataIndexMap()}
          />
        )

      case Enums.activeTab.listJobs:
        return(
          <ListJobsTab
            env={currentMode}
            errCode={errCode}
            processError={(i) => processErrorResponse(i)}
          />
        )
      case Enums.activeTab.deactivateUsers:
        return(
          <BulkUpdateTab
            env={currentMode}
            errCode={errCode}
            setErrCode={(i) => setErrCode(i)}
            processError={(i) => processErrorResponse(i)}
          />
        )
      
      case Enums.activeTab.generalSupport:
        return(
          <GeneralSupportTab
            env={currentMode}
            errCode={errCode}
            setErrCode={(i) => setErrCode(i)}
            processError={(i) => processErrorResponse(i)}
          />
        )
      
      case Enums.activeTab.promotion:
        return(
          <PromotionTab
            env={currentMode}
            errCode={errCode}
            setErrCode={(i) => setErrCode(i)}
            processError={(i) => processErrorResponse(i)}
          />
        )
    }
  }

  React.useEffect(() => {

  }, [
    currentMode,
    currentTab,
    errCode,
    fullApiUserTable,
    unfilteredApiUserTable,
    currApiUserTablePage,
    numApiUserTablePages,
    apiUserTableRows,
    apiUserTableLoaded,
    apiUserTableUiState,
    packTableType,
    packTable,
    unfilteredPackTable,
    currPackTablePage,
    numPackTablePages,
    packTableRows,
    packTableLoaded,
    portalUserTable,
    unfilteredPortalUserTable,
    currPortalUserTablePage,
    numPortalUserTablePages,
    portalUserTableRows,
    portalUserTableLoaded,
    portalUserTableUiState
  ])

  return(
    <section className="container mt-6 ml-2 mr-2 mb-4">
      {buildTabGroupHeader()}
      {displayTabContent()}
    </section>
  )
}