import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import DMToolTip from "../ui/DMToolTip";
import { tooltipVideoShouldCrop } from "../common/enums";

// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Scrollbar } from "swiper";
import "./assets/styles/MultiPersonSelector.scss";
SwiperCore.use([Navigation, Scrollbar]);

// image
import imgCustom from "../../images/animate-3d/character-select/model-custom.jpg";
import FaceIcon_No from "../../images/multiPersonTracking/FaceIcon_No_Red.png";
import FaceIcon from "../../images/multiPersonTracking/FaceIcon_White.png";
import HandIcon_No from "../../images/multiPersonTracking/HandIcon_No_Red.png";
import HandIcon from "../../images/multiPersonTracking/HandIcon_White.png";
import GLBIcon_No from "../../images/multiPersonTracking/GLBIcon_No_Red.png";
import GLBIcon from "../../images/multiPersonTracking/GLBIcon_White.png";

const submitButton = "Submit";

export default function MultiPersonSelector({
  numModels,
  overTheMaximum,
  showMultiPersonSelector,
  setShowMultiPersonSelector,
  setMultiPersonIsAlreadyCreated,
  setMultiPersonCharacter,
  selectionNum,
  setSelectionNum,
  characters,
  setCharacters,
}) {
  // left
  const [disabledSwiperNavigation, setDisabledSwiperNavigation] = useState<
    "left" | "right" | "all" | ""
  >("");

  const selectionTrackingPerson = () => {
    const character = [...characters];
    const characterList = [];
    character.forEach((item) => {
      if (item.check) {
        const data = {
          trackingId: item.trackingId,
          modelId: item.model.id,
        };
        characterList.push(data);
      }
    });
    setMultiPersonCharacter(characterList);
    if (characterList.length) {
      setMultiPersonIsAlreadyCreated(true);
      setShowMultiPersonSelector(false);
    } else {
      setMultiPersonIsAlreadyCreated(false);
      setShowMultiPersonSelector(false);
    }
  };

  const trackCharacterSelection = (i) => {
    const character = [...characters];

    const checkChararcter = character.filter((row) => {
      return row.check;
    });

    let length = character[i].check
      ? checkChararcter.length - 1
      : checkChararcter.length + 1;
    if (length > overTheMaximum) return;

    character[i].check = !character[i].check;

    setSelectionNum(length);
    setCharacters(character);
  };

  const selectCharacter = (row, num: number, i: number) => {
    const character = [...characters];

    row.modelNum = num;
    row.model = numModels[num];
    row.modelUrl =
      numModels[num].thumbImg instanceof Blob
        ? URL.createObjectURL(numModels[num].thumbImg)
        : numModels[num].thumb
        ? numModels[num].thumb
        : imgCustom;

    character[i] = row;
    setCharacters(character);
  };

  const children = () => {
    return (
      <div className="detail">
        <div className="is-flex is-align-content-center is-justify-content-center">
          <div className="title is-3 mb-0 m-3">
            Tracking Persons: {selectionNum} / {overTheMaximum}
          </div>
          <DMToolTip
            text={tooltipVideoShouldCrop()}
            iconColor="#354d74"
            tipId="upgrade-subscription"
            icon={`fas fa-info-circle fa-md`}
            cursor="cursor-pt"
          />
        </div>
        <div className="subtitle is-5 has-text-centered">
          Total Persons Detected: {characters.length}
        </div>
        <div className="is-flex is-align-items-center detail-swiper">
          <i
            className={
              disabledSwiperNavigation === "left" ||
              disabledSwiperNavigation === ""
                ? "swiper-navigation detail-swiper-prev fas fa-6x fa-chevron-left mr-5 notEnabled"
                : "swiper-navigation detail-swiper-prev fas fa-6x fa-chevron-left mr-5"
            }
          />
          <div className="detail-swiper-centent p-5">
            {characters && (
              <Swiper
                className="none-select"
                observer={true}
                observeParents={true}
                slidesPerView={3}
                spaceBetween={characters.length < 3 ? 0 : 20}
                navigation={{
                  nextEl: ".detail-swiper-next",
                  prevEl: ".detail-swiper-prev",
                }}
                scrollbar={{
                  el: ".detail-swiper-scrollbar",
                  draggable: true,
                  dragSize: 120,
                }}
                onSwiper={(swiper) => {
                  if (swiper.activeIndex === 0) {
                    if (characters.length <= 3) {
                      setDisabledSwiperNavigation("");
                    } else {
                      setDisabledSwiperNavigation("left");
                    }
                  }
                }}
                onSlideChange={(swiper) => {
                  setDisabledSwiperNavigation("all");
                  if (swiper.activeIndex === 0) {
                    setDisabledSwiperNavigation("left");
                  }
                  if (swiper.activeIndex === characters.length - 3) {
                    setDisabledSwiperNavigation("right");
                  }
                }}
              >
                {characters &&
                  characters.map((row, i) => {
                    const handDataType = row.model.handDataType;
                    const faceDataType = row.model.faceDataType;
                    const GLBsupport = row.model.platform === "roblox" ? 0 : 1;
                    return (
                      <SwiperSlide key={i}>
                        <div
                          className={
                            "character p-1" + (row.check ? " check" : "")
                          }
                          onClick={() => trackCharacterSelection(i)}
                        >
                          <div className="character-persons">
                            <div className="head p-1 px-2 is-flex is-align-content-center is-justify-content-space-between">
                              <span className="m-0">{i + 1}</span>
                              <div className="m-0">
                                <input
                                  type="checkbox"
                                  checked={row.check}
                                  readOnly
                                />
                                <span className="ml-1">Track</span>
                              </div>
                            </div>
                            <img className="filter-img" src={row.url} alt="" />
                          </div>
                          <div className="character-model">
                            <div className="head p-1 px-2 is-flex is-align-content-center">
                              <img
                                className="icon m-0 mr-2"
                                src={
                                  faceDataType === 1 ? FaceIcon : FaceIcon_No
                                }
                                alt="GLB output is enabled"
                              />
                              <img
                                className="icon m-0 mr-2"
                                src={
                                  handDataType === 1 ? HandIcon : HandIcon_No
                                }
                                alt="GLB output is enabled"
                              />
                              <img
                                className="icon m-0"
                                src={GLBsupport === 1 ? GLBIcon : GLBIcon_No}
                                alt="GLB output is enabled"
                              />
                            </div>
                            <div className="model">
                              <img
                                className="filter-img"
                                src={row.modelUrl}
                                alt={row.model.name}
                              />
                            </div>
                            <div
                              className="selection is-flex"
                              onClick={(event) => {
                                event.stopPropagation();
                              }}
                            >
                              <i
                                className="fas fa-2x fa-chevron-left m-0 py-1 px-3"
                                onClick={() => {
                                  if (row.modelNum === 0) {
                                    const num = numModels.length - 1;
                                    selectCharacter(row, num, i);
                                  } else {
                                    const num = row.modelNum - 1;
                                    selectCharacter(row, num, i);
                                  }
                                }}
                              />
                              <div
                                onClick={() => {
                                  setShowMultiPersonSelectorDialog(true);
                                  setShowMultiPersonSelectorNum(i);
                                }}
                              >
                                Select Character
                              </div>
                              <i
                                className="fas fa-2x fa-chevron-right m-0 py-1 px-3"
                                onClick={() => {
                                  if (row.modelNum === numModels.length - 1) {
                                    const num = 0;
                                    selectCharacter(row, num, i);
                                  } else {
                                    const num = row.modelNum + 1;
                                    selectCharacter(row, num, i);
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            )}
          </div>
          <i
            className={
              disabledSwiperNavigation === "right" ||
              disabledSwiperNavigation === ""
                ? "swiper-navigation detail-swiper-next fas fa-6x fa-chevron-right ml-5 notEnabled"
                : "swiper-navigation detail-swiper-next fas fa-6x fa-chevron-right ml-5"
            }
          />
        </div>
        <div className="detail-swiper-scrollbar" />
      </div>
    );
  };

  const [showMultiPersonSelectorDialog, setShowMultiPersonSelectorDialog] =
    useState(false);
  const [showMultiPersonSelectorNum, setShowMultiPersonSelectorNum] =
    useState(0);

  const buildCharacterSelectionDialog = () => {
    const title = "Account Characters";
    const actionBtnLabel = "Close";

    const character = [...characters];
    const selectedCharacter = character[showMultiPersonSelectorNum];
    const selectedModel = selectedCharacter.model;
    const addDate = selectedModel.date;

    return (
      <div id="modal-ter" className="modal is-active CharacterSelectionDialog">
        <div className="modal-background"></div>
        <div className="modal-card" style={{ width: "66vw" }}>
          <header className="modal-card-head m-0">
            <p className="modal-card-title">{title}</p>
            <button
              className="delete"
              onClick={() => setShowMultiPersonSelectorDialog(false)}
              aria-label="close"
            ></button>
          </header>
          <section className="modal-card-body m-0 p-0">
            <div className="content">
              <div className="columns m-2 is-flex-wrap-wrap">
                {numModels &&
                  numModels.map((item, i) => {
                    const modelUrl =
                      item.thumbImg instanceof Blob
                        ? URL.createObjectURL(item.thumbImg)
                        : item.thumb
                        ? item.thumb
                        : imgCustom;

                    return (
                      <div
                        className="has-text-centered model-thumbnails-list mb-2 m-0 is-flex is-align-items-center is-justify-content-center"
                        key={i.toString() + item.name}
                      >
                        <figure
                          className="image br-4 selection-card AccountCharacters"
                          onClick={() =>
                            selectCharacter(
                              selectedCharacter,
                              i,
                              showMultiPersonSelectorNum
                            )
                          }
                          id="anim-fadein"
                        >
                          <div className="br-4">
                            <img
                              src={modelUrl}
                              className={
                                selectedModel.id === item.id
                                  ? " p-1 br-4 animated-border bShadow has-background-light"
                                  : " br-4 dm-brand-border-md bShadow has-background-light"
                              }
                              alt={item.model?.name}
                            />
                          </div>
                        </figure>
                      </div>
                    );
                  })}
              </div>
            </div>
          </section>
          <section className="m-0 p-0">
            <div className="columns m-0 has-background-link-light">
              <div className={"column p-4 has-text-centered"}>
                <div className="title is-5 dm-brand-font">
                  {selectedModel.name}
                </div>
                <div className="subtitle is-6 dm-brand-font">
                  {selectedModel.platform !== "custom" ? (
                    <div className="subtitle is-6 dm-brand-font">
                      (System Provided)
                    </div>
                  ) : (
                    <div className="subtitle is-6 dm-brand-font">
                      Added: {addDate}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
          <footer className="modal-card-foot m-0">
            <div className="columns m-0 fullwidth">
              <div className="column p-0">
                <div className="buttons is-right">
                  <div
                    className="button action-btn-dark"
                    tabIndex={0}
                    onClick={() => setShowMultiPersonSelectorDialog(false)}
                  >
                    {actionBtnLabel}
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  };
  return (
    <>
      <Modal
        className="MultiPersonSelector-modal"
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showMultiPersonSelector}
        onHide={selectionTrackingPerson}
        animation={false}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Multi-Person Selection</Modal.Title>
        </Modal.Header>
        <Modal.Body>{children()}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className={!selectionNum ? "" : "active"}
            onClick={() => selectionTrackingPerson()}
            disabled={!selectionNum}
          >
            {submitButton}
          </Button>
        </Modal.Footer>
      </Modal>

      {showMultiPersonSelectorDialog && buildCharacterSelectionDialog()}
    </>
  );
}
