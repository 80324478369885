import React, { useState, useEffect } from 'react';

export default function InputDialog(props) {
  const { title, message, okLabel, onOk, preFilledValue, validateInput } = props;
  const [inputText, setInputText] = useState('');

  useEffect(() => {
    setInputText(preFilledValue)
  }, []);

  function handleOk() {
    return onOk(inputText)
  }

  return (
    <div id="modal-ter" className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head is-marginless">
          <p className="modal-card-title">{title}</p>
        </header>
        <section className="modal-card-body is-marginless">
          <div className="content">
            <div className="subtitle is_3">{message}</div>
            <div className="columns has-text-centered">
              <div className="column is-2">
                <span className="icon has-text-link is-large"><i className="fas fa-exclamation-circle fa-3x"></i></span>
              </div>
              <div className="column">
                <div className="columns has-text-left">  
                  <div className="column">
                    <div className="field">
                      <p className="control">
                        <input className="input pr-0" value={inputText} onChange={(event) => setInputText(event.target.value)} type="text" autoFocus/>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="columns">
              { validateInput(inputText)
                ?
                <div className="button preview-btn" onClick={()=>handleOk()}>{okLabel}</div>
                :
                <div disabled className="button fullwidth">{okLabel}</div>
              }
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}