import {AccountInfo} from "../../types";

export function setUserData(userInfo: AccountInfo) {
    const gtag = window['gtag']
    gtag('set', 'user_data', convertToUserData(userInfo))
}

export function convertToUserData(userInfo: AccountInfo) {
    return {
        email: userInfo.email,
        phone_number: userInfo.phone,
        address: {
            first_name: userInfo.firstName,
            last_name: userInfo.lastName,
            street: userInfo.address?.street,
            city: userInfo.address?.city,
            region: userInfo.address?.region,
            postal_code: userInfo.address?.postalCode,
            country: userInfo.address?.country
        }
    }
}
