import React, { useRef, useContext, useEffect } from 'react'
import { Link, useHistory } from "react-router-dom"
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { AppStateContext } from '../../AppStateContext'
import { supportMail } from '../api/apiRequests';
import AnimVersionPanel from '../common/AnimVersionPanel'
import LoadingScreen from '../common/LoadingScreen'
import DMDialog from '../ui/DMDialog'
import * as Enums from './enums'

const useCredentials = (process.env.REACT_APP_NODE_ENV !== 'development')

export default function Support(props) {

  // browser history
  let history = useHistory()
  const appStateContext = useContext(AppStateContext)

  //************************************************************ 
  // State variables for support form entries: 
  //************************************************************
  const [supportTitle, setSupportTitle] = React.useState(
    Enums.buildStateObj("", false, Enums.inputSpanHide, Enums.normalInputClass)
  )
  const [supportDesc, setSupportDesc] = React.useState(
    Enums.buildStateObj("", false, Enums.inputSpanHide, Enums.normalTextAreaClass)
  )
  const normalFileSpan = "help is-danger hide"
  const [file, setFile] = React.useState(
    Enums.buildStateObj([], false, normalFileSpan, Enums.normalInputClass)
  )
  const [fileData, setFileData] = React.useState([])
  const [fileError, setFileError] = React.useState([])
  const [filesTooLarge, setFilesTooLarge] = React.useState(false)
  const [fileKey, setFileKey] = React.useState('')
  const [missingFieldsMessage, setMissingFieldsMessage] = React.useState("")
  const [submitStatus, setSubmitStatus] = React.useState(Enums.FORM_STATE.ready)

  const maxFileSize = 26214400
  const errorFileSpan = "help is-danger"
  const minSupportMsgLength = 20

  // SEO 
  const docTitle = "Support | DEEPMOTION"
  const metaDesc = "Submit any support requests you may have here. Thanks for your interest in DeepMotion!"

  // form strings
  const submitButton    = "Submit"
  const tryAgainButton  = "Try Again"
  const backButton      = "Home"
  const goToDash        = "Back To Dashboard"
  const formTitle       = "Product Support" 
  const formText        = "Please include any important information and details regarding your support request"
  const fileSize        = `All files should be ${Enums.convertBytesTo(maxFileSize, "MB", 0)} or less.`
  const field_1_Title   = 'Title'
  const field_2_Title   = 'Details'
  const field_3_Title   = "Choose a file"
  const inProgressTitle = "Submitting request..."
  const successTitle    = "Thank you for your request!"
  const successSub1     = "We've received your request and will review it soon..."
  const successSub2     = "- The DeepMotion Team"
  const errorTitle      = "Sorry, something went wrong..."
  const errorSubtitle   = "We encountered an error submitting your request, unable to connect to server."
  const errorSubtitle2  = "If the problem continues please contact DeepMotion Support via e-mail."
  const errorFileSize   = `File(s) size is too big. Please select file(s) totalling ${Enums.convertBytesTo(maxFileSize, "MB", 0)} in size or smaller.`

  ////////////////////////////////////////////////////////////////////
  // custom hook to re-initialize service upon handle browser refresh
  ////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if( !appStateContext.state.anim3dInitialized || !appStateContext.state.accountDataRetrieved ) {
      props.initializeA3DService()
    } 
    else {
      if( props.LOADING.show ) {
        props.setLOADING({...props.LOADING, ...{show: false}})
      }
    }
  }, [appStateContext.state.anim3dInitialized, appStateContext.state.accountDataRetrieved])

  async function submitSupportForm() {
    if (isFormReadyForSubmission()) {
      setSubmitStatus(Enums.FORM_STATE.inProgress)

      const fileTypes = Array.from(file.value, f => f.type)
      const fileNames = Array.from(file.value, f => Enums.removeIllegalOrReservedCharacters(f.name))

      let subInfo = ""
      if (appStateContext.state.subscriptionInfo.isEnterpriseUser) {
        subInfo = "Enterprise"
      } else {
        subInfo = appStateContext.state.subscriptionInfo.name
      }
      // code to send info to backend to create email
      let reqBody = {
        uid: appStateContext.state.uid,
        email: appStateContext.state.email,
        title: supportTitle.value,
        content: supportDesc.value,
        subInfo: subInfo,
        fileData: fileData,
        fileType: fileTypes,
        fileName: fileNames
      }
      const res = await supportMail(reqBody).catch((error) => {
        console.error(error)
        setSubmitStatus(Enums.FORM_STATE.error)
        return null
      })
      if (res) {
        console.log(`Support ticket has been sent.\n${res}`)
        setSubmitStatus(Enums.FORM_STATE.success)
      }
    }
  }

  /*
   * onClick() validation event for Support Title field
   */
  const validateSupportTitle = event => {
    let tmpStateObj = Enums.buildStateObj(
      (event.target.value),
      supportTitle.isValid,
      supportTitle.span,
      supportTitle.iClass
    )

    // sanitize value
    tmpStateObj.value = Enums.removeIllegalOrReservedCharacters(tmpStateObj.value)

    // verify the new value is not null, meets min length requirements, and is not all spaces...
    if( tmpStateObj.value && tmpStateObj.value !== "" && tmpStateObj.value.length >= Enums.minInputLength && tmpStateObj.value.trim().length ) {
      tmpStateObj.isValid = true
      if( tmpStateObj.iClass.toString() === Enums.missingInputClass ) {
        tmpStateObj.iClass = Enums.normalInputClass
      }
    }
    else { 
      tmpStateObj.isValid = false
    }
    //*** finally we update the state with latest data
    if( !Enums.compareStates(supportTitle,tmpStateObj) ) {
      setSupportTitle(tmpStateObj)
    }
  }

  /*
   * onClick() validation event for support description 
   */
  const validateSupportDescription = event => { 
    let tmpStateObj = Enums.buildStateObj(
      (event.target.value),
      supportDesc.isValid,
      supportDesc.span,
      supportDesc.iClass
    )

    // sanitize value
    tmpStateObj.value = Enums.removeIllegalOrReservedCharacters(tmpStateObj.value)

    // verify the new value is not null, meets min length requirements, and is not all spaces...
    if( tmpStateObj.value && tmpStateObj.value !== "" && tmpStateObj.value.length >= minSupportMsgLength && tmpStateObj.value.trim().length ) {
      tmpStateObj.isValid = true
      if( tmpStateObj.iClass.toString() === Enums.missingTextAreaClass ) {
        tmpStateObj.iClass = Enums.normalTextAreaClass
      }
    }
    else { 
      tmpStateObj.isValid = false
    }
    //***
    setSupportDesc(tmpStateObj)
  }

  /*
   * highlight for support title field when field loses focus
   * through the onBlur() event
   */
  const highlightSupportTitleError = () => { 
    let tmpStateObj = Enums.buildStateObj(
      supportTitle.value,
      supportTitle.isValid,
      supportTitle.span,
      supportTitle.iClass,
    )
    if( !tmpStateObj.isValid ) {
      tmpStateObj.iClass = Enums.missingInputClass
    }
    else {
      tmpStateObj.iClass = Enums.normalInputClass
    }
    setSupportTitle(tmpStateObj)
  }

  /*
   * highlight for support description field when field loses focus
   * through the onBlur() event
   */
  const highlightSupportDescriptionError = () => { 
    let tmpStateObj = Enums.buildStateObj(
      supportDesc.value,
      supportDesc.isValid,
      supportDesc.span,
      supportDesc.iClass,
    )
    if( !tmpStateObj.isValid ) {
      tmpStateObj.iClass = Enums.missingTextAreaClass
    }
    else {
      tmpStateObj.iClass = Enums.normalTextAreaClass
    }
    setSupportDesc(tmpStateObj)
  }

  const readFiles = async (files) => {
    let filesReadPromise = files.map( file => {
      const fileReader = new FileReader()
      return new Promise((resolve, reject) => {
        fileReader.onload = () => resolve(fileReader.result)
        fileReader.readAsDataURL(file)
      })
    })
    let dataFromFiles = await Promise.all(filesReadPromise)
    setFileData(dataFromFiles)
  }
  
  const validateFileUpload = event => {
    const fileArr = Array.from(event.target.files)
    let tmpStateObj = Enums.buildStateObj(
      fileArr,
      file.isValid,
      file.span,
      file.iClass
    )
    
    if (tmpStateObj.value) {
      let fileSizes = 0
      let validFile = []
      tmpStateObj.value.forEach(f => {
        fileSizes += f.size
        validFile.push(true) // validFile.push((f.type.toString().includes('image') || f.type.toString().includes('video'))) // no longer perform the type check 
      })
      if (fileSizes < maxFileSize && !validFile.includes(false)) {
        tmpStateObj.isValid = true
        tmpStateObj.span = normalFileSpan
        readFiles(tmpStateObj.value)
        setFile(tmpStateObj)
      } else {
        tmpStateObj.isValid = false
        tmpStateObj.span = errorFileSpan
        setFileError(validFile)
        setFilesTooLarge(true)
      }
    }
  }

  /* 
   * Returns true if all form field values are ready for submission,
   * false otherwise 
   */
  function isFormReadyForSubmission() {
    // check state variables for validating each form field
    let formFields = [
      { "name": "Support Title", "value": supportTitle.isValid },
      { "name": "Support Text", "value": supportDesc.isValid },
      { "name": "File(s) Attached", "value": file.isValid }
    ]
    let formStatus = []
    let printString = "Required form fields missing/invalid:\n"

    formFields.forEach(function (aItem) {
      // special check for files
      if( aItem.name === "File(s) Attached" && file.value.length === 0 ) {
        formStatus.push(true)
        return
      }
      if( !aItem.value ) {
        formStatus.push(false)
        printString += ("\t- " + aItem.name + "\n")
      }
    });

    ////////////////////////////////////////////////////
    if( formStatus.includes(false) ) {
      // form not ready 
      // console.log(printString)
      setMissingFieldsMessage(printString)
      const tmpTitleObj = Enums.buildStateObj(
        supportTitle.value,
        supportTitle.isValid,
        supportTitle.span,
        Enums.missingInputClass
      )
      const tmpDescObj = Enums.buildStateObj(
        supportDesc.value,
        supportDesc.isValid,
        supportDesc.span,
        Enums.missingTextAreaClass
      )
      setSupportTitle(tmpTitleObj)
      setSupportDesc(tmpDescObj)
      return false
    }
    ////////////////////////////////////////////////////
    else {
      // console.log(`Form is ready`)
      return true
    }
    ////////////////////////////////////////////////////
  }

  /* 
   * Reset form and all state variables after form submission
   */
  function resetForm() {
    // console.log(`Resetting support form...`)
    // reset all form field values to null/default:
    setSupportTitle( 
      Enums.buildStateObj("", false, Enums.inputSpanHide, Enums.normalInputClass) 
    )
    setSupportDesc(
      Enums.buildStateObj("", false, Enums.inputSpanHide, Enums.normalTextAreaClass)
    )
    setMissingFieldsMessage("")
    setSubmitStatus(Enums.FORM_STATE.ready)
  }

  /* 
   * Clear selected files if the user chooses to not include files
   */
  function removeAddedFiles() {
    const tmpStateObj = Enums.buildStateObj(
      [],
      false, 
      normalFileSpan, 
      Enums.normalInputClass
    )
    setFile(tmpStateObj)
    setFileError([])
    setFilesTooLarge(false)
    setFileKey(Math.random().toString(36))
  }

  // build list of uploaded files to display in the file upload box
  function buildFileSelectNameDisplay() {
    let fileNameList = []
    if(file.value.length !== 0) {
      file.value.forEach((f,i) => {
        fileNameList.push(<p key={f.name + i}>{f.name}</p>)
      })
    }
    return fileNameList
  }

  /* 
   * Builds the main signup for Animate 3D which is designed
   * to be mobile responsive:
   */
  function buildSupportForm() {
    /*********************************************
     * Render the Support Form:
     *********************************************/
    return (
      <div className="" style={{paddingTop:'15px'}}>

        {/*** Move title above form for mobile ***/}
        <h1 className="subtitle is-2 animate-header" style={{marginBottom:'35px'}}>{formTitle}</h1>
        <h1 className="subtitle is-5 animate-header" style={{marginBottom:'35px'}}>{formText}</h1>

        {/*** DISPLAY MISSING FIELDS MESSAGE ***/}
        {supportTitle.iClass === Enums.missingInputClass || supportDesc.iClass === Enums.missingTextAreaClass || (file.span === errorFileSpan)
        ?
        <div className="field">
          <p className="help is-danger">{missingFieldsMessage}</p>
        </div>
        :
        <div className="field"></div>
        }

        {/*** SUPPORT TITLE ***/}
        <div className="field">
          <label className="label">{field_1_Title}</label>
          <div className="control has-icons-right">
            <input className={supportTitle.iClass} onChange={validateSupportTitle} onBlur={highlightSupportTitleError} type="text" placeholder="Title" />
            <span className={supportTitle.span}>
              <i className="fas fa-check"></i>
            </span>
          </div>
        </div>
      

        {/*** SUPPORT TEXT AREA ***/}
        <div className="field">
          <label className="label">{field_2_Title}</label>
          <div className="control has-icons-right">
            <textarea className={supportDesc.iClass} onChange={validateSupportDescription} onBlur={highlightSupportDescriptionError} type="textarea" placeholder="Description"/>
            <span className={supportDesc.span}>
              <i className="fas fa-check"></i>
            </span>
          </div>
        </div>

        {/*** FILE SELECT AREA ***/}
        <div className="field">
          <div className="file is-boxed has-name">
            <label className="file-label">
              <input className="file-input" key={fileKey} type="file" multiple name="additional-info" accept="" onChange={validateFileUpload}></input>
              <span className="file-cta mx-0">
                <span className="file-icon" style={{marginRight: 'auto'}}>
                  <i className="fas fa-upload"></i>
                </span>
                <span className="file-label">{field_3_Title}</span>
              </span>
              <span className="file-name mx-0" style={{height: 'auto', minHeight: '2.5em'}}>
                {buildFileSelectNameDisplay()}
              </span>
            </label>
          </div>
          <p className="help">{fileSize}</p>
          <p className={file.span}>{errorFileSize}</p>
          <div className="buttons is-centered">
            <button className={`button is-outlined is-danger mt-2 mb-0 ${file.value.length > 0 ? '':'hide'}`} onClick={() => removeAddedFiles()}>
              <span>Remove Files</span>
              <span className="icon is-small">
                <i className="fas fa-times"></i>
              </span>
            </button>
          </div>
        </div>

        {/*** [SUBMIT] button ***/}
        <div className="field mgTop-20">
          <div className="control">
            <button onClick={() => submitSupportForm()} className="button is-medium preview-btn">{submitButton}</button>
          </div>
        </div>

        {/* back button */}
        <div className="field">
          <div className="control">
            <Link to={Enums.routes.Dash}> Back </Link>
          </div>
        </div>
      </div>
    )
  }

  //---------------
  // using react helmet to set page title and metadata
  function seoMetaData_EN(props) {
    return (
      <Helmet>
        <meta charSet="utf-8" />
        <title> {docTitle} </title>
        <meta name="description" content= {metaDesc} />
      </Helmet>
    )
  }

  /* 
   * Builds the progress bar column for when a submission is in-progress
   */
  function buildProgressDisplay() {
    return (
      <div className="Absolute-Center" >
        <div className="column has-text-centered vcenter-flex" style={{minHeight:'50vh',marginBottom:'0'}}>

          <div className="">
            <div className="columns">
              <div className="column">
                <h4 className="subtitle is-4 has-text-black mgTop-50">{inProgressTitle}</h4>
              </div>
            </div>
            <div className="columns">
              <div className="column hcenter is-half">
                <progress className="progress indeterminate is-medium loading-bar" max="100"></progress>
              </div>
            </div>    
          </div>
        </div>
      </div>
    )
  }

  /* 
   * Builds the submission successful display
   */
  function buildSubmitSuccessDisplay() {
    return (

      <div className="" style={{paddingTop:'15px'}}>

        <h1 className="subtitle is-2 animate-header" style={{marginBottom:'35px'}}>{formTitle}</h1>

        <div className="column ">
          <div className="">
            <h1 className="title is-4 has-text-black mgTop-50">{successTitle}</h1>
            <div className="columns">
              <div className="column is-half has-text-centered ">
                <span className="icon has-text-success">
                  <i className="fas fa-check fa-5x"></i>
                </span>
              </div>
            </div>    
            <h2 className="subtitle is-5 has-text-black"> {successSub1} </h2>
            <h2 className="subtitle is-5 has-text-black"> {successSub2} </h2>
            <div className="columns">
              <div className="column is-half has-text-centered ">
                <button onClick={()=>history.push(Enums.routes.Dash)} className="button preview-btn">{goToDash}</button>
              </div>
            </div>
          </div>
        </div>
      </div>  
    )
  }

  // 
  // Builds the submission error display
  //
  function buildSubmitErrorDisplay() {
    return (
      <div className="Absolute-Center" style={{paddingTop:'20px'}}>
        <div className="column" >
          <div className="">
            <h1 className="title is-3 has-text-black mgTop-50">{errorTitle}</h1>
            <div className="columns">
              <div className="column is-half has-text-centered hcenter ">
                <span className="icon">
                  <i className="fas fa-exclamation-triangle fa-3x" style={{color:'orange'}}></i>
                </span>
              </div>
            </div>
            <h2 className="subtitle is-4 has-text-black" >{errorSubtitle}</h2>
            <h2 className="subtitle is-4 has-text-black" >{errorSubtitle2}</h2>
            <div className="columns">
              <div className="column is-half has-text-centered hcenter ">
                <div className="field is-grouped">
                  <div className="control">
                    <button onClick={()=>resetForm()} className="button preview-btn">{tryAgainButton}</button>
                  </div>
                  <div className="control">
                    <button onClick={()=>history.push(Enums.routes.Dash)} className="button preview-btn">{backButton}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>  
    )
  }

  function BuildAttachmentErrorDialog() {
    if (fileError.length === 0 || (!fileError.includes(false) && filesTooLarge === false)) {
      return null
    }

    if (filesTooLarge) {
      const errorMsgLargeFile = [<div key="file-too-large-dialog"><h5 className="title is-5">Files Too Large</h5><p>{errorFileSize}</p></div>]
      return(
        <DMDialog
          title="Problem Attaching Files"
          content={errorMsgLargeFile}
          msgFormat="html"
          label1="Close"
          action1={() => setFilesTooLarge(false)}
          isDanger={true}
        />
      )
    } else {
      let errorMessageHtml = [<h6 className="title is-6" key="err-msg-0">The following files are not images or videos:</h6>]
      errorMessageHtml = file.value.map((f, i) => {
        if (fileError[i] === false) {
          return <p key={`err-msg-${i}`}>{f.name}</p>
        }
      })

      return(
        <DMDialog
          title="Problem Attaching Files"
          content={errorMessageHtml}
          msgFormat="html"
          label1="Close"
          action1={() => setFileError([])}
          isDanger={true}
        />
      )
    }
  }

  ///********************************************************************
  ///Builds the entire screen for both desktop and mobile
  ///********************************************************************/
  function BUILD_SCREEN() {
    switch( submitStatus ) {
      case Enums.FORM_STATE.ready:
        return (
          <div className="mgTop-20">
            <div className="columns has-text-centered" >
              <div className="column is-half" style={{marginBottom:'0'}}>
                {/*** render the contact sales signup form: ***/}
                {buildSupportForm()}

              </div>
            </div>
          </div>
        )
      
      case Enums.FORM_STATE.inProgress:
        return (
          <div className="mgTop-20">
            <div className="columns has-text-centered" >
              <div className="column is-half" style={{marginBottom:'0'}}>
                {/*** render the contact sales signup form: ***/}
                {buildProgressDisplay()}
              </div>
            </div>
          </div>
        )
      
      case Enums.FORM_STATE.success:
        // TODO: Copied code from company website form, need to refactor! 
        return (
          <div className="columns mgTop-20 has-text-centered form-col" style={{marginBottom:'0'}}>
            {buildSubmitSuccessDisplay()}
          </div>
        )
      
      case Enums.FORM_STATE.failure:
        return (

          <div className="mgTop-20">
            <div className="columns has-text-centered" >
              <div className="column is-half" style={{marginBottom:'0'}}>
                {/*** render the support form: ***/}
                {buildSubmitErrorDisplay()}

              </div>
            </div>
          </div>  
        )
      
      default:
        return (<div></div>)
    }
  }

  React.useEffect(() => {
    let tmpStateObj = null
    //**************************************************
    //------ Validate Support Title Field ------
    //**************************************************
    tmpStateObj = Enums.buildStateObj(
      supportTitle.value,
      supportTitle.isValid,
      supportTitle.span,
      supportTitle.iClass
    )
    if( tmpStateObj.iClass.toString() !== Enums.missingInputClass ) {
      tmpStateObj.iClass = Enums.normalInputClass
    }
    tmpStateObj.span = Enums.inputSpanHide
    if( tmpStateObj.isValid ) {
      tmpStateObj.span = Enums.inputSpanShowSuccess
    }
    if( !Enums.compareStates(supportTitle,tmpStateObj) ) {
      setSupportTitle(tmpStateObj)
    }
    //****************


    //**************************************************
    //------ Validate Support Description Field ------
    //**************************************************
    tmpStateObj = Enums.buildStateObj(
      supportDesc.value,
      supportDesc.isValid,
      supportDesc.span,
      supportDesc.iClass
    )
    if( tmpStateObj.iClass.toString() !== Enums.missingTextAreaClass ) {
      tmpStateObj.iClass = Enums.normalTextAreaClass
    }
    tmpStateObj.span = Enums.inputSpanHide
    if( tmpStateObj.isValid ) {
      tmpStateObj.span = Enums.inputSpanShowSuccess
    }
    if( !Enums.compareStates(supportDesc,tmpStateObj) ) {
      setSupportDesc(tmpStateObj)
    }
  },[
    supportTitle,
    supportDesc,
    missingFieldsMessage,
    submitStatus
  ])

  React.useEffect(() => {

  }, [
    file,
    fileError,
    filesTooLarge,
    fileData,
    fileKey
  ])

  // set page title and meta data
  var helmetData = seoMetaData_EN()

  return (
    <HelmetProvider>
      <div id="anim-fadein" className="column mt-0">
      {helmetData}
      <AnimVersionPanel />
      <BuildAttachmentErrorDialog />
      {
        props.LOADING.show
        ?
        <LoadingScreen />
        :
        BUILD_SCREEN()
      }
      </div>
    </HelmetProvider>
)
}