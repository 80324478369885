import React from "react"
import "./assets/styles/MultiPersonSelectorSwitch.scss"

// strings
const jobTypeSinglePerson = "Single Person"
const jobTypeMultiPerson = "Multi-Person (Beta)"

const singlePerson = <svg className="single-person" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 104"><circle cx="20" cy="13" r="13" /><path d="M86,118.16V144a8,8,0,0,0,8,8h11a8,8,0,0,0,8-8V118.54a10,10,0,0,0,7-9.54V85a10,10,0,0,0-10-10H90A10,10,0,0,0,80,85v24A10,10,0,0,0,86,118.16Z" transform="translate(-80 -48)" /></svg>
const multiPerson = <svg className="multi-person" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 104 112"><circle cx="20" cy="13" r="13" /><path d="M103.21,70.6A13,13,0,1,1,113,58,13.07,13.07,0,0,1,103.21,70.6Z" transform="translate(-48 -37)" /><path d="M78.08,99.83v9A10,10,0,0,0,81,115.89V133a8,8,0,0,1-8,8H62a8,8,0,0,1-8-8V107.16A10,10,0,0,1,48,98V74A10,10,0,0,1,58,64H78a10,10,0,0,1,9.41,6.62,10,10,0,0,0-9.33,10Z" transform="translate(-48 -37)" /><path d="M80.16,107.76A9.29,9.29,0,0,1,80,106V82a10,10,0,0,1,7.84-9.76A9.6,9.6,0,0,1,90,72h20a9.6,9.6,0,0,1,2.16.24A10,10,0,0,1,120,82v24a9.29,9.29,0,0,1-.16,1.76A9.78,9.78,0,0,1,118,112a10,10,0,0,1-5,3.55V141a8,8,0,0,1-8,8H94a8,8,0,0,1-8-8V115.16a10,10,0,0,1-5-4.81A9.36,9.36,0,0,1,80.16,107.76Z" transform="translate(-48 -37)" /><circle cx="84" cy="13" r="13" /><path d="M121.92,99.13v9.7a9.94,9.94,0,0,1-3.92,7.93V133a8,8,0,0,0,8,8h11a8,8,0,0,0,8-8V107.54A10,10,0,0,0,152,98V74a10,10,0,0,0-10-10H122a10,10,0,0,0-9.41,6.62,10,10,0,0,1,9.33,10Z" transform="translate(-48 -37)" /></svg>

export default function MultiPersonSelectorSwitch({ multiPersonTracking, setMultiPersonTracking, multiPersonSelection }) {

  let anim3dFontColor = "dm-brand-2-font", poseFontColor = "selector-inactive"
  if (multiPersonTracking) {
    anim3dFontColor = "selector-inactive"
    poseFontColor = "dm-brand-2-font"
  }
  return (
    <>
      <div className={"MultiPersonSelectorSwitch column box disabled-switch m-3 p-4" + (multiPersonSelection ? " is-lock" : "")}>
        <div className="columns is-justify-content-center is-gapless is-mobile has-text-centered has-text-weight-bold br-4">
          {/* left title */}
          <div className={`is-5 m-0 p-0 flex-1 ${anim3dFontColor}`}>
            <div className="columns is-gapless is-mobile m-0 p-0 float-right">
              {singlePerson}
              <div className="mx-6 p-0 flex-vert-center">
                {jobTypeSinglePerson}
              </div>
            </div>
          </div>
          {/* checkbox */}
          <div className="column is-2 m-0 p-0 flex-vert-center">
            <i className={`fas fa-2x fa-chevron-left ${anim3dFontColor}`} />
            <label className="jobtype-switch">
              <input disabled={multiPersonSelection} onChange={() => setMultiPersonTracking(!multiPersonTracking)} type="checkbox" checked={multiPersonTracking} />
              <span className="jobtype-slider" />
            </label>
            <i className={`fas fa-2x fa-chevron-right ${poseFontColor}`} />
          </div>
          {/* right title */}
          <div className={`is-5 m-0 p-0 flex-1 ${poseFontColor}`}>
            <div className="columns is-gapless is-mobile m-0 p-0">
              <div className="mx-6 p-0 flex-vert-center">
                {jobTypeMultiPerson}
              </div>
              {multiPerson}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}