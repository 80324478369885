export const converBase64ToFile = (editorBase64, fileName) => {
    let blobURI = base64toBlob(editorBase64)
    return blobToFile(blobURI, fileName)
}

// base64 -> Blob
export const base64toBlob = (editorBase64) => {
    var base64Arr = editorBase64.split(",")
    var type = ""
    var base64String = ""
    if (base64Arr.length > 1) {
      base64String = base64Arr[1];
      type = base64Arr[0].substring(
        base64Arr[0].indexOf(":") + 1,
        base64Arr[0].indexOf(";")
      )
    }
    // base 64 encoder and decoder
    var binary = atob(base64String);
    var array = []

    // base64 translate ascii
    for (var i = 0; i < binary.length; i++) {
        array[i] = binary.charCodeAt(i)
    }

    // Generate Blob objects
    return new Blob([new Uint8Array(array)], { type: type })
}

// blob -> file
export const blobToFile = (theBlob, fileName) => {
    theBlob.lastModifiedDate = new Date()  // The last modification date of the file
    theBlob.name = fileName                // filename
    return new File([theBlob], fileName, {type: theBlob.type, lastModified: Date.now()})
}