import React, { useRef } from 'react'
import { Link, useHistory } from "react-router-dom"
import { Helmet, HelmetProvider } from 'react-helmet-async';
import AnimVersionPanel from '../common/AnimVersionPanel'
import * as Enums from './enums'
const qs = require('querystring')

// images
import imgSupport from '../../images/dashboard/support.jpg'
import imgFeedback from '../../images/dashboard/feedback.jpg'

// strings
const screenTitle = "What do you need from us?"
const newSupportText = "Create New Support Ticket"
const newFeedbackText = "Submit Feedback"
const textSupport = "Get Support"
const textFeedback = "Submit Feedback"

// SEO 
const docTitle = "Contact Us | DEEPMOTION"
const metaDesc = "Request support or submit feedback"

export default function ContactUs(props) {

  const [supportTileHover, setSupportTileHover] = React.useState(false)
  const [feedbackTileHover, setFeedbackTileHover] = React.useState(false)

  let titleSizeClass = "is-4"
  let subtitleSizeClass = "is-5"
  if( props.screenWidth > Enums.largeDisplayWidth) {
    titleSizeClass = "is-1"
    subtitleSizeClass = "is-2"
  }
  else if( props.screenWidth <= Enums.fullHDWidth ) {
    titleSizeClass = "is-5"
    subtitleSizeClass = "is-6"
  }

  //---------------
  // using react helmet to set page title and metadata
  function seoMetaData_EN(props) {
    return (
      <Helmet>
        <meta charSet="utf-8" />
        <title> {docTitle} </title>
        <meta name="description" content= {metaDesc} />
      </Helmet>
    )
  }

  function buildSupportCard() {
    return ( 
      <div className={"bShadow" + (supportTileHover ? " tile-border-pulse glow-on-hover" : "")}
        onClick={()=>props.history.push(Enums.routes.SupportPage)}
        onMouseEnter={()=>setSupportTileHover(true)}
        onMouseLeave={()=>setSupportTileHover(false)}
        >
        <div className={"columns m-0 p-0 br-4-top fullwidth " + (supportTileHover ? "dm-brand-2" : "dm-brand")}>
          <div className="column">
            <div className={ `title ${titleSizeClass}` + (supportTileHover ? " dm-brand-font" : " has-text-light")}> {textSupport} </div>
          </div>
        </div>
        <div className={"card dm-brand-border-md br-top-none br-4-bot has-background-white " + (supportTileHover ? "selection-card-active" : "")}>
          <div className="card-image">
            <figure className="image is-16by9">
              <img src={imgSupport} className="br-none" alt="Support" />
            </figure>
          </div>
          <div className="card-content p-0 px-2" style={{minHeight:'80px'}}>
            <div className="content">
              <div className={`subtitle ${subtitleSizeClass} p-4 dm-brand-font `}> {newSupportText} </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  function buildFeedbackCard() {
    return ( 
      <div className={"bShadow" + (feedbackTileHover ? " tile-border-pulse glow-on-hover" : "")}
        onClick={()=>props.history.push(Enums.routes.FeedbackPage)}
        onMouseEnter={()=>setFeedbackTileHover(true)}
        onMouseLeave={()=>setFeedbackTileHover(false)}
        >
        <div className={"columns m-0 p-0 br-4-top fullwidth " + (feedbackTileHover ? "dm-brand-2" : "dm-brand")}>
          <div className="column">
            <div className={ `title ${titleSizeClass}` + (feedbackTileHover ? " dm-brand-font" : " has-text-light")}> {textFeedback} </div>
          </div>
        </div>
        <div className={"card dm-brand-border-md br-top-none br-4-bot has-background-white " + (feedbackTileHover ? "selection-card-active" : "")}>
          <div className="card-image">
            <figure className="image is-16by9">
              <img src={imgFeedback} className="br-none" alt="Support" />
            </figure>
          </div>
          <div className="card-content p-0 px-2" style={{minHeight:'80px'}}>
            <div className="content">
              <div className={`subtitle ${subtitleSizeClass} p-4 dm-brand-font `}> {newFeedbackText} </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  /******************************************************************* 
   * Builds screen to select support or feedback
   *******************************************************************/
  function buildSelectionScreen() {
    return ( 
      <div className="mgTop-20">
        <div className="columns fullwidth has-text-centered mb-5">
          <div className="column pt-0">
            <div className={"title ${titleSizeClass} has-text-light dash-title py-1 mx-3"}> {screenTitle} </div>
          </div>
        </div>
        <div className="columns is-mobile mt-5">
          <div className="column is-three-fifths is-offset-one-fifth">
            <div className="columns has-text-centered mb-5" >
              <div className="column">
                {buildSupportCard()}
              </div>
              <div className="column">
                {buildFeedbackCard()}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  // set page title and meta data
  var helmetData = seoMetaData_EN()

  return (
    <HelmetProvider>
      <div id="anim-fadein" className="column mt-0">
        <AnimVersionPanel />
        {helmetData}
        {buildSelectionScreen()}
      </div>
    </HelmetProvider>
  )
}