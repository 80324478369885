import React, { useContext, useState } from "react";
import DMToolTip from "../ui/DMToolTip";
import * as Enums from "../common/enums";

import "./assets/styles/index.scss";
import { AppStateContext } from "../../AppStateContext";

const detailLink =
  "https://www.deepmotion.com/article/animation-credit-program";

const motionTextTips =
  "Describe the motion in the video covering action type, direction, style, trajectory, emotions, and key details for thorough animation labeling.";
const postTextTips =
  "Describe the pose using short but detailed description covering action type, direction, style, trajectory, emotions, and key details for thorough labeling.";
const multiPersonTextTips =
  "Describe the overall motion performed in the multi-person video covering interaction and individual action type, direction, style, trajectory, emotions, and key details for thorough animation labeling.";

const motionLabeling = ({
  multiPersonTracking,
  motionLabelingText,
  setMotionLabelingText,
}) => {
  const { state } = useContext(AppStateContext);
  const maxLength = 256;

  return (
    <div className="motionLabeling box disabled-switch m-3 p-4">
      <div className="is-flex is-align-items-center">
        <div className="tag dm-brand-2 has-text-white is-normal m-0 mr-2">
          New
        </div>
        <div className="title is-5 dm-white m-0">
          Describe The {!state.animJobSettings.jobType ? "Motion" : "Pose"} To
          Earn Free Animation Credits
        </div>
        <DMToolTip
          text={
            !state.animJobSettings.jobType
              ? Enums.motionLabelingTips
              : Enums.poseLabelingTips
          }
          isTipHtml={true}
          tipId="video-length-should-trim"
          icon="fas fa-info-circle fa-md"
          cursor="cursor-pt"
        />
        <div
          className="LearnMore dm-white ml-2 px-1 cursor-pt"
          onClick={() => window.open(detailLink)}
        >
          Learn More
        </div>
      </div>

      <div className="motionLabeling-textarea">
        <textarea
          className="textarea mt-3"
          maxLength={maxLength}
          placeholder={
            multiPersonTracking
              ? multiPersonTextTips
              : !state.animJobSettings.jobType
              ? motionTextTips
              : postTextTips
          }
          value={motionLabelingText}
          onChange={(e) => {
            setMotionLabelingText(e.target.value);
          }}
        />
        <div className="textLenght dm-white">
          {motionLabelingText.length}/ {maxLength}
        </div>
      </div>
    </div>
  );
};

export default motionLabeling;
