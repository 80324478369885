import * as Enums from './components/common/enums'
import dmLogo from './images/logo.png'

let orgUrl = null
// okta signin widget configuration:
if( process.env.REACT_APP_OKTA_ORG_URL && process.env.REACT_APP_OKTA_ORG_URL !== "" ) {
  orgUrl = process.env.REACT_APP_OKTA_ORG_URL
}
else if( process.env.OKTA_ORG_URL && process.env.OKTA_ORG_URL !== "" ) {
  orgUrl = process.env.OKTA_ORG_URL
}
const oktaAuthConfig = {
  issuer: `${process.env.REACT_APP_OKTA_ORG_URL}/oauth2/default`,
  clientId: `${process.env.REACT_APP_OKTA_CID}`,
  redirectUri: window.location.origin + `/login/callback`,
  scopes: ['openid', 'email', 'profile'],
  autoRenew: true,
  tokenManager: {
    autoRenew: true,
    expireEarlySeconds: 5 * 60 // expire 5 mins early 
  }
}
const oktaSignInConfig = {
  baseUrl: orgUrl,
  clientId: `${process.env.REACT_APP_OKTA_CID}`,
  redirectUri: window.location.origin + `/login/callback`,
  scopes: ['openid', 'email', 'profile'],
  // devMode: false,
  autoRenew: true,
  tokenManager: {
    autoRenew: true,
    expireEarlySeconds: 5 * 60 // expire 5 mins early 
  },
  authParams: {
    // If your app is configured to use the Implicit flow
    // instead of the Authorization Code with Proof of Code Key Exchange (PKCE)
    // you will need to uncomment the below line
    // pkce: false
  },
  language: 'en',
  logo: dmLogo,
  i18n: {
    en: {
      'primaryauth.title': 'Sign in',
      'primaryauth.username.placeholder': 'Email',
      'needhelp': 'Forgot Password ?',
      'forgotpassword': 'Reset Password',
      'error.username.required': 'Please enter an email'
    },
    'zh-CN': {
      'primaryauth.username.placeholder': '邮箱',
      'error.username.required': '请输入邮箱'
    }
  },
  helpLinks: {
    forgotPassword: Enums.routes.ForgotPwdPage
  }
  // Additional documentation on config options can be found at https://github.com/okta/okta-signin-widget#basic-config-options
}

export { oktaAuthConfig, oktaSignInConfig }
