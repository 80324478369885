import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AppStateContext } from "../../AppStateContext";

// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Scrollbar } from "swiper";
import "swiper/swiper-bundle.css";
import "./assets/styles/MultiPerson.scss";

// utils
import { shallowEqual, bubbleSort } from "../common/utils";

// compontents
import MultiPersonSelector from "./MultiPersonSelector";
import InfoDialog from "../common/InfoDialog";
import DMToolTip from "../ui/DMToolTip";

// api
import {
  startNewAnimOrPoseJob,
  checkJobStatus,
  getLinksForJob,
  stopInProgressJob,
} from "../api/apiRequests";

// enums
import * as Enums from "../common/enums";

// iamge
import imgCustom from "../../images/animate-3d/character-select/model-custom.jpg";
import UnknownCharacterIcon_White from "../../images/multiPersonTracking/UnknownCharacterIcon_White.png";
import {
  MODEL_NAME_FEMALE_WITH_FACE_UE,
  PLATFORM_UNREAL,
  useRootAtOriginEmulation,
} from "../../hooks/useRootAtOriginEmulation";

const titleMpTracking = "Multi-Person Tracking";
const textWaitingTips = "Waiting to upload";
const textTracked = "Persons Tracked";
const stepTip = [
  "Upload Image or Video",
  "Analyzing...",
  "Click 'Save & Continue'",
  "Click Continue",
  "Wait for Multi-Person Tracking...",
];
const TotalCharacters = "Total Characters Detected:";
const selectionButton = "Review Multi-Person Selection";

SwiperCore.use([Navigation, Scrollbar]);

let timer: NodeJS.Timer | null = null;
export default function MultiPerson({
  multiPersonSelection,
  setMultiPersonSelection,
  setMultiPersonTracking,
  setMultiPersonIsAlreadyCreated,
  multiPersonRid,
  setMultiPersonRid,
  setMultiPersonCharacter,
  checkFeatureAvailability,
  videoCropData,
  videoTrimData,
  videoCropInit,
  normalizeVideoCropCoord,
  logoutUser,
  handleHttpError,
  setErrorDialogInfo,
}) {
  const { state } = useContext(AppStateContext);
  const modellist = [
    ...state.accountTotals.platformCharactersList,
    ...state.accountTotals.charactersList,
  ];

  const [numModels, setNumModels] = useState(modellist);

  // swiper
  const [disabledSwiperNavigation, setDisabledSwiperNavigation] = useState<
    "left" | "right" | "all" | ""
  >("");

  const [step, setStep] = useState(0);

  // Getting current traceable character
  const history = useHistory();
  const [pathname, setPathname] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const [dialogMessage, setDdialogMessage] = useState({
    title: "",
    msg: "",
  });

  const [swiperWidth, setSwiperWidth] = useState(0);
  const [swiperSlideWidth, setSwiperSlideWidth] = useState(0);
  useEffect(() => {
    history.block((route) => {
      const last = stepTip.length - 1;
      if (step === last) {
        setDdialogMessage({
          title: "Task running",
          msg: "Do you want to leave the job creation page? All the progress with be lost.",
        });
        setShowDialog(true);
        setPathname(route.pathname);
        return false;
      } else {
        return true;
      }
    });
    if (step !== 0) {
      setSwiperSlideWidth((swiperWidth - 220) / 3);
    }
  }, [step]);

  useEffect(() => {
    const width = document.getElementsByClassName(
      "MultiPersonSelector-selection"
    )[0].scrollWidth;
    setSwiperWidth(width);
    return () => {
      console.log("logout MPT");
      history && history.block(() => true);
    };
  }, []);

  // Maximum number of people tracked at current level
  const overTheMaximum = checkFeatureAvailability(
    state.subscriptionInfo.name,
    Enums.featureLockNames.maxTrackingPersons
  );
  const [characterJobProgress, setCharacterJobProgress] = useState(0);
  // Multi Person Selector
  const [selectionNum, setSelectionNum] = useState(0);
  const [showMultiPersonSelector, setShowMultiPersonSelector] = useState(false);
  const [startMultiPersonTracking, setStartMultiPersonTracking] =
    useState(false);
  const [characters, setCharacters] = useState([]);

  const mulitPersonSelection = () => {
    setMultiPersonIsAlreadyCreated(true);
    setShowMultiPersonSelector(true);
  };
  // Monitor video upload status true：loading
  useEffect(() => {
    if (state.silentUploadInProgress) {
      setStep(1);
    } else if (
      !state.silentUploadInProgress &&
      state.inputVideoData?.fileLength
    ) {
      const numStep = state.animJobSettings.jobType === 0 ? 2 : 3;
      setStep(numStep);
    }
  }, [state.silentUploadInProgress]);

  useEffect(() => {
    if (state.currWorkflowStep === Enums.uiStates.initial) {
      setStep(0);
    }
  }, [state.currWorkflowStep]);

  // cilck Save & Continue
  useEffect(() => {
    if (multiPersonSelection && state.videoStorageUrl) {
      personDetectedData();
    }
  }, [multiPersonSelection]);

  const personDetectedData = async () => {
    // Details of the number of people detected
    setStep(4);
    // form request data body for job request:
    const jobParams = ["config=configWebApp", "pipeline=mp_detection"];
    const isVideoTrimDataPresent =
      videoTrimData &&
      (videoTrimData.from > 0 ||
        videoTrimData.to < state.inputVideoData.fileLength);
    if (isVideoTrimDataPresent) {
      jobParams.push(
        `trim=${videoTrimData.from.toFixed(2)},${videoTrimData.to.toFixed(2)}`
      );
    }

    const isVideoCropDataPresent =
      videoCropData && !shallowEqual(videoCropData, videoCropInit);
    if (isVideoCropDataPresent) {
      const crop = normalizeVideoCropCoord(videoCropData);
      jobParams.push(
        `crop=${crop.left},${crop.top},${crop.right},${crop.bottom}`
      );
    }
    const requestData = {
      processor: "video2anim",
      params: jobParams,
      url: state.videoStorageUrl,
    };
    const res = await startNewAnimOrPoseJob(requestData, true).catch(
      (error) => {
        getCharacterIsFailed();
        if (error.response) {
          if (error.response.status === Enums.eCodes.Forbidden) {
            console.error(
              `Access forbidden error encountered while starting animation job - \n${error}`
            );
            logoutUser();
          }
          if (error.response.status === Enums.eCodes.InternalServerError) {
            setErrorDialogInfo(
              true,
              Enums.eCodes.InternalServerError,
              Enums.customErrors[error.response.data.error],
              error.response.data.message,
              () => {
                return;
              }
            );
          }
        } else {
          handleHttpError(error, "Problem Starting New Job", true);
        }
        return null;
      }
    );
    if (res == null) return;
    timer = setInterval(async () => {
      await checkStatusTimerCallback(res.data.rid);
    }, 1500);
    return res;
  };

  const checkStatusTimerCallback = async (rid: string) => {
    setMultiPersonRid(rid);
    const statusRes = await checkJobStatus(rid, true).catch((error) => {
      console.error(`Problem checking job status ${rid}, error is ${error}`);
      setErrorDialogInfo(
        true,
        Enums.eCodes.OtherError,
        "Problem Checking Job Status"
      );
      getCharacterIsFailed();
    });

    if (statusRes == null) {
      return;
    }
    // check against count, loop through count checking statuses
    // status array element contains job's rid, status, step, and total fields
    if (statusRes.data.count > 0) {
      const statusInfo = statusRes.data.status[0];
      if (!statusInfo.status) {
        console.error(
          `Warning: Found ${statusRes.data.count} jobs however statusInfo() is null. Stopping current status checks. This may indicate a network connectivity issue or a server-side problem.`
        );
        getCharacterIsFailed();
      } else if (statusInfo.status === "FAILURE") {
        setErrorDialogInfo(Enums.eCodes.OtherError, "Job Failed", true);
        handleHttpError(
          "There was an error trying to create animations from the video provided, if the problem continues please contact DeepMotion Support.",
          "Job Failed",
          true
        );
        console.log("Animation job has failed!");
        getCharacterIsFailed();
      } else if (statusInfo.status === "SUCCESS") {
        console.log("SUCCESS");
        if (timer) {
          clearInterval(timer);
          timer = null;
        }
        const { data } = await getLinksForJob(rid);
        const urls = data.links[0].urls;

        const character = getMultiPersonModelDownloadLinks(urls);
        if (character.length <= 1) {
          setErrorDialogInfo(
            true,
            Enums.eCodes.InternalServerError,
            "Video Processing Failed",
            "We don't allow single person videos in Multi-Person flow."
          );
          setMultiPersonTracking(false);
          setMultiPersonSelection(false);
          setCharacterJobProgress(0);
        } else {
          const charactersData = bubbleSort(character);
          if (charactersData.length <= 3) {
            setDisabledSwiperNavigation("");
          } else {
            setDisabledSwiperNavigation("left");
          }
          setCharacters(charactersData);
          setStartMultiPersonTracking(true);
          setCharacterJobProgress(0);
        }
      } else if (statusInfo.status === "PROGRESS") {
        const progress =
          (statusInfo.details.step / statusInfo.details.total) * 100;
        setCharacterJobProgress(Math.round(progress));
      }
    }
  };

  const getMultiPersonModelDownloadLinks = (urls: any[]) => {
    const models: any[] = [];
    urls.forEach((items: any) => {
      const temp: any[] = [];
      items.files.forEach((item: any) => {
        Object.entries(item).forEach(([key, value]) => {
          temp.push({ type: key, link: value });
        });
      });
      models.push({ name: items.name, links: temp });
    });

    const character = [];
    const multiPersonCharacter = [];
    for (const model of models) {
      // dmpe files part of landmarks node
      const defaultModelIndex = numModels.findIndex((model) =>
        model.name === state.animJobSettings.rootJointAtOrigin
          ? Enums.defaultModelName
          : MODEL_NAME_FEMALE_WITH_FACE_UE
      );
      const defaultModel = numModels[defaultModelIndex];

      model.links.forEach((link) => {
        const data = {
          sort: 0,
          name: "",
          trackingId: "",
          value: "",
          url: "",
          check: false,
          model: defaultModel,
          modelUrl:
            defaultModel.thumbImg instanceof Blob
              ? URL.createObjectURL(defaultModel.thumbImg)
              : defaultModel.thumb
              ? defaultModel.thumb
              : imgCustom,
          modelNum: defaultModelIndex,
        };
        if (link.type === "png") {
          const trackingId = model.name.split("thumbnail_character_")[1];
          data.sort = Number(trackingId);
          data.check = Number(trackingId) <= overTheMaximum ? true : false;
          data.name = model.name;
          data.trackingId = trackingId;
          data.value = "png";
          data.url = link.link;
        }
        // get character ID
        // if (link.type === "cdsave") {
        //   console.log(link);
        //   console.log(model);
        //   getMultiPersonJobTrackID(link.link).then((res) => {
        //     console.log(res);
        //   });
        // }
        data.name && character.push(data);
      });
    }
    character.forEach((item) => {
      if (item.check) {
        const data = {
          trackingId: item.trackingId,
          modelId: numModels[item.modelNum].id,
        };
        multiPersonCharacter.push(data);
      }
    });
    setMultiPersonCharacter(multiPersonCharacter);
    if (character.length <= overTheMaximum) {
      setSelectionNum(character.length);
    } else {
      setSelectionNum(overTheMaximum);
    }
    return character;
  };
  // The Cancel task TAB is displayed
  // personDetectedData（）
  const pauseToGetCharacter = () => {
    return (
      <InfoDialog
        title={dialogMessage.title}
        msg={dialogMessage.msg}
        label2="Yes"
        action2={() => clearPauseToGetCharacter()}
        label1="No"
        action1={() => setShowDialog(false)}
      />
    );
  };
  const clearPauseToGetCharacter = async () => {
    history.block(() => true);
    await stopInProgressJob(multiPersonRid);
    history.push(pathname);
    getCharacterIsFailed();
  };

  // Reset all states
  const getCharacterIsFailed = () => {
    setMultiPersonRid("");
    setCharacterJobProgress(0);
    setMultiPersonSelection(false);
    const numStep = state.animJobSettings.jobType === 0 ? 2 : 3;
    setStep(numStep);
    if (timer) {
      clearInterval(timer);
      timer = null;
    }
  };

  // ‘rootJointAtOrigin’，change select model
  useEffect(() => {
    const character = [...characters];
    if (state.animJobSettings.rootJointAtOrigin === 1) {
      const models = modellist.filter((item) => {
        return item.platform === PLATFORM_UNREAL;
      });
      character.map((item) => {
        const newModel = useRootAtOriginEmulation(
          state.animJobSettings.rootJointAtOrigin,
          numModels,
          item.model
        );
        const num = models.findIndex((item) => item.id === newModel.id);
        item.model = newModel;
        item.modelNum = num;
        item.modelUrl =
          models[num].thumbImg instanceof Blob
            ? URL.createObjectURL(models[num].thumbImg)
            : models[num].thumb
            ? models[num].thumb
            : imgCustom;
      });

      setNumModels(models);
    } else {
      character.map((item) => {
        const newModel = useRootAtOriginEmulation(
          state.animJobSettings.rootJointAtOrigin,
          modellist,
          item.model
        );
        const num = modellist.findIndex((item) => item.id === newModel.id);
        item.model = newModel;
        item.modelNum = num;
        item.modelUrl =
          modellist[num].thumbImg instanceof Blob
            ? URL.createObjectURL(modellist[num].thumbImg)
            : modellist[num].thumb
            ? modellist[num].thumb
            : imgCustom;
      });

      setNumModels(modellist);
    }
  }, [state.animJobSettings.rootJointAtOrigin]);

  return (
    <div className="MultiPersonSelector column disabled-switch box m-3 p-0 p-4 has-text-centered none-select">
      <div
        className={
          "MultiPersonSelector-mark title is-5 mb-0" +
          (startMultiPersonTracking ? " is-hidden" : "")
        }
      >
        {step === stepTip.length - 1 ? (
          <div className="MultiPersonSelector-mark-tips load">
            <div className="loadEffect is-flex">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <div>{characterJobProgress}%</div>
            </div>
            <div>{stepTip[step]}</div>
          </div>
        ) : step === 0 ? (
          state.animJobSettings.jobType ? (
            <div className="MultiPersonSelector-mark-tips">Upload Image</div>
          ) : (
            <div className="MultiPersonSelector-mark-tips">Upload Video</div>
          )
        ) : (
          <div className="MultiPersonSelector-mark-tips">{stepTip[step]}</div>
        )}
      </div>
      <div className="title is-5 has-text-white my-2">{titleMpTracking}</div>
      {startMultiPersonTracking ? (
        <>
          <div className="subtitle is-5 has-text-white m-0 mt-2 is-flex is-align-items-center is-justify-content-center">
            <p>
              {selectionNum} / {overTheMaximum} {textTracked}
            </p>
            <DMToolTip
              text={Enums.tooltipVideoShouldCrop()}
              iconColor="#ffffff"
              tipId="upgrade-subscription"
              icon={`fas fa-info-circle fa-md`}
              cursor="cursor-pt"
            />
          </div>
          <div className="subtitle is-7 has-text-white mt-3">
            {TotalCharacters} {characters.length}
          </div>
        </>
      ) : (
        <div className="subtitle is-5 has-text-white m-0 mt-2 mb-6">
          {textWaitingTips}
        </div>
      )}
      <div
        className="MultiPersonSelector-selection flex-1"
        style={{ width: swiperWidth ? swiperWidth : "auto" }}
      >
        <div className="MultiPersonSelector-selection-swiper is-flex is-align-items-center">
          <i
            className={
              disabledSwiperNavigation === "left" ||
              disabledSwiperNavigation === ""
                ? "swiper-navigation swiper-prev fas fa-3x fa-chevron-left notEnabled"
                : "swiper-navigation swiper-prev fas fa-3x fa-chevron-left"
            }
          />
          <Swiper
            className="none-select"
            observer={true}
            slidesPerView={
              characters.length === 0
                ? "auto"
                : characters.length >= 3
                ? 3
                : characters.length
            }
            spaceBetween={20}
            navigation={{
              nextEl: ".swiper-next",
              prevEl: ".swiper-prev",
            }}
            scrollbar={{
              el: ".swiper-scrollbar",
              draggable: true,
              dragSize: 60,
            }}
            onSwiper={(swiper) => {
              if (swiper.activeIndex === 0) {
                if (characters.length <= 3) {
                  setDisabledSwiperNavigation("");
                } else {
                  setDisabledSwiperNavigation("left");
                }
              }
            }}
            onSlideChange={(swiper) => {
              setDisabledSwiperNavigation("all");
              if (swiper.activeIndex === 0) {
                setDisabledSwiperNavigation("left");
              }
              if (swiper.activeIndex === characters.length - 3) {
                setDisabledSwiperNavigation("right");
              }
            }}
          >
            {characters.length ? (
              characters.map((row, i) => {
                return (
                  <SwiperSlide key={i}>
                    <div
                      className={
                        row.check ? "slide-box p-1 show" : "slide-box p-1"
                      }
                      style={{
                        width: swiperSlideWidth + "px",
                        height: swiperSlideWidth + "px",
                      }}
                    >
                      <img className="slide-img" src={row.url} alt="" />
                    </div>
                  </SwiperSlide>
                );
              })
            ) : (
              <SwiperSlide>
                <div className="MultiPersonSelector-selection-swiper is-flex is-align-items-center">
                  <img
                    className="unavailable p-3"
                    src={UnknownCharacterIcon_White}
                    alt=""
                  />
                </div>
              </SwiperSlide>
            )}
          </Swiper>
          <i
            className={
              disabledSwiperNavigation === "right" ||
              disabledSwiperNavigation === ""
                ? "swiper-navigation swiper-next fas fa-3x fa-chevron-right notEnabled"
                : "swiper-navigation swiper-next fas fa-3x fa-chevron-right"
            }
          />
        </div>
        <div className="swiper-scrollbar" />
      </div>

      <div className="MultiPersonSelector-footer column mt-6">
        <div
          className="button action-btn glow-on-hover btn-shadow glow-on-hover m-0"
          onClick={mulitPersonSelection}
        >
          {selectionButton}
        </div>
      </div>
      <MultiPersonSelector
        numModels={numModels}
        overTheMaximum={overTheMaximum}
        showMultiPersonSelector={showMultiPersonSelector}
        setShowMultiPersonSelector={setShowMultiPersonSelector}
        setMultiPersonIsAlreadyCreated={setMultiPersonIsAlreadyCreated}
        setMultiPersonCharacter={setMultiPersonCharacter}
        selectionNum={selectionNum}
        setSelectionNum={setSelectionNum}
        characters={characters}
        setCharacters={setCharacters}
      />
      {showDialog && pauseToGetCharacter()}
    </div>
  );
}
