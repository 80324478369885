import React, { useContext, useEffect } from "react";
import "../../../styles/homePage.scss";

import { Helmet, HelmetProvider } from "react-helmet-async";
import LoadingScreen from "../../common/LoadingScreen";
import AnimVersionPanel from "../../common/AnimVersionPanel";
import { AppStateContext } from "../../../AppStateContext";
import * as Enums from "../../common/enums";
import DMToolTip from "../../ui/DMToolTip";

// SEO & metadata
const docTitle = "Animate 3D | DEEPMOTION";
const metaDesc = "Welcome to your Animate 3D Dashboard";

// images
import imgNewAnim from "../../../images/dashboard/animate-3d.jpg";
import img3DModels from "../../../images/animate-3d/custom-characters.jpg";

const textNewAnim = "Create";

export default function Animate3DHome(props) {
  const { state } = useContext(AppStateContext);

  // handle component mount
  useEffect(() => {
    if (!state.accountDataRetrieved || !state.anim3dInitialized) {
      props.initializeA3DService();
    } else {
      if (props.LOADING.show) {
        props.setLOADING({ ...props.LOADING, ...{ show: false } });
      }
    }
  }, [state.accountDataRetrieved, state.anim3dInitialized]);

  const handleGoTo3DModels = () => {
    props.history.push(Enums.routes.Anim3dModelManage);
  };
  const handleGoToLibrary = () => {
    props.history.push(Enums.routes.Anim3dLibrary);
  };
  const handleGoToNewAnim = () => {
    if (state.jobsData && !state.jobsData.length) {
      // Route to the guided FTE if 0 jobs in account
      props.history.push(Enums.routes.Anim3dGuidedFTE);
    } else {
      props.history.push(Enums.routes.Anim3dCreate);
    }
  };

  const subscriptionData = () => {
    const data = {
      name: state.subscriptionInfo?.name,
      tagClass: "",
    };

    if (state.subscriptionInfo.isEnterpriseUser) {
      data.name =
        Enums.accountPlansInfo[Enums.accountPlansInfo.length - 1].name;
      // use the last tag class in the accountPlans array for Enterprise users
      data.tagClass =
        Enums.accountPlansInfo[Enums.accountPlansInfo.length - 1].tagClass;
    } else {
      if (data?.name) {
        const findAccountPlansInfo = Enums.accountPlansInfo.find(
          (item) => item.name === data.name
        );
        data.name = findAccountPlansInfo.name;
        data.tagClass = findAccountPlansInfo.tagClass;
      } else {
        // default tag class for Freemium plans
        data.tagClass = Enums.accountPlansInfo[0].tagClass;
      }
    }

    return data;
  };

  const subscription = subscriptionData();

  const AccountDetailTile = () => {
    const currCycleStartDate = state.currentBillCycleInfo.currCycleStartDate;
    const currCycleEndDate = state.currentBillCycleInfo.currCycleEndDate;
    const cancel_at_period_end = state.subscriptionInfo.cancel_at_period_end;
    const cancel_at = cancel_at_period_end
      ? Enums.dateConverter(state.subscriptionInfo.cancel_at, true)
      : "";
    const cancel_at_curr_cycle_end =
      cancel_at_period_end && cancel_at.localeCompare(currCycleEndDate) == 0;

    const isEnterpriseUser = state.subscriptionInfo.isEnterpriseUser;
    const isStudioUser =
      state.subscriptionInfo.name ===
      Enums.accountPlansInfo[Enums.accountPlansInfo.length - 2].name;
    const plan = state.subscriptionInfo.name;

    const remainingCredits = state.currentBillCycleInfo.remainingRounded;

    const animTimeTip = Enums.subscriptionAnimTimeTip(isStudioUser, plan);

    const AnimationCreditsTips = () => {
      return (
        <>
          {animTimeTip}
          {Enums.animCreditsDescr}
        </>
      );
    };
    const animationLabelingCreditsTip = Enums.animationLabelingCreditsTip;
    const animationCorrectionCreditsTip = Enums.animationCorrectionCreditsTip;

    const labelingCredits = state.currentBillCycleInfo.minuteBalanceLabeling;
    const correctionCredits =
      state.currentBillCycleInfo.minuteBalanceCorrection;

    const isUnlimited = state.accountTotals.max_rerun === -1;

    let rerunRemainingStr = "";
    let remainingReruns = "";
    let rerunTipText = null;
    const subscriptionMonthText = isEnterpriseUser
      ? "for the current period "
      : "for the current subscription month ";

    if (isUnlimited) {
      rerunRemainingStr = "Unlimited";
      rerunTipText = `<div className="block">You have <span class="has-text-weight-semibold"> ${remainingReruns} </span> reruns available ${subscriptionMonthText}which ends on <span class="has-text-weight-semibold">${currCycleEndDate}</span>. <div class="notification mt-3 is-info is-light">Animation reruns can be used to improve the quality of your existing animations without using any account animation credits</div></div>`;
    } else {
      rerunRemainingStr = `${
        state.accountTotals.max_rerun - state.accountTotals.rerun_count
      } / ${state.accountTotals.max_rerun}`;
      remainingReruns = `${
        state.accountTotals.max_rerun - state.accountTotals.rerun_count
      }`;
      rerunTipText = `<div className="block">You have <span class="has-text-weight-semibold"> ${remainingReruns} </span> reruns available ${subscriptionMonthText}which ends on <span class="has-text-weight-semibold">${currCycleEndDate}</span>. <div class="notification mt-3 is-info is-light">Animation reruns can be used to improve the quality of your existing animations without using any account animation credits</div></div>`;
    }

    return (
      <div className="accountDetail tile is-child box dm-brand dm-white bShadow">
        <div className="is-flex">
          <div className="title is-4 dm-white m-0">About Your Plan</div>
          <div className="ml-4">
            <div className={subscription.tagClass}>{subscription.name}</div>
          </div>
        </div>
        <div className="currCycle">
          {cancel_at_curr_cycle_end ? (
            <p className="pt-0 has-text-danger has-text-left">
              Cancels on {cancel_at}
            </p>
          ) : (
            <p className="pt-0 has-text-left has-text-white">
              {currCycleStartDate} - {currCycleEndDate}
            </p>
          )}
        </div>
        <div className="accountDetail-credits title is-5 dm-brand-2-font bottom-border is-inline-block py-2 mb-0">
          <span className="title is-3 dm-brand-2-font">
            {isStudioUser ? "Unlimited" : Math.round(remainingCredits)}
          </span>
          <span className="ml-2">Animation Credits</span>
          <DMToolTip
            text={AnimationCreditsTips()}
            tipId="animation-credits"
            icon="fas fa-info-circle fa-md"
            cursor="cursor-pt"
          />
        </div>
        <div className="accountDetail-credits is-flex is-align-items-center title is-6 dm-white mt-2 mb-0">
          <div className="is-flex is-flex-direction-column m-0">
            <div className="m-0 credits">{Math.trunc(labelingCredits)}</div>
            <div className="m-0 credits">{Math.trunc(correctionCredits)}</div>
          </div>
          <div className="is-flex is-flex-direction-column ml-2">
            <div className="is-flex ml-0">
              <div>FREE Animation Labeling Credits</div>
              <DMToolTip
                text={animationLabelingCreditsTip}
                tipId="labeling-credits"
                icon="fas fa-info-circle fa-md"
                cursor="cursor-pt"
              />
            </div>
            <div className="is-flex">
              <div>FREE Animation Correction Credits</div>
              <DMToolTip
                text={animationCorrectionCreditsTip}
                tipId="correction-credits"
                icon="fas fa-info-circle fa-md"
                cursor="cursor-pt"
              />
            </div>
          </div>
        </div>
        <div className="accountDetail-credits title is-5 dm-brand-2-font bottom-border is-inline-block py-2 mb-0 mt-2">
          <span>Reruns</span>
          <DMToolTip
            text={rerunTipText}
            isTipHtml={true}
            tipId="Reruns-tip"
            icon="fas fa-info-circle fa-md"
            cursor="cursor-pt"
          />
        </div>
        <div className="accountDetail-credits is-flex is-align-items-center title is-6 dm-white mt-2 mb-0">
          {isUnlimited ? (
            <div className="m-0">{rerunRemainingStr}</div>
          ) : (
            <>
              <div className="m-0 credits">{rerunRemainingStr}</div>
              <div className="ml-2 is-flex">remaining</div>
            </>
          )}
        </div>
      </div>
    );
  };

  const NewAnimationTile = () => {
    return (
      <div
        className="NewAnimationTile tile is-child box dm-brand dm-white bShadow new-anim-grad has-text-centered"
        onClick={handleGoToNewAnim}
      >
        <p className="icon-text">
          <span className="icon dm-brand-font is-medium mr-2">
            <i className="far fa-play-circle fa-2x"></i>
          </span>
          <span className="title is-5 mr-4 dm-brand-font">{textNewAnim}</span>
        </p>
      </div>
    );
  };

  const LibraryCardTile = () => {
    const jobsDataLength = state.jobsData.length;

    return (
      <div className="LibraryCardTile tile is-parent box dm-brand dm-white bShadow m-0">
        <div className="py-2">
          <img src={imgNewAnim} alt={`${jobsDataLength} Animations`} />
          <div className="title is-5 m-0 dm-white p-2">
            {jobsDataLength} Animations
          </div>
          <div
            className="block button fullwidth is-info is-outlined is-normal"
            onClick={handleGoToLibrary}
          >
            View Library
          </div>
        </div>
      </div>
    );
  };

  const ModelCardTile = () => {
    const charactersListLength = state.accountTotals.charactersList.length;
    const characterLimit = state.accountTotals.characterLimit;

    return (
      <div className="ModelCardTile tile is-parent box dm-brand dm-white bShadow m-0 mr-4">
        <div className="py-2">
          <img
            src={img3DModels}
            alt={`${charactersListLength} / ${characterLimit} 3D Models`}
          />
          <div className="title is-5 m-0 dm-white p-2">
            {charactersListLength + "/" + characterLimit} 3D Models
          </div>
          <div
            className="button fullwidth is-info is-outlined is-normal"
            onClick={handleGoTo3DModels}
          >
            View Characters
          </div>
        </div>
      </div>
    );
  };

  const newList = [
    {
      title: `SayMotion™ by DeepMotion: A Leap into the Future of 3D Animation Through the Power of Words`,
      link: 'https://www.deepmotion.com/post/deepmotion-launches-saymotion-open-beta',
      img: 'https://storage.googleapis.com/www.deepmotion.com/assets/6455380df2986348b83d59ff/65f9acbc74c15d190d558003_SayMotion_Launch_Blog_large.png'
    },
    {
      title:
        'SayMotion™ Open Beta Guide: Getting Started With Text-to-3D Animation',
      link: 'https://www.deepmotion.com/post/guide-to-saymotion--open-beta-unleash-the-power-of-text-to-3d-animation',
      img: 'https://storage.googleapis.com/www.deepmotion.com/assets/6455380df2986348b83d59ff/65f9aca3312fca3d2ea1c88f_SayMotion_NewUserGuide_Blog_Large.png'
    }
  ];

  const AnnouncementsTile = () => {
    return (
      <div className="AnnouncementsTile tile is-child box dm-brand dm-white bShadow">
        <div className="title is-5 dm-white">Announcements</div>
        {newList.map((item, index) => {
          return (
            <div
              className="box dm-brand bShadow brand p-0"
              key={"Announcements_" + index}
              style={{
                borderRadius: "5px",
                overflow: "hidden",
                cursor: "pointer",
              }}
              onClick={() => {
                window.open(item.link);
              }}
            >
              <img className="img" src={item.img} alt={item.title} />
              <div className="subtitle is-6 dm-white p-2">{item.title}</div>
            </div>
          );
        })}
      </div>
    );
  };

  const buildHomePage = () => {
    return (
      <div className="section m-0 p-0">
        <div id="anim-fadein" className="column">
          <div className="ml-6 mr-6 my-1">
            <div className="homePage p-4">
              <div className="tile">
                <div className="tile is-vertical is-7 m-0 mr-4">
                  <AccountDetailTile />
                  <NewAnimationTile />
                  <div className="tile m-0">
                    <ModelCardTile />
                    <LibraryCardTile />
                  </div>
                </div>
                <AnnouncementsTile />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // using react helmet to set page title and metadata
  function seoMetaData_EN() {
    return (
      <Helmet>
        <meta charSet="utf-8" />
        <title> {docTitle} </title>
        <meta name="description" content={metaDesc} />
      </Helmet>
    );
  }
  // set page title and meta data
  const helmetData = seoMetaData_EN();

  return (
    <React.Fragment>
      <AnimVersionPanel pageTitle="Home" />
      {props.LOADING.show ? (
        <LoadingScreen />
      ) : (
        <HelmetProvider>
          <div className="section m-0 p-0">
            {helmetData}
            {buildHomePage()}
          </div>
        </HelmetProvider>
      )}
    </React.Fragment>
  );
}
