import React, { useState, useContext, useEffect } from "react";
import { AppStateContext } from '../../AppStateContext'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { submitForm } from '../api/apiRequests';
import "../../styles/cancellation-survey.scss";
import * as Enums from '../common/enums';
import axios from 'axios'
const qs = require('querystring')

const question = [
  {
    type: "select",
    external_id: "satisfaction",
    title: "Overall, how satisfied are you with Animate 3D?",
    answer: [
      "Very Satisfied",
      "Satisfied",
      "Neutral",
      "Unsatisfied",
      "Very Unsatisfied"
    ]
  },
  {
    type: "select",
    external_id: "useagain",
    title: "Would you use Animate 3D again in the future?",
    answer: [
      "Definitely",
      "Probably",
      "Not Sure",
      "Probably Not",
      "Definitely Not"
    ]
  },
  {
    type: "select",
    external_id: "reason",
    title: "What was the primary reason for canceling your subscription?",
    answer: [
      "I finished my project",
      "It didn’t meet my needs",
      "Quality was less than expected",
      "Customer service was less than expected"
    ]
  },
  {
    type: "select",
    external_id: "serviceuselength",
    title: "How many estimated months per year do you need Animate 3D service?",
    answer: [
      "1-3 month",
      "4-6 month",
      "7-9 month",
      "10-12 month",
      "Only a one time use",
    ]
  },
  {
    type: "write",
    external_id: "comment",
    title: "What could we do to improve the service?"
  }
]

// form strings
const submitButton        = "Submit"
const textareaPlaceholder = "Thank you for your support."
const surveyTitle         = "Cancellation Survey"
const surveyDetail        = "Thank you for your support of DeepMotion! We're sorry to see you go and are interested in learning more about why you decided to cancel your subscription. Your responses will be kept confidential and will not be used for any purpose other than research conducted by our company."


function ModalCancellationSurvey({
    showCancellationSurvey,
    setShowCancellationSurvey
  }) {

  const appStateContext = useContext(AppStateContext)

  const [fieldsValue, setFieldsValue] = useState([])
  const [fieldsImproveValue, setFieldsImproveValue] = useState("")
  const [fieldsMissing, setFieldsMissing] = useState(true)

  //---------------------------------------------------------------
  const appId         = process.env.REACT_APP_PODIO_SURVEY_AID
  const appToken      = process.env.REACT_APP_PODIO_SURVEY_APT
  const clientId      = process.env.REACT_APP_PODIO_CID
  const clientSecret  = process.env.REACT_APP_PODIO_CLS
  const redirectUri   = process.env.REACT_APP_PODIO_RED
  const csrfState     = process.env.REACT_APP_PODIO_CSR
  const authUrl       = process.env.REACT_APP_PODIO_AUTH_URL
  const restApiUrl    = process.env.REACT_APP_PODIO_BASE_URL + `/${appId}`
  //---------------------------------------------------------------

    /******************************************************************
   ******************************************************************
   * submitCancellationSurveyForm()
   *
   * Submits the sign up for using the Podio API
   ******************************************************************
   ******************************************************************/
   const submitCancellationSurveyPodio = async () => {

    //TODO: DEBUG!!!
    // Two API requests needed to submit form through Podio API:
    // 1. authenticate with auth server to get auth token
    // 2. create new record in Podio cloud signup app
    if (fieldsMissing) return


    // build the request body needed for oauth authentication
    let authRequestBody = {
      grant_type: 'app',
      app_id: appId,
      app_token: appToken,
      client_id: clientId,
      redirect_uri: redirectUri,
      client_secret: clientSecret,
      state: csrfState
    }

    // set axios request headers for token request:
    const authRequestHeaders = {
      headers: {
        // Podio OAuth API requires the Content-Type header to be
        // set to application/x-www-form-urlencoded
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }

    /*******************************************************
     *******************************************************
      * Podio API #1 - Get access token
      *******************************************************
      *******************************************************/
    const res = await axios.post(authUrl, qs.stringify(authRequestBody), authRequestHeaders).catch(() => {
      //TODO: Handle error
      console.log(`Error - could not authenticate with server.`)
      return null
    })

    if (res == null)
      return

    // If successfully authenticated, make POST request to Podio
    // API to create a new lead record...
    let aToken = res.data.access_token
    // let rToken = res.data.refresh_token
    console.log(`Access token received`)
    // add auth token to request header for next API request
    const apiRequestHeaders = {
      headers: {
        // Podio createItem() API requires a JSON request body...
        'Content-Type': 'application/json',
        'Authorization': `OAuth2 ${aToken}`
      }
    }

    /*******************************************************
     *******************************************************
      * Podio API #2 - Create new item in cloud signup app
      *******************************************************
      *******************************************************/

    // configure API request body for Create Item call
    const createItemRequestBody = createRequestBody()

    try {
      //==> PODIO POST API - Create New Item:
      await axios.post(restApiUrl, JSON.stringify(createItemRequestBody), apiRequestHeaders)
      // send user feedback confirmation email if feedback has successfully been entered into podio
      await submitCancellationSurveyForm(createItemRequestBody)
      console.log(`Form submitted successfully`)
    }
    catch (error) {
      // Create Item API returned error
      //TODO: Handle error
      console.log(`Error encountered while submitting feedback...\n`)
      console.log(`${JSON.stringify(error)}`)
      setShowCancellationSurvey(false)
    }
  }

  const submitCancellationSurveyForm = async (createItemRequestBody = {}) => {

    const data = {
      bizType: Enums.formType?.survey_unsubscription,
      bizId: appStateContext.state.subscriptionInfo.canceled_at,
      content: createItemRequestBody ? createItemRequestBody : createItemRequestBody.fields
    }

    await submitForm(data).catch((error) => {
      console.log(`Error encountered sending user confirmation email (feedback was logged) -- ${error}`)
    })

    //=============================================================
    // --> ON SUCCESSFUL SUBMIT REDIRECT BACK TO ANIMATE 3D PRODUCT
    // PAGE WITH URL QUERY PARAM TO SHOW SUCCESS MODAL
    //=============================================================

    setShowCancellationSurvey(false)
  }

  const createRequestBody = () => {
    let fields = [...fieldsValue]
    let fieldsImprove = {
      external_id: question[4].external_id,
      values: fieldsImproveValue
    }
    fieldsImproveValue && fields.push(fieldsImprove)

    fields.push({
      external_id: 'email',
      values: [{type: "work", value: appStateContext.state.email}]
    })

    let createItemRequestBody = {
      external_id: "Cancellation Survey",
      fields: fields
    }

    return createItemRequestBody
  }

  useEffect(() => {
    if (fieldsValue.length > 0 || fieldsImproveValue) {
      setFieldsMissing(false)
    }else {
      setFieldsMissing(true)
    }
  }, [fieldsImproveValue, fieldsValue])

  const textareaOnChange = (e) => {
    setFieldsImproveValue(e.target.value)
  }

  const handleChange = (external_id, i) => {
    const fields = {
      external_id: external_id,
      values: i + 1
    }

    let data = [...fieldsValue]

    let index = data.findIndex((e) => e.external_id === fields.external_id)

    if (index !== -1) {
      data[index].values = i + 1
    } else {
      data.push(fields)
    }

    setFieldsValue(data)
  }

  const children =
    <>
    <div className="header">
      {/* TBD:Is the title inside the dialog redundant? */}
      {/*<h2 className="title is-3 m-0">{surveyTitle}</h2>*/}
      <div className="detail">{surveyDetail}</div>
    </div>
      {
        question && question.map((e, i) => (
          <div className="question" key={i}>
            <div className="subtitle is-5 my-2 mt-5">{e.title}</div>
            {
              e.type === "select" ?
                <div className="control select-content">
                  {
                    e.answer && e.answer.map((answer, index) => (
                      <label className="radio item my-2 ml-0" key={"answer" + index}>
                        <input type="radio" name={"answer" + i} value={answer} onChange={() => handleChange(e.external_id, index)} />
                        <span>{answer}</span>
                      </label>
                    ))
                  }
                </div>
                :
                <div className="write-content">
                  <textarea className="textarea" placeholder={textareaPlaceholder} onChange={textareaOnChange} />
                </div>
            }
          </div>
        ))
      }
    </>

  return (
    <>
      <Modal
        className="cancellation-modal"
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showCancellationSurvey}
        onHide={submitCancellationSurveyForm}
        animation={true}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Cancellation Survey</Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>
        <Button
            variant="primary"
            className={fieldsMissing ? "" : "active"}
            onClick={() => submitCancellationSurveyPodio()}
            disabled={fieldsMissing}
          >
            {submitButton}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ModalCancellationSurvey
