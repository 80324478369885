import React from 'react'
import { useHistory } from "react-router-dom"
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { addNewModelToAccount } from '../../../api/apiRequests'
import CharacterSwiper from './CharacterSwiper'
// import ParticlesBackground from '../../../common/ParticlesBackground'
import AnimVersionPanel from '../../../common/AnimVersionPanel'
import InfoDialog from '../../../common/InfoDialog'
import DragZone from '../DragDrop'
import * as Enums from '../../../common/enums'
// const qs = require('querystring')

// strings and other global data:
var tmpFilename = ""
const uiStates = Object.freeze({
    "initial":                0, 
    "fileSelected":           1,
    "uploadInProgress":       2, 
    "uploadDoneSuccess":      3, 
    "uploadDoneFailure":      4
  })
const tabStatus = Object.freeze({
  none:                     "",
  active:                   "is-active", 
  hidden:                   "is-hidden"
})
const selectCharacterBtn = "Select Model"
const removeCharacterBtn = "Remove Model"
const tabAvailableModels = "Available Characters"
const tabUploadModel = "Upload Character"
const iconCustomModels = "fas fa-users fa-lg"
const iconAddModel = "fas fa-user-plus fa-lg"
const uploadFormatsTitle = "Supported formats:"
const uploadFormatsDetails = "FBX, GLB, VRM"
const uploadBtnText = "Upload"
const cancelBtnText = "Discard"
const inProgressTitle = "Uploading 3D model, please wait..."
const requirementsTitle = "3D Model Requirements"
const uploadsNotAllowedText1 = "You have reached the maximum character limit allowed for your plan"
const uploadsNotAllowedText2 = "To add new characters please remove one or more existing characters from your account."

const characterIcon = "fas fa-user-plus fa-5x"
const warningIcon = "fas fa-exclamation-triangle fa-3x"

// strings 
// requirements/recommendations for Custom Character feature:
const recommendationsList = [
  {title:'Pose: ',text:"The model’s bind pose must be a T-pose when you export your model"},
  {title:'Joint Names: ',text:"All joint names must be unique and without any spaces"},
  {title:'Model Height: ',text:"If using Physics Filter your character must be sized within typical human proportions with a height of 1-2 meters."},
  {title:'Clean Model: ',text:"Include only the character mesh and rigging information in your model file, without extra information (i.e. animations and environment models)"},
  {title:'Origin Plane: ',text:"The feet of the character must be placed on the origin plane"},
  {title:'Joint Scaling: ',text:"Avoid using scale on the joints, if you need to scale up the whole skeleton please apply it at a root node"},
  {title:'Root Joint: ',text:"Only models with one (1) root joint are supported"},
  {title:'Single Character: ',text:"Only one (1) character must be in the upload file"}
]

// SEO 
const docTitle = "Upload Character | DEEPMOTION"
const metaDesc = "Upload your custom character with T-pose as the first frame."

//+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// [FUNCTIONAL COMPONENT]
// Custom Character Upload
//+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
export default function CharacterUpload(props) {

  // browser history
  let history = useHistory()

  //************************************************************ 
  // Custom Character page state variables
  //************************************************************
  const [uiState, setUiState] = React.useState(uiStates.initial)
  const [fileName, setFileName] = React.useState(null)
  const [fileSize, setFileSize] = React.useState(null)
  const [selectedFile, setSelectedFile] = React.useState(null)
  const [characterIndex, setCharacterIndex] = React.useState(0)
  const [charactersList, setCharactersList] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const [currentDisplayTab, setCurrentDisplayTab] = React.useState([tabStatus.active, tabStatus.none])
  const [currentDisplayTabDiv, setCurrentDisplayTabDiv] = React.useState([tabStatus.active, tabStatus.hidden])
  const [modelImageLoaded, setModelImageLoaded] = React.useState(false)
  //---------------------------------------------------------------

  //---------------
  // using react helmet to set page title and metadata
  function seoMetaData_EN() {
    return (
      <Helmet>
        <meta charSet="utf-8" />
        <title> {docTitle} </title>
        <meta name="description" content= {metaDesc} />
      </Helmet>
    )
  }

  ////////////////////////////////////////////////////////////////////
  // Handles on upload change for custom model selection
  ////////////////////////////////////////////////////////////////////
  function uploadOnchange(array) {
    
    const fName = array[0].name
    const fSize = array[0].size
    const dialogData = {...props.appStateContext.state.dialogInfo, videoFileName: fName}
    // use regex check on filename to only allow .fbx, glb, gltf, vrm extensions
    // const regex = RegExp(/\.(fbx|glb|gltf|vrm)$/i)
    const regex = RegExp(/\.(fbx|glb|vrm)$/i)
    if(!regex.test(fName)) {
      console.log(`Error: File name "${fName}" is invalid, please rename your file locally and try again.`);
      // resetState()
      tmpFilename = fName
      props.appStateContext.dispatch({...{dialogInfo: dialogData}, confirmDialogId: Enums.confirmDialog.inputFileNameInvalid})
      return
    }
    if(fName.length > Enums.MAX_FILENAME_LENGTH) {
      console.log(`Error: File name "${fName}" exceeds max character limit of ${Enums.MAX_FILENAME_LENGTH}, please rename your file locally and try again.`);
      tmpFilename = fName
      props.appStateContext.dispatch({...{dialogInfo: dialogData}, confirmDialogId: Enums.confirmDialog.inputFileNameInvalid})
      return
    }
    // limit filename characters to standard Unicode UTF-8
    const santized = Enums.removeIllegalOrReservedCharactersFromFileName(fName)
    if( Enums.containsNonAsciiCharacters(fName) || (santized.length !== fName.length) ) {
      tmpFilename = fName
      console.log(`Error: File fName "${fName}" contains invalid characters or exceeds max length of ${Enums.MAX_FILENAME_LENGTH}. Please rename your file locally and try again.`)
      props.appStateContext.dispatch({...{dialogInfo: dialogData}, confirmDialogId: Enums.confirmDialog.inputFileNameInvalid})
      return
    }

    //TODO: Add a MAX upload size check! 
    // if( size > process.env.REACT_APP_MAX_CLIP_SIZE ) {
    //   tmpSize = formatSizeUnits(size, 2)
    //   console.log(`Error: Max input clip size of ${formatSizeUnits(process.env.REACT_APP_MAX_CLIP_SIZE, 2)} exceeded. Input clip size was `, tmpSize, ".\nAborting job request...")
    //   resetState()
    // 
    //   props.appStateContext.dispatch({confirmDialogId: Enums.confirmDialog.inputFileTooBig})
    //   return
    // }
    
    // update state variables once user has selected a file
    setFileName(fName)
    setFileSize(fSize)
    setSelectedFile(array[0])
    let tmpStateObj = JSON.parse(JSON.stringify(Enums.customModelObj))
    tmpStateObj.name = fName
    tmpStateObj.size = fSize
    // update the custom model state info 
    props.appStateContext.dispatch({
      ...{animJobSettings: props.appStateContext.state.animJobSettings}, 
      ...{animJobSettings: {'customModelInfo': tmpStateObj}}
    })
    // props.updateAnimJobSettings({'customModelInfo': tmpStateObj})
  }

  ////////////////////////////////////////////////////////////////////
  // Calls Upload Model DMBT API to upload a custom character to the 
  // backend Animate 3D service
  //
  // @param retry : if we should retry on auth error encountered 
  ////////////////////////////////////////////////////////////////////
  async function uploadCustomModelToBackend() {

    // first update state so UI can update accordingly
    setUiState(uiStates.uploadInProgress)

    const modelParams = {
      name: fileName,
      modelExt: Enums.getFileExtension(fileName).toLowerCase() // remove default .fbx since we prevent uploading unaccepted files in the first place
    }
    const modelName = props.animJobSettings.customModelInfo.name
    let res = await addNewModelToAccount('', modelName, true, selectedFile, modelParams, false).catch((error) => {
      if (error.type == 'cloud') {
        // Problem saving character assets to the cloud
        props.handleHttpError(error, "Error Uploading 3D Model", false)
      } else if (error.type == 'uploading') {
        // Problem retrieving signed model URI for uploading
        props.handleHttpError(error, "", true)
      } else if (error.type == 'backend') {
        // Problem saving character assets to the backend
        setUiState(uiStates.initial)
        if(error.error.response.status === Enums.eCodes.InternalServerError) {          
          props.setErrorDialogInfo(
            true, 
            Enums.eCodes.InternalServerError, 
            Enums.customErrors[error.error.response.data.error], 
            error.error.response.data.message, () => {
              return
          })
        }
      }
      return null
    })

    if (res == null)
      return

    if( props.displayAsTab ) {
      props.modelUploadedCallback()
    }
    else {
      // otherwise uploaded model during create flow, re-route 
      // the user to the anim create page
      history.push(Enums.routes.Anim3dCreate)
    }
  }

  ////////////////////////////////////////////////////////////////////
  // Builds info / requirements area for uploading a custom model
  ////////////////////////////////////////////////////////////////////
  function buildUploadInfoArea() { 

    let leftSide = []
    let rightSide = []
    if( recommendationsList ) {
      for( let i = 0; i < recommendationsList.length; i++ ) {
        if( i < recommendationsList.length/2 ) {
          leftSide.push(
            <p className="subtitle" key={i}><strong className="dm-brand-font has-text-weight-semibold">{recommendationsList[i].title}</strong>{recommendationsList[i].text}</p>
          )
        }
        else {
          rightSide.push(
            <p className="subtitle" key={i}><strong className="dm-brand-font has-text-weight-semibold">{recommendationsList[i].title}</strong>{recommendationsList[i].text}</p>
          )
        }
      }
    }

    return (  
      <div className="columns rounded-corners">
        <div className="column is-11 has-background-warning-light mb-6 p-5">
          <div className="columns mgBot-20">
            <div className="column has-text-centered">
              <h3 className="title is-4 has-text-danger">{requirementsTitle}</h3>
            </div>
          </div>
          <div className="columns ">
            <div className="column tips-col has-text-left">
              <div>
                {leftSide}
              </div>  
            </div>
            <div className="column tips-col has-text-left">
              <div>
                {rightSide}
              </div>
            </div>
          </div>
          <div className="column has-text-centered">
            <div className={"button action-btn glow-on-hover btn-shadow"}
                onClick={()=>window.open("https://blog.deepmotion.com/2020/11/19/animate-3d-custom-characters/", '_blank')} >
              Refer to Custom Character FAQ
            </div>
          </div>
        </div>
      </div>
    )
  }

  ////////////////////////////////////////////////////////////////////
  // Builds File Upload in Progress UI
  ////////////////////////////////////////////////////////////////////
  function buildFileUploadInProgress() {
    return (
      <div className="column rounded-corners-bottom action-panel has-text-centered vcenter-flex">
        <div className="">
          <div className="columns">
            <div className="column">
              <h4 className="subtitle is-4 dm-brand-font">{inProgressTitle}</h4>
            </div>
          </div>
          <div className="columns">
            <div className="column hcenter is-half">
              <progress className="progress indeterminate is-medium loading-bar-alt" max="100"></progress>
            </div>
          </div>    
        </div>
      </div>
    )
  }

  ////////////////////////////////////////////////////////////////////
  // Displays the initial drag and drop area for uploading the model
  ////////////////////////////////////////////////////////////////////
  function buildDragAndDropArea() {
    if( !props.accountTotals ) {
      return
    }
    let uploadDiv = []
    if( props.accountTotals.charactersList.length < props.accountTotals.characterLimit ) {
      uploadDiv.push(
        <div key="drag-zone" className="columns rounded-corners-top rounded-corners-bottom action-panel has-background-link-light">
          <div className="column is-7 has-text-centered">
            <DragZone onFilesAdded={uploadOnchange} animJobSettings={props.animJobSettings} type="custom-character" />
          </div>
          <div className="column has-text-centered">
            <p className="subtitle is-5 dm-brand-font">{uploadFormatsTitle}</p>
            <p className="title is-4 dm-brand-font">{uploadFormatsDetails}</p>
          </div>
        </div>
      )
    }
    else {
      uploadDiv.push(
        <div key="model-limit-msg">
          {buildUploadsNotAllowedScreen()}
        </div>
      )
    }
    return (
      <React.Fragment>
        {uploadDiv}
      </React.Fragment>
    )
  }

  ////////////////////////////////////////////////////////////////////
  // Displays the file selected UI (ie before user uploads the file)
  ////////////////////////////////////////////////////////////////////
  function buildFileSelectedArea() {
    return (
      <div className="columns rounded-corners-top rounded-corners-bottom action-panel has-background-link-light ">
        <div className="column has-text-left">
          <div className="columns">
            <div className="column has-text-centered">
              <span className="icon dm-brand-font"> 
                <i className={characterIcon}></i>
              </span>
            </div>
            <div className="column has-text-centered">
              <div className="columns">
                <div className="column has-text-centered">
                  <p className="title is-4 dm-brand-font">{fileName}</p>
                </div>
              </div>
              <div className="columns">
                <div className="column has-text-centered">
                  <p className="title is-5 dm-brand-font">{Enums.formatSizeUnits(fileSize)}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className="column has-text-centered">
          <div className="columns">
            <div className="column is-two-thirds has-text-centered ">
              <button onClick={()=>uploadCustomModelToBackend(true)} className="button is-medium action-btn glow-on-hover fullwidth">{uploadBtnText}</button>
            </div>
          </div>
          <div className="columns">
            <div className="column is-two-thirds has-text-centered ">
              <button onClick={()=>resetScreen()} className="button is-medium is-info is-inverted is-light fullwidth">{cancelBtnText}</button>
            </div>
          </div>
        </div>

      </div>
    )
  }

  /******************************************************************* 
   * Builds the standard vs custom character select screen
   *******************************************************************/
  function buildUploadCharacterScreen() {

    let displayDiv = null
    // dynamically build the page based on the current
    // functional component state vars
    switch( uiState ) {
      case uiStates.initial:
        displayDiv = buildDragAndDropArea()
        break
      case uiStates.fileSelected:
        displayDiv = buildFileSelectedArea()
        break
      case uiStates.uploadInProgress:
        displayDiv = buildFileUploadInProgress()
        break
      case uiStates.uploadDoneSuccess:
        // currently routing to next page on success
        break
      case uiStates.uploadDoneFailure:
        //TODO: 
        break
      default:
        displayDiv = buildDragAndDropArea()
        break
    }

    // return the rendered component
    return (
      <div>
        <div className="columns has-text-centered mb-0" >
          <div className="column mt-0">
            <div className="section my-2 p-0">
              <div className="columns">
                <div className="column is-11 mb-6 rounded-corners-top rounded-corners-bottom bShadow has-background-link-light">
                  
                  {displayDiv}

                </div>
              </div>
            </div>
          </div>
        </div>
        {buildUploadInfoArea()}
      </div>  
    )
  }

  function buildUploadsNotAllowedScreen() {
    return (
      <div className="columns notification has-background-danger-light">
        <div className="column is-10 has-text-left">
          <div className="columns">
            <div className="column is-2 has-text-centered">
              <span className="icon has-text-danger">
                <i className={warningIcon}></i>
              </span>
            </div>
            <div className="column has-text-left">
              <h3 className="title is-4 has-text-danger">
                {uploadsNotAllowedText1} ({props.accountTotals.charactersList.length}/{props.accountTotals.characterLimit}). {uploadsNotAllowedText2}
              </h3>
            </div>
          </div>
        </div>
      </div>
    )
  }

  // Reset the screen back to initial state where user can drag and drop
  // their model or select from the button
  function resetScreen() {
    // update state hooks to reset screen
    setSelectedFile(null)
    setUiState(uiStates.initial)
  }

  // switch the active UI tab 
  function switchTabs(tabState) {
    if( tabState === tabStatus.active ) {
      setCurrentDisplayTab([tabStatus.active, tabStatus.none])
      setCurrentDisplayTabDiv([tabStatus.active, tabStatus.hidden])
    }
    else {
      setCurrentDisplayTab([tabStatus.none, tabStatus.active])
      setCurrentDisplayTabDiv([tabStatus.hidden, tabStatus.active])
    }
  }

  ////////////////////////////////////////////////////////////////////
  // parses the data returned from the list models request
  ////////////////////////////////////////////////////////////////////
  function sortUploadedCharacterListByDate(data) {
    let outputList = []
    data.forEach( item => {
      const d = new Date(item.ctime)
      // convert date to human readable format
      const addDate = [
        d.getMonth()+1,
        d.getDate(),
        d.getFullYear()].join('/')+' '+
      [ Enums.addZero(d.getHours()),
        Enums.addZero(d.getMinutes()),
        Enums.addZero(d.getSeconds())].join(':')

      const modelObject = {
        id: item.id,
        name: item.name,
        thumb: item.thumb,
        ctime: item.ctime,
        date: addDate
      }
      outputList.push(modelObject)
    })
    outputList.sort(function(a, b) {
      return b.ctime - a.ctime
    })
    setCharactersList(outputList)
    setLoading(false)
  }

  ////////////////////////////////////////////////////////////////////
  // set custom character then navigate to the create anim page
  ////////////////////////////////////////////////////////////////////
  function selectCustomCharacter() {
    let tmpStateObj = props.animJobSettings.customModelInfo
    tmpStateObj.id = charactersList[characterIndex].id
    tmpStateObj.name = charactersList[characterIndex].name
    props.updateAnimJobSettings({'customModelInfo': tmpStateObj}, () => {
      history.push(Enums.routes.Anim3dCreate)
    })
  }

  ////////////////////////////////////////////////////////////////////
  // Shows dialog for custom character removal
  //
  // @param charId : the modelId of the custom character to remove
  ////////////////////////////////////////////////////////////////////
  function handleRemoveCustomCharacter() {
    let tmpStateObj = props.appStateContext.state.animJobSettings.customModelInfo
    tmpStateObj.modelId = charactersList[characterIndex].id
    tmpStateObj.name = charactersList[characterIndex].name
    tmpStateObj.clbFunc = removeCharacterCallback
    props.appStateContext.dispatch({dialogInfo: tmpStateObj, confirmDialogId: Enums.confirmDialog.removeCustChar})
  }

  function removeCharacterCallback() {
    props.getAccountCustomCharacters(true)
    .then(() => {
      setSelectedFile(null)
      setUiState(uiStates.initial)
      setCharactersList(null)
    })
    .catch( error => {
      console.error(`Error encountered refreshing account characters list:\n${error}`)
    })
  }

  // displays loading UI while retrieving custom characters info 
  function showLoadingUI() {
    return (
      <div className="section">
        <div className="columns is-vcentered">
          <div className="column is-one-third has-text-centered is-vcentered">  
            <p className="title is-4">Looking for characters...</p>
            <progress className="progress indeterminate is-medium loading-bar" max="100"></progress>
          </div>
        </div>
      </div>
    )
  }

  //---------------------------------------------------------
  // React useEffect() hook for when form state changes:
  //---------------------------------------------------------
  React.useEffect(() => {

    if( selectedFile && (uiState === uiStates.initial) ) {
      setUiState(uiStates.fileSelected)
    }

    if( !props.displayAsTab ) {
      // we call the characters list API if we don't have the character's 
      // data yet and no current request in progress
      if( !charactersList ) {
        // retrieve list of account characters...
        setLoading(true)
        props.getAccountCustomCharacters(true)
        .then( customCharactersResp => {
          sortUploadedCharacterListByDate(customCharactersResp.data.list)
          setLoading(false)
        })
        .catch( error => {
          console.error(`Error encountered while refreshing account characters list:\n${error}`)
        })
      }
    }

  }, [
      selectedFile,
      fileName,
      fileSize,
      uiState,
      charactersList
    ]);

  // set page title and meta data
  var helmetData = seoMetaData_EN()

  // confirmation dialog for invalid filename encountered 
  if( props.confirmDialogId === Enums.confirmDialog.inputFileNameInvalid ) {
    const message = `Error: File name "${tmpFilename}" contains invalid characters or exceeds max length of ${Enums.MAX_FILENAME_LENGTH}. Please rename your file locally and try again.`
    return (
      <InfoDialog 
        title="File Name Invalid"
        msg={message}
        label1="OK"
        action1={props.closeModal}
      />
    )
  }

  ////////////////////////////////////////////////////////////////////
  // finally we return this functional component...
  ////////////////////////////////////////////////////////////////////

  if( props.displayAsTab ) {
    return buildUploadCharacterScreen()
  }
  else {
    return (
      <HelmetProvider>
        <div id="anim-fadein" className="column mt-0">
          {helmetData}
          <div className="columns">
            <div className="column">
              <AnimVersionPanel />
            </div>
          </div>

          { loading 
            ? 
            showLoadingUI()
            :

            <React.Fragment>
              
              {/* Only show upload workflow if no custom characers found for account */}
              { charactersList && charactersList.length === 0 
                ?
                <div>    
                  {buildUploadCharacterScreen()}
                  {buildUploadInfoArea()}
                </div>  
                :
                <React.Fragment>
                  {
                    charactersList && charactersList.length
                    &&
                    <div>
                      <div className="tabs tabs-area is-boxed is-centered">
                        <ul>
                          <li className={currentDisplayTab[0]} >
                            <a id="ExistingModels" onClick={()=>switchTabs("is-active", "is-hidden")} >
                              <span className="icon is-small"><i className={iconCustomModels + " mgRight-5"}></i></span>
                              {
                                (charactersList && charactersList.length && charactersList.length !== 'undefined')
                                ?
                                <span>{tabAvailableModels} ({charactersList.length}/{props.accountTotals.characterLimit})</span>
                                :
                                <span>{tabAvailableModels}</span>
                              }
                            </a>
                          </li>
                          <li className={currentDisplayTab[1]} >
                            <a id="NewModel" onClick={()=>switchTabs("is-hidden", "is-active")} >
                              <span className="icon is-small"><i className={iconAddModel + " mgRight-5"}></i></span>
                              <span>{tabUploadModel}</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="tab-content">

                        {/*** Available Characters Tab Content: ***/}
                        <div className={"tab-pane " + currentDisplayTabDiv[0]} id="ExistingModels">
                          <div>
                            <div className="columns is-vcentered">
                              <div className="column has-text-centered"> 
                                { /* Build the Characters Selection section */ }
                                <CharacterSwiper charactersList={charactersList} setCharacterIndex={setCharacterIndex} setModelImageLoaded={setModelImageLoaded} />
                              </div>
                              <div className="column has-text-left"> 
                                <div className="columns is-vcentered">
                                  <div className="column"> 
                                    <div className="button is-medium action-btn glow-on-hover fullwidth" onClick={()=>selectCustomCharacter()} style={{width: '65%'}}><span className="no-side-margins">{selectCharacterBtn}</span></div>
                                  </div>
                                </div>
                                <div className="columns is-vcentered">
                                  <div className="column"> 
                                    <div className="button is-medium discard-btn" onClick={()=>handleRemoveCustomCharacter()} style={{width: '65%'}}><span className="no-side-margins">{removeCharacterBtn}</span></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/*** Character Upload Tab Content: ***/}
                        <div className={"tab-pane " + currentDisplayTabDiv[1]} id="NewModel">
                          {buildUploadCharacterScreen()}
                        </div>

                      </div>
                    </div>
                  }
                  
                  {
                    !modelImageLoaded 
                    &&
                    showLoadingUI()
                  }

                </React.Fragment>  
              }
            </React.Fragment>
          }
        </div>
      </HelmetProvider>
    )
  }
} 