import * as Enums from './enums'
import { AppState } from '../../../types/index'

export const initialAppState: Partial<AppState> = {
  accessList: [false,false,false,false],
  accountTotals: {
    size: 0,
    time: 0,
    maxTimeInSeconds: 0,
    remainingTimeInSeconds: 0,
    currCycleMinsExpired: false,
    rerun_count: 0,
    max_rerun: 3,
    numCharacters: 0,
    characterLimit: 0,
    charactersList: null,
    platformCharactersList: null
  },
  accountDataRetrieved: false,
  anim3dInitialized: false,
  animationJobProgress: 0,
  animJobId: "",
  animLabelingFreeCredits: 0,
  animMaxVideoLabelingScore: 0,
  animCorrectionFreeCredits: 0,
  animMaxAnimationQualityScore: 0,
  animVideoDesc: '',
  animSourceJobId: '',
  animJobLinks: null,
  animJobSettings: {...Enums.JOB_SETTINGS_TEMPLATE},
  animJobSettingsSnapshots: JSON.stringify(Enums.JOB_SETTINGS_TEMPLATE),
  confirmDialogId: null,
  currDownloadModel: Enums.characterModels['1'].fileName,
  currDownloadLinks: {},
  currFTEStep: Enums.FTESteps.begin,
  currentBillCycleInfo: {
    usedRounded: 0,
    remainingRounded: 0,
    usedMonthlyTime: 0,
    remainingMonthlyTime: 0,
    usagePercent: 0,
    currCycleStartDate: "",
    currCycleEndDate: "",
    totalCurrCycleMins: 0
  },
  currPage: 1,
  currProductSelection: 0,
  currSortField: 'date',
  currSortDirection: 'down',
  currSortKeywordsName: '',
  currSortKeywordsDay: {
    from: '',
    to: ''
  },
  currWorkflowStep: Enums.uiStates.initial,
  dialogInfo: {},
  displayPreview: null,
  email: null,
  errorDialogInfo: {
    show: false,
    id: null,
    title: "",
    msg: ""
  },
  groups: [],
  showSelectedFile: '',
  inputVideoData: {
    selectedFile: null,
    fileName: null,
    fileLength: null,
    fileSize: null,
    videoRes: null,
    fps: null,
    codec: null
  },
  isFTEFaceTrackingFirstEnable: false,
  isJobInProgress: false,
  jobsData: null,
  libraryInitialized: false,
  loading: false,
  firstLogin: undefined,
  firstName: null,
  lastName: null,
  numPages: 0,
  openFirstJob: false,
  rowsPerPage: 10,
  pageState_CharacterManage: Enums.pageState.mount,
  progressMsg: "",
  rerunRootJointAtOrigin: false,
  rerunSettings: {...Enums.JOB_SETTINGS_TEMPLATE},
  sideMenuCollapsed: false,
  silentUploadInProgress: false,
  displayTrimAndCropDialog: false,
  tncThumbnails: [],
  tncSupported: true,
  videoCanPlay: true,
  subscriptionInfo: {
    status: "",
    cid: null,
    name: "",
    current_period_start: 0,
    current_period_end: 0,
    cancel_at_period_end: false,
    cancel_at: "",
    isEnterpriseUser: false,
    plan_expiary_date: "",
    featurePacks: null,
    history: {
      ongoing: 0,
      canceled: 0
    }
  },
  uid: null,
  videoFileName: null,
  videoStorageUrl: null
}
