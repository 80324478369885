import React from 'react'
import * as Enums from '../../../common/enums'

export default function CustomModal(props) { 
  
  function buildModalHeader() {
    if (props.title == null) {
      console.log('no title')
      return false
    } else {
      return(
        <header className="modal-card-head m-0">
          <p className="modal-card-title">{props.title}</p>
          <button 
            className="delete" 
            aria-label="close"
            onClick={() => props.closeModal()}
          ></button>
        </header>
      )
    }
		
  }
  
  function buildModalFooter() {
    if (props.func == null) {
      return false
    } 
    // if (func == OK) set to single button confirm dialog
    else if (props.func === 'OK') {
      return(
        <footer className="modal-card-foot m-0">
          <button 
            className="button"
            aria-label="close"
            onClick={() => props.closeModal()}
          >OK</button>
        </footer>
      )
    }
    else {
      return(
        <footer className="modal-card-foot m-0">
          <button 
            className="button is-link"
            onClick={props.func}
          >Confirm</button>
          <button 
            className="button"
            aria-label="close"
            onClick={() => props.closeModal(Enums.activeModal.none)}
          >Cancel</button>
        </footer>
      )
    }
  }

	return (
    <div className="modal is-active">
      <div className="modal-background"></div>
        <div className="modal-card">
          {buildModalHeader()}
          {props.modalBody()}
          {buildModalFooter()}
        </div>
    </div>
	)
}