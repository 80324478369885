import React, { useState, useEffect, useContext } from 'react'
import 'react-circular-progressbar/dist/styles.css'
import { AppStateContext } from '../../../AppStateContext'
import ProgressDisplay from './ProgressDisplay'
import JobQueuedUI from './JobQueuedUI'
import LoadingScreen from '../../common/LoadingScreen'
import AnimVersionPanel from '../../common/AnimVersionPanel'
import * as Enums from '../../common/enums'
import '../../../styles/NewJobConfiguration.scss'
export default function NewJobConfiguration(props) {

  const appStateContext = useContext(AppStateContext)

  /////////////////////////////////////////////////////////////////////
  // local component state hooks:
  /////////////////////////////////////////////////////////////////////
  const [componentInitialized, setComponentInitialized] = useState(false)

  // component mount effect hook:
  useEffect(() => {
    if( !appStateContext.state.anim3dInitialized || !appStateContext.state.accountDataRetrieved ) {
      !props.LOADING.show && props.initializeA3DService()
    } 
    else {
      if( props.LOADING.show ) {
        props.setLOADING({...props.LOADING, ...{show: false}})
      }
    }
  }, [appStateContext.state.anim3dInitialized, appStateContext.state.accountDataRetrieved])

  useEffect(async () => {
    if( !componentInitialized && appStateContext.state.anim3dInitialized && appStateContext.state.accountDataRetrieved) {
      window.scrollTo(0, 0)
      let newStateData = { animJobSettings: JSON.parse(JSON.stringify(Enums.JOB_SETTINGS_TEMPLATE)) }
      if( appStateContext.state.animJobSettings.jobType ) {
        // preseve current job type (i.e. anim or pose)
        newStateData.animJobSettings.jobType = 1
      }
      // also set animation job settings to default upon component mount but remember
      // current custom model data if set
      if( appStateContext.state.animJobSettings.customModelInfo.id ) {
        newStateData.animJobSettings.customModelInfo = appStateContext.state.animJobSettings.customModelInfo
      }
      appStateContext.dispatch(newStateData)
      setComponentInitialized(true)
    }
  }, [componentInitialized, appStateContext.state.anim3dInitialized, appStateContext.state.accountDataRetrieved])

  /////////////////////////////////////////////////////////////////////
  // use effect hook for starting background media upload process
  /////////////////////////////////////////////////////////////////////
  useEffect( () => {
    if( appStateContext.state.currWorkflowStep === Enums.uiStates.fileSelected && 
        appStateContext.state.silentUploadInProgress ) {
      props.uploadInputVideo()
    }
  }, [appStateContext.state.currWorkflowStep] )

  /////////////////////////////////////////////////////////////////////
  // Builds the select video clip UI
  /////////////////////////////////////////////////////////////////////
  function buildSelectMotionClipScreen() {
    return (
      <React.Fragment>
        <div id="anim-fadein" className="column">
          <AnimVersionPanel />

          {/* File Upload Area */}
          <div className="section pt-4">
            <div className="columns">
              <div className="column bShadow has-text-centered rounded-corners dm-brand">
                <div className="dm-brand rounded-corners">
                  {props.buildJobTypeSelectionRow()}
                </div>
                {/* <div className="columns dm-brand rounded-corners m-0"> */}
                <div className="NewJobConfiguration-content">
                  {props.buildAddMotionClipArea()}
                  {props.buildCharacterSettingsArea()}
                </div>
              </div>
            </div>
            {props.InformationArea()}
          </div>  
        </div>
      </React.Fragment>
    )
  }

  /******************************************************************
   *** render()
   *** 
   *** We conditionally render UI components based on which step of 
   *** the workflow the user is currently on.
   ******************************************************************/

  if( props.LOADING.show ) {
    return (
      <React.Fragment>
      <AnimVersionPanel />
      <LoadingScreen />
      </React.Fragment>
    )
  }
  else {
    /////////////////////////////////////////////////////
    // Main workflow logic that controls UI steps and 
    // job processing
    /////////////////////////////////////////////////////
    switch(appStateContext.state.currWorkflowStep) {

      /////////////////////////////////////////////////////
      // currWorkflowStep : Enums.uiStates.initial (0)
      //
      // Initial state, input file background upload starts
      /////////////////////////////////////////////////////
      case Enums.uiStates.initial:
        return buildSelectMotionClipScreen()

      /////////////////////////////////////////////////////
      // currWorkflowStep : Enums.uiStates.fileSelected (1)
      //
      // File Selected, configure animation job settings
      /////////////////////////////////////////////////////
      case Enums.uiStates.fileSelected:
        return (
          <React.Fragment>
            {props.renderScreenAfterFileSelected()}
          </React.Fragment>
        )

      /////////////////////////////////////////////////////
      // currWorkflowStep : Enums.uiStates.jobInProgress (2)
      //
      // Job in progress
      /////////////////////////////////////////////////////
      case Enums.uiStates.jobInProgress:
        return (
          <React.Fragment>
            <div className="column" id="anim-fadein">
              <AnimVersionPanel />
              <div className="section pt-5">
                <ProgressDisplay />

                {props.InformationArea()}
                {/* TODO: Disabling POC version of animated tips
                <DMAnimatedTips
                  tipList={Enums.tipList}
                  tipListLength={Enums.tipList.length}
                /> 
                */}
                </div>
            </div>
          </React.Fragment>
        ) 

      /////////////////////////////////////////////////////
      // currWorkflowStep : Enums.uiStates.jobQueued (3)
      //
      // Job Queued
      /////////////////////////////////////////////////////
      case Enums.uiStates.jobQueued:
        return (
          <React.Fragment>
            <div id="anim-fadein" className="column">
              <AnimVersionPanel />
              <JobQueuedUI />
              {props.InformationArea()} 
            </div>
          </React.Fragment>
        )

      default:
      break
    }
  }
}