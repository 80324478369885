import { AppStateContext } from '../../AppStateContext'
import React, { useContext, useEffect, useReducer, useRef, useState } from 'react'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import ReactPlayer from 'react-player'
import Draggable from 'react-draggable'
import Newrange from '../videocropandtrim/dualrangeslider/Newrange'

import { millisToMinutesAndSeconds, secondtomilisecond } from '../videocropandtrim/dualrangeslider/timmer'
import * as Enums from '../common/enums'
import {
  tooltipVideoDurationLimits,
  tooltipVideoFPSLimits,
  tooltipVideoLengthShouldTrim,
  tooltipVideoResolutionLimits
} from '../common/enums'
import Modaldemo from '../videocropandtrim/Modaldemo'
import { Alert } from 'react-bootstrap'
import { PatternFormat } from 'react-number-format'
import '../../components/videocropandtrim/assets/css/main.scss'
import Width_Icon from '../../images/videotrim/Width_Icon.png'
import Height_Icon from '../../images/videotrim/Height_Icon.png'
import VideoLength_Icon from '../../images/videotrim/VideoLength_Icon.png'
import FPS_Icon from '../../images/videotrim/FPS_Icon.png'
import { Buffer } from 'buffer'
import { getPricingPageForNewPurchase } from '../api/apiRequests'
import DMToolTip from '../ui/DMToolTip'

global.Buffer = Buffer
const HeaderMemo = React.memo(Newrange)

function CropButton ({
  setVideoCropData,
  setVideoTrimData,
  videoTrimData,
  videoCropData,
  fallback,
  setFallback,
  checkFeatureAvailability,
  displayDialog,
  multiPersonSelection
}) {
  const minCropHeight = 300
  const minCropWidth = 300
  const { state, dispatch } = useContext(AppStateContext)
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0)

  function forceRender () {
    forceUpdate()
  }

  const [timeFormat, setTimeFormat] = useState({
    starttime: '',
    endtime: ''
  })
  const [startTime, setStartTime] = useState({
    S_start: 0,
    S_end: 0,
    E_start: 0,
    E_end: 0,
    P_time: 0
  })

  const [metadata, setMetadata] = useState()
  const [slider, setSlider] = useState(false)
  const [show, setShow] = useState(false)
  const [check, setCheck] = useState(false)
  const reactPlayer = React.useRef('')
  const [isPlaying, setIsPlaying] = useState(false)
  const [timings, setTimings] = React.useState([
    {
      start: 0,
      end: 0
    }
  ])
  const maxDuration = checkFeatureAvailability(state.subscriptionInfo.name, Enums.featureLockNames.maxDuration)
  const maxFps = checkFeatureAvailability(state.subscriptionInfo.name, Enums.featureLockNames.maxFps)
  const maxResolution = checkFeatureAvailability(state.subscriptionInfo.name, Enums.featureLockNames.maxResolution)

  const firstInput = useRef('')
  const secondInput = useRef('')
  const [sliderPoints, setSliderPoints] = useState({
    start: 0,
    end: 0
  })
  // cropped resolution
  const [videoResolution, setVideoResolution] = useState({})
  // the max resolution of this video file can be
  const [videoMaxResolution, setVideoMaxResolution] = useState({})
  const [forPlayPause, setForPlayPause] = useState({ start: null, end: null })
  const [errorData, setErrorData] = useState({
    title: '',
    body: ''
  })
  const [DragPosition, setDragPosition] = useState({ x: 0, y: 0 })
  // temp crop data, to restore to VideoCropData if not Save the page
  const [crop, setCrop] = React.useState({
    height: videoCropData?.height || 100,
    unit: '%',
    width: videoCropData?.width || 100,
    x: videoCropData?.x || 0,
    y: videoCropData?.y || 0
  })
  const [loadedTime, setLoadedTime] = useState(0)
  const [slideNew, setSlideNew] = useState(false)
  const [loadTimeForRight, setLoadTimeForRight] = useState(100)
  const clientWidthFrame = useRef(null)
  const [upgradeUrl, setUpgradeUrl] = useState('')

  useEffect(() => {
    const getUrl = async () => {
      const url = await getPricingPageForNewPurchase()
      setUpgradeUrl(url)
    }
    getUrl()
  }, [])

  useEffect(() => {
    if (displayDialog) {
      trimCrop(state.inputVideoData)
    }
  }, [displayDialog])
  useEffect(() => {
    // if (check) {
    let S_start = Number(timeFormat.starttime.split(':')[0]) < 10
      ? `0${Number(timeFormat.starttime.split(':')[0])}`
      : Number(timeFormat.starttime.split(':')[0])

    let S_end = Number(timeFormat.starttime.split(':')[1]) < 10
      ? `0${Number(timeFormat.starttime.split(':')[1])}`
      : Number(timeFormat.starttime.split(':')[1])

    let E_start = Number(timeFormat.endtime.split(':')[0]) < 10
      ? `0${Number(timeFormat.endtime.split(':')[0])}`
      : Number(timeFormat.endtime.split(':')[0])

    let E_end = Number(timeFormat.endtime.split(':')[1]) < 10
      ? `0${Number(timeFormat.endtime.split(':')[1])}`
      : Number(timeFormat.endtime.split(':')[1])

    let P_time = Number(E_start) * 60 + Number(E_end) - Number(S_start) * 60 - Number(S_end)

    setStartTime({
      S_start: S_start,
      S_end: S_end,
      E_start: E_start,
      E_end: E_end,
      P_time: millisToMinutesAndSeconds(P_time * 1000)
    })
    // }
  }, [timeFormat.starttime, timeFormat.endtime])
  const [loader, setLoader] = useState(false)

  async function trimCrop (file, e) {
    e && e.preventDefault()

    setLoader(true)
    let fileUrl = URL.createObjectURL(file.selectedFile)
    let data = {
      height: file.videoRes.h,
      width: file.videoRes.w,
      duration: file.fileLength,
      size: file.fileSize,
      start: 0
    }

    if (data.height > data.width) {
      setVideoMaxResolution({
        h: Math.min(maxResolution.w, data.height),
        w: Math.min(maxResolution.h, data.width),
        // hp&wp: cropping to the maximum size(means maxResolution.w) is allowed regardless of width and height, which means changing the aspect ratio
        hp: Math.min(maxResolution.w, data.height),
        wp: Math.min(maxResolution.w, data.width)
      })
    } else {
      setVideoMaxResolution({
        w: Math.min(maxResolution.w, data.width),
        h: Math.min(maxResolution.h, data.height),
        // hp&wp: cropping to the maximum size(means maxResolution.w) is allowed regardless of width and height, which means changing the aspect ratio
        wp: Math.min(maxResolution.w, data.width),
        hp: Math.min(maxResolution.w, data.height)
      })
    }
    //
    const videoResolutionHeight = Math.floor(data.height * (videoCropData?.height || 100) * 0.01)
    const videoResolutionWidth = Math.floor(data.width * (videoCropData?.width || 100) * 0.01)

    setVideoResolution({ width: videoResolutionWidth, height: videoResolutionHeight })
    setCrop(videoCropData)

    setMetadata({ ...data, url: fileUrl, fps: file.fps })
    setSliderPoints(videoTrimData ? {
      start: secondtomilisecond(Number(videoTrimData?.from)),
      end: secondtomilisecond(Number(videoTrimData?.to))
    } : {
      start: secondtomilisecond(Number(data?.start)),
      end: secondtomilisecond(Number(data?.duration))
    })
    setTimeFormat({
      starttime: toHHMMSS(Number(videoTrimData?.from ?? data.start)),
      endtime: toHHMMSS(Number(videoTrimData?.to ?? data.duration))
    })
    setTimings([{ start: videoTrimData?.from ?? data.start, end: Number(videoTrimData?.to ?? data.duration) }])
    dynamicData(videoTrimData?.from ?? data.start, data.duration)
    setCheck(true)
    setShow(true)
    setLoader(false)
  }

  async function save (e) {
    // e.target.innerText = 'Loading....'
    e.preventDefault()
    pauseVideo()
    setVideoCropData(crop)
    setVideoTrimData({
      from: timings[0].start,
      to: timings[0].end
    })
    let _fallback = { ...fallback }
    if (fallback['durationIsExceeded']) {
      const isValid = validateDurationTrimmed()
      _fallback = {
        ..._fallback,
        durationIsFallback: isValid,
        durationIsFallbackSaved: isValid
      }
    }

    if (fallback['fpsIsExceeded']) {
      _fallback = {
        ..._fallback,
        fpsIsFallbackSaved: true,
      }
    }

    if (fallback['resolutionIsExceeded']) {
      const isValid = validateResolutionCropped()
      _fallback = {
        ..._fallback,
        resolutionIsFallback: isValid,
        resolutionIsFallbackSaved: isValid,
      }
    }
    setFallback(_fallback)
    setShow(false)
    dispatch({
      displayTrimAndCropDialog: false
    })
  }

  var count = 0

  function dynamicData (playtime, duration) {
    let a = playtime * 100
    let b = a / duration
    setDragPosition({
      ...DragPosition,
      x: Number(((clientWidthFrame.current * b) / 100).toFixed(2))
    })
    setLoadedTime(
      (Number(((clientWidthFrame.current * b) / 100).toFixed(2)) * 100) /
      clientWidthFrame.current
    )

    setSlideNew(false)
    if (count > 0) {
      setSlider(true)
      setDragPosition({
        ...DragPosition,
        x: Number(((clientWidthFrame.current * b) / 100).toFixed(2))
      })
      setLoadedTime(
        (Number(((clientWidthFrame.current * b) / 100).toFixed(2)) * 100) /
        clientWidthFrame.current
      )
    }
    count += 1
  }

  function dynamicDataForRightSlide (playtime) {
    let a = playtime * 100
    let b = a / metadata.duration
    setLoadTimeForRight(b)
    setSlideNew(true)
    setSlider(false)
  }

  useEffect(() => {
    if (DragPosition.x || loadTimeForRight) {
      if (parseInt(loadTimeForRight) <= parseInt(loadedTime)) {
        setDragPosition({
          ...DragPosition,
          x: (clientWidthFrame.current * loadTimeForRight) / 100
        })
      }
    }
  }, [DragPosition.x, check, loadTimeForRight, loadedTime])

  function validateDurationTrimmed () {
    return (timings[0].end - timings[0].start) <= maxDuration
  }

  function validateResolutionCropped () {
    if (videoResolution.height > videoResolution.width) {
      return videoResolution.height <= videoMaxResolution.w
        && videoResolution.width <= videoMaxResolution.h
    } else {
      return videoResolution.width <= videoMaxResolution.w
        && videoResolution.height <= videoMaxResolution.h
    }
  }

  //#region
  function onWidthChanged (width) {
    const properWidth = Math.min(Number(width), videoMaxResolution.wp)
    setVideoResolution((e) => {
      return {
        ...e,
        width: properWidth
      }
    })
    setCrop((e) => {
      return {
        ...e,
        width: (properWidth * 100 / metadata.width).toFixed(2)
      }
    })
    return Number(width) === properWidth
  }

  function onHeightChanged (height) {
    const properHeight = Math.min(Number(height), videoMaxResolution.hp)
    setVideoResolution((e) => {
      return {
        ...e,
        height: Number(properHeight)
      }
    })
    setCrop((e) => {
      return {
        ...e,
        height: (properHeight * 100 / metadata.height).toFixed(2)
      }
    })
    return Number(height) === properHeight
  }

  //#endregion

  const toHHMMSS = (secs) => {
    const secNum = Math.round(parseFloat(secs))
    const hours = Math.floor(secNum / 3600)
    const minutes = Math.floor(secNum / 60) % 60
    const seconds = secNum % 60

    return [hours, minutes, seconds]
      .map((val) => (val < 10 ? `0${val}` : val))
      .filter((val, index) => val !== '00' || index > 0)
      .join(':')
      .replace(/^0/, '')
  }

  function Blur () {
    if (
      Number(startTime.S_start) * 60 + Number(startTime.S_end) <
      Number(timeFormat.endtime.split(':')[0]) * 60 +
      Number(timeFormat.endtime.split(':')[1])
    ) {
      setStartTime({
        ...startTime,
        S_end: startTime.S_end,
        S_start: startTime.S_start
      })
      //#region for slider change
      dynamicData(
        Number(startTime.S_start) * 60 + Number(startTime.S_end),
        metadata.duration
      )
      setSliderPoints({
        ...sliderPoints,
        start:
          Number(startTime.S_start) * 60 * 1000 + Number(startTime.S_end) * 1000
      })
      setTimings([
        {
          ...timings[0],
          start: Number(startTime.S_end) + Number(startTime.S_start) * 60
        }
      ])
    } else {
      let timingFormatted = toHHMMSS(timings[0].start)

      setStartTime((pre) => {
        return {
          ...pre,
          S_start:
            Number(timingFormatted.split(':')[0]) < 10
              ? `0${Number(timingFormatted.split(':')[0])}`
              : Number(timingFormatted.split(':')[0]),

          S_end:
            Number(timingFormatted.split(':')[1]) < 10
              ? `0${Number(timingFormatted.split(':')[1])}`
              : Number(timingFormatted.split(':')[1])
        }
      })
      dynamicData(
        Number(timingFormatted.split(':')[0]) * 60 +
        Number(timingFormatted.split(':')[1]),
        metadata.duration
      )
      setSliderPoints({
        ...sliderPoints,
        start:
          Number(timingFormatted.split(':')[0]) * 60 * 1000 +
          Number(timingFormatted.split(':')[1]) * 1000
      })
      setTimings([
        {
          ...timings[0],
          start:
            Number(timingFormatted.split(':')[1]) +
            Number(timingFormatted.split(':')[0]) * 60
        }
      ])
    }
  }

  function Blur2 () {
    if (
      Number(startTime.E_start) * 60 + Number(startTime.E_end) <
      Number(metadata.duration) &&
      Number(startTime.E_start) * 60 + Number(startTime.E_end) >
      Number(startTime.S_start) * 60 + Number(startTime.S_end)
    ) {
      setStartTime({
        ...startTime,
        E_end: startTime.E_end,
        E_start: startTime.E_start
      })

      setSliderPoints({
        ...sliderPoints,
        end:
          Number(startTime.E_start) * 60 * 1000 + Number(startTime.E_end) * 1000
      })
      setTimings([
        {
          ...timings[0],
          end: Number(startTime.E_end) + Number(startTime.E_start) * 60
        }
      ])
    } else {
      let timeinformateend = toHHMMSS(timings[0].end)
      setStartTime((pre) => {
        return {
          ...pre,
          E_start:
            Number(timeinformateend.split(':')[0]) < 10
              ? `0${Number(timeinformateend.split(':')[0])}`
              : Number(timeinformateend.split(':')[0]),

          E_end:
            Number(timeinformateend.split(':')[1]) < 10
              ? `0${Number(timeinformateend.split(':')[1])}`
              : Number(timeinformateend.split(':')[1])
        }
      })
      setSliderPoints({
        ...sliderPoints,
        end:
          Number(timeinformateend.split(':')[0]) * 60 * 1000 +
          Number(timeinformateend.split(':')[1]) * 1000
      })
      setTimings([
        {
          ...timings[0],
          end:
            Number(timeinformateend.split(':')[1]) +
            Number(timeinformateend.split(':')[0]) * 60
        }
      ])
    }
  }

  const [activeDrags, setActiveDrags] = useState(0)

  const onStart = () => {
    setSlider(true)
    setActiveDrags(activeDrags + 1)
  }

  const onStop = () => {
    setActiveDrags(activeDrags - 1)
    setSlider(false)
  }

  const playVideo = () => {
    const currentTime = reactPlayer.current.getCurrentTime()
    if (currentTime < timings[0].start || currentTime >= timings[0].end) {
      reactPlayer.current.seekTo(timings[0].start, 'seconds')
    }
    setIsPlaying(true)
    setSlider(true)
  }

  function pauseVideo () {
    setIsPlaying(false)
    setSlider(false)
  }

  const first = useRef(null)

  useEffect(() => {
    if (check) {
      first.current = document.getElementsByClassName('noUi-connect')[0]
    }
    forceRender()
  }, [check])
  useEffect(() => {
    if (document.getElementsByClassName('noUi-connect')[0]) {
      clientWidthFrame.current = Math.max(1, document.getElementsByClassName('noUi-connect')[0].clientWidth)
    }
  }, [document.getElementsByClassName('noUi-connect')[0]])

  const onControlledDrag = (e, position) => {
    const { x, y } = position

    setDragPosition({ x, y })
    setLoadedTime(((x * 100) / clientWidthFrame.current).toFixed(2))

    const xPercentage = x * 100

    const getTotalPercentage = (
      xPercentage /
      document.getElementsByClassName('noUi-connect')[0].clientWidth
    ).toFixed(2)

    const getTime = (metadata.duration * getTotalPercentage) / 100

    if (
      Number(getTime) * 1000 > sliderPoints.end ||
      Number(getTime) * 1000 < sliderPoints.start
    ) {
      setDragPosition({ ...DragPosition, x: parseInt(DragPosition.x) })
      return false
    } else {
      reactPlayer.current.seekTo(getTime, 'seconds')
    }
  }

  const playPointDraggable = useRef(null)
  const dragHandlers = {
    onStart: onStart,
    onStop: onStop,
    onDrag: onControlledDrag
  }
  //#region  clickable
  useEffect(() => {
    if (document.getElementsByClassName('noUi-connect')[0]) {
      document
        .getElementsByClassName('noUi-connect')[0]
        .addEventListener('click', (e) => {
          setDragPosition({ ...DragPosition, x: e.layerX })

          let currentime = (
            (e.layerX * 100) /
            document.getElementsByClassName('noUi-connect')[0].clientWidth
          ).toFixed(2)

          reactPlayer.current.seekTo(
            (metadata.duration * Number(currentime)) / 100,
            'seconds'
          )
        })
    }
  }, [document.getElementsByClassName('noUi-connect')[0]])

  //#endregion
  return (
    <>
      <div className="column has-text-centered my-3 mt-5" id="cropandtrimbutton">
        {state.videoCanPlay && state.tncSupported && <button
          type="button"
          className="button action-btn glow-on-hover btn-shadow"
          style={{ width: '100%' }}
          disabled={!state.inputVideoData?.fileLength || loader || multiPersonSelection}
          onClick={(e) => trimCrop(state.inputVideoData, e)}
        >
          <span className="no-side-margins">
            {state.inputVideoData?.fileLength ? 'Trim & Crop' : 'Loading...'}
          </span>
        </button>}
      </div>
      {check && (
        <Modaldemo
          clickHandle={save}
          setCheck={setCheck}
          show={show}
          setShow={setShow}
          setCrop={setCrop}
          setErrorData={setErrorData}
          errorData={errorData}
          isPlaying={isPlaying}
          playVideo={playVideo}
          pauseVideo={pauseVideo}
          hideCloseButton={fallback['durationIsExceeded'] && !fallback['durationIsFallbackSaved']
          || (fallback['resolutionIsExceeded'] && !fallback['resolutionIsFallbackSaved'])}
        >
          {errorData.title === '' ? (
            <>
              {fallback['durationIsExceeded'] &&
              <div className="red-bold">
                {!fallback['durationIsFallback'] &&
                <span>Your video should be trimmed to your account max, trim length and &quot;Save&quot; to continue</span>}
                {fallback['durationIsFallback'] && !fallback['durationIsFallbackSaved'] &&
                <span>Your video has been auto-trimmed to your account max, review and &quot;Save&quot; to continue</span>}
                {!fallback['durationIsFallbackSaved'] &&
                <DMToolTip
                  text={tooltipVideoLengthShouldTrim()}
                  iconColor="#fc4242"
                  tipId="upgrade-subscription-duration"
                  icon={`fas fa-info-circle fa-md`}
                  cursor="cursor-pt"
                />}
              </div>
              }
              {fallback['resolutionIsExceeded'] &&
              <div className="red-bold">
                {!fallback['resolutionIsFallback'] &&
                <span>Your video should be cropped to your account max, crop video and &quot;Save&quot; to continue</span>}
                {fallback['resolutionIsFallback'] && !fallback['resolutionIsFallbackSaved'] &&
                <span>Your video has been auto-cropped to your account max, review and &quot;Save&quot; to continue</span>}
                {!fallback['resolutionIsFallbackSaved'] &&
                <DMToolTip
                  text={tooltipVideoResolutionLimits()}
                  iconColor="#fc4242"
                  tipId="upgrade-subscription-resolution-limits"
                  icon={`fas fa-info-circle fa-md`}
                  cursor="cursor-pt"
                />}
              </div>
              }
              <div style={{ height: 360, width: 640 }}>
                <ReactCrop
                  crop={crop}
                  onChange={(c, percentCrop) => {
                    const width = Math.round((metadata.width * percentCrop.width) / 100)
                    const height = Math.round((metadata.height * percentCrop.height) / 100)

                    setCrop(percentCrop)
                    setVideoResolution({
                      width: width,
                      height: height
                    })
                  }}
                  keepSelection={true}
                  minHeight={(minCropHeight / metadata.height) * 360}
                  minWidth={(minCropWidth / metadata.width) * 640}
                  maxHeight={(videoMaxResolution.hp / metadata.height) * 360}
                  maxWidth={(videoMaxResolution.wp / metadata.width) * 640}
                >
                  <ReactPlayer
                    ref={reactPlayer}
                    id="videoid"
                    url={metadata?.url}
                    playing={isPlaying}
                    className="react-player"
                    progressInterval={30}
                    width={'100%'}
                    height={'100%'}
                    controls={false}
                    onLoadedData={() => {
                      setTimeout(() => {
                        reactPlayer.current.seekTo(timings[0].start, 'seconds')
                      }, 1)
                    }}
                    onProgress={(e) => {
                      if (e.playedSeconds === e.loadedSeconds) {
                        setIsPlaying(false)
                        setSlider(false)
                        reactPlayer.current.seekTo(timings[0].start, 'seconds')
                      }

                      if (e.playedSeconds.toFixed(2) >= timings[0].end) {
                        setIsPlaying(false)
                        setSlider(false)
                        reactPlayer.current.seekTo(timings[0].start, 'seconds')
                      }
                      if (isPlaying) {
                        dynamicData(e.playedSeconds.toFixed(2), e.loadedSeconds)
                      }
                    }}
                  />
                </ReactCrop>
              </div>
              {/* starttime endtime */}

              <div className="videoframe-slider-box">
                <div className="frame-content none-select">
                  <div className="max-width frame">
                    MAX <b>{videoMaxResolution?.w}</b> &nbsp;{' '}
                    <span className="icon">
                      <img src={Width_Icon} alt="" className="width"/>
                      {/* <MdHorizontalSplit /> */}
                    </span>
                    <span className="input-box" style={{ display: 'flex' }}>
                      <input
                        type="number"
                        name=""
                        id=""
                        className="form-control icontrol"
                        onBlur={(e) => {
                          if (e.target.value < minCropWidth) {
                            onWidthChanged(minCropWidth)
                          } else {
                            onWidthChanged(e.target.value)
                          }
                        }}
                        value={videoResolution?.width}
                        ref={firstInput}
                        onChange={(e) => {
                          onWidthChanged(e.target.value)
                        }}
                      />
                      <div
                        className="num-arrows"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          flexDirection: 'column',
                          justifyContent: 'center'
                        }}
                      >
                        <svg
                          width={15}
                          onClick={() => {
                            firstInput.current.stepUp()
                            firstInput.current.focus()
                            onWidthChanged(firstInput.current.value)
                          }}
                          height={15}
                          viewBox="-2 -4 10 10"
                          fill="none"
                          stroke="#354d74"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            name="up"
                            d="M4.2506 0.333496C4.1256 0.166829 3.8756 0.166829 3.7506 0.333496L0.625604 4.50016C0.471095 4.70617 0.618089 5.00016 0.875603 5.00016L7.1256 5.00016C7.38312 5.00016 7.53011 4.70617 7.3756 4.50016L4.2506 0.333496Z"
                            fill="#f0b354"
                          />
                        </svg>

                        <svg
                          width={15}
                          onClick={() => {
                            if (firstInput.current.value > minCropWidth) {
                              firstInput.current.stepDown()
                              firstInput.current.focus()
                              onWidthChanged(firstInput.current.value)
                            }
                          }}
                          height={15}
                          viewBox="-2 9 10 10"
                          fill="none"
                          stroke="#354d74"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            name="down"
                            d="M3.74947 14.6668C3.87447 14.8335 4.12447 14.8335 4.24947 14.6668L7.37447 10.5002C7.52898 10.2942 7.38198 10.0002 7.12447 10.0002H0.874469C0.616954 10.0002 0.46996 10.2942 0.624469 10.5002L3.74947 14.6668Z"
                            fill="#f0b354"
                          />
                        </svg>
                      </div>
                    </span>
                  </div>
                  <span className="xe"> &nbsp; X &nbsp; </span>
                  <div className="max-height frame">
                    <span className="input-box" style={{ display: 'flex' }}>
                      <input
                        type="number"
                        ref={secondInput}
                        name=""
                        id=""
                        value={videoResolution?.height}
                        onBlur={(e) => {
                          if (e.target.value < minCropHeight) {
                            onHeightChanged(minCropHeight)
                          } else {
                            onHeightChanged(e.target.value)
                          }
                        }}
                        onChange={(e) => {
                          onHeightChanged(e.target.value)
                        }}
                        className="form-control icontrol"
                      />
                      <div
                        className="num-arrows"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          flexDirection: 'column',
                          justifyContent: 'center'
                        }}
                      >
                        <svg
                          stroke="#354d74"
                          width={15}
                          onClick={() => {
                            secondInput.current.stepUp()
                            secondInput.current.focus()
                            onHeightChanged(secondInput.current.value)
                          }}
                          height={15}
                          viewBox="-2 -4 10 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            name="up"
                            d="M4.2506 0.333496C4.1256 0.166829 3.8756 0.166829 3.7506 0.333496L0.625604 4.50016C0.471095 4.70617 0.618089 5.00016 0.875603 5.00016L7.1256 5.00016C7.38312 5.00016 7.53011 4.70617 7.3756 4.50016L4.2506 0.333496Z"
                            fill="#f0b354"
                          />
                        </svg>
                        <svg
                          stroke="#354d74"
                          width={15}
                          onClick={() => {
                            if (secondInput.current.value > minCropHeight) {
                              secondInput.current.stepDown()
                              secondInput.current.focus()
                              onHeightChanged(secondInput.current.value)
                            }
                          }}
                          height={15}
                          viewBox="-2 9 10 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            name="down"
                            d="M3.74947 14.6668C3.87447 14.8335 4.12447 14.8335 4.24947 14.6668L7.37447 10.5002C7.52898 10.2942 7.38198 10.0002 7.12447 10.0002H0.874469C0.616954 10.0002 0.46996 10.2942 0.624469 10.5002L3.74947 14.6668Z"
                            fill="#f0b354"
                          />
                        </svg>
                      </div>
                    </span>
                    <span className="icon">
                      {' '}
                      <img src={Height_Icon} alt="" className="height"/>
                      {/* <MdBorderVertical /> */}
                    </span>{' '}
                    <b>{videoMaxResolution?.h}</b> MAX
                    <DMToolTip
                      text={tooltipVideoResolutionLimits()}
                      iconColor={validateResolutionCropped() ?
                        (fallback['resolutionIsExceeded'] && !fallback['resolutionIsFallbackSaved'] ? '#ff8c00' : '#354d74')
                        : '#fc4242'}
                      tipId="resolution-limits"
                      icon={`fas fa-info-circle fa-md`}
                      cursor="cursor-pt"
                    />
                  </div>
                </div>
                <div className="video-frame-box none-select">
                  <div className="video-frame">
                    {state.tncThumbnails && state.tncThumbnails.map((e, index) => {
                      return <img src={e} alt="" key={`thumbnails${index}`}/>
                    })}
                    <div className="video-slider">
                      <HeaderMemo
                        start={secondtomilisecond(Number(metadata.start))}
                        end={secondtomilisecond(Number(metadata.duration))}
                        timings={timings}
                        refData={reactPlayer}
                        setTimings={setTimings}
                        newChangeSlide
                        setForPlayPause={setForPlayPause}
                        playpausetimevideo={forPlayPause}
                        dynamicData={dynamicData}
                        videoDuration={metadata.duration}
                        setSlider={setSlider}
                        slider={slider}
                        dynamicDataForRightSlide={dynamicDataForRightSlide}
                        setTimeFormat={setTimeFormat}
                        toHHMMSS={toHHMMSS}
                        setSlideNew={setSlideNew}
                        sliderPoints={sliderPoints}
                        setSliderPoints={setSliderPoints}
                      />
                    </div>

                    <Draggable
                      bounds="parent"
                      {...dragHandlers}
                      position={DragPosition}
                      nodeRef={playPointDraggable}
                    >
                      <div ref={playPointDraggable} className="main-video-playpoint">
                        <div className="video-playpoint">
                          {(slider || isPlaying) && (
                            <div className="tool-tip">
                              <span>
                                {millisToMinutesAndSeconds(
                                  ((loadedTime * metadata.duration) / 100) *
                                  1000
                                )}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </Draggable>
                    <div
                      className="main-video-playpoint"
                      style={{
                        left: `${loadTimeForRight}%`,
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                        width: '0'
                      }}
                    >
                      <div className="video-playpoint">
                        {slideNew && (
                          <div className="tool-tip">
                            <span>
                              {millisToMinutesAndSeconds(
                                ((loadTimeForRight * metadata.duration) / 100) *
                                1000
                              )}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="fix-frame-box">
                    <div className="fix-frame left">
                      <PatternFormat
                        onBlur={Blur}
                        displayType="input"
                        style={{
                          margin: '0 !important',
                          width: '60px',
                          border: 'none',
                          background: 'transparent',
                          textIndent: '4px'
                        }}
                        mask="_"
                        allowEmptyFormatting={true}
                        format="##:##"
                        value={`${startTime.S_start}:${startTime.S_end}`}
                        onChange={(e) => {
                          let [start, end] = e.target.value.split(':')
                          setStartTime((ert) => {
                            return {
                              ...ert,
                              S_start: start,
                              S_end: end
                            }
                          })
                        }}
                      />
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          flexDirection: 'column',
                          justifyContent: 'center'
                        }}
                      >
                        <svg
                          disabled
                          width={15}
                          onClick={() => {
                            if (
                              Number(startTime.S_start) * 60 +
                              Number(startTime.S_end) ===
                              Number(startTime.E_start) * 60 +
                              Number(startTime.E_end) -
                              1
                            ) {
                              return false
                            }

                            setStartTime((oldvalues) => {
                              let newvalue = Number(oldvalues.S_end) + 1
                              let abc = {
                                S_start: oldvalues.S_start,
                                S_end: newvalue < 10 ? `0${newvalue}` : newvalue
                              }
                              if (newvalue > 59) {
                                abc = {
                                  S_end: '00',
                                  S_start:
                                    Number(oldvalues.S_start) + 1 < 10
                                      ? `0${Number(oldvalues.S_start) + 1}`
                                      : Number(oldvalues.S_start) + 1
                                }
                              }

                              dynamicData(
                                Number(abc?.S_start) * 60 + Number(abc?.S_end),
                                metadata.duration
                              )
                              setSliderPoints({
                                ...sliderPoints,
                                start:
                                  Number(abc?.S_start) * 60 * 1000 +
                                  Number(abc?.S_end) * 1000
                              })
                              setTimings([
                                {
                                  ...timings[0],
                                  start:
                                    Number(abc?.S_end) +
                                    Number(abc?.S_start) * 60
                                }
                              ])
                              return {
                                ...oldvalues,

                                ...abc
                              }
                            })
                          }}
                          height={15}
                          viewBox="-2 -4 10 10"
                          fill="none"
                          stroke="#354d74"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            name="up"
                            d="M4.2506 0.333496C4.1256 0.166829 3.8756 0.166829 3.7506 0.333496L0.625604 4.50016C0.471095 4.70617 0.618089 5.00016 0.875603 5.00016L7.1256 5.00016C7.38312 5.00016 7.53011 4.70617 7.3756 4.50016L4.2506 0.333496Z"
                            fill="#f0b354"
                          />
                        </svg>
                        <svg
                          width={15}
                          onClick={() => {
                            if (
                              Number(startTime.S_start) * 60 +
                              Number(startTime.S_end) ===
                              0
                            ) {
                              return false
                            }

                            setStartTime((oldvalues) => {
                              let newvalue = Number(oldvalues.S_end) - 1
                              let abc = {
                                S_start: oldvalues.S_start,
                                S_end: newvalue < 10 ? `0${newvalue}` : newvalue
                              }
                              if (newvalue < 0) {
                                abc = {
                                  S_end: '59',
                                  S_start:
                                    Number(oldvalues.S_start) - 1 < 10
                                      ? `0${Number(oldvalues.S_start) - 1}`
                                      : Number(oldvalues.S_start) - 1
                                }
                              }
                              dynamicData(
                                Number(abc?.S_start) * 60 + Number(abc?.S_end),
                                metadata.duration
                              )
                              setSliderPoints({
                                ...sliderPoints,
                                start:
                                  Number(abc?.S_start) * 60 * 1000 +
                                  Number(abc?.S_end) * 1000
                              })
                              setTimings([
                                {
                                  ...timings[0],
                                  start:
                                    Number(abc?.S_end) +
                                    Number(abc?.S_start) * 60
                                }
                              ])

                              return {
                                ...oldvalues,

                                ...abc
                              }
                            })
                          }}
                          height={15}
                          viewBox="-2 9 10 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            name="down"
                            d="M3.74947 14.6668C3.87447 14.8335 4.12447 14.8335 4.24947 14.6668L7.37447 10.5002C7.52898 10.2942 7.38198 10.0002 7.12447 10.0002H0.874469C0.616954 10.0002 0.46996 10.2942 0.624469 10.5002L3.74947 14.6668Z"
                            fill="#f0b354"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="fix-frame right">
                      <PatternFormat
                        onBlur={Blur2}
                        displayType="input"
                        style={{
                          margin: '0 !important',
                          width: '60px',
                          border: 'none',
                          background: 'transparent',
                          textIndent: '4px'
                        }}
                        mask="_"
                        allowEmptyFormatting={true}
                        format="##:##"
                        value={`${startTime.E_start}:${startTime.E_end}`}
                        onChange={(e) => {
                          let [start, end] = e.target.value.split(':')

                          setStartTime((ert) => {
                            return {
                              ...ert,
                              E_start: start,
                              E_end: end
                            }
                          })
                        }}
                      />
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          flexDirection: 'column',
                          justifyContent: 'center'
                        }}
                      >
                        <svg
                          onClick={() => {
                            if (
                              Number(startTime.E_start) * 60 +
                              Number(startTime.E_end) ===
                              Math.floor(Number(metadata.duration))
                            ) {
                              return false
                            }
                            setStartTime((oldvalues) => {
                              let newvalue = Number(oldvalues.E_end) + 1
                              let abc = {
                                E_start: oldvalues.E_start,
                                E_end: newvalue < 10 ? `0${newvalue}` : newvalue
                              }
                              if (newvalue > 59) {
                                abc = {
                                  E_end: '00',
                                  E_start:
                                    Number(oldvalues.E_start) + 1 < 10
                                      ? `0${Number(oldvalues.E_start) + 1}`
                                      : Number(oldvalues.E_start) + 1
                                }
                              }
                              setSliderPoints({
                                ...sliderPoints,
                                end:
                                  Number(abc?.E_start) * 60 * 1000 +
                                  Number(abc?.E_end) * 1000
                              })
                              setTimings([
                                {
                                  ...timings[0],
                                  end:
                                    Number(abc?.E_end) +
                                    Number(abc?.E_start) * 60
                                }
                              ])
                              return {
                                ...oldvalues,
                                ...abc
                              }
                            })
                          }}
                          width={15}
                          height={15}
                          viewBox="-2 -4 10 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.2506 0.333496C4.1256 0.166829 3.8756 0.166829 3.7506 0.333496L0.625604 4.50016C0.471095 4.70617 0.618089 5.00016 0.875603 5.00016L7.1256 5.00016C7.38312 5.00016 7.53011 4.70617 7.3756 4.50016L4.2506 0.333496Z"
                            fill="#f0b354"
                          />
                        </svg>
                        <svg
                          onClick={() => {
                            if (
                              Number(startTime.E_start) * 60 +
                              Number(startTime.E_end) ===
                              Number(startTime.S_start) * 60 +
                              Number(startTime.S_end) +
                              1
                            ) {
                              return false
                            }
                            setStartTime((oldvalues) => {
                              let newvalue = Number(oldvalues.E_end) - 1
                              let abc = {
                                E_start: oldvalues.E_start,
                                E_end: newvalue < 10 ? `0${newvalue}` : newvalue
                              }
                              if (newvalue < 0) {
                                abc = {
                                  E_end: '59',
                                  E_start:
                                    Number(oldvalues.E_start) - 1 < 10
                                      ? `0${Number(oldvalues.E_start) - 1}`
                                      : Number(oldvalues.E_start) - 1
                                }
                              }
                              setSliderPoints({
                                ...sliderPoints,
                                end:
                                  Number(abc?.E_start) * 60 * 1000 +
                                  Number(abc?.E_end) * 1000
                              })
                              setTimings([
                                {
                                  ...timings[0],
                                  end:
                                    Number(abc?.E_end) +
                                    Number(abc?.E_start) * 60
                                }
                              ])
                              return {
                                ...oldvalues,

                                ...abc
                              }
                            })
                          }}
                          width={15}
                          height={15}
                          viewBox="-2 9 10 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.74947 14.6668C3.87447 14.8335 4.12447 14.8335 4.24947 14.6668L7.37447 10.5002C7.52898 10.2942 7.38198 10.0002 7.12447 10.0002H0.874469C0.616954 10.0002 0.46996 10.2942 0.624469 10.5002L3.74947 14.6668Z"
                            fill="#f0b354"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="length-frame-box none-select">
                    <div className="video-length">
                      <span
                        className={validateDurationTrimmed() ?
                          (fallback['durationIsExceeded'] && !fallback['durationIsFallbackSaved'] ? 'orange-bold' : '') : 'red-bold'}>Video Length: &nbsp;</span>
                      {fallback['durationIsExceeded'] && <span
                        className={!fallback['durationIsFallbackSaved'] && 'orange-bold'}>{millisToMinutesAndSeconds(metadata.duration * 1000)} ➩&nbsp;</span>}
                      {startTime.P_time}
                      <span className="clock">
                        {/* <BsClockHistory /> */}
                        <img src={VideoLength_Icon} alt=""/>
                        <b>{millisToMinutesAndSeconds(maxDuration * 1000)}</b> MAX
                      </span>
                      <DMToolTip
                        text={tooltipVideoDurationLimits(upgradeUrl)}
                        iconColor={validateDurationTrimmed() ?
                          (fallback['durationIsExceeded'] && !fallback['durationIsFallbackSaved'] ? '#ff8c00' : '#354d74') : '#fc4242'}
                        tipId="duration-limits"
                        icon={`fas fa-info-circle fa-md`}
                        cursor="cursor-pt"
                      />
                    </div>
                    <div className="video-length frame-seconds">
                      <span className={fallback['fpsIsExceeded'] && !fallback['fpsIsFallbackSaved'] && 'orange-bold'}>Frame Per Second: &nbsp;</span>
                      {fallback['fpsIsExceeded'] &&
                      <span className={!fallback['fpsIsFallbackSaved'] && 'orange-bold'}>{metadata?.fps} ➩&nbsp;</span>}
                      {Math.min(metadata?.fps, maxFps)}
                      <span className="clock">
                        {/* <BsFillClockFill /> */}
                        <img src={FPS_Icon} alt=""/>
                        <b>{maxFps}</b> MAX
                      </span>
                      <DMToolTip
                        text={tooltipVideoFPSLimits()}
                        iconColor={fallback['fpsIsExceeded'] && !fallback['fpsIsFallbackSaved'] ? '#ff8c00' : '#354d74'}
                        tipId="fps-limits"
                        icon={`fas fa-info-circle fa-md`}
                        cursor="cursor-pt"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <Alert key={'danger'} variant={'danger'}>
              {errorData.body}
            </Alert>
          )}
        </Modaldemo>
      )}
    </>
  )
}

export default CropButton
