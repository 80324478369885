import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';

/*************************************************************************** 
 * React functional component for page titles and metadata
 ***************************************************************************/
export default function HelmetPageData(props) {

  function helmetData() {
    return (
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title> {props.docTitle} </title>
          <meta name="description" content= {props.metaDesc} />
        </Helmet>
      </HelmetProvider>
    )
  }

  // return the component
  return helmetData()
}