export const jobErrors = {
  // Base 100: Enforcement
  //-----------------------------------------
  //
  UnknownError: {
    code: 100,
    message: 'Animation job failed',
    description: 'Sorry something went wrong processing this job, no animation time will be deducted from your account. If the problem continues please contact DeepMotion Support.'
  },
  // check for not enough anim minutes
  MinutesBalanceTooLow: {
    code: 101,
    message: 'Insufficient minutes balance',
    description: `Sorry, your account doesn't have enough animation time left to complete the job. Please upgrade to a higher tier plan or wait until the next bill cycle to receive more animation time.`
  },
  // check for max video resolution exceeded
  MaxResolutionExceeded: {
    code: 102,
    message: 'Max video resolution is exceeded',
    description: `Sorry, your current subscription plan doesn't support the resolution of your input video. Please upgrade to a higher tier plan that supports your video's resolution or use a video of lower resolution.`
  },
  // check for max video FPS exceeded
  MaxFPSExceeded: {
    code: 103,
    message: 'Max video FPS is exceeded',
    description: `Sorry, your current subscription plan doesn't support the FPS of your input video. Please upgrade to a higher tier plan that supports your video's FPS or use a video with lower FPS.`
  },
  // check for max video duration exceeded
  MaxDurationExceeded: {
    code: 104,
    message: 'Max video duration is exceeded',
    description: `Sorry, your current subscription plan doesn't support the length of your input video. Please upgrade to a higher tier plan that supports the length of your video or use a shorter length video.`
  },
  //-----------------------------------------
  // Base 200: Asset Pre-Processing
  //-----------------------------------------
  VideoOrModelCopyError: {
    code: 201,
    message: 'Error downloading the video or DM asset',
    description: 'Our servers reported an error while trying to process this animation job, no animation time will be deducted from your account. If this problem continues to happen please contact DeepMotion Support.'
  },
  InvalidVideoCodecError: {
    code: 202,
    message: 'Error converting the video',
    description: 'The input video format is not supported. It is recommended to encode the input video with H.264 codec and use .mp4 as the container file format.'
  },
  //-----------------------------------------
  // Base 300: CLI Pipeline
  //-----------------------------------------
  InternalCliPipelineError: {
    code: 300,
    message: 'Internal cli pipeline error',
    description: 'Sorry, we had an internal error with our processing pipeline. If the problem continues please contact Support for help.'
  },
  //-----------------------------------------
  // Base 500: DMBT
  //-----------------------------------------
  FailedToParseArgsError: {
    code: 503,
    message: 'Error processing the parameters',
    description: 'Sorry, we had an internal error processing the tracking parameters. If the problem continues please contact Support for help.'
  },
  FailedToLoadDataError: {
    code: 504,
    message: 'Error loading the character assets',
    description: 'Sorry, we had an internal error loading character assets. Please contact Support for help.'
  },
  ExplosionDetectedError: {
    code: 505,
    message: 'Physics Filter is incompatible',
    description: 'Sorry, A Physics Filter incompatibility was detected when processing this video using the chosen character. Please consider turning off the Physics Filter.'
  },
  FailedToCreatePoseEstimatorError: {
    code: 506,
    message: 'Error creating the pose estimation',
    description: 'Sorry, we had an internal error creating your pose estimation. Please contact Support for help.'
  },
  FailedToCreateBodyTrackerError: {
    code: 507,
    message: 'Error processing the body tracking',
    description: 'Sorry, we had an internal error while performing body tracking for your input. Please contact Support for help.'
  },
  PoseEstimationTrackingError: {
    code: 508,
    message: `Input video or image doesn't meet the requirements`,
    description: `Sorry, your input video or image doesn't meet our requirements to generate animations of good quality.Please review our video capture guidelines and submit a new video that meets the guidelines. If the problem continues please contact Support and send the video or image you used.`
  },
  FailedToLoadConfigError: {
    code: 509,
    message: 'Error loading the configurations',
    description: 'Sorry, we had an internal error loading the job configuration. If the problem continues please contact Support for help.'
  },
  FailedToOpenFileForWritingError: {
    code: 510,
    message: 'Error opening internal files',
    description: 'Sorry, we had an internal error trying to generate animation results. Please contact Support for help.'
  },
  InterruptedError: {
    code: 511,
    message: 'Processing interrupted',
    description: 'Sorry, we had an internal error and body tracking was interrupted. If the problem continues please contact Support for help.'
  },
  FailedToDetectHuman: {
    code: 513,
    message: 'Failed to detect character in the video',
    description: 'Sorry, we were not able to detect a human character in your video. Please be sure your video includes a realistic human character (not a cartoon character), review our video capture guidelines and try again. If the problem continues please contact Support for help.'
  },
  BodyTrackingTimeoutError: {
    code: 599,
    message: 'Body tracking timeout',
    description: 'Body tracking timeout'
  },

  //-----------------------------------------
  // Base 700: DMFT
  //-----------------------------------------
  InternalFaceTrackingError: {
    code: 701,
    message: 'Error processing the face tracking',
    description: 'Sorry, we had an error during face tracking. Please make sure your facial rig meets the requirements or try a new custom character model that supports ARKit Blendshapes.'
  },
  FaceTrackingTimeoutError: {
    code: 799,
    message: 'Face tracking timeout',
    description: 'Face tracking timeout'
  },

  //-----------------------------------------
  // Base 900: Vector CLI
  //-----------------------------------------
  LoadMeshFailedError: {
    code: 901,
    message: 'Error loading the mesh of the custom character',
    description: 'Sorry, we had an error loading the mesh of your custom character. Please make sure your custom character meets the requirements or try a new custom character model. If the problem continues please send your video, custom character model, and this error code to Support for help.'
  },
  LoadBvhFailedError: {
    code: 902,
    message: 'Error loading the BVH custom character',
    description: 'Sorry, we had an error while loading the BVH custom character file. Please make sure your custom character meets the requirements or try a new custom character model. If the problem continues please send your video, custom character model, and this error code to Support for help.'
  },
  CopyAnimFailedError: {
    code: 903,
    message: 'Error copying animations onto the custom character',
    description: 'CopyAnimFailedError: "Sorry, we had an error copying animations onto your custom character. Please make sure your custom character meets the requirements or try a new custom character model. If the problem continues please send your video, custom character model, and this error code to Support for help.'
  },
  ExportFailedError: {
    code: 904,
    message: 'Error exporting animations for the custom character',
    description: 'Sorry, we had an error exporting animations for your custom character. Please make sure your custom character meets the requirements or try a new custom character model. If the problem continues please send your video, custom character model, and this error code to Support for help.'
  },
  MeshNotProvidedError: {
    code: 905,
    message: 'Custom character doesn’t include skinned mesh information',
    description: 'Sorry, your custom character doesn’t include skinned mesh information. Please make sure your custom character meets the requirements or upload a rigged custom character model with skinned mesh in it.'
  },
  BlendShapesLessThanHalfError: {
    code: 906,
    message: 'More than half of the required blendshapes are missing',
    description: 'Sorry, your character is missing more than half of the required blendshapes for Face Tracking. Please make sure your facial rig meets the requirements, or upload a new custom character with the complete set of blendshapes.'
  },
  LoadFaceDefinitionFailedError: {
    code: 907,
    message: 'Error loading facial definition for the custom character',
    description: 'Sorry, we had an internal error loading facial definition for your custom character. Please make sure your facial rig meets requirements or upload a custom character with the complete set of blendshapes. If the problem persists please send your video, custom character model, and this error to Support for help.'
  },
  LoadDMFTDataFailedError: {
    code: 908,
    message: 'Error loading facial tracking data',
    description: 'Sorry, we had an internal error loading facial tracking data. Please make sure your facial rig meets the requirements or upload a new custom character with the complete set of blendshapes. If the problem continues please send your video, custom character model, and this error to Support for help.'
  },
  LoadHumanoidMapError: {
    code: 909,
    message: 'Error loading the metadata of the custom character',
    description: 'Sorry, we had an internal error loading the metadata of your custom character. Please make sure your custom character meets the requirements or try a new custom character.  If the problem continues please send your video, custom character model, and this error to Support for help.'
  },
  AnimationBakingTimeoutError: {
    code: 999,
    message: 'Animation baking timeout',
    description: 'Animation baking timeout'
  },

  //-----------------------------------------
  // Base 1100: Render CLI
  //-----------------------------------------
  RenderCliError: {
    code: 1101,
    message: 'Error rendering result into a video or image',
    description: 'Sorry, we had an internal error while rendering the result into a video or image. Please send your video, custom character model (if any), and this error to Support for help.'
  },
  InvalidInputParameterError: {
    code: 1102,
    message: 'Error rendering result into a video or image',
    description: 'Sorry, we had an internal error while rendering the result into a video or image. Please send your video, custom character model (if any), and this error to Support for help.'
  },
  FailedToLoadOrPlayInputVideoError: {
    code: 1103,
    message: 'Error loading input video or image',
    description: 'Sorry, we had an internal error while loading your input video or image. Please send your video, custom character model (if any), and this error to Support for help.'
  },
  FailedToLoadInputBvhError: {
    code: 1104,
    message: 'Error loading input BVH file',
    description: 'Sorry, we had an internal error while loading the BVH file for video/image rendering. Please send your video, custom character model (if any), and this error to Support for help.'
  },
  FailedToLoadInputCharacterError: {
    code: 1105,
    message: 'Error loading input character',
    description: 'Sorry, we had an internal error while loading your character for the video/image rendering. Please send your video, custom character model (if any), and this error to Support for help.'
  },
  FailedToAttachAnimToCharacterError: {
    code: 1106,
    message: 'Error attaching animation to character',
    description: 'Sorry, we had an internal error attaching your animation to the character for the video/image rendering. Please send your video, custom character model (if any), and this error to Support for help.'
  },
  FailedToConfigureBackDropError: {
    code: 1107,
    message: 'Error configuring backdrop',
    description: 'Sorry, we had an internal error while configuring the backdrop for the video/image rendering. Please send your video, custom character model (if any), and this error to Support for help.'
  },
  FailedToCreateGifError: {
    code: 1108,
    message: 'Error creating gif',
    description: 'Sorry, we had an internal error while rendering GIF output. Please send your video, custom character model (if any), and this error to Support for help.'
  },

  //-----------------------------------------
  // Base 1300: Hand tracking
  //-----------------------------------------
  CreateHandEstimationError: {
    code: 1301,
    message: 'Error creating the hand estimation',
    description: 'Error creating the hand estimation, please try again'
  },
  CreateHandEstimationError1: {
    code: 1302,
    message: 'Error creating the hand estimation',
    description: 'Error creating the hand estimation, please try again'
  },
  CreateHandEstimationError2: {
    code: 1303,
    message: 'Error creating the hand estimation',
    description: 'Error creating the hand estimation, please try again'
  },
  OpenVideoError: {
    code: 1304,
    message: 'Error opening the video',
    description: 'Error opening the video, please try again'
  },
  ParseVideoPathError: {
    code: 1305,
    message: 'Error parsing video path',
    description: 'Error parsing video path, please try again'
  },
  LoadInternalFilesError: {
    code: 1306,
    message: 'Error loading internal files',
    description: 'Error loading internal files, please try again'
  },
  ProcessHandTrackingError: {
    code: 1307,
    message: 'Error processing hand tracking',
    description: 'Error loading internal files, please try again'
  },
  ProcessVideoError: {
    code: 1308,
    message: 'Error processing the video',
    description: 'Error processing the video, please try again'
  },
  HandTrackingTimeoutError: {
    code: 1399,
    message: 'Hand tracking timeout',
    description: 'Error loading internal files, please try again'
  }
}

export function getErrorByCode (code) {
  return Object.values(jobErrors).find(e => e.code == code)
}
