import React, { useState, useContext, useEffect } from "react";
import { AppStateContext } from "../../AppStateContext";
import "./assets/styles/MultiPersonSelectorDownloadDialog.scss";

// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Scrollbar } from "swiper";

SwiperCore.use([Navigation, Scrollbar]);
// image
import imgCustom from "../../images/animate-3d/character-select/model-custom.jpg";
import FaceIcon_No from "../../images/multiPersonTracking/FaceIcon_No_Red.png";
import FaceIcon from "../../images/multiPersonTracking/FaceIcon_White.png";
import HandIcon_No from "../../images/multiPersonTracking/HandIcon_No_Red.png";
import HandIcon from "../../images/multiPersonTracking/HandIcon_White.png";
import GLBIcon_No from "../../images/multiPersonTracking/GLBIcon_No_Red.png";
import GLBIcon from "../../images/multiPersonTracking/GLBIcon_White.png";

import DMDropDown from "../ui/DMDropDown";
import { outputFormats } from "../common/enums";

const titleDownloadAll = "Download All";
const titleDownload = "Download";

export default function MultiPersonSelectorDownloadDialog() {
  const { state } = useContext(AppStateContext);
  // left
  const [disabledSwiperNavigation, setDisabledSwiperNavigation] = useState<
    "left" | "right" | "all" | ""
  >("");
  const downloadLinks = state.animJobLinks.downloadLinks;
  const characters = state.animJobLinks.multiPersonDownloadLinks;
  const multiPersonRid = state.animJobLinks.multiPersonRid;
  const multiJobsData = state.jobsData.find(
    (item) => item.rid === multiPersonRid
  );
  const [downloadAllLinksSelect, setDownloadAllLinksSelect] = useState([]);
  const [downloadAllLinksList, setDownloadAllLinksList] = useState([]);
  const [downloadAllLinks, setDownloadAllLinks] = useState({
    type: "",
    link: "",
  });
  // const [useTPose, setUseTPose] = useState(false);
  const [multiCharacters, setMultiCharacters] = useState([]);

  useEffect(() => {
    const data = [...characters];
    data.forEach((item) => {
      item.data = [];
      item.downloadLink.forEach((element) => {
        item.data.push({ value: element.type, available: true });
      });
      item.value = item.downloadLink[0].type;
      item.link = item.downloadLink[0].link;
    });

    downloadLinks.models.forEach((item) => {
      if (item.name === "all_characters") {
        setDownloadAllLinks(item.links[0]);
        const select = [];

        const selectlinks = item.links.sort((a, b) => {
          if (a.type < b.type) {
            return -1;
          }
          if (a.type > b.type) {
            return 1;
          }
          return 0;
        });
        selectlinks.forEach((element) => {
          select.push({ value: element.type, available: true });

          const search = outputFormats.indexOf(element.type);
          if (data.length === 1 && search !== -1) {
            data[0].data.push({ value: element.type, available: true });
            data[0].downloadLink.push(element);
          }
        });
        setDownloadAllLinks(selectlinks[0]);
        setDownloadAllLinksSelect(select);
        setDownloadAllLinksList(item.links);
      }
    });
    setMultiCharacters(data);
  }, []);

  return (
    <div className="MultiPersonSelectorDownloadDialog detail">
      <div className="columns m-0 p-0 fullwidth">
        <div className="column m-0 p-0 pl-5 pr-5 notification is-info is-light has-text-left">
          <div className="title is-5 pt-5"> {multiJobsData.name} </div>
          <div className="subtitle is-6 pb-5"> {multiJobsData.date} </div>
        </div>
        <div className="column is-4 left-border m-0 p-0 notification is-info is-light flex-vert-center has-text-centered">
          <div className="title pl-2 pr-2 is-5">
            {" "}
            {multiJobsData.length} sec{" "}
          </div>
        </div>
      </div>
      {multiCharacters.length !== 1 && (
        <div className="is-flex is-align-items-center is-justify-content-space-between mx-6 py-5">
          <div className="is-flex is-align-items-center is-justify-content-flex-end m-0 ml-5">
            {/* TODO: This function is unavailable and waiting for update */}
            {/* <span>First Frame T-Pose</span>
          <label className="jobtype-switch ml-3">
            <input
              onChange={() => setUseTPose(!useTPose)}
              type="checkbox"
              checked={useTPose}
            />
            <span
              className={"jobtype-slider" + (useTPose ? " useTPose" : "")}
            />
          </label> */}
          </div>

          <div className="is-flex m-0 mr-5">
            <a
              href={downloadAllLinks.link}
              className="button action-btn fade-in mr-2"
            >
              <span className="no-side-margins">{titleDownloadAll}</span>
            </a>
            {downloadAllLinksList.length && (
              <DMDropDown
                data={downloadAllLinksSelect}
                value={downloadAllLinks.type}
                onChange={(value, cb) => {
                  const data = [...downloadAllLinksList];
                  const item = data.find((item) => item.type === value);
                  setDownloadAllLinks(item);
                  if (cb) {
                    cb();
                  }
                }}
                isUp={false}
                isStyled={true}
                noMargins={true}
                textClass={"is-uppercase"}
              />
            )}
          </div>
        </div>
      )}
      <div className="multi is-flex is-align-items-center detail-swiper">
        <i
          className={
            disabledSwiperNavigation === "left" ||
            disabledSwiperNavigation === ""
              ? "swiper-navigation detail-swiper-prev fas fa-6x fa-chevron-left mr-5 notEnabled"
              : "swiper-navigation detail-swiper-prev fas fa-6x fa-chevron-left mr-5"
          }
        />
        <div className="detail-swiper-centent">
          {multiCharacters && (
            <Swiper
              className="none-select"
              observer={true}
              observeParents={true}
              slidesPerView={multiCharacters.length > 1 ? 2 : 1}
              spaceBetween={20}
              navigation={{
                nextEl: ".detail-swiper-next",
                prevEl: ".detail-swiper-prev",
              }}
              scrollbar={{
                el: ".detail-swiper-scrollbar",
                draggable: true,
                dragSize: 120,
              }}
              onSwiper={(swiper) => {
                if (swiper.activeIndex === 0) {
                  if (characters.length <= 2) {
                    setDisabledSwiperNavigation("");
                  } else {
                    setDisabledSwiperNavigation("left");
                  }
                }
              }}
              onSlideChange={(swiper) => {
                setDisabledSwiperNavigation("all");
                if (swiper.activeIndex === 0) {
                  setDisabledSwiperNavigation("left");
                }
                if (swiper.activeIndex === characters.length - 2) {
                  setDisabledSwiperNavigation("right");
                }
              }}
            >
              {multiCharacters.map((row, i) => {
                const handDataType = row.model.handDataType;
                const faceDataType = row.model.faceDataType;
                const GLBsupport = row.model.platform === "roblox" ? 0 : 1;
                return (
                  <SwiperSlide key={i}>
                    <div className="is-flex">
                      <div className="characterBox p-1">
                        <div className="head p-1 px-2">
                          {Number(row.modelTrackingId)}
                        </div>
                        <div className="is-flex">
                          <img
                            className="characterThumbnail"
                            src={row.characterThumbnail}
                            alt=""
                          />
                        </div>
                        <div className="characterModel mt-2">
                          <div className="head p-1 px-2 is-flex is-align-content-center">
                            <img
                              className="icon m-0 mr-2"
                              src={faceDataType === 1 ? FaceIcon : FaceIcon_No}
                              alt="GLB output is enabled"
                            />
                            <img
                              className="icon m-0 mr-2"
                              src={handDataType === 1 ? HandIcon : HandIcon_No}
                              alt="GLB output is enabled"
                            />
                            <img
                              className="icon m-0"
                              src={GLBsupport === 1 ? GLBIcon : GLBIcon_No}
                              alt="GLB output is enabled"
                            />
                          </div>
                          <img
                            className="characterThumbnail"
                            src={row.model.modelThumb}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="is-flex mt-2">
                      <DMDropDown
                        data={row.data}
                        value={row.value}
                        onChange={(value, cb) => {
                          const data = [...characters];
                          data[i].value = value;
                          const link = data[i].downloadLink.find(
                            (item) => item.type === value
                          );
                          data[i].link = link.link;
                          setMultiCharacters(data);
                          if (cb) {
                            cb();
                          }
                        }}
                        isUp={true}
                        isStyled={true}
                        noMargins={true}
                        textClass={"is-uppercase"}
                      />
                    </div>
                    <div className="is-flex mt-2">
                      <a href={row.link} className="button action-btn fade-in">
                        <span className="no-side-margins">{titleDownload}</span>
                      </a>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          )}
        </div>
        <i
          className={
            disabledSwiperNavigation === "right" ||
            disabledSwiperNavigation === ""
              ? "swiper-navigation detail-swiper-next fas fa-6x fa-chevron-right ml-5 notEnabled"
              : "swiper-navigation detail-swiper-next fas fa-6x fa-chevron-right ml-5"
          }
        />
      </div>
      <div className="detail-swiper-scrollbar" />
    </div>
  );
}
