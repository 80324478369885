import React, { Component } from 'react'
import {
  createNewFeaturePack,
  createNewMinutePack,
  listPacks } from '../../api/apiRequests'
import CustomModal from './components/CustomModal'
import TableButton from './components/TableButton'
import SearchField from './components/SearchField'
import { PackCreateConfirm, LoadingModal, ErrorModal } from './components/ConfirmationDialogue'
import { Pagination, PaginationRowsPerPageDropdown } from '../../common/Pagination'
import * as Enums from '../../common/enums'

export default function CustomPackTab(props) {
  
  const [currentModal, setActiveModal] = React.useState(Enums.activeModal.none)
  const defaultStateObj = Enums.buildStateObj("", false, Enums.inputSpanHide, Enums.normalInputClass)

  // create new minute pack variables
  const [minutePackId, setMinutePackId] = React.useState("")
  const [minutePackName, setMinutePackName] = React.useState(
    Enums.buildStateObj("", false, Enums.inputSpanHide, Enums.normalInputClass)
  )
  const [numMinutesInPack, setNumMinutesInPack] = React.useState(
    Enums.buildStateObj("", false, Enums.inputSpanHide, Enums.normalInputClass)
  )
  const [validDurationMinutePack, setValidDurationMinutePack] = React.useState(
    Enums.buildStateObj("", false, Enums.inputSpanHide, Enums.normalInputClass)
  )

  // create new feature pack state variables
  const [featurePackId, setFeaturePackId] = React.useState("")
  const [featurePackName, setFeaturePackName] = React.useState(
    Enums.buildStateObj("", false, Enums.inputSpanHide, Enums.normalInputClass)
  )
  const [featurePackLogo, setFeaturePackLogo] = React.useState("0")
  const [featurePackFps, setFeaturePackFps] = React.useState(
    Enums.buildStateObj("", false, Enums.inputSpanHide, Enums.normalInputClass)
  )
  const [featurePackVideoWidth, setFeaturePackVideoWidth] = React.useState(
    Enums.buildStateObj("", false, Enums.inputSpanHide, Enums.normalInputClass)
  )
  const [featurePackVideoHeight, setFeaturePackVideoHeight] = React.useState(
    Enums.buildStateObj("", false, Enums.inputSpanHide, Enums.normalInputClass)
  )
  const [featurePackNumCharacters, setFeaturePackNumCharacters] = React.useState(
    Enums.buildStateObj("", false, Enums.inputSpanHide, Enums.normalInputClass)
  )
  const [featurePackNumRerun, setFeaturePackNumRerun] = React.useState(
    Enums.buildStateObj("", false, Enums.inputSpanHide, Enums.normalInputClass)
  )
  const [featurePackVideoLength, setFeaturePackVideoLength] = React.useState(
    Enums.buildStateObj("", false, Enums.inputSpanHide, Enums.normalInputClass)
  )
  const [featurePackPhysFilter, setFeaturePackPhysFilter] = React.useState("0")
  const [featurePackSpdMulti, setFeaturePackSpdMulti] = React.useState("0")
  const [featurePackMotionSmth, setFeaturePackMotionSmth] = React.useState("0")
  const [featurePackDmpe, setFeaturePackDmpe] = React.useState("0")
  const [featurePackFootLock, setFeaturePackFootLock] = React.useState(Enums.footLockMode[0])

  const [activePackData, setActivePackData] = React.useState()
  const [tableChanged, setTableChanged] = React.useState(false)

  ///////////////////////////////////////////////////////////
  // variable storage and input validation
  ///////////////////////////////////////////////////////////
  const storeMinutePackId = event => {
    setMinutePackId(event.target.value)
  }

  const validateMinutePackName = event => {
    let tmpStateObj = Enums.buildStateObj(
      (event.target.value),
      minutePackName.isValid,
      minutePackName.span,
      minutePackName.iClass
    )
    if (tmpStateObj.value && tmpStateObj.value !== "") {
      tmpStateObj.isValid = true
      if( tmpStateObj.iClass.toString() === Enums.missingInputClass ) {
        tmpStateObj.iClass = Enums.normalInputClass
      }
    } else {
      tmpStateObj.isValid = false
    }

    if (!Enums.compareStates(minutePackName, tmpStateObj))
      setMinutePackName(tmpStateObj)
  }

  const storeMinutePackLogo = event => {
    setMinutePackLogo(event.target.value)
  }

  const validateNumMinutes = event => {
    let tmpStateObj = Enums.buildStateObj(
      (event.target.value),
      numMinutesInPack.isValid,
      numMinutesInPack.span,
      numMinutesInPack.iClass
    )
    if (tmpStateObj.value && tmpStateObj.value > 0) {
      tmpStateObj.isValid = true
      if( tmpStateObj.iClass.toString() === Enums.missingInputClass ) {
        tmpStateObj.iClass = Enums.normalInputClass
      }
    } else {
      tmpStateObj.isValid = false
    }

    if (!Enums.compareStates(numMinutesInPack, tmpStateObj))
      setNumMinutesInPack(tmpStateObj)
  }

  const validateMinuteMonths = event => {
    let tmpStateObj = Enums.buildStateObj(
      (event.target.value),
      validDurationMinutePack.isValid,
      validDurationMinutePack.span,
      validDurationMinutePack.iClass
    )
    // -1 is for a pack that does not expire
    if (tmpStateObj.value && (tmpStateObj.value > 0 || tmpStateObj.value == -1)) {
      tmpStateObj.isValid = true
      if( tmpStateObj.iClass.toString() === Enums.missingInputClass ) {
        tmpStateObj.iClass = Enums.normalInputClass
      }
    } else {
      tmpStateObj.isValid = false
    }

    if (!Enums.compareStates(validDurationMinutePack, tmpStateObj))
      setValidDurationMinutePack(tmpStateObj)
  }

  const storeFeaturePackId = event => {
    setFeaturePackId(event.target.value)
  }

  const validateFeaturePackName = event => {
    let tmpStateObj = Enums.buildStateObj(
      (event.target.value),
      featurePackName.isValid,
      featurePackName.span,
      featurePackName.iClass
    )
    if (tmpStateObj.value && tmpStateObj.value !== "" && tmpStateObj.value.length > 0) {
      tmpStateObj.isValid = true
      if( tmpStateObj.iClass.toString() === Enums.missingInputClass ) {
        tmpStateObj.iClass = Enums.normalInputClass
      }
    } else {
      tmpStateObj.isValid = false
    }
    
    if (!Enums.compareStates(featurePackName, tmpStateObj))
      setFeaturePackName(tmpStateObj)
  }

  const storeFeaturePackLogo = event => {
    setFeaturePackLogo(event.target.value)
  }

  const validateFeaturePackFps = event => {
    let tmpStateObj = Enums.buildStateObj (
      (event.target.value),
      featurePackFps.isValid,
      featurePackFps.span,
      featurePackFps.iClass
    )
    // should the FPS be divisible by 30?
    if (tmpStateObj.value && parseInt(tmpStateObj.value) > 0) {
      tmpStateObj.isValid = true
      if( tmpStateObj.iClass.toString() === Enums.missingInputClass ) {
        tmpStateObj.iClass = Enums.normalInputClass
      }
    } else {
      tmpStateObj.isValid = false
    }
    
    if (!Enums.compareStates(featurePackFps, tmpStateObj))
      setFeaturePackFps(tmpStateObj)
  }

  const validateFeaaturePackVideoWidth = event => {
    let tmpStateObj = Enums.buildStateObj (
      (event.target.value),
      featurePackVideoWidth.isValid,
      featurePackVideoWidth.span,
      featurePackVideoWidth.iClass
    )
    if (tmpStateObj.value && parseInt(tmpStateObj.value) > 0) {
      tmpStateObj.isValid = true
      if( tmpStateObj.iClass.toString() === Enums.missingInputClass ) {
        tmpStateObj.iClass = Enums.normalInputClass
      }
    } else {
      tmpStateObj.isValid = false
    }
    
    if (!Enums.compareStates(featurePackVideoWidth, tmpStateObj))
      setFeaturePackVideoWidth(tmpStateObj)
  }

  const validateFeaturePackVideoHeight = event => {
    let tmpStateObj = Enums.buildStateObj (
      (event.target.value),
      featurePackVideoHeight.isValid,
      featurePackVideoHeight.span,
      featurePackVideoHeight.iClass
    )
    if (tmpStateObj.value && parseInt(tmpStateObj.value) > 0) {
      tmpStateObj.isValid = true
      if( tmpStateObj.iClass.toString() === Enums.missingInputClass ) {
        tmpStateObj.iClass = Enums.normalInputClass
      }
    } else {
      tmpStateObj.isValid = false
    }
    
    if (!Enums.compareStates(featurePackVideoHeight, tmpStateObj))
      setFeaturePackVideoHeight(tmpStateObj)
  }

  const validateFeaturePackNumCharacters = event => {
    let tmpStateObj = Enums.buildStateObj (
      (event.target.value),
      featurePackNumCharacters.isValid,
      featurePackNumCharacters.span,
      featurePackNumCharacters.iClass
    )
    if (tmpStateObj.value && parseInt(tmpStateObj.value) >= 0) {
      tmpStateObj.isValid = true
      if( tmpStateObj.iClass.toString() === Enums.missingInputClass ) {
        tmpStateObj.iClass = Enums.normalInputClass
      }
    } else {
      tmpStateObj.isValid = false
    }
    
    if (!Enums.compareStates(featurePackNumCharacters, tmpStateObj))
      setFeaturePackNumCharacters(tmpStateObj)
  }

  const validateFeaturePackNumRerun = event => {
    let tmpStateObj = Enums.buildStateObj (
      (event.target.value),
      featurePackNumRerun.isValid,
      featurePackNumRerun.span,
      featurePackNumRerun.iClass
    )
    if (tmpStateObj.value && parseInt(tmpStateObj.value) >= 0) {
      tmpStateObj.isValid = true
      if( tmpStateObj.iClass === Enums.missingInputClass ) {
        tmpStateObj.iClass = Enums.normalInputClass
      }
    } else {
      tmpStateObj.isValid = false
    }
    
    if (!Enums.compareStates(featurePackNumRerun, tmpStateObj))
      setFeaturePackNumRerun(tmpStateObj)
  }

  const validateFeaturePackVideoLength = event => {
    let tmpStateObj = Enums.buildStateObj (
      (event.target.value),
      featurePackVideoLength.isValid,
      featurePackVideoLength.span,
      featurePackVideoLength.iClass
    )
    if (tmpStateObj.value && parseInt(tmpStateObj.value) > 0) {
      tmpStateObj.isValid = true
      if( tmpStateObj.iClass.toString() === Enums.missingInputClass ) {
        tmpStateObj.iClass = Enums.normalInputClass
      }
    } else {
      tmpStateObj.isValid = false
    }

    if (!Enums.compareStates(featurePackVideoLength, tmpStateObj))
      setFeaturePackVideoLength(tmpStateObj)
  }

  const storeFeaturePackPhysicsFilter = event => {
    setFeaturePackPhysFilter(event.target.value)
  }
  
  const storeFeaturePackSpeedMultiplier = event => {
    setFeaturePackSpdMulti(event.target.value)
  }

  const storeFeaturePackMotionSmoothing = event => {
    setFeaturePackMotionSmth(event.target.value)
  }

  const storeFeaturePackDmpe = event => {
    setFeaturePackDmpe(event.target.value)
  }

  const storeFeaturePackFootLocking = event => {
    setFeaturePackFootLock(event.target.value)
  }

  ///////////////////////////////////////////////////////////
  // Highlight errors in pack creation modal
  ///////////////////////////////////////////////////////////
  const highlightErrors = (state, setState) => {
    let tmpStateObj = Enums.buildStateObj(
      state.value,
      state.isValid,
      state.span,
      state.iClass
    )
    if (!tmpStateObj.isValid) {
      tmpStateObj.span = Enums.inputSpanHide
      tmpStateObj.iClass = Enums.missingInputClass
    } else {
      tmpStateObj.span = Enums.inputSpanShowSuccess
    }

    setState(tmpStateObj)
  }

  ///////////////////////////////////////////////////////////
  // Reset feature pack values
  ///////////////////////////////////////////////////////////
  function resetCreateMinutePackData() {
    setMinutePackId("")
    setMinutePackName(defaultStateObj)
    setNumMinutesInPack(defaultStateObj)
    setValidDurationMinutePack(defaultStateObj)
  }

  function resetCreateFeaturePackData() {
    setFeaturePackId("")
    setFeaturePackName(defaultStateObj)
    setFeaturePackLogo("0")
    setFeaturePackFps(defaultStateObj)
    setFeaturePackVideoWidth(defaultStateObj)
    setFeaturePackVideoHeight(defaultStateObj)
    setFeaturePackNumCharacters(defaultStateObj)
    setFeaturePackNumRerun(defaultStateObj)
    setFeaturePackVideoLength(defaultStateObj)
    setFeaturePackPhysFilter("0")
    setFeaturePackSpdMulti("0")
    setFeaturePackMotionSmth("0")
    setFeaturePackFootLock(Enums.footLockMode[0])
  }

  ///////////////////////////////////////////////////////////
  // search function
  ///////////////////////////////////////////////////////////
  function performSearch(searchTerm) {
    if (searchTerm === "") {
      props.setTable(props.unfilteredTable)
      props.setNumPages(Math.ceil(props.unfilteredTable.length / props.numRows))
      return
    }

    console.log(`search term: ${searchTerm}`)
    let filteredData = []
    props.unfilteredTable.forEach(function(item) {
      let searchVal = parseInt(searchTerm)
      if (item.id.includes(searchTerm)) {
        filteredData.push(item)
      } else if (props.tableType === Enums.packTableType.minutePack) {
        if (searchVal === item.minutes) {
          filteredData.push(item)
        }
      } else if (props.tableType === Enums.packTableType.featurePack) {
        if (searchVal === item.max_video_fps || searchVal === item.max_video_height || 
          searchVal === item.max_video_width || searchVal === item.max_custom_character) {
          filteredData.push(item)
        }
      }
    })
    props.setNumPages(Math.ceil(filteredData.length / props.numRows))
    props.setTable(filteredData)
  }

  function setActiveDataAndEnableModal(rowData) {
    setActivePackData(rowData)
    setActiveModal(Enums.activeModal.packDetailView)
  }

  function submitNewMinutePack() {
    if (minutePackName.isValid && numMinutesInPack.isValid && validDurationMinutePack.isValid) {
      setActiveModal(Enums.activeModal.loadingModal)
      createNewMinutePack(minutePackId, minutePackName.value, numMinutesInPack.value, validDurationMinutePack.value)
      .then((data) => {
        setActiveModal(Enums.activeModal.packCreateConfirmation)
        props.setDataLoaded(false)
        setTableChanged(true)
      }).catch((error) => {
        props.processError(error)
        setActiveModal(Enums.activeModal.failureModal)
      })
    }
  }

  function submitNewFeaturePack() {
    console.log('feature pack function being sent')
    if (featurePackName.isValid && featurePackFps.isValid 
        && featurePackVideoWidth.isValid && featurePackVideoHeight.isValid
        && featurePackNumCharacters.isValid && featurePackVideoLength.isValid
        && featurePackNumRerun.isValid) {
      setActiveModal(Enums.activeModal.loadingModal)
      createNewFeaturePack(
        featurePackId,
        featurePackName.value,
        featurePackLogo,
        featurePackFps.value,
        featurePackVideoWidth.value,
        featurePackVideoHeight.value,
        featurePackNumCharacters.value,
        featurePackNumRerun.value,
        featurePackVideoLength.value,
        featurePackPhysFilter,
        featurePackSpdMulti,
        featurePackMotionSmth,
        featurePackDmpe,
        featurePackFootLock
      )
      .then((res) => {
        setActiveModal(Enums.activeModal.packCreateConfirmation)
        props.setDataLoaded(false)
        setTableChanged(true)
      }).catch((error) => {
        props.processError(error)
        setActiveModal(Enums.activeModal.failureModal)
      })
    }
  }

  function buildButtonGroup() {
    let minuteBtnClass = "button"
    let featureBtnClass = "button"
    if (props.tableType === Enums.packTableType.minutePack) {
      featureBtnClass += " is-hidden"
    } else {
      minuteBtnClass += " is-hidden"
    }
    return(
      <div className="buttons is-centered is-grouped">
        <button
          className={minuteBtnClass}
          onClick={() => setActiveModal(Enums.activeModal.createMinutePack)}
        >
          Create Minutes Pack
        </button>
        <button
          className={featureBtnClass}
          onClick={() => setActiveModal(Enums.activeModal.createFeaturePack)}
        >
          Create Feature Pack
        </button>
      </div>
    )
  }

  function regenerateTableData(dataType) {
    props.setTableType(dataType)
    props.setDataLoaded(false)
    setTableChanged(true)
    performSearch("")
  }

  function buildTableTypeSelect() {
    let minuteBtnClass = "button" 
    let featureBtnClass = "button"
    if (props.tableType === Enums.packTableType.minutePack) {
      minuteBtnClass += " is-link is-selected"
    } else {
      featureBtnClass += " is-link is-selected"
    }

    return(
      <div className="buttons is-centered has-addons">
        <button
          className={minuteBtnClass}
          onClick={() => regenerateTableData(Enums.packTableType.minutePack)}
        >
          Display Minute Packs
        </button>
        <button
          className={featureBtnClass}
          onClick={() => regenerateTableData(Enums.packTableType.featurePack)}
        >
          Display Feature Packs
        </button>
      </div>
    )
  }

  function buildMinutePackModal() {
    return(
      <section className="modal-card-body">
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">ID (Optional)</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded">
                <input className="input" onChange={storeMinutePackId} type="text" placeholder="ID" value={minutePackId}></input>
              </p>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Name</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded has-icons-right">
                <input className={minutePackName.iClass} onChange={validateMinutePackName} onBlur={() => highlightErrors(minutePackName, setMinutePackName)} type="text" placeholder="Name" value={minutePackName.value}></input>
                <span className={minutePackName.span}>
                  <i className="fas fa-check"></i>
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Number of Minutes</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded has-icons-right">
                <input className={numMinutesInPack.iClass} onChange={validateNumMinutes} onBlur={() => highlightErrors(numMinutesInPack, setNumMinutesInPack)} type="number" placeholder="0 minutes" value={numMinutesInPack.value}></input>
                <span className={numMinutesInPack.span}>
                  <i className="fas fa-check"></i>
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Valid Duration</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded has-icons-right">
                <input className={validDurationMinutePack.iClass} onChange={validateMinuteMonths} onBlur={() => highlightErrors(validDurationMinutePack, setValidDurationMinutePack)} type="number" placeholder="0 months" value={validDurationMinutePack.value}></input>
                <span className={validDurationMinutePack.span}>
                  <i className="fas fa-check"></i>
                </span>
              </p>
            </div>
          </div>
        </div>
      </section>
    )
  }

  function buildFeaturePackModal() {
    let footLockOptions = []
    Object.values(Enums.footLockMode).forEach((value, index) => {
      footLockOptions.push(
        <option value={value} key={index}>{Enums.capitalizeFirstLetter(value)}</option>
      )
    })
    return(
      <section className="modal-card-body">
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">ID (Optional)</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded">
                <input className="input" onChange={storeFeaturePackId} type="text" placeholder="ID" value={featurePackId}></input>
              </p>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Name</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded has-icons-right">
                <input className={featurePackName.iClass} onChange={validateFeaturePackName} onBlur={() => highlightErrors(featurePackName, setFeaturePackName)} type="text" placeholder="Name" value={featurePackName.value}></input>
                <span className={featurePackName.span}>
                  <i className="fas fa-check"></i>
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Use Logo</label>
          </div>
          <div className="field-body">
            <div className="field is-narrow">
              <div className="control">
                <div className="select is-fullwidth">
                  <select value={featurePackLogo} onChange={storeFeaturePackLogo}>
                    <option value="0">No Logo</option>
                    <option value="1">Use Logo</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Video FPS</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded has-icons-right">
                <input className={featurePackFps.iClass} onChange={validateFeaturePackFps} onBlur={() => highlightErrors(featurePackFps, setFeaturePackFps)} type="number" placeholder="30" value={featurePackFps.value}></input>
                <span className={featurePackFps.span}>
                  <i className="fas fa-check"></i>
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Max Video Width</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded has-icons-right">
                <input className={featurePackVideoWidth.iClass} onChange={validateFeaaturePackVideoWidth} onBlur={() => highlightErrors(featurePackVideoWidth, setFeaturePackVideoWidth)} type="number" placeholder="1920" value={featurePackVideoWidth.value}></input>
                <span className={featurePackVideoWidth.span}>
                  <i className="fas fa-check"></i>
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Max Video Height</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded has-icons-right">
                <input className={featurePackVideoHeight.iClass} onChange={validateFeaturePackVideoHeight} onBlur={() => highlightErrors(featurePackVideoHeight, setFeaturePackVideoHeight)} type="number" placeholder="1080" value={featurePackVideoHeight.value}></input>
                <span className={featurePackVideoHeight.span}>
                  <i className="fas fa-check"></i>
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Max Video Length in Seconds</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded has-icons-right">
                <input className={featurePackVideoLength.iClass} onChange={validateFeaturePackVideoLength} onBlur={() => highlightErrors(featurePackVideoLength, setFeaturePackVideoLength)} type="number" placeholder="10" value={featurePackVideoLength.value}></input>
                <span className={featurePackVideoLength.span}>
                  <i className="fas fa-check"></i>
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Max Custom Characters</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded has-icons-right">
                <input className={featurePackNumCharacters.iClass} onChange={validateFeaturePackNumCharacters} onBlur={() => highlightErrors(featurePackNumCharacters, setFeaturePackNumCharacters)} type="number" placeholder="5" value={featurePackNumCharacters.value}></input>
                <span className={featurePackNumCharacters.span}>
                  <i className="fas fa-check"></i>
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Max Number of Reruns</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded has-icons-right">
                <input className={featurePackNumRerun.iClass} onChange={validateFeaturePackNumRerun} onBlur={() => highlightErrors(featurePackNumRerun, setFeaturePackNumRerun)} type="number" placeholder="0" value={featurePackNumRerun.value}></input>
                <span className={featurePackNumRerun.span}>
                  <i className="fas fa-check"></i>
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Allow Physics Filter</label>
          </div>
          <div className="field-body">
            <div className="field is-narrow">
              <div className="control">
                <div className="select is-fullwidth">
                  <select value={featurePackPhysFilter} onChange={storeFeaturePackPhysicsFilter}>
                    <option value="0">No</option>
                    <option value="1">Yes</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Allow Speed Multiplier</label>
          </div>
          <div className="field-body">
            <div className="field is-narrow">
              <div className="control">
                <div className="select is-fullwidth">
                  <select value={featurePackSpdMulti} onChange={storeFeaturePackSpeedMultiplier}>
                    <option value="0">No</option>
                    <option value="1">Yes</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Allow Motion Smoothing</label>
          </div>
          <div className="field-body">
            <div className="field is-narrow">
              <div className="control">
                <div className="select is-fullwidth">
                  <select value={featurePackMotionSmth} onChange={storeFeaturePackMotionSmoothing}>
                    <option value="0">No</option>
                    <option value="1">Yes</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Allow DMPE</label>
          </div>
          <div className="field-body">
            <div className="field is-narrow">
              <div className="control">
                <div className="select is-fullwidth">
                  <select value={featurePackDmpe} onChange={storeFeaturePackDmpe}>
                    <option value="0">No</option>
                    <option value="1">Yes</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Foot Locking Level</label>
          </div>
          <div className="field-body">
            <div className="field is-narrow">
              <div className="control">
                <div className="select is-fullwidth">
                  <select value={featurePackFootLock} onChange={storeFeaturePackFootLocking}>
                    {footLockOptions}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
  
  function buildPackDetailModal() {
    let modalBodyInfo
    if (props.tableType === Enums.packTableType.minutePack) {
      modalBodyInfo = 
        <div className="content">
          <p><b>Plan ID:</b> {activePackData.id}</p>
          <p><b>Minutes:</b> {activePackData.minutes}</p>
          <p><b>Duration:</b> {`${activePackData.duration} months`}</p>
          <p><b>Renewable:</b> {activePackData.renewable === 1 ? "Yes" : "No"}</p>
        </div>
    } else {
      let footLockingStr = ''
      if (Array.isArray(activePackData.foot_locking)) {
        activePackData.foot_locking.forEach(mode => {
          if (footLockingStr.length > 0) footLockingStr += ', '
          footLockingStr += Enums.capitalizeFirstLetter(mode) 
        })
      } else {
        footLockingStr = Enums.capitalizeFirstLetter(activePackData.foot_locking)
      }
      modalBodyInfo = 
        <div className="content">
          <p><b>Plan ID:</b> {activePackData.id}</p>
          <p><b>Logo:</b> {activePackData.logo === 1 ? "Yes" : "No"}</p>
          <p><b>Maximum Video FPS:</b> {activePackData.max_video_fps}</p>
          <p><b>Maximum Video Width:</b> {activePackData.max_video_width}</p>
          <p><b>Maximum Video Height:</b> {activePackData.max_video_height}</p>
          <p><b>Max Video Length (in seconds):</b> {activePackData.max_video_length}</p>
          <p><b>Allowed Custom Characters:</b> {activePackData.max_custom_character}</p>
          <p><b>Max Rerun:</b> {activePackData.max_rerun}</p>
          <p><b>Physics Filter:</b> {activePackData.physics_filter === 1 ? "Yes" : "No"}</p>
          <p><b>Speed Multiplier:</b> {activePackData.speed_multiplier === 1 ? "Yes" : "No"}</p>
          <p><b>DMPE:</b> {activePackData.dmpe === 1 ? "Yes" : "No"}</p>
          <p><b>Foot Locking Modes:</b> {footLockingStr}</p>
          <p><b>Renewable:</b> {activePackData.renewable === 1 ? "Yes" : "No"}</p>
        </div>
    }

    return(
      <section className="modal-card-body">
        {modalBodyInfo}
      </section>
    )
  }

  function closeSuccessModal() {
    resetCreateFeaturePackData()
    resetCreateMinutePackData()
    setActiveModal(Enums.activeModal.none)
  }
  
  function displayModal() {
    switch(currentModal) {
      case Enums.activeModal.none:
        return false
      
      case Enums.activeModal.createMinutePack:
        return(
          <CustomModal {...props}
            title="Create Minutes Pack"
            func={submitNewMinutePack}
            modalBody={() => buildMinutePackModal()}
            closeModal={() => setActiveModal(Enums.activeModal.none)}
          />
        )

      case Enums.activeModal.createFeaturePack:
        return(
          <CustomModal {...props}
            title="Create Feature Pack"
            func={submitNewFeaturePack}
            modalBody={() => buildFeaturePackModal()}
            closeModal={() => setActiveModal(Enums.activeModal.none)}
          />
        )

      case Enums.activeModal.packCreateConfirmation:
        let pName = props.tableType === Enums.packTableType.minutePack ? minutePackName.value : featurePackName.value
        return(
          <CustomModal {...props}
            title={`${props.tableType} Created`}
            func="OK"
            modalBody={() => PackCreateConfirm(props.tableType, pName)}
            closeModal={() => closeSuccessModal()}
          />
        )
      
      case Enums.activeModal.packDetailView:
        return(
          <CustomModal {...props}
            title={`${props.tableType} Details`}
            modalBody={() => buildPackDetailModal()}
            closeModal={() => setActiveModal(Enums.activeModal.none)}
          />
        )

      case Enums.activeModal.loadingModal:
        return(
          <CustomModal {...props}
            title="Loading..."
            modalBody={() => LoadingModal()}
            closeModal={() => setActiveModal(Enums.activeModal.none)}
          />
        )

      case Enums.activeModal.failureModal:
        return(
          <CustomModal {...props}
            title="Error"
            modalBody={() => ErrorModal(props.errCode)}
            closeModal={() => setActiveModal(Enums.activeModal.none)}
          />
        )
    }
  }

  function getPackTableData() {
    listPacks((props.tableType === Enums.packTableType.featurePack) ? 1 : 0)
    .then((res) => {
      // console.log(res.data)
      props.setTable(res.data)
      props.setNumPages(Math.ceil(res.data.length / props.numRows))
      props.setUnfilteredTable(res.data)
      props.setDataLoaded(true)
      setTableChanged(false)
    }).catch((error) => {
      console.log(`Error: Could not retrieve pack data for ${props.tableType} - ${error}`)
      props.setDataLoaded(false)
    })
  }

  function buildPackTable() {
    if (!props.isDataLoaded) {
      return
    }
    // calculate page range to display
    let lowRange = ((props.currPage-1)*props.numRows) + 1
    let highRange = ((props.currPage-1)*props.numRows) + props.numRows
    if (highRange > props.table.length) {
      highRange = props.table.length
    }

    return(
      <div className="section">
        <div className="columns is-centered">
          <div className="column is-narrow">
            <div className="table-container">
              <table className="table is-striped is-hoverable">
                <thead>
                  <tr>
                    <th title="Pack Name">Available Packs</th>
                    <th title="Minutes">View Pack Info</th>
                  </tr>
                </thead>
                {buildPackTableRows()}
              </table>
            </div>
          </div>
        </div>
        <div className="columns is-mobile">
          {/* pagination pages and prev/next buttons */}
          <Pagination {...props}
            totalPages={props.numPages}
            setPageFunction={(page) => props.setCurrPage(page)}
            currTablePage={props.currPage}
            tableDataLength={props.table.length}
            rowsToDisplay={props.numRows}
          />

          {/* dropdown to select rows per page */}
          <div className="column has-text-right">
          <h5 className="subtitle is-5">Show rows: 
              <PaginationRowsPerPageDropdown {...props}
                rowsToDisplay={props.numRows}
                setRowsToDisplay={(row)=>props.setNumRows(row)}
                setNumPages={(pages)=>props.setNumPages(pages)}
                tableSize={props.table.length}
                setCurrPage={(page)=>props.setCurrPage(page)}
                currPage={props.currPage}
              />
            </h5>
          </div>

          {/* show user range of current page */}
          <div className="column has-text-left">
            <h5 className="subtitle is-5">Showing {lowRange} to {highRange} / {props.table.length}</h5>
          </div>
        </div>
      </div>
    )
  }

  function buildPackTableRows() {
    if(!props.isDataLoaded && !props.table) {
      return false
    }

    let packsOnPage = []
    let index = 0

    for (let i = 0; i < props.numRows; i++) {
      index = i + ((props.currPage-1)*props.numRows)
      if (index > props.table.length - 1) {
        break
      }

      let pack = props.table [ index ]
      const pid = pack.id
      const key = pid + index.toString()

      packsOnPage.push(
        <tr key={key}>
          <td>{pid}</td>
          <td>
            <TableButton
              label="Details"
              modalFunction={() => setActiveDataAndEnableModal(pack)}
            />
          </td>
        </tr>
      )
    }

    return(
      <tbody>
        {packsOnPage}
      </tbody>
    )
  }

  React.useEffect(() => {

    if (!props.isDataLoaded) {
      // console.log(`starting to get pack table data for ${props.tableType}`)
      props.setTable([])
      setActivePackData({})
      getPackTableData()
      props.setDataLoaded(true)
      setTableChanged(false)
    }

  }, [
    currentModal,
    minutePackId,
    minutePackName,
    numMinutesInPack,
    validDurationMinutePack,
    featurePackId,
    featurePackName,
    featurePackLogo,
    featurePackFps,
    featurePackVideoWidth,
    featurePackVideoHeight,
    featurePackNumCharacters,
    featurePackNumRerun,
    featurePackVideoLength,
    featurePackPhysFilter,
    featurePackSpdMulti,
    featurePackMotionSmth,
    featurePackDmpe,
    featurePackFootLock,
    activePackData,
    tableChanged
  ])

  return(
    <div className="container">

      <div className="columns">
        <div className="column">
          <SearchField {...props}
            query={(term) => performSearch(term)}
          />
        </div>
        <div className="column">
          {buildButtonGroup()}
        </div>            
      </div>
      
      {buildTableTypeSelect()}
      {buildPackTable()}
      {displayModal()}
    </div>
  )
}