/**
 *
 * @param videoFile {File} // the video file
 * @param numberOfThumbnails {number} //number of thumbnails you want to generate
 * @returns {string[]} // an array of base64 thumbnails images
 *
 */
export const generateVideoThumbnails = async (videoFile, numberOfThumbnails) => {
    let video = document.createElement("video")
    video.preload = "metadata"
    video.src = await importFileAndPreview(videoFile)
    video.muted = true
    video.crossOrigin = "Anonymous"
    video.playsInline = true
    // This is necessary to trigger the canplay event in safari
    video.currentTime = 1

    return new Promise((resolve) => {
        const onCanplay = async () => {
            console.debug('canplay')
            video.removeEventListener('canplay', onCanplay)
            let images = []
            for (let i = 0; i < numberOfThumbnails; i++) {
                const skipToTime = (video.duration / numberOfThumbnails) * i
                try {
                    const res = await generateVideoThumbnail(video, skipToTime)
                    images.push(res)
                }catch (e){
                    video = null
                    reject(e)
                    return
                }
            }
            video = null
            resolve(images)
        }
        video.addEventListener("canplay", onCanplay)
    })
}

const generateVideoThumbnail = async (video, skipToTime, imageType = "image/jpeg") => {
    return new Promise((resolve, reject) => {
        const onSeeked = async function () {
            try {
                video.removeEventListener("seeked", onSeeked);
                const res = await captureVideoPoster(video, imageType)
                resolve(res)
            } catch (err) {
                reject(err)
            }
        }
        video.currentTime = skipToTime
        video.addEventListener('seeked', onSeeked)
    })
}

const captureVideoPoster = (video, imageType) => {
    return new Promise((resolve, reject) => {
        if (!video) {
            reject('not a valid video file')
            return
        }
        const width = video.videoWidth;
        const height = video.videoHeight;
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = width;
        canvas.height = height;

        ctx.drawImage(video, 0, 0, width, height);

        ctx.canvas.toBlob(blob => {
            if(!blob){
                reject('can not capture the thumbnail')
                return
            }
            const reader = new FileReader;
            reader.readAsDataURL(blob);
            reader.onloadend = async function () {
                const base64data = reader.result;
                resolve(base64data)
            }
        }, imageType, 1)
    })
};


export const importFileAndPreview = async (file) => {
    return new Promise((resolve) => {
        window.URL = window.URL || window.webkitURL;
        const preview = window.URL.createObjectURL(file);
        setTimeout(() => {
            resolve(preview)
        }, 100)
    })
};
