import { AppState, Action } from "../types";
import * as Enums from "./components/common/enums";
import { initialAppState } from "./components/common/initialAppState";

/////////////////////////////////////////////////////////////////////
// Reducer function that allows for more complex state management
// note: function accepts 2 different formats for the |action| param:
//    1. {...STATE} - an object of 1 or more STATE key-value pairs
//    2. {dispatchType: "string", action: {...STATE} } - where the
//      |dispatchPatch| key can be used for custom state updates.
/////////////////////////////////////////////////////////////////////
export const stateReducer = (state: AppState, action: Action): AppState => {
  let nextState: AppState;
  if (!("dispatchType" in action)) {
    // if dispatchType key not present then update state using
    // the |action| object parameter
    nextState = { ...state, ...action };
  } else {
    // otherwise custom update:
    switch (action.dispatchType) {
      case "initializeAccountInfo":
        nextState = {
          ...state,
          ...action.payload,
          ...{ accountDataRetrieved: true },
        };
        break;
      case "initializeService":
        nextState = {
          ...state,
          ...action.payload,
          ...{ anim3dInitialized: true },
        };
        break;
      case "initializeLibrary":
        nextState = {
          ...state,
          ...action.payload,
          ...{ libraryInitialized: true },
        };
        break;
      case "initialize3dModelsPages":
        // side effect of initializing 3d models page is it also gets needed data for the general service
        nextState = { ...state, ...action.payload };
        break;
      case "DIALOG_AnimDownloadDefaultModel":
        const newState = {
          ...action.payload,
          ...{ confirmDialogId: Enums.confirmDialog.standardDownload },
        };
        nextState = { ...state, ...newState };
        break;
      case "DIALOG_MultiPersonAnimDownloadCustomModel":
        nextState = {
          ...state,
          ...action.payload,
          ...{ confirmDialogId: Enums.confirmDialog.customMultiPersonDownload },
        };
        break;
      case "DIALOG_AnimDownloadCustomModel":
        nextState = {
          ...state,
          ...action.payload,
          ...{ confirmDialogId: Enums.confirmDialog.customDownload },
        };
        break;
      case "resetModelsPageAndDialogInfo":
        let payload =
          location.pathname === Enums.routes.Anim3dModelManage
            ? { pageState_CharacterManage: Enums.pageState.init }
            : {};
        payload = {
          ...payload,
          ...{ errorDialogInfo: { show: false, id: null, title: "", msg: "" } },
        };
        nextState = { ...state, ...(payload as any) };
        break;
      case "reset":
        // resets entire app state
        nextState = { ...state, ...(initialAppState as any) };
        break;
      default:
        console.error(
          `Invalid state dispatch type passed: ${action.dispatchType}`
        );
        nextState = null;
    }
  }
  return nextState;
};
