import React, { useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Pause_Icon from '../../images/videotrim/Pause_Icon.png'
import Play_Icon from '../../images/videotrim/Play_Icon.png'
function Modaldemo({
  children,
  clickHandle,
  show,
  setShow,
  setCheck,
  errorData,
  setErrorData,
  isPlaying,
  playVideo,
  pauseVideo,
  hideCloseButton
}) {
  useEffect(() => {
    return () => {
      pauseVideo()
    }
  }, [])
  const handleClose = () => {
    setCheck(false)
    setShow(false)
    // setCrop({
    //   height: 100,
    //   unit: '%',
    //   width: 100,
    //   x: 0,
    //   y: 0
    // })
    setErrorData({ title: '', body: '' })
  }

  return (
    <>
      <Modal
        className="video-modal"
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
        animation={false}
        backdrop="static"
      >
        <Modal.Header closeButton={!hideCloseButton}>
          <Modal.Title>
            {errorData.title !== ''
              ? errorData.title
              : 'Trim and Crop Your Video'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>
          {/* <Button
            variant="secondary"
            className="close-btn"
            onClick={handleClose}
          > */}
          {errorData.title === '' && (
            <div className="play-button none-select">
              {isPlaying ? (
                <img src={Pause_Icon} onClick={pauseVideo} alt="" />
              ) : (
                // <HiOutlinePause onClick={pausevideo} />
                <img src={Play_Icon} onClick={playVideo} alt="" />
                // <FaRegPlayCircle onClick={playvideo} />
              )}
            </div>
          )}

          {/* </Button> */}
          {errorData.title === '' && (
            <div className="save-btn none-select">
              <Button
                variant="primary"
                className="crop-btn"
                onClick={(e) => clickHandle(e)}
                // disabled={!ready}
              >
                Save
              </Button>
            </div>
          )}
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Modaldemo
