import Nouislider from "nouislider-react";
import React, { useEffect, useState } from "react";
import "./newrange.scss";
import "nouislider/distribute/nouislider.css";
import { fordualrangeslider, millisToMinutesAndSeconds } from "./timmer";

function Newrange({
  start,
  end,
  setTimings,
  newChangeSlide,
  refData,
  timings,
  dynamicData,
  videoDuration,
  setSlider,
  slider,
  dynamicDataForRightSlide,
  setSlideNew,
  setSliderPoints,
  sliderPoints,
  setTimeFormat,
  toHHMMSS,
}) {
  const [timeduration, settimeduration] = useState({ start: start, end: end });
  function getVals() {
    // Get slider values
    var parent = this.parentNode;
    var slides = parent.getElementsByTagName("input");
    var slide1 = parseFloat(slides[0].value);
    var slide2 = parseFloat(slides[1].value);
    if (slide1 > slide2) {
      var tmp = slide2;
      slide2 = slide1;
      slide1 = tmp;
    }

    var displayElement = parent.getElementsByClassName("rangeValues")[0];
    displayElement.innerHTML = "$ " + slide1 + "k - $" + slide2 + "k";
  }
  window.onload = function () {
    // Initialize Sliders
    var sliderSections = document.getElementsByClassName("range-slider");
    for (var x = 0; x < sliderSections.length; x++) {
      var sliders = sliderSections[x].getElementsByTagName("input");
      for (var y = 0; y < sliders.length; y++) {
        if (sliders[y].type === "range") {
          sliders[y].oninput = getVals;
          // Manually trigger event first time to display values
          sliders[y].oninput();
        }
      }
    }
  };
  function changehandle(e) {
    if (e.target.name == "start") {
      const val = Math.min(Number(e.target.value), timeduration.end - 1000);
    }
    if (e.target.name == "end") {
      const val2 = Math.max(Number(e.target.value), timeduration.start + 1000);
    }
    setTimings([
      {
        start: Number(timeduration.start) / 1000,
        end: Number(timeduration.end) / 1000,
      },
    ]);
  }

  return !newChangeSlide ? (
    <section className="range-slider">
      <span className="rangeValues" />
      <span className="start current-time">
        {millisToMinutesAndSeconds(timeduration.start)}
      </span>{" "}
      <div className="croparea">
        <input
          name="start"
          value={timeduration.start}
          
          min={0}
          max={end}
          step={"100"}
          type="range"
          onChange={changehandle}
        />
        <input
          value={timeduration.end}
          name="end"
         
          min={0}
          max={end}
          step={"100"}
          type="range"
          onChange={changehandle}
        />{" "}
      </div>
      <span className="end current-time">
        {millisToMinutesAndSeconds(timeduration.end)}
      </span>
    </section>
  ) : (
    <Nouislider
      range={{ min: timeduration.start, max: timeduration.end }}
      start={[sliderPoints.start, sliderPoints.end]}
      connect
      step={100}
      format={{
        from: Number,
        to: function (value) {
          return fordualrangeslider(value);
        },
      }}
      onEnd={() => {
        setSlider(false);
        setSlideNew(false);
      }}
      behaviour="smooth-steps"
      animate={false}
      tooltips={false}
      margin={1000}
      onChange={(e) => {
        // refData.current.seekTo(Number(e[0]), "seconds");
        setSliderPoints({
          start: Number(e[0]) * 1000,
          end: Number(e[1]) * 1000,
        });
        setTimings([
          {
            start: Number(e[0]),
            end: Number(e[1]),
          },
        ]);
        setTimeFormat({
          starttime: toHHMMSS(Number(e[0])),
          endtime: toHHMMSS(Number(e[1])),
        });
      }}
      onSlide={(e, handle) => {
        if (handle === 0) {
          refData.current.seekTo(Number(e[0]), "seconds");
          dynamicData(Number(e[0]), Number(videoDuration));
        }
        if (handle === 1) {
          dynamicDataForRightSlide(Number(e[1]));
        }
      }}
    />
  );
}

export default Newrange;
