export const MODEL_NAME_FEMALE_WITH_FACE_UE =
  "Adult Female with Facial Rig (UE)";
export const MODEL_NAME_MALE_UE = "Adult Male (UE)";
const MODEL_NAME_FEMALE_WITH_FACE = "Adult Female with Facial Rig";
const MODEL_NAME_MALE = "Adult Male";
export const PLATFORM_UNREAL = "Unreal Engine";
const PLATFORM_DEEPMOTION = "deepmotion";

function loadModelIdsNeeded(characterModels) {
  return {
    femaleWithFaceUE: characterModels.find(
      (model) =>
        model.name === MODEL_NAME_FEMALE_WITH_FACE_UE &&
        model.platform === PLATFORM_UNREAL
    ),
    maleUE: characterModels.find(
      (model) =>
        model.name === MODEL_NAME_MALE_UE && model.platform === PLATFORM_UNREAL
    ),
    femaleWithFace: characterModels.find(
      (model) =>
        model.name === MODEL_NAME_FEMALE_WITH_FACE &&
        model.platform === PLATFORM_DEEPMOTION
    ),
    male: characterModels.find(
      (model) =>
        model.name === MODEL_NAME_MALE && model.platform === PLATFORM_DEEPMOTION
    ),
  };
}

export function useRootAtOriginEmulation(e, numModels, defaultModel) {
  const model = defaultModel;
  const modelId = defaultModel.id;
  let newModel = model;

  const modelIdsNeeded = loadModelIdsNeeded(numModels);

  if (e) {
    if (modelId === modelIdsNeeded.male.id) {
      newModel = modelIdsNeeded.maleUE;
    } else {
      newModel = modelIdsNeeded.femaleWithFaceUE;
    }
  } else {
    if (modelId === modelIdsNeeded.maleUE.id) {
      newModel = modelIdsNeeded.male;
    } else if (modelId === modelIdsNeeded.femaleWithFaceUE.id) {
      newModel = modelIdsNeeded.femaleWithFace;
    }
  }
  return newModel;
}
