import React, { useState, useEffect, useRef, useContext } from "react";
import { imagetoCanvas, canvastoDataURL } from "image-conversion";
import { AppStateContext } from "../../../AppStateContext";
import * as Enums from "../../common/enums";
import "../../../sass/App.scss";

export default function Dropzone(props) {
  const appStateContext = useContext(AppStateContext);

  const fileInputRef = useRef();
  const [hightlight, setHightlight] = useState(false);

  // component un-mount
  useEffect(() => {
    return function cleanup() {
      setHightlight(false);
    };
  }, []);

  function openFileDialog() {
    if (props.disabled) return;
    fileInputRef.current.click();
  }

  async function onFilesAdded(evt) {
    if (props.disabled) return;
    const files = evt.target.files;

    if (props.onFilesAdded) {
      const array = fileListToArray(files);
      props.onFilesAdded(array);
    }
    // reset the file reference after each selection
    appStateContext.state.animJobSettings.jobType && (await onloadImage(files));

    evt.target.value = "";
  }

  function onloadImage(files) {
    return new Promise((resolve) => {
      let imgBlob = new Blob(files);
      let imgUrl = URL.createObjectURL(imgBlob);

      let image = new Image();
      image.src = imgUrl;

      image.onload = async () => {
        if (image.width > 1000) {
          const res = await imagetoCanvas(image, { width: 1000 });
          const url = await canvastoDataURL(res);
          appStateContext.dispatch({ showSelectedFile: url });
          resolve(true);
        } else {
          appStateContext.dispatch({ showSelectedFile: imgUrl });
          resolve(true);
        }
      };
    });
  }

  function onDragOver(evt) {
    evt.preventDefault();
    if (props.disabled) return;
    setHightlight(true);
  }

  function onDragLeave() {
    setHightlight(false);
  }

  async function onDrop(evt) {
    evt.preventDefault();
    if (props.disabled) return;

    const files = evt.dataTransfer.files;

    if (props.onFilesAdded) {
      const array = fileListToArray(files);
      props.onFilesAdded(array);
    }
    // reset the file reference after each selection
    evt.target.value = "";
    appStateContext.state.animJobSettings.jobType && (await onloadImage(files));
    setHightlight(false);
  }

  function fileListToArray(list) {
    const array = [];
    for (var i = 0; i < list.length; i++) {
      array.push(list.item(i));
    }
    return array;
  }

  // if prop "type" is passed with value "custom-character" then we
  // want to allow .fbx file uploads, otherwise we default to the
  // standard video upload component
  function buildDragInput() {
    let acceptList = "";
    if (props.animJobSettings.jobType === Enums.jobTypes.animation) {
      acceptList = ".mp4, .mov, .avi, video/x-msvideo";
    } else {
      acceptList = ".jpg, .jpeg, .png, .gif, .bmp, image/png, image/gif";
    }
    if (props.type === "custom-character") {
      // acceptList = ".fbx,.glb,.gltf,.vrm"
      acceptList = ".fbx,.glb,.vrm";
    }
    return (
      <input
        ref={fileInputRef}
        className="FileInput"
        type="file"
        accept={acceptList}
        onChange={onFilesAdded}
      />
    );
  }

  let dragMessage =
    props.animJobSettings.jobType === Enums.jobTypes.animation
      ? "Drag and drop video"
      : "Drag and drop image";
  if (props.type === "custom-character") {
    dragMessage = "Drag and drop 3D model";
  }
  return (
    <div
      className={`Dropzone ${
        hightlight ? "Highlight animated-border" : "animated-border"
      }`}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
      onClick={openFileDialog}
      style={{ cursor: props.disabled ? "default" : "pointer" }}
    >
      {buildDragInput()}

      <p className="subtitle mb-1"> {dragMessage} </p>
      <p className="subtitle mb-2">or</p>
      <p className="subtitle is-6 has-text-link has-text-weight-semibold">
        Browse files
      </p>
    </div>
  );
}
