import qs from 'qs'
import {useHistory, useLocation} from 'react-router-dom'
import {AccessToken, IDToken, OktaAuth, Tokens, TokenType} from "@okta/okta-auth-js";
import Cookies from "js-cookie";
import {oktaAuthConfig} from "../../config";
import {jsonSafeParse} from "./utils";

export const useQuery = () => {
    const location = useLocation()
    const history = useHistory()
    const keyValues = qs.parse(location.search, {ignoreQueryPrefix: true}) || {}

    return [
        keyValues,
        (params: object | null) => {
            if (params) {
                history.push({
                    pathname: location.pathname,
                    search: qs.stringify({...keyValues, ...params})
                })
            } else {
                history.push({
                    pathname: location.pathname
                })
            }
        }
    ]
}
const idTokenCookieKey = `${process.env.REACT_APP_ENV}_idTokens`
const accessTokenCookieKey = `${process.env.REACT_APP_ENV}_accessTokens`
const dmSessionCookieKey = 'dmsess'
const cookieDomain = process.env.REACT_APP_COOKIE_DOMAIN

const oktaAuth = new OktaAuth(oktaAuthConfig)

export function setTokens(tokens: Tokens) {
    Cookies.set(idTokenCookieKey, JSON.stringify(tokens.idToken), {
        expires: new Date(tokens.idToken.expiresAt * 1000),
        domain: cookieDomain
    })

    Cookies.set(accessTokenCookieKey, JSON.stringify(tokens.accessToken), {
        expires: new Date(tokens.accessToken.expiresAt * 1000),
        domain: cookieDomain
    })
}

export function getTokens() {
    const accessTokenStr = Cookies.get(accessTokenCookieKey)
    const idTokenStr = Cookies.get(idTokenCookieKey)
    if (accessTokenStr && idTokenStr) {
        const accessToken = jsonSafeParse(accessTokenStr)
        const idToken = jsonSafeParse(idTokenStr)
        if (accessToken?.['accessToken'] && idToken?.['idToken']) {
            return {
                accessToken,
                idToken
            }
        }
    }
    return undefined
}

export async function getToken(type: TokenType): Promise<string> {
    if (type === 'accessToken') {
        const accessTokenStr = Cookies.get(accessTokenCookieKey)
        if (accessTokenStr) {
            const accessToken = jsonSafeParse(accessTokenStr)?.['accessToken']
            if (accessToken) {
                return accessToken
            }
        }
        const accessToken = (await oktaAuth.tokenManager.get(type)) as AccessToken
        if (accessToken && !oktaAuth.tokenManager.hasExpired(accessToken)) {
            Cookies.set(accessTokenCookieKey, JSON.stringify(accessToken), {
                expires: new Date(accessToken.expiresAt * 1000),
                domain: cookieDomain
            })
            return accessToken.accessToken
        } else {
            return undefined
        }
    } else {
        const idTokenStr = Cookies.get(idTokenCookieKey)
        if (idTokenStr) {
            const idToken = jsonSafeParse(idTokenStr)?.['idToken']
            if (idToken) {
                return idToken
            }
        }
        const idToken = (await oktaAuth.tokenManager.get(type)) as IDToken
        if (idToken && !oktaAuth.tokenManager.hasExpired(idToken)) {
            Cookies.set(idTokenCookieKey, JSON.stringify(idToken), {
                expires: new Date(idToken.expiresAt * 1000),
                domain: cookieDomain
            })
            return idToken.idToken
        } else {
            return undefined
        }
    }
}

export function cleanTokens() {
    Cookies.remove(idTokenCookieKey, {domain: cookieDomain})
    Cookies.remove(accessTokenCookieKey, {domain: cookieDomain})
    Cookies.remove(dmSessionCookieKey, {domain: cookieDomain})
    Cookies.remove(process.env.REACT_APP_COOKIE_NAME, {domain: cookieDomain})

    localStorage.clear()
    sessionStorage.clear()
}
