import React, { useContext, useEffect } from 'react'
import { Link, useHistory } from "react-router-dom"
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { AppStateContext } from '../../AppStateContext'
import { feedbackConfirmation } from '../api/apiRequests';
import axios from 'axios'
// import ParticlesBackground from '../common/ParticlesBackground'
import AnimVersionPanel from '../common/AnimVersionPanel'
import LoadingScreen from '../common/LoadingScreen'
import * as Enums from './enums'
const qs = require('querystring')

/*================================================== 
  [FUNCTIONAL COMPONENT]
  Customer Feedback functional component
 ===================================================*/
export default function FeedbackForm(props) {

  // browser history
  let history = useHistory()
  const appStateContext = useContext(AppStateContext)

  //************************************************************ 
  // State variables for sign up form entries: 
  //************************************************************
  const [feedbackTitle, setFeedbackTitle] = React.useState(
    Enums.buildStateObj("", false, Enums.inputSpanHide, Enums.normalInputClass)
  )
  const [feedbackDescription, setFeedbackDescription] = React.useState(
    Enums.buildStateObj("", false, Enums.inputSpanHide, Enums.normalTextAreaClass)
  )
  const [mayWeContactField, setMayWeContactField] = React.useState(true)
  const [missingFieldsMessage, setMissingFieldsMessage] = React.useState("")
  const [submitStatus, setSubmitStatus] = React.useState(Enums.FORM_STATE.ready)

  //---------------------------------------------------------------
  const appId         = process.env.REACT_APP_PODIO_AID
  const appToken      = process.env.REACT_APP_PODIO_APT
  const clientId      = process.env.REACT_APP_PODIO_CID
  const clientSecret  = process.env.REACT_APP_PODIO_CLS
  const redirectUri   = process.env.REACT_APP_PODIO_RED
  const csrfState     = process.env.REACT_APP_PODIO_CSR
  const authUrl       = process.env.REACT_APP_PODIO_AUTH_URL
  const restApiUrl    = process.env.REACT_APP_PODIO_BASE_URL + `/${appId}`
  //---------------------------------------------------------------

  // SEO 
  const docTitle = "Feedback | DEEPMOTION"
  const metaDesc = "We'd love to hear any feedback you may have regarding any of our products or services. Thanks for your interest in DeepMotion!"

  // form strings
  const submitButton    = "Submit"
  const tryAgainButton  = "Try Again"
  const backButton      = "Home"
  const goToDash        = "Back To Dashboard"
  const formTitle       = "Product Feedback" 
  const formText        = "We would love to hear any feedback, feature requests, or suggestions you may have regarding any of our products or services" 
  const field_1_Title   = 'Title'
  const field_2_Title   = 'Feedback'
  const field_3_Title   = 'May we contact you regarding your feedback?'
  const inProgressTitle = "Submitting request..."
  const successTitle    = "Thank you for your feedback!"
  const successSub1     = "We've received your feedback and will review it soon..."
  const successSub2     = "- The DeepMotion Team"
  const errorTitle      = "Sorry, something went wrong..."
  const errorSubtitle   = "We encountered an error submitting your request, unable to connect to server."
  const errorSubtitle2  = "If the problem continues please contact Deepmotion Support."

  ////////////////////////////////////////////////////////////////////
  // custom hook to re-initialize service upon handle browser refresh
  ////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if( !appStateContext.state.anim3dInitialized || !appStateContext.state.accountDataRetrieved ) {
      props.initializeA3DService()
    } 
    else {
      if( props.LOADING.show ) {
        props.setLOADING({...props.LOADING, ...{show: false}})
      }
    }
  }, [appStateContext.state.anim3dInitialized, appStateContext.state.accountDataRetrieved])

  /******************************************************************
   ****************************************************************** 
   * submitSignUpForm()
   * 
   * Submits the sign up for using the Podio API
   ******************************************************************
   ******************************************************************/
  async function submitSignUpForm() {

    //TODO: DEBUG!!!
    if( isFormReadyForSubmission() /* true*/ ) {
      // Two API requests needed to submit form through Podio API:
      // 1. authenticate with auth server to get auth token
      // 2. create new record in Podio cloud signup app

      // mark state as form submission in-progress
      setSubmitStatus(Enums.FORM_STATE.inProgress)

      // build the request body needed for oauth authentication
      let authRequestBody = {
        grant_type: 'app',
        app_id: appId,
        app_token: appToken,
        client_id: clientId,
        redirect_uri: redirectUri,
        client_secret: clientSecret,
        state: csrfState
      }

      // set axios request headers for token request:
      const authRequestHeaders = {
        headers: {
          // Podio OAuth API requires the Content-Type header to be
          // set to application/x-www-form-urlencoded 
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }

      /*******************************************************
       *******************************************************
       * Podio API #1 - Get access token 
       *******************************************************
       *******************************************************/
      const res = await axios.post(authUrl, qs.stringify(authRequestBody), authRequestHeaders).catch(() => {
        //TODO: Handle error
        console.log(`Error - could not authenticate with server.`) 
        // mark state as failed submission status
        setSubmitStatus(Enums.FORM_STATE.failure)
        return null
      })

      if (res == null)
        return

      // If successfully authenticated, make POST request to Podio
      // API to create a new lead record...
      let aToken = res.data.access_token
      // let rToken = res.data.refresh_token
      console.log(`Access token received`)
      // add auth token to request header for next API request
      const apiRequestHeaders = {
        headers: {
          // Podio createItem() API requires a JSON request body...
          'Content-Type': 'application/json',
          'Authorization': `OAuth2 ${aToken}`
        }
      }

      /*******************************************************
       *******************************************************
        * Podio API #2 - Create new item in cloud signup app
        *******************************************************
        *******************************************************/

      // configure API request body for Create Item call
      let createItemRequestBody = {
        "external_id":"Customer Feedback",
        "fields": [
          {
            "external_id": "title",
            "values": feedbackTitle.value
          },
          {
            "external_id": "feedback",
            "values": feedbackDescription.value
          },
          {
            "external_id": "may-we-contact-you-re-your-feedback",
            "values": mayWeContactField ? [2] : [1]
          },
          {
            "external_id": "email",
            "values": [{
              "type": "work",
              "value": appStateContext.state.email // user's email read in through props!
            }]
          }
        ]
      }

      try {
        //==> PODIO POST API - Create New Item:
        await axios.post(restApiUrl, JSON.stringify(createItemRequestBody), apiRequestHeaders)
        // send user feedback confirmation email if feedback has successfully been entered into podio
        await feedbackConfirmation().catch((error) => {
          console.log(`Error encountered sending user confirmation email (feedback was logged) -- ${error}`)
        })
      }
      catch (error) {
        // Create Item API returned error
        //TODO: Handle error
        console.log(`Error encountered while submitting feedback...\n`)
        console.log(`${JSON.stringify(error)}`)
        // mark state as failed submission status
        setSubmitStatus(Enums.FORM_STATE.failure)
        return
      }

      console.log(`Form submitted successfully`)

      //=============================================================
      // --> ON SUCCESSFUL SUBMIT REDIRECT BACK TO ANIMATE 3D PRODUCT 
      // PAGE WITH URL QUERY PARAM TO SHOW SUCCESS MODAL
      //=============================================================

      setSubmitStatus(Enums.FORM_STATE.success)
      // history.push(Enums.routes.Dash + '?form=complete')
    }

    //----------------------------------------------------
    // Else form is NOT ready for submission, update
    // classes to highlight errors/required fields
    //----------------------------------------------------
    else {
      // run checks for required fields that should be highlighted
      highlightFeedbackTitleError()
      highlightFeedbackDescriptionError()
    }
  }

  /*
   * onClick() validation event for Feedback Title field
   */
  const validateFeedbackTitle = event => {
    let tmpStateObj = Enums.buildStateObj(
      (event.target.value),
      feedbackTitle.isValid,
      feedbackTitle.span,
      feedbackTitle.iClass
    )
    // verify the new value is not null, meets min length requirements, and is not all spaces...
    if( tmpStateObj.value && tmpStateObj.value !== "" && tmpStateObj.value.length >= Enums.minInputLength && tmpStateObj.value.trim().length ) {
      tmpStateObj.isValid = true
      if( tmpStateObj.iClass.toString() === Enums.missingInputClass ) {
        tmpStateObj.iClass = Enums.normalInputClass
      }
    }
    else { 
      tmpStateObj.isValid = false
    }
    //*** finally we update the state with latest data
    if( !Enums.compareStates(feedbackTitle,tmpStateObj) ) {
      setFeedbackTitle(tmpStateObj)
    }
  }

  /*
   * onClick() validation event for feedback description 
   */
  const validateFeedbackDescription = event => { 
    let tmpStateObj = Enums.buildStateObj(
      (event.target.value),
      feedbackDescription.isValid,
      feedbackDescription.span,
      feedbackDescription.iClass
    )
    // verify the new value is not null, meets min length requirements, and is not all spaces...
    if( tmpStateObj.value && tmpStateObj.value !== "" && tmpStateObj.value.length >= Enums.minInputLength && tmpStateObj.value.trim().length ) {
      tmpStateObj.isValid = true
      if( tmpStateObj.iClass.toString() === Enums.missingTextAreaClass ) {
        tmpStateObj.iClass = Enums.normalTextAreaClass
      }
    }
    else { 
      tmpStateObj.isValid = false
    }
    //***
    setFeedbackDescription(tmpStateObj)
  }


  /*
   * highlight for feedback title field when field loses focus
   * through the onBlur() event
   */
  const highlightFeedbackTitleError = () => { 
    let tmpStateObj = Enums.buildStateObj(
      feedbackTitle.value,
      feedbackTitle.isValid,
      feedbackTitle.span,
      feedbackTitle.iClass,
    )
    if( !tmpStateObj.isValid ) {
      tmpStateObj.iClass = Enums.missingInputClass
    }
    else {
      tmpStateObj.iClass = Enums.normalInputClass
    }
    setFeedbackTitle(tmpStateObj)
  }

  /*
   * highlight for feedback description field when field loses focus
   * through the onBlur() event
   */
  const highlightFeedbackDescriptionError = () => { 
    let tmpStateObj = Enums.buildStateObj(
      feedbackDescription.value,
      feedbackDescription.isValid,
      feedbackDescription.span,
      feedbackDescription.iClass,
    )
    if( !tmpStateObj.isValid ) {
      tmpStateObj.iClass = Enums.missingTextAreaClass
    }
    else {
      tmpStateObj.iClass = Enums.normalTextAreaClass
    }
    setFeedbackDescription(tmpStateObj)
  }

  /* 
   * Returns true if all form field values are ready for submission,
   * false otherwise 
   */
  function isFormReadyForSubmission() {
    if( !feedbackTitle.value || !feedbackDescription.value ) {
      return false
    }
    // check state variables for validating each form field
    let formFields = [
      { "name": "Feedback Title", "value": feedbackTitle.isValid },
      { "name": "Feedback Text", "value": feedbackDescription.isValid }
    ]
    let formStatus = true
    let printString = "Required form fields missing/invalid:\n"

    formFields.forEach(function (aItem) {
      if( !aItem.value ) {
        formStatus = false
        printString += ("\t- " + aItem.name + "\n")
      }
    });

    ////////////////////////////////////////////////////
    if( !formStatus ) {
      // form not ready 
      // console.log(printString)
      setMissingFieldsMessage(printString)
      return false
    }
    ////////////////////////////////////////////////////
    else {
      // console.log(`Form is ready`)
      return true
    }
    ////////////////////////////////////////////////////
  }

  /* 
   * Reset form and all state variables after form submission
   */
  function resetForm() {
    // console.log(`Resetting feedback form...`)
    // reset all form field values to null/default:
    setFeedbackTitle( 
      Enums.buildStateObj("", false, Enums.inputSpanHide, Enums.normalInputClass) 
    )
    setFeedbackDescription(
      Enums.buildStateObj("", false, Enums.inputSpanHide, Enums.normalTextAreaClass)
    )
    setMayWeContactField( true )
    setMissingFieldsMessage("")
    setSubmitStatus(Enums.FORM_STATE.ready)
  }

  /* 
   * Builds the main signup for Animate 3D which is designed
   * to be mobile responsive:
   */
  function buildProductFeedbackForm() {

    /*********************************************
     * Render the Feedback Form:
     *********************************************/
    return (
      <div className="" style={{paddingTop:'15px'}}>

        {/*** Move title above form for mobile ***/}
        <h1 className="subtitle is-2 animate-header" style={{marginBottom:'35px'}}>{formTitle}</h1>
        <h1 className="subtitle is-5 animate-header" style={{marginBottom:'35px'}}>{formText}</h1>

        {/*** DISPLAY MISSING FIELDS MESSAGE ***/}
        {feedbackTitle.iClass === Enums.missingInputClass || feedbackDescription.iClass === Enums.missingTextAreaClass
        ?
        <div className="field">
          <p className="help is-danger">{missingFieldsMessage}</p>
        </div>
        :
        <div className="field"></div>
        }

        {/*** FEEDBACK TITLE ***/}
        <div className="field">
          <label className="label">{field_1_Title}</label>
          <div className="control has-icons-right">
            <input className={feedbackTitle.iClass} onChange={validateFeedbackTitle} onBlur={highlightFeedbackTitleError} type="text" placeholder="Title" />
            <span className={feedbackTitle.span}>
              <i className="fas fa-check"></i>
            </span>
          </div>
        </div>
      

        {/*** FEEDBACK TEXT AREA ***/}
        <div className="field">
          <label className="label">{field_2_Title}</label>
          <div className="control has-icons-right">
            <textarea className={feedbackDescription.iClass} onChange={validateFeedbackDescription} onBlur={highlightFeedbackDescriptionError} type="textarea" placeholder="Feedback"/>
            <span className={feedbackDescription.span}>
              <i className="fas fa-check"></i>
            </span>
          </div>
        </div>

        {/*** CHECKBOX: MAY WE CONTACT YOU ***/} 
        <div className="field mgTop-20">
          <label className="label">{field_3_Title}</label>
          <div className="control">
            <label className="radio" style={{marginLeft:'25px'}}>
              <input defaultChecked type="radio" onClick={()=>setMayWeContactField(true)} name="question" />
              <span style={{marginLeft:'10px'}}>
                Yes
              </span>  
            </label>
            <label className="radio">
              <input type="radio" onClick={()=>setMayWeContactField(false)} name="question" />
              <span style={{marginLeft:'10px'}}>
                No
              </span>
            </label>
          </div>
        </div>

        {/*** [SUBMIT] button ***/}
        <div className="field mgTop-20">
          <div className="control">
            <button onClick={submitSignUpForm} className="button is-medium preview-btn">{submitButton}</button>
          </div>
        </div>

        {/* back button */}
        <div className="field">
          <div className="control">
            <Link to={Enums.routes.Dash}> Back </Link>
          </div>
        </div>
      </div>
    )
  }

  //---------------
  // using react helmet to set page title and metadata
  function seoMetaData_EN() {
    return (
      <Helmet>
        <meta charSet="utf-8" />
        <title> {docTitle} </title>
        <meta name="description" content= {metaDesc} />
      </Helmet>
    )
  }

  /* 
   * Builds the progress bar column for when a submission is in-progress
   */
  function buildProgressDisplay() {
    return (
      <div className="Absolute-Center" >
        <div className="column has-text-centered vcenter-flex" style={{minHeight:'50vh',marginBottom:'0'}}>

          <div className="">
            <div className="columns">
              <div className="column">
                <h4 className="subtitle is-4 has-text-black mgTop-50">{inProgressTitle}</h4>
              </div>
            </div>
            <div className="columns">
              <div className="column hcenter is-half">
                <progress className="progress indeterminate is-medium loading-bar" max="100"></progress>
              </div>
            </div>    
          </div>
        </div>
      </div>
    )
  }

  /* 
   * Builds the submission successful display
   */
  function buildSubmitSuccessDisplay() {
    return (

      <div className="" style={{paddingTop:'15px'}}>

        <h1 className="subtitle is-2 animate-header" style={{marginBottom:'35px'}}>{formTitle}</h1>

        <div className="column ">
          <div className="">
            <h1 className="title is-4 has-text-black mgTop-50">{successTitle}</h1>
            <div className="columns">
              <div className="column is-half has-text-centered ">
                <span className="icon has-text-success">
                  <i className="fas fa-check fa-5x"></i>
                </span>
              </div>
            </div>    
            <h2 className="subtitle is-5 has-text-black"> {successSub1} </h2>
            <h2 className="subtitle is-5 has-text-black"> {successSub2} </h2>
            <div className="columns">
              <div className="column is-half has-text-centered ">
                <button onClick={()=>history.push(Enums.routes.Dash)} className="button preview-btn">{goToDash}</button>
              </div>
            </div>
          </div>
        </div>
      </div>  
    )
  }

  // 
  // Builds the submission error display
  //
  function buildSubmitErrorDisplay() {
    return (
      <div className="Absolute-Center" style={{paddingTop:'20px'}}>
        <div className="column" >
          <div className="">
            <h1 className="title is-3 has-text-black mgTop-50">{errorTitle}</h1>
            <div className="columns">
              <div className="column is-half has-text-centered hcenter ">
                <span className="icon">
                  <i className="fas fa-exclamation-triangle fa-3x" style={{color:'orange'}}></i>
                </span>
              </div>
            </div>
            <h2 className="subtitle is-4 has-text-black" >{errorSubtitle}</h2>
            <h2 className="subtitle is-4 has-text-black" >{errorSubtitle2}</h2>
            <div className="columns">
              <div className="column is-half has-text-centered hcenter ">
                <div className="field is-grouped">
                  <div className="control">
                    <button onClick={()=>resetForm()} className="button preview-btn">{tryAgainButton}</button>
                  </div>
                  <div className="control">
                    <button onClick={()=>history.push(Enums.routes.Dash)} className="button preview-btn">{backButton}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>  
    )
  }

  ///********************************************************************
  ///Builds the entire screen for both desktop and mobile
  ///********************************************************************/
  function BUILD_SCREEN() {
    switch( submitStatus ) {
      case Enums.FORM_STATE.ready:
        return (
          <div className="mgTop-20">
            <div className="columns p-5 has-text-centered" >
              <div className="column is-8 p-4 br-4 has-background-info-light" style={{marginBottom:'0'}}>
                {/*** render the contact sales signup form: ***/}
                {buildProductFeedbackForm()}

              </div>
            </div>
          </div>
        )
      
      case Enums.FORM_STATE.inProgress:
        return (
          <div className="mgTop-20">
            <div className="columns has-text-centered" >
              <div className="column is-half" style={{marginBottom:'0'}}>
                {/*** render the contact sales signup form: ***/}
                {buildProgressDisplay()}
              </div>
            </div>
          </div>
        )
      
      case Enums.FORM_STATE.success:
        // TODO: Copied code from company website form, need to refactor! 
        return (
          <div className="columns mgTop-20 has-text-centered form-col" style={{marginBottom:'0'}}>
            {buildSubmitSuccessDisplay()}
          </div>
        )
      
      case Enums.FORM_STATE.failure:
        return (

          <div className="mgTop-20">
            <div className="columns has-text-centered" >
              <div className="column is-half" style={{marginBottom:'0'}}>
                {/*** render the feedback signup form: ***/}
                {buildSubmitErrorDisplay()}

              </div>
            </div>
          </div>  
        )
      
      default:
        return (<div></div>)
    }
  }

  //---------------------------------------------------------
  // React useEffect() hook for when form state changes:
  //---------------------------------------------------------

  React.useEffect(() => {
    let tmpStateObj = null
    //**************************************************
    //------ Validate Feedback Title Field ------
    //**************************************************
    tmpStateObj = Enums.buildStateObj(
      feedbackTitle.value,
      feedbackTitle.isValid,
      feedbackTitle.span,
      feedbackTitle.iClass
    )
    if( tmpStateObj.iClass.toString() !== Enums.missingInputClass ) {
      tmpStateObj.iClass = Enums.normalInputClass
    }
    tmpStateObj.span = Enums.inputSpanHide
    if( tmpStateObj.isValid ) {
      tmpStateObj.span = Enums.inputSpanShowSuccess
    }
    if( !Enums.compareStates(feedbackTitle,tmpStateObj) ) {
      setFeedbackTitle(tmpStateObj)
    }
    //****************


    //**************************************************
    //------ Validate Feedback Description Field ------
    //**************************************************
    tmpStateObj = Enums.buildStateObj(
      feedbackDescription.value,
      feedbackDescription.isValid,
      feedbackDescription.span,
      feedbackDescription.iClass
    )
    if( tmpStateObj.iClass.toString() !== Enums.missingTextAreaClass ) {
      tmpStateObj.iClass = Enums.normalTextAreaClass
    }
    tmpStateObj.span = Enums.inputSpanHide
    if( tmpStateObj.isValid ) {
      tmpStateObj.span = Enums.inputSpanShowSuccess
    }
    if( !Enums.compareStates(feedbackDescription,tmpStateObj) ) {
      setFeedbackDescription(tmpStateObj)
    }
    //****************

    //**************************************************
    //------ Validate MayWeContactField ------
    //**************************************************
    // tmpStateObj = Enums.buildStateObj(
    //   phone.value,
    //   phone.isValid,
    //   phone.span,
    //   phone.iClass
    // )
    // if( tmpStateObj.iClass.toString() !== Enums.missingInputClass ) {
    //   tmpStateObj.iClass = Enums.normalInputClass
    // }
    // tmpStateObj.span = Enums.inputSpanHide
    // if( tmpStateObj.isValid ) {
    //   tmpStateObj.span = Enums.inputSpanShowSuccess
    // }
    // if( !Enums.compareStates(phone,tmpStateObj) ) {
    //   setMayWeContactField(tmpStateObj)
    // }
    // //****************

  }, [
      feedbackTitle,
      feedbackDescription,
      mayWeContactField,
      missingFieldsMessage,
      submitStatus
    ]);


  // set page title and meta data
  var helmetData = seoMetaData_EN()

  //setFormControllerState(formContext)

  /**************************
   * Return the component...
   **************************/
  return (
    <HelmetProvider>
      <div id="anim-fadein" className="column mt-0">
        {helmetData}
        <AnimVersionPanel />
        {
          props.LOADING.show
          ?
          <LoadingScreen />
          :
          BUILD_SCREEN()
        }
      </div>
    </HelmetProvider>
  )
} 