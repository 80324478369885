import React, { useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import {redirectToCustomerPortal, redirectToPricingPageForNewPurchase, getUserPlanData, listSubscriptions} from '../api/apiRequests'
import {Helmet, HelmetProvider} from 'react-helmet-async'
import { AppStateContext } from '../../AppStateContext'
import queryString from 'query-string'
import * as Enums from '../common/enums'
// import ParticlesBackground from '../common/ParticlesBackground'
import AnimVersionPanel from '../common/AnimVersionPanel'

const titlePage = "Profile"
const titleAccount = "Account Plan"
const titleName = "Name"
const titleEmail = "Email"
const titleSub = "Plan"
const titleBillCycle = "Billing Cycle"

const titleRenewPlan = "Renew Plan"
const titleChangePlan = "Update Plan and Billing"
const titleCompleteSubscription = "Complete Subscription"
const titleCloseAccount = "Close Account"
const titleSubsHistory = "Subscription History"
const titleViewPlans = "View Plans & Pricing"
const textAnim3dHome = "Animate 3D Home"
const textBackToDash = "Back to Dashboard"
const docTitle = "Profile | DEEPMOTION"
const metaDesc = "Account profile page for DeepMotion Animate 3D service"

/*************************************************************************** 
 * [FUNCTIONAL COMPONENT]
 ***************************************************************************/
export default function AccountProfilePage(props) {

  const [dataLoaded, setDataLoaded] = React.useState(false)
  const appStateContext = useContext(AppStateContext)
  const viewSubsHistory = async () => {
    const url = await redirectToCustomerPortal(true)
    if (url) {
      const aLink = document.createElement('a')
      aLink.href = url
      aLink.click()
    }
  }

  ////////////////////////////////////////////////////////////////////////
  // Get latest subscription information from payment system
  //
  // @param cb : optional callback function
  ////////////////////////////////////////////////////////////////////////
  async function getLatestAccountSubscription(cb) {
    
    if( !appStateContext.state.subscriptionInfo.name || appStateContext.state.subscriptionInfo.name === 'undefined' ) {
      props.setLOADING({...props.LOADING, ...{show: false}})
      props.setErrorDialogInfo(true, Enums.eCodes.OtherError, "Problem Getting Profile Data")
      return
    }
    const res = await listSubscriptions().catch((error) => {
      // props.setLOADING({...props.LOADING, ...{show: false}})
      setDataLoaded(true)
      console.log(`Could not retrieve user profile information - \n${error}`)
      props.setErrorDialogInfo(true, Enums.eCodes.OtherError, "Problem Getting Profile Data")
      return null
    })
    if (res == null) return

    if( res.data.data.length > 0 ) {
      let subInfo = {}
      for( let i = 0; i < res.data.data.length; i++ ) {
        if( res.data.data[i].plan.name !== appStateContext.state.subscriptionInfo.name && (res.data.data[i].status === 'active' || res.data.data[i].status === 'past_due') ) {
          // only update subscription if we find an active one
          subInfo.name = res.data.data[i].plan.name
          subInfo.status = res.data.data[i].status
          subInfo.current_period_start = res.data.data[i].current_period_start
          subInfo.current_period_end = res.data.data[i].current_period_end
          subInfo.cancel_at_period_end = res.data.data[i].cancel_at_period_end
          subInfo.cancel_at = res.data.data[i].cancel_at
          appStateContext.dispatch({subscriptionInfo: subInfo}, () => {
            // can't break since inside a callback...
            i = res.data.data.length
          })
        }
      }
      // if( !subInfo ) {
      //   // if subInfo is null after above for-loop then we found 
      //   // subscription(s) but none were active
      //   // appStateContext.dispatch({loading: false})
      //   // setDataLoaded(true)
      // }
      if( cb ) {
        cb()
      }
    }
    else {
      // else no active subscriptions found, ensure the UI indicates user is 
      // on the Freemium plan in case they just downgraded from a paid plan
      if( appStateContext.state.subscriptionInfo.name !== Enums.accountPlansInfo[0].name ) {
        console.log(`!!! appStateContext.state.subscriptionInfo --> ${JSON.stringify(appStateContext.state.subscriptionInfo, null, 4)}`)
        subInfo.name = Enums.accountPlansInfo[0].name
        subInfo.status = 'active'
        appStateContext.dispatch({subscriptionInfo: subInfo}, () => {
          if( cb ) {
            cb()
          }
        })
      }
      else {
        if( cb ) {
          cb()
        }
      }
    }
  }

  ////////////////////////////////////////////////////////////////////////
  // Renders User Profile info including name and email
  ////////////////////////////////////////////////////////////////////////
  function buildUserProfileSection() {
    return (
      <div>
        <div className="columns">
          <div className="column is-one-third">
            <h2 className="title is-5"> {titleName} </h2>
          </div>
          <div className="column">
            <h2 className="subtitle is-5 dm-brand-font"> {appStateContext.state.firstName} {appStateContext.state.lastName} </h2>
          </div>
        </div>
        <div className="columns">  
          <div className="column is-one-third">
            <h2 className="title is-5"> {titleEmail} </h2>
          </div>
          <div className="column">
            <h2 className="subtitle is-5 dm-brand-font"> {appStateContext.state.email} </h2>
          </div>  
        </div>
      </div>
    )
  }

  ////////////////////////////////////////////////////////////////////////
  // Renders Account subscription info section
  ////////////////////////////////////////////////////////////////////////
  function buildAccountInfoSection() {
    const currCycleStartDate = Enums.dateConverter(appStateContext.state.subscriptionInfo.current_period_start, true)
    const currCycleEndDate = Enums.dateConverter(appStateContext.state.subscriptionInfo.current_period_end, true)

    let subscriptionName = null
    if( appStateContext.state.subscriptionInfo.isEnterpriseUser  ) {
      // Enterprise minutes pack
      subscriptionName = Enums.accountPlansInfo[ Enums.accountPlansInfo.length-1 ].name
    } else if( appStateContext.state.subscriptionInfo.status === 'active' || appStateContext.state.subscriptionInfo.status === 'past_due') {
      subscriptionName = appStateContext.state.subscriptionInfo.name + ` (${appStateContext.state.subscriptionInfo.status})`
    } else {
      subscriptionName = 'Subscription is ' +  appStateContext.state.subscriptionInfo.status
    }
    return (
      <div>
        <div className="columns">  
          <div className="column is-one-third">
            <h2 className="title is-5"> {titleSub} </h2>
          </div>
          <div className="column">
            <h2 className="subtitle is-5 dm-brand-font"> {subscriptionName} </h2>
          </div>  
        </div>
        <div className="columns">  
          <div className="column is-one-third">
            <h2 className="title is-5"> {titleBillCycle} </h2>
          </div>
          <div className="column">
          {
            appStateContext.state.subscriptionInfo.cancel_at_period_end
            ?
              <h2 className="subtitle is-5 has-text-danger"> Your plan will be canceled on {Enums.dateConverter(appStateContext.state.subscriptionInfo.cancel_at, true)} </h2>
            :
              <h2 className="subtitle is-5 dm-brand-font"> {currCycleStartDate} - {currCycleEndDate} </h2>
          }
          </div>
        </div>
        
        {/*** Temporarily removing mins from profile page
        
        <div className="columns">  
          <div className="column is-one-third">
            <h2 className="title is-5"> {titleUsedTime} </h2>
          </div>
          <div className="column">
            <h2 className="subtitle is-5 dm-brand-font"> {usedMonthlyTime} (hh:mm:ss) </h2>
          </div>  
        </div>
        
        <div className="columns">  
          <div className="column is-one-third">
            <h2 className="title is-5"> {titleRemainingTime} </h2>
          </div>
          <div className="column">
            <h2 className="subtitle is-5 dm-brand-font"> {remainingMonthlyTime} (hh:mm:ss) </h2>
          </div>  
        </div>

        ***/}
        
      </div>
    )
  }

  ////////////////////////////////////////////////////////////////////////
  // Renders the entire profile page
  ////////////////////////////////////////////////////////////////////////
  function buildProfilePage() {
    if( props.loading ) {
      return (
        <React.Fragment>
          <div className="section" id="anim-fadein">
            <div className="columns is-vcentered">
              <div className="column is-one-third has-text-centered is-vcentered">  
                <p className="title is-4">Loading...</p>
                <progress className="progress indeterminate is-medium loading-bar" max="100"></progress>
              </div>
            </div>
          </div>
        </React.Fragment>
      )
    }
    else {
      return (
        <HelmetProvider>
          <div className="column mt-0 anim-fadein" id="anim-fadein" >
            {helmetData}
            <AnimVersionPanel />
            <div className="m-5 p-5 br-4" style={{background: 'rgba(239, 245, 251, 0.65)', border: '1px solid #2d4e77' }}>
              <div className="columns">
                <div className="column">
                  <h1 className="subtitle is-3 profile-label has-text-black" style={{marginBottom: '15px'}}> {titlePage} </h1>
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  {buildUserProfileSection()}
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <h1 className="subtitle is-3 profile-label has-text-black mt-5"> {titleAccount} </h1>
                </div>
              </div>
              <div className="columns mgTop-20">
                <div className="column">
                  {buildAccountInfoSection()}
                </div>
              </div>
              <div className="columns mgTop-20">
                <div className="column">

                  <div className="field is-grouped">
                    <p className="control">
                      { 
                        appStateContext.state.subscriptionInfo.name === Enums.accountPlansInfo[0].name
                        ? 
                        ((appStateContext.state.subscriptionInfo.status === 'active' || appStateContext.state.subscriptionInfo.status === 'past_due')
                          ? 
                          <a className="button library-btn is-normal" onClick={()=>redirectToPricingPageForNewPurchase()}>
                            <span className="no-side-margins">{titleViewPlans}</span></a>
                          : 
                          <a className="button library-btn is-normal" onClick={()=>redirectToCustomerPortal()}>
                            <span className="no-side-margins">{titleCompleteSubscription}</span></a>
                        )
                        : 
                        (appStateContext.state.subscriptionInfo.cancel_at_period_end ?
                            <a className="button library-btn is-normal" onClick={()=>redirectToCustomerPortal()}>
                            <span className="no-side-margins">{titleRenewPlan}</span></a> :
                            <a className="button library-btn is-normal" onClick={()=>redirectToCustomerPortal()}>
                              <span className="no-side-margins">{titleChangePlan}</span></a>
                        )
                      }
                    </p>
                    <p className="control">
                      { 
                        appStateContext.state.subscriptionInfo.name === Enums.accountPlansInfo[0].name && appStateContext.state.subscriptionInfo.history.canceled !== 0
                        &&
                        <a className="button cancel-btn is-normal" onClick={viewSubsHistory} ><span className="no-side-margins">{titleSubsHistory}</span></a>
                      }
                    </p>
                    <p className="control">
                      { 
                        appStateContext.state.subscriptionInfo.name === Enums.accountPlansInfo[0].name && !appStateContext.state.subscriptionInfo.isEnterpriseUser
                        &&
                        <a className="button cancel-btn is-normal" onClick={()=>props.DISPATCH({confirmDialogId: Enums.confirmDialog.closeUserAccount})} ><span className="no-side-margins">{titleCloseAccount}</span></a>
                      }
                    </p>
                  </div>
                  
                </div>
              </div>
              
              {/* Don't show Anim3D home button if users does not have product access */}
              {
                appStateContext.state.accessList[0]
                &&
                <div className="columns mb-0">
                  <div className="column has-text-left">
                    <Link to={(appStateContext.state.jobsData && !appStateContext.state.jobsData.length) ? Enums.routes.Anim3dGuidedFTE : Enums.routes.Anim3d}> {textAnim3dHome} </Link>
                  </div>
                </div>
              }

              <div className="columns">
                <div className="column has-text-left">
                  <Link to={Enums.routes.Dash}> {textBackToDash} </Link>
                </div>
              </div>
            </div>
          </div>
        </HelmetProvider>
      )
    }
  }

  ////////////////////////////////////////////////////////////////////////
  // cancel user subscription
  ////////////////////////////////////////////////////////////////////////
  function getUserSubscriptions() {
    listSubscriptions()
    .then(res => {
      if (res.data.data.length > 0) {
        let subList = []
        res.data.data.forEach(s => {
          subList.push({
            sub_id: s.id,
            sub_name: s.items.data[0].plan.name
          })
        })
        props.setSubscriptionList(subList)
        props.DISPATCH({confirmDialogId: Enums.confirmDialog.cancelSubscription})
      }
    })
  }

  ////////////////////////////////////////////////////////////////////////
  // Retrieves associated product metadata based on name
  ////////////////////////////////////////////////////////////////////////
  function retriveProductDataFromName( planName ) {
    for( let i = 0; i < Enums.accountPlansInfo.length; i++ ) {
      if( planName === Enums.accountPlansInfo[i].name ) {
        return Enums.accountPlansInfo[i]
      }
    }
    // if none matched assume Freemium
    return Enums.accountPlansInfo[0]
  }

  //---------------
  // using react helmet to set page title and metadata
  function seoMetaData_EN(props) {
    return (
      <Helmet>
        <meta charSet="utf-8" />
        <title> {docTitle} </title>
        <meta name="description" content= {metaDesc} />
      </Helmet>
    )
  }
  // set page title and meta data
  var helmetData = seoMetaData_EN()

  //---------------------------------------------------------
  // React useEffect() hook for when state changes:
  //---------------------------------------------------------
  useEffect(() => {
    if( !dataLoaded ) {
      if( queryString.parse(props.location.search).return ) {
        if( appStateContext.state.subscriptionInfo.name ) {
          getLatestAccountSubscription( async () => {
            const res = await getUserPlanData()
            const data = props.updateUserPlanData(res.data)
            let tmpData = appStateContext.state.subscriptionInfo
            if( tmpData.name === Enums.accountPlansInfo[0].name ) {
              // Special case for Freemium plans (otherwise data is read
              // from Stripe backend for paid subscriptions)
              let startDate = new Date(data.user.plan_expiary_date)
              // Set cycle start date to one month ago
              startDate.setMonth(startDate.getMonth() - 1)
              tmpData.current_period_start = Date.parse(startDate)/1000
              if( appStateContext.state.subscriptionInfo.isEnterpriseUser ) {
                // TBD: Enterprise animation packs have 1 year default expiration time. However multiple packs can be stacked
                // on top of a normal subscription plan. Therefore there is not always a single expiration date. Just punt
                // the underlying Freemium plan's expiration date for 12 months as a place holder end date.
                startDate.setMonth(startDate.getMonth() + 12)
                tmpData.current_period_end = startDate/1000
              } else {
                tmpData.current_period_end = data.user.plan_expiary_date/1000
              }
              appStateContext.dispatch({subscriptionInfo: tmpData}, () => {
                setDataLoaded(true)
              })
            }
            else {
              setDataLoaded(true)
            }
          })
        }
      }
    }
    else {
      if( props.LOADING ) {
        props.setLOADING({...props.LOADING, ...{show: false}})
      }
    }
  }, [dataLoaded, appStateContext.state.subscriptionInfo.name])

  ////////////////////////////////////////////////////////////////////
  // custom hook to re-initialize service upon handle browser refresh
  ////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if( queryString.parse(props.location.search).return || !appStateContext.state.anim3dInitialized || !appStateContext.state.accountDataRetrieved ) {
      props.initializeA3DService()
    } 
    else {
      if( props.LOADING.show ) {
        props.setLOADING({...props.LOADING, ...{show: false}})
      }
    }
  }, [appStateContext.state.anim3dInitialized, appStateContext.state.accountDataRetrieved, appStateContext.state.subscriptionInfo])

  ////////////////////////////////////////////////////////////////////////
  // Main Render for this Functional Component 
  ////////////////////////////////////////////////////////////////////////
  return buildProfilePage()

}