////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
// Main Functional Component for this application:
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
import React, {useEffect, useReducer, useRef, useState} from 'react'
import {Link, Redirect, Route, Switch, useHistory, useLocation} from 'react-router-dom'
import {LoginCallback, SecureRoute, Security} from '@okta/okta-react'
import {AppStateContext} from './AppStateContext'
import {OktaAuth, toRelativeUrl} from '@okta/okta-auth-js'
import queryString from 'query-string'
import {buildStyles, CircularProgressbarWithChildren} from 'react-circular-progressbar'
import ReactTooltip from 'react-tooltip'
import {useIdleTimer} from 'react-idle-timer'
import axios from 'axios'
import errorReporter from './components/common/ErrorReporter'
import 'bulma/css/bulma.css'
import CropButton from "./components/videocropandtrim/CropButton"
import ReactCrop, {Crop} from 'react-image-crop'
// PROJECT SOURCE
import {stateReducer} from './reducers'
import {
    AccountInfo,
    AnimJobLinks,
    AppState,
    DownloadLinkModel,
    JobSettings,
    PortalErrorFlags,
    PortalFallback
} from '../types'

import {
    abortRequest,
    analyzeVideoInputData,
    cancelSubscription,
    checkCustomCharacterFeatureCompatiability,
    checkForInProgressOrQueuedJobs,
    checkJobStatus,
    downloadModelThumbnails,
    getAccountCustomCharacters,
    getAllPlansData,
    getJobsDataForAccount,
    getLinksForJob,
    getNewPEServiceToken,
    getPeSaveUrls,
    getUserPlanData,
    redirectToCustomerPortal,
    redirectToPricingPageForNewPurchase,
    redirectToPricingPageWithCustomerPortal,
    removeCustomModelFromAccount,
    removeJobFromAccount,
    retrieveUserProfileInfo,
    startNewAnimOrPoseJob,
    stopInProgressJob,
    uploadJobDataToBackend
} from './components/api/apiRequests'
import PageTemplate from './components/PageTemplate'
import LoadingScreen from './components/common/LoadingScreen'
import InfoDialog from './components/common/InfoDialog'
import InputDialog from './components/common/InputDialog'
import ContactUs from './components/common/ContactUs'
import FeedbackForm from './components/common/FeedbackForm'
import AnimVersionPanel from './components/common/AnimVersionPanel'
import DragZone from './components/products/animate-3d/DragDrop'
import Support from './components/common/Support'
// import ParticlesBackground from './components/common/ParticlesBackground'
import DashboardPage from './components/DashboardPage'
import Anim3DHome from './components/products/animate-3d/Animate3DHome'
import GuidedFTE from './components/products/animate-3d/GuidedFTE'
import NewJobConfig from './components/products/animate-3d/NewJobConfiguration'
import Library from './components/products/animate-3d/Library'
import Previewer from './components/products/animate-3d/Previewer'
import Login from './components/authentication/SignInPage'
import ProfilePage from './components/authentication/AccountProfilePage'
import ForgotPwdPage from './components/authentication/ForgotPwdPage'
import ActivityPage from './components/admin/ActivityPage'
import AdminAPIApp from './components/admin/admin-tool/AdminAPIApp'
import AccountClosedPage from './components/authentication/AccountClosedPage'
import CharacterManagePage from './components/products/animate-3d/custom-character/CharacterManage'
import ProgressProvider from './components/products/animate-3d/ProgressProvider'
import DMBTSdkPage from './components/products/dmbtSdk'
import VRSdkPage from './components/products/vrSdk'
import YesNoSlider from './components/ui/YesNoSlider'
import DMDropDown from './components/ui/DMDropDown'
import DMDialog from './components/ui/DMDialog'
import DMDialogEnhanced from './components/ui/DMDialogEnhanced'
import DMToolTip from './components/ui/DMToolTip'
import DMDropDownKnob from './components/ui/DMDropDownKnob'
import DMDropDownColorPicker from './components/ui/DMDropDownColorPicker'
import DMSlider from './components/ui/DMSlider'
import Knob from './components/ui/KnobElem'
import CharacterSwiperDefault from './components/common/CharacterSwiperDefault'
// MultiPerson
import MultiPerson from "./components/mp_tracking/index"
import MultiPersonSelectorSwitch from "./components/mp_tracking/MultiPersonSelectorSwitch"
import MultiPersonSelectorDownloadDialog from "./components/mp_tracking/MultiPersonSelectorDownloadDialog"
import MotionLabeling from './components/motionLabeling/index'
import * as Enums from './components/common/enums'
import {oktaAuthConfig, oktaSignInConfig} from './config'
import {initialAppState} from './components/common/initialAppState'
// Backdrop
import Backdrop from './components/backdrop/backdrop'
// CSS
import 'react-circular-progressbar/dist/styles.css'
import './sass/App.scss'
import './styles/extra-app-style.css'
// IMAGES
import imgStandard from './images/animate-3d/character-select/default-characters.jpg'
import imgRobloxR15 from './images/animate-3d/character-select/roblox-r15.png'
import imgCustom from './images/animate-3d/character-select/model-custom.jpg'
import imgFaceModel from './images/animate-3d/female-face.jpg'
import imgNewAnim from './images/animate-3d/new-animation.jpg'
import imgNewPose from './images/animate-3d/new-static-pose.jpg'
import { shallowEqual, bubbleSort, bubbleSortForMultiPersonPreviewLinks } from './components/common/utils'
import ModalCancellationSurvey from './components/ui/DMCancellationSurvey'
import {generateVideoThumbnails} from './components/common/videoThumbnailsGenerator'
import {getErrorByCode, jobErrors} from './common/errors'
import JobFailedDialog from './components/dialog/JobFailedDialog'

import { useRootAtOriginEmulation, MODEL_NAME_FEMALE_WITH_FACE_UE, MODEL_NAME_MALE_UE } from './hooks/useRootAtOriginEmulation'
import {cleanTokens, getToken, getTokens} from "./components/common/hooks";
import {jobTypes} from "./components/common/enums";
import {convertToUserData, setUserData} from "./common/gTagHelper";
// strings
const pageTitleDashboard = "Product Dashboard"
const pageTitleA3DHome = "Home"
const titleDownload = "Download"
const titleCreateAnim = "Create"
const titleFTEGuide = "Animation Guide"
const textNotAvailable = "Thumbnail not available"
const textManageModels = "Manage Models"
const titleCouldNotRetrieve = "Could Not Retrieve Animations"
const textCouldNotRetrieve = "Sorry there was a problem, we could not retrieve animations for this job."
const text3DPoseTitle = "3D Pose Settings"
const textGenericErrorTitle = "Sorry, Something Went Wrong"
const textInputVideoErrorTitle = "Problem with Input Video"
const textDialogOk = "Ok"
const textDialogCancel = "Cancel"
const textDialogClose = "Close"
const textDialogDelete = "Delete"
const textDialogUpgrade = "Upgrade Plan"
const textDialogTrimLength = "Trim Length"
const textDialogCropVideo = "Crop Video"
const textDialogDownsample = "Downsample Video"
const textDialogTrimLengthAndCropVideo = "Trim Length & Crop Video"
const textDialogTrimLengthAndDownsample = "Trim Length & Downsample Video"
const textDefaultLoadingMsg = "Loading..."
const settingFlags = [
    <span key="setting-on" className="icon is-medium"><i
        className="fas fa-check-circle has-text-success fa-lg"></i></span>,
    <span key="setting-off" className="icon is-medium"><i
        className="fas fa-times-circle has-text-danger fa-lg"></i></span>
]
// strings
const jobType3dAnim = "3D Animation"
const jobType3dPose = "3D Pose"
const mp4EnableOutputTitle = "Enable MP4 Output"
const mp4EnableShadowTitle = "Enable Shadow"
const mp4EnableAudioTitle = "Enable MP4 Audio"
const modelSectionDefault = "Default"
const textUploadingVideo = "Uploading video, please wait..."
const textMultiPersonStep1 = "Please Click Save & Continue"
const textMultiPersonStep2 = "Please Review Multi-Person Selection"
const textAnalyzing = "Analyzing..."
const textAddMotionClip = "Add Video Clip"
const textAddImage = "Add Image"
const textFloating = "Floating"
const textMultiPersonAnimationSave = "Save & Continue"
const textMultiPersonPoseSave = "Continue"
// url
const dmSignUpUrl = "https://www.deepmotion.com/sign-up"
// icons
const iconMotionClip = "far fa-play-circle"
const iconImage = "far fa-image"
// default background (ie. Green Scren) color:
let defaultGSColor = [0, 177, 64, 0]

var tmpSize = 0

const closeModalFlags = Object.freeze({
    routeToModelSelect: 0,
    routeToLibrary: 1,
    routeToA3dHome: 2,
    routeToModelManage: 3,
    routeToProfilePage: 4
})
const NUM_MODELS_PER_ROW = 3

const resetJobObject = Object.freeze({
    isJobInProgress: false,
    animationJobProgress: 0,
    currWorkflowStep: Enums.uiStates.initial,
    silentUploadInProgress: false,
    videoStorageUrl: null,
    videoFileName: null
})

////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
// App Entry Point:
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
export default function App() {

    // browser history
    const history = useHistory()
    const location = useLocation()

    const customAuthHandler = () => {
        const tokens = getTokens()
        if (tokens) {
            oktaAuth.handleLoginRedirect(tokens)
            return
        }
        const queryParams = queryString.parse(location.search)
        if (queryParams.idToken && queryParams.accessToken) {
            history.push(`/?idToken=${encodeURIComponent(queryParams.idToken as string)}&accessToken=${encodeURIComponent(queryParams.accessToken as string)}`)
        } else {
            history.push(Enums.routes.SignIn)
        }
    }

    const oktaAuth = new OktaAuth(oktaAuthConfig)

    const restoreOriginalUri = async (_oktaAuth, originalUri) => {
        // to remember their last logged in route line with originalUri though likely need
        // make sure app state logic always has all the info it needs upon re-login.
        history.replace(toRelativeUrl(originalUri || Enums.routes.Dash, window.location.origin))
    }

    //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    // --- APP STATE ---
    const [STATE, DISPATCH] = useReducer(stateReducer, initialAppState as AppState)
    //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

    //additional state hooks:
    const [isComponentMounted, setIsComponentMounted] = useState(false)
    const [jobStatusPollingActive, setJobStatusPollingActive] = useState(false)
    const [activeJobMenu, setActiveJobMenu] = useState<number>(Enums.jobMenu.animSettings)
    const [LOADING, setLOADING] = useState({show: false, msg: textDefaultLoadingMsg})
    const [selectedFileType, setSelectedFileType] = useState(Enums.animFileType.FBX)
    const [closeAccountButtonEnabled, setcloseAccountButtonEnabled] = useState(false)
    const [cancelSubscriptionButtonEnabled, setCancelSubscriptionButtonEnabled] = useState({
        sub_name: false,
        email: false
    })
    const [subscriptionList, setSubscriptionList] = useState([])
    const [selectedSubscription, setSelectedSubscription] = useState<{ sub_id?: any, sub_name?: any }>({})
    const [deleteJobButtonEnabled, setDeleteJobButtonEnabled] = useState(false)
    const [rerunViewState, setRerunViewState] = useState<number>(Enums.pageState.rerunAnimSettings)
    const [rerunConfirmInfo, setRerunConfirmInfo] = useState({showModal: false, jobId: 0})
    const [showColorPickerDialog, setShowColorPickerDialog] = useState(false)
    const [thumbnailRatio, setThumbnailRatio] = useState("")
  // const [numCustomModels, setNumCustomModels] = useState(0)
    const [customModelThumbnailRatios, setCustomModelThumbnailRatios] = useState([])
  // const [rerenderCustomThumbnails, setRerenderCustomThumbnails] = useState(false)
    const [fileSelectThumbnailRatio, setFileSelectThumbnailRatio] = useState("")
    const [deleteModelThumbnailRatio, setDeleteModelThumbnailRatio] = useState("")
    const [showCancellationSurvey, setShowCancellationSurvey] = useState(false)
    const videoCropInit = Object.freeze({
        height: 100,
        unit: '%',
        width: 100,
        x: 0,
        y: 0
    })
    const [videoCropData, setVideoCropData] = useState<Crop>(videoCropInit)

    const [videoTrimData, setVideoTrimData] = useState(null)
    const fallbackInit: PortalFallback = Object.freeze({
        durationOriginal: 0, // original video length
        durationIsExceeded: false,
        durationIsFallback: false,
        durationIsFallbackSaved: false,
        fpsOriginal: 0,
        fpsIsExceeded: false,
        fpsIsFallback: false,
        fpsIsFallbackSaved: false,
        resolutionOriginal: 0,
        resolutionIsExceeded: false,
        resolutionIsFallback: false,
        resolutionIsFallbackSaved: false
    })
    const [fallback, setFallback] = useState(fallbackInit)
    const videoref = React.useRef(null)
    useEffect(() => {
        if (videoTrimData) {
            videoref.current.currentTime = Number(videoTrimData?.from)
        }
    }, [videoTrimData?.from, videoTrimData?.to])

    const checkStatusTimer = useRef(null)
    // multi-Person Tracking
    // Step1: multi-Person Tracking (switch Single Person or Multi-Person)
    const [multiPersonTracking, setMultiPersonTracking] = useState(false)
    // setp2: Multi-Person Selection (click save & continue)
    const [multiPersonSelection, setMultiPersonSelection] = useState(false)
    // Step3: Create Multi-Person Animation
    const [multiPersonIsAlreadyCreated, setMultiPersonIsAlreadyCreated] = useState(false)
    const [multiPersonRid, setMultiPersonRid] = useState('')
    const [multiPersonCharacter, setMultiPersonCharacter] = useState([])
    // motion labeling
    const [motionLabelingText, setMotionLabelingText] = useState('')

    /////////////////////////////////////////////////////////////////////
    // use effect hook for component mount
    /////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (!isComponentMounted) {
            componentMount()
        }
    }, [isComponentMounted]);
    /////////////////////////////////////////////////////////////////////
    // component un-mount
    /////////////////////////////////////////////////////////////////////
    useEffect(() => {
        return function cleanup() {
            componentUnMount()
        }
    }, [])

    /////////////////////////////////////////////////////////////////////
    // use effect hook for dashboard & FTE initialization
    /////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (location.pathname === Enums.routes.Dash || location.pathname === Enums.routes.Anim3dGuidedFTE) {
            if (STATE.accountDataRetrieved && STATE.anim3dInitialized) {
                // initialization function that supports browser refresh
                if (LOADING.show) {
                    setLOADING({show: false, msg: textDefaultLoadingMsg})
                }
            } else {
                if (!LOADING.show) {
                    // ensure loading wheel displayed if data not initialized yet
                    setLOADING({...LOADING, ...{show: true}})
                }
            }
        }
    }, [STATE.accountDataRetrieved, STATE.anim3dInitialized, location.pathname])

    /////////////////////////////////////////////////////////////////////
    // use effect hook for A3d home page
    /////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (location.pathname === Enums.routes.Anim3d) {
            if (STATE.anim3dInitialized && STATE.accountDataRetrieved) {
                if (LOADING.show) {
                    setLOADING({show: false, msg: textDefaultLoadingMsg})
                }
            } else {
                if (!LOADING.show) {
                    setLOADING({...LOADING, ...{show: true}})
                }
            }
        }
    }, [STATE.anim3dInitialized, location.pathname])

    /////////////////////////////////////////////////////////////////////
    // use effect hook for library initialization
    /////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (location.pathname === Enums.routes.Anim3dLibrary) {
            if (STATE.libraryInitialized) {
                sortLibraryByColumn()
                setLOADING({show: false, msg: textDefaultLoadingMsg})
            } else {
                if (!LOADING.show) {
                    setLOADING({...LOADING, ...{show: true}})
                }
            }
        }
    }, [STATE.libraryInitialized, location.pathname])

    /////////////////////////////////////////////////////////////////////
    // use effect hook for 3d models page initialization
    /////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (location.pathname === Enums.routes.Anim3dModelManage) {
            if (STATE.accountTotals.charactersList && STATE.pageState_CharacterManage !== Enums.pageState.init) {
                // if accountDataRetrieved was just set to true and we are currently
                // on dashboard route disable the loading screen
                if (LOADING.show && STATE.pageState_CharacterManage === Enums.pageState.ready) {
                    setLOADING({show: false, msg: textDefaultLoadingMsg})
                }
            } else {
                if (!LOADING.show) {
                    setLOADING({...LOADING, ...{show: true}})
                }
            }
        }
    }, [STATE.pageState_CharacterManage, STATE.accountTotals.charactersList, location.pathname])

    /////////////////////////////////////////////////////////////////////
    // hook for opening anim previewer either from Library button click
    // or when a new job finishes
    /////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (STATE.displayPreview && isExistingJob(STATE.animJobId) && Object.keys(STATE.animJobLinks).length !== 0) {
            if (location.pathname === Enums.routes.Anim3dCreate || location.pathname === Enums.routes.Anim3dLibrary) {
                // route the user to preview page on button click from Library and also for
                // newly created animation if user is on job progress sceeen
                history.push(Enums.routes.Anim3dPreview)
            } else if (location.pathname === Enums.routes.Anim3dGuidedFTE) {
                if (process.env.REACT_APP_NODE_ENV === 'production') {
                    (window as any).enhanced_conversion_data = convertToUserData(STATE as AccountInfo);
                    (window as any).gtag('event', 'conversion', {'send_to': 'AW-628695234/iG2SCI7W5tcDEMLB5KsC'});
                } else {
                    console.log(`*** Google AD: pretend to send First Job completed conversion tag => AW-628695234/iG2SCI7W5tcDEMLB5KsC for email:${STATE.email}`)
                }

                // using query param 'fte=true' to display custom models unlock
                // dialog on first job preview for new users
                history.push(Enums.routes.Anim3dPreview + "?fte=true")
            }
        }
    }, [STATE.displayPreview, STATE.animJobLinks])

    /////////////////////////////////////////////////////////////////////
    // hook for when current workflow step changes
    /////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (STATE.currWorkflowStep === Enums.uiStates.initial) {
            setVideoTrimData(null)
            setVideoCropData(videoCropInit)
        } else if (STATE.currWorkflowStep === Enums.uiStates.jobInProgress && STATE.isJobInProgress) {
            // TODO: Refactor to use single state to represent job in progress?
            if (location.pathname === Enums.routes.Anim3dLibrary || location.pathname === Enums.routes.Anim3dPreview) {
                // jobs that are not started from Library or Preview page are reruns, route to
                // Anim3dCreate route to job progress UI
                history.push(`${Enums.routes.Anim3dCreate}?rerun=true`)
            }
        }
    }, [STATE.currWorkflowStep])

    /////////////////////////////////////////////////////////////////////
    // use effect hook for download links change
    /////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (STATE.currDownloadLinks && Object.keys(STATE.currDownloadLinks).length && isExistingJob(STATE.animJobId)) {
            buildOutputFileTypeDropDown()
        }
    }, [STATE.currDownloadLinks])

    /////////////////////////////////////////////////////////////////////
    // re-render when dialogId changes
    /////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (!STATE.confirmDialogId) {
            // reset dialog related state when dialog closes
            setSelectedFileType(Enums.animFileType.FBX)
            setRerunViewState(Enums.pageState.rerunAnimSettings)
            setRerunConfirmInfo({showModal: false, jobId: 0})
            setcloseAccountButtonEnabled(false)
            setDeleteJobButtonEnabled(false)
        }
    }, [STATE.confirmDialogId])

    /////////////////////////////////////////////////////////////////////
    // hook for setting a new job in progress
    /////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (STATE.isJobInProgress) { // if a job was just started...
            if (STATE.currWorkflowStep === Enums.uiStates.jobInProgress &&
                STATE.animJobId && STATE.animJobId !== 0 && STATE.animJobId !== "") {
                if (!jobStatusPollingActive) {
                    setJobStatusPollingActive(true)
                    checkStatusTimerCallback()
                }
            }
        }
    }, [STATE.isJobInProgress, STATE.animJobId])

    /////////////////////////////////////////////////////////////////////
    // hook calculating img ratio
    /////////////////////////////////////////////////////////////////////
  // useEffect(() => {
  // }, [thumbnailRatio, fileSelectThumbnailRatio])

  // This is keeping the picture parallel, right?
  // useEffect(() => {
  //   if (numCustomModels > 0) {
  //     let modelThumbnailRatioList = new Array()
  //     let _imgArr = new Array()
  //     // first we build image columns for each model thumbnail
  //     const modelsList = STATE.accountTotals.platformCharactersList.concat(STATE.accountTotals.charactersList);
  //     for (let i = 0; i < numCustomModels; i++) {
  //       // let image = (STATE.accountTotals.charactersList[i].thumbImg instanceof Blob) ? URL.createObjectURL(STATE.accountTotals.charactersList[i].thumbImg) : imgCustom
  //       let image = (modelsList[i].thumbImg instanceof Blob) ? URL.createObjectURL(modelsList[i].thumbImg) : imgCustom

  //       modelThumbnailRatioList[i] = ""
  //       _imgArr[i] = new Image()
  //       _imgArr[i].src = image

  //       // calculate class for each thumbnail
  //       _imgArr[i].onload = function () {
  //         let thumbnailClass = ((this.naturalWidth / this.naturalHeight) > 1.3) ? "is-16by9 is-16by9-special mr-0 ml-0" : "is-1by1 m-0"
  //         modelThumbnailRatioList[i] = thumbnailClass
  //         setCustomModelThumbnailRatios(modelThumbnailRatioList)
  //         // setRerenderCustomThumbnails(true)
  //       }
  //     }
  //   }
  // }, [numCustomModels])
  // TODO: Don't know what this does
  // useEffect(() => {
  //   if (rerenderCustomThumbnails) {
  //     setRerenderCustomThumbnails(false)
  //   }
  // }, [rerenderCustomThumbnails])

    /////////////////////////////////////////////////////////////////////
    // define auth related functions for timeout and idle timer reset
    /////////////////////////////////////////////////////////////////////
    const handleOnIdle = event => {
        console.error(`user is idle, was last active at ${getLastActiveTime()}\n${event}`)
        //setErrorDialogInfo(true, Enums.eCodes.Unauthorized, "Your session has expired")
        alert("Your session has expired due to inactivity, please sign in again, " +
            "check your 'Create' page for jobs in progress and check your " +
            "'Library' page for recently completed jobs.")
        logoutUser()
    }
    const handleOnActive = event => reset()
    const handleOnAction = event => reset()

    const {reset, getRemainingTime, getLastActiveTime} = useIdleTimer({
        timeout: 1000 * 60 * Enums.idleTimeoutInMins,
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        onAction: handleOnAction,
        debounce: 500,
        crossTab: {
            emitOnAllTabs: true
        }
    })

    /////////////////////////////////////////////////////////////////////
    // add/remove event listener on component mount/un-mount
    // for detecting page refresh and maintaining React state
    /////////////////////////////////////////////////////////////////////
    function componentMount() {
        // Run okta as a service for several benefits including cross-tab synchronization
        // https://github.com/okta/okta-auth-js#running-as-a-service
        // console.log(`~~~ Attempting to initialize okta service ~~~`)
        // oktaAuth.start()

        // window.addEventListener("load", onLoad)
        // window.addEventListener("beforeunload", onUnload)

        if (process.env.REACT_APP_DEBUG) {
            // enable axios debugging in staging and local builds
            localStorage.setItem('debug', 'axios')
        }
        setIsComponentMounted(true)
    }

    /////////////////////////////////////////////////////////////////////
    function componentUnMount() {
        // console.log(`--- Attempting to stop okta service ---`)
        // oktaAuth.stop() // stop running service
        // window.removeEventListener("load", onLoad)
        // window.removeEventListener("beforeunload", onUnload)
        if (process.env.REACT_APP_DEBUG) {
            // remove axios debug cookie on component un-mount
            localStorage.removeItem('debug')
        }
        setIsComponentMounted(false)
    }

    /////////////////////////////////////////////////////////////////////
    // Special handling for page un-load in progress
    /////////////////////////////////////////////////////////////////////
    function onUnload(e) {
        // only backup state to local storage if user on an authenticated route
        // (ie. includes '/dashboard' in the path)
        if (window.location.pathname.toString().includes(Enums.routes.Dash)) {
            saveStateToLocalStorage(() => {
                // don't return until saving to local storage complete
                return
            })
        }
    }

    /////////////////////////////////////////////////////////////////////
    // Special handling for page load in progress
    /////////////////////////////////////////////////////////////////////
    function onLoad(e) {
        // only restore state from local storage if user on an authenticated route. this
        // helps manage state if/when the user refreshes the page/browser
        if (window.location.pathname.toString().includes(Enums.routes.Dash)) {
            checkAndRestoreStateIfCached()
        }
    }

    /////////////////////////////////////////////////////////////////////
    // Restores state from browser's local storage if key found
    /////////////////////////////////////////////////////////////////////
    function checkAndRestoreStateIfCached() {
        const localStorageData = localStorage.getItem(Enums.localStorageStateId)
        if (localStorageData !== null) {
            restoreAppStateFromLocalStorage(localStorageData)
        }
    }

    /////////////////////////////////////////////////////////////////////
    // Saves react state to browser's local storage
    /////////////////////////////////////////////////////////////////////
    function saveStateToLocalStorage(cb) {
        // to maintain state across page refresh we temporarily save all
        // state data to local storage, allow page refresh to happen,
        // then read back from storage and remove once complete
        let dataStr = JSON.stringify(STATE)
        localStorage.setItem(Enums.localStorageStateId, dataStr)
        if (cb) {
            cb()
        }
    }

    /////////////////////////////////////////////////////////////////////
    // Updates state hook defined in this component, mostly used
    // for restoring app state after page/broswer refresh
    //
    // @param storageData : data returned from local storage API
    /////////////////////////////////////////////////////////////////////
    function restoreAppStateFromLocalStorage(storageData) {
        const tmpData = JSON.parse(storageData)
        // tmpData.browserRefresh = true
        localStorage.removeItem(Enums.localStorageStateId)
        DISPATCH(tmpData as Partial<AppState>)
    }

    /////////////////////////////////////////////////////////////////////
    // Removes local auth and storage keys that may have been
    // set during app execution
    //
    // @param cb : callback function when done
    /////////////////////////////////////////////////////////////////////
    function removeLocalStorageData(cb) {
        // first remove any previous local storage data
        localStorage.removeItem(Enums.localStorageStateId)
        localStorage.removeItem(Enums.oktaCacheStorage)
        localStorage.removeItem(Enums.oktaTokenStorage)
        if (cb) {
            return cb()
        }
    }

    /////////////////////////////////////////////////////////////////////////////
    async function getUserInfoAndSubscriptionData() {
        await getNewPEServiceToken()
        let accountInfo = null
        let aList = null
        const res = await retrieveUserProfileInfo().catch((error) => {
            if (error.message && (typeof error.message) === 'string' && error.message.includes("403")) {
                console.error('Access is denied : 403')
                logoutUser()
            } else {
                console.error(`Could not retrieve user info -> ${error}`)
            }
            throw error
        })

        // set feature locks data
        const accountPlansData = await getAllPlansData()
        Enums.setFeatureLocksData(accountPlansData)

        // store the account info so we can update further below...
        accountInfo = res.data
        aList = updateProductsAccessList(JSON.parse(accountInfo.groups))
        const userPlanDataRes = await getUserPlanData(true)
        let planData = userPlanDataRes.data
        const userPlanData = updateUserPlanData({...planData, ...{subcriptionInfo: accountInfo.subs}})

        let subscriptionData = {...STATE.subscriptionInfo, ...accountInfo.subs, ...userPlanData.subscriptionInfo}
        if (subscriptionData.name === Enums.accountPlansInfo[0].name) {
            // Set billing cycle dates for Freemium or Enterprise plans (otherwise data
            // is read from Stripe for paid subscriptions)
            let startDate = new Date(userPlanData.subscriptionInfo.plan_expiary_date)
            // Set cycle start date to one month ago
            startDate.setMonth(startDate.getMonth() - 1)
            // subscriptionData.current_period_start = Date.parse(startDate) / 1000
            subscriptionData.current_period_start = startDate.getTime() / 1000
            if (subscriptionData.isEnterpriseUser) {
                // TBD: Enterprise animation packs have 1 year default expiration time. However multiple packs can be stacked
                // on top of a normal subscription plan. Therefore there is not always a single expiration date. Just punt
                // the underlying Freemium plan's expiration date for 12 months as a place holder end date.
                startDate.setMonth(startDate.getMonth() + 12)
                subscriptionData.current_period_end = startDate.getTime() / 1000
            } else {
                subscriptionData.current_period_end = userPlanData.subscriptionInfo.plan_expiary_date / 1000
            }
        }
        const subscription = subscriptionData.name === Enums.accountPlansInfo[0].name ? subscriptionData : accountInfo.subs
        const subscriptionInfo = {...subscription, ...{history: accountInfo.subsHistory ?? STATE.subscriptionInfo.history}}

        return {
            firstName: accountInfo.firstName,
            lastName: accountInfo.lastName,
            phone: accountInfo.phone,
            address:accountInfo.address,
            uid: accountInfo.uid,
            firstLogin: accountInfo.firstLogin,
            groups: JSON.parse(accountInfo.groups),
            email: accountInfo.email,
            history: accountInfo.subsHistory,
            accessList: aList,
            // for paid users read subscription data from backend API response
            subscriptionInfo: subscriptionInfo
        }
    }

    /////////////////////////////////////////////////////////////////////////////
    async function initializeAccountInfo(skipUpdate) {
        // set User for the error reporter
        errorReporter.setUser()

        if (STATE.accountDataRetrieved) {
            return {accountTotals: STATE.accountTotals, subscriptionInfo: STATE.subscriptionInfo}
        }
        if (!LOADING.show) {
            setLOADING({...LOADING, ...{show: true}})
        }
        const data = await getUserInfoAndSubscriptionData().catch((error) => {
            throw `Error encountered getUserInfoAndSubscriptionData.\n${error}`
        })
        setUserData(data as AccountInfo)
        if (!skipUpdate) {
            DISPATCH({dispatchType: 'initializeAccountInfo', payload: data})
        }
        return data
    }

    /////////////////////////////////////////////////////////////////////////////
    async function initializeA3DService(skipUpdate) {
        if (STATE.anim3dInitialized) {
            return
        }
        setLOADING({show: true, msg: textDefaultLoadingMsg})
        try {
            // create a running state object that we add to and propagate down
            // through the below async chain
            const accountData = await initializeAccountInfo(skipUpdate)
            checkSubscription(accountData.subscriptionInfo)
            let newStateDataObject = {...accountData}
            console.log(`>>> initializeA3DService: accountData.firstLogin=${(accountData as any).firstLogin}, STATE.firstLogin=${STATE.firstLogin}`);
            if (STATE.firstLogin === undefined && (accountData as any)?.firstLogin) {
                (newStateDataObject as AccountInfo).firstLogin = false;

                if (process.env.REACT_APP_NODE_ENV === 'production') {
                    (window as any).enhanced_conversion_data = convertToUserData(accountData as AccountInfo);
                    (window as any).gtag('event', 'conversion', {'send_to': 'AW-628695234/EVUoCPGm29cDEMLB5KsC'});
                } else {
                    console.log(`*** Google AD: pretend to send First Login conversion tag => AW-628695234/EVUoCPGm29cDEMLB5KsC for email:${(accountData as any).email}`)
                }
            }
            await initializeLibrary()
            const planData = await getUserPlanData(true)
            const billData = updateBillingCycleData({...newStateDataObject, ...planData.data})
            newStateDataObject = {...newStateDataObject, ...billData}
            const res = await getAccountCustomCharacters(true)
            const modelData = await parseAccountModelsData(res)
            // Set Adult Female as default
            const DefaultCustomModelInfo = modelData.accountTotals.platformCharactersList.find(model => model.name === Enums.defaultModelName)

            const DefaultAnimJobSettings = {...STATE.animJobSettings, ...{customModelInfo: DefaultCustomModelInfo}}
            const sortedCharacterListByDate = sortCharacterListByDate(modelData.accountTotals.charactersList)
            const newTotals = {
                accountTotals: {
                    ...modelData.accountTotals, ...{charactersList: sortedCharacterListByDate}
                },
                animJobSettings: DefaultAnimJobSettings,
                animJobSettingsSnapshots: JSON.stringify(DefaultAnimJobSettings)
            }
            newStateDataObject = {...newStateDataObject, ...newTotals}
            if (!skipUpdate) {
                DISPATCH({dispatchType: 'initializeService', payload: newStateDataObject})
            }
            return
        } catch (error) {
            console.error(`Error encountered while initializing Animate 3D service.\n ${error}`)
            throw error
        }
    }

    function checkSubscription(subscriptionInfo) {
        if (subscriptionInfo.cancel_at_period_end && !subscriptionInfo.cancel_confirmed) {
            process.env.NODE_ENV !== "development" && setShowCancellationSurvey(true)
        }
    }

    /////////////////////////////////////////////////////////////////////////////
    async function initializeLibrary() {
        if (STATE.libraryInitialized) {
            return
        }
        setLOADING({show: true, msg: 'Getting jobs data...'})
        let res = await getJobsDataForAccount(true)
        const accountJobData = parseAccountJobsData(res)
        let dataObj: Partial<AppState> = {
            ...accountJobData,
            displayPreview: STATE.openFirstJob ? true : false,
            numPages: Math.ceil(accountJobData.jobsData.length / STATE.rowsPerPage)
        }
        if (!STATE.isJobInProgress) {
            res = await checkForInProgressOrQueuedJobs(true)
      if (res.data.count >= 1 && res.data.list) {
        const MPTJobFirstStep = res.data.list[0].parameters.find(item => item === 'pipeline=mp_detection')
        if(!MPTJobFirstStep) {
                console.log('initializeLibrary found in progress jobs')
                dataObj = {
                    ...dataObj,
                    isJobInProgress: true,
                    currWorkflowStep: Enums.uiStates.jobInProgress,
                    animJobId: res.data.list[0].rid
                }
            }
        }
    }

        return DISPATCH({dispatchType: 'initializeLibrary', payload: dataObj})
    }

    /////////////////////////////////////////////////////////////////////////////
    async function initializeCharacterManagePage() {
        setLOADING({show: true, msg: 'Getting model data...'})
        try {
            await initializeAccountInfo(false)
            await initializeA3DService(false)
            await initializeLibrary()
            const customCharactersResp = await getAccountCustomCharacters(true)
            const accountTotalsWithThumbnails = await parseAccountModelsData(customCharactersResp)
            // Set Adult Female as default
            const DefaultCustomModelInfo = accountTotalsWithThumbnails.accountTotals.platformCharactersList.find(model => model.name === Enums.defaultModelName)
            const DefaultAnimJobSettings = {...STATE.animJobSettings, ...{customModelInfo: DefaultCustomModelInfo}}
            const sortedCharacterListByDate = sortCharacterListByDate(accountTotalsWithThumbnails.accountTotals.charactersList)
            const newTotals = {...accountTotalsWithThumbnails.accountTotals, ...{charactersList: sortedCharacterListByDate}}
            const newStateData = {
                accountTotals: newTotals,
                pageState_CharacterManage: Enums.pageState.ready,
                animJobSettings: DefaultAnimJobSettings,
                animJobSettingsSnapshots: JSON.stringify(DefaultAnimJobSettings)
            }
            return DISPATCH({dispatchType: 'initialize3dModelsPages', payload: newStateData})
        } catch (error) {
            DISPATCH({pageState_CharacterManage: Enums.pageState.ready})
            throw `Error encountered while intializing 3d models page data:\t${error}\n${JSON.stringify(error, null, 4)}`
        }
    }

    ////////////////////////////////////////////////////////////////////
    async function getLatestPlanMinutes() {
        let userPlanData = null
        const res = await getUserPlanData(true)
        userPlanData = {...res.data}
        let data = updateUserPlanData(userPlanData)
        // accountTotals and subscriptionInfo
        userPlanData = {...userPlanData, ...data}
        let billingCycleData = updateBillingCycleData(userPlanData)
        let accountInfo = {
            accountTotals: {...STATE.accountTotals, ...billingCycleData.accountTotals},
            subscriptionInfo: {...STATE.subscriptionInfo, ...billingCycleData.subscriptionInfo},
            currentBillCycleInfo: {...STATE.currentBillCycleInfo, ...billingCycleData.currentBillCycleInfo}
        }
        return accountInfo
    }

    ////////////////////////////////////////////////////////////////////
    // parses the data returned from the list models request
    ////////////////////////////////////////////////////////////////////
    function sortCharacterListByDate(data) {
        let outputList = []
        data.forEach(item => {
            const d = new Date(item.ctime)
            // convert date to human readable format
            const addDate = [
                    d.getMonth() + 1,
                    d.getDate(),
                    d.getFullYear()].join('/') + ' ' +
                [Enums.addZero(d.getHours()),
                    Enums.addZero(d.getMinutes()),
                    Enums.addZero(d.getSeconds())].join(':')

            const modelObject = {
                id: item.id,
                name: item.name,
                platform: item.platform,
                thumb: item.thumb,
                rigId: item.rigId,
                compatibilityDetectionStatus: item.compatibilityDetectionStatus,
                faceDataType: item.faceDataType,
                handDataType: item.handDataType,
                ctime: item.ctime,
                mtime: item.mtime,
                date: addDate,
                thumbImg: item.thumbImg
            }
            outputList.push(modelObject)
        })
        outputList.sort(function (a, b) {
            return b.ctime - a.ctime
        })
        return outputList
    }

    ////////////////////////////////////////////////////////////////////
    // Gets/Updates latest minutes balance for the current account
    //
    // @param data : response data from user data API
    ////////////////////////////////////////////////////////////////////
    function updateUserPlanData(data) {
        let tmpVal = data.accountTotals ? data.accountTotals : STATE.accountTotals
        tmpVal.maxTimeInSeconds = 0

        // get latest remaining/used minutes data:
        for (let i = 0; i < data.userPackMinute.length; i++) {
            if (data.userPackMinute[i].active) {
                tmpVal.maxTimeInSeconds = data.userPackMinute[i].staticData.minutes * 60
            }
        }
        // Aggregate max monthly mins + permanent mins to calculate total seconds
        if (data.user.minute_balance_permanent) {
            tmpVal.maxTimeInSeconds += data.user.minute_balance_permanent * 60
        }

        if (!tmpVal.maxTimeInSeconds) {
            // if no active plans found default to Freemium mins
            tmpVal.maxTimeInSeconds = Enums.accountPlansInfo[0].minsInt * 60
        }

        // Aggregate recurring mins + permanent mins balances
        let remainingTimeInSeconds = Math.floor( (data.user.minute_balance +
            data.user.minute_balance_permanent +
            data.user.minute_balance_correction +
            data.user.minute_balance_labeling)
            * 60 )
        tmpVal.remainingTimeInSeconds = remainingTimeInSeconds

        // get users remaining rerun count for current billing cycle
        tmpVal.rerun_count = data.user.rerun_count
        tmpVal.max_rerun = data.userPackFeature[0].staticData.max_rerun

        // check for higher max rerun account added to account through feature pack(s):
        for (let i = 0; i < data.userMaxUpgradePackFeature.length; i++) {
            if (data.userMaxUpgradePackFeature[i].staticData.max_rerun > tmpVal.max_rerun) {
                tmpVal.max_rerun = data.userMaxUpgradePackFeature[i].staticData.max_rerun
            }
        }

        // partial seconds are omitted when checking for expired balance
        if (remainingTimeInSeconds === 0) {
            tmpVal.currCycleMinsExpired = true
        } else {
            tmpVal.currCycleMinsExpired = false
        }

        // check for max character limit:
        tmpVal.characterLimit = Enums.accountPlansInfo[0].modelsInt
        for (let i = 0; i < data.userPackFeature.length; i++) {
            if (data.userPackFeature[i].active) {
                tmpVal.characterLimit = data.userPackFeature[i].staticData.max_custom_character
            }
        }
        let tmpObj = {...data.subscriptionInfo}
        // store cycle end date (for both monthly & annual plans)
        tmpObj.plan_expiary_date = data.user.plan_expiary_date
        // check for enterprise user flag
        if (data.user.isEnterpriseUser) {
            tmpObj.isEnterpriseUser = true
        } else {
            tmpObj.isEnterpriseUser = false
        }
        // finally we update the accountTotals state
        return {accountTotals: tmpVal, subscriptionInfo: tmpObj}
    }

    ////////////////////////////////////////////////////////////////////
    // Performs background upload API for Guided FTE and normal
    // job creation
    ////////////////////////////////////////////////////////////////////
    async function uploadInputVideo() {
        // start background upload process for input video/image
        try {
            await performBackgroundVideoUpload(true)
        } catch (error) {
            if (error.message === 'canceled') {
                console.debug(`Uploading file is canceled-\n${error}`)
                return
            }
            console.error(`Problem encountered during background upload process-\n${error}`)
            if (!STATE.inputVideoData || !STATE.inputVideoData.videoRes) {
                // MD-8355 - check to videoRes in case completely invalid file such as a PDF
                // renamed as MP4 since we attempt to access keys of that object when valid
                let dialogData = STATE.dialogInfo
                dialogData.videoFileName = STATE.inputVideoData.fileName
                DISPATCH({...{dialogInfo: dialogData}, confirmDialogId: Enums.confirmDialog.inputInvalidOrCorrupt})
                console.error(`Could not read video data from user selected file.\n${error}`)
            } else {
                DISPATCH({
                    confirmDialogId: Enums.eCodes.InternalServerError
                })
            }
            return
        }
        DISPATCH({
            silentUploadInProgress: false,
        })

        // now that we have video metadata check against subscription limits and
        let errorFlags = {
            maxResolution: false,
            maxDuration: false,
            maxFps: false,
        }

        // check if need fallback
        let _fallback = {...fallback}

        const maxResolution: any = checkFeatureAvailability(STATE.subscriptionInfo.name, Enums.featureLockNames.maxResolution)

        if (maxResolution && STATE.inputVideoData.videoRes) {
            const normalizedFileWidth = Math.max(STATE.inputVideoData.videoRes.w, STATE.inputVideoData.videoRes.h)
            const normalizedFileHeight = Math.min(STATE.inputVideoData.videoRes.w, STATE.inputVideoData.videoRes.h)
            const normalizedWidthLimit = Math.max(maxResolution.w, maxResolution.h)
            const normalizedHeightLimit = Math.min(maxResolution.w, maxResolution.h)
            if (normalizedFileWidth > normalizedWidthLimit || normalizedFileHeight > normalizedHeightLimit) {
                errorFlags.maxResolution = true
                _fallback = {
                    ..._fallback,
                    resolutionOriginal: STATE.inputVideoData.videoRes,
                    resolutionIsExceeded: true
                }
            }
        }


        const maxDuration = checkFeatureAvailability(STATE.subscriptionInfo.name, Enums.featureLockNames.maxDuration) as number

        // Check the maximum video length
        if (STATE.inputVideoData.fileLength > maxDuration) {
            errorFlags.maxDuration = true
            _fallback = {
                ..._fallback,
                durationIsExceeded: true,
                durationOriginal: STATE.inputVideoData.fileLength
            }
        }

        // feature gates such as max duration, fps, resolution
        // Check the maximum video fps
        const maxFps = checkFeatureAvailability(STATE.subscriptionInfo.name, Enums.featureLockNames.maxFps)
        if (STATE.inputVideoData.fps > maxFps) {
            errorFlags.maxFps = true
            _fallback = {
                ..._fallback,
                fpsIsExceeded: true,
                fpsOriginal: STATE.inputVideoData.fps
            }
        }
        let tncSupported = STATE.animJobSettings.jobType === Enums.jobTypes.animation
        //////////////////////////////////////////////////////////////////////////////////////////////
        // tncSupported threshold, based on the Linear Regression model from QA test data:
        // The actual coefficients are -1.09044860e-10  4.53377658e-04 -1.19784784e-03 -3.54473633e-08
        if (tncSupported) {
            const threshold = (-1.09 * STATE.inputVideoData.fileSize / 1000000
                + 4.534 * STATE.inputVideoData.fileLength
                - 11.98 * STATE.inputVideoData.fps
                - 3.545 * STATE.inputVideoData.videoRes.w * STATE.inputVideoData.videoRes.h / 10000) + 8000
            tncSupported = threshold > 0
        }

        DISPATCH({tncSupported})
        // check if any video validation problems need to be solved
        if (Object.values(errorFlags).some((flag) => flag === true)) {
            tncSupported && setFallback(_fallback)
            const inputData = STATE.inputVideoData
            inputData.errorFlags = errorFlags
            DISPATCH({
                confirmDialogId: tncSupported ? Enums.confirmDialog.VideoProblemsSolving : Enums.confirmDialog.VideoValidationFailed,
                currWorkflowStep: tncSupported ? Enums.uiStates.fileSelected : Enums.uiStates.initial,
                isJobInProgress: false,
                inputVideoData: inputData,
                silentUploadInProgress: false
            })
        }
    }

    function checkBeforeCreateJob() {
        let errorFlags: PortalErrorFlags = {
            maxDuration: false,
        }

        const maxDuration = checkFeatureAvailability(STATE.subscriptionInfo.name, Enums.featureLockNames.maxDuration) as number
        let duration = STATE.inputVideoData.fileLength
        if (videoTrimData) {
            duration = videoTrimData.to - videoTrimData.from
        }
        if (duration > maxDuration) {
            errorFlags.maxDuration = true
        }
        if (fallback['resolutionIsExceeded'] && !fallback["resolutionIsFallbackSaved"]) {
            errorFlags.maxResolution = true
        }

        // check if any video validation problems need to be solved
        if (Object.values(errorFlags).some((flag) => flag === true)) {
            const inputData = STATE.inputVideoData
            inputData.errorFlags = errorFlags
            DISPATCH({
                confirmDialogId: Enums.confirmDialog.VideoProblemsSolving,
                currWorkflowStep: Enums.uiStates.fileSelected,
                isJobInProgress: false,
                inputVideoData: inputData,
                silentUploadInProgress: false,
                displayTrimAndCropDialog: false
            })
        } else {
            DISPATCH({confirmDialogId: Enums.confirmDialog.confirmNewAnimJob})
        }
    }

    ////////////////////////////////////////////////////////////////////
    // stops an in-progress job
    ////////////////////////////////////////////////////////////////////
    async function stopCurrentHandler(retry) {
        const res = await stopInProgressJob(STATE.animJobId).catch((error) => {
            console.error('Problem encountered while stopping the current job.');
            handleHttpError(error, "Problem Stopping Job")
        })
        setVideoTrimData(null)
        setVideoCropData(videoCropInit)
        if (res.status !== 200) {
            console.error(`Error attempting to cancel job ${STATE.animJobId} --> [HTTP ${res.status}] ${res.error ? res.error : JSON.stringify(res.data, null, 4)}`)
            return
        }
        let inputData = {
            selectedFile: null,
            fileName: null,
            fileLength: null,
            fileSize: null,
            videoRes: null,
            fps: null,
            codec: null
        }
        if (checkStatusTimer.current) {
            clearTimeout(checkStatusTimer.current)
            checkStatusTimer.current = null
        }

        setJobStatusPollingActive(false)

        // cancel the current job and reset the UI workflow
        DISPATCH({
            inputVideoData: inputData,
            currWorkflowStep: Enums.uiStates.initial,
            currFTEStep: Enums.FTESteps.begin,
            animJobId: 0,
            animationJobProgress: 0,
            animationJobPositionInQueue: undefined,
            isJobInProgress: false,
            confirmDialogId: Enums.confirmDialog.none,
            videoStorageUrl: null,
            videoFileName: null
        })
    }

    ////////////////////////////////////////////////////////////////////
    // Checks for access to specific features based on account plan
    //
    // FIXME: fix the return value typing, we should avoid polymorphism
    ////////////////////////////////////////////////////////////////////
    function checkFeatureAvailability(planName: string, featureName: string) {
        let planIndex = 0 // default plan to Freemium
        let activePackNames = []
        // First we loop through the custom packs array and record the names
        // of any active packs we find
        if (STATE.subscriptionInfo.featurePacks) {
            for (let pack of STATE.subscriptionInfo.featurePacks) {
                if (pack.active) {
                    // parse pack name, check if index higher
                    activePackNames.push(pack.pack_id)
                }
            }
        }
        // get index of plan based on subscription name, scan through in
        // reverse order to make sure we apply highest pack features
        for (let i = Enums.accountPlansInfo.length - 1; i >= 0; i--) {
            let found = false
            for (let activePack of activePackNames) {
                if (activePack.toLowerCase().includes(Enums.accountPlansInfo[i].name.toLowerCase())) {
                    planIndex = i
                    found = true
                    break
                }
            }
            if (found) {
                break
            } else {
                if (Enums.accountPlansInfo[i].name.toLowerCase() === planName.toLowerCase()) {
                    planIndex = i
                    break
                }
            }
        }
        // find corresponding feature data for feature in question
        for (const [key, value] of Object.entries(Enums.featureLocksData)) {
            if (key === featureName) {
                return value[planIndex]
            }
        }
        console.log(`Warning: Could not find feature availability data for feature: ${featureName}`)
        return null
    }

    ////////////////////////////////////////////////////////////////////
    // builds Knob UI element for PE Smoothness selector
    ////////////////////////////////////////////////////////////////////
    function buildPoseFilteringSelect(stateObj) {
        if (!STATE.subscriptionInfo) {
            return
        }
        let featureAvailable = checkFeatureAvailability(STATE.subscriptionInfo.name, Enums.featureLockNames.peSmoothness)
        return (
            <div style={{display: 'inline-flex'}}>
                {
                    featureAvailable
                        ?
                        <div className="cursor-grab">
                            <Knob className="mr-4"
                                  size={46}
                                  numTicks={10}
                                  degrees={180}
                                  min={1}
                                  max={100}
                                  value={stateObj.poseFilteringStrength * 100}
                                  color={true}
                                  lMargin={10}
                                  rMargin={15}
                                  onChange={changeSmoothnessSelect}
                                  snapToTicks={true}
                            />
                        </div>
                        :
                        <div className="no-cursor">
                            <Knob className="mr-4 no-cursor" onMouseDown={null} onClick={null}
                                  disabled={true}
                                  size={46}
                                  numTicks={10}
                                  degrees={180}
                                  min={1}
                                  max={100}
                                  value={0}
                                  color={true}
                                  lMargin={10}
                                  rMargin={15}
                            />
                        </div>
                }
                <span className={"subtitle is-5 ml-5 " + (!featureAvailable ? " no-cursor dm-gray" : " has-text-white")}
                      style={{marginTop: 'auto', marginBottom: 'auto'}}>
          {
              !featureAvailable
              &&
              <span className="icon is-small ml-2 mr-4">
              <i className="fas fa-lock fa-lg has-text-black" aria-hidden="true"></i>
            </span>
          }
                    {Enums.dropDownLabels.motionSmoothing}
                    <span className="mr-2">
            {stateObj.poseFilteringStrength === 0.0
                ?
                " 0.0"
                :
                <span className="has-text-success">
                {
                    (parseInt(stateObj.poseFilteringStrength) === 1 ? " 1.0" : (" " + stateObj.poseFilteringStrength))
                }
              </span>
            }
          </span>
          <DMToolTip
              text={Enums.toolTipSmoothness}
              tipId="smoothness-tip"
              isTipHtml={true}
              noMargins={true}
          />
        </span>
            </div>
        )
    }

    ////////////////////////////////////////////////////////////////////
    // builds Knob UI element for Eye Tracking Sensitivity selector
    ////////////////////////////////////////////////////////////////////
    function buildEyeTrackingSensitivitySelect(stateObj, faceEnabled, faceSupported) {
        let featureEnabled = faceEnabled
        return (
            <div style={{display: 'inline-flex'}}>
                {
                    featureEnabled && faceSupported
                        ?
                        <div className="cursor-grab">
                            <Knob className="mr-4"
                                  size={46}
                                  numTicks={10}
                                  degrees={180}
                                  min={1}
                                  max={100}
                                  value={stateObj.iris_turning_agility * 100}
                                  color={true}
                                  lMargin={10}
                                  rMargin={15}
                                  onChange={changeEyeTrackingSensitivity}
                                  snapToTicks={true}
                            />
                        </div>
                        :
                        <div className="no-cursor">
                            <Knob className="mr-4 no-cursor" onMouseDown={null} onClick={null}
                                  disabled={true}
                                  size={46}
                                  numTicks={10}
                                  degrees={180}
                                  min={1}
                                  max={100}
                                  value={50.5}
                                  color={true}
                                  lMargin={10}
                                  rMargin={15}
                            />
                        </div>
                }
                <span
                    className={"subtitle is-5 ml-5 " + (featureEnabled && faceSupported ? " has-text-white" : " no-cursor dm-gray")}
                    style={{marginTop: 'auto', marginBottom: 'auto'}}>
          {Enums.dropDownLabels.eyeTrackingSensitivity}
                    <span className="mr-2">
            {stateObj.iris_turning_agility === 0.0
                ?
                " 0.0"
                :
                <span className="has-text-success">
                {
                    (parseInt(stateObj.iris_turning_agility) === 1 ? " 1.0" : (" " + stateObj.iris_turning_agility))
                }
              </span>
            }
          </span>
          <DMToolTip
              text={Enums.toolTipEyeTrackingSensitivity}
              tipId="eyeTrackingSensitivity-tip"
              isTipHtml={true}
              noMargins={true}
          />
        </span>
            </div>
        )
    }

    ////////////////////////////////////////////////////////////////////
    // builds Knob UI element for Video Speed Multiplier feature
    ////////////////////////////////////////////////////////////////////
    function buildSpeedMultiplierSelect(stateObj) {
        if (!STATE.subscriptionInfo) {
            return
        }
        let featureAvailable = checkFeatureAvailability(STATE.subscriptionInfo.name, Enums.featureLockNames.slowMotion)
        return (
            <div style={{display: 'inline-flex'}}>
                {
                    featureAvailable
                        ?
                        <div className="cursor-grab">
                            <Knob className="mr-4"
                                  size={46}
                                  numTicks={8}
                                  degrees={180}
                                  min={1}
                                  max={100}
                                  value={((stateObj.videoSpeedMultiplier - 1) / 7.0 * 100)}
                                  color={true}
                                  lMargin={10}
                                  rMargin={15}
                                  onChange={changeSpeedMultSelect}
                            />
                        </div>
                        :
                        <div className="no-cursor">
                            <Knob
                                className="mr-4 no-cursor"
                                onMouseDown={null} onClick={null}
                                disabled={true}
                                size={46}
                                numTicks={8}
                                degrees={180}
                                min={1}
                                max={100}
                                value={0}
                                color={true}
                                lMargin={10}
                                rMargin={15}
                            />
                        </div>
                }
                <span
                    className={"subtitle is-5 ml-5 " + (!featureAvailable ? " no-cursor dm-gray" : " has-text-white")}
                    style={{marginTop: 'auto', marginBottom: 'auto'}}>
                    {
                        !featureAvailable
                        &&
                        <span className="icon is-small ml-2 mr-4">
                        <i className="fas fa-lock fa-lg has-text-black" aria-hidden="true"></i>
                        </span>
                    }
                    {Enums.dropDownLabels.videoSpeedMultiplier}
                    <span className="mr-2">
                        {stateObj.videoSpeedMultiplier <= 1.0
                            ?
                            " 1.0"
                            :
                            <span className="has-text-success">
                                {/* highlight values > 1.0 */}
                                {" " + stateObj.videoSpeedMultiplier}
                            </span>
                        }
                    </span>
                    <DMToolTip
                        text={Enums.toolTipVideSpeedMult}
                        tipId="videospeed-tip"
                        isTipHtml={true}
                        noMargins={true}
                    />
                </span>
            </div>
        )
    }

    ////////////////////////////////////////////////////////////////////////
    function changeSmoothnessSelect(value: string) {
        let newStateData = STATE.animJobSettings
        // convert from [0-100] int range to float [0.0-1.0]
        newStateData.poseFilteringStrength = (Math.abs(parseFloat(value) / 100)).toFixed(1)
        DISPATCH({animJobSettings: newStateData})
    }

    ////////////////////////////////////////////////////////////////////////
    function changeSmoothnessReRunSelect(value) {
        let newStateData = STATE.rerunSettings
        // convert from [0-100] int range to float [0.0-1.0]
        newStateData.poseFilteringStrength = (Math.abs(parseFloat(value) / 100)).toFixed(1)
        DISPATCH({rerunSettings: newStateData})
    }

    ////////////////////////////////////////////////////////////////////////
    function changeEyeTrackingSensitivity(value: string) {
        let newStateData = STATE.animJobSettings
        // convert from [0-100] int range to float [0.0-1.0]
        newStateData.iris_turning_agility = (Math.abs(parseFloat(value) / 100)).toFixed(1)
        DISPATCH({animJobSettings: newStateData})
    }

    ////////////////////////////////////////////////////////////////////////
    function changeSpeedMultSelect(value) {
        let newStateData = STATE.animJobSettings
        // convert from [0-100] int range to float [1.0-8.0]
        newStateData.videoSpeedMultiplier = (Math.abs((value / 100.0) * 7.0) + 1).toFixed(1)
        DISPATCH({animJobSettings: newStateData})
    }

    ////////////////////////////////////////////////////////////////////////
    function changeSpeedMultReRunSelect(value, cb) {
        let newStateData = STATE.rerunSettings
        // convert from [0-100] int range to float [1.0-8.0]
        newStateData.videoSpeedMultiplier = (Math.abs((value / 100.0) * 7.0) + 1).toFixed(1)
        DISPATCH({rerunSettings: newStateData})
    }

    /////////////////////////////////////////////////////////////////////
    // checkStatusTimerCallback() : Checks status of the currently processing animation
    // job. This is called on a timing loop when a job is currently processing
    // to get progress updates, is also called upon component mount.
    //
    /////////////////////////////////////////////////////////////////////
    async function checkStatusTimerCallback() {
        if (checkStatusTimer.current) {
            clearTimeout(checkStatusTimer.current)
            checkStatusTimer.current = null
        }
        
        if (STATE.animJobId === null || STATE.animJobId === "" || STATE.animJobId === 0 ||
            !STATE.isJobInProgress || STATE.currWorkflowStep !== Enums.uiStates.jobInProgress) {
            console.log(`\ncheckStatusTimerCallback: ${JSON.stringify(STATE, null, 4)}\n`)
            DISPATCH({currWorkflowStep: Enums.uiStates.initial})
            setLOADING({show: false, msg: textDefaultLoadingMsg})
            setJobStatusPollingActive(false)
            return
        }

        const statusRes = await checkJobStatus(STATE.animJobId, true).catch((error) => {
            console.error(`Problem checking job status ${STATE.animJobId}, error is ${error}`)
            setErrorDialogInfo(true, Enums.eCodes.OtherError, "Problem Checking Job Status")
            setJobStatusPollingActive(false)
            return null
        })

        if (statusRes == null) {
            return
        }

        // check against count, loop through count checking statuses
        // status array element contains job's rid, status, step, and total fields
        if (statusRes.data.count > 0) {
            setLOADING({show: false, msg: textDefaultLoadingMsg})
            const statusInfo = statusRes.data.status[0]
            if (!statusInfo.status) {
                console.error(`Warning: Found ${statusRes.data.count} jobs however statusInfo() is null. Stopping current status checks. This may indicate a network connectivity issue or a server-side problem.`)
                setJobStatusPollingActive(false)
                // clear multi person detail
                clearMultiPersonDetail()
            }
            else if (statusInfo.status === "FAILURE") {
                ///--- Check for known backend errors and display custom message
                if (!checkForKnownErrors(statusInfo)) {
                    DISPATCH({
                        currWorkflowStep: Enums.uiStates.initial,
                        currFTEStep: Enums.FTESteps.begin,
                        animJobId: 0,
                        animationJobProgress: 0,
                        isJobInProgress: false,
                        videoStorageUrl: null,
                        videoFileName: null
                    })
                    // else push a generic job failure error since an unknown / un-registered error
                    setErrorDialogInfo(Enums.eCodes.OtherError, "Job Failed", true)
                    handleHttpError("There was an error trying to create animations from the video provided, if the problem continues please contact DeepMotion Support.", "Job Failed", true)
                    console.log('Animation job has failed!')
                }
                setJobStatusPollingActive(false)
                // clear multi person detail
                clearMultiPersonDetail()
            }
            else if (statusInfo.status === "RETRY") {
                ///--- Check for known backend errors and display custom message
                if (!checkForKnownErrors(statusInfo)) {
                    // else push a generic job failure error since an unknown / un-registered error
                    DISPATCH({
                        currWorkflowStep: Enums.uiStates.initial,
                        isJobInProgress: false,
                        animationJobProgress: 0
                    })
                    setErrorDialogInfo(Enums.eCodes.OtherError, "Job Failed", true)
                    handleHttpError("There was an error trying to create animations from the video provided, if the problem continues please contact DeepMotion Support.", "Job Failed", true)
                    console.log('Animation job has failed!')
                }
                setJobStatusPollingActive(false)
                // clear multi person detail
                clearMultiPersonDetail()
            }
            else if (statusInfo.status === "SUCCESS") {
                console.log('Animation job has successfully completed.')
                let newJobsData = null
                let newAccountData = null
                let data = await getLatestPlanMinutes()
                newAccountData = data
                let res = await getJobsDataForAccount(true)
                let accountAndJobData = parseAccountJobsData(res)
                newJobsData = accountAndJobData.jobsData
                const jobInfo = newJobsData.find(item => item.rid === STATE.animJobId)
                res = await getLinksForJob(STATE.animJobId, true)
                const parsedLinks = await parseJobLinksData(res, STATE.animJobId)
                const animLinks = parsedLinks.animJobLinks
                const dLinks = parseModelDownloadLinks(animLinks)

                // clear multi person detail
                clearMultiPersonDetail()

                // once below async call complete a custom useEffect() hook
                // will re-route user to the Previewer
                DISPATCH({
                    // Credits
                    animLabelingFreeCredits: jobInfo.labelingFreeCredits,
                    animMaxVideoLabelingScore: jobInfo.maxVideoLabelingScore,
                    animCorrectionFreeCredits: jobInfo.correctionFreeCredits,
                    animMaxAnimationQualityScore: jobInfo.maxAnimationQualityScore,
                    animVideoDesc: jobInfo.videoDesc,
                    animSourceJobId: jobInfo.sourceJobId,
                    // set info needed to open previewer for this job
                    animJobLinks: animLinks,
                    currDownloadLinks: dLinks,
                    currWorkflowStep: Enums.uiStates.initial,
                    currFTEStep: Enums.FTESteps.begin,
                    // clear input video data
                    inputVideoData: {
                        selectedFile: null,
                        fileName: null,
                        fileLength: null,
                        fileSize: null,
                        videoRes: null,
                        fps: null,
                        codec: null
                    },
                    jobsData: newJobsData,
                    isJobInProgress: false,
                    displayPreview: true,
                    videoStorageUrl: null,
                    videoFileName: null,
                    animationJobProgress: 0,
                    animationJobPositionInQueue: undefined,
                    progressMsg: "Initializing process...",
                    animJobSettings: {...Enums.JOB_SETTINGS_TEMPLATE},
                    animJobSettingsSnapshots: JSON.stringify(Enums.JOB_SETTINGS_TEMPLATE),
                    rerunSettings: {...Enums.JOB_SETTINGS_TEMPLATE},
                    libraryInitialized: false,
                    anim3dInitialized: false,
                    ...newAccountData
                })
                setJobStatusPollingActive(false)
            } else if (statusInfo.status === "PROGRESS") {
                const step = statusInfo["details"]["step"]
                const total = statusInfo["details"]["total"]
                const positionInQueue = statusInfo["positionInQueue"]
                if (step === 0) {
                    DISPATCH({progressMsg: "Starting job, please wait..."})
                } else {
                    DISPATCH({
                        progressMsg: STATE.animJobSettings.jobType === Enums.jobTypes.staticPose ? "Generating 3D Pose" : "Generating Animation"
                    })
                }
                calculateProgress(positionInQueue, total, step)
                if (checkStatusTimer.current) {
                    clearTimeout(checkStatusTimer.current)
                    checkStatusTimer.current = null
                }
                checkStatusTimer.current = setTimeout(checkStatusTimerCallback, 5000)
            }
        }

        // TODO: REVIEW "QUEUED JOB" LOGIC BELOW - verify with Topu / backend team
        // the logic we want to trigger Queued jobs UI and if below is correct as
        // seems to be causing some issues when navigating with a valid job in progress
        // ------------------------------
        // Else data list count is < 1...
        else {
            // if isJobQueuedOrFailed == true it means at least one progress result
            // was returned from /emojis/status endpoint and indicates current
            // job is queued (pending) status...
            // if( isJobQueuedOrFailed ) {
            //   console.log(`Queued job found, updating UI...`)
            //   // if status = PROGRESS but count is < 1 then job is either queued
            //   // ie pending status or celery backend worker might have failed
            //   if( STATE.currWorkflowStep !== Enums.uiStates.jobQueued ) {
            //     DISPATCH({currWorkflowStep: Enums.uiStates.jobQueued})
            //   }
            // }
            setJobStatusPollingActive(false)
            clearMultiPersonDetail()
        }
    }

  async function clearMultiPersonDetail() {
    if (multiPersonSelection && multiPersonRid) await stopInProgressJob(multiPersonRid)
    setMultiPersonSelection(false)
    setMultiPersonRid('')
    setMultiPersonIsAlreadyCreated(false)
    setMultiPersonCharacter([])
    // clear motion labeling
    setMotionLabelingText('')
  }

    /////////////////////////////////////////////////////////////////////
    // this function is for errors during Job Processing
    function checkForKnownErrors(statusInfo) {
        if (statusInfo?.details?.exc_message) {
            const errorCode = statusInfo.details.exc_message[0]
            const error = getErrorByCode(errorCode)
            if (error) {
                DISPATCH({...resetJobObject, ...{confirmDialogId: errorCode}})
            } else {
                DISPATCH({...resetJobObject, ...{confirmDialogId: jobErrors.UnknownError.code}})
            }
            return true
        }
        return false
    }

    /////////////////////////////////////////////////////////////////////
    // Updates current download model and links state vars
    //
    // @param newModel : if null or false we default to model1
    /////////////////////////////////////////////////////////////////////
    function setCurrDownloadModel(newModel) {
        const selectedDownloadModel = !newModel ? Enums.characterModels['1'].fileName : newModel

        // DISPATCH({currDownloadModel: selectedDownloadModel})
        setCurrentModelLinks(selectedDownloadModel)
    }

    function parseModelDownloadLinks(jobLinksData: AnimJobLinks) {
        let dLinks: any = {}
        // async call to get links is made upon component mount, so here we
        // make sure the links are not null before assigning download links
        if (!jobLinksData) {
            console.error(`Warning: Invalid job links data passed to parsing function.`)
        }
        let found = false
        for (const model of jobLinksData.downloadLinks.models) {
            // dynamically update the download links based on the currently selected download model
            // for standard model jobs match the expected model name against current state var. For
            // custom model jobs the model.name will be the modelId which is a GUID
            if (model.name.includes('bvh-framesMap')
                || model.name.includes('framesIssueStatus')
                || model.name.includes('stderr')
                || model.name.includes('stdout')
                || model.name.includes('inter')
                || model.name.includes('pose_estimation_result')) {
                continue
            }
            if ((!jobLinksData.downloadLinks.customMode && model.name === STATE.currDownloadModel)
                || (!jobLinksData.downloadLinks.customMode && jobLinksData.downloadLinks.faceTrackingMode)
                || jobLinksData.downloadLinks.customMode
                || model.name === "landmarks") { // dmpe files part of landmarks node
                for (const link of model.links) {
                    if (link.type === Enums.animFileType.fbx) {
                        dLinks.fbxLink = link.link
                    }
                    if (link.type === Enums.animFileType.bvh) {
                        dLinks.bvhLink = link.link
                    }
                    if (link.type === Enums.animFileType.gif) {
                        dLinks.gifLink = link.link
                    }
                    if (link.type === Enums.animFileType.jpg) {
                        dLinks.jpgLink = link.link
                    }
                    if (link.type === Enums.animFileType.png) {
                        dLinks.pngLink = link.link
                    }
                    if (link.type === Enums.animFileType.glb) {
                        dLinks.glbLink = link.link
                    }
                    if (link.type === Enums.animFileType.mp4) {
                        dLinks.mp4Link = link.link
                    }
                    if (link.type === Enums.animFileType.dmpe) {
                        dLinks.dmpeLink = link.link
                    }
                }
            }
        }
        return dLinks
    }

    /////////////////////////////////////////////////////////////////////
    // Retrieves/sets download links for currently selected download model
    /////////////////////////////////////////////////////////////////////
    function setCurrentModelLinks(newModel: DownloadLinkModel) {
        let dLinks: any = {}
        const currSelectedModel = newModel ? newModel : STATE.currDownloadModel
        // async call to get links is made upon component mount, so here we
        // make sure the links are not null before assigning download links
        if (STATE.animJobLinks) {
            let found = false
            for (const model of STATE.animJobLinks.downloadLinks.models) {
                // dynamically update the download links based on the currently selected download model
                // for standard model jobs match the expected model name against current state var. For
                // custom model jobs the model.name will be the modelId which is a GUID
                if (model.name.includes('bvh-framesMap')
                    || model.name.includes('framesIssueStatus')
                    || model.name.includes('stderr')
                    || model.name.includes('stdout')
                    || model.name.includes('inter')
                    || model.name.includes('pose_estimation_result')) {
                    continue
                }
                if ((!STATE.animJobLinks.downloadLinks.customMode && model.name === currSelectedModel)
                    || (!STATE.animJobLinks.downloadLinks.customMode && STATE.animJobLinks.downloadLinks.faceTrackingMode)
                    || STATE.animJobLinks.downloadLinks.customMode
                    || model.name === "landmarks") { // dmpe files part of landmarks node
                    for (const link of model.links) {
                        if (link.type === Enums.animFileType.fbx) {
                            dLinks.fbxLink = link.link
                        }
                        if (link.type === Enums.animFileType.bvh) {
                            dLinks.bvhLink = link.link
                        }
                        if (link.type === Enums.animFileType.gif) {
                            dLinks.gifLink = link.link
                        }
                        if (link.type === Enums.animFileType.jpg) {
                            dLinks.jpgLink = link.link
                        }
                        if (link.type === Enums.animFileType.png) {
                            dLinks.pngLink = link.link
                        }
                        if (link.type === Enums.animFileType.glb) {
                            dLinks.glbLink = link.link
                        }
                        if (link.type === Enums.animFileType.mp4) {
                            dLinks.mp4Link = link.link
                        }
                        if (link.type === Enums.animFileType.dmpe) {
                            dLinks.dmpeLink = link.link
                        }
                    }
                }
            }
            DISPATCH({currDownloadLinks: dLinks})
        }
    }

    //////////////////////////////////////////////////////////////////////
    function buildModelThumbnailsSection() {
        const generateModelThumbnails = (charactersList, numCharacters, thumbnailsList) => {
            for (let i = 0; i < numCharacters; i++) {
                let image = (charactersList[i].thumbImg instanceof Blob) ? URL.createObjectURL(charactersList[i].thumbImg) : charactersList[i].thumb ? charactersList[i].thumb : imgCustom

                thumbnailsList.push(
                    <div className="has-text-centered model-thumbnails-list mb-2 m-0 is-flex is-align-items-center is-justify-content-center" key={i.toString() + charactersList[i].name}>
                        <figure className="image br-4 selection-card AccountCharacters" onClick={() => handleModelSelectionClick(charactersList[i])} id={STATE.animJobSettings.customModelInfo.id === charactersList[i].id ? "anim-fadein" : ""}>
                            <div className="br-4">
                                <img src={image}
                                     className={STATE.animJobSettings.customModelInfo.id === charactersList[i].id ? " p-1 br-4 animated-border bShadow has-background-light" : " br-4 dm-brand-border-md bShadow has-background-light"}
                                     alt={`${charactersList[i].name}`}/>
                            </div>
                        </figure>
                    </div>
                )
            }
        };

        let modelThumbnailsList = []

        let platformModels = STATE.accountTotals.platformCharactersList
        let newPlatformModels = STATE.accountTotals.platformCharactersList
        let numPlatformModels = STATE.accountTotals.platformCharactersList.length

        if (STATE.animJobSettings.rootJointAtOrigin) {
            newPlatformModels = []
            platformModels.forEach(item => {
                if (item.name === MODEL_NAME_MALE_UE || item.name === MODEL_NAME_FEMALE_WITH_FACE_UE) {
                    newPlatformModels.push(item)
                }
            })
        }

        numPlatformModels = newPlatformModels.length
        generateModelThumbnails(newPlatformModels, numPlatformModels, modelThumbnailsList)

        let numModels: number = STATE.accountTotals.charactersList.length

        // once list is creaetd, parse image list for ratios
        if (!STATE.animJobSettings.rootJointAtOrigin) generateModelThumbnails(STATE.accountTotals.charactersList, numModels, modelThumbnailsList)

        return (
            // make character thumbnails section vertically scrollable...
      <div className="columns m-2 is-flex-wrap-wrap">
        {modelThumbnailsList && modelThumbnailsList.map(item => {
          return (item)
        })}
            </div>
        )
    }

    /////////////////////////////////////////////////////////////////////
    // Handler for model selection double clicking
    //
    /////////////////////////////////////////////////////////////////////
    function handleModelSelectionClick(modelInfo) {
        // handle second click on model thumbnail
        if (
            modelInfo.id === STATE.animJobSettings.customModelInfo.id &&
            modelInfo.name === STATE.animJobSettings.customModelInfo.name &&
            modelInfo.size === STATE.animJobSettings.customModelInfo.size &&
            modelInfo.modelUrl === STATE.animJobSettings.customModelInfo.modelUrl &&
            modelInfo.thumbUrl === STATE.animJobSettings.customModelInfo.thumbUrl &&
            modelInfo.thumbImg === STATE.animJobSettings.customModelInfo.thumbImg
        ) {
            if (STATE.accountTotals.charactersList.length) {
                updateModelInfoandNavigateUser(false, true)
            } else {
                updateModelInfoandNavigateUser(false, false)
            }
        }
        // set selected model as active
        else {
            DISPATCH({animJobSettings: {...STATE.animJobSettings, ...{'customModelInfo': modelInfo}}})
        }
    }

    /////////////////////////////////////////////////////////////////////
    // Handler for Downloading job directly from the library page
    //
    // @param rid : request ID of the job to download assets for
    /////////////////////////////////////////////////////////////////////
    async function handleDownloadJobClick(rid) {
        const linksRes = await getLinksForJob(rid, true)
        const parsedLinks = await parseJobLinksData(linksRes, rid)
        const animLinks = parsedLinks.animJobLinks
        const dLinks = parseModelDownloadLinks(animLinks)
        const dialogType = animLinks.downloadLinks.detectionMode === 1 ?
            'DIALOG_MultiPersonAnimDownloadCustomModel' :
            doesJobUseCustomModel(rid) ?
                'DIALOG_AnimDownloadCustomModel' :
                'DIALOG_AnimDownloadDefaultModel'
        DISPATCH({
            dispatchType: dialogType,
            payload: {
                animJobId: rid,
                animJobLinks: animLinks,
                currDownloadLinks: dLinks
            }
        })
    }

    /////////////////////////////////////////////////////////////////////
    // Returns true if a custom model was used for the animation job
    /////////////////////////////////////////////////////////////////////
    function doesJobUseCustomModel(jobId) {
        if (!STATE.jobsData) {
            return false
        }
        // default model jobs have customModel property = "standard", otherwise
        // for custom model jobs they will have the modelId as the value
        for (let i = 0; i < STATE.jobsData.length; i++) {
            if (STATE.jobsData[i].rid === jobId) {
                if (STATE.jobsData[i].customModel !== "standard") {
                    return true
                }
            }
        }
        return false
    }

    /////////////////////////////////////////////////////////////////////
    // Calculates the current animation job progress based on step and
    // total counts returned from backend
    /////////////////////////////////////////////////////////////////////
    function calculateProgress(positionInQueue, total, step, lastProgress = 0) {
        if (total <= 0) total = 1
        if (step <= 0) step = 0
        if (step > total) step = total

        let progress = Math.floor(step * 100 / total)
        let maxStepProgress = Math.floor((step + 1) * 100 / total)
        if (lastProgress >= progress && lastProgress + 1 >= maxStepProgress) {
            progress = maxStepProgress
        } else if (lastProgress >= progress) {
            progress = lastProgress + 1
        }
        if (progress > STATE.animationJobProgress) {
            // don't allow progress bar to be updated with a lower value than current
            DISPATCH({animationJobPositionInQueue: positionInQueue, animationJobProgress: progress})
        } else {
            DISPATCH({animationJobPositionInQueue: positionInQueue})
        }
    }

    /////////////////////////////////////////////////////////////////////
    // Calculates used minutes for the current billing cycle by
    // scanning through jobs data and aggregating jobs created
    // between billing cycle start and end dates
    //
    // --> Returns job time in seconds (Promise)
    /////////////////////////////////////////////////////////////////////
    function calculateUsedTimeForCurrCycle() {
        try {
            if (!STATE.jobsData || STATE.jobsData.length === 0) {
                return 0
            } else {
                let usedTime = 0.0
                // loop through jobs data...
                STATE.jobsData.forEach((job: { dateRaw: number; lengthRaw: number }) => {
                    if (Math.floor(job.dateRaw / 1000) >= STATE.subscriptionInfo.current_period_start && job.dateRaw / 1000 < STATE.subscriptionInfo.current_period_end) {
                        usedTime += job.lengthRaw
                    }
                })
                return usedTime
            }
        } catch (error) {
            console.error(`Error encountered while calculating used cycle time: ${error}`)
            throw error
        }
    }

    ////////////////////////////////////////////////////////////////////
    // parse jobs data returned from API and update app state
    ////////////////////////////////////////////////////////////////////
    function parseAccountJobsData(res) {
        // sort response data jobs list from oldest to newest to help
        // with the rerun removal algorithm further below...
        const list = res.data.list.sort((a, b) => a.mtime - b.mtime)
        let logs = []
        let accTotals = STATE.accountTotals
        // reset time and size total before re-calculating
        accTotals.time = 0
        accTotals.size = 0

        for (let i = 0; i < list.length; i++) {
            const d = new Date(list[i].mtime)
            const date = [d.getMonth() + 1, d.getDate(), d.getFullYear()].join('/')
            // sum the animation times and sizes
            accTotals.time += list[i].fileDuration
            accTotals.size += list[i].fileSize
            //if not getting fileduration from backend, display nothing at thing time
            const fLength = list[i].fileDuration ? list[i].fileDuration : ' '
            const fSize = Enums.formatSizeUnits(list[i].fileSize)
            let settings: Partial<JobSettings> = {}
            // jobs created before corresponding backend changes (for the environment in question)
            // will not have previous run parameters/settings available
            if (!(Object.keys(list[i].parameters).length === 0 && list[i].parameters.constructor === Object)) {
                settings.inputVideoData = {
                    fileName: list[i].fileName,
                    fileLength: fLength,
                    // TODO: No resolution or FPS being returned for jobs currently
                    videoRes: null,
                };
                settings.formats = list[i].parameters?.['formats'] || ''
                if ((list[i].parameters?.['createDMPE'] || 'false').toLowerCase() === 'false') {
                    // append dmpe format if enabled
                    settings.formats = `${settings.formats},dmpe`
                }
                // Convert to an object since buildJobSummaryInfoTables()
                // expects an object {val:true} of format extensions...
                settings.formats = settings.formats.split(",").reduce((obj, format) => {
                    obj[format] = true;
                    return obj;
                }, {})
                settings.trackFace = parseInt(list[i].parameters?.['trackFace'] || '0')
                settings.trackHand = parseInt(list[i].parameters?.['trackHand'] || '0')
                settings.physicsSim = !list[i].parameters?.dis?.includes("s") || false
                settings.footLockMode = list[i].parameters?.['footLockingMode'] || ''
                settings.fallbackPose = list[i].parameters?.['fallbackPose'] || Enums.fallbackPose.i_pose
                settings.videoSpeedMultiplier = list[i].parameters?.['videoSpeedMultiplier'] || '1'
                settings.poseFilteringStrength = list[i].parameters?.['poseFilteringStrength'] || '0'
                settings.iris_turning_agility = list[i].parameters?.['iris_turning_agility'] || '0'
                settings.keyframeReducer = 'vector.fbx_key_reduce' in list[i].parameters ? true : false
                settings.camMode = (!list[i].parameters['render.camMode'] || list[i].parameters['render.camMode'] === "-1" || list[i].parameters['render.camMode'] === -1)
                    ? 'N/A' :
                    Enums.cameraMotionSettings[parseInt(list[i].parameters['render.camMode'])]
                settings.camHorizontalAngle = parseFloat(list[i].parameters?.['render.camHorizontalAngle'] || '0.0')
                // ensure rootJointAtOrigin value remains a boolean
                let rootAtOrigin = list[i].parameters?.['rootAtOrigin'] || ''
                settings.rootJointAtOrigin = (rootAtOrigin === 'true' || rootAtOrigin === '1')
                let upperBodyOnly = list[i].parameters?.['upperBodyOnly'] || ''
                settings.upperBodyOnly = (upperBodyOnly === 'true' || upperBodyOnly === '1')

                settings.sbs = (list[i].parameters?.['render.sbs'] || '') === '1'
                settings.shadow = (list[i].parameters?.['render.shadow'] || '') === '1'
                // includeAudio property is only present if audio option disabled for job
                settings.includeAudio = ((list[i].parameters?.['render.includeAudio'] || '0') === '0') ? false : true


                // Trim & Crop
                const trim = list[i].parameters?.['trim']
                const crop = list[i].parameters?.['crop']
                if (trim) settings.trim = trim
                if (crop) settings.crop = crop

                // backdrop
                const backdrop = list[i].parameters?.['render.backdrop']

                settings.environment = Enums.BackdropEnv[0]

                if (!!backdrop) {
                    settings.backdrop = backdrop
                    const isEnvironment = Enums.BackdropEnv.findIndex(environment => environment === backdrop)
                    if (isEnvironment !== -1) {
                        settings.shadow = false

                        settings.backdrop = Enums.Backdrop.environments
                        settings.environment = backdrop
                    }

                    if (backdrop === Enums.Backdrop.studio || backdrop === Enums.Backdrop.solid) {
                        const bgColor = list[i].parameters?.['render.bgColor'] || ''
                        if (!!bgColor) {
                            settings.bgColor = bgColor.split(',')
                        } else {
                            settings.bgColor = Enums.defaultGSColor.toString().split(',')
                        }
                    } else if (backdrop === 'transparent') {
                        settings.backdrop = Enums.Backdrop.transparent
                    }
                } else {
                    settings.backdrop = settings.sbs ? Enums.Backdrop.defaultWithOriginal : Enums.Backdrop.default
                }

            }
            // push each job's data into logs array
            logs.push({
                name: list[i].fileName,
                length: fLength,
                lengthRaw: list[i].fileDuration,
                size: Enums.formatSizeUnits(list[i].fileSize),
                sizeRaw: list[i].fileSize,
                date: date,
                dateRaw: list[i].mtime,
                rid: list[i].rid,
                sourceJobId: list[i].parameters?.['sourceJobId'] || '',
                customModel: list[i].modelId,
                customMultiPersonModel: list[i].models,
                settings: settings,
                jobType: list[i].jobType,
                thumb: list[i].thumb,
                faceDataExist: list[i].faceDataExist,
                handDataExist: list[i].handDataExist,
                correctionFreeCredits: Math.trunc(list[i].correctionFreeCredits),
                labelingFreeCredits: Math.trunc(list[i].labelingFreeCredits),
                maxAnimationQualityScore: (list[i].maxAnimationQualityScore * 100).toFixed(2),
                maxVideoLabelingScore: (list[i].maxVideoLabelingScore * 100).toFixed(2),
                videoDesc: list[i].videoDesc
            })
        }//end for

        const newNumPages = Math.ceil(logs.length / STATE.rowsPerPage)
        let newCurrPage = STATE.currPage
        if (STATE.currPage > newNumPages && newNumPages !== 0) {
            // set to last page if beyond current index after updating jobs data
            newCurrPage = newNumPages
        }
        return {
            accountTotals: accTotals,
            currPage: newCurrPage,
            jobsData: logs,
            numPages: newNumPages
        }
    }

    /////////////////////////////////////////////////////////////////////
    function updateBillingCycleData(data) {
        let subData = data.subscriptionInfo
        subData.featurePacks = data.userPackFeature
        let totalCurrCycleMins = data.userMaxUpgradePackMinute.staticData.minutes

        const minuteBalanceCorrection = Math.floor(data.user.minute_balance_correction * 60)
        const minuteBalanceLabeling = Math.floor(data.user.minute_balance_labeling * 60)

        const time = calculateUsedTimeForCurrCycle()
        let usedRounded = Math.ceil(time)
        let remainingRounded = Math.floor(STATE.accountTotals.remainingTimeInSeconds)
        let usedMonthlyTime = (!Enums.secondsToTime(usedRounded) || Enums.secondsToTime(usedRounded) === "") ? "00:00:00" : Enums.secondsToTime(usedRounded)
        let remainingMonthlyTime = (!Enums.secondsToTime(remainingRounded) || Enums.secondsToTime(remainingRounded) === "") ? "00:00:00" : Enums.secondsToTime(remainingRounded)
        let usagePercent = Math.round(((totalCurrCycleMins * 60) - remainingRounded) / (totalCurrCycleMins * 60) * 100)
        if (usagePercent < 0) {
            usagePercent = 0
        }
        // convert billing cycle dates from unix timestamp to date strings

        let startDate = new Date(data.user.plan_expiary_date)
        startDate.setMonth(startDate.getMonth() - 1)  // subtract one month
        const currCycleStartDate = Enums.dateConverter(startDate.getTime() / 1000, true)
        let currCycleEndDate = null
        if (data.subscriptionInfo.isEnterpriseUser) {
            // TBD: Enterprise animation packs have 1 year default expiration time. However multiple packs can be stacked
            // on top of a normal subscription plan. Therefore there is not always a single expiration date. Just punt
            // the underlying Freemium plan's expiration date for 12 months as a place holder end date.
            startDate.setMonth(startDate.getMonth() + 12)
            currCycleEndDate = Enums.dateConverter(startDate.getTime() / 1000, true)
        } else {
            currCycleEndDate = Enums.dateConverter(data.user.plan_expiary_date / 1000, true)
        }
        const newBillingCycleData = {
            usedRounded: usedRounded,
            remainingRounded: remainingRounded,
            usedMonthlyTime: usedMonthlyTime,
            remainingMonthlyTime: remainingMonthlyTime,
            usagePercent: usagePercent,
            currCycleStartDate: currCycleStartDate,
            currCycleEndDate: currCycleEndDate,
            totalCurrCycleMins: totalCurrCycleMins,
            minuteBalanceCorrection: minuteBalanceCorrection,
            minuteBalanceLabeling: minuteBalanceLabeling
        }

        return {
            currentBillCycleInfo: newBillingCycleData,
            subscriptionInfo: subData,
            accountTotals: data.accountTotals
        }
    }

    /////////////////////////////////////////////////////////////////////
    async function parseAccountModelsData(res: any) {
        try {
            let data = STATE.accountTotals
            data.charactersList = res.data.list.filter(m => m.platform === 'custom') // up to their subscription limit
            data.platformCharactersList = res.data.list.filter(m => m.platform !== 'custom')
            if (data.charactersList.length > STATE.accountTotals.characterLimit) {
                // cap maximum # of characters, starting from most recent...
                data.charactersList = data.charactersList.slice(data.charactersList.length - STATE.accountTotals.characterLimit, data.charactersList.length)
                if (!STATE.accountTotals.characterLimit || STATE.accountTotals.characterLimit === 0) {
                    console.log(`Warning: Invalid account character limit encountered in getAccountCustomCharacters().`)
                }
            }

            const accountTotalsWithThumbnails = await downloadModelThumbnails(data)
            return accountTotalsWithThumbnails
        } catch (error) {
            console.error(`Error encountered while parsing account models data.\n ${error}`)
        }
    }

    /////////////////////////////////////////////////////////////////////
    // Get info on a specific animation job based on job ID:
    /////////////////////////////////////////////////////////////////////
    function getJobDetailsById(jobId) {
        for (let i = 0; i < STATE.jobsData.length; i++) {
            if (STATE.jobsData[i].rid === jobId) {
                return STATE.jobsData[i]
            }
        }
        return null
    }

    /////////////////////////////////////////////////////////////////////
    // Returns true if jobId is an existing (ie. already completed) job,
    // otherwise returs false
    /////////////////////////////////////////////////////////////////////
    function isExistingJob(jobId) {
        let found = STATE.jobsData.find(job => job.rid === jobId)
        return (found === undefined ? false : true)
    }

    /////////////////////////////////////////////////////////////////////
    // Retrieve model data from app state
    // @param modelId : id of the model to retrieve
    /////////////////////////////////////////////////////////////////////
    function getModelDataById(modelId) {
        if (modelId === Enums.robloxModelId) {
            return {id: Enums.robloxModelId, name: 'Roblox R15'}
        } else {
            const found = (STATE.accountTotals.charactersList && STATE.accountTotals.charactersList.find(model => model.id === modelId)) || (STATE.accountTotals.platformCharactersList && STATE.accountTotals.platformCharactersList.find(model => model.id === modelId))
            return (found === undefined ? null : found)
        }
    }

    /////////////////////////////////////////////////////////////////////
    // Check if platform model
    // @param modelId : id of the model to retrieve
    /////////////////////////////////////////////////////////////////////
    function isPlatformModelById(modelId) {
        const found = STATE.accountTotals.platformCharactersList && STATE.accountTotals.platformCharactersList.find(model => model.id === modelId)
        return found !== undefined
    }

    /////////////////////////////////////////////////////////////////////
    // Previews an animation job using the Unity online preivewer
    //
    // @param rid : the requestID (ie job id) for the given job
    /////////////////////////////////////////////////////////////////////
    async function openAnimationPreviewer(rid) {
        setLOADING({...LOADING, ...{show: true}})
        const linkRes = await getLinksForJob(rid, true).catch((error) => {
            console.error(`Error while retrieving animation job links! ${error}\n${JSON.stringify(error, null, 4)}`)
            setErrorDialogInfo(true, Enums.eCodes.OtherError, "Problem Getting Job Links")
            return null
        })
        if (linkRes == null) {
            return
        }
        const jobInfo = getJobDetailsById(rid)
        const parsedLinks = await parseJobLinksData(linkRes, rid)
        const animLinks = parsedLinks.animJobLinks
        const dLinks = parseModelDownloadLinks(animLinks)

        DISPATCH({
            animJobId: rid,
            animLabelingFreeCredits: jobInfo.labelingFreeCredits,
            animMaxVideoLabelingScore: jobInfo.maxVideoLabelingScore,
            animCorrectionFreeCredits: jobInfo.correctionFreeCredits,
            animMaxAnimationQualityScore: jobInfo.maxAnimationQualityScore,
            animVideoDesc: jobInfo.videoDesc,
            animSourceJobId: jobInfo.sourceJobId,
            animJobLinks: animLinks,
            currDownloadLinks: dLinks,
            displayPreview: true
        })
    }

    function getPeSaveLink(urls) {
        const peSaveURL = urls.find(url => url.name == "pose_estimation_result");
        return peSaveURL ? peSaveURL.files[0].pesave : "";
    }

    function normalizeVideoCropCoord(videocrop) {
        let left = Number(((1 * videocrop.x) / 100).toFixed(3))
        let top = Number(((1 * videocrop.y) / 100).toFixed(3))
        let width = Number(((1 * videocrop.width) / 100).toFixed(3))
        let height = Number(((1 * videocrop.height) / 100).toFixed(3))
        return {left, top, right: Number(((width + left)).toFixed(3)), bottom: Number(((height + top)).toFixed(3))}
    }

    /////////////////////////////////////////////////////////////////////
    function getFileLinkForDownload(urls, fileName, fmt) {
        let ret = ""
        urls.forEach(items => {
            if (items.name == fileName) {
                items.files.forEach(item => {
                    Object.entries(item).forEach(([key, value]) => {
                        if (key == fmt) {
                            ret = String(value)
                        }
                    })
                })
            }
        })
        return ret
    }

    async function parseJobLinksData(res, rid) {
        const data = res.data
        let detectionMode = data.links[0].detectionMode

        let predefinedCharacterNamesInOrder = [Enums.characterModels['1'].fileName, Enums.characterModels['2'].fileName, Enums.characterModels['3'].fileName, Enums.characterModels['4'].fileName, Enums.characterModels['5'].fileName, Enums.characterModels['6'].fileName]
        let previewLinks: any = {}
        let downloadLinks: any = {}
        let multiPersonDownloadLinks = []

        previewLinks.customMode = downloadLinks.customMode = data.links[0].modelId ? (data.links[0].modelId !== 'standard') : doesJobUseCustomModel(rid)
        previewLinks.faceTrackingMode = downloadLinks.faceTrackingMode = data.links[0].faceDataType ? (data.links[0].faceDataType > 0) : false

        // Build Previewer links
        previewLinks.video = data.links[0].inputWebm
        previewLinks.videoThumbnail = data.links[0].inputThumb
        previewLinks.hasAudio = data.links[0].hasAudio //true or false
        previewLinks.hideFloor = data.links[0].parameters.footLockingMode == Enums.footLockMode.never;
        previewLinks.useLogoOnDefaultCharacters = false

        previewLinks.characters = []

        let peSaveData = await getPeSaveUrls(rid, true)
        let peSaveUrls = peSaveData.data

        // Multi Persons
        if (detectionMode == 1) {
            res.data.links[0].models.forEach(async (model) => {
                let character: any = {}
                character.id = Number(model.trackingId)
                let trackingIdStr = (character.id).toLocaleString('en-US', {
                    minimumIntegerDigits: 3,
                    useGrouping: false
                })
                let characterThumbnail = getFileLinkForDownload(data.links[0].urls, "thumbnail_character_" + trackingIdStr, "png")
                character.characterThumbnail = characterThumbnail
                character.modelThumbnail = model.modelThumb ? model.modelThumb : ""
                character.faceTrackingMode = model.faceDataType > 0 ? true : false

                let modelId = model.modelId + "_" + trackingIdStr
                character.humanoidMap = getFileLinkForDownload(data.links[0].urls, modelId, "humanoidmap")
                character.character = getFileLinkForDownload(data.links[0].urls, modelId, "glb")
                character.modelId = model.modelId
                character.peSave = getFileLinkForDownload(data.links[0].urls, "pose_estimation_result_" + trackingIdStr, "pesave")
                character.peSaveUpload = null
                character.peSaveModified = ""
                if (peSaveUrls) {
                    peSaveUrls.forEach((peSaveObj) => {
                        if (trackingIdStr == peSaveObj.trackingId) {
                            if (peSaveObj.modifiedVersionReadUrl) {
                                character.peSaveModified = peSaveObj.modifiedVersionReadUrl
                            }
                            character.peSaveUpload = peSaveObj.modifiedVersionWriteUrl
                        }
                    })
                }
                previewLinks.characters.push(character)
                // Multi Person
                let MultiPersonDownloadLinksCharacter = {
                    sort: Number(trackingIdStr),
                    model: model,
                    modelName: modelId,
                    modelTrackingId: trackingIdStr,
                    characterThumbnail: characterThumbnail,
                    downloadLink: []
                }
                multiPersonDownloadLinks.push(MultiPersonDownloadLinksCharacter)
            })
        } else {  // Single Person
            let character: any = {}
            character.id = 0
            character.characterThumbnail = ""
            character.modelThumbnail = ""
            character.faceTrackingMode = previewLinks.faceTrackingMode
            character.peSave = getFileLinkForDownload(data.links[0].urls, "pose_estimation_result", "pesave")
            character.peSaveUpload = null
            character.peSaveModified = ""
            if (peSaveUrls) {
                //send user modified peSave if exist instead of generated peSave to previewer
                if (peSaveUrls.modifiedVersionReadUrl) {
                    character.peSaveModified = peSaveUrls.modifiedVersionReadUrl
                }
                character.peSaveUpload = peSaveUrls.modifiedVersionWriteUrl
            }

            if (!previewLinks.customMode && !previewLinks.faceTrackingMode) {
                character.character = ['female', 'female slim', 'male', 'male fat', 'male young', 'child']
                character.bvh = []
                predefinedCharacterNamesInOrder.forEach(async (char) => {
                    character.bvh.push(getFileLinkForDownload(data.links[0].urls, char, "bvh"))
                })
                character.modelId = predefinedCharacterNamesInOrder
                previewLinks.useLogoOnDefaultCharacters = true
            } else {
                let modelId = data.links[0].modelId
                if (!previewLinks.customMode && previewLinks.faceTrackingMode)
                    modelId = "female-normal-dmface"

                character.modelId = modelId
                character.humanoidMap = await getFileLinkForDownload(data.links[0].urls, modelId, "humanoidmap")
                character.character = await getFileLinkForDownload(data.links[0].urls, modelId, "glb")
            }
            previewLinks.characters.push(character)
        }

        // build download links
        downloadLinks.fileName = data.links[0].name
        downloadLinks.detectionMode = detectionMode
        downloadLinks.modelId = data.links[0].modelId
        // downloadLinks.modelsData = bubbleSortForMultiPersonModelsData(res.data.links[0].models)
        downloadLinks.modelsData = res.data.links[0].models

        // Single Person
        if (detectionMode === 0) {
            downloadLinks.models = []
            data.links[0].urls.forEach(items => {
                const temp = []
                items.files.forEach(item => {
                    Object.entries(item).forEach(([key, value]) => {
                        temp.push({type: key, link: value});
                    })
                })
                downloadLinks.models.push({name: items.name, links: temp})
            })
        } else {
            //ToDo: May be Replace/Modify below for MultiPerson download. currently that is same as single person
            downloadLinks.models = []
            data.links[0].urls.forEach(items => {
                const temp = []
                items.files.forEach(item => {
                    Object.entries(item).forEach(([key, value]) => {
                        if(Enums.animFileType.hasOwnProperty(key)) {
                            temp.push({type: key, link: value});
                        }
                    })
                })

                const characterDownloadLink = multiPersonDownloadLinks.findIndex(character => character.modelName === items.name)
                if (characterDownloadLink !== -1) {
                    const tempSort = temp.sort((a, b) => {
                        if (a.type < b.type) {
                            return -1;
                        }
                        if (a.type > b.type) {
                            return 1;
                        }
                            return 0;
                    })
                    multiPersonDownloadLinks[characterDownloadLink].downloadLink = tempSort
                }
                downloadLinks.models.push({name: items.name, links: temp})
            })
        }

        // NOTE: for the previewer, previewLinks now contains the query parameter that the previewer can directly consume,
        // means in GetConfig() in previewer's index.html there is no further json string composing code needed
        // GetConfig() now simply needs to do this:
        /*  var src = window.location.href.split('?src=')[1]
        jsonStr = decodeURI(src)
        unityInstance.SendMessage("Previewer", "LoadConfig", jsonStr);*/
        // the motivation of simplifying the previewer's index.html as above is that
        // scattering the code to accomplish one function in different places makes maintenance difficult
        // construct the new links data
        multiPersonDownloadLinks = bubbleSort(multiPersonDownloadLinks)
        previewLinks.characters = bubbleSortForMultiPersonPreviewLinks(previewLinks.characters)
        let newLinks: AnimJobLinks = {
            previewLinks: previewLinks,
            downloadLinks: downloadLinks,
            multiPersonDownloadLinks: multiPersonDownloadLinks,
            multiPersonRid: data.links[0].rid
        }
        return {animJobLinks: newLinks}
    }

    /////////////////////////////////////////////////////////////////////
    // called once user initiaties a new animation job
    // @param params : object with key-value pairs of parameters
    /////////////////////////////////////////////////////////////////////
    async function initNewAnimationJob(params) {
        window.scrollTo(0, 0)
        // start processing job immediately if we already have storage link
        if (STATE.videoStorageUrl) {
            DISPATCH({
                isJobInProgress: true,
                currWorkflowStep: Enums.uiStates.jobInProgress,
                currFTEStep: Enums.FTESteps.addMotionClip,
                progressMsg: "Initializing process...",
                confirmDialogId: Enums.confirmDialog.none,
                animJobId: 0
            })
            await beginAnimationJob({
                processUrl: STATE.videoStorageUrl,
                retry: true
            })
        }
        // otherwise start a brand new job starting with upload
        else {
            const res: any = await uploadJobDataToBackend(
                STATE.inputVideoData.fileName,
                STATE.inputVideoData.selectedFile,
                true
            )
            console.log(`Done uploading to GCP...`)
            DISPATCH({
                currWorkflowStep: Enums.uiStates.jobInProgress,
                isJobInProgress: true,
                progressMsg: "Uploading data...",
                videoStorageUrl: res.videoStorageUrl,
                confirmDialogId: Enums.confirmDialog.none,
                animJobId: 0
            })
            await beginAnimationJob({processUrl: res.videoStorageUrl, retry: true})
        }
    }

    /////////////////////////////////////////////////////////////////////
    // Begins a new animation job
    /////////////////////////////////////////////////////////////////////
    async function beginAnimationJob(params) {
        let jobParams = []
        // skip createAnimJobSettingsObject, only when rerun triggered during Preview phase
        if (params.specialRerun == null) {
            jobParams = createAnimJobSettingsObject(params.rerun)
        }
        // form request data body for job request:
        let requestData
        let request = {
            processor: "video2anim",
            params: jobParams
        }
        // set rid or url param based on if rerun or regular job, respectively
        if (params.rerun) {
            requestData = {
                ...request,
                rid: params.rid
            }
        } else {
            if (multiPersonSelection) {
                requestData = {
                    ...request,
                    rid_mp_detection: multiPersonRid
                }
            } else {
                requestData = {
                    ...request,
                    url: params.processUrl
                }
            }
        }
        const res = await startNewAnimOrPoseJob(requestData, true)
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === Enums.eCodes.Forbidden) {
                        console.error(
                            `Access forbidden error encountered while starting animation job - \n${error}`
                        )
                        logoutUser()
                    }
                    if (error.response.status === Enums.eCodes.InternalServerError) {
                        setErrorDialogInfo(
                            true,
                            Enums.eCodes.InternalServerError,
                            Enums.customErrors[error.response.data.error],
                            error.response.data.message,
                            () => {
                                return
                            }
                        )
                    }
                } else {
                    handleHttpError(error, "Problem Starting New Job", true)
                }
                return null
            })
        if (res == null) return

        let newStateData: Partial<AppState> = {
            isJobInProgress: true,
            currWorkflowStep: Enums.uiStates.jobInProgress,
            progressMsg: "Initializing process...",
            confirmDialogId: null
        }
        if (res.data && res.data.rid && res.data.rid !== "") {
            newStateData.animJobId = res.data.rid
        }
        DISPATCH(newStateData)
        if (params.rerun) {
            history.push(`${Enums.routes.Anim3dCreate}?rerun=true`)
        }
    }

    /////////////////////////////////////////////////////////////////////
    // Deletes a custom model from the signed in user's account
    //
    // @param modelId : unique model id of the character to delete
    // @param clbFunc : callback function once done
    /////////////////////////////////////////////////////////////////////
    async function deleteCustomModel(modelId, clbFunc) {
        DISPATCH({confirmDialogId: null})  // close dialog if up
        let res = await removeCustomModelFromAccount(modelId)
            .catch((error) => {
                // handle error
                console.log('There was a problem removing the custom character.');
                if (error.response) {
                    if (error.response.status === Enums.eCodes.Unauthorized) {
                        logoutUser()
                    }
                    if (error.response.status === Enums.eCodes.Forbidden) {
                        logoutUser()
                    } else {
                        handleHttpError(error, "Could Not Delete Character")
                    }
                } else {
                    handleHttpError(error, "Could Not Delete Custom Character")
                }
                return null
            })
        if (res == null) {
            return
        }
        // once job is deleted we retrieve the list of custom characters again
        // to make sure state vars are in-sync
        res = await getAccountCustomCharacters(false)
        if (clbFunc) {
            return clbFunc()
        } else {
            return res
        }
    }

    /////////////////////////////////////////////////////////////////////
    // Delete a previous animation job
    //
    // @param rid : the requestID (ie job id) for the given job
    /////////////////////////////////////////////////////////////////////
    async function deleteAnimationJob(rid) {
        window.scrollTo(0, 0)
        DISPATCH({confirmDialogId: null})  // close dialog if up
        const res = await removeJobFromAccount(rid, true).catch((error) => {
            // handle error
            console.error(`There was a problem while attempting to delete the job ${rid} --> ${error}`);
            handleHttpError(error, "Could Not Delete Job")
            return null
        })
        if (res == null) {
            return
        }
        return DISPATCH({anim3dInitialized: false, libraryInitialized: false})
    }

    /////////////////////////////////////////////////////////////////////
    // onClick() handler for Deleting a job from the library page
    //
    // @param rid : request ID of the anim job to delete
    // @param fileName : job file name (used in delete confirmation dialog)
    // @param fileDuration : job file length (used in delete confirmation dialog)
    // @param fileSize : job file size (used in delete confirmation dialog)
    // @param date : job creation date (used in delete confirmation dialog)
    /////////////////////////////////////////////////////////////////////
    function handleDeleteJobClick(rid, fileName, fileDuration, fileSize, date) {
        const dialogObj = {"name": fileName, "length": fileDuration, "size": fileSize, "date": date}
        DISPATCH({dialogInfo: dialogObj, confirmDialogId: rid})
    }

    //////////////////////////////////////////////////////////////////////
    // local helper functions for anim delete modal...
    //////////////////////////////////////////////////////////////////////
    async function deleteAnimJobAndClearLocalState(rid) {
        setDeleteJobButtonEnabled(false)
        await deleteAnimationJob(rid)
    }

    function closeDeleteAnimJobModal() {
        setDeleteJobButtonEnabled(false)
        closeModal()
    }

    /////////////////////////////////////////////////////////////////////
    function validateJobDeleteInput(event) {
        if (event && event.target) {
            if (event.target.value === STATE.dialogInfo.name) {
                setDeleteJobButtonEnabled(true)
            } else {
                setDeleteJobButtonEnabled(false)
            }
        }
    }

    //////////////////////////////////////////////////////////////////////
    // local helper functions for cancelSubscription modal
    //////////////////////////////////////////////////////////////////////
    function setSubscriptionToCancel(event) {
        if (event && event.target) {
            let selectecdSub = subscriptionList.find(ele => ele.sub_name === event.target.value)
            setSelectedSubscription(selectecdSub)
        }
    }

    function validateSubscriptionToCancel(event) {
        if (event && event.target) {
            let tmp = cancelSubscriptionButtonEnabled
            if (event.target.value === STATE.email) {
                if (event.target.value === selectedSubscription.sub_name) {
                    tmp.sub_name = true
                } else {
                    tmp.sub_name = false
                }
            } else {
                tmp.sub_name = false
            }
            setCancelSubscriptionButtonEnabled(tmp)
        }
    }

    function validateCancelSubscription(event) {
        if (event && event.target) {
            let tmp = cancelSubscriptionButtonEnabled
            if (event.target.value === STATE.email) {
                tmp.email = true
            } else {
                tmp.email = false
            }
            setCancelSubscriptionButtonEnabled(tmp)
        }
    }

    //////////////////////////////////////////////////////////////////////
    // local helper functions for accountClose modal...
    //////////////////////////////////////////////////////////////////////
    function validateCloseAccountInput(event) {
        if (event && event.target) {
            if (event.target.value === STATE.email) {
                setcloseAccountButtonEnabled(true)
            } else {
                setcloseAccountButtonEnabled(false)
            }
        }
    }

    /////////////////////////////////////////////////////////////////////
    // Sorts the library data based on column and direction
    //
    // @param cb : optional callback fcn for when finished
    /////////////////////////////////////////////////////////////////////
    function sortLibraryByColumn() {
        // to sort the library we sort the jobsData state array
        // let logs =  arr ? arr : STATE.jobsData
        let logs = STATE.jobsData
        if (logs) {
            switch (STATE.currSortField) {
                case Enums.libraryColName[0]:
                    if (STATE.currSortDirection === 'up') {
                        logs.sort((a, b) => a.name.localeCompare(b.name))
                    } else {
                        logs.sort((a, b) => b.name.localeCompare(a.name))
                    }
                    break
                case Enums.libraryColName[1]:
                    if (STATE.currSortDirection === 'up') {
                        logs.sort((a, b) => a.lengthRaw - b.lengthRaw)
                    } else {
                        logs.sort((a, b) => b.lengthRaw - a.lengthRaw)
                    }
                    break
                case Enums.libraryColName[2]:
                    if (STATE.currSortDirection === 'up') {
                        logs.sort((a, b) => a.sizeRaw - b.sizeRaw)
                    } else {
                        logs.sort((a, b) => b.sizeRaw - a.sizeRaw)
                    }
                    break
                case Enums.libraryColName[3]:
                    if (STATE.currSortDirection === 'up') {
                        logs.sort((a, b) => (a.correctionFreeCredits + a.labelingFreeCredits) - (b.correctionFreeCredits + b.labelingFreeCredits))
                    } else {
                        logs.sort((a, b) => (b.correctionFreeCredits + b.labelingFreeCredits) - (a.correctionFreeCredits + a.labelingFreeCredits))
                    }
                    break
                case Enums.libraryColName[4]:
                    if (STATE.currSortDirection === 'up') {
                        logs.sort((a, b) => a.dateRaw - b.dateRaw)
                    } else {
                        logs.sort((a, b) => b.dateRaw - a.dateRaw)
                    }
                    break
                default:
                    break
            }
        } else {
            console.log(`sortLibraryByColumn() called with null jobs data!`)
        }
        // update the jobs data with sorted data to refresh the library
        DISPATCH({jobsData: logs})
        return 'ok'

    }

    /////////////////////////////////////////////////////////////////////
    // updateProductsAccessList()
    //
    // Parses the user's okta group(s) membership and enables/disabled products
    // based off which ones they have access to
    //
    // @param cb : optional callback function to call once done updating
    /////////////////////////////////////////////////////////////////////
    function updateProductsAccessList(groupList) {
        let aList = STATE.accessList
        if (!groupList) {
            console.log(`Warning: missing user groups info!`)
            throw `Warning: missing user groups info!`
        }

        //////////////////////////////////////////////////////////
        // DeepMotion Admin group
        //////////////////////////////////////////////////////////
        if (groupList.includes(process.env.REACT_APP_AG_ADMIN)) {
            aList[Enums.productInfo.DMBT_Cloud.id - 1] = true
            aList[Enums.productInfo.DMBT_SDK.id - 1] = true
            aList[Enums.productInfo.VR_SDK.id - 1] = true
            aList[Enums.productInfo.APE_SDK.id - 1] = true
            // enable all products for admin group and return
            return aList
        } else {

            //////////////////////////////////////////////////////////
            // [Product 1] ANIMATE 3D Access Groups
            //////////////////////////////////////////////////////////
            if (groupList.includes(decodeURI(process.env.REACT_APP_AG_DMBT_CLOUD)) ||
                groupList.includes(decodeURI(process.env.REACT_APP_AG_DMBT_CLOUD_TEST))) {
                aList[Enums.productInfo.DMBT_Cloud.id - 1] = true
            } else {
                aList[Enums.productInfo.DMBT_Cloud.id - 1] = false
            }

            //////////////////////////////////////////////////////////
            // [Product 2] DMBT Real Time Body Tracking SDK (SamTv,
            // Windows || Windows & Android)
            //////////////////////////////////////////////////////////
            if (groupList.includes(process.env.REACT_APP_AG_SAM_TV) ||
                groupList.includes(process.env.REACT_APP_AG_DMBT_SDK_AND) ||
                groupList.includes(process.env.REACT_APP_AG_DMBT_SDK_WIN_AND)) {
                aList[Enums.productInfo.DMBT_SDK.id - 1] = true
            } else {
                aList[Enums.productInfo.DMBT_SDK.id - 1] = false
            }

            //////////////////////////////////////////////////////////
            // [Product 3] DMBT VR 3PT Tracking SDK (Windows ||
            // Windows & Android)
            //////////////////////////////////////////////////////////
            if (groupList.includes(process.env.REACT_APP_AG_DM3PT_SDK_WIN) ||
                groupList.includes(process.env.REACT_APP_AG_DM3PT_SDK_WIN_AND)) {
                aList[Enums.productInfo.VR_SDK.id - 1] = true
            } else {
                aList[Enums.productInfo.VR_SDK.id - 1] = false
            }

            //////////////////////////////////////////////////////////
            // [Product 4] Avatar Physics Engine (ie. APE + ACE SDK)
            //////////////////////////////////////////////////////////
            if (groupList.includes(process.env.REACT_APP_AG_PHYSICS_ENGINE)) {
                aList[Enums.productInfo.APE_SDK.id - 1] = true
            } else {
                aList[Enums.productInfo.APE_SDK.id - 1] = false
            }

            ////////////////////////////////////////
            // *** Closed *** Avatar Alpha Program:
            ////////////////////////////////////////
            if (groupList.includes(process.env.REACT_APP_AG_FREE) &&
                groupList.length <= 2) {
                // disable access to all products if they are only a member
                // of the closed Avatar program and Everyone group which
                // all users are a member of
                aList[Enums.productInfo.DMBT_Cloud.id - 1] = false
                aList[Enums.productInfo.DMBT_SDK.id - 1] = false
                aList[Enums.productInfo.VR_SDK.id - 1] = false
                aList[Enums.productInfo.APE_SDK.id - 1] = false
            }

            // update the access list state array
            return aList
        }
    }

    /////////////////////////////////////////////////////////////////////
    // returns true if any glb download links found
    /////////////////////////////////////////////////////////////////////
    function jobContainsGLBDownloadLinks() {
        for (const model of STATE.animJobLinks.downloadLinks.models) {
            if (!model.name.includes('bvh-framesMap') && !model.name.includes('framesIssueStatus')) {
                //TODO: update above IF statement once backend is updated to
                //return if a particular job used standard characters or custom
                for (const link of model.links) {
                    if (link.type === Enums.animFileType.glb) {
                        return true
                    }
                }
            }
        }
        return false
    }

    /////////////////////////////////////////////////////////////////////
    // onClick() handler for closing/canceling modal
    // @param reset : if true stops online previewer and resets job links
    /////////////////////////////////////////////////////////////////////
    function closeModal(resetJobData?) {
        const dialogData = {confirmDialogId: Enums.confirmDialog.none, dialogInfo: {}}
        if (resetJobData) {
            DISPATCH({...resetJobObject, ...dialogData})
        } else {
            DISPATCH(dialogData)
        }
    }

    ///--------------------------------------------------------------------
    /// onClick() handler for closing custom error dialog
    ///--------------------------------------------------------------------
    function closeModalAndResetWorkflow(flag?: number) {
        setRerunConfirmInfo({showModal: false, jobId: 0})
        setSelectedFileType(Enums.animFileType.FBX)
        setcloseAccountButtonEnabled(false)
        setDeleteJobButtonEnabled(false)
        setRerunViewState(Enums.pageState.rerunAnimSettings)
        DISPATCH({
            currWorkflowStep: Enums.uiStates.initial,
            animationJobProgress: 0,
            isJobInProgress: false, // likely a bug, should be isJobInProgress
            displayPreview: false,
            confirmDialogId: Enums.confirmDialog.none,
            errorDialogInfo: {show: false, id: null, title: "", msg: ""}
        })
        // route back to model select page once done reseting state
        if (flag === closeModalFlags.routeToLibrary) {
            history.push(Enums.routes.Anim3dLibrary)
        } else if (flag === closeModalFlags.routeToModelSelect) {
            history.push(Enums.routes.Anim3dModelSelect)
        } else if (flag === closeModalFlags.routeToA3dHome) {
            history.push(Enums.routes.Anim3d)
        } else if (flag === closeModalFlags.routeToProfilePage) {
            history.push(Enums.routes.Anim3dProfilePage)
        }
    }

    function getJobTypeFromFileName(fileName) {
        const fileExt = fileName.split('.')[1];
        if (Enums.acceptedStaticPoseFormats.includes(fileExt)) return Enums.jobTypes.staticPose;
        if (Enums.acceptedVideoFormats.includes(fileExt)) return Enums.jobTypes.animation;
    }

    function getJobType(isRerun) {
        if (isRerun) {
            const job = STATE.jobsData.filter(job => job.rid === STATE.animJobId);
            return getJobTypeFromFileName(job[0].name);
        }

        return STATE.animJobSettings.jobType;
    }

    /////////////////////////////////////////////////////////////////////
    // Creates a job settings object to be used in API request for
    // starting a new animation job.
    //
    // @param isRerun : if true reads job settings data from |rerunSettings|
    // state object, if false reads from |animJobSettings| state
    /////////////////////////////////////////////////////////////////////
    function createAnimJobSettingsObject(isRerun) {
        const settingValueBasedOnJobType = getSettingValueBasedOnJobType(isRerun)
        let outputFormatsList = []
        for (const [key, value] of Object.entries(settingValueBasedOnJobType.formats)) {
            if (value === true) {
                outputFormatsList.push(key)
            }
        }

        let joinedKeys = outputFormatsList.join(',')

        // create animation job params array to include in request body
        let jobParams = [
            `config=configWebApp`,
            `fullLog`,
            `formats=${joinedKeys}`
        ]

        if (isRerun) {
            if (STATE.rerunSettings.customModelInfo.id !== "multiple") {
                // do not allow modification of custom character setting for reruns
                if (STATE.rerunSettings.customModelInfo && STATE.rerunSettings.customModelInfo.id !== 'standard') {
                    jobParams.push(`model=${STATE.rerunSettings.customModelInfo.id}`)
                }
            }
        } else {
            if (STATE.animJobSettings.customModelInfo && STATE.animJobSettings.customModelInfo.id) {
                jobParams.push(`model=${STATE.animJobSettings.customModelInfo.id}`)
            }
        }

        // add face tracking, hand tracking, foot locking, fallback pose, speed mult, smoothness, and camera motion params
        jobParams.push(`trackFace=${settingValueBasedOnJobType.trackFace}`)
        jobParams.push(`trackHand=${settingValueBasedOnJobType.trackHand}`)

        let footLockingMode = settingValueBasedOnJobType.footLockMode;
        if (getJobType(isRerun) == Enums.jobTypes.staticPose && footLockingMode === Enums.footLockMode.auto) {
            footLockingMode = Enums.footLockMode.always;
        }
        jobParams.push(`footLockingMode=${footLockingMode}`)

        jobParams.push(`fallbackPose=${settingValueBasedOnJobType.fallbackPose}`)
        if (checkFeatureAvailability(STATE.subscriptionInfo.name, Enums.featureLockNames.slowMotion)) {
            jobParams.push(`videoSpeedMultiplier=${settingValueBasedOnJobType.videoSpeedMultiplier}`)
        }
        if (checkFeatureAvailability(STATE.subscriptionInfo.name, Enums.featureLockNames.peSmoothness)) {
            jobParams.push(`poseFilteringStrength=${settingValueBasedOnJobType.poseFilteringStrength}`)
        }
        jobParams.push(`iris_turning_agility=${settingValueBasedOnJobType.iris_turning_agility}`)
        jobParams.push(`rootAtOrigin=${settingValueBasedOnJobType.rootJointAtOrigin}`)
        jobParams.push(`upperBodyOnly=${settingValueBasedOnJobType.upperBodyOnly}`)
        if (settingValueBasedOnJobType.jobType === Enums.jobTypes.staticPose) {
            // set camera to Fixed for static pose jobs
            jobParams.push(`render.camMode=1`)
        } else {
            jobParams.push(`render.camMode=${Enums.cameraMotionSettings.indexOf(settingValueBasedOnJobType.camMode)}`)
        }
        jobParams.push(`render.camHorizontalAngle=${settingValueBasedOnJobType.camHorizontalAngle}`)

        const shouldHideFloor = settingValueBasedOnJobType.footLockMode == Enums.footLockMode.never;
        jobParams.push(`render.hideFloor=${shouldHideFloor}`);

        // enable DMPE output for premium (Pro+) subscriptions
        if (isProfessionalOrHigherPlan()) {
            jobParams.push(`createDMPE=${true}`)
        }

        // configure side-by-side rendering based on user selection
        switch (settingValueBasedOnJobType.backdrop) {
            case Enums.Backdrop.defaultWithOriginal:
                jobParams.push('render.sbs=1')
                break
            case Enums.Backdrop.solid:{
                jobParams.push('render.backdrop=solid')
                jobParams.push('render.sbs=0')
                // MP4 green-screen background color
                let color = settingValueBasedOnJobType.bgColor.toString()
                // remove quotes from color string
                color = color.replace(/"/g, "")
                jobParams.push(`render.bgColor=${color}`)
                break
            }
            case Enums.Backdrop.studio: {
                jobParams.push('render.backdrop=studio')
                jobParams.push('render.sbs=0')
                // MP4 green-screen background color
                let color = settingValueBasedOnJobType.bgColor.toString()
                // remove quotes from color string
                color = color.replace(/"/g, "")
                jobParams.push(`render.bgColor=${color}`)
                break
            }
            case Enums.Backdrop.environments:
                jobParams.push('render.backdrop=' + settingValueBasedOnJobType.environment)
                jobParams.push('render.sbs=0')
                break
            case Enums.Backdrop.transparent:
                jobParams.push('render.backdrop=transparent')
                jobParams.push('render.sbs=0')
                break
            default:
                jobParams.push('render.sbs=0')
          }

        if (settingValueBasedOnJobType.shadow) {
            // render shadow in MP4 output
            jobParams.push("render.shadow=1")
        } else {
            jobParams.push("render.shadow=0")
        }

        if (!settingValueBasedOnJobType.includeAudio) {
            jobParams.push(`render.includeAudio=0`)
        }

        // check for job settings the user has selected
        if (settingValueBasedOnJobType['keyframeReducer'] === true) {
            jobParams.push("vector.fbx_key_reduce")
        }
        // check for job settings the user has selected
        if (settingValueBasedOnJobType['physicsSim'] === false) {
            jobParams.push("dis=s")
        }

        // Add DM Logo to MP4 Output (if enabled)
        // TODO: update logic once account plans are enabled
        jobParams.push("render.logo=1")


        // multi person tracking
        if (multiPersonSelection && !isRerun) {
            jobParams.push('models=' + JSON.stringify(multiPersonCharacter))
        }
        if (STATE.rerunSettings.customModelInfo.id === "multiple" && isRerun) {
            const multiPersonCharacterData = []
            STATE.rerunSettings.modelsData.forEach((item) => {
                const data = {
                    trackingId: item.trackingId,
                    modelId: item.modelId
                }
                multiPersonCharacterData.push(data)
            })
            jobParams.push('models=' + JSON.stringify(multiPersonCharacterData))
        }

        const isRerunTrimDataPresent = isRerun && settingValueBasedOnJobType.trim
        if (isRerunTrimDataPresent) {
            jobParams.push(`trim=${settingValueBasedOnJobType.trim}`)
        }

        const isVideoTrimDataPresent = videoTrimData &&
            (videoTrimData.from > 0 || videoTrimData.to < STATE.inputVideoData.fileLength)
        if (isVideoTrimDataPresent) {
            jobParams.push(`trim=${videoTrimData.from.toFixed(2)},${videoTrimData.to.toFixed(2)}`)
        }

        const isRerunCropDataPresent = isRerun && settingValueBasedOnJobType.crop
        if (isRerunCropDataPresent) {
            jobParams.push(`crop=${settingValueBasedOnJobType.crop}`)
        }

        const isVideoCropDataPresent = videoCropData && !shallowEqual(videoCropData, videoCropInit)
        if (isVideoCropDataPresent) {
            const crop = normalizeVideoCropCoord(videoCropData)
            jobParams.push(`crop=${crop.left},${crop.top},${crop.right},${crop.bottom}`)
        }

        if (motionLabelingText) {
            jobParams.push(`description=${motionLabelingText}`)
        }

        // return the job settings object...
        return jobParams
    }

    ////////////////////////////////////////////////////////////////////
    // Retrieves the job settings object for regular & rerun jobs
    ////////////////////////////////////////////////////////////////////
    function getSettingValueBasedOnJobType(isRerun) {
        return (isRerun ? STATE.rerunSettings : STATE.animJobSettings)
    }

    ////////////////////////////////////////////////////////////////////
    // True for subscriptions = Professional or higher
    ////////////////////////////////////////////////////////////////////
    function isProfessionalOrHigherPlan() {

        if (STATE.subscriptionInfo.isEnterpriseUser) {
            return true
        }
        // customer may have a stripe subscription but a different set of account plan
        // features may be applied through feature packs. Backend prioritizes feature packs
        // so we first check for highest level feature pack
        if (STATE.subscriptionInfo.featurePacks && STATE.subscriptionInfo.featurePacks.length) {
            let oneActivePackFound = false
            for (let i = 0; i < STATE.subscriptionInfo.featurePacks.length; i++) {
                let pack = STATE.subscriptionInfo.featurePacks[i]
                if (pack.active) {
                    oneActivePackFound = true
                    // return true if "professional", "studio", or "enterprise" pack
                    if (pack.pack_id.toLowerCase().includes(Enums.accountPlansInfo[Enums.accountPlansInfo.length - 1].name.toLowerCase()) ||
                        pack.pack_id.toLowerCase().includes(Enums.accountPlansInfo[Enums.accountPlansInfo.length - 2].name.toLowerCase()) ||
                        pack.pack_id.toLowerCase().includes(Enums.accountPlansInfo[Enums.accountPlansInfo.length - 3].name.toLowerCase())) {
                        return true
                    }
                }
            }
            if (oneActivePackFound) {
                // if there was at least one active pack and it was not Pro+ then
                // a lower level pack has been applied to the account
                return false
            }
        }
        if (STATE.subscriptionInfo.name.toLowerCase() === Enums.accountPlansInfo[Enums.accountPlansInfo.length - 1].name.toLowerCase() ||
            STATE.subscriptionInfo.name.toLowerCase() === Enums.accountPlansInfo[Enums.accountPlansInfo.length - 2].name.toLowerCase() ||
            STATE.subscriptionInfo.name.toLowerCase() === Enums.accountPlansInfo[Enums.accountPlansInfo.length - 3].name.toLowerCase()) {
            return true
        } else {
            return false
        }
    }

    ////////////////////////////////////////////////////////////////////
    // True for subscriptions = studio or higher
    ////////////////////////////////////////////////////////////////////
    function isStudioOrHigherPlan() {
        if (STATE.subscriptionInfo.isEnterpriseUser) {
            return true
        }
        // customer may have a stripe subscription but a different set of account plan
        // features may be applied through feature packs. Backend prioritizes feature packs
        // so we first check for highest level feature pack
        if (STATE.subscriptionInfo.featurePacks && STATE.subscriptionInfo.featurePacks.length) {
            let oneActivePackFound = false
            for (let i = 0; i < STATE.subscriptionInfo.featurePacks.length; i++) {
                let pack = STATE.subscriptionInfo.featurePacks[i]
                if (pack.active) {
                    oneActivePackFound = true
                    // return true if "studio", or "enterprise" pack
                    if (pack.pack_id.toLowerCase().includes(Enums.accountPlansInfo[Enums.accountPlansInfo.length - 1].name.toLowerCase()) ||
                        pack.pack_id.toLowerCase().includes(Enums.accountPlansInfo[Enums.accountPlansInfo.length - 2].name.toLowerCase())) {
                        return true
                    }
                }
            }
            if (oneActivePackFound) {
                // if there was at least one active pack and it was not Studio+ then
                // a lower level pack has been applied to the account
                return false
            }
        }
        if (STATE.subscriptionInfo.name.toLowerCase() === Enums.accountPlansInfo[Enums.accountPlansInfo.length - 1].name.toLowerCase() ||
            STATE.subscriptionInfo.name.toLowerCase() === Enums.accountPlansInfo[Enums.accountPlansInfo.length - 2].name.toLowerCase()) {
            return true
        } else {
            return false
        }
    }

    /////////////////////////////////////////////////////////////////////
    // Builds the title row with job type drop down selector
    /////////////////////////////////////////////////////////////////////
    function buildJobTypeSelectionRow() {
        let anim3dFontColor = "dm-brand-2-font", poseFontColor = "selector-inactive"
        if (STATE.animJobSettings.jobType) {
            anim3dFontColor = "selector-inactive"
            poseFontColor = "dm-brand-2-font"
        }
        return (
            <>
                <div className="column box disabled-switch m-3 p-0 ">
                    <div className="columns is-gapless is-mobile has-text-centered has-text-weight-bold m-0 br-4">
                        <div className={`column is-5 m-0 p-0 ${anim3dFontColor}`}>
                            <div className="columns is-gapless is-mobile m-0 p-0">
                                <div className="column is-5 m-0 p-0 has-text-centered">
                                    <figure
                                        className={`image is-16by9 ${STATE.animJobSettings.jobType ? "inactive-figure" : "active-figure"}`}>
                                        <img src={imgNewAnim} className="br-4-left" alt="Default charaters"/>
                                    </figure>
                                </div>
                                <div className="column m-0 p-0 flex-vert-center">{jobType3dAnim}</div>
                            </div>
                        </div>
                        <div className="column is-2 m-0 p-0 flex-vert-center">
                            <i className={`fas fa-2x fa-chevron-left ${anim3dFontColor}`}/>
                            <label className="jobtype-switch">
                                <input onChange={() => switchAnimSettingsJobType(STATE.animJobSettings.jobType)}
                                       type="checkbox" checked={STATE.animJobSettings.jobType}/>
                                <span className="jobtype-slider"/>
                            </label>
                            <i className={`fas fa-2x fa-chevron-right ${poseFontColor}`}/>
                        </div>
                        <div className={`column is-5 m-0 p-0 ${poseFontColor}`}>
                            <div className="columns is-gapless is-mobile m-0 p-0">
                                <div className="column m-0 p-0 flex-vert-center">{jobType3dPose}</div>
                                <div className="column is-5 m-0 p-0 has-text-centered">
                                    <figure
                                        className={`image is-16by9 ${STATE.animJobSettings.jobType ? "active-figure" : "inactive-figure"}`}>
                                        <img src={imgNewPose} className="br-4-right" alt="Default charaters"/>
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <MultiPersonSelectorSwitch
                    multiPersonTracking={multiPersonTracking}
                    setMultiPersonTracking={setMultiPersonTracking}
                    multiPersonSelection={multiPersonSelection}
                />
            </>
        )
    }

    /////////////////////////////////////////////////////////////////////
    function switchAnimSettingsJobType(value) {
        // if switching from anim job to 3d pose and user has already uploaded input file or there
        // is a video upload in progress warn them that they will lose the uploaded media
        if (STATE.videoStorageUrl || STATE.silentUploadInProgress) {
            DISPATCH({confirmDialogId: Enums.confirmDialog.confirmLoseUploadData})
        } else {
            let newAnimJobSettings = JSON.parse(STATE.animJobSettingsSnapshots)
            DISPATCH({
                animJobSettings: {...newAnimJobSettings, ...{jobType: value === 0 ? 1 : 0}},
                animJobSettingsSnapshots: JSON.stringify(STATE.animJobSettings)
            })
        }
    }

    /////////////////////////////////////////////////////////////////////
    // Builds the file selected UI
    /////////////////////////////////////////////////////////////////////
    function renderScreenAfterFileSelected() {
        if (!STATE.inputVideoData || Object.keys(STATE.inputVideoData).length === 0) {
            return <div/>
        }
        let customModelName = null
        let uiImage = null
        let descr = ""
        // show custom model info if model ID present
        if (STATE.animJobSettings.customModelInfo.id) {
            customModelName = STATE.animJobSettings.customModelInfo.name
            uiImage = (STATE.animJobSettings.customModelInfo.thumbImg instanceof Blob) ? URL.createObjectURL(STATE.animJobSettings.customModelInfo.thumbImg) : imgCustom
            descr = customModelName
        } else {
            // otherwise standard cloud character set
            customModelName = ""
            uiImage = imgStandard
            descr = modelSectionDefault
        }
        /**
         * Temporarily turn off the need to handle files that are too large/long/frame rate/resolution
         * Until we started working on the DW-42 (https://midastouch.atlassian.net/browse/DW-42)

         let bodyCredits = STATE.inputVideoData.fileLength
         let faceCredits = STATE.animJobSettings.trackFace ? bodyCredits * 0.5 : 0
         let handCredits = STATE.animJobSettings.trackHand ? bodyCredits * 0.5 : 0
         let totalCredits = Math.ceil(bodyCredits + faceCredits + handCredits)

         if( totalCredits > STATE.currentBillCycleInfo.remainingRounded ) {
      // reset UI and display not enough mins dialog if selected video duration
      // is longer than remaining monthly time
      console.log(`Not enough animation credits left in the current subscription month. Input video is ${STATE.inputVideoData.fileLength} seconds long and it needs ${totalCredits} credits to process with your current settings. However only ${STATE.currentBillCycleInfo.remainingRounded} credits are remaning.`)
      resetDialogsData(null, true)
      DISPATCH({confirmDialogId: Enums.confirmDialog.MinutesBalanceTooLow})
    }
         else if( STATE.inputVideoData.fileLength > process.env.REACT_APP_MAX_CLIP_LENGTH ) {
      console.log(`Error: Max video clip length of ${process.env.REACT_APP_MAX_CLIP_LENGTH} exceeded, input video was ${STATE.inputVideoData.fileLength} seconds.`);
      resetDialogsData(null, true)
      DISPATCH({confirmDialogId: Enums.confirmDialog.inputFileTooLong})
      return <div />
    }
         */


            // show progress border when background upload in progress
        let borderClass = STATE.silentUploadInProgress ? "bkgd-progress-bar meter-bkgd" : ""

        return (
            <React.Fragment>
                <div id="anim-fadein" className="column">
                    {
                        window.location.pathname !== Enums.routes.Anim3dGuidedFTE
                        &&
                        <AnimVersionPanel/>
                    }
                    <div className="section pt-4">
                        <div className="columns">
                            <div className="column bShadow has-text-centered rounded-corners dm-brand">
                                <div className="dm-brand rounded-corners-top">
                                    {buildJobTypeSelectionRow()}
                                </div>
                                <div className="columns dm-brand" style={{padding: '0 10px'}}>
                                    {
                                        !STATE.inputVideoData.selectedFile
                                            ?
                                            buildAddMotionClipArea()
                                            :
                                            buildMotionClipSelectedArea()
                                    }

                                    {/*** DISPLAY CHARACTER SETTINGS ***/}
                                    {buildCharacterSettingsArea()}
                                </div>
                                {/*** MOTION LABELING ***/}
                                <MotionLabeling
                                    multiPersonTracking={multiPersonTracking}
                                    motionLabelingText={motionLabelingText}
                                    setMotionLabelingText={setMotionLabelingText}
                                />
                                {/*** JOB SETTINGS UI ***/}
                                {buildJobSettingsArea()}

                            </div>
                        </div>
                    </div>
                    {InformationArea()}
                </div>
            </React.Fragment>
        )
    }

    function buildJobSettingsArea(isGuidedFTE?: boolean) {
        const columnClass = isGuidedFTE ? "rounded-corners" : "rounded-corners-bottom"
        return (
            <div className="columns rounded-corners">
                <div className={`column dm-brand m-0 pt-0 ${columnClass}`}>
                    <div className="box disabled-switch m-3 p-4">
                        {
                            STATE.animJobSettings.jobType === Enums.jobTypes.animation
                                ?
                                buildAnimationJobConfigScreen()
                                :
                                buildStaticPoseJobConfigScreen()
                        }

                        {/*** Create & Discard Action Buttons ***/}
                        <div className="columns">
                            { /* Do not show discard button for the guided FTE */
                                location.pathname !== Enums.routes.Anim3dGuidedFTE
                                &&
                                <div className="column has-text-centered">
                                  <button type="button" className="button remove-btn btn-shadow"
                                          onClick={() => history.push(Enums.routes.Anim3d)} style={{width: '100%'}}>
                                    <span className="no-side-margins">Discard Job</span></button>
                                </div>
                            }
                            <div className="column has-text-centered">
                                {!STATE.silentUploadInProgress && (!multiPersonTracking || multiPersonIsAlreadyCreated)
                                    ?
                                    <button type="button" className="button action-btn glow-on-hover btn-shadow"
                                            onClick={() => DISPATCH({confirmDialogId: Enums.confirmDialog.CheckBeforeCreateJob})}
                                            style={{width: '100%'}}>
                                        <span className="no-side-margins"> {titleCreateAnim} </span>
                                    </button>
                                    :
                                    <button type="button" className="button create-anim-btn-disabled no-cursor"
                                            style={{width: '100%'}}>
                    <span className="logo-text-blink">
                      <span className="icon dm-brand-2-font ml-0 mr-1">
                        <i className="fas fa-exclamation-triangle">
                        </i>
                      </span>
                        {!multiPersonTracking ? textUploadingVideo : !multiPersonSelection ? textMultiPersonStep1 : textMultiPersonStep2}
                    </span>
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function buildMotionClipSelectedArea() {

        let fileResolution = ''
        if (STATE.inputVideoData.videoRes) {
            const videoResolutionWidth = STATE.inputVideoData.videoRes.w * ((videoCropData?.width || 100) * 0.01)
            const videoResolutionHeight = STATE.inputVideoData.videoRes.h * ((videoCropData?.height || 100) * 0.01)
            fileResolution = videoResolutionWidth.toFixed() + " x " + videoResolutionHeight.toFixed()
        } else {
            fileResolution = "N/A"
        }

        let fileNameSize = (STATE.inputVideoData.fileName.length > Math.floor(Enums.MAX_FILENAME_LENGTH / 2)) ? "is-6" : "is-5"
        let fileFPS = (STATE.inputVideoData.fps) ? (Number.isInteger(STATE.inputVideoData.fps) ? STATE.inputVideoData.fps : STATE.inputVideoData.fps.toFixed(2)) : "N/A"

        // show progress border when background upload in progress
        let borderClass = STATE.silentUploadInProgress ? "bkgd-progress-bar meter-bkgd" : ""

        return (
            <div className="column box disabled-switch m-3 mt-3 p-4 is-relative">
                <div>
                    {/* conditionally show background upload progress bar */}
                    <div className={borderClass}>
                        <span style={{width: '100%'}}></span>
                    </div>
                    {/*** DISPLAY SELECTED INPUT VIDEO & FILE INFO ***/}
                    <div className="columns m-0">
                        <div className="column">
                            <div className="columns block-title-bar">
                                <div className="column bottom-border block-title">
                                    <span className={"title is-5 has-text-white " + fileNameSize}><span className="icon"
                                                                                                        style={{marginRight: '10px'}}><i
                                        className="far fa-play-circle"></i></span><span>{STATE.inputVideoData.fileName}</span></span>
                                </div>
                                <div className="column is-1 m-0 p-0 bottom-border">
                                    {
                                        <DMToolTip
                                            text={STATE.animJobSettings.jobType === Enums.jobTypes.staticPose ? Enums.toolTipChangeImage : Enums.toolTipChangeVideo}
                                            tipId="tip-change-video"
                                            icon="fas fa-times-circle fa-lg"
                                            iconColor="#fab03c"
                                            cursor="cursor-pt"
                                            onClick={() => {
                                                DISPATCH({
                                                    confirmDialogId: Enums.confirmDialog.confirmInputMediaRemoval,
                                                    displayTrimAndCropDialog: false
                                                })
                                                setVideoCropData(videoCropInit)
                                                setVideoTrimData(null)
                                            }}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="columns m-0 pr-4 pl-4 pt-0 pb-0">
                        <div className="column is-7 mt-0">
                            {
                                STATE.animJobSettings.jobType === Enums.jobTypes.staticPose && !Enums.isFileExtensionVideoFormat(STATE.inputVideoData.fileName)
                                    ?
                                    <figure className="image is-256x256">
                                        <img src={STATE.showSelectedFile} className="bShadow br-4"/>
                                    </figure>
                                    :
                                    (
                                        <div className="video-viewer">
                                            <ReactCrop crop={videoCropData} onChange={() => {
                                            }}>
                                                <div>
                                                    <video
                                                        id="anim-fadein"
                                                        controls
                                                        className="bShadow"
                                                        style={{borderRadius: "6px", maxHeight: "33vh"}}
                                                        ref={videoref}
                                                        onError={() => {
                                                            DISPATCH({videoCanPlay: false})
                                                        }}
                                                        onLoadedData={async () => {
                                                            try {
                                                                const thumbnails = await generateVideoThumbnails(STATE.inputVideoData.selectedFile, 10)
                                                                DISPATCH({
                                                                    tncThumbnails: thumbnails,
                                                                    videoCanPlay: true
                                                                })
                                                            } catch (e) {
                                                                console.error(e)
                                                                DISPATCH({videoCanPlay: false})
                                                            }
                                                        }}
                                                        onTimeUpdate={(e) => {
                                                            const target = e.target as HTMLVideoElement
                                                            if (videoTrimData && (target.currentTime >= videoTrimData.to)) {
                                                                videoref.current.pause()
                                                                target.currentTime = videoTrimData.from
                                                            }
                                                        }
                                                        }
                                                        onSuspend={(e) => {
                                                            const target = e.target as HTMLVideoElement
                                                            if (videoTrimData) {
                                                                target.currentTime = videoTrimData.from
                                                            }
                                                        }
                                                        }
                                                        onEnded={(e) => {
                                                            const target = e.target as HTMLVideoElement
                                                            if (videoTrimData) {
                                                                target.currentTime = videoTrimData.from
                                                            }
                                                        }}
                                                    >
                                                        <source
                                                            src={URL.createObjectURL(STATE.inputVideoData.selectedFile)}
                                                            type="video/mp4"
                                                        />
                                                        <source
                                                            src={URL.createObjectURL(STATE.inputVideoData.selectedFile)}
                                                            type="video/avi"
                                                        />
                                                        <source
                                                            src={URL.createObjectURL(STATE.inputVideoData.selectedFile)}
                                                            type="video/mov"
                                                        />
                                                        Your browser does not support HTML5 video.
                                                        {/*** TODO: Create new placeholder image for when video not supported  ***/}
                                                        <figure className="image is-256x256">
                                                            <img src={imgStandard}/>
                                                        </figure>
                                                    </video>
                                                </div>
                                            </ReactCrop>
                                        </div>
                                    )
                            }
                            {
                                STATE?.inputVideoData?.selectedFile?.type.split("/")[0] === "video" &&
                                <CropButton
                                  videoCropData={videoCropData}
                                  setVideoCropData={setVideoCropData}
                                  videoTrimData={videoTrimData}
                                  setVideoTrimData={setVideoTrimData}
                                  fallback={fallback}
                                  setFallback={setFallback}
                                  checkFeatureAvailability={checkFeatureAvailability}
                                  displayDialog={STATE.displayTrimAndCropDialog}
                                  multiPersonSelection={multiPersonSelection}
                                />
                            }
                        </div>
                        <div className="column mt-0">
                            <div className="columns has-text-left">
                                <div className="column">
                                    {
                                        STATE.silentUploadInProgress
                                            ?
                                            <span className="subtitle is-5 has-text-white"><span className="icon"
                                                                                                 style={{marginRight: '10px'}}><i
                                                className="far fa-clock"></i></span><span
                                                className="logo-text-blink">{textAnalyzing}</span></span>
                                            :
                                            <span
                                                className={fallback['durationIsExceeded'] && !fallback["durationIsFallbackSaved"] ? "subtitle is-5 red-bold" : "subtitle is-5 has-text-white"}><span
                                                className="icon" style={{marginRight: '10px'}}><i
                                                className="far fa-clock"></i></span>
                        <span>{videoTrimData ? ((videoTrimData.to - videoTrimData.from).toFixed(2) + " sec") :
                            STATE.inputVideoData.fileLength ? (STATE.inputVideoData.fileLength.toFixed(2) + " sec") : "N/A"}</span>
                        <span>{fallback['durationIsExceeded'] && !fallback["durationIsFallbackSaved"] &&
                        <DMToolTip
                          text={Enums.tooltipVideoLengthShouldTrim()}
                          iconColor="#fc4242"
                          tipId="video-length-should-trim"
                          icon={`fas fa-info-circle fa-md`}
                          cursor="cursor-pt"
                        />
                        }</span></span>
                                    }
                                </div>
                            </div>
                            <div className="columns has-text-left">
                                <div className="column">
                                    {
                                        STATE.silentUploadInProgress
                                            ?
                                            <span className="subtitle is-5 has-text-white"><span className="icon"
                                                                                                 style={{marginRight: '10px'}}><i
                                                className="far fa-hdd"></i></span><span
                                                className="logo-text-blink">{textAnalyzing}</span></span>
                                            :
                                            <span className="subtitle is-5 has-text-white"><span className="icon"
                                                                                                 style={{marginRight: '10px'}}><i
                                                className="far fa-hdd"></i></span><span>{Enums.formatSizeUnits(STATE.inputVideoData.fileSize, 2)}</span></span>
                                    }
                                </div>
                            </div>
                            <div className="columns has-text-left">
                                <div className="column">
                                    {
                                        STATE.silentUploadInProgress
                                            ?
                                            <span className="subtitle is-5 has-text-white"><span className="icon"
                                                                                                 style={{marginRight: '10px'}}><i
                                                className="fas fa-desktop"></i></span><span
                                                className="logo-text-blink">{textAnalyzing}</span></span>
                                            :
                                            <span
                                                className={fallback['resolutionIsExceeded'] && !fallback["resolutionIsFallbackSaved"] ? "subtitle is-5 red-bold" : "subtitle is-5 has-text-white"}><span
                                                className="icon" style={{marginRight: '10px'}}><i
                                                className="fas fa-desktop"></i></span><span>{fileResolution}</span>
                      <span>{fallback['resolutionIsExceeded'] && !fallback["resolutionIsFallbackSaved"] &&
                      <DMToolTip
                        text={Enums.tooltipVideoResolutionLimits()}
                        iconColor="#fc4242"
                        tipId="resolution-limits"
                        icon={`fas fa-info-circle fa-md`}
                        cursor="cursor-pt"
                      />
                      }</span></span>
                                    }
                                </div>
                            </div>
                            {
                                STATE.animJobSettings.jobType === Enums.jobTypes.animation
                                &&
                                <div className="columns has-text-left">
                                  <div className="column">
                                      {
                                          STATE.silentUploadInProgress
                                              ?
                                              <span className="subtitle is-5 has-text-white"><span className="icon"
                                                                                                   style={{marginRight: '10px'}}><i
                                                  className="fas fa-film"></i></span><span
                                                  className="logo-text-blink">{textAnalyzing}</span></span>
                                              :
                                              <span
                                                  className={fallback['fpsIsExceeded'] && !fallback["fpsIsFallbackSaved"] ? "subtitle is-5 red-bold" : "subtitle is-5 has-text-white"}><span
                                                  className="icon" style={{marginRight: '10px'}}><i
                                                  className="fas fa-film"></i></span><span>
                          {Math.min(fileFPS, (checkFeatureAvailability(STATE.subscriptionInfo.name, Enums.featureLockNames.maxFps) as number))} FPS</span></span>
                                      }
                                  </div>
                                </div>
                            }
                        </div>
                    </div>
                    {
                        multiPersonTracking &&
                        <div className='column top-border'>
                          <button
                            type="button"
                            className="button action-btn glow-on-hover btn-shadow"
                            style={{width: '100%'}}
                            disabled={!STATE.inputVideoData?.fileLength || multiPersonSelection}
                            onClick={() => {
                                setMultiPersonSelection(true)
                            }}
                          >
                <span className="no-side-margins">{STATE.animJobSettings.jobType === 0 ? textMultiPersonAnimationSave : textMultiPersonPoseSave}</span>
                          </button>
                        </div>
                    }
                </div>
            </div>
        )
    }

    function buildCharacterSettingsArea() {
        let isRobloxModel = false
        let customModelName = null
        let faceSupported = false
        let handSupported = false
        let uiImage = null
        let descr = ""

        // special case for Roblox model:
        if (STATE.animJobSettings.customModelInfo && STATE.animJobSettings.customModelInfo.name === Enums.robloxModelId) {
            // otherwise standard cloud character set
            customModelName = STATE.animJobSettings.customModelInfo.name
            isRobloxModel = true
            faceSupported = false
            handSupported = false
            uiImage = imgRobloxR15
            descr = customModelName
        } else {
            if (STATE.animJobSettings.customModelInfo && STATE.animJobSettings.customModelInfo.id) {
                customModelName = STATE.animJobSettings.customModelInfo.name
                const checkedModel = checkCustomCharacterFeatureCompatiabilityIfNecessary(STATE.animJobSettings.customModelInfo.id)
                if (checkedModel) {
                    faceSupported = checkedModel.faceDataType === 1
                    handSupported = checkedModel.handDataType === 1
                } else {
                    faceSupported = handSupported = null
                }
                uiImage = STATE.animJobSettings.customModelInfo.thumb ? STATE.animJobSettings.customModelInfo.thumb : imgCustom
                descr = customModelName
            } else {
                // otherwise standard cloud character set
                customModelName = ""
                faceSupported = true
                handSupported = true
                uiImage = imgStandard
                descr = ""
            }
        }
    const glbSupported = STATE.animJobSettings.customModelInfo && STATE.animJobSettings.customModelInfo.id && STATE.animJobSettings.customModelInfo.platform !== Enums.robloxModelPlatform

        let customGlbToolTip = [
            <React.Fragment key="customGlbToolTip">
                <DMToolTip
                    text={Enums.toolTipGlb}
                    tipId="glb-output-tip"
                    noMargins={true}
                />
            </React.Fragment>
        ]
        let faceSupportedToolTip = [
            <React.Fragment key="faceSupportedToolTip">
                <DMToolTip
                    text={Enums.toolTipFaceTrack}
                    tipId="face-supported-tip"
                    noMargins={true}
                />
            </React.Fragment>
        ]
        let headSupportedToolTip = [
            <React.Fragment key="headSupportedToolTip">
                <DMToolTip
                    text={Enums.toolTipHeadTrack}
                    tipId="face-supported-tip"
                    noMargins={true}
                />
            </React.Fragment>
        ]
        let handSupportedToolTip = [
            <React.Fragment key="handSupportedToolTip">
                <DMToolTip
                    text={Enums.toolTipHandTrack}
                    tipId="hand-supported-tip"
                    noMargins={true}
                />
            </React.Fragment>
        ]
        return (
            <>
                {
                    multiPersonTracking ?
                        <MultiPerson
                            multiPersonSelection={multiPersonSelection}
                            setMultiPersonSelection={setMultiPersonSelection}
                            setMultiPersonTracking={setMultiPersonTracking}
                            setMultiPersonIsAlreadyCreated={setMultiPersonIsAlreadyCreated}
                            multiPersonRid={multiPersonRid}
                            setMultiPersonRid={setMultiPersonRid}
                            setMultiPersonCharacter={setMultiPersonCharacter}
                            checkFeatureAvailability={checkFeatureAvailability}
                            videoCropData={videoCropData}
                            videoTrimData={videoTrimData}
                            videoCropInit={videoCropInit}
                            normalizeVideoCropCoord={normalizeVideoCropCoord}
                            logoutUser={logoutUser}
                            setErrorDialogInfo={setErrorDialogInfo}
                            handleHttpError={handleHttpError}
                        />
                        :
                        <div className="column box disabled-switch m-3 p-4">
                            <div className="columns m-0">
                                <div className="column has-text-left bottom-border">
                                    <span className="title is-5 has-text-white"><span className="icon"
                                                                                      style={{marginRight: '10px'}}><i
                                        className="fas fa-user"></i></span><span
                                        className="mr-1">3D Model: </span><span>{descr}</span></span>
                                </div>
                                <div className="column is-1 m-0 p-0 bottom-border">
                                    <DMToolTip
                                        text={Enums.toolTipChangeModel}
                                        tipId="tip-change-model"
                                        icon="fas fa-edit fa-lg"
                                        iconColor="#fab03c"
                                        cursor="cursor-pt"
                                        onClick={() => DISPATCH({confirmDialogId: Enums.confirmDialog.customModelSelect})}
                                    />
                                </div>
                            </div>
                            <div className="columns m-0">
                                <div className="column mt-0 has-text-centered br-4">
                                    <figure id="anim-fadein" className={"image br-4 " + fileSelectThumbnailRatio}
                                            style={{minHeight: '125px', border: '2px solid #363636'}}>
                                        <img onLoad={(img) => calcFileSelectThumbnailRatio(img)} src={uiImage}
                                             className="bShadow m-0 has-background-light" alt="custom character"/>
                                    </figure>
                                </div>
                                {
                                    <div className="column has-text-left mt-0">
                                        {
                                            faceSupported
                                                ?
                                                <h3 className="subtitle is-5 mb-3 has-text-white">Face Tracking is <span
                                                    className="has-text-success mr-1">supported</span>
                                                    {faceSupportedToolTip}
                                                </h3>
                                                :
                                                <>
                                                    {
                                                        isRobloxModel
                                                            ?
                                                            (<h3 className="subtitle is-5 mb-3 has-text-white">Head Rotation Tracking
                                                                is <span className="has-text-warning mr-1">not supported</span>
                                                                {headSupportedToolTip}
                                                            </h3>)
                                                            :
                                                            (<h3 className="subtitle is-5 mb-3 has-text-white">Head Rotation Tracking
                                                                is <span className="has-text-success mr-1">supported</span>
                                                                {headSupportedToolTip}
                                                            </h3>)
                                                    }
                                                </>

                                        }
                                        {
                                            handSupported
                                                ?
                                                <h3 className="subtitle is-5 mb-3 has-text-white">Hand Tracking is <span
                                                    className="has-text-success mr-1">supported</span>
                                                    {handSupportedToolTip}
                                                </h3>
                                                :
                                                (
                                                    handSupported === null
                                                        ?
                                                        <h3 className="subtitle is-5 mb-3 has-text-white">Hand Tracking
                                                            is <span className="logo-text-blink has-text-warning">being checked</span>
                                                            {handSupportedToolTip}
                                                        </h3>
                                                        :
                                                        <h3 className="subtitle is-5 mb-3 has-text-white">Hand Tracking
                                                            is <span className="has-text-warning">not supported</span>
                                                            {handSupportedToolTip}
                                                        </h3>
                                                )
                                        }
                                        {
                                            glbSupported
                                                ?
                                                <h3 className="subtitle is-5 has-text-white">GLB output is <span
                                                    className="has-text-success mr-1">enabled</span>
                                                    {customGlbToolTip}
                                                </h3>
                                                :
                                                <h3 className="subtitle is-5 has-text-white">GLB output is <span
                                                    className="has-text-warning">disabled</span>
                                                    {customGlbToolTip}
                                                </h3>
                                        }
                                    </div>
                                }
                            </div>
                            <div className="columns m-0">
                                <div className="column pt-1 pb-1">
                                    <h5 className="subtitle is-6 pt-0 mt-0" style={{color: 'transparent'}}>.</h5>
                                </div>
                            </div>
                            {/*
            <div className="columns m-0 p-4">
              <div className="column pt-1 pb-1 has-text-right">
                  <button type="button" className="button remove-btn" onClick={()=>DISPATCH({confirmDialogId: Enums.confirmDialog.customModelSelect})} >
                    <span className="no-side-margins"> Change Character </span>
                  </button>
              </div>
            </div>
            */}
                        </div>
                }
            </>
        )
    }

    function changeRootAtOrigin(e, isRerun = false) {
        const numModels = [
            ...STATE.accountTotals.platformCharactersList,
            ...STATE.accountTotals.charactersList,
        ];
        const model = isRerun ? STATE.rerunSettings.customModelInfo : STATE.animJobSettings.customModelInfo
        const checked = e.target.checked

        const jobInfo = getJobDetailsById(STATE.animJobId)
        const newModel = useRootAtOriginEmulation(checked, numModels, model)

        let state
        if (isRerun) {
            if (jobInfo.customModel === "multiple") {
                const customMultiPersonModel = jobInfo.customMultiPersonModel
                const newModelsData = customMultiPersonModel.map(item => {
                    const model = numModels.findIndex((model) => model.id === item.modelId);
                    const newModel = useRootAtOriginEmulation(
                        e.target.checked,
                        numModels,
                        model
                      );
                    return {
                        faceDataType: newModel.faceDataType,
                        handDataType: newModel.handDataType,
                        modelId: newModel.id,
                        trackingId: item.trackingId,
                    }
                });
                state = {
                    rerunSettings:
                        {
                            ...STATE.rerunSettings,
                            ...{
                                rootJointAtOrigin: e.target.checked ? 1 : 0,
                                modelsData: STATE.rerunRootJointAtOrigin
                                    ? newModelsData
                                    : e.target.checked
                                    ? newModelsData
                                    : customMultiPersonModel,
                            },
                    }
                }
            } else {
                const defaultModel = {id : jobInfo.customModel}
                state = {
                    rerunSettings:
                        {
                            ...STATE.rerunSettings,
                            ...{
                                rootJointAtOrigin: e.target.checked ? 1 : 0,
                                customModelInfo: STATE.rerunRootJointAtOrigin
                                    ? newModel
                                    : e.target.checked
                                    ? newModel
                                    : defaultModel,
                            },
                    }
                }
            }
        } else {
            state = {
                animJobSettings:
                {
                    ...STATE.animJobSettings,
                    ...{
                        rootJointAtOrigin: (e.target.checked ? 1 : 0),
                        customModelInfo: newModel
                    }
                }
            }
        }
        DISPATCH(state)
    }

    function buildAnimationSettingsArea() {

        // Multi-Person Tracking warning tips
        // check for face/head Rotation Tracking
        // check for Hand Rotation Tracking
        let isRobloxModel = false
        let HandTrackingDisabled = false
        let FaceTrackingSupport = true
        let AllFaceTrackingSupport = 0
        let HandTrackingSupport = true
        let AllHandTrackingSupport = 0
        const MultiPersonUnsupportTip = '<div class="dm-white">The selected role does not support this function<div/>'
        const MultiPersonRootJointOriginTip = '<div class="dm-white">Please check the selected characters<div/>'
        // single person
        let faceSupported = false
        let handSupported = false

        let faceOrHeadRotationTrackingTipText = ''
        let faceOrHeadRotationLabel = 'Face Tracking'
        if (multiPersonTracking) {
            faceSupported = true
            handSupported = true

            const numModels = [
                ...STATE.accountTotals.platformCharactersList,
                ...STATE.accountTotals.charactersList,
            ];
            multiPersonCharacter.forEach(character => {
                const models = numModels.find(item => item.id === character.modelId)
                const { faceDataType, handDataType, name } = models
                if (faceDataType !== 1) {
                    FaceTrackingSupport = false
                }
                if (name !== Enums.robloxModelId) {
                    AllFaceTrackingSupport++
                }
                if (handDataType !== 1) {
                    HandTrackingSupport = false
                } else {
                    AllHandTrackingSupport++
                }
            })

            faceOrHeadRotationTrackingTipText = FaceTrackingSupport ? Enums.toolTipFaceTrack + Enums.toolTipHeadTrack : Enums.toolTipFaceTrack + Enums.toolTipHeadTrack + MultiPersonUnsupportTip
            faceOrHeadRotationLabel = 'Face/Head Rotation Tracking'

            isRobloxModel = AllFaceTrackingSupport === 0
            HandTrackingDisabled = AllHandTrackingSupport === 0
        } else {
            if (!STATE.animJobSettings.customModelInfo.id) {
                faceSupported = handSupported = true
            } else if (STATE.animJobSettings.customModelInfo.name === Enums.robloxModelId) {
                isRobloxModel = true
            } else{
                const checkedModel = checkCustomCharacterFeatureCompatiabilityIfNecessary(STATE.animJobSettings.customModelInfo.id)
                if (checkedModel) {
                    faceSupported = checkedModel.faceDataType === 1
                    handSupported = checkedModel.handDataType === 1
                }
            }
            STATE.animJobSettings.trackHand = handSupported && STATE.animJobSettings.trackHand

            faceOrHeadRotationTrackingTipText = faceSupported ? Enums.toolTipFaceTrack : Enums.toolTipHeadTrack
            faceOrHeadRotationLabel = faceSupported ? "Face Tracking" : "Head Rotation Tracking"

            HandTrackingDisabled = !handSupported
        }

        const footLockOptions = checkFeatureAvailability(STATE.subscriptionInfo.name, Enums.featureLockNames.footLocking)

        let footlockDropDownData = []
        Object.values(Enums.footLockMode).forEach((value, index) => {
        footlockDropDownData.push({ value: value, available: footLockOptions[index] })
        })
        let fallbackPoseDropDownData = []
        Object.values(Enums.fallbackPose).forEach((value, index) => {
        fallbackPoseDropDownData.push({ value: value, available: true })
        })

        return (
            <div className="columns">
                <div className="column has-text-left">
                    <div className="columns mt-1 mb-1">
                        <div className={!multiPersonTracking ? "column " : "column multiPerson unsupport"}>
                            <YesNoSlider
                                label="Root Joint at Origin (Legacy)"
                                onChangeFunc={(event) => changeRootAtOrigin(event)}
                                isTipHtml={true}
                                tipText={!multiPersonTracking ? Enums.toolTipRootJoint : Enums.toolTipRootJoint + MultiPersonRootJointOriginTip}
                                tipId="root-joint-at-origin"
                                isDisabled={multiPersonTracking && !multiPersonCharacter.length}
                                value={STATE.animJobSettings.rootJointAtOrigin}
                            />
                        </div>
                        <div className="column">
                            <YesNoSlider
                                label="Physics Filter"
                                onChangeFunc={(event) => DISPATCH({animJobSettings: {...STATE.animJobSettings, ...{'physicsSim': event.target.checked}}})}
                                tipText={Enums.toolTipPhysSim}
                                tipId="phys-filter"
                                isDisabled={false}
                                value={STATE.animJobSettings.physicsSim}
                            />
                        </div>
                    </div>
                    <div className="columns mt-1 mb-1">
                        <div className="column">
                            <DMDropDown
                                label={Enums.dropDownLabels.footLockMode}
                                value={STATE.animJobSettings.footLockMode}
                                onChange={changeFootLockingSelect}
                                data={footlockDropDownData}
                                tipText={Enums.tooltipFootLocking}
                                isTipHtml={true}
                                isDisabled={false}
                            />
                        </div>
                        <div className={FaceTrackingSupport ? "column" : "column multiPerson unsupport"}>
                            <YesNoSlider
                                label={faceOrHeadRotationLabel}
                                onChangeFunc={(event) => {
                                    DISPATCH({
                                        animJobSettings: {
                                            ...STATE.animJobSettings,
                                            ...{'trackFace': (event.target.checked ? 1 : 0)}
                                        }
                                    })
                                }}
                                isDisabled={isRobloxModel}
                                isTipHtml={true}
                                tipText={faceOrHeadRotationTrackingTipText}
                                tipId="face-track"
                                value={isRobloxModel ? false : STATE.animJobSettings.trackFace}
                            />
                        </div>
                    </div>
                    <div className="columns mt-1 mb-1">
                        <div className="column">
                            <DMDropDown
                                label={Enums.dropDownLabels.fallbackPose}
                                value={STATE.animJobSettings.fallbackPose}
                                onChange={changeFallbackPoseSelect}
                                data={fallbackPoseDropDownData}
                                tipText={Enums.tooltipFallbackPose}
                                isTipHtml={true}
                                isDisabled={false}
                            />
                        </div>
                        <div className={HandTrackingSupport ? "column" : "column multiPerson unsupport"}>
                            <YesNoSlider
                                label={"Hand Tracking (Beta)"}
                                onChangeFunc={(event) => DISPATCH({animJobSettings: {...STATE.animJobSettings, ...{'trackHand': (event.target.checked ? 1 : 0)}}})}
                                isDisabled={HandTrackingDisabled}
                                isTipHtml={true}
                                tipText={HandTrackingSupport ? Enums.toolTipHandTrack : Enums.toolTipHandTrack + MultiPersonUnsupportTip}
                                tipId="hand-track"
                                value={HandTrackingDisabled ? false : STATE.animJobSettings.trackHand}
                            />
                        </div>
                    </div>
                    <div className="columns mt-1 mb-1">
                        <div className="column">
                            {buildPoseFilteringSelect(STATE.animJobSettings)}
                        </div>
                        <div className="column">
                            <YesNoSlider
                                label="Upper Body Only"
                                onChangeFunc={(event) => DISPATCH({animJobSettings: {...STATE.animJobSettings, ...{'upperBodyOnly': (event.target.checked ? 1 : 0)}}})}
                                tipText={Enums.toolTipUpperBodyOnly}
                                tipId="upper-body-only"
                                isDisabled={false}
                                value={STATE.animJobSettings.upperBodyOnly}
                            />
                        </div>
                    </div>
                    <div className="columns mt-1 mb-1">
                        <div className="column">
                            {buildSpeedMultiplierSelect(STATE.animJobSettings)}
                        </div>
                        <div className="column">
                            {buildEyeTrackingSensitivitySelect(STATE.animJobSettings, STATE.animJobSettings.trackFace, faceSupported)}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    function settingsDisabledShadow(isRerun, isVideoSet) {
        const jobSettings = isRerun ? STATE.rerunSettings : STATE.animJobSettings
        const shouldEnableShadow = jobSettings.backdrop === Enums.Backdrop.environments

        if (isVideoSet) {
            if (!jobSettings.formats.mp4) return true
            return shouldEnableShadow
        } else {
            if (!jobSettings.formats.gif && !jobSettings.formats.jpg && !jobSettings.formats.png) return true
            return shouldEnableShadow
        }
    }

    function buildVideoSettingsArea() {
        let cameraSettingsDropDownData = []
        Object.values(Enums.cameraMotionSettings).forEach((value) => {
            cameraSettingsDropDownData.push({value: value, available: true})
        })
        const mp4BackDropDownData = createMp4BackOptions(jobTypes.animation)

        const disabledShadow = settingsDisabledShadow(false, true)

        return (
            <div className="columns" style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2,minmax(0,1fr))'
            }}>
                <div className="column has-text-left ml-0">
                    <div className="column pt-0">
                        <YesNoSlider
                            label={mp4EnableOutputTitle}
                            onChangeFunc={(event) => enableImageOrVideoType('mp4', event.target.checked)}
                            tipText={Enums.toolTipMp4}
                            tipId="mp4-enable"
                            isDisabled={!STATE.animJobSettings.formats.fbx}
                            value={STATE.animJobSettings.formats.mp4}
                        />
                    </div>
                    <div className="column">
                        <DMDropDown
                            label={Enums.dropDownLabels.SelectBkgdOption}
                            value={STATE.animJobSettings.backdrop}
                            onChange={changeCustomBkgdSelect}
                            data={mp4BackDropDownData}
                            tipText={Enums.toolTipMp4BackType}
                            isTipHtml={true}
                            isDisabled={!STATE.animJobSettings.formats.mp4}
                        />
                    </div>
                    <div className="column">
                        <YesNoSlider
                            label={mp4EnableShadowTitle}
                            onChangeFunc={(event) => DISPATCH({animJobSettings: {...STATE.animJobSettings, ...{'shadow': event.target.checked}}})}
                            tipText={Enums.toolTipMp4Shadow}
                            tipId="mp4-shadow"
                            isDisabled={disabledShadow}
                            value={disabledShadow ? false : STATE.animJobSettings.shadow}
                        />
                    </div>
                    <div className="column max-col-height has-text-left">
                        <YesNoSlider
                            label={mp4EnableAudioTitle}
                            value={(STATE.animJobSettings.formats.mp4 ? STATE.animJobSettings.includeAudio : false)}
                            onChangeFunc={(event) => DISPATCH({animJobSettings: {...STATE.animJobSettings, ...{'includeAudio': event.target.checked}}})}
                            tipText={Enums.toolTipIncludeAud}
                            tipId="mp4-include-audio"
                            isDisabled={!STATE.animJobSettings.formats.mp4}
                        />
                    </div>
                    <div className="column max-col-height is-align-items-center is-flex">
                        {buildBackgroundColorSelect()}
                    </div>
                    <div className="column">
                        <DMDropDown
                            label={Enums.dropDownLabels.cameraMotion}
                            value={STATE.animJobSettings.camMode}
                            onChange={changeMp4OutputCameraMotion}
                            data={cameraSettingsDropDownData}
                            tipText={Enums.tooltipMp4Camera}
                            isDisabled={!STATE.animJobSettings.formats.mp4}
                            isTipHtml={true}
                        />
                    </div>
                    <div className="column max-col-height">
                        <DMSlider
                            label={Enums.sliderLabels.camHorizontalAngle}
                            value={STATE.animJobSettings.camHorizontalAngle}
                            minValue={-90}
                            maxValue={90}
                            step={5}
                            onChange={changeMp4OutputCameraAngle}
                            isDisabled={!STATE.animJobSettings.formats.mp4}
                            isNew={true}
                            tipId="mp4-cam-angle-tip"
                            tipText={Enums.toolTipVideoCameraHorizontalAngle}
                            darkMark={false}
                        />
                    </div>
                </div>
                <div className='mt-0' style={{width: '100%'}}>
                    <Backdrop />
                </div>
            </div>
        )
    }

    /////////////////////////////////////////////////////////////////////
    function buildAnimationJobConfigScreen() {
        return (
            <React.Fragment>
                <div className="buttons has-addons JobConfigScreen">
                    <div className='column p-0 is-flex is-justify-content-flex-end'>
                        <button
                            onClick={() => setActiveJobMenu(Enums.jobMenu.animSettings)}
                            className={"button button-first " + (activeJobMenu === Enums.jobMenu.animSettings ? "is-link is-selected" : "is-light")}
                            >
                            Animation Output
                        </button>
                    </div>
                    <div className='column p-0 is-flex is-justify-content-flex-start'>
                        <button
                            onClick={() => setActiveJobMenu(Enums.jobMenu.videoSettings)}
                            className={"button button-last " + (activeJobMenu === Enums.jobMenu.videoSettings ? "is-link is-selected" : "is-light")}
                        >
                            Video Output
                        </button>
                    </div>
                </div>
                {
                    activeJobMenu === Enums.jobMenu.animSettings
                        ?
                        buildAnimationSettingsArea()
                        :
                        buildVideoSettingsArea()
                }
            </React.Fragment>
        )
    }

    /////////////////////////////////////////////////////////////////////
    function createMp4BackOptions(jobType) {
        let mp4BackDropDownData = []
        Object.values(Enums.Backdrop).forEach((value, index) => {
            if( jobType !== jobTypes.animation || value !== Enums.Backdrop.transparent ){
                mp4BackDropDownData.push({value: value, available: true})
            }
        })
        return mp4BackDropDownData
    }

    /////////////////////////////////////////////////////////////////////
    function buildStaticPoseJobConfigScreen() {
        return (
            <React.Fragment>
            <div className="buttons is-centered has-addons JobConfigScreen">
                    <div className='column p-0 is-flex is-justify-content-flex-end'>
                        <button
                            onClick={() => setActiveJobMenu(Enums.jobMenu.animSettings)}
                            className={"button button-first " + (activeJobMenu === Enums.jobMenu.animSettings ? "is-link is-selected" : "is-light")}
                        >
                            Pose Output
                        </button>
                    </div>
                    <div className='column p-0 is-flex is-justify-content-flex-start'>
                        <button
                            onClick={() => setActiveJobMenu(Enums.jobMenu.videoSettings)}
                            className={"button button-last " + (activeJobMenu === Enums.jobMenu.videoSettings ? "is-link is-selected" : "is-light")}
                        >
                            Image Output
                        </button>
                    </div>
            </div>
            {
                activeJobMenu === Enums.jobMenu.animSettings
                    ?
                    buildPoseSettingsArea()
                    :
                    buildImageSettingsArea()
            }
        </React.Fragment>
        )
    }

    function buildPoseSettingsArea() {
        let fallbackPoseDropDownData = []
        Object.values(Enums.fallbackPose).forEach((value, index) => {
            fallbackPoseDropDownData.push({value: value, available: true})
        })
        // Multi-Person Tracking warning tips
        // check for face/head Rotation Tracking
        // check for Hand Rotation Tracking
        let isRobloxModel = false
        let HandTrackingDisabled = false
        let FaceTrackingSupport = true
        let AllFaceTrackingSupport = 0
        let HandTrackingSupport = true
        let AllHandTrackingSupport = 0
        const MultiPersonUnsupportTip = '<div class="dm-white">The selected role does not support this function<div/>'
        const MultiPersonRootJointOriginTip = '<div class="dm-white">Please check the selected characters<div/>'
        // single person
        let faceSupported = false
        let handSupported = false

        let faceOrHeadRotationTrackingTipText = ''
        let faceOrHeadRotationLabel = 'Face Tracking'
        if (multiPersonTracking) {
            faceSupported = true
            handSupported = true

            const numModels = [
                ...STATE.accountTotals.platformCharactersList,
                ...STATE.accountTotals.charactersList,
            ];
            multiPersonCharacter.forEach(character => {
                const models = numModels.find(item => item.id === character.modelId)
                const { faceDataType, handDataType, name } = models
                if (faceDataType !== 1) {
                    FaceTrackingSupport = false
                }
                if (name !== Enums.robloxModelId) {
                    AllFaceTrackingSupport++
                }
                if (handDataType !== 1) {
                    HandTrackingSupport = false
                } else {
                    AllHandTrackingSupport++
                }
            })

            faceOrHeadRotationTrackingTipText = FaceTrackingSupport ? Enums.toolTipFaceTrack + Enums.toolTipHeadTrack : Enums.toolTipFaceTrack + Enums.toolTipHeadTrack + MultiPersonUnsupportTip
            faceOrHeadRotationLabel = 'Face/Head Rotation Tracking'

            isRobloxModel = AllFaceTrackingSupport === 0
            HandTrackingDisabled = AllHandTrackingSupport === 0
        } else {
            if (!STATE.animJobSettings.customModelInfo.id) {
                faceSupported = handSupported = true
            }else if (STATE.animJobSettings.customModelInfo.name === Enums.robloxModelId) {
                isRobloxModel = true
            } else{
                const checkedModel = checkCustomCharacterFeatureCompatiabilityIfNecessary(STATE.animJobSettings.customModelInfo.id)
                if (checkedModel) {
                    faceSupported = checkedModel.faceDataType === 1
                    handSupported = checkedModel.handDataType === 1
                }
            }
            STATE.animJobSettings.trackHand = handSupported && STATE.animJobSettings.trackHand

            faceOrHeadRotationTrackingTipText = faceSupported ? Enums.toolTipFaceTrack : Enums.toolTipHeadTrack
            faceOrHeadRotationLabel = faceSupported ? "Face Tracking" : "Head Rotation Tracking"

            HandTrackingDisabled = !handSupported
        }

        return (
            <div className="mb-2 pb-4">
                <div className="columns mt-1 mb-1">
                    <div className={HandTrackingSupport ? "column" : "column multiPerson unsupport"}>
                        <YesNoSlider
                            label={"Hand Tracking (Beta)"}
                            onChangeFunc={(event) => DISPATCH({animJobSettings: {...STATE.animJobSettings, ...{'trackHand': (event.target.checked ? 1 : 0)}}})}
                            isTipHtml={true}
                            tipText={HandTrackingSupport ? Enums.toolTipHandTrack : Enums.toolTipHandTrack + MultiPersonUnsupportTip}
                            tipId="hand-track"
                            isDisabled={HandTrackingDisabled}
                            value={HandTrackingDisabled ? false : STATE.animJobSettings.trackHand}
                        />
                    </div>
                    <div className={FaceTrackingSupport ? "column" : "column multiPerson unsupport"}>
                        <YesNoSlider
                            label={faceOrHeadRotationLabel}
                            onChangeFunc={(event) => {
                                DISPATCH({
                                    animJobSettings: {
                                        ...STATE.animJobSettings,
                                        ...{'trackFace': (event.target.checked ? 1 : 0)}
                                    }
                                })
                            }}
                            isDisabled={isRobloxModel}
                            isTipHtml={true}
                            tipText={faceOrHeadRotationTrackingTipText}
                            tipId="face-track"
                            value={isRobloxModel ? false : STATE.animJobSettings.trackFace}
                        />
                    </div>
                </div>

                <div className="columns mt-1 mb-1">
                    <div className={!multiPersonTracking ? "column " : "column multiPerson unsupport"}>
                        <YesNoSlider
                            label="Root Joint at Origin (Legacy)"
                            onChangeFunc={(event) => changeRootAtOrigin(event)}
                            isTipHtml={true}
                            tipText={!multiPersonTracking ? Enums.toolTipRootJoint : Enums.toolTipRootJoint + MultiPersonRootJointOriginTip}
                            tipId="root-joint-at-origin"
                            isDisabled={multiPersonTracking && !multiPersonCharacter.length}
                            value={STATE.animJobSettings.rootJointAtOrigin}
                        />
                    </div>
                    <div className="column">
                        <YesNoSlider
                            label="Physics Filter"
                            onChangeFunc={(event) => DISPATCH({animJobSettings: {...STATE.animJobSettings, ...{'physicsSim': event.target.checked}}})}
                            tipText={Enums.toolTipPhysSim}
                            tipId="phys-filter"
                            isDisabled={false}
                            value={STATE.animJobSettings.physicsSim}
                        />
                    </div>
                </div>
                <div className="columns mt-1 mb-1">
                    <div className="column">
                        <YesNoSlider
                            label={textFloating}
                            onChangeFunc={(event) => DISPATCH({animJobSettings: {...STATE.animJobSettings, ...{'footLockMode': event.target.checked ? Enums.footLockMode.never : Enums.footLockMode.always}}})}
                            tipText={Enums.toolTipStaticPoseFootLocking}
                            tipId="floating"
                            isDisabled={false}
                            isTipHtml={true}
                            value={STATE.animJobSettings.footLockMode == Enums.footLockMode.never}
                        />
                    </div>
                    <div className="column">
                        <YesNoSlider
                            label="Upper Body Only"
                            onChangeFunc={(event) => DISPATCH({animJobSettings: {...STATE.animJobSettings, ...{'upperBodyOnly': (event.target.checked ? 1 : 0)}}})}
                            tipText={Enums.toolTipUpperBodyOnly}
                            tipId="upper-body-only"
                            isDisabled={false}
                            value={STATE.animJobSettings.upperBodyOnly}
                        />
                    </div>
                </div>
                <div className="columns mt-1 mb-1">
                    <div className="column">
                        <DMDropDown
                            label={Enums.dropDownLabels.fallbackPose}
                            value={STATE.animJobSettings.fallbackPose}
                            onChange={changeFallbackPoseSelect}
                            data={fallbackPoseDropDownData}
                            tipText={Enums.tooltipFallbackPose}
                            isTipHtml={true}
                            isDisabled={false}
                            outerMarginClass='is-flex is-align-items-center'
                        />
                    </div>
                </div>
            </div>
        )
    }
    function buildImageSettingsArea() {
        const mp4BackDropDownData = createMp4BackOptions(jobTypes.staticPose)
        const disabledShadow = settingsDisabledShadow(false, false)

        return (
            <div className="mb-2 pb-4 columns">
                <div className="mt-1 mb-1">
                    <div className="column pt-0">
                        <YesNoSlider
                            label="JPG Output"
                            onChangeFunc={(event) => enableImageOrVideoType('jpg', event.target.checked)}
                            tipText={Enums.toolTipJpg}
                            tipId="jpg-output"
                            isDisabled={STATE.animJobSettings.backdrop === Enums.Backdrop.transparent}
                            value={STATE.animJobSettings.formats.jpg}
                        />
                    </div>
                    <div className="column">
                        <YesNoSlider
                            label="GIF Output"
                            onChangeFunc={(event) => enableImageOrVideoType('gif', event.target.checked)}
                            tipText={Enums.toolTipGif}
                            tipId="gif-output"
                            isDisabled={STATE.animJobSettings.backdrop === Enums.Backdrop.transparent}
                            value={STATE.animJobSettings.formats.gif}
                        />
                    </div>
                    <div className="column">
                        <YesNoSlider
                            label="PNG Output"
                            onChangeFunc={(event) => enableImageOrVideoType('png', event.target.checked)}
                            tipText={Enums.toolTipPng}
                            tipId="png-output"
                            isDisabled={STATE.animJobSettings.backdrop === Enums.Backdrop.transparent}
                            value={STATE.animJobSettings.formats.png}
                        />
                    </div>
                    <div className="column">
                        <YesNoSlider
                            label={mp4EnableShadowTitle}
                            onChangeFunc={(event) => DISPATCH({animJobSettings: {...STATE.animJobSettings, ...{'shadow': event.target.checked}}})}
                            tipText={Enums.toolTipImageShadow}
                            tipId="enable-mp4-shadow"
                            isDisabled={disabledShadow}
                            value={disabledShadow ? false : STATE.animJobSettings.shadow}
                        />
                    </div>
                    <div className="column">
                        <DMDropDown
                            label={Enums.dropDownLabels.SelectBkgdOption}
                            value={STATE.animJobSettings.backdrop}
                            onChange={changeCustomBkgdSelect}
                            data={mp4BackDropDownData}
                            tipText={Enums.toolTipPoseBackType}
                            isTipHtml={true}
                            isDisabled={!((STATE.animJobSettings.formats.gif || STATE.animJobSettings.formats.jpg || STATE.animJobSettings.formats.png))}
                            outerMarginClass='is-flex is-align-items-center has-text-left'
                        />
                    </div>
                    <div className="column max-col-height is-align-items-center is-flex has-text-left">
                        {buildBackgroundColorSelect()}
                    </div>
                </div>
                <Backdrop />
            </div>
        )
    }
    /////////////////////////////////////////////////////////////////////
    // builds the Recommendations / Best Results info area
    /////////////////////////////////////////////////////////////////////
    function InformationArea() {
        return (
            <div className="columns rounded-corners has-background-warning-light info-area mx-4 mt-6">
                <div className="column">
                    <div className="columns">
                        <div className="column has-text-centered">
                            <h3 className="title is-4">For best results:</h3>
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column has-text-left" style={{marginTop: '10px'}}>
                            <div className="content">
                                <p className="subtitle is_4"><strong>Camera</strong>: Should be stationary and parallel
                                    to your subject</p>
                                <p className="subtitle is_4"><strong>Character Placement</strong>: The entire body or
                                    the upper body from head to waist should be visible and located 2-6 meters (6-20
                                    feet) from the camera</p>
                                <p className="subtitle is_4"><strong>Lighting</strong>: Neutral lighting with high
                                    contrast between the subject and the background is recommended</p>
                            </div>
                        </div>

                        <div className="column has-text-left" style={{marginTop: '10px'}}>
                            <div className="content">
                                <p className="subtitle is_4"><strong>Occlusion</strong>: The subject should not be
                                    occluded by any objects and there should be a single subject in the video clip or
                                    image</p>
                                <p className="subtitle is_4"><strong>Clothing</strong>: Do not wear loose clothing or
                                    clothing that covers key joints like knees and elbows</p>
                                <p className="subtitle is_4"><strong>Face Tracking</strong>: Face Tracking is supported
                                    for full body and upper body tracking modes, though for better results upper body is
                                    recommended</p>
                                <p className="subtitle is_4"><strong>Hand Tracking</strong>: Hand Tracking is supported
                                    for full body and upper body tracking modes, though for better results upper body is
                                    recommended</p>
                            </div>
                        </div>
                    </div>
                    <div className="is-divider"
                         style={{width: '300px', height: '2px', color: 'gray', marginBottom: '10px'}}></div>
                    <div className="columns">
                        <div className="column has-text-centered">
                            <h3 className="subtitle is-5 mb-3 is-underlined">Need Help?</h3>
                            <h4 className="subtitle is-5">If you have questions or need further support please <a href={`${process.env.REACT_APP_COMPANY_SITE}/support`} target="_blank"> contact us </a></h4>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function buildBackgroundColorSelect() {
        // disable if state doesn't output image/video file
        const includeCheck = verifyShowColorPicker()
        
        const toolTipMp4BackColor = STATE.animJobSettings.jobType === Enums.jobTypes.staticPose ? Enums.toolTipPoseBackColor : Enums.toolTipMp4BackColor
        return (
            <div className="columns">
                <div className="column">
                    <DMDropDownColorPicker
                        className="mr-4"
                        label="Background Color"
                        data={(!STATE.animJobSettings.bgColor || STATE.animJobSettings.bgColor === 'N/A') ? defaultGSColor : STATE.animJobSettings.bgColor}
                        value={STATE.animJobSettings.bgColor}
                        onChange={handleBkgdColorChange}
                        isDisabled={includeCheck}
                        tipText={toolTipMp4BackColor}
                        isTipHtml={true}
                        rightAlign={true}
                    />
                </div>
            </div>
        )
    }
    function verifyShowColorPicker() {
        // disable if state doesn't output image/video file
        const includeBackdropCheck =
          STATE.animJobSettings.backdrop !== "studio" &&
          STATE.animJobSettings.backdrop !== "solid";
    
        const includeMp4Check =
          STATE.animJobSettings.jobType === Enums.jobTypes.staticPose
            ? !(
                STATE.animJobSettings.formats.gif ||
                STATE.animJobSettings.formats.jpg ||
                STATE.animJobSettings.formats.png
              )
            : !STATE.animJobSettings.formats.mp4;
    
        return includeMp4Check || includeBackdropCheck;
    }

    /////////////////////////////////////////////////////////////////////
    function updateCustomModelCompatibilityDetectionRsults(modelId, detectionStatus, faceDataType, handDataType) {
        let newCharactersList = []
        STATE.accountTotals.charactersList.forEach(item => {
            const modelObject = {
                id: item.id,
                name: item.name,
                thumb: item.thumb,
                rigId: item.rigId,
                compatibilityDetectionStatus: item.id === modelId ? detectionStatus : item.compatibilityDetectionStatus,
                faceDataType: item.id === modelId ? faceDataType : item.faceDataType,
                handDataType: item.id === modelId ? handDataType : item.handDataType,
                ctime: item.ctime,
                mtime: item.mtime,
                date: item.data,
                thumbImg: item.thumbImg
            }
            newCharactersList.push(modelObject)
        })
        let newAccountTotals = STATE.accountTotals
        newAccountTotals.charactersList = newCharactersList
        DISPATCH({accountTotals: newAccountTotals})
    }

    /////////////////////////////////////////////////////////////////////
    function checkCustomCharacterFeatureCompatiabilityIfNecessary(modelId) {
        let model = getModelDataById(modelId)
        if (!model) {
            return null
        }
        if (model.compatibilityDetectionStatus < 0) {
            return null;
        }
        if (model.compatibilityDetectionStatus > 2) {
            return model;
        }
        updateCustomModelCompatibilityDetectionRsults(modelId, -1, model.faceDataType, model.handDataType)
        checkCustomCharacterFeatureCompatiability(modelId, true).then(res => {
            if (res) {
                updateCustomModelCompatibilityDetectionRsults(modelId, 3, res.data.faceDataType, res.data.handDataType)
            }
        })
        return null
    }

    /////////////////////////////////////////////////////////////////////
    // Builds the drag & drop tile area for adding a video clip
    /////////////////////////////////////////////////////////////////////
    function buildAddMotionClipArea() {

        const addIcon = STATE.animJobSettings.jobType === Enums.jobTypes.animation ? iconMotionClip : iconImage
        const addText = STATE.animJobSettings.jobType === Enums.jobTypes.animation ? textAddMotionClip : textAddImage
        const acceptedTypes = STATE.animJobSettings.jobType === Enums.jobTypes.animation ? Enums.textAcceptedClipTypes : Enums.textAcceptedImgTypes

        return (
            <div className="column box disabled-switch m-3 p-4">
                <div className="columns m-0">
                    <div className="column has-text-left bottom-border">
                        <span className="title is-5 has-text-white"><span className="icon"
                                                                          style={{marginRight: '10px'}}><i
                            className={addIcon}></i></span><span>{addText}</span></span>
                    </div>
                </div>
                <div className="columns m-0">
                    <div className="column mt-0" style={{minHeight: '175px'}}>
                        <DragZone onFilesAdded={uploadOnchange} animJobSettings={STATE.animJobSettings}/>
                    </div>
                </div>
                <div className="columns m-0">
                    <div className="column has-text-left pt-1 pb-1">
                        <h5 className="subtitle is-6 pt-0 mt-0 has-text-white"> {acceptedTypes} </h5>
                    </div>
                </div>
            </div>
        )
    }

    /////////////////////////////////////////////////////////////////////
    // Displays users animation minutes balance/usage
    /////////////////////////////////////////////////////////////////////
    function buildRemainingMinutesMeter(percentage) {
        // convert usage percent to remaining
        const remainingPct = 100 - percentage
        let color = null
        if (STATE.accountTotals.remainingTimeInSeconds <= Enums.accountPlansInfo[0].minsInt * 60) {
            color = '#fab03c'
        } else {
            color = setProductSkuColor()
        }

        let remainingTime = String(STATE.currentBillCycleInfo.remainingMonthlyTime)
        if (remainingTime.substring(0, 3) === "00:") {
            // only show minutes and seconds if less than 1 hour of time
            remainingTime = remainingTime.substring(3, remainingTime.length)
        }
        return (
            <ProgressProvider valueStart={0} valueEnd={Math.max(0, remainingPct)}>
                {(value) =>
                    <CircularProgressbarWithChildren className=""
                                                     value={value}
                                                     text={remainingPct + "%"}
                                                     maxValue={100}
                                                     minValue={0}
                                                     strokeWidth={12}
                                                     circleRatio={0.75}
                                                     styles={buildStyles({
                                                         strokeLinecap: 'butt',
                                                         rotation: 1 / 2 + 1 / 8,
                                                         textSize: '16px',
                                                         pathTransitionDuration: 0.75,
                                                         pathColor: (percentage >= 100) ?
                                                             '#f14668' : setProductSkuColor(),
                                                         textColor: 'white',
                                                         trailColor: '#d6d6d6',
                                                         backgroundColor: '#fff'
                                                     })}
                    >
                    </CircularProgressbarWithChildren>
                }
            </ProgressProvider>
        )
    }

    /////////////////////////////////////////////////////////////////////
    // Calculates the minutes meter path color based on percentage
    /////////////////////////////////////////////////////////////////////
    function setProductSkuColor() {
        let pathColor = Enums.accountPlansInfo[0].skuColor
        if (STATE.subscriptionInfo.status !== 'active' && STATE.subscriptionInfo.status !== 'past_due') {
            // default path color used for Freemium accounts:
            document.body.style.setProperty('--dm-progress-color', pathColor)
            return pathColor
        } else {
            for (let i = 0; i < Enums.accountPlansInfo.length; i++) {
                if (Enums.accountPlansInfo[i].name === STATE.subscriptionInfo.name) {
                    pathColor = Enums.accountPlansInfo[i].skuColor
                }
            }
            document.body.style.setProperty('--dm-progress-color', pathColor)
            return pathColor
        }
    }

    /////////////////////////////////////////////////////////////////////
    function getProductColorCSSClass() {
        let productSkuColorClass = 'dm-progress-bar-freemium'
        if (!STATE.subscriptionInfo || !STATE.subscriptionInfo.name) {
            return productSkuColorClass
        }
        switch (STATE.subscriptionInfo.name) {
            case Enums.accountPlansInfo[1].name:
                productSkuColorClass = 'dm-progress-bar-starter'
                break
            case Enums.accountPlansInfo[2].name:
                productSkuColorClass = 'dm-progress-bar-innovator'
                break
            case Enums.accountPlansInfo[3].name:
                productSkuColorClass = 'dm-progress-bar-professional'
                break
            case Enums.accountPlansInfo[4].name:
                productSkuColorClass = 'dm-progress-bar-studio'
                break
        }
        return productSkuColorClass
    }

    /////////////////////////////////////////////////////////////////////
    function resetVideoUploadInfo(cb) {
    }

    /////////////////////////////////////////////////////////////////////
    function checkJobDownloadHasCustomCharacter() {
        return ((STATE.animJobLinks && STATE.animJobLinks.downloadLinks) ? STATE.animJobLinks.downloadLinks.customMode : false)
    }

    /////////////////////////////////////////////////////////////////////
    function goToMotionClipSelectPage() {
        history.push(Enums.routes.Anim3dCreate)
    }

    /////////////////////////////////////////////////////////////////////
    function goToUploadCharacterPage() {
        history.push(Enums.routes.Anim3dModelUpload)
    }

    /////////////////////////////////////////////////////////////////////
    // redirects user to account closed page upon account de-activation
    /////////////////////////////////////////////////////////////////////
    function closedAccount() {
        console.log("Redirecting to account closed page.")
        DISPATCH({confirmDialogId: Enums.confirmDialog.none})
        removeLocalStorageData(() => {
            history.push(Enums.routes.ClosedAccount)
        })
    }

    ////////////////////////////////////////////////////////////////////
    // displayVideoValidationErrors() : Displays a user friendly dialog
    // that displays one or more errors re: input video validation or
    // various limits/features exceeded for the account/subscription.
    //
    // @param errorFlagsObj : An object of key/boolean fields indicating
    // which error(s) may have occurred.  For example, video is both
    // too long (maxDuration: true) and it exceed FPS limit (maxFps: true)
    ////////////////////////////////////////////////////////////////////
    function displayVideoValidationErrors(errorFlagsObj) {
        let tmpObj = STATE.inputVideoData
        if (!tmpObj || tmpObj === undefined) {
            tmpObj = {}
        }
        tmpObj.errorFlags = errorFlagsObj
        DISPATCH({
            isJobInProgress: false,
            currWorkflowStep: Enums.uiStates.initial,
            inputVideoData: tmpObj,
            confirmDialogId: Enums.confirmDialog.VideoValidationFailed,
            silentUploadInProgress: false,
            videoStorageUrl: null,
            videoFileName: null
        })
    }

    ////////////////////////////////////////////////////////////
    // Opens the rerun screen from library and preview pages
    //
    // @param rid : job id of the selected job to rerun
    ////////////////////////////////////////////////////////////
    async function displayReRunModal(rid) {
        const planData = await getUserPlanData(true)
        const userPlan = updateUserPlanData({...planData.data, ...{subcriptionInfo: STATE.subscriptionInfo}})
        const jobInfo = getJobDetailsById(rid)
        let initialRerunSettings = {...Enums.JOB_SETTINGS_TEMPLATE, ...jobInfo.settings}
        // need to deep copy
        let initialFormats = {...Enums.JOB_SETTINGS_TEMPLATE.formats, ...jobInfo.settings.formats}
        initialRerunSettings.formats = initialFormats

        initialRerunSettings.customModelInfo = {id: jobInfo.customModel}
        initialRerunSettings.rid = rid
        if (jobInfo.customModel === "multiple") {
            const res = await getLinksForJob(rid, true)
            const parsedLinks = await parseJobLinksData(res, rid)
            const animLinks = parsedLinks.animJobLinks
            initialRerunSettings.modelsData = animLinks.downloadLinks.modelsData
        }
        DISPATCH({
            animJobId: rid,
            rerunRootJointAtOrigin: initialRerunSettings.rootJointAtOrigin,
            accountTotals: userPlan.accountTotals,
            rerunSettings: initialRerunSettings,
            confirmDialogId: Enums.confirmDialog.reRunJobConfig
        })
    }

    function returnJobUserModelDetail (params) {
        if (params.rid === undefined) {
            if (multiPersonSelection || params.isMultiPersonJob) {
                params.customMultiPersonModel = multiPersonCharacter
            } else {
                params.modelInfo = STATE.animJobSettings.customModelInfo
            }
        }

        const jobUserModelDetail = {
            name: "Model Name",
            isCustomModel: false,
            isMultiPersonJob: multiPersonSelection || params.isMultiPersonJob,
            defaultCharacter: [],
            customCharacter: [],
        };

        const numModels = [
            ...STATE.accountTotals.platformCharactersList,
            ...STATE.accountTotals.charactersList,
        ];

        if (multiPersonSelection || params.isMultiPersonJob) {
            jobUserModelDetail.name = "Multi-Person";

            const findModelId = [];
            params.customMultiPersonModel.forEach((model) => {
              const findModels = numModels.find((item) => item.id === model.modelId);
              const models = findModels ? findModels : Enums.deletedModel;

              if (!findModelId.includes(models.id)) {
                findModelId.push(models.id);
                if (models.platform !== "custom") {
                  jobUserModelDetail.defaultCharacter.push(models);
                } else {
                  jobUserModelDetail.customCharacter.push(models);
                }
              }
            });
        } else {
            if (params.modelInfo === "standard") {
              Object.entries(Enums.characterModels).forEach(([key, value]) => {
                const findModels = numModels.find((item) => item.name === value.uiName);
                jobUserModelDetail.defaultCharacter.push(findModels);
              });
            } else if(params.modelInfo.platform !== "custom") {
              jobUserModelDetail.defaultCharacter.push(params.modelInfo);
            } else {
              jobUserModelDetail.customCharacter.push(params.modelInfo);

            }
        }

        return jobUserModelDetail
    }
    ////////////////////////////////////////////////////////////
    // Displays animation settings table for a given job. If
    // params.reRun is true then displays a third column for
    // changing the setting
    ////////////////////////////////////////////////////////////
    function buildAnimationSettingsTable(params) {
        const jobUserModelDetail = returnJobUserModelDetail(params)

        if (params.reRun) {
            return buildAnimSettingsRerunView(params, jobUserModelDetail)
        } else {
            return buildAnimSettingsSummaryView(params, jobUserModelDetail)
        }
    }

    ////////////////////////////////////////////////////////////
    // Displays job video output settings
    ////////////////////////////////////////////////////////////
    function buildVideoSettingsTable(params) {
        if (params.reRun) {
            return buildVideoSettingsRerunView(params)
        } else {
            return buildVideoSettingsSummaryView(params)
        }
    }

    ////////////////////////////////////////////////////////////
    // Builds section for animation output file formats
    ////////////////////////////////////////////////////////////
    function buildOutputFormatsTable(formats) {
        let formatsArray = formats.split(',')
        let contentList = []
        for (let i = 0; i < formatsArray.length; i++) {
            contentList.push(
                <div className="column m-1 py-5 fade-in has-text-centered has-text-weight-semibold"
                     key={formatsArray[i]}>
          <span className="icon dm-brand-font is-large fa-4x">
            <i className="fas fa-file-video" aria-hidden="true"></i>
          </span>
                    <div
                        className="subtitle mt-2 dm-brand-font has-text-weight-semibold is-uppercase">{formatsArray[i]}</div>
                </div>
            )
        }
        return (
            <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
                <thead>
                <tr>
                    <th className="has-background-info-light dm-brand-font" colSpan={formatsArray.length}>Output
                        Formats
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>
                        <div className="columns">
                            <div className={`column ${(contentList.length === 2 ? "is-6" : "")}`}>
                                <div className="columns">
                                    {contentList}
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        )
    }

    /////////////////////////////////////////////////////////////////////
    // Builds dynamic actions dropdown for each job row of the library
    // @param rid : ID of the job we're building the dropdown for
    // @param jobData : job settings and metadata for a given job
    /////////////////////////////////////////////////////////////////////
    function buildActionsDropDown(rid, jobData) {
        return (
            <div className="dropdown is-hoverable btn-shadow br-4 is-right">
                <div className="dropdown-trigger">
                    <button className="button preview-btn" onMouseDown={(e) => e.preventDefault()} aria-haspopup="true"
                            aria-controls="dropdown-menu">
                        <span className="icon is-small"><i className="fas fa-ellipsis-v fa-lg"></i></span>
                    </button>
                </div>
                <div className="dropdown-menu" id="dropdown-menu" role="menu">
                    <div className="dropdown-content has-text-left">
                        <a onClick={() => handleDownloadJobClick(rid)} className="dropdown-item">
                            Download Animations
                        </a>
                        <a onClick={() => displayJobSettingsModal(rid)} className="dropdown-item">
                            View Job Settings
                        </a>
                        <React.Fragment>
              <span>
                <a onClick={() => displayReRunModal(rid)} className="dropdown-item">
                  Rerun
                  <span className="dm-brand-font" style={{fontSize: '1rem'}}>
                    <i className="fas fa-info-circle ml-2"
                       data-for="tooltip-rerun"
                       data-border={true}
                       data-border-color="black"
                       data-tip
                       data-text-color="#2d4e77"
                       data-background-color="white">
                    </i>
                  </span>
                </a>
              </span>
                            <ReactTooltip className="tip-max-w" id="tooltip-rerun" place="left" effect="solid">
                                <div className="subtitle">
                                    <div dangerouslySetInnerHTML={{__html: Enums.tooltipReRun}}/>
                                </div>
                            </ReactTooltip>
                        </React.Fragment>
                        <hr className="mt-2 mb-2"/>
                        <a onClick={() => handleDeleteJobClick(rid, jobData.name, jobData.lengthRaw, jobData.size, jobData.dateRaw)}
                           className="dropdown-item has-text-danger">
                            Delete
                        </a>
                    </div>
                </div>
            </div>
        )
    }

    ////////////////////////////////////////////////////////////
    function displayJobSettingsModal(rid) {
        DISPATCH({animJobId: rid, confirmDialogId: Enums.confirmDialog.libraryJobSettings})
    }

    ////////////////////////////////////////////////////////////
    // renders a simple column based meter that gives the user
    // a visual idea of how much time the new anim will take
    // wrt to their remaining time for current cycle
    ////////////////////////////////////////////////////////////
    function buildTimeMeterForNewJob(settings, faceSupported, totalCredits) {
        // calculate percentage new anim will take out of user's remaining monthly time
        let newAnimTimePercent = 0
        let faceOrHead = multiPersonTracking ? "Face/Head Rotation" : faceSupported ? "Face" : "Head Rotation"
        let trackingFeatureList = 'Body Tracking'
        if (settings.trackFace) {
            if (settings.trackHand) {
                trackingFeatureList += `, ${faceOrHead} Tracking and Hand Tracking`
            } else {
                trackingFeatureList += ` and ${faceOrHead} Tracking`
            }
        } else if (settings.trackHand) {
            trackingFeatureList += ` and Hand Tracking`
        }

        if ((STATE.currentBillCycleInfo.remainingRounded - totalCredits) < 1) {
            newAnimTimePercent = 100
        } else {
            newAnimTimePercent = Math.ceil(totalCredits / STATE.currentBillCycleInfo.remainingRounded * 100)
        }

        const isStudioUser = STATE.subscriptionInfo.name === Enums.accountPlansInfo[Enums.accountPlansInfo.length - 2].name
        const creditsEnough = STATE.currentBillCycleInfo.remainingRounded >= totalCredits
        return (
            <React.Fragment>
                <div className="columns m-0 my-2 px-2 py-4" style={{height: '48px'}}>
                    <div className="column progress-time-remain">
                        <div className={newAnimTimePercent >= 100 ? "progress-time-new-full" : "progress-time-new"}
                             style={{width: newAnimTimePercent.toString() + "%"}}/>
                    </div>
                </div>
                <div className="columns m-0">
                    <div className="column px-3 has-text-left">
                        {
                            creditsEnough
                                ?
                                <div className="subtitle is-6">
                                    This animation will use <span
                                    className="dm-brand-font has-text-weight-semibold">{Math.ceil(totalCredits)} out of {STATE.currentBillCycleInfo.remainingRounded} </span> {isStudioUser ? "high priority " : ""} credit(s)
                                    for {trackingFeatureList} from your account balance
                                </div>
                                :
                                isStudioUser
                                    ?
                                    <div className="subtitle is-6">
                                        <span
                                            className="dm-brand-font has-text-weight-semibold">{STATE.currentBillCycleInfo.remainingRounded}</span> high
                                        priority credits remaining, <span
                                        className="dm-brand-font has-text-weight-semibold">{Math.ceil(totalCredits)} </span> needed,
                                        use unlimited credits instead.
                                    </div>
                                    :
                                    <div className="subtitle is-6">
                                        Not enough credits: <span
                                        className="dm-brand-font has-text-weight-semibold">{STATE.currentBillCycleInfo.remainingRounded}</span> remaining, <span
                                        className="dm-brand-font has-text-weight-semibold">{Math.ceil(totalCredits)} </span> needed
                                        for completing the job.
                                    </div>
                        }
                    </div>
                </div>
                <div className='subtitle is-6 px-3'>
                    Once the animation is complete, you will learn how many
                    <span className='dm-brand-font has-text-weight-semibold mx-1'>free credits</span>
                    you earn through our
                    <span className='dm-brand-font has-text-weight-semibold mx-1'>Free Animation Credit Program.</span>
                </div>
            </React.Fragment>
        )
    }

    ////////////////////////////////////////////////////////////
    // used in new animation confirmation dialog & library job
    // settings view
    //
    // @param rid : if rerun the rid of job to rerun
    // @param displayMinsMeter : displays anim time bank if true
    ////////////////////////////////////////////////////////////
    function buildJobSummaryInfoTables(rid, displayMinsMeter, returnObj?) {
        let faceSupported = false
        let jobDetails = null
        let settings = null

        // new anim job if rid is null or 0
        if (!rid) {
            settings = JSON.parse(JSON.stringify(STATE.animJobSettings))
            settings.inputVideoData = STATE.inputVideoData

            jobDetails = {}
            jobDetails.lengthRaw = videoTrimData ? (videoTrimData.to - videoTrimData.from) : STATE.inputVideoData.fileLength
            // jobDetails.lengthRaw = STATE.inputVideoData.fileLength
            jobDetails.customModel = STATE.animJobSettings.customModelInfo
            jobDetails.name = STATE.inputVideoData.fileName
            jobDetails.date = "" // only valid for rerun jobs
            jobDetails.jobType = STATE.animJobSettings.jobType
        }
        // otherwise it's a rerun animation job
        else {
            jobDetails = getJobDetailsById(rid)
            if (!jobDetails) {
                return (
                    <React.Fragment>
                        <div className="columns">
                            <div className="column">
                                <div className="notification subtitle is-info is-light">
                                    Job settings are not available when they are replaced with a recent rerun.
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )
            }
            settings = {
                ...Enums.JOB_SETTINGS_TEMPLATE,
                ...jobDetails.settings
            }
        }
        if (jobDetails.customModel === "standard") {
            faceSupported = true
        } else if (jobDetails.customModel.name !== Enums.robloxModelId) {
            const checkedModel = checkCustomCharacterFeatureCompatiabilityIfNecessary(jobDetails.customModel.id)
            if (checkedModel) {
                faceSupported = checkedModel.faceDataType === 1
            }
        }

        if (Object.keys(settings).length === 0 && settings.constructor === Object) {
            return (
                <React.Fragment>
                    <div className="columns">
                        <div className="column">
                            <div className="notification subtitle is-info is-light">
                                Job settings are not available for animations created before July 25, 2021.
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )
        } else {
            const settingFlags = []
            settingFlags.push(
                <span key="setting-on" className="icon is-medium"><i
                    className="fas fa-check-circle has-text-success fa-lg"></i></span>
            )
            settingFlags.push(
                <span key="setting-off" className="icon is-medium"><i
                    className="fas fa-times-circle has-text-danger fa-lg"></i></span>
            )
            let outputFormatsList = []
            for (const [key, value] of Object.entries(settings.formats)) {
                if (value === true) {
                    outputFormatsList.push(key)
                }
            }
            let outputFormatsListJoined = outputFormatsList.join(', ')

            const findModelInfo = getModelDataById(jobDetails.customModel)
            const modelInfo = findModelInfo ? findModelInfo : Enums.deletedModel

            if (jobDetails.customModel !== 'standard') {
                // prepend glb format if custom character present
                outputFormatsListJoined = "glb, " + outputFormatsListJoined
            }

            let mp4Enabled = outputFormatsListJoined.includes('mp4')
            // build parameters object for below functions:
            const params = {
                settings: settings,
                isMultiPersonJob: jobDetails.customModel === "multiple",
                customMultiPersonModel: jobDetails.customMultiPersonModel,
                duration: jobDetails.lengthRaw,
                animName: jobDetails.name,
                cDate: jobDetails.dateRaw,
                outputFormatsList: outputFormatsListJoined,
                mp4Enabled: mp4Enabled,
                modelInfo: jobDetails.customModel === 'standard' ? 'standard' : modelInfo,
                showFullDate: (!rid ? false : true),
                jobType: jobDetails.jobType,
                rid: jobDetails.rid
            }

            const modelLength = params.isMultiPersonJob ? jobDetails.customMultiPersonModel.length : 1

            const trackFace =
                settings?.trackFace === 1
                ? modelLength * jobDetails.lengthRaw * 0.5
                : 0
            const trackHand =
                settings?.trackHand === 1
                ? modelLength * jobDetails.lengthRaw * 0.5
                : 0

            const creditsUsed = Math.round(
                jobDetails.lengthRaw * modelLength + trackFace + trackHand
            )

            let bodyCredits = jobDetails.lengthRaw
            let faceCredits = settings.trackFace ? bodyCredits * 0.5 : 0
            let handCredits = settings.trackHand ? bodyCredits * 0.5 : 0
            let totalCredits = 0
            if (multiPersonTracking) {
                const numModels = [
                    ...STATE.accountTotals.platformCharactersList,
                    ...STATE.accountTotals.charactersList,
                ];
                let supportFaceTrackingNum = 0
                let supportHandTrackingNum = 0
                multiPersonCharacter.forEach(character => {
                    const models = numModels.find(item => item.id === character.modelId)
                    const { platform, handDataType } = models
                    if (platform !== "roblox") {
                        supportFaceTrackingNum++
                    }
                    if (handDataType === 1) {
                        supportHandTrackingNum++
                    }
                })
                const multiPersonTrackingNum = multiPersonCharacter.length
                totalCredits = bodyCredits * multiPersonTrackingNum + faceCredits * supportFaceTrackingNum + handCredits * supportHandTrackingNum
            } else {
                totalCredits = bodyCredits + faceCredits + handCredits
            }
            const isStudioUser = STATE.subscriptionInfo.name === Enums.accountPlansInfo[Enums.accountPlansInfo.length - 2].name
            if (returnObj != undefined)
                returnObj.creditsEnough = isStudioUser || STATE.currentBillCycleInfo.remainingRounded >= totalCredits

            return (
                <div>

                    {/*** Display summary info including animation name, date, length: ***/}
                    {buildAnimationHeaderSection(params)}
                    {
                        displayMinsMeter
                        ? buildTimeMeterForNewJob(settings, faceSupported, totalCredits)
                        :  <div className='JobSettingCreditsLabeling px-5 mt-4 title is-6 mb-0'>
                                <div className='is-flex is-flex-align-items-center creditsUsed'>
                                    <div className='m-0 mr-3'>{creditsUsed}</div>
                                    <div className='m-0 has-text-left'>Credits Used</div>
                                </div>
                                <div className='is-flex is-flex-align-items-center'>
                                    <div className='m-0 mr-3'>{jobDetails.labelingFreeCredits}</div>
                                    <div className='m-0 has-text-left'>FREE Animation Labeling Credtis Earned</div>
                                </div>
                                <div className='is-flex is-flex-align-items-center'>
                                    <div className='m-0 mr-3'>{jobDetails.correctionFreeCredits }</div>
                                    <div className='m-0 has-text-left'>FREE Animation Correction Credtis Earned</div>
                                </div>
                            </div>
                    }

                    {/*** Display Input Info, animation settings, and video settings tables: ***/}

                    {
                        jobDetails.jobType === Enums.jobTypes.staticPose
                            ?
                            <div className="p-5">
                                {buildStaticPoseSettingsTable(params, faceSupported)}
                                {buildOutputFormatsTable(outputFormatsListJoined)}
                            </div>
                            :
                            <div className="p-5">
                                {buildAnimationSettingsTable(params)}
                                {buildVideoSettingsTable(params)}
                                {buildOutputFormatsTable(outputFormatsListJoined)}
                            </div>
                    }

                </div>
            )
        }
    }

    ////////////////////////////////////////////////////////////
    function buildAnimationHeaderSection(params) {
        if (!params.animName) {
            console.log(`warning - invalid params.animName passed to buildAnimationHeaderSection()`)
            return
        }
        let cDate = null
        let jobName = params.animName
        if (params.showFullDate) {
            // display full date including time localized to user's region
            cDate = new Date(params.cDate).toLocaleString(Enums.getNavigatorLanguage())
        } else {
            cDate = params.cDate
        }
        if (params.animName.includes('.')) {
            jobName = params.animName.split('.').slice(0, -1).join('.')
        }

        return (
            <div className="columns m-0 p-0 fullwidth">
                <div className="column m-0 p-0 pl-5 pr-5 notification is-info is-light has-text-left">
                    <div className="title is-5 pt-5"> {jobName} </div>
                    <div className="subtitle is-6 pb-5"> {cDate} </div>
                </div>
                <div
                    className="column is-4 left-border m-0 p-0 notification is-info is-light flex-vert-center has-text-centered">
                    <div className="title pl-2 pr-2 is-5"> {params.duration.toFixed(2)} sec</div>
                </div>
            </div>
        )
    }

    /////////////////////////////////////////////////////////////////////
    // Displays info on input motion video
    /////////////////////////////////////////////////////////////////////
    function buildInputInfoTable(params) {
        return (
            <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
                <thead>
                <tr>
                    <th className="has-background-info-light dm-brand-font dm-brand-border-md" colSpan={2}>Input Video
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td className="td-half">Name</td>
                    <td className="has-text-weight-semibold">{params.settings.inputVideoData ? params.settings.inputVideoData.fileName : ""}</td>
                </tr>
                <tr>
                    <td className="td-half">Length</td>
                    <td className="has-text-weight-semibold">{params.settings.inputVideoData ? params.settings.inputVideoData.fileLength.toFixed(2) : ""}</td>
                </tr>
                <tr>
                    <td className="td-half">Resolution</td>
                    <td className="has-text-weight-semibold">{params.settings.inputVideoData && params.settings.inputVideoData.videoRes ? (params.settings.inputVideoData.videoRes.w + " x " + params.settings.inputVideoData.videoRes.h) : "N/A"}</td>
                </tr>
                <tr>
                    <td className="td-half">FPS</td>
                    <td className="has-text-weight-semibold">{params.settings.inputVideoData && params.settings.inputVideoData.fps ? params.settings.inputVideoData.fps : "N/A"}</td>
                </tr>
                </tbody>
            </table>
        )
    }

    ////////////////////////////////////////////////////////////
    // Check bg color settings for summary display
    ////////////////////////////////////////////////////////////
    function isBgColorUnavailable(params) {
        if (params.settings.backdrop === Enums.Backdrop.solid || params.settings.backdrop === Enums.Backdrop.studio) {
            return false
        } else{
            return true
        }
    }

    ////////////////////////////////////////////////////////////
    function buildStaticPoseSettingsTable(params, faceSupported) {
        const jobUserModelDetail = returnJobUserModelDetail(params)

        if (params.reRun) {
            return buildStaticPoseRerunView(params, jobUserModelDetail)
        } else {
            return buildStaticPoseSummaryView(params, jobUserModelDetail, faceSupported)
        }
    }

    ////////////////////////////////////////////////////////////
    function buildStaticPoseSummaryView(params, jobUserModelDetail, faceSupported) {
        return (
            <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth is-capitalize">
                <thead>
                <tr>
                    <th className="has-background-info-light dm-brand-font" colSpan={2}>{text3DPoseTitle}</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td className="td-half">Root Joint at Origin (Legacy)</td>
                    <td className="td-col-center has-text-weight-semibold"> {params.settings.rootJointAtOrigin ? settingFlags[0] : settingFlags[1]} </td>
                </tr>
                <tr>
                    <td className="td-half">Upper Body Only</td>
                    <td className="td-col-center has-text-weight-semibold"> {params.settings.upperBodyOnly ? settingFlags[0] : settingFlags[1]} </td>
                </tr>
                <tr>
                    <td className="td-half">Physics Filter</td>
                    <td className="td-col-center has-text-weight-semibold"> {params.settings.physicsSim ? settingFlags[0] : settingFlags[1]} </td>
                </tr>
                <tr>
                    <td className="td-half">{textFloating}</td>
                    <td className="td-col-center has-text-weight-semibold"> {params.settings.footLockMode == Enums.footLockMode.never ? settingFlags[0] : settingFlags[1]} </td>
                </tr>
                <tr>
                    <td className="td-half">Fallback Pose</td>
                    <td className="td-col-center has-text-weight-semibold is-capitalized">{params.settings.fallbackPose}</td>
                </tr>
                {
                    jobUserModelDetail.defaultCharacter.length !== 0 && (
                        <tr>
                            <td className="td-half">Default Character</td>
                            <td className="td-col-center has-text-weight-semibold">
                            {
                                jobUserModelDetail.defaultCharacter.map(item => {
                                    return(<div key={item.id}>{item.name}</div>)
                                })
                            }</td>
                        </tr>
                    )
                }
                {
                    jobUserModelDetail.customCharacter.length !== 0 && (
                        <tr>
                            <td className="td-half">Custom Character</td>
                            <td className="td-col-center has-text-weight-semibold">
                            {
                                jobUserModelDetail.customCharacter.map(item => {
                                    return(<div key={item.id}>{item.name}</div>)
                                })
                            }</td>
                        </tr>
                    )
                }
                <tr>
                    <td className="td-half">Enable Shadow</td>
                    <td className="td-col-center has-text-weight-semibold">{(params.settings.shadow && params.settings.shadow !== 'N/A' ? settingFlags[0] : settingFlags[1])}</td>
                </tr>
                <tr>
                    <td className="td-half">Select Background</td>
                    <td className='td-col-center has-text-weight-semibold'>{params.settings.backdrop}</td>
                </tr>
                <tr>
                    <td className="td-half">Background Color</td>
                    <td className="td-col-center has-text-weight-semibold">
                        <span className="is-inline-flex">
                            {/* Only show color box if bgColor is valid */}
                            {
                                isBgColorUnavailable(params)
                                    ?
                                    "N/A"
                                    :
                                    <React.Fragment>
                                        <button
                                            className="button dm-brand-font"
                                            style={{
                                                backgroundColor: `rgb(${params.settings.bgColor[0]},${params.settings.bgColor[1]},${params.settings.bgColor[2]})`,
                                                width: '5rem',
                                                cursor: 'auto'
                                            }}
                                            data-for="bkgd-color-tip"
                                            data-border={true}
                                            data-border-color="black"
                                            data-tip
                                            data-text-color="#2d4e77"
                                            data-background-color="white"
                                        />
                                        <ReactTooltip className="tip-max-w" id="bkgd-color-tip" place="right" effect="solid">
                                            <p className="subtitle is-5 dm-brand-font has-text-weight-semibold">
                                                rgb({params.settings.bgColor[0]},{params.settings.bgColor[1]},{params.settings.bgColor[2]})
                                            </p>
                                        </ReactTooltip>
                                    </React.Fragment>
                            }
                        </span>
                    </td>
                </tr>
                <tr>
                    <td className="td-half">{faceSupported ? "Face Tracking" : "Head Rotation Tracking"}</td>
                    <td className="td-col-center has-text-weight-semibold"> {params.settings.trackFace ? settingFlags[0] : settingFlags[1]} </td>
                </tr>
                <tr>
                    <td className="td-half">Hand Tracking</td>
                    <td className="td-col-center has-text-weight-semibold"> {params.settings.trackHand ? settingFlags[0] : settingFlags[1]} </td>
                </tr>
                </tbody>
            </table>
        )
    }

    /////////////////////////////////////////////////////////////////////
    function buildAnimSettingsSummaryView(params, jobUserModelDetail) {
        let faceSupported = false

        if (params.modelInfo === "standard") {
            faceSupported = true
        } else if (params.modelInfo.name !== Enums.robloxModelId) {
            const checkedModel = checkCustomCharacterFeatureCompatiabilityIfNecessary(params.modelInfo)
            if (checkedModel) {
                faceSupported = checkedModel.faceDataType === 1
            }
        }

        let speedMultiplier = String(params.settings.videoSpeedMultiplier).length > 0 ? params.settings.videoSpeedMultiplier + 'x' : settingFlags[1]
        return (
            <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth is-capitalize">
                <thead>
                <tr>
                    <th className="has-background-info-light dm-brand-font" colSpan={3}>Animation Settings</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td className="td-half">{faceSupported ? "Face Tracking" : "Head Rotation Tracking"}</td>
                    <td className="td-col-center has-text-weight-semibold"> {params.settings.trackFace ? settingFlags[0] : settingFlags[1]} </td>
                </tr>
                <tr>
                    <td className="td-half">Hand Tracking</td>
                    <td className="td-col-center has-text-weight-semibold"> {params.settings.trackHand ? settingFlags[0] : settingFlags[1]} </td>
                </tr>
                <tr>
                    <td className="td-half">Root Joint at Origin (Legacy)</td>
                    <td className="td-col-center has-text-weight-semibold"> {params.settings.rootJointAtOrigin ? settingFlags[0] : settingFlags[1]} </td>
                </tr>
                <tr>
                    <td className="td-half">Upper Body Only</td>
                    <td className="td-col-center has-text-weight-semibold"> {params.settings.upperBodyOnly ? settingFlags[0] : settingFlags[1]} </td>
                </tr>
                <tr>
                    <td className="td-half">Physics Filter</td>
                    <td className="td-col-center has-text-weight-semibold"> {params.settings.physicsSim ? settingFlags[0] : settingFlags[1]} </td>
                </tr>
                <tr>
                    <td className="td-half">Foot Locking</td>
                    <td className="td-col-center has-text-weight-semibold is-capitalized">{params.settings.footLockMode}</td>
                </tr>
                <tr>
                    <td className="td-half">Fallback Pose</td>
                    <td className="td-col-center has-text-weight-semibold is-capitalized">{params.settings.fallbackPose}</td>
                </tr>
                <tr>
                    <td className="td-half">Speed Multiplier</td>
                    <td className="td-col-center has-text-weight-semibold"> {speedMultiplier} </td>
                </tr>
                <tr>
                    <td className="td-half">Motion Smoothing</td>
                    <td className="td-col-center has-text-weight-semibold"> {params.settings.poseFilteringStrength <= 0 ? "0.0" : params.settings.poseFilteringStrength} </td>
                </tr>
                <tr>
                    <td className="td-half">Eye Tracking Sensitivity</td>
                    <td className="td-col-center has-text-weight-semibold"> {params.settings.iris_turning_agility <= 0 ? "0.0" : params.settings.iris_turning_agility} </td>
                </tr>
                {
                    jobUserModelDetail.defaultCharacter.length !== 0 && (
                        <tr>
                            <td className="td-half">Default Character</td>
                            <td className="td-col-center has-text-weight-semibold">
                            {
                                jobUserModelDetail.defaultCharacter.map(item => {
                                    return(<div key={item.id}>{item.name}</div>)
                                })
                            }</td>
                        </tr>
                    )
                }
                {
                    jobUserModelDetail.customCharacter.length !== 0 && (
                        <tr>
                            <td className="td-half">Custom Character</td>
                            <td className="td-col-center has-text-weight-semibold">
                            {
                                jobUserModelDetail.customCharacter.map(item => {
                                    return(<div key={item.id}>{item.name}</div>)
                                })
                            }</td>
                        </tr>
                    )
                }
                {/*
                <tr>
                    <td className="td-half">FBX Frame Reducer</td>
                    <td className="td-col-center has-text-weight-semibold"> {params.settings.keyframeReducer ? settingFlags[0] : settingFlags[1] } </td>
                </tr>
                */}
                </tbody>
            </table>
        )
    }

    /////////////////////////////////////////////////////////////////////
    function buildVideoSettingsSummaryView(params) {
        let camModeClass = ""
        if (params.mp4Enabled && params.settings.camMode !== "N/A") {
            camModeClass = "is-capitalized"
        }
        return (
            <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth is-capitalize">
                <thead>
                <tr>
                    <th className="has-background-info-light dm-brand-font" colSpan={2}>Video Output Settings</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td className="td-half">MP4 Enabled</td>
                    <td className="td-col-center has-text-weight-semibold">{params.mp4Enabled ? settingFlags[0] : settingFlags[1]}</td>
                </tr>
                <tr>
                    <td className="td-half">Enable Shadow</td>
                    <td className="td-col-center has-text-weight-semibold">{!params.mp4Enabled ? 'N/A' : (params.settings.shadow && params.settings.shadow !== 'N/A' ? settingFlags[0] : settingFlags[1])}</td>
                </tr>
                <tr>
                    <td className="td-half">Enable Audio</td>
                    <td className="td-col-center has-text-weight-semibold">{!params.mp4Enabled ? 'N/A' : (params.settings.includeAudio && params.settings.includeAudio !== 'N/A' ? settingFlags[0] : settingFlags[1])}</td>
                </tr>
                <tr>
                    <td className="td-half">Camera Mode</td>
                    <td className={`td-col-center has-text-weight-semibold ${camModeClass}`}>{!params.mp4Enabled ? 'N/A' : params.settings.camMode}</td>
                </tr>
                <tr>
                    <td className="td-half">Camera Horizontal Angle</td>
                    <td className={`td-col-center has-text-weight-semibold ${camModeClass}`}>{!params.mp4Enabled ? 'N/A' : params.settings.camHorizontalAngle}</td>
                </tr>
                <tr>
                    <td className="td-half">Select Background</td>
                    <td className='td-col-center has-text-weight-semibold'>{params.settings.backdrop}</td>
                </tr>
                <tr>
                    <td className="td-half">Background Color</td>
                    <td className="td-col-center has-text-weight-semibold">
                        <span className="is-inline-flex">
                            {/* Only show color box if bgColor is valid */}
                            {
                                isBgColorUnavailable(params)
                                    ?
                                    "N/A"
                                    :
                                    <React.Fragment>
                                        <button
                                            className="button dm-brand-font"
                                            style={{
                                                backgroundColor: `rgb(${params.settings.bgColor[0]},${params.settings.bgColor[1]},${params.settings.bgColor[2]})`,
                                                width: '5rem',
                                                cursor: 'auto'
                                            }}
                                            data-for="bkgd-color-tip"
                                            data-border={true}
                                            data-border-color="black"
                                            data-tip
                                            data-text-color="#2d4e77"
                                            data-background-color="white"
                                        />
                                        <ReactTooltip className="tip-max-w" id="bkgd-color-tip" place="right" effect="solid">
                                            <p className="subtitle is-5 dm-brand-font has-text-weight-semibold">
                                                rgb({params.settings.bgColor[0]},{params.settings.bgColor[1]},{params.settings.bgColor[2]})
                                            </p>
                                        </ReactTooltip>
                                    </React.Fragment>
                            }
                        </span>
                    </td>
                </tr>
                </tbody>
            </table>
        )
    }

    /////////////////////////////////////////////////////////////////////
    function buildVideoSettingsRerunView(params) {
        let camModeClass = ""
        if (params.mp4Enabled && params.settings.camMode !== "N/A") {
            camModeClass = "is-capitalized"
        }
        const mp4BackDropDownData = createMp4BackOptions(jobTypes.animation)
        const cameraSettingsDropDownData = getCameraSettingsDropDownData()

        const disabledShadow = settingsDisabledShadow(true, true)
        return (
            <div className='buildVideoSettingsRerunView'>
                <table className="table is-striped is-narrow is-hoverable is-fullwidth">
                    <thead>
                    <tr>
                        <th className="has-background-info-light dm-brand-font dm-brand-border-r">Video Output Settings</th>
                        <th className="has-background-info-light dm-brand-font dm-brand-border-r">Current</th>
                        <th className="has-background-info-light dm-brand-font">New</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td className="td-40 dm-brand-border-r">MP4 Enabled
                            <DMToolTip
                                text={Enums.toolTipMp4}
                                iconColor="#2d4e77"
                                iconSize=".75rem"
                                tipId="mp4-format-tip"
                                isTipHtml={true}
                                noMargins={true}
                            />
                        </td>
                        <td className="td-30 td-col-center has-text-weight-semibold dm-brand-border-r">{params.mp4Enabled ? settingFlags[0] : settingFlags[1]}</td>
                        <td className="td-col-center has-text-weight-semibold">
                            <YesNoSlider
                                onChangeFunc={(event) => DISPATCH({rerunSettings: {...STATE.rerunSettings, ...{'formats': createNewOutputFormatsObj('mp4', event.target.checked, true)}}})}
                                tipText={Enums.toolTipMp4}
                                tipId="mp4-shadow"
                                isDisabled={!STATE.rerunSettings.formats.fbx}
                                value={STATE.rerunSettings.formats.mp4 && (STATE.rerunSettings.formats.mp4 === true)}
                                centerAlign={true}
                                noMargins={true}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="td-40 dm-brand-border-r">{mp4EnableShadowTitle}
                            <DMToolTip
                                text={Enums.toolTipMp4Shadow}
                                iconColor="#2d4e77"
                                iconSize=".75rem"
                                tipId="mp4-shadow-tip"
                                isTipHtml={true}
                                noMargins={true}
                            />
                        </td>
                        <td className="td-30 td-col-center has-text-weight-semibold dm-brand-border-r">{!params.mp4Enabled ? 'N/A' : (params.settings.shadow && params.settings.shadow !== 'N/A' ? settingFlags[0] : settingFlags[1])}</td>
                        <td className="td-col-center has-text-weight-semibold">
                            <YesNoSlider
                                onChangeFunc={(event) => DISPATCH({rerunSettings: {...STATE.rerunSettings, ...{'shadow': event.target.checked}}})}
                                tipText={Enums.toolTipMp4Shadow}
                                tipId="mp4-shadow"
                                isDisabled={disabledShadow}
                                value={disabledShadow ? false : STATE.rerunSettings.shadow}
                                centerAlign={true}
                                noMargins={true}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="td-40 dm-brand-border-r">Enable Audio
                            <DMToolTip
                                text={Enums.toolTipIncludeAud}
                                iconColor="#2d4e77"
                                iconSize=".75rem"
                                tipId="mp4-audio-tip"
                                isTipHtml={true}
                                noMargins={true}
                            />
                        </td>
                        <td className="td-30 td-col-center has-text-weight-semibold dm-brand-border-r">{!params.mp4Enabled ? 'N/A' : (params.settings.includeAudio && params.settings.includeAudio !== 'N/A' ? settingFlags[0] : settingFlags[1])}</td>
                        <td className="td-col-center has-text-weight-semibold">
                            <YesNoSlider
                                onChangeFunc={(event) => DISPATCH({rerunSettings: {...STATE.rerunSettings, ...{'includeAudio': event.target.checked}}})}
                                tipText={Enums.toolTipIncludeAud}
                                tipId="mp4-include-audio"
                                isDisabled={!STATE.rerunSettings.formats.mp4}
                                value={(STATE.rerunSettings.formats.mp4 ? STATE.rerunSettings.includeAudio : false)}
                                centerAlign={true}
                                noMargins={true}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="td-40 dm-brand-border-r">Camera Mode
                            <DMToolTip
                                text={Enums.tooltipMp4Camera}
                                iconColor="#2d4e77"
                                iconSize=".75rem"
                                tipId="mp4-cam-mode-tip"
                                isTipHtml={true}
                                noMargins={true}
                            />
                        </td>
                        <td className={`td-30 td-col-center has-text-weight-semibold dm-brand-border-r ${camModeClass}`}>{!params.mp4Enabled ? 'N/A' : params.settings.camMode}</td>
                        <td className="td-col-center has-text-weight-semibold">
                            <DMDropDown
                                value={STATE.rerunSettings.camMode === 'N/A' ? Enums.cameraMotionSettings[0] : STATE.rerunSettings.camMode}
                                onChange={(val) => DISPATCH({rerunSettings: {...STATE.rerunSettings, ...{'camMode': val}}})}
                                data={cameraSettingsDropDownData}
                                isDisabled={!STATE.rerunSettings.formats.mp4}
                                rightAlign={true}
                                noMargins={true}
                            />
                        </td>
                    </tr>

                    <tr>
                        <td className="td-40 dm-brand-border-r">Select Background
                            <DMToolTip
                                text={Enums.toolTipMp4BackType}
                                iconColor="#2d4e77"
                                iconSize=".75rem"
                                tipId="mp4-background-tip"
                                isTipHtml={true}
                                noMargins={true}
                            />
                        </td>
                        <td className={"td-30 td-col-center has-text-weight-semibold dm-brand-border-r " + (params.mp4Enabled ? "is-capitalized" : "")}>{params.settings.backdrop}</td>
                        <td className="td-col-center has-text-weight-semibold">
                            <DMDropDown
                                value={STATE.rerunSettings.backdrop}
                                onChange={(value, cb) => changeCustomBkgdSelect(value, cb, true)}
                                data={mp4BackDropDownData}
                                isDisabled={!STATE.rerunSettings.formats.mp4}
                                rightAlign={true}
                                noMargins={true}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="td-40 dm-brand-border-r">Background Color
                            <DMToolTip
                                text={Enums.toolTipMp4BackColor}
                                iconColor="#2d4e77"
                                iconSize=".75rem"
                                tipId="mp4-background-tip"
                                isTipHtml={true}
                                noMargins={true}
                            />
                        </td>
                        <td className="td-30 td-col-center has-text-weight-semibold dm-brand-border-r">
                            <span className="is-inline-flex">
                                {
                                isBgColorUnavailable(params)
                                    ?
                                    'N/A'
                                    :
                                    <React.Fragment>
                                        <button
                                            className="button dm-brand-font mr-2"
                                            style={{
                                                backgroundColor: `rgb(${params.settings.bgColor[0]},${params.settings.bgColor[1]},${params.settings.bgColor[2]})`,
                                                width: '5rem',
                                                cursor: 'auto'
                                            }}
                                            data-for="bkgd-color-tip"
                                            data-border={true}
                                            data-border-color="black"
                                            data-tip
                                            data-text-color="#2d4e77"
                                            data-background-color="white"
                                        />
                                        <ReactTooltip className="tip-max-w" id="bkgd-color-tip" place="right" effect="solid">
                                            <p className="subtitle is-5 dm-brand-font has-text-weight-semibold">
                                                rgb({params.settings.bgColor[0]},{params.settings.bgColor[1]},{params.settings.bgColor[2]})
                                            </p>
                                        </ReactTooltip>
                                    </React.Fragment>
                                }
                            </span>
                        </td>
                        <td className="td-30 td-col-center has-text-weight-semibold">
                            <DMDropDownColorPicker
                                data={(!STATE.rerunSettings.bgColor || STATE.rerunSettings.bgColor === 'N/A') ? Enums.defaultGSColor : STATE.rerunSettings.bgColor}
                                value={STATE.rerunSettings.bgColor}
                                onChange={handleBkgdColorChangeReRun}
                                isDisabled={STATE.rerunSettings.formats.mp4 ? !(STATE.rerunSettings.backdrop === Enums.Backdrop.solid || STATE.rerunSettings.backdrop === Enums.Backdrop.studio) : true}
                                isRerun={true}
                                rightAlign={true}
                                noMargins={true}
                            />
                        </td>
                    </tr>

                    <tr>
                        <td className="td-40 dm-brand-border-r">Camera Horizontal Angle
                            <DMToolTip
                                text={Enums.toolTipVideoCameraHorizontalAngle}
                                iconColor="#2d4e77"
                                iconSize=".75rem"
                                tipId="mp4-cam-angle-tip"
                                isTipHtml={true}
                                noMargins={true}
                            />
                        </td>
                        <td className="td-30 td-col-center has-text-weight-semibold dm-brand-border-r">{!params.mp4Enabled ? 'N/A' : params.settings.camHorizontalAngle}</td>
                        <td className="td-30 td-col-center has-text-weight-semibold">
                            <table>
                                <tbody>
                                <tr>
                                    <td className="td-no-border"></td>
                                    <td className="td-no-border">
                                        <DMSlider
                                            value={(STATE.rerunSettings.camHorizontalAngle)}
                                            minValue={-90}
                                            maxValue={90}
                                            step={5}
                                            onChange={changeMp4OutputCameraAngleReRun}
                                            isDisabled={!STATE.rerunSettings.formats.mp4}
                                            darkMark={true}
                                        />
                                    </td>
                                    <td className="td-no-border"></td>
                                </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    </tbody>
                </table>

                <Backdrop fullWidth Rerun />
            </div>
        )
    }

    /////////////////////////////////////////////////////////////////////
    function buildAnimSettingsRerunView(params, jobUserModelDetail) {
        const speedMultiplier = String(params.settings.videoSpeedMultiplier).length > 0 ? params.settings.videoSpeedMultiplier + 'x' : settingFlags[1]
        const footLockOptions = checkFeatureAvailability(STATE.subscriptionInfo.name, Enums.featureLockNames.footLocking)
        let footlockDropDownData = []
        Object.values(Enums.footLockMode).forEach((value, index) => {
            footlockDropDownData.push({value: value, available: footLockOptions[index]})
        })

        let fallbackPoseDropDownData = []
        Object.values(Enums.fallbackPose).forEach((value) => {
            fallbackPoseDropDownData.push({value: value, available: true})
        })

        let isRobloxModel = false
        let HandTrackingDisabled = false
        let AllFaceTrackingSupport = 0
        let AllHandTrackingSupport = 0
        // single person
        let faceSupported = false
        let handSupported = false
        if (params.isMultiPersonJob) {
            faceSupported = true
            handSupported = true

            const numModels = [
                ...STATE.accountTotals.platformCharactersList,
                ...STATE.accountTotals.charactersList,
            ];

            const defaultModel = numModels.find(
                (model) => model.name === Enums.defaultModelName
            );

            params.customMultiPersonModel.forEach(character => {
                const findModels = numModels.find(item => item.id === character.modelId)
                const models = findModels ?? defaultModel
                const { handDataType, name } = models
                if (name !== Enums.robloxModelId) {
                    AllFaceTrackingSupport++
                }
                if (handDataType === 1) {
                    AllHandTrackingSupport++
                }
            })
            isRobloxModel = AllFaceTrackingSupport === 0
            HandTrackingDisabled = AllHandTrackingSupport === 0
        } else {
            if (!params.modelInfo.id) {
                faceSupported = handSupported = true
            } else if (params.modelInfo.name === Enums.robloxModelId) {
                isRobloxModel = true
            } else{
                const checkedModel = checkCustomCharacterFeatureCompatiabilityIfNecessary(STATE.animJobSettings.customModelInfo.id)
                if (checkedModel) {
                    faceSupported = checkedModel.faceDataType === 1
                    handSupported = checkedModel.handDataType === 1
                }
            }
            HandTrackingDisabled = !handSupported
        }
        STATE.rerunSettings.trackHand = handSupported && STATE.rerunSettings.trackHand

        const { rootJointOriginTip, rootJointOriginTipColor, uniformity } = getRootJointAtOriginSetting(params)

        return (
            <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth is-capitalize">
                <thead>
                <tr>
                    <th className="has-background-info-light dm-brand-font dm-brand-border-r">Animation Settings</th>
                    <th className="has-background-info-light dm-brand-font dm-brand-border-r">Current</th>
                    <th className="has-background-info-light dm-brand-font">New</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td className="td-40 dm-brand-border-r">
                        {faceSupported ? "Face Tracking" : "Head Rotation Tracking"}
                        <DMToolTip
                            text={faceSupported ? Enums.toolTipFaceTrack : Enums.toolTipHeadTrack}
                            iconColor="#2d4e77"
                            iconSize=".75rem"
                            tipId="face-tracking-tip"
                            isTipHtml={true}
                            noMargins={true}
                        />
                    </td>
                    <td className="td-30 td-col-center has-text-weight-semibold dm-brand-border-r"> {params.settings.trackFace ? settingFlags[0] : settingFlags[1]} </td>
                    <td className="td-col-center has-text-weight-semibold">
                        <YesNoSlider
                            onChangeFunc={(event) => DISPATCH({
                                rerunSettings: {
                                    ...STATE.rerunSettings, ...{
                                        isRerun: true,
                                        'trackFace': (event.target.checked ? 1 : 0)
                                    }
                                }
                            })}
                            isDisabled={isRobloxModel}
                            toolTip={faceSupported ? Enums.toolTipFaceTrack : Enums.toolTipHeadTrack}
                            value={STATE.rerunSettings.trackFace}
                            centerAlign={true}
                            noMargins={true}
                        />
                    </td>
                </tr>
                <tr>
                    <td className="td-40 dm-brand-border-r">Hand Tracking
                        <DMToolTip
                            text={Enums.toolTipHandTrack}
                            iconColor="#2d4e77"
                            iconSize=".75rem"
                            tipId="hand-tracking-tip"
                            isTipHtml={true}
                            noMargins={true}
                        />
                    </td>
                    <td className="td-30 td-col-center has-text-weight-semibold dm-brand-border-r"> {params.settings.trackHand ? settingFlags[0] : settingFlags[1]} </td>
                    <td className="td-col-center has-text-weight-semibold">
                        <YesNoSlider
                            onChangeFunc={(event) => DISPATCH({
                                rerunSettings: {
                                    ...STATE.rerunSettings, ...{
                                        isRerun: true,
                                        'trackHand': (event.target.checked ? 1 : 0)
                                    }
                                }
                            })}
                            toolTip={Enums.toolTipHandTrack}
                            isDisabled={HandTrackingDisabled}
                            value={STATE.rerunSettings.trackHand}
                            centerAlign={true}
                            noMargins={true}
                        />
                    </td>
                </tr>
                <tr className={"multiPerson " + (!uniformity && 'unsupport')}>
                    <td className="td-40 dm-brand-border-r">Root Joint at Origin (Legacy)
                        <DMToolTip
                            text={rootJointOriginTip}
                            iconColor={rootJointOriginTipColor}
                            iconSize=".75rem"
                            tipId="root-joint-tip"
                            isTipHtml={true}
                            noMargins={true}
                        />
                    </td>
                    <td className="td-30 td-col-center has-text-weight-semibold dm-brand-border-r"> {params.settings.rootJointAtOrigin ? settingFlags[0] : settingFlags[1]} </td>
                    <td className="td-col-center has-text-weight-semibold">
                        <YesNoSlider
                            onChangeFunc={(event) => changeRootAtOrigin(event, true)}
                            toolTip={Enums.toolTipRootJoint}
                            value={STATE.rerunSettings.rootJointAtOrigin}
                            centerAlign={true}
                            noMargins={true}
                        />
                    </td>
                </tr>
                <tr>
                    <td className="td-40 dm-brand-border-r">Upper Body Only
                        <DMToolTip
                            text={Enums.toolTipUpperBodyOnly}
                            iconColor="#2d4e77"
                            iconSize=".75rem"
                            tipId="upper-body-only-tip"
                            isTipHtml={true}
                            noMargins={true}
                        />
                    </td>
                    <td className="td-30 td-col-center has-text-weight-semibold dm-brand-border-r"> {params.settings.upperBodyOnly ? settingFlags[0] : settingFlags[1]} </td>
                    <td className="td-col-center has-text-weight-semibold">
                        <YesNoSlider
                            onChangeFunc={(event) => DISPATCH({rerunSettings: {...STATE.rerunSettings, ...{'upperBodyOnly': (event.target.checked ? 1 : 0)}}})}
                            toolTip={Enums.toolTipUpperBodyOnly}
                            isDisabled={false}
                            value={STATE.rerunSettings.upperBodyOnly}
                            centerAlign={true}
                            noMargins={true}
                        />
                    </td>
                </tr>
                <tr>
                    <td className="td-40 dm-brand-border-r">Physics Filter
                        <DMToolTip
                            text={Enums.toolTipPhysSim}
                            tipId="physics-filter-tip"
                            iconColor="#2d4e77"
                            iconSize=".75rem"
                            isTipHtml={true}
                            noMargins={true}
                        />
                    </td>
                    <td className="td-30 td-col-center has-text-weight-semibold dm-brand-border-r"> {params.settings.physicsSim ? settingFlags[0] : settingFlags[1]} </td>
                    <td className="td-col-center has-text-weight-semibold">
                        <YesNoSlider
                            onChangeFunc={(event) => DISPATCH({rerunSettings: {...STATE.rerunSettings, ...{'physicsSim': event.target.checked}}})}
                            toolTip={Enums.toolTipPhysSim}
                            isDisabled={false}
                            value={STATE.rerunSettings.physicsSim}
                            centerAlign={true}
                            noMargins={true}
                        />
                    </td>
                </tr>
                <tr>
                    <td className="td-40 dm-brand-border-r">Foot Locking
                        <DMToolTip
                            text={Enums.tooltipFootLocking}
                            tipId="footlocking-tip"
                            iconColor="#2d4e77"
                            iconSize=".75rem"
                            isTipHtml={true}
                            noMargins={true}
                        />
                    </td>
                    <td className="td-30 td-col-center has-text-weight-semibold is-capitalized dm-brand-border-r">{params.settings.footLockMode}</td>
                    <td className="td-col-center has-text-weight-semibold">
                        <DMDropDown
                            data={footlockDropDownData}
                            value={STATE.rerunSettings.footLockMode}
                            onChange={changeFootLockingSelectReRun}
                            rightAlign={true}
                            noMargins={true}
                        />
                    </td>
                </tr>
                <tr>
                    <td className="td-40 dm-brand-border-r">Fallback Pose
                        <DMToolTip
                            text={Enums.tooltipFallbackPose}
                            tipId="fallbackpose-tip"
                            iconColor="#2d4e77"
                            iconSize=".75rem"
                            isTipHtml={true}
                            noMargins={true}
                        />
                    </td>
                    <td className="td-30 td-col-center has-text-weight-semibold is-capitalized dm-brand-border-r">{params.settings.fallbackPose}</td>
                    <td className="td-col-center has-text-weight-semibold">
                        <DMDropDown
                            data={fallbackPoseDropDownData}
                            value={STATE.rerunSettings.fallbackPose}
                            onChange={changeFallbackPoseSelectReRun}
                            rightAlign={true}
                            noMargins={true}
                        />
                    </td>
                </tr>
                <tr>
                    <td className="td-40 dm-brand-border-r">Motion Smoothing
                        <DMToolTip
                            text={Enums.toolTipSmoothness}
                            tipId="smoothness-tip"
                            iconColor="#2d4e77"
                            iconSize=".75rem"
                            isTipHtml={true}
                            noMargins={true}
                        />
                    </td>
                    <td className="td-30 td-col-center has-text-weight-semibold dm-brand-border-r"> {params.settings.poseFilteringStrength <= 0 ? "0.0" : (params.settings.poseFilteringStrength == 1 ? "1.0" : params.settings.poseFilteringStrength)} </td>
                    <td className="td-col-center has-text-weight-semibold">
                        <DMDropDownKnob
                            value={STATE.rerunSettings.poseFilteringStrength}
                            // convert from [0-100] int range to float [0.0-1.0]
                            onChange={(val) => DISPATCH({rerunSettings: {...STATE.rerunSettings, ...{'poseFilteringStrength': Math.abs((parseFloat(val) / 100)).toFixed(1)}}})}
                            isDisabled={!checkFeatureAvailability(STATE.subscriptionInfo.name, Enums.featureLockNames.peSmoothness)}
                            buttonClass={"dm-brand-font is-light"}
                            numTicks={10}
                            snapToTicks={true}
                        />
                    </td>
                </tr>
                <tr>
                    <td className="td-40 dm-brand-border-r">Eye Tracking Sensitivity
                        <DMToolTip
                            text={Enums.toolTipEyeTrackingSensitivity}
                            tipId="eyeTrackingSensitivity-tip"
                            iconColor="#2d4e77"
                            iconSize=".75rem"
                            isTipHtml={true}
                            noMargins={true}
                        />
                    </td>
                    <td className="td-30 td-col-center has-text-weight-semibold dm-brand-border-r"> {params.settings.iris_turning_agility <= 0 ? "0.0" : (params.settings.iris_turning_agility == 1 ? "1.0" : params.settings.iris_turning_agility)} </td>
                    <td className="td-col-center has-text-weight-semibold">
                        <DMDropDownKnob
                            value={STATE.rerunSettings.iris_turning_agility}
                            // convert from [0-100] int range to float [0.0-1.0]
                            onChange={(val) => DISPATCH({rerunSettings: {...STATE.rerunSettings, ...{'iris_turning_agility': Math.abs((parseFloat(val) / 100)).toFixed(1)}}})}
                            isDisabled={true}
                            buttonClass={"dm-brand-font is-light"}
                            numTicks={10}
                            snapToTicks={true}
                        />
                    </td>
                </tr>
                <tr>
                    <td className="td-40 dm-brand-border-r">Speed Multiplier
                        <DMToolTip
                            text={Enums.toolTipVideSpeedMult}
                            tipId="video-speed-tip"
                            iconColor="#2d4e77"
                            iconSize=".75rem"
                            isTipHtml={true}
                            noMargins={true}
                        />
                    </td>
                    <td className="td-30 td-col-center has-text-weight-semibold dm-brand-border-r"> {speedMultiplier} </td>
                    <td className="td-col-center has-text-weight-semibold">
                        <DMDropDownKnob
                            value={STATE.rerunSettings.videoSpeedMultiplier}
                            // convert from [0-100] int range to float [1.0-8.0]
                            onChange={(val) => DISPATCH({rerunSettings: {...STATE.rerunSettings, ...{'videoSpeedMultiplier': (Math.abs((val / 100.0) * 7.0) + 1).toFixed(1)}}})}
                            isDisabled={!checkFeatureAvailability(STATE.subscriptionInfo.name, Enums.featureLockNames.slowMotion)}
                            buttonClass={"dm-brand-font is-light"}
                            numTicks={8}
                        />
                    </td>
                </tr>
                {
                    jobUserModelDetail.defaultCharacter.length !== 0 && (
                        <tr>
                            <td className="td-half">Default Character</td>
                            <td className="td-col-center has-text-weight-semibold dm-brand-border-r jobUserModel">
                            {
                                jobUserModelDetail.defaultCharacter.map(item => {
                                    return(<div className='ellipsis' key={item.id}>{item.name}</div>)
                                })
                            }
                            </td>
                            <td className="td-col-center has-text-weight-semibold jobUserModel">
                            {
                                jobUserModelDetail.defaultCharacter.map(item => {
                                    return(<div className='ellipsis' key={item.id}>{item.name}</div>)
                                })
                            }
                            </td>
                        </tr>
                    )
                }
                {
                    jobUserModelDetail.customCharacter.length !== 0 && (
                        <tr>
                            <td className="td-half">Custom Character</td>
                            <td className="td-col-center has-text-weight-semibold dm-brand-border-r jobUserModel">
                            {
                                jobUserModelDetail.customCharacter.map(item => {
                                    return(<div className='ellipsis' key={item.id}>{item.name}</div>)
                                })
                            }
                            </td>
                            <td className="td-col-center has-text-weight-semibold jobUserModel">
                            {
                                jobUserModelDetail.customCharacter.map(item => {
                                    return(<div className='ellipsis' key={item.id}>{item.name}</div>)
                                })
                            }
                            </td>
                        </tr>
                    )
                }

                {/*
          <tr>
            <td className="td-40">FBX Frame Reducer
              <DMToolTip
                text={Enums.toolTipFBXKeyFrame}
                iconColor="#2d4e77"
                iconSize=".75rem"
                tipId="fbx-keyframe-reduce-tip"
                noMargins={true}
              />
            </td>
            <td className="td-30 td-col-center has-text-weight-semibold"> {params.settings.keyframeReducer ? settingFlags[0] : settingFlags[1] } </td>
            <td className="td-col-center has-text-weight-semibold">
              <YesNoSlider
                onChangeFunc={(event)=>DISPATCH( {rerunSettings:  {...STATE.rerunSettings, ...{'keyframeReducer': event.target.checked}}} )}
                toolTip={Enums.toolTipFBXKeyFrame}
                isDisabled={STATE.rerunSettings.formats.fbx ? false : true}
                value={STATE.rerunSettings.keyframeReducer}
                centerAlign={true}
              />
            </td>
          </tr>
          */}

                </tbody>
            </table>
        )
    }
    function getRootJointAtOriginSetting (params) {
        const newRootJointAtOrigin =
        typeof STATE.rerunSettings.rootJointAtOrigin === "boolean"
          ? STATE.rerunSettings.rootJointAtOrigin
          : STATE.rerunSettings.rootJointAtOrigin === 1;

        const unsupportRootJointOriginTip = '<div class="dm-white">Please check the selected characters<div/>'

        const rootJointOriginTip =
        params.settings.rootJointAtOrigin === newRootJointAtOrigin
          ? Enums.toolTipRootJoint
          : Enums.toolTipRootJoint + unsupportRootJointOriginTip;

        const rootJointOriginTipColor =
        params.settings.rootJointAtOrigin === newRootJointAtOrigin
          ? "#2d4e77"
          : "#F55E19"

        const uniformity = params.settings.rootJointAtOrigin === newRootJointAtOrigin
        return { rootJointOriginTip, rootJointOriginTipColor, uniformity }
    }

    /////////////////////////////////////////////////////////////////////
    function buildStaticPoseRerunView(params, jobUserModelDetail) {
        let isRobloxModel = false
        let HandTrackingDisabled = false
        let AllFaceTrackingSupport = 0
        let AllHandTrackingSupport = 0
        // single person
        let faceSupported = false
        let handSupported = false
        if (params.isMultiPersonJob) {
            faceSupported = true
            handSupported = true

            const numModels = [
                ...STATE.accountTotals.platformCharactersList,
                ...STATE.accountTotals.charactersList,
            ];
            const defaultModel = numModels.find(
                (model) => model.name === Enums.defaultModelName
            );

            params.customMultiPersonModel.forEach(character => {
                const findModels = numModels.find(item => item.id === character.modelId)
                const models = findModels ?? defaultModel

                const { handDataType, name } = models
                if (name !== Enums.robloxModelId) {
                    AllFaceTrackingSupport++
                }
                if (handDataType === 1) {
                    AllHandTrackingSupport++
                }
            })
            isRobloxModel = AllFaceTrackingSupport === 0
            HandTrackingDisabled = AllHandTrackingSupport === 0
        } else {
            if (!params.modelInfo.id) {
                faceSupported = handSupported = true
            } else if (params.modelInfo.name === Enums.robloxModelId) {
                isRobloxModel = true
            } else{
                const checkedModel = checkCustomCharacterFeatureCompatiabilityIfNecessary(STATE.animJobSettings.customModelInfo.id)
                if (checkedModel) {
                    faceSupported = checkedModel.faceDataType === 1
                    handSupported = checkedModel.handDataType === 1
                }
            }
            HandTrackingDisabled = !handSupported
        }
        STATE.rerunSettings.trackHand = handSupported && STATE.rerunSettings.trackHand

        const mp4BackDropDownData = createMp4BackOptions(jobTypes.staticPose)

        let fallbackPoseDropDownData = []
        Object.values(Enums.fallbackPose).forEach((value) => {
            fallbackPoseDropDownData.push({value: value, available: true})
        })

        const hasImageOut = STATE.rerunSettings.formats.gif || STATE.rerunSettings.formats.jpg || STATE.rerunSettings.formats.png

        const disabledShadow = settingsDisabledShadow(true, false)

        const { rootJointOriginTip, rootJointOriginTipColor, uniformity } = getRootJointAtOriginSetting(params)
        return (
            <div className='buildStaticPoseRerunView'>
                <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth is-capitalize">
                    <thead>
                    <tr>
                        <th className="has-background-info-light dm-brand-font" colSpan={3}> {text3DPoseTitle} </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td className="td-40 dm-brand-border-r">{faceSupported ? "Face Tracking" : "Head Rotation Tracking"}
                            <DMToolTip
                                text={faceSupported ? Enums.toolTipFaceTrack : Enums.toolTipHeadTrack}
                                iconColor="#2d4e77"
                                iconSize=".75rem"
                                tipId="face-tracking-tip"
                                isTipHtml={true}
                                noMargins={true}
                            />
                        </td>
                        <td className="td-30 td-col-center has-text-weight-semibold dm-brand-border-r"> {params.settings.trackFace ? settingFlags[0] : settingFlags[1]} </td>
                        <td className="td-col-center has-text-weight-semibold">
                            <YesNoSlider
                                onChangeFunc={(event) => DISPATCH({
                                    rerunSettings: {
                                        ...STATE.rerunSettings, ...{
                                            isRerun: true,
                                            'trackFace': (event.target.checked ? 1 : 0)
                                        }
                                    }
                                })}
                                isDisabled={isRobloxModel}
                                toolTip={Enums.toolTipFaceTrack}
                                value={STATE.rerunSettings.trackFace}
                                centerAlign={true}
                                noMargins={true}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="td-40 dm-brand-border-r">Hand Tracking
                            <DMToolTip
                                text={Enums.toolTipHandTrack}
                                iconColor="#2d4e77"
                                iconSize=".75rem"
                                tipId="hand-tracking-tip"
                                isTipHtml={true}
                                noMargins={true}
                            />
                        </td>
                        <td className="td-30 td-col-center has-text-weight-semibold dm-brand-border-r"> {params.settings.trackHand ? settingFlags[0] : settingFlags[1]} </td>
                        <td className="td-col-center has-text-weight-semibold">
                            <YesNoSlider
                                onChangeFunc={(event) => DISPATCH({
                                    rerunSettings: {
                                        ...STATE.rerunSettings, ...{
                                            isRerun: true,
                                            'trackHand': (event.target.checked ? 1 : 0)
                                        }
                                    }
                                })}
                                toolTip={Enums.toolTipHandTrack}
                                isDisabled={HandTrackingDisabled}
                                value={STATE.rerunSettings.trackHand}
                                centerAlign={true}
                                noMargins={true}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="td-40 dm-brand-border-r">JPG Enabled
                            <DMToolTip
                                text={Enums.toolTipJpg}
                                iconColor="#2d4e77"
                                iconSize=".75rem"
                                tipId="jpg-format-tip"
                                isTipHtml={true}
                                noMargins={true}
                            />
                        </td>
                        <td className="td-30 td-col-center has-text-weight-semibold dm-brand-border-r">{params.outputFormatsList.includes('jpg') ? settingFlags[0] : settingFlags[1]}</td>
                        <td className="td-col-center has-text-weight-semibold">
                            <YesNoSlider
                                onChangeFunc={(event) => enableImageOrVideoTypeRerun('jpg', event.target.checked)}
                                tipText={Enums.toolTipJpg}
                                value={STATE.rerunSettings.formats.jpg && (STATE.rerunSettings.formats.jpg === true)}
                                centerAlign={true}
                                isDisabled={STATE.rerunSettings.backdrop === Enums.Backdrop.transparent}
                                noMargins={true}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="td-40 dm-brand-border-r">PNG Enabled
                            <DMToolTip
                                text={Enums.toolTipPng}
                                iconColor="#2d4e77"
                                iconSize=".75rem"
                                tipId="png-format-tip"
                                isTipHtml={true}
                                noMargins={true}
                            />
                        </td>
                        <td className="td-30 td-col-center has-text-weight-semibold dm-brand-border-r">{params.outputFormatsList.includes('png') ? settingFlags[0] : settingFlags[1]}</td>
                        <td className="td-col-center has-text-weight-semibold">
                            <YesNoSlider
                                onChangeFunc={(event) => enableImageOrVideoTypeRerun('png', event.target.checked)}
                                tipText={Enums.toolTipPng}
                                value={STATE.rerunSettings.formats.png && (STATE.rerunSettings.formats.png === true)}
                                centerAlign={true}
                                isDisabled={STATE.rerunSettings.backdrop === Enums.Backdrop.transparent}
                                noMargins={true}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="td-40 dm-brand-border-r">GIF Enabled
                            <DMToolTip
                                text={Enums.toolTipGif}
                                iconColor="#2d4e77"
                                iconSize=".75rem"
                                tipId="gif-format-tip"
                                isTipHtml={true}
                                noMargins={true}
                            />
                        </td>
                        <td className="td-30 td-col-center has-text-weight-semibold dm-brand-border-r">{params.outputFormatsList.includes('gif') ? settingFlags[0] : settingFlags[1]}</td>
                        <td className="td-col-center has-text-weight-semibold">
                            <YesNoSlider
                                onChangeFunc={(event) => enableImageOrVideoTypeRerun('gif', event.target.checked)}
                                tipText={Enums.toolTipGif}
                                value={STATE.rerunSettings.formats.gif && (STATE.rerunSettings.formats.gif === true)}
                                centerAlign={true}
                                isDisabled={STATE.rerunSettings.backdrop === Enums.Backdrop.transparent}
                                noMargins={true}
                            />
                        </td>
                    </tr>
                    <tr className={"multiPerson " + (!uniformity && 'unsupport')}>
                        <td className="td-half dm-brand-border-r">Root Joint at Origin (Legacy)
                            <DMToolTip
                                text={rootJointOriginTip}
                                iconColor={rootJointOriginTipColor}
                                iconSize=".75rem"
                                tipId="root-joint-tip"
                                isTipHtml={true}
                                noMargins={true}
                            />
                        </td>
                        <td className="td-col-center has-text-weight-semibold dm-brand-border-r"> {params.settings.rootJointAtOrigin ? settingFlags[0] : settingFlags[1]} </td>
                        <td>
                            <YesNoSlider
                                onChangeFunc={(event) => changeRootAtOrigin(event, true)}
                                toolTip={Enums.toolTipRootJoint}
                                value={STATE.rerunSettings.rootJointAtOrigin}
                                centerAlign={true}
                                noMargins={true}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="td-half dm-brand-border-r">Upper Body Only</td>
                        <td className="td-col-center has-text-weight-semibold dm-brand-border-r"> {params.settings.upperBodyOnly ? settingFlags[0] : settingFlags[1]} </td>
                        <td>
                            <YesNoSlider
                                onChangeFunc={(event) => DISPATCH({rerunSettings: {...STATE.rerunSettings, ...{'upperBodyOnly': (event.target.checked ? 1 : 0)}}})}
                                toolTip={Enums.toolTipUpperBodyOnly}
                                isDisabled={false}
                                value={STATE.rerunSettings.upperBodyOnly}
                                centerAlign={true}
                                noMargins={true}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="td-half dm-brand-border-r">Physics Filter</td>
                        <td className="td-col-center has-text-weight-semibold dm-brand-border-r"> {params.settings.physicsSim ? settingFlags[0] : settingFlags[1]} </td>
                        <td>
                            <YesNoSlider
                                onChangeFunc={(event) => DISPATCH({rerunSettings: {...STATE.rerunSettings, ...{'physicsSim': event.target.checked}}})}
                                toolTip={Enums.toolTipPhysSim}
                                isDisabled={false}
                                value={STATE.rerunSettings.physicsSim}
                                centerAlign={true}
                                noMargins={true}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="td-half dm-brand-border-r">{textFloating}</td>
                        <td className="td-col-center has-text-weight-semibold dm-brand-border-r"> {params.settings.footLockMode == Enums.footLockMode.never ? settingFlags[0] : settingFlags[1]} </td>
                        <td>
                            <YesNoSlider
                                onChangeFunc={(event) => DISPATCH({rerunSettings: {...STATE.rerunSettings, ...{'footLockMode': event.target.checked ? Enums.footLockMode.never : Enums.footLockMode.always}}})}
                                toolTip={Enums.toolTipStaticPoseFootLocking}
                                isDisabled={false}
                                value={STATE.rerunSettings.footLockMode == Enums.footLockMode.never ? true : false}
                                centerAlign={true}
                                noMargins={true}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="td-40 dm-brand-border-r">Fallback Pose
                            <DMToolTip
                                text={Enums.tooltipFallbackPose}
                                tipId="fallbackpose-tip"
                                iconColor="#2d4e77"
                                iconSize=".75rem"
                                isTipHtml={true}
                                noMargins={true}
                            />
                        </td>
                        <td className="td-30 td-col-center has-text-weight-semibold is-capitalized dm-brand-border-r">{params.settings.fallbackPose}</td>
                        <td className="td-col-center has-text-weight-semibold">
                            <DMDropDown
                                data={fallbackPoseDropDownData}
                                value={STATE.rerunSettings.fallbackPose}
                                onChange={changeFallbackPoseSelectReRun}
                                rightAlign={true}
                                noMargins={true}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="td-half dm-brand-border-r">
                            {mp4EnableShadowTitle}
                            <DMToolTip
                                text={Enums.toolTipImageShadow}
                                tipId="enableShadowTitle-tip"
                                iconColor="#2d4e77"
                                iconSize=".75rem"
                                isTipHtml={true}
                                noMargins={true}
                            />
                        </td>
                        <td className="td-col-center has-text-weight-semibold dm-brand-border-r">{(params.settings.shadow && params.settings.shadow !== 'N/A' ? settingFlags[0] : settingFlags[1])}</td>
                        <td>
                            <YesNoSlider
                                onChangeFunc={(event) => DISPATCH({rerunSettings: {...STATE.rerunSettings, ...{'shadow': event.target.checked}}})}
                                isDisabled={disabledShadow}
                                value={disabledShadow ? false : STATE.rerunSettings.shadow}
                                centerAlign={true}
                                noMargins={true}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="td-half dm-brand-border-r">Select Background</td>
                        <td className={"td-col-center has-text-weight-semibold dm-brand-border-r " + ((params.outputFormatsList.includes('jpg') || params.outputFormatsList.includes('gif') || params.outputFormatsList.includes('png')) ? "is-capitalized" : "")}>
                            {params.settings.backdrop}
                        </td>
                        <td className="td-col-center has-text-weight-semibold">
                            {hasImageOut ?
                                <DMDropDown
                                    value={STATE.rerunSettings.backdrop}
                                    onChange={(value, cb) => changeCustomBkgdSelect(value, cb, true)}
                                    data={mp4BackDropDownData}
                                    isDisabled={!hasImageOut}
                                    rightAlign={true}
                                    noMargins={true}
                                />
                                :
                                "N/A"
                            }
                        </td>
                    </tr>
                    <tr>
                        <td className="td-half dm-brand-border-r">Background Color</td>
                        <td className="td-col-center has-text-weight-semibold dm-brand-border-r">
                            <span className="is-inline-flex">
                                {/* Only show color box if bgColor is valid */}
                                {
                                    isBgColorUnavailable(params)
                                        ?
                                        "N/A"
                                        :
                                        <React.Fragment>
                                            <button
                                                className="button dm-brand-font"
                                                style={{
                                                    backgroundColor: `rgb(${params.settings.bgColor[0]},${params.settings.bgColor[1]},${params.settings.bgColor[2]})`,
                                                    width: '5rem',
                                                    cursor: 'auto'
                                                }}
                                                data-for="bkgd-color-tip"
                                                data-border={true}
                                                data-border-color="black"
                                                data-tip
                                                data-text-color="#2d4e77"
                                                data-background-color="white"
                                            />
                                            <ReactTooltip className="tip-max-w" id="bkgd-color-tip" place="right" effect="solid">
                                                <p className="subtitle is-5 dm-brand-font has-text-weight-semibold">
                                                    rgb({params.settings.bgColor[0]},{params.settings.bgColor[1]},{params.settings.bgColor[2]})
                                                </p>
                                            </ReactTooltip>
                                        </React.Fragment>
                                }
                            </span>
                        </td>
                        <td className="td-col-center has-text-weight-semibold">
                            <DMDropDownColorPicker
                                data={(!STATE.rerunSettings.bgColor || STATE.rerunSettings.bgColor === 'N/A') ? Enums.defaultGSColor : STATE.rerunSettings.bgColor}
                                value={STATE.rerunSettings.bgColor}
                                onChange={handleBkgdColorChangeReRun}
                                isDisabled={hasImageOut ? !(STATE.rerunSettings.backdrop === Enums.Backdrop.solid || STATE.rerunSettings.backdrop === Enums.Backdrop.studio) : true}
                                isRerun={true}
                                rightAlign={true}
                                noMargins={true}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="td-half dm-brand-border-r">{jobUserModelDetail.isCustomModel ? 'Custom Character' : 'Default Character'}</td>
                        <td className="td-col-center has-text-weight-semibold dm-brand-border-r"> {jobUserModelDetail.name} </td>
                        <td className="td-col-center has-text-weight-semibold"> {jobUserModelDetail.name} </td>
                    </tr>
                    </tbody>
                </table>

                <Backdrop fullWidth Rerun />
            </div>
        )
    }

    /////////////////////////////////////////////////////////////////////
    function enableImageOrVideoType(type, value) {
        if (
            !value &&
            (!(STATE.animJobSettings.formats.gif || type === 'gif') ||
                !(STATE.animJobSettings.formats.jpg || type === 'jpg') ||
                !(STATE.animJobSettings.formats.png || type === 'png') ||
                !(STATE.animJobSettings.formats.mp4 || type === 'mp4'))
        ) {
            DISPATCH({
                animJobSettings: {
                    ...STATE.animJobSettings, ...{
                        'formats': createNewOutputFormatsObj(type, value),
                        'greenScreen': false
                    }
                }
            })
        } else {
            DISPATCH({animJobSettings: {...STATE.animJobSettings, ...{'formats': createNewOutputFormatsObj(type, value)}}})
        }
    }

    /////////////////////////////////////////////////////////////////////
    function enableImageOrVideoTypeRerun(type, value) {
        if (
            !value &&
            (!(STATE.rerunSettings.formats.gif || type === 'gif') ||
                !(STATE.rerunSettings.formats.jpg || type === 'jpg') ||
                !(STATE.rerunSettings.formats.png || type === 'png') ||
                !(STATE.rerunSettings.formats.mp4 || type === 'mp4'))
        ) {
            DISPATCH({
                rerunSettings: {
                    ...STATE.rerunSettings, ...{
                        'formats': createNewOutputFormatsObj(type, value, true),
                        'greenScreen': false
                    }
                }
            })
        } else {
            DISPATCH({rerunSettings: {...STATE.rerunSettings, ...{'formats': createNewOutputFormatsObj(type, value, true)}}})
        }
    }

    /////////////////////////////////////////////////////////////////////
    function changeMP4IncludeOriginalVideo(value) {
        if (value) {
            // if enabling side by side (ie. include original video) also disable
            // background color since cannot set solid background at same time
            DISPATCH({animJobSettings: {...STATE.animJobSettings, ...{'sbs': value, 'greenScreen': false}}})
        } else {
            DISPATCH({animJobSettings: {...STATE.animJobSettings, ...{'sbs': value}}})
        }
    }

    /////////////////////////////////////////////////////////////////////
    function changeMP4IncludeOriginalVideoReRun(value) {
        if (value) {
            // if enabling side by side (ie. include original video) also disable
            // background color since cannot set solid background at same time
            DISPATCH({rerunSettings: {...STATE.rerunSettings, ...{'sbs': value, 'greenScreen': false}}})
        } else {
            DISPATCH({rerunSettings: {...STATE.rerunSettings, ...{'sbs': value}}})
        }
    }

    ////////////////////////////////////////////////////////////////////////
    function changeFootLockingSelect(val, cb) {
        DISPATCH({animJobSettings: {...STATE.animJobSettings, ...{'footLockMode': val}}})
        if (cb) {
            cb()
        }
    }

    ////////////////////////////////////////////////////////////////////////
    function changeFootLockingSelectReRun(val, cb) {
        DISPATCH({rerunSettings: {...STATE.rerunSettings, ...{'footLockMode': val}}})
        if (cb) {
            cb()
        }
    }

    ////////////////////////////////////////////////////////////////////////
    function changeFallbackPoseSelect(val, cb) {
        DISPATCH({animJobSettings: {...STATE.animJobSettings, ...{'fallbackPose': val}}})
        if (cb) {
            cb()
        }
    }

    ////////////////////////////////////////////////////////////////////////
    function changeFallbackPoseSelectReRun(val, cb) {
        DISPATCH({rerunSettings: {...STATE.rerunSettings, ...{'fallbackPose': val}}})
        if (cb) {
            cb()
        }
    }

    ////////////////////////////////////////////////////////////////////////
    function changeMp4OutputCameraMotion(value, cb) {
        DISPATCH({animJobSettings: {...STATE.animJobSettings, ...{'camMode': value}}})
        if (cb) {
            cb()
        }
    }

    ////////////////////////////////////////////////////////////////////////
    function changeMp4OutputCameraMotionReRun(value, cb) {
        DISPATCH({rerunSettings: {...STATE.rerunSettings, ...{'camMode': value}}})
        if (cb) {
            cb()
        }
    }

    ////////////////////////////////////////////////////////////////////////
    function changeMp4OutputCameraAngle(value, index) {
        DISPATCH({animJobSettings: {...STATE.animJobSettings, ...{'camHorizontalAngle': value}}})
    }

    ////////////////////////////////////////////////////////////////////////
    function changeMp4OutputCameraAngleReRun(value, index) {
        DISPATCH({rerunSettings: {...STATE.rerunSettings, ...{'camHorizontalAngle': value}}})
    }

    ////////////////////////////////////////////////////////////////////////
    function changeCustomBkgdSelect(value, cb, reRun = false) {
        const newAnimSettings = reRun ? STATE.rerunSettings : STATE.animJobSettings

        newAnimSettings.backdrop = value
        if (value === Enums.Backdrop.solid || value === Enums.Backdrop.studio) {
            newAnimSettings.greenScreen = true
        } else {
            newAnimSettings.greenScreen = false
        }
        if (value === Enums.Backdrop.transparent){
            newAnimSettings.formats = {...newAnimSettings.formats, gif: true, png: true, jpg: false}
        }
        newAnimSettings.shadow = value === Enums.Backdrop.environments ? false : true

        const jobSettings = reRun ? { rerunSettings: newAnimSettings } : { animJobSettings: newAnimSettings }

        DISPATCH(jobSettings)
        if (cb) {
            cb()
        }
    }

    /////////////////////////////////////////////////////////////////////
    // Helper functions for setting animation output |formats| which
    // is a property (ie key) of job state object
    /////////////////////////////////////////////////////////////////////
    function createNewOutputFormatsObj(format, val, isRerun?: boolean) {
        let formatsObj = isRerun
            ? JSON.parse(JSON.stringify(STATE.rerunSettings.formats))
            : JSON.parse(JSON.stringify(STATE.animJobSettings.formats))
        formatsObj[format] = (typeof (val) === undefined) ? false : val
        return formatsObj
    }

    /////////////////////////////////////////////////////////////////////
    function getCameraSettingsDropDownData() {
        let cameraSettingsDropDownData = []
        Enums.cameraMotionSettings.forEach((value) => {
            cameraSettingsDropDownData.push({value: value, available: true})
        })
        return cameraSettingsDropDownData
    }

    /////////////////////////////////////////////////////////////////////
    function handleBkgdColorChange(color) {
        // only 100% alpha allow currently
        DISPATCH({animJobSettings: {...STATE.animJobSettings, ...{'bgColor': [color.rgb.r, color.rgb.g, color.rgb.b, 1]}}})
    }

    /////////////////////////////////////////////////////////////////////
    function handleBkgdColorChangeReRun(color) {
        // only 100% alpha allow currently
        DISPATCH({rerunSettings: {...STATE.rerunSettings, ...{'bgColor': [color.rgb.r, color.rgb.g, color.rgb.b, 1]}}})
    }

    /////////////////////////////////////////////////////////////////////
    function resetSelectedInputVideo(newJobType) {
        const resetObj = {
            selectedFile: null,
            fileName: null,
            fileLength: null,
            fileSize: null,
            videoRes: null,
            fps: null,
            codec: null
        }
        let newAnimJobSettings = (STATE.animJobSettings.jobType !== newJobType) ? JSON.parse(STATE.animJobSettingsSnapshots) : STATE.animJobSettings
        // if in FTE mode we always send the user back to the add video clip screen
        // whether the video failed on upload step or during actual job processing
        let dispatchObject = {
            currWorkflowStep: Enums.uiStates.initial,
            currFTEStep: Enums.FTESteps.addMotionClip,
            videoStorageUrl: null,
            videoFileName: null,
            inputVideoData: resetObj,
            animJobSettings: {...newAnimJobSettings, ...{jobType: newJobType ? 1 : 0}},
            animJobSettingsSnapshots: (STATE.animJobSettings.jobType !== newJobType) ? JSON.stringify(STATE.animJobSettings) : STATE.animJobSettingsSnapshots,
            confirmDialogId: Enums.confirmDialog.none
        }
        setFallback(fallbackInit)
        DISPATCH(dispatchObject)
        // clear multi person detail
        clearMultiPersonDetail()
    }

    /******************************************************************
     * performBackgroundVideoUpload() - called once input video is selected,
     *  starts uploading input video asynchoronously in the background
     *  while user continues job configuration. Once done we call video
     *  analyzer API to retrieve metadata including length and size.
     *
     * @param retry : if we should retry on auth error encountered
     ******************************************************************/
    async function performBackgroundVideoUpload(retry) {
        let storageUrl: any = null
        let res: any = await uploadJobDataToBackend(
            STATE.videoFileName,
            STATE.inputVideoData.selectedFile,
            retry
        )
            .catch((error) => {
                console.error(`Error detected attempting to upload job input in background: ${error}`)
                throw error
            })

        storageUrl = res.videoStorageUrl
        res = await analyzeVideoInputData(res.videoStorageUrl, true)
            .catch((error) => {
                console.error(`Error detected during analyzeVideoInputData: ${error}`)
                throw error
            })
        let newStateData = {}
        let inputData = STATE.inputVideoData
        if (!res.data || Object.keys(res.data).length === 0 || !res.data.duration || !res.data.width || !res.data.fps) {
            // abort job if problem with request or could not read input
            // video time, resolution, or fps
            inputData.errorFlags = {videoReadFailure: true}
            newStateData = {...newStateData, ...{inputVideoData: inputData}}
            newStateData = {...newStateData, ...{videoStorageUrl: null}}
            newStateData = {...newStateData, ...{videoFileName: null}}
            newStateData = {...newStateData, ...{silentUploadInProgress: false}}
            throw 'Video validation failed!'
        } else {
            let duration = res.data.duration
            if (typeof (res.data.duration) === 'string') {
                duration = parseFloat(res.data.duration)
            }
            inputData.fileLength = duration
            inputData.fileSize = res.data.size
            inputData.videoRes = {w: res.data.width, h: res.data.height}
            inputData.fps = res.data.fps
            inputData.codec = res.data.codec
            newStateData = {
                ...newStateData,
                ...{inputVideoData: inputData},
                ...{videoStorageUrl: storageUrl}
            }
            DISPATCH(newStateData)
            return 'ok'
        }
    }

    /////////////////////////////////////////////////////////////////////
    // Handle local file selection from the user
    /////////////////////////////////////////////////////////////////////
    function proceedUpload(name, array) {
        const video = document.createElement('video')
        video.src = URL.createObjectURL(array[0])
        setTimeout(() => {
            let tmpObj = STATE.inputVideoData
            if (!tmpObj || tmpObj === undefined) {
                tmpObj = {}
            }
            tmpObj.selectedFile = array[0]
            tmpObj.fileName = name
            DISPATCH({
                currWorkflowStep: Enums.uiStates.fileSelected,
                inputVideoData: tmpObj,
                videoFileName: name,
                silentUploadInProgress: true
            })
        }, 100)
    }

    function uploadOnchange(array) {
        // if parameter is null/undefined/not-array, return
        if (!Array.isArray(array) || !array.length) {
            return;
        }
        const name = array[0].name
        const size = array[0].size

        // reject unwanted filetypes
        const splitExt = name.split('.')
        const ext = splitExt[splitExt.length - 1].toLowerCase()
        let formatAccepted = false
        let acceptedFormatsListBasedOnJob = null
        if (STATE.animJobSettings.jobType === Enums.jobTypes.animation) {
            acceptedFormatsListBasedOnJob = Enums.acceptedVideoFormats
        } else {
            acceptedFormatsListBasedOnJob = Enums.acceptedStaticPoseFormats
        }
        for (let i = 0; i < acceptedFormatsListBasedOnJob.length; i++) {
            if (ext === acceptedFormatsListBasedOnJob[i]) {
                formatAccepted = true
                break
            }
        }
        if (!formatAccepted) {
            console.error(`Error: File "${name}" is of invalid type. Accepted formats are ${JSON.stringify(acceptedFormatsListBasedOnJob)}`)
            DISPATCH({confirmDialogId: Enums.confirmDialog.inputFileTypeWrong, videoFileName: name})
            return
        }
        // reject exceeded file size
        if (size > checkFeatureAvailability(STATE.subscriptionInfo.name, Enums.featureLockNames.maxSize)) {
            tmpSize = Enums.formatSizeUnits(size, 2)
            console.error(`Error: Max input clip size of ${Enums.formatSizeUnits(checkFeatureAvailability(STATE.subscriptionInfo.name, Enums.featureLockNames.maxSize), 2)} exceeded. Input clip size was `, tmpSize, ".\nAborting job request...")
            DISPATCH({dialogInfo: {size: tmpSize}, confirmDialogId: Enums.confirmDialog.inputFileTooBig})
            return
        }

        // limit filename characters to standard Unicode UTF-8
        const sanitized = Enums.removeIllegalOrReservedCharactersFromFileName(name)
        const bNameContainsInvalidCharacters = Enums.containsNonAsciiCharacters(name) || (sanitized.length !== name.length)
        const bNameExceedsMaxLen = name.length > Enums.MAX_FILENAME_LENGTH
        if (bNameContainsInvalidCharacters || bNameExceedsMaxLen) {
            console.error(`Error: File name "${name}" contains invalid characters or exceeds max length.`)
            const fileExt = !sanitized ? "" : sanitized.split(".").pop()
            const fileNameWithExtRemoved = sanitized.replace(/\.[^/.]+$/, "")
            let message = bNameContainsInvalidCharacters ? `The file name contains unsupported characters ${(() => {
                let charSet1 = new Set(name.split(""));
                let charSet2 = new Set(sanitized.split(""));
                let unsupportedCharSet = new Set([...charSet1].filter(x => !charSet2.has(x)));
                return `"${[...unsupportedCharSet].join('", "')}"`
            })()}.` : ""
            message = bNameExceedsMaxLen ? message.concat(bNameContainsInvalidCharacters ? " " : "", `File name length should not exceed ${Enums.MAX_FILENAME_LENGTH}.`) : message
            message = message.concat(" ", "Please, rename the file.")
            DISPATCH({
                dialogInfo: {
                    videoFileName: name, message: message, fileName: fileNameWithExtRemoved,
                    onConfirm: (inputText) => {
                        const dialogData = {confirmDialogId: Enums.confirmDialog.none, dialogInfo: {}}
                        DISPATCH(dialogData)
                        proceedUpload(`${inputText}.${fileExt}`, array)
                    },
                    validateInput: (inputText) => {
                        return (inputText.length > 0) && !Enums.containsNonAsciiCharacters(inputText) && (Enums.removeIllegalOrReservedCharactersFromFileName(inputText).length == inputText.length) && (inputText.length <= Enums.MAX_FILENAME_LENGTH)
                    }
                },
                // confirmDialogId: Enums.confirmDialog.inputFileNameInvalid
                confirmDialogId: Enums.confirmDialog.inputFileNameRename
            })
        } else {
            proceedUpload(name, array)
        }
    }

    /////////////////////////////////////////////////////////////////////
    // resetDialogsData() - called when a job is cancelled or fails and also
    // when this component unmounts...
    /////////////////////////////////////////////////////////////////////
    function resetDialogsData(error, saveDuration) {
        // reset some local and global state hooks
        setActiveJobMenu(Enums.jobMenu.animSettings)
        setShowColorPickerDialog(false)
        // preserve video duration and name for dialogs if saveDuration = true
        const duration = saveDuration ? STATE.inputVideoData.fileLength : null
        const fName = saveDuration ? STATE.inputVideoData.fileName : null
        const resetVideoData = {
            selectedFile: null,
            fileName: fName,
            fileLength: duration,
            fileSize: null,
            // save videoRes data for use in error dialogs if errorFlags present
            videoRes: (STATE.inputVideoData && STATE.inputVideoData.errorFlags) ? STATE.inputVideoData.videoRes : null,
            fps: null,
            codec: null,
            // save temporary errorFlags if present
            errorFlags: (STATE.inputVideoData && STATE.inputVideoData.errorFlags) ? STATE.inputVideoData.errorFlags : null
        }
        DISPATCH({
            confirmDialogId: Enums.confirmDialog.none,
            currWorkflowStep: Enums.uiStates.initial,
            dialogInfo: {},
            animationJobProgress: 0,
            isJobInProgress: false,
            silentUploadInProgress: false
        })
        if (error) {
            // if error passed in propogate up to handleHttpError() in dashboard
            handleHttpError(error, "")
        }
        return true
    }

    /////////////////////////////////////////////////////////////////////
    // cancels a users subscription and redirects them to survey page
    /////////////////////////////////////////////////////////////////////
    function cancelSubscriptionAndRedirect() {
        cancelSubscription(selectedSubscription.sub_id)
            .then(res => {
                // TODO:
                // if no error, send user to page with survey
                // create page with survey
            })
    }

    /////////////////////////////////////////////////////////////////////
    // Removes user's application(s) access to any DM apps,
    // de-activates their account, and logs the user out
    /////////////////////////////////////////////////////////////////////
    async function closeUserAccount() {

        // first get idToken
        const token = await getToken('idToken')
        // console.log(`id token: ${token}`)

        console.log(`Attempting to close user account`)
        let reqBody = {
            idToken: token,
            uid: STATE.uid,
            email: STATE.email
        }
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/closeUserAccount`, reqBody,
            {withCredentials: process.env.REACT_APP_NODE_ENV !== 'development'})
            .catch((error) => {
                //TODO: throw an error dialog
                console.error(error)
                return null
            })
        if (res == null) return
        // TODO: Re-direct to "Account Closed" landing page when done!
        console.log(`User account has been closed.\n${res}`)
        closedAccount()
    }

    /////////////////////////////////////////////////////////////////////
    // Dynamically retrieves output animation types for a given job and
    // returns as an array of sorted strings per extension
    /////////////////////////////////////////////////////////////////////
    function retrieveJobOutputFileTypes() {
        if (!STATE.animJobId || STATE.animJobId === "") {
            return null
        }
        const jobDetails = getJobDetailsById(STATE.animJobId)
        const jobUsesRobloxModel = jobDetails.customModel === Enums.robloxModelId ? true : false
        let outputFileTypesList = []
        if (STATE.currDownloadLinks) {
            let sortedLinks = Enums.sortObject(STATE.currDownloadLinks)
            for (const [key, value] of Object.entries(sortedLinks)) {
                if (key.includes(Enums.animFileType.bvh) && !jobUsesRobloxModel) {
                    outputFileTypesList.push(Enums.animFileType.bvh.toUpperCase())
                } else if (key.includes(Enums.animFileType.fbx)) {
                    outputFileTypesList.push(Enums.animFileType.fbx.toUpperCase())
                } else if (key.includes(Enums.animFileType.glb) && !jobUsesRobloxModel) {
                    outputFileTypesList.push(Enums.animFileType.glb.toUpperCase())
                } else if (key.includes(Enums.animFileType.mp4)) {
                    outputFileTypesList.push(Enums.animFileType.mp4.toUpperCase())
                } else if (key.includes(Enums.animFileType.gif)) {
                    outputFileTypesList.push(Enums.animFileType.gif.toUpperCase())
                } else if (key.includes(Enums.animFileType.dmpe)) {
                    outputFileTypesList.push(Enums.animFileType.dmpe.toUpperCase())
                } else if (key.includes(Enums.animFileType.jpg)) {
                    outputFileTypesList.push(Enums.animFileType.jpg.toUpperCase())
                } else if (key.includes(Enums.animFileType.png)) {
                    outputFileTypesList.push(Enums.animFileType.png.toUpperCase())
                }
            }
            return outputFileTypesList
        }
    }

    ////////////////////////////////////////////////////////////////////
    // General error handling function for http errors
    // TODO: Refactor params to only use dialogInfo object
    ////////////////////////////////////////////////////////////////////
    function handleHttpError(error, title, dialogInfo?) {
        if (dialogInfo.error && dialogInfo.message) {
            setErrorDialogInfo(true, dialogInfo.message, dialogInfo.error)
        }

        // 400 - Bad request (usually a problematic model or video upload from user)
        if (error.response && error.response.status === Enums.eCodes.BadRequest) {
            console.log("There was a problem with your request, please try again. If the problem continues please contact service@deepmotion.com.")
            setErrorDialogInfo(true, Enums.eCodes.BadRequest, title)
        }
        // 401 - User unauthorized (token expired, deleted, or corrupted)
        else if (error.response && error.response.status === Enums.eCodes.Unauthorized) {
            console.log("User un-authenticated (token has expired or been removed), please sign in again.")
            logoutUser()
        }
        // 403 - Forbidden
        else if (error.response && error.response.status === Enums.eCodes.Forbidden) {
            console.log("403 - Access Forbidden.")
            setErrorDialogInfo(true, Enums.eCodes.Forbidden, title)
            return
        }
        // 404 - Not Found
        else if (error.response && error.response.status === Enums.eCodes.NotFound) {
            console.log("404 - Not Found")
            setErrorDialogInfo(true, Enums.eCodes.NotFound, title)
            return
        }
        // 408 - Request Timeout
        else if (error.response && error.response.status === Enums.eCodes.RequestTimeout) {
            console.log("The request timed out, this could be due to networking/internet issues or possibly a server side problem. Please try your request again, if the problem continues contact service@deepmotion.com.")
            setErrorDialogInfo(true, Enums.eCodes.RequestTimeout, title)
        }
        // 444 - Closed No Response
        else if (error.response && error.response.status === Enums.eCodes.ClosedNoResponse) {
            console.log("The request was closed without a response, if this problem continues please contact service@deepmotion.com.")
            //TODO - pop modal
        }
        // 499 - Client Closed Request
        else if (error.response && error.response.status === Enums.eCodes.ClientClosedRequest) {
            console.log("The request was closed by the client, please try again. If the problem continues please contact service@deepmotion.com.")
            //TODO - pop modal
        }
            /*********************************************
             Server Side HTTP Errors
             **********************************************/
        // 500 - Internal Server Error
        else if (error.response && error.response.status === Enums.eCodes.InternalServerError) {
            console.log("Sorry there was an internal server error. Please try again, if the problem continues please contact service@deepmotion.com.")
            setErrorDialogInfo(
                true,
                Enums.eCodes.InternalServerError,
                Enums.customErrors[error.response.data.error],
                error.response.data.message, () => {
                    return
                })
        }
        // 502 - Bad Gateway
        else if (error.response && error.response.status === Enums.eCodes.BadGateway) {
            console.log("Sorry we encountered a networking error - bad gateway. Please try again, if the problem continues please contact service@deepmotion.com.")
            setErrorDialogInfo(true, Enums.eCodes.BadGateway, title)
        }
        // 503 - Service Unavailable
        else if (error.response && error.response.status === Enums.eCodes.ServiceUnavailable) {
            console.log("Sorry the service is currently unavailble, exiting application. If the problem continues please contact service@deepmotion.com.")
            logoutUser()
        }
        // 504 - Gateway Timeout
        else if (error.response && error.response.status === Enums.eCodes.GatewayTimeout) {
            console.log("Sorry we encountered a networking error - the gateway timed out. Please try again, if the problem continues please contact service@deepmotion.com.")
            //TODO - pop modal
        }
        // 507 - Insufficient Storage
        else if (error.response && error.response.status === Enums.eCodes.InsufficientStorage) {
            console.log("Sorry there was a problem, the server is reporting insufficient storage space. Please contact DeepMotion at service@deepmotion.com to resolve.")
            //TODO - pop modal
        }
            // Unknown error - i.e. status and error fields not present in response
        // (can occur due to CORS errors for instance)
        else {
            setErrorDialogInfo(true, Enums.eCodes.OtherError, title)
        }
    }

    /////////////////////////////////////////////////////////////////////
    // Error dialog setter
    /////////////////////////////////////////////////////////////////////
    function setErrorDialogInfo(show, id, title, msg?, cb?) {
        let tmpObj = {show: show, id: id, title: !id ? "" : title, msg: !id ? "" : msg}
        DISPATCH({errorDialogInfo: tmpObj})
    }

    /////////////////////////////////////////////////////////////////////
    // logs the user out by redirecting to the login page
    /////////////////////////////////////////////////////////////////////
    async function logoutUser() {
        cleanTokens()
        await oktaAuth.signOut()
    }

    /////////////////////////////////////////////////////////////////////
    // Builds the side bar menu on left hand of screen
    /////////////////////////////////////////////////////////////////////
    function buildSideBarMenu() {
        return (
            <div/>
        )
    }

    /////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////
    // --- Custom Dialogs & related functions ---
    /////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////

    ///--------------------------------------------------------------------
    /// Modal for downloading animations using our standard cloud
    /// character set (ie various female, male, and child models)
    ///--------------------------------------------------------------------
    function DownloadDefaultCharacterModal() {
        if (Object.keys(STATE.currDownloadLinks).length === 0) {
            console.log(`Warning: Animation download dialog invoked with invalid job download links: ${STATE.currDownloadLinks}`)
            return
        }
        if (!STATE.animJobId) {
            console.log(`Warning: Animation download dialog invoked with invalid animJobId: ${STATE.animJobId}`)
            return
        }
        const jobDetails = getJobDetailsById(STATE.animJobId)
        if (!jobDetails) {
            return (
                <div id="modal-ter" className="modal is-active">
                    <div className="modal-background"></div>
                    <div className="modal-card" id="anim-fadein">
                        <header className="modal-card-head m-0">
                            <p className="modal-card-title"> Download Animations </p>
                            <button className="delete" onClick={() => closeModal()} aria-label="close"></button>
                        </header>
                        <section className={`modal-card-body m-0 p-0`} style={{overflowX: 'hidden'}}>
                            <div className="columns">
                                <div className="column">
                                    <div className="notification subtitle is-info is-light">
                                        Animation downloads are not available when they are replaced with a recent
                                        rerun.
                                    </div>
                                </div>
                            </div>
                        </section>
                        <footer className="modal-card-foot m-0">
                            <div className="columns m-0 fullwidth">
                                <div className="column p-0">
                                    <div className="buttons is-right">
                                        <button className="button is-white dm-brand-font" tabIndex={0}
                                                onClick={() => closeModal()}> {textDialogClose} </button>
                                    </div>
                                </div>
                            </div>
                        </footer>
                    </div>
                </div>
            )
        }
        const animName = jobDetails.name
        const duration = jobDetails.length
        const cDate = new Date(jobDetails.dateRaw).toLocaleString(Enums.getNavigatorLanguage())
        const params = {
            animName: animName,
            duration: duration,
            cDate: cDate
        }

        return (
            <div id="modal-ter" className="modal is-active">
                <div className="modal-background"></div>
                <div className="modal-card" id="anim-fadein">
                    <header className="modal-card-head m-0">
                        <p className="modal-card-title"> Download Animations </p>
                        <button className="delete" onClick={() => closeModal()} aria-label="close"></button>
                    </header>
                    <section className={`modal-card-body m-0 p-0`} style={{overflowX: 'hidden'}}>
                        <div className="content">
                            <div className="columns m-0">
                                <div className="column p-0">

                                    {buildAnimationHeaderSection(params)}
                                    <div className="columns m-0 fullwidth">

                                        {/*** Build default character selector ***/}
                                        <div className="column">
                                            <div className="columns m-0">
                                                <div className="column m-0 has-text-centered">
                                                    <CharacterSwiperDefault DISPATCH={DISPATCH} setCurrDownloadModel={setCurrDownloadModel}/>
                                                </div>
                                            </div>
                                        </div>

                                        {/*** Build Output File Type Dropdown ***/}
                                        <div className="column">
                                            <div className="columns m-0">
                                                <div className="column has-text-centered">
                                                    {buildOutputFileTypeDropDown()}
                                                </div>
                                            </div>

                                            {/*** Build Download Button ***/}
                                            <div className="column">
                                                <div className="columns m-0">
                                                    {buildAnimationDownloadButton()}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>
                    <footer className="modal-card-foot m-0">
                        <div className="columns m-0 fullwidth">
                            <div className="column p-0">
                                <div className="buttons is-right">
                                    <button className="button is-white dm-brand-font" tabIndex={0}
                                            onClick={() => closeModal()}> {textDialogClose} </button>
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        )

    }

    function DownloadMultiPersonCharacterModal() {
        const characters = STATE.animJobLinks.multiPersonDownloadLinks;
        return (
            <div id="modal-ter" className="modal is-active">
                <div className="modal-background"></div>
                <div className="modal-card modal-card-big" id="anim-fadein">
                    <header className="modal-card-head m-0">
                        <p className="modal-card-title"> Download Animations </p>
                        <button
                            className="delete"
                            onClick={() => closeModal()}
                            aria-label="close"
                        ></button>
                    </header>
                    <section
                        className={`modal-card-body m-0 p-0`}
                        style={{overflowX: "hidden"}}
                    >
                        <MultiPersonSelectorDownloadDialog/>
                    </section>
                    <footer className="modal-card-foot m-0">
                        <div className="columns m-0 fullwidth">
                            <div className="column p-0">
                                <div className="buttons is-right">
                                    <button
                                        className="button is-white dm-brand-font"
                                        tabIndex={0}
                                        onClick={() => closeModal()}
                                    >
                                        {" "}
                                        {textDialogClose}{" "}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        )
    }

    ///--------------------------------------------------------------------
    /// Modal for downloading animations using our standard cloud
    /// character set (ie various female, male, and child models)
    ///
    /// @param mode : if true stops current preview and resets
    ///   download links when closing the modal
    ///--------------------------------------------------------------------
    function DownloadCustomCharacterModal(mode?) {
        const jobDetails = getJobDetailsById(STATE.animJobId)
        if (!jobDetails) {
            return (
                <div id="modal-ter" className="modal is-active">
                    <div className="modal-background"></div>
                    <div className="modal-card" id="anim-fadein">
                        <header className="modal-card-head m-0">
                            <p className="modal-card-title"> Download Animations </p>
                            <button className="delete" onClick={() => closeModal()} aria-label="close"></button>
                        </header>
                        <section className={`modal-card-body m-0 p-0`} style={{overflowX: 'hidden'}}>
                            <div className="columns">
                                <div className="column">
                                    <div className="notification subtitle is-info is-light">
                                        Animation downloads are not available when they are replaced with a recent
                                        rerun.
                                    </div>
                                </div>
                            </div>
                        </section>
                        <footer className="modal-card-foot m-0">
                            <div className="columns m-0 fullwidth">
                                <div className="column p-0">
                                    <div className="buttons is-right">
                                        <button className="button is-white dm-brand-font" tabIndex={0}
                                                onClick={() => closeModal()}> {textDialogClose} </button>
                                    </div>
                                </div>
                            </div>
                        </footer>
                    </div>
                </div>
            )
        }
        const textCustom = "(Custom)"
        const animName = jobDetails.name
        const duration = jobDetails.length
        const cDate = new Date(jobDetails.dateRaw).toLocaleString(Enums.getNavigatorLanguage())
        const imgData = getModelDataById(jobDetails.customModel)
        let imgName = null
        let thumbImg = null
        // special case for face tracking, display our female face tracking model
        if (jobDetails.customModel === Enums.robloxModelId) {
            imgName = "Roblox R15"
            thumbImg = imgRobloxR15
        } else if (jobDetails.settings.trackFace && jobDetails.customModel === "standard") {
            imgName = "Default Face Model"
            thumbImg = imgFaceModel
        } else {
            imgName = (imgData && imgData.name) ? imgData.name : textCustom
            thumbImg = (imgData && imgData.thumbImg instanceof Blob) ? URL.createObjectURL(imgData.thumbImg) : imgCustom
        }
        const params = {
            animName: animName,
            duration: duration,
            cDate: cDate
        }

        return (
            <div id="modal-ter" className="modal is-active">
                <div className="modal-background"></div>
                <div className="modal-card" id="anim-fadein">
                    <header className="modal-card-head m-0">
                        <p className="modal-card-title"> Download Animations </p>
                        <button className="delete" onClick={() => closeModal()} aria-label="close"></button>
                    </header>
                    <section className={`modal-card-body m-0 p-0`} style={{overflowX: 'hidden'}}>
                        <div className="content">
                            <div className="columns m-0">
                                <div className="column p-0">
                                    {buildAnimationHeaderSection(params)}
                                    <div className="columns m-0 fullwidth">

                                        {/*** Show custom character image ***/}
                                        <div className="column">
                                            <div className="columns m-0">
                                                <div className="column m-0">
                                                    <div className="card dm-brand-border-lg">
                                                        <div className="card-image">
                                                            <figure className={"image m-0 " + thumbnailRatio}
                                                                    style={{borderRadius: '4px', overflow: 'hidden'}}>
                                                                <img onLoad={(img) => calcThumbnailRatio(img)}
                                                                     src={thumbImg} alt={`image-${imgName}`}/>
                                                            </figure>
                                                            {
                                                                thumbImg === imgCustom
                                                                &&
                                                                <div className="thumbnail-unavail has-text-centered">
                                                                  <div
                                                                    className="thumbnail-unavail-content dash-prod-btn subtitle is-4">
                                                                      {textNotAvailable}
                                                                  </div>
                                                                </div>
                                                            }
                                                        </div>
                                                        <div
                                                            className="card-content bShadow dm-brand has-text-centered">
                                                            <div className="media">
                                                                <div className="media-content">
                                                                    <p className="title is-5 mb-4 has-text-white">{imgName}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/*** Build Output File Type Dropdown ***/}
                                        <div className="column">
                                            <div className="columns m-0">
                                                <div className="column has-text-centered">
                                                    {buildOutputFileTypeDropDown()}
                                                </div>
                                            </div>

                                            {/*** Build Download Button ***/}
                                            <div className="column">
                                                <div className="columns m-0">
                                                    {buildAnimationDownloadButton()}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <footer className="modal-card-foot m-0">
                        <div className="columns m-0 fullwidth">
                            <div className="column p-0">
                                <div className="buttons is-right">
                                    <button className="button is-white dm-brand-font" tabIndex={0}
                                            onClick={() => closeModal()}> {textDialogClose} </button>
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        )
    }

    ///--------------------------------------------------------------------
    /// Confirm deletion of custom character model
    ///--------------------------------------------------------------------
    function DeleteCustomCharacterModal() {
        let image = (STATE.dialogInfo.thumbImg instanceof Blob) ? URL.createObjectURL(STATE.dialogInfo.thumbImg) : imgCustom
        let cDate = new Date(STATE.dialogInfo.date).toLocaleString(Enums.getNavigatorLanguage())
        const dialogBody = [
            <div key='remove-model-confirm'>
                <div className="columns m-0 pl-3 pr-3 fullwidth download-modal-bkgd">
                    <div className="column is-1 mr-5">
            <span className="icon is-large">
              <i className="fas fa-trash-alt fa-3x has-text-white"></i>
            </span>
                    </div>
                    <div className="column p-0">
                        <div className="columns m-0">
                            <div className="column is-3 pb-1 m-0 has-text-right">
                                <h1 className="subtitle is-6 has-text-white"> Name: </h1>
                            </div>
                            <div className="column pb-1 m-0 has-text-left">
                                <h1 className="subtitle is-6 dm-brand-2-font"> {STATE.dialogInfo.name} </h1>
                            </div>
                        </div>
                        <div className="columns m-0">
                            <div className="column is-3 pb-1 pt-1 m-0 has-text-right">
                                <h1 className="subtitle is-6 has-text-white">Created:</h1>
                            </div>
                            <div className="column pb-1 pt-1 m-0 has-text-left">
                                <h1 className="subtitle is-6 dm-brand-2-font"> {cDate} </h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="columns m-4">
                    <div className="column has-text-centered is-vcentered">
                        <div className="is-relative">
                            <figure className={"image bShadow " + deleteModelThumbnailRatio}>
                                <img onLoad={(img) => calcDeleteModelThumbnailRatio(img)} src={image}
                                     alt={STATE.dialogInfo.name} className="img-outline"/>
                                {
                                    !(STATE.dialogInfo.thumbImg instanceof Blob)
                                    &&
                                    <div className="thumbnail-unavail has-text-centered">
                                      <div className="thumbnail-unavail-content dash-prod-btn subtitle is-4">
                                        Thumbnail Not Available
                                      </div>
                                    </div>
                                }
                            </figure>
                        </div>
                    </div>
                    <div className="column has-text-left">
                        <p className="subtitle is_3">
                            Are you sure you would like to delete this custom character? Once deleted the character can
                            not be retrieved.
                        </p>
                    </div>
                </div>
            </div>
        ]
        return (
            <DMDialog
                title="Delete Character"
                content={dialogBody}
                msgFormat="html"
                label1={textDialogCancel}
                action1={() => closeModal()}
                label2={textDialogDelete}
                action2={() => deleteCustomModel(STATE.dialogInfo.modelId, STATE.dialogInfo.clbFunc)}
            />
        )
    }

    ///--------------------------------------------------------------------
    /// Not enough animation minutes modal (Re-uses displayVideoValidationErrors()
    /// function to display which can also be used to display multiple validation
    /// errors at same time (such as max resolution, size, FPS exceeded)
    ///--------------------------------------------------------------------
    function buildNotEnoughMinutesModal() {
        let errObj: PortalErrorFlags = {}
        errObj.minsBalanceTooLow = true
        return displayVideoValidationErrors(errObj)
    }

    function resetJobSettingsAndInputVideoAfterError() {
        let newStateData = {
            animJobSettings: JSON.parse(JSON.stringify(Enums.JOB_SETTINGS_TEMPLATE)),
            inputVideoData: null,
            currWorkflowStep: Enums.uiStates.initial,
            isJobInProgress: false,
            silentUploadInProgress: false,
            animationJobProgress: 0,
            videoStorageUrl: null,
            videoFileName: null

        }
        if (STATE.animJobSettings.jobType) {
            newStateData.animJobSettings.jobType = STATE.animJobSettings.jobType
        }
        if (STATE.animJobSettings.customModelInfo.id) {
            newStateData.animJobSettings.customModelInfo = STATE.animJobSettings.customModelInfo
        }
        setFallback(fallbackInit)
        DISPATCH(newStateData)
    }

    function upgradeButtonClicked() {
        if (STATE.subscriptionInfo.name !== Enums.accountPlansInfo[Enums.accountPlansInfo.length - 1].name &&
            STATE.subscriptionInfo.name !== Enums.accountPlansInfo[Enums.accountPlansInfo.length - 2].name) {
            if (STATE.subscriptionInfo.name === Enums.accountPlansInfo[0].name) {
                if (STATE.subscriptionInfo.status === 'active' || STATE.subscriptionInfo.status === 'past_due') {
                    redirectToPricingPageForNewPurchase()
                } else {
                    // It is an 'incomplete' paid subscription
                    redirectToCustomerPortal()
                }
            } else {
                // It is on a paid plan
                redirectToPricingPageWithCustomerPortal()
            }
        }
        closeModal()
    }

    ///--------------------------------------------------------------------
    /// Video validation failed dialog - can display multiple error(s)
    ///--------------------------------------------------------------------
    function buildVideoValidationFailed() {
        let msg = []
        let title = []
        const mediaType = STATE.animJobSettings.jobType ? "Image" : "Video"
        let bodyCredits = STATE.inputVideoData.fileLength
        let faceCredits = STATE.animJobSettings.trackFace ? bodyCredits * 0.5 : 0
        let handCredits = STATE.animJobSettings.trackHand ? bodyCredits * 0.5 : 0
        let totalCredits = Math.ceil(bodyCredits + faceCredits + handCredits)
        let creditStr = 'and it needs ' + totalCredits + ' credits to process'
        if (STATE.animJobSettings.trackFace && !STATE.animJobSettings.trackHand) {
            creditStr += " with face tracking turned on"
            console.log('buildVideoValidationFailed: case 0')
        } else if (STATE.animJobSettings.trackHand && !STATE.animJobSettings.trackFace) {
            creditStr += " with hand tracking turned on"
            console.log('buildVideoValidationFailed: case 1')
        } else if (STATE.animJobSettings.trackHand && STATE.animJobSettings.trackFace) {
            creditStr += " with face and hand tracking turned on"
            console.log('buildVideoValidationFailed: case 2')
        }

        let errorMsgs = [
            STATE.inputVideoData.fileLength ? `Not enough animation credits available, video is ${STATE.inputVideoData.fileLength.toFixed(1)} seconds long ${creditStr}, but only ${STATE.currentBillCycleInfo.remainingRounded} credit(s) are remaining.` : "",
            STATE.inputVideoData.fileLength ? `Video length is ${STATE.inputVideoData.fileLength} seconds, maximum allowed under your current plan is ${checkFeatureAvailability(STATE.subscriptionInfo.name, Enums.featureLockNames.maxDuration)} seconds.` : "",
            STATE.inputVideoData.fps ? `Video frames per second is ${STATE.inputVideoData.fps}, maximum allowed under your current plan is ${checkFeatureAvailability(STATE.subscriptionInfo.name, Enums.featureLockNames.maxFps)} FPS.` : "",
            STATE.inputVideoData.videoRes ? `${mediaType} resolution is ${STATE.inputVideoData.videoRes.w} x ${STATE.inputVideoData.videoRes.h}, maximum allowed under your current plan is ${((checkFeatureAvailability(STATE.subscriptionInfo.name, Enums.featureLockNames.maxResolution)) as any).w} x ${((checkFeatureAvailability(STATE.subscriptionInfo.name, Enums.featureLockNames.maxResolution)) as any).h}. Please try using a smaller ${mediaType.toLowerCase()}, or upgrade your plan.` : "",
            `Could not read video data, it may be using an un-supported codec or the file may be corrupt. Please fix the video or use a different video clip.`
        ]
        // find corresponding feature data for feature in question
        for (const [key, value] of Object.entries(STATE.inputVideoData.errorFlags)) {
            // todo: minsBalanceTooLow seems useless here, remove it in the future?
            if (key === 'minsBalanceTooLow' && value === true) {
                msg.push(
                    <div className="notification is-danger is-light" key={errorMsgs[0]}>
                        <div className="columns">
                            <div className="column is-1 p-1 m-1">
                                <span className="icon is-danger has-text-danger is-medium"><i
                                    className="fas fa-exclamation-triangle fa-lg"></i></span>
                            </div>
                            <div className="column p-1 m-1">
                                <div className="subtitle is-5">{errorMsgs[0]}</div>
                            </div>
                        </div>
                    </div>
                )
            }
            if (key === 'maxDuration' && value === true) {
                msg.push(
                    <div className="notification is-danger is-light" key={errorMsgs[1]}>
                        <div className="columns">
                            <div className="column is-1 p-1 m-1">
                                <span className="icon is-danger has-text-danger is-medium"><i
                                    className="fas fa-exclamation-triangle fa-lg"></i></span>
                            </div>
                            <div className="column p-1 m-1">
                                <div className="subtitle is-5">{errorMsgs[1]}</div>
                            </div>
                        </div>
                    </div>
                )
                title.push("Video Length Limit ")
            }
            if (key === 'maxFps' && value === true) {
                msg.push(
                    <div className="notification is-danger is-light" key={errorMsgs[2]}>
                        <div className="columns">
                            <div className="column is-1 p-1 m-1">
                                <span className="icon is-danger has-text-danger is-medium"><i
                                    className="fas fa-exclamation-triangle fa-lg"></i></span>
                            </div>
                            <div className="column p-1 m-1">
                                <div className="subtitle is-5">{errorMsgs[2]}</div>
                            </div>
                        </div>
                    </div>
                )
                title.push("Frame Per Second Limit ")
            }
            if (key === 'maxResolution' && value === true) {
                msg.push(
                    <div className="notification is-danger is-light" key={errorMsgs[3]}>
                        <div className="columns">
                            <div className="column is-1 p-1 m-1">
                                <span className="icon is-danger has-text-danger is-medium"><i
                                    className="fas fa-exclamation-triangle fa-lg"></i></span>
                            </div>
                            <div className="column p-1 m-1">
                                <div className="subtitle is-5">{errorMsgs[3]}</div>
                            </div>
                        </div>
                    </div>
                )
                title.push(`${mediaType} Resolution Limit `)
            }
            if (key === 'videoReadFailure' && value === true) {
                msg.push(
                    <div className="notification is-danger is-light" key={errorMsgs[4]}>
                        <div className="columns">
                            <div className="column is-1 p-1 m-1">
                                <span className="icon is-danger has-text-danger is-medium"><i
                                    className="fas fa-exclamation-triangle fa-lg"></i></span>
                            </div>
                            <div className="column p-1 m-1">
                                <div className="subtitle is-5">{errorMsgs[4]}</div>
                            </div>
                        </div>
                    </div>
                )
            }
        }
        const dialogBody = [
            <div key="problems-detected" className="columns has-text-left">
                <div className="column">
                    <p className="subtitle is-5">The following problem(s) were detected:</p>
                    {msg}
                </div>
            </div>
        ]
        return (
            isStudioOrHigherPlan()
                ?
                <DMDialog
                    title={title.length ? (title.join("& ") + " Reached") : "Unable to Create Animation"}
                    content={dialogBody}
                    msgFormat="html"
                    label1={textDialogCancel}
                    action1={() => {
                        closeModal()
                        resetJobSettingsAndInputVideoAfterError()
                    }}
                />
                :
                <DMDialog
                    title={title.length ? (title.join("& ") + " Reached") : "Unable to Create Animation"}
                    content={dialogBody}
                    msgFormat="html"
                    label1={textDialogUpgrade}
                    action1={() => {
                        upgradeButtonClicked()
                        resetJobSettingsAndInputVideoAfterError()
                    }}
                    label2={textDialogCancel}
                    action2={() => {
                        closeModal()
                        resetJobSettingsAndInputVideoAfterError()
                    }}
                />
        )
    }

    function buildVideoProblemsSolving() {

        let msg = []
        let title = []
        const mediaType = STATE.animJobSettings.jobType ? "Image" : "Video"

        let errorMsgs = [
            STATE.inputVideoData.fileLength ? `Video length is ${STATE.inputVideoData.fileLength.toFixed(2)} seconds, maximum allowed under your current plan is ${checkFeatureAvailability(STATE.subscriptionInfo.name, Enums.featureLockNames.maxDuration)} seconds. Please trim your video length, or upgrade your plan.` : "",
            STATE.inputVideoData.fps ? `Video frames per second is ${STATE.inputVideoData.fps}, maximum allowed under your current plan is ${checkFeatureAvailability(STATE.subscriptionInfo.name, Enums.featureLockNames.maxFps)} FPS. You can automatically downsample your video to ${checkFeatureAvailability(STATE.subscriptionInfo.name, Enums.featureLockNames.maxFps)} FPS, or upgrade your plan.` : "",
            STATE.inputVideoData.videoRes ? `${mediaType} resolution is ${STATE.inputVideoData.videoRes.w} x ${STATE.inputVideoData.videoRes.h}, maximum allowed under your current plan is ${((checkFeatureAvailability(STATE.subscriptionInfo.name, Enums.featureLockNames.maxResolution)) as any).w} x ${((checkFeatureAvailability(STATE.subscriptionInfo.name, Enums.featureLockNames.maxResolution)) as any).h}. Please crop your video, or upgrade your plan.` : "",
        ]
        // find corresponding feature data for feature in question
        for (const [key, value] of Object.entries(STATE.inputVideoData.errorFlags)) {
            if (key === 'maxDuration' && value === true) {
                msg.push(
                    <div className="notification is-danger is-light" key={errorMsgs[0]}>
                        <div className="columns">
                            <div className="column is-1 p-1 m-1">
                                <span className="icon is-danger has-text-danger is-medium"><i
                                    className="fas fa-exclamation-triangle fa-lg"></i></span>
                            </div>
                            <div className="column p-1 m-1">
                                <div className="subtitle is-5">{errorMsgs[0]}</div>
                            </div>
                        </div>
                    </div>
                )
                title.push("Video Length Limit ")
            }
            if (key === 'maxFps' && value === true) {
                msg.push(
                    <div className="notification is-danger is-light" key={errorMsgs[1]}>
                        <div className="columns">
                            <div className="column is-1 p-1 m-1">
                                <span className="icon is-danger has-text-danger is-medium"><i
                                    className="fas fa-exclamation-triangle fa-lg"></i></span>
                            </div>
                            <div className="column p-1 m-1">
                                <div className="subtitle is-5">{errorMsgs[1]}</div>
                            </div>
                        </div>
                    </div>
                )
                title.push("Frames Per Second Limit ")
            }
            if (key === 'maxResolution' && value === true) {
                msg.push(
                    <div className="notification is-danger is-light" key={errorMsgs[2]}>
                        <div className="columns">
                            <div className="column is-1 p-1 m-1">
                                <span className="icon is-danger has-text-danger is-medium"><i
                                    className="fas fa-exclamation-triangle fa-lg"></i></span>
                            </div>
                            <div className="column p-1 m-1">
                                <div className="subtitle is-5">{errorMsgs[2]}</div>
                            </div>
                        </div>
                    </div>
                )
                title.push("Resolution Limit ")
            }
        }
        const dialogBody = [
            <div key="problems-detected" className="columns has-text-left">
                <div className="column">
                    <p className="subtitle is-5">The following problem(s) were detected:</p>
                    {msg}
                </div>
            </div>
        ]

        let btnPrimaryText
        if (fallback["durationIsExceeded"] && fallback['fpsIsExceeded']) {
            btnPrimaryText = textDialogTrimLengthAndDownsample
        } else if (fallback["durationIsExceeded"]) {
            btnPrimaryText = textDialogTrimLength
        } else {
            btnPrimaryText = textDialogDownsample
        }

        return <DMDialogEnhanced
            title={title.join("& ") + " Reached"}
            content={dialogBody}
            msgFormat="html"
            btnPrimaryText={btnPrimaryText}
            btnPrimaryAction={() => {
                doFallback()
                closeModal()
            }}
            btnSecondaryText={isStudioOrHigherPlan() ? "" : textDialogUpgrade}
            btnSecondaryAction={() => {
                upgradeButtonClicked()
                resetJobSettingsAndInputVideoAfterError()
            }}
            btnConfirmText={textDialogCancel}
            btnConfirmAction={() => {
                closeModal()
                resetJobSettingsAndInputVideoAfterError()
            }}
        />
    }

    function doFallback() {
        let _fallback = {...fallback}
        if (fallback["durationIsExceeded"]) {
            _fallback = {
                ..._fallback,
                durationIsFallback: true,
            }

            const maxDuration = checkFeatureAvailability(STATE.subscriptionInfo.name, Enums.featureLockNames.maxDuration)
            setVideoTrimData({
                from: 0,
                to: maxDuration
            })

        }

        if (fallback["fpsIsExceeded"]) {
            _fallback = {
                ..._fallback,
                fpsIsFallback: true,
            }
        }

        if (fallback["resolutionIsExceeded"]) {
            _fallback = {
                ..._fallback,
                resolutionIsFallback: true,
            }

            const maxResolution = checkFeatureAvailability(STATE.subscriptionInfo.name, Enums.featureLockNames.maxResolution) as { w: number; h: number; }
            let cropWidth = maxResolution.w * 100 / STATE.inputVideoData.videoRes.w
            let cropHeight = maxResolution.h * 100 / STATE.inputVideoData.videoRes.h
            if (STATE.inputVideoData.videoRes.w < STATE.inputVideoData.videoRes.h) {
                cropWidth = maxResolution.h * 100 / STATE.inputVideoData.videoRes.w
                cropHeight = maxResolution.w * 100 / STATE.inputVideoData.videoRes.h
            }
            setVideoCropData({
                height: cropHeight,
                unit: '%',
                width: cropWidth,
                x: (100 - cropWidth) / 2,
                y: (100 - cropHeight) / 2
            })
        }
        setFallback(_fallback)
        DISPATCH({
            currWorkflowStep: Enums.uiStates.fileSelected,
            displayTrimAndCropDialog: true
        })
    }

    ///--------------------------------------------------------------------
    /// Video copy or model copy to GCP error
    ///--------------------------------------------------------------------
    function buildVideoOrModelCopyError() {
        let msg = `Our servers reported an error while trying to process this animation job, no animation time will be deducted from your account. If this problem continues to happen please contact DeepMotion Support.`
        const dialogBody = [
            <div key='video-or-model-copy-error'>
                <div className="columns has-text-centered" key='video-or-model-copy-error'>
                    <div className="column">
                        <span className="icon is-danger has-text-danger is-large"><i
                            className="fas fa-exclamation-triangle fa-3x"></i></span>
                    </div>
                </div>
                <div className="columns has-text-centered">
                    <div className="column">
                        <h2 className="subtitle is-5">{msg}</h2>
                    </div>
                </div>
            </div>
        ]
        return (
            <DMDialog
                title={textGenericErrorTitle}
                content={dialogBody}
                msgFormat="html"
                label1={textDialogClose}
                action1={() => closeModalAndResetWorkflow(closeModalFlags.routeToA3dHome)}
            />
        )
    }

    ///--------------------------------------------------------------------
    /// Invalid or un-supported input video codec:
    ///--------------------------------------------------------------------
    function buildInvalidCodecError() {
        let msg = `The input video format is not supported. It is recommended to encode the input video with H.264 codec and use .mp4 as the container file format.`
        const dialogBody = [
            <div key="invalid-video-codec-error">
                <div className="columns has-text-centered">
                    <div className="column">
                        <span className="icon is-danger has-text-danger is-large"><i
                            className="fas fa-exclamation-triangle fa-3x"></i></span>
                    </div>
                </div>
                <div className="columns has-text-centered">
                    <div className="column">
                        <h2 className="subtitle is-5">{msg}</h2>
                    </div>
                </div>
            </div>
        ]
        return (
            <DMDialog
                title={textInputVideoErrorTitle}
                content={dialogBody}
                msgFormat="html"
                label1={textDialogClose}
                action1={() => closeModal()}
            />
        )
    }

    ///--------------------------------------------------------------------
    /// Modal for selecting custom characters from users account
    ///--------------------------------------------------------------------
    function buildCharacterSelectionDialog() {
        const title = "Account Characters"
        const actionBtnLabel = "Close"
        const actionBtnClickFcn = STATE.accountTotals.charactersList.length ? () => updateModelInfoandNavigateUser(false, true) : () => updateModelInfoandNavigateUser(false, false)
        const addDate = STATE.animJobSettings.customModelInfo.ctime
            ? Enums.convertUnixDateToStandardFormat(STATE.animJobSettings.customModelInfo.ctime)
            : ""

    // const canCreateNewModel = (STATE.accountTotals.characterLimit - STATE.accountTotals.charactersList.length > 0) ? true : false
        return (
            <div id="modal-ter" className="modal is-active">
                <div className="modal-background"></div>
                <div className="modal-card" style={{width: '66vw'}}>
                    <header className="modal-card-head m-0">
                        <p className="modal-card-title">{title}</p>
                        <button className="delete" onClick={() => closeModal()} aria-label="close"></button>
                    </header>
                    <section className="modal-card-body m-0 p-0">
                        <div className="content">

                            {buildModelThumbnailsSection()}

                        </div>
                    </section>
                    <section className="m-0 p-0">
                        <div className="columns m-0 has-background-link-light">
                            <div className={"column p-4 has-text-centered"}>
                                <div
                                    className="title is-5 dm-brand-font">{STATE.animJobSettings.customModelInfo.name}</div>
                                <div className="subtitle is-6 dm-brand-font">
                                    {
                                        !STATE.animJobSettings.customModelInfo.id ?
                                            <div className="subtitle is-6 dm-brand-font"></div>
                                            :
                                            STATE.animJobSettings.customModelInfo.name === Enums.robloxModelId || isPlatformModelById(STATE.animJobSettings.customModelInfo.id) ?
                                                <div className="subtitle is-6 dm-brand-font"> (System Provided) </div>
                                                :
                                                <div className="subtitle is-6 dm-brand-font"> Added: {addDate} </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </section>
                    <footer className="modal-card-foot m-0">
                        <div className="columns m-0 fullwidth">
                            {/*
                canCreateNewModel
                &&
                <div className="column p-0">
                  <div className="buttons is-left">
                    <div className="button ml-4" onClick={()=>closeDialogAndOpenModelsPage()}>
                      <span> {textManageModels} </span>
                    </div>
                  </div>
                </div>
              */}
                            <div className="column p-0">
                                <div className="buttons is-right">
                                    <div className="button action-btn-dark" tabIndex={0}
                                         onClick={actionBtnClickFcn}> {actionBtnLabel} </div>
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        )
    }

    ///--------------------------------------------------------------------
    /// Modal for warning user they will lose upload data on job type switch
    ///--------------------------------------------------------------------
    function buildWarnUploadDataLoseModal(mode) {
        const cancelBtnLabel = "No"
        const actionBtnLabel = mode ? "Yes, Change to Pose" : "Yes, Change to Animation"
        const msg = STATE.silentUploadInProgress ? "The input file currently being uploaded will be lost if you change the job type, continue?" : "Your uploaded input file will be lost if you change job type, continue?"
        const dialogBody = [
            <div key="warn-lose-upload">
                <div className="columns has-text-left">
                    <div className="column is-2 has-text-centered">
                        <span className="icon is-warning has-text-warning is-large"><i
                            className="fas fa-exclamation-triangle fa-3x"></i></span>
                    </div>
                    <div className="column">
                        <h2 className="subtitle is-5">{msg}</h2>
                    </div>
                </div>
            </div>
        ]
        return (
            <DMDialog
                title="Warning"
                content={dialogBody}
                label1={cancelBtnLabel}
                action1={() => closeModal()}
                label2={actionBtnLabel}
                action2={() => resetSelectedInputVideo(mode)}
            />
        )
    }

    ///--------------------------------------------------------------------
    /// Confrim removal of currently uploading or fully uploaded input media
    ///--------------------------------------------------------------------
    function buildConfirmInputMediaRemoval(mode) {
        const cancelBtnLabel = "No"
        const actionBtnLabel = STATE.silentUploadInProgress ? "Yes, Stop Upload" : "Yes, Remove"
        const msg = STATE.silentUploadInProgress ? "Stop the current upload that's in progress?" : "Remove the currently uploaded input file?"
        const dialogBody = [
            <div key="confirm-remove-input">
                <div className="columns has-text-left">
                    <div className="column is-2 has-text-centered">
                        <span className="icon is-warning has-text-warning is-large"><i
                            className="fas fa-exclamation-triangle fa-3x"></i></span>
                    </div>
                    <div className="column">
                        <h2 className="subtitle is-5">{msg}</h2>
                    </div>
                </div>
            </div>
        ]
        return (
            <DMDialog
                title="Warning"
                content={dialogBody}
                label1={cancelBtnLabel}
                action1={() => closeModal()}
                label2={actionBtnLabel}
                action2={() => {
                    STATE.silentUploadInProgress && abortRequest()
                    resetSelectedInputVideo(mode)
                }}
            />
        )
    }

    ///--------------------------------------------------------------------
    /// Confirm deletetion of an existing animation job including all
    /// its associated files (ie FBX, BVH, JPG, etc)
    ///--------------------------------------------------------------------
    function buildAnimationDeleteModal() {
        const rid = STATE.confirmDialogId
        let cDate = new Date(STATE.dialogInfo.date).toLocaleString(Enums.getNavigatorLanguage())
        let msg = "Confirm deletion by typing the job name (case sensitive) below"
        return (
            <div id="modal-ter" className="modal is-active">
                <div className="modal-background"></div>
                <div className="modal-card">
                    <header className="modal-card-head m-0">
                        <p className="modal-card-title">Delete Animation</p>
                        <button className="delete" onClick={() => closeDeleteAnimJobModal()}
                                aria-label="close"></button>
                    </header>
                    <section className="modal-card-body m-0 p-0">
                        <div className="content">
                            <div className="columns m-0 pl-3 pr-3 fullwidth has-background-info-light">
                                <div className="column is-1 mr-5">
                  <span className="icon is-large">
                    <i className="fas fa-trash-alt fa-3x has-text-danger"></i>
                  </span>
                                </div>
                                <div className="column p-0">
                                    <div className="columns m-0">
                                        <div className="column is-2 pb-1 m-0 has-text-right">
                                            <h1 className="subtitle is-6 dm-brand-font">Job Name:</h1>
                                        </div>
                                        <div className="column pb-1 m-0 has-text-left">
                                            <h1 className="subtitle is-6 has-text-black"> {STATE.dialogInfo.name} </h1>
                                        </div>
                                    </div>
                                    <div className="columns m-0">
                                        <div className="column is-2 pb-1 pt-1 m-0 has-text-right">
                                            <h1 className="subtitle is-6 dm-brand-font">Length:</h1>
                                        </div>
                                        <div className="column pb-1 pt-1 m-0 has-text-left">
                                            <h1 className="subtitle is-6 has-text-black">{STATE.dialogInfo.length.toFixed(2)} seconds</h1>
                                        </div>
                                    </div>
                                    <div className="columns m-0">
                                        <div className="column is-2 pb-1 pt-1 m-0 has-text-right">
                                            <h1 className="subtitle is-6 dm-brand-font">Created:</h1>
                                        </div>
                                        <div className="column pb-1 pt-1 m-0 has-text-left">
                                            <h1 className="subtitle is-6 has-text-black"> {cDate} </h1>
                                        </div>
                                    </div>
                                    <div className="columns m-0">
                                        <div className="column is-2 pb-1 pt-1 m-0 has-text-right">
                                            <h1 className="subtitle is-6 dm-brand-font">Size:</h1>
                                        </div>
                                        <div className="column pb-1 pt-1 m-0 has-text-left">
                                            <h1 className="subtitle is-6 has-text-black"> {STATE.dialogInfo.size} </h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="columns m-0 fullwidth">
                                <div className="column">
                                    <div className="block p-4">
                                        <p className="subtitle is-5">
                                            Deleting this animation will permanently remove it from your Animate 3D
                                            Library, if you need a backup copy make sure you download the animation
                                            before deleting it.
                                        </p>
                                        <div className="subtitle is-5 notification is-info is-light">
                                            Note: Deleting animations does <span
                                            className="has-text-weight-semibold">not</span> add back any animation
                                            credits to your account.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="columns m-0 fullwidth">
                                <div className="column px-5">
                                    <label className="label">{msg}</label>
                                    <input className="input" onChange={validateJobDeleteInput} type="text"
                                           name="jobName" id="job-input" placeholder="Job Name" required/>
                                </div>
                            </div>
                        </div>
                    </section>
                    <footer className="modal-card-foot m-0">
                        <div className="columns m-0 fullwidth">
                            <div className="column p-0">
                                <div className="buttons is-right">
                                    <div className="button is-white dm-brand-font" tabIndex={0}
                                         onClick={closeDeleteAnimJobModal}>Cancel
                                    </div>
                                    {
                                        deleteJobButtonEnabled
                                            ?
                                            <div className="button is-danger" tabIndex={1}
                                                 onClick={() => deleteAnimJobAndClearLocalState(rid)}>Delete</div>
                                            :
                                            <div className="button is-danger btn-disabled">Delete</div>
                                        // <div className="button is-danger" {/* disabled */}>Delete</div>
                                    }
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        )
    }

    ///--------------------------------------------------------------------
    /// Cancel subscription modal:
    ///--------------------------------------------------------------------
    function buildCancelSubscriptionModal(subList) {
        let title = "Cancel Subscription"
        let msg = "WARNING: This action will cancel your subscription."
        let btnText = "Cancel Subscription"

        let subscriptionButtonList = []
        subList.forEach(s => {
            subscriptionButtonList.push(
                <React.Fragment>
                    <input value={s.sub_name} type="radio" name="subscription"/> {s.sub_name}
                </React.Fragment>
            )
        })

        return (
            <div id="modal-ter" className="modal is-active">
                <div className="modal-background"></div>
                <div className="modal-card">
                    <header className="modal-card-head m-0">
                        <p className="modal-card-title">{title}</p>
                        <button className="delete" onClick={() => closeModalAndResetWorkflow()}
                                aria-label="close"></button>
                    </header>
                    <section className="modal-card-body m-0">
                        <div className="content">
                            <h5 className="title is-5">Select Subscription to Cancel</h5>
                            <div className="control block" onChange={setSubscriptionToCancel}>
                                {subscriptionButtonList}
                            </div>
                            <div className="columns has-text-centered">
                                <div className="column">
                                    <span className="icon is-danger has-text-danger is-large"><i
                                        className="fas fa-exclamation-triangle fa-3x"></i></span>
                                </div>
                            </div>
                            <div className="columns has-text-centered">
                                <div className="column">
                                    <h2 className="subtitle is-5">{msg}</h2>
                                </div>
                            </div>
                            <div className="columns">
                                <div className="column">
                                    <label className="label">Subscription Name</label>
                                    <input className="input" onChange={validateSubscriptionToCancel} type="text"
                                           name="subscription" id="subscription-input" placeholder="subscription name"
                                           required/>
                                </div>
                            </div>
                            <div className="columns">
                                <div className="column">
                                    <label className="label">E-mail</label>
                                    <input className="input" onChange={validateCancelSubscription} type="email"
                                           name="email" id="email-input" placeholder="email address" required/>
                                </div>
                            </div>
                        </div>
                    </section>
                    <footer className="modal-card-foot m-0">
                        <div className="columns fullwidth">
                            <div className="column">
                                <div className="button"
                                     onClick={() => closeModalAndResetWorkflow()}> {textDialogCancel} </div>
                            </div>
                            <div className="column">
                                {
                                    (cancelSubscriptionButtonEnabled.sub_name && cancelSubscriptionButtonEnabled.email)
                                        ?
                                        <div className="button is-danger"
                                             onClick={() => cancelSubscriptionAndRedirect()}> {btnText} </div>
                                        :
                                        <div className="button is-danger btn-disabled"> {btnText} </div>
                                    // <div className="button is-danger" disabled> {btnText} </div>
                                }
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        )
    }

    ///--------------------------------------------------------------------
    /// Close and de-activate user account modal:
    ///--------------------------------------------------------------------
    function buildCloseAccountModal() {
        let title = "Close Account"
        let msg = "WARNING: This action will permanently DELETE your account and you will lose all your Animate 3D and SayMotion data. To permanently DELETE your account, please enter your email address into the field below. This action cannot be undone."
        return (
            <div id="modal-ter" className="modal is-active">
                <div className="modal-background"></div>
                <div className="modal-card">
                    <header className="modal-card-head m-0">
                        <p className="modal-card-title">{title}</p>
                        <button className="delete" onClick={() => closeModalAndResetWorkflow()}
                                aria-label="close"></button>
                    </header>
                    <section className="modal-card-body m-0">
                        <div className="content">
                            <div className="columns has-text-centered">
                                <div className="column">
                                    <span className="icon is-danger has-text-danger is-large"><i
                                        className="fas fa-exclamation-triangle fa-3x"></i></span>
                                </div>
                            </div>
                            <div className="columns has-text-centered">
                                <div className="column">
                                    <h2 className="subtitle is-5">{msg}</h2>
                                </div>
                            </div>
                            <div className="columns">
                                <div className="column">
                                    <input className="input" onChange={validateCloseAccountInput} type="email"
                                           name="email" id="email-input" placeholder="email address" required/>
                                </div>
                            </div>
                        </div>
                    </section>
                    <footer className="modal-card-foot m-0">
                        <div className="columns fullwidth">
                            <div className="column">
                                <div className="button"
                                     onClick={() => closeModalAndResetWorkflow()}> {textDialogCancel} </div>
                            </div>
                            <div className="column">
                                {
                                    closeAccountButtonEnabled
                                        ?
                                        <div className="button is-danger" onClick={() => closeUserAccount()}> Close
                                            Account </div>
                                        :
                                        <div className="button is-danger btn-disabled"> Close Account </div>
                                    // FIXME: <div className="button is-danger" disabled > Close Account </div>
                                }
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        )
    }

    ///--------------------------------------------------------------------
    /// Shows job summary information from library page
    ///--------------------------------------------------------------------
    function buildJobSettingsModal() {
        const dialogBody = [
            <div key="job-settings-info">
                {buildJobSummaryInfoTables(STATE.animJobId, false)}
            </div>
        ]
        return (
            <DMDialog
                title="Job Settings"
                content={dialogBody}
                msgFormat="html"
                label1={textDialogClose}
                action1={closeModal}
                noPadding={true}
            />
        )
    }

    ///--------------------------------------------------------------------
    /// Builds the confirm new animation modal
    ///--------------------------------------------------------------------
    function buildConfirmNewJobModal() {
        let returnObj = {
            creditsEnough: true
        }

        const dialogTitle = STATE.animJobSettings.jobType === Enums.jobTypes.staticPose
            ? "Confirm New Static Pose" : "Confirm New Animation"
        const dialogBody = [buildJobSummaryInfoTables(0, true, returnObj)]
        return (
            <DMDialog
                title={dialogTitle}
                content={dialogBody}
                msgFormat="html"
                noPadding={true}
                label1="Back to Settings"
                action1={() => DISPATCH({confirmDialogId: Enums.confirmDialog.none})}
                label2={returnObj.creditsEnough ? "Start Job" : textDialogCancel}
                action2={() => {
                    if (returnObj.creditsEnough) {
                        initNewAnimationJob({retry: true})
                    } else {
                        closeModal()
                        resetJobSettingsAndInputVideoAfterError()
                    }
                }}
            />
        )
    }

    ///--------------------------------------------------------------------
    /// input dialog for renaming invalid file name
    ///--------------------------------------------------------------------
    function buildInputFileRenamingModal() {
        return (
            <InputDialog
                title="File Rename"
                message={STATE.dialogInfo.message}
                okLabel="Rename"
                preFilledValue={STATE.dialogInfo.fileName}
                onOk={STATE.dialogInfo.onConfirm}
                validateInput={STATE.dialogInfo.validateInput}
            />
        )
    }

    ///--------------------------------------------------------------------
    /// confirmation dialog for max input clip length reached
    ///--------------------------------------------------------------------
    function buildInputFileTooLongModal() {
        let customMsg = `The maximum length for input video clips is ${process.env.REACT_APP_MAX_CLIP_LENGTH} seconds, current file is ${STATE.inputVideoData.fileLength} seconds. Please try using a shorter video clip.`
        return (
            <InfoDialog
                title="Input Video Too Long"
                msg={customMsg}
                isDanger={true}
                label1="OK"
                action1={closeModal}
            />
        )
    }

    ///--------------------------------------------------------------------
    /// confirmation dialog for max input size length reached
    ///--------------------------------------------------------------------
    function buildInputFileTooBigModal() {
        let customMsg = `The Maximum input size allowed is ${Enums.formatSizeUnits(checkFeatureAvailability(STATE.subscriptionInfo.name, Enums.featureLockNames.maxSize), 0)}, your file is ${STATE.dialogInfo.size}. Please try using a smaller sized video or upgrade your plan.`
        return (
            isStudioOrHigherPlan()
                ?
                <DMDialog
                    title="Size Limit Reached"
                    content={customMsg}
                    msgFormat="html"
                    label1={textDialogCancel}
                    action1={() => closeModal()}
                />
                :
                <DMDialog
                    title="Size Limit Reached"
                    content={customMsg}
                    msgFormat="html"
                    label1={textDialogUpgrade}
                    action1={() => upgradeButtonClicked()}
                    label2={textDialogCancel}
                    action2={() => closeModal()}
                />
        )
    }

    ///--------------------------------------------------------------------
    ///
    ///--------------------------------------------------------------------
    function buildInputFileNameInvalidModal() {
        const message = `File name: <br/><br/><strong>${STATE.dialogInfo.videoFileName}</strong><br/><br/> contains invalid characters. Please rename your file and try again.`
        return (
            <InfoDialog
                title="File Name Invalid"
                msg={message}
                msgFormat="html"
                isDanger={true}
                label1="OK"
                action1={resetDialogsData}
            />
        )
    }

    ///--------------------------------------------------------------------
    ///
    ///--------------------------------------------------------------------
    function buildInputMaxLengthExceededModal() {
        const message = `File name: <br/><br/><strong>${STATE.dialogInfo.videoFileName}</strong><br/><br/> exceeds max length of <strong>${Enums.MAX_FILENAME_LENGTH}</strong> characters. Please shorten the file name and try again.`
        return (
            <InfoDialog
                title="File Name Too Long"
                msg={message}
                msgFormat="html"
                isDanger={true}
                label1="OK"
                action1={resetDialogsData}
            />
        )
    }

    ///--------------------------------------------------------------------
    ///
    ///--------------------------------------------------------------------
    function buildInputInvalidOrCorruptModal() {
        const message = `The input video file supplied: <br/><br/><strong>${STATE.dialogInfo.videoFileName}</strong><br/><br/> does not have any video information or is corrupted. Please try using a different file.`
        return (
            <InfoDialog
                title="Input Media Not Valid"
                msg={message}
                msgFormat="html"
                isDanger={true}
                label1="OK"
                action1={resetDialogsData}
            />
        )
    }

    ///--------------------------------------------------------------------
    ///
    ///--------------------------------------------------------------------
    function buildInputFileTypeWrongModal() {
        const fileTypesString = STATE.animJobSettings.jobType === Enums.jobTypes.staticPose
            ? Enums.textAcceptedImgTypes
            : Enums.textAcceptedClipTypes
        const message = `File "${STATE.videoFileName}" is using an unsupported file extension. ${fileTypesString}`
        return (
            <InfoDialog
                title="File Type Invalid"
                msg={message}
                msgFormat="html"
                isDanger={true}
                label1="OK"
                action1={() => resetSelectedInputVideo(STATE.animJobSettings.jobType)}
            />
        )
    }

    ///--------------------------------------------------------------------
    ///
    ///--------------------------------------------------------------------
    function buildCancelInProgressJobModal() {
        return (
            <DMDialog
                title="Confirm Job Cancel"
                content="Are you sure you would like to cancel the current job?"
                noPadding={true}
                label1="No"
                label2="Yes, Cancel Job"
                action1={closeModal}
                action2={() => stopCurrentHandler(true)}
            />
        )
    }

    ///--------------------------------------------------------------------
    /// Builds the rerun animation/pose job modal:
    ///--------------------------------------------------------------------
    function buildJobReRunModal() {
        if (!STATE.animJobId) {
            console.log(`Warning: null or invalid jobId found while opening rerun modal, closing dialog!`)
            closeModal()
            return <div/>
        }
        const jobDetails = getJobDetailsById(STATE.animJobId)
        if (!jobDetails) {
            return <div/>
        }
        let faceSupported = false
        let faceDataType = 0
        let handDataType = 0
        if (jobDetails.customModel === "standard") {
            faceSupported = true
        } else if (jobDetails.customModel !== Enums.robloxModelId) {
            const checkedModel = checkCustomCharacterFeatureCompatiabilityIfNecessary(jobDetails.customModel)
            if (checkedModel) {
                faceDataType = checkedModel.faceDataType
                handDataType = checkedModel.handDataType
                faceSupported = checkedModel.faceDataType === 1
            }
        }

        const animName = jobDetails.name
        const duration = jobDetails.length
        const cDate = new Date(jobDetails.dateRaw).toLocaleString(Enums.getNavigatorLanguage())
        const dialogTitle = jobDetails.jobType ? "Rerun 3D Pose" : "Rerun Animation"
        const jobId = jobDetails.rid
        const settings = {
            ...Enums.JOB_SETTINGS_TEMPLATE,
            ...jobDetails.settings
        }
        if (Object.keys(settings).length === 0 || !settings.inputVideoData) {
            return (
                <React.Fragment>
                    <div className="columns">
                        <div className="column">
                            <div className="notification subtitle is-info is-light">
                                Unable to rerun animation jobs created before July 25, 2021.
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )
        } else {
            const settingFlags = []
            settingFlags.push(
                <span key="setting-on" className="icon is-small"><i
                    className="fas fa-check-circle has-text-success fa-sm"></i></span>
            )
            settingFlags.push(
                <span key="setting-off" className="icon is-small"><i
                    className="fas fa-times-circle has-text-danger fa-sm"></i></span>
            )

            let outputFormatsList = []
            let rerunFormatsList = []
            for (const [key, value] of Object.entries(settings.formats)) {
                if (value === true) {
                    outputFormatsList.push(key)
                }
            }
            for (const [key, value] of Object.entries(STATE.rerunSettings.formats)) {
                if (value === true) {
                    rerunFormatsList.push(key)
                }
            }
            let outputFormatsListJoined = outputFormatsList.join(', ')
            let rerunFormatsListJoined = rerunFormatsList.join(', ')

            const findModelInfo = getModelDataById(jobDetails.customModel)
            const modelInfo = findModelInfo ? findModelInfo : Enums.deletedModel
            if (modelInfo && modelInfo.id !== 'standard') {
                // prepend glb format if custom character present
                outputFormatsListJoined = "glb, " + outputFormatsListJoined
                rerunFormatsListJoined = "glb, " + rerunFormatsListJoined
            }

            let mp4Enabled = outputFormatsListJoined.includes('mp4')
            let rerunIsAvailable = STATE.accountTotals.max_rerun === -1 ? true : ((STATE.accountTotals.max_rerun - STATE.accountTotals.rerun_count) > 0)
            let rerunCountStr = STATE.accountTotals.max_rerun === -1 ? "Unlimited" : `${STATE.accountTotals.max_rerun - STATE.accountTotals.rerun_count}`
            let rerunRemaininStr = STATE.accountTotals.max_rerun === -1 ? "Unlimited" : `${STATE.accountTotals.max_rerun - STATE.accountTotals.rerun_count} / ${STATE.accountTotals.max_rerun}`
            let bodyCredits = settings.inputVideoData.fileLength
            let faceCredits = (STATE.rerunSettings.trackFace && !jobDetails.faceDataExist) ? bodyCredits * 0.5 : 0
            let handCredits = (STATE.rerunSettings.trackHand && !jobDetails.handDataExist) ? bodyCredits * 0.5 : 0
            let rerunCredits = 0
            // Multi-Person Tracking
            if (jobDetails.customModel === "multiple") {
                rerunCredits = Math.ceil((faceCredits + handCredits) * jobDetails.customMultiPersonModel.length)
            } else {
                rerunCredits = Math.ceil(faceCredits + handCredits)
            }

            const isStudioUser = STATE.subscriptionInfo.name === Enums.accountPlansInfo[Enums.accountPlansInfo.length - 2].name
            let creditsEnough = isStudioUser || (STATE.currentBillCycleInfo.remainingRounded >= rerunCredits)

            const params = {
                settings: settings,
                isMultiPersonJob: jobDetails.customModel === "multiple",
                customMultiPersonModel: jobDetails.customMultiPersonModel,
                outputFormatsList: outputFormatsListJoined,
                mp4Enabled: mp4Enabled,
                animName: animName,
                duration: duration,
                cDate: cDate,
                faceDataType: faceDataType,
                handDataType: handDataType,
                modelInfo: jobDetails.customModel === 'standard' ? 'standard' : modelInfo,
                // set reRun=true for rerun view of each table
                reRun: true,
                rid: jobDetails.rid
            }
            let displayScreen = null
            if (jobDetails.jobType) {
                displayScreen = buildStaticPoseSettingsTable(params, faceSupported)
            } else {
                // rerunViewState is always 9
                switch (rerunViewState) {
                    default:
                        break
                    case Enums.pageState.rerunAnimSettings:
                        displayScreen = buildAnimationSettingsTable(params)
                        break
                    case Enums.pageState.rerunVideoSettings:
                        displayScreen = buildVideoSettingsTable(params)
                        break
                }
            }

            return (
                <React.Fragment>
                    <div id="modal-ter" className="modal is-active">
                        <div className="modal-background"></div>
                        <div className="modal-card" style={{height: '100%'}}>
                            <header className="modal-card-head m-0">
                                <p className="modal-card-title">{dialogTitle}</p>
                                <button className="delete" onClick={() => closeModal()} aria-label="close"></button>
                            </header>
                            <section className="modal-card-body m-0 p-0">
                                <div className="content">
                                    {buildAnimationHeaderSection(params)}
                                    <div className="columns m-1 p-1">
                                        <div className="column has-text-left">
                                            <div className="columns is-justify-content-center">
                                                {
                                                    rerunIsAvailable
                                                        ?
                                                        (
                                                            creditsEnough
                                                                ?
                                                                <div
                                                                    className="column notification is-warning is-light p-4 m-5">
                                                                    <div>
                                    <span className="icon is-medium">
                                      <i className="fas fa-info-circle" aria-hidden="true"></i>
                                    </span>
                                                                        {
                                                                            isStudioUser
                                                                                ?
                                                                                <span
                                                                                    className="has-text-weight-semibold">
                                          {rerunCountStr} reruns and Unlimited credits (with {STATE.currentBillCycleInfo.remainingRounded} high priority credits) remaining for the current month
                                        </span>
                                                                                :
                                                                                <span
                                                                                    className="has-text-weight-semibold">
                                          {rerunCountStr} reruns and {STATE.currentBillCycleInfo.remainingRounded} credits remaining for the current month
                                        </span>
                                                                        }
                                                                    </div>
                                                                    {
                                                                        rerunCredits > 0
                                                                            ?
                                                                            <div>
                                        <span className="icon is-medium">
                                          <i className="fas fa-info-circle" aria-hidden="true"></i>
                                        </span>
                                                                                <span
                                                                                    className="has-text-weight-semibold">
                                          {rerunCredits} credits will be used for {faceSupported ? "Face" : "Head Rotation"} and/or Hand Tracking in this rerun
                                        </span>
                                                                            </div>
                                                                            :
                                                                            <div></div>
                                                                    }
                                                                </div>
                                                                :
                                                                <div
                                                                    className="column notification is-danger is-light p-4 ml-5 mr-5 mb-3 mt-3 has-text-centered">
                                  <span className="icon is-medium">
                                    <i className="fas fa-exclamation-triangle" aria-hidden="true"></i>
                                  </span>
                                                                    <span className="block has-text-weight-semibold">
                                    Not enough credits: ({STATE.currentBillCycleInfo.remainingRounded} remaining, {rerunCredits} needed) for hand or face tracking
                                  </span>
                                                                </div>
                                                        )
                                                        :
                                                        <div
                                                            className="column notification is-danger is-light p-4 ml-5 mr-5 mb-3 mt-3 has-text-centered">
                              <span className="icon is-medium">
                                <i className="fas fa-exclamation-triangle" aria-hidden="true"></i>
                              </span>
                                                            {
                                                                <React.Fragment>
                                  <span className="block has-text-weight-semibold">
                                    You have used all of your animation reruns for the current month
                                  </span>
                                                                    <div className="has-text-weight-semibold">
                                                                        Reruns Remaining: {rerunRemaininStr}
                                                                    </div>
                                                                </React.Fragment>
                                                            }
                                                        </div>
                                                }
                                            </div>

                                            {/* Only show screen toggle button for regular animation jobs (i.e not for static poses) */}
                                            {
                                                !jobDetails.jobType
                                                &&
                                                <div className="columns">
                                                  <div className="column">
                                                    <div className="buttons is-centered has-addons">
                                                      <button
                                                        onClick={() => setRerunViewState(Enums.pageState.rerunAnimSettings)}
                                                        className={"button " + (rerunViewState === Enums.pageState.rerunAnimSettings ? "is-link is-selected" : "is-light")}>Animation
                                                        Settings
                                                      </button>
                                                      <button
                                                        onClick={() => setRerunViewState(Enums.pageState.rerunVideoSettings as number)}
                                                        className={"button " + (rerunViewState === (Enums.pageState.rerunVideoSettings as number) ? "is-link is-selected" : "is-light")}>Video
                                                        Settings
                                                      </button>
                                                    </div>
                                                  </div>
                                                </div>
                                            }
                                            <div className="columns">
                                                <div className="column">
                                                    {displayScreen}
                                                </div>
                                            </div>

                                            <div className="columns">
                                                <div className="column">
                                                    {buildOutputFormatsTable(rerunFormatsListJoined)}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </section>
                            <footer className="modal-card-foot m-0">
                                <div className="columns m-0 fullwidth">
                                    <div className="column p-0">
                                        <div className="buttons is-right">
                                            <button className="button is-white dm-brand-font" tabIndex={0}
                                                    onClick={() => closeModal()}>Close
                                            </button>
                                            {
                                                rerunIsAvailable
                                                    ?
                                                    (
                                                        creditsEnough
                                                            ?
                                                            <div className="button action-btn" tabIndex={1}
                                                                 onClick={() => setRerunConfirmInfo({
                                                                     showModal: true,
                                                                     jobId: jobId
                                                                 })}> Rerun </div>
                                                            :
                                                            <div
                                                                className="button is-danger is-light no-cursor btn-disabled"
                                                                tabIndex={1}><span className="no-side-margins">Out of Credits </span>
                                                            </div>
                                                    )
                                                    :
                                                    (
                                                        STATE.subscriptionInfo.name === Enums.accountPlansInfo[0].name
                                                            ?
                                                            <div
                                                                className="button is-danger is-light no-cursor btn-disabled"
                                                                tabIndex={1}><span
                                                                className="no-side-margins"> Unavailable </span></div>
                                                            :
                                                            <div
                                                                className="button is-danger is-light no-cursor btn-disabled"
                                                                tabIndex={1}><span className="no-side-margins">Rerun Limit Reached </span>
                                                            </div>
                                                    )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </footer>
                        </div>
                    </div>
                </React.Fragment>
            )
        }
    }

    ///--------------------------------------------------------------------
    function buildRemoveCharacterConfirmModal() {
        let dialogTitle = "Discard Custom Character?"
        let msg = `We found a custom character (${STATE.animJobSettings.customModelInfo.name}) ready to be used in your next animation, selecting this option will remove it and use standard characters instead.`
        return (
            <InfoDialog
                title={dialogTitle}
                msg={msg}
                label1="No, Keep Character"
                action1={() => updateModelInfoandNavigateUser(false, true)}
                label2="Yes, Discard Character"
                action2={() => updateModelInfoandNavigateUser(true, true)}
            />
        )
    }

    ///--------------------------------------------------------------------
    function buildUploadCharacterConfirmModal() {
        let dialogTitle = "Upload New Character?"
        let msg = `We found a custom character (${STATE.animJobSettings.customModelInfo.name}) ready for your next animation, uploading a new character will replace the current one.`
        return (
            <InfoDialog
                title={dialogTitle}
                msg={msg}
                label1="No, Keep Character"
                action1={() => updateModelInfoandNavigateUser(false, true)}
                label2="Yes, Upload New Character"
                action2={() => updateModelInfoandNavigateUser(true, false)}
            />
        )
    }

    ///--------------------------------------------------------------------
    /// Logout confirmation modal:
    ///--------------------------------------------------------------------
    function buildExitApplicationModal() {
        return (
            <DMDialog
                title="Confirm Sign Out"
                content="Are you sure you want to sign out of DeepMotion?"
                noPadding={true}
                label1="Cancel"
                label2="Sign Out"
                action1={() => DISPATCH({confirmDialogId: null})}
                action2={() => logoutUser()}
            />
        )
    }

    //////////////////////////////////////////////////////////////////////
    // helper function for custom character workflow
    //////////////////////////////////////////////////////////////////////
    function updateModelInfoandNavigateUser(discardModel, pageFlag) {
        // close the dialog, update custom model info (if needed), and
        // navigate user to the appropriate page based on flag
        closeModal()
        if (discardModel) {
            DISPATCH({animJobSettings: {...STATE.animJobSettings, ...{'customModelInfo': Enums.customModelObj}}})
        }
    }

    ///--------------------------------------------------------------------
    /// Confirmation dialog for starting a new re-run job
    ///--------------------------------------------------------------------
    function RerunConfirmationModal(rid) {
        const dialogBody = [
            <div key="rerun-confirm" className="columns">
                <div className="column has-text-left">
                    <p className="subtitle is_3">Are you sure you would like to rerun this animation?</p>
                </div>
            </div>
        ]
        return (
            <DMDialog
                title="Confirm Rerun"
                content={dialogBody}
                msgFormat="html"
                label1="Cancel"
                action1={() => setRerunConfirmInfo({showModal: false, jobId: 0})}
                label2="Yes, Rerun"
                action2={() => startJobRerun(rid)}
            />
        )
    }

    //////////////////////////////////////////////////////////////////////
    // helper function for starting a rerun job
    //////////////////////////////////////////////////////////////////////
    function startJobRerun(jobId) {
        setRerunConfirmInfo({showModal: false, jobId: 0})
        DISPATCH({confirmDialogId: null})
        beginAnimationJob({rerun: true, rid: jobId, retry: true})
    }

    ///--------------------------------------------------------------------
    /// Builds the model dropdown selector for downloading
    /// standard characters
    ///--------------------------------------------------------------------
    function buildOutputFileTypeDropDown() {
        const fileTypesList = retrieveJobOutputFileTypes()
        if (!fileTypesList || !fileTypesList.length) {
            return
        }
        let dd_data = []
        fileTypesList.forEach((fileType) => {
            dd_data.push({value: fileType, available: true})
        })
        // default anim file type is FBX, but if user disabled FBX then select first file type
        if (selectedFileType === Enums.animFileType.FBX && !fileTypesList.includes(Enums.animFileType.FBX)) {
            if (dd_data[0]) {
                setSelectedFileType(dd_data[0].value)
            }
        }
        return (
            <DMDropDown
                data={dd_data}
                value={selectedFileType}
                onChange={onFileTypeChange}
                isUp={false}
                isStyled={true}
                noMargins={true}
            />
        )
    }

    // wrapper function for updating selected file type
    function onFileTypeChange(value, cb) {
        setSelectedFileType(value)
        if (cb) {
            cb()
        }
    }

    ///--------------------------------------------------------------------
    /// Builds a custom branded animations download button
    ///--------------------------------------------------------------------
    function buildAnimationDownloadButton() {
        if (!STATE.currDownloadLinks) {
            return
        }
        // loop through STATE.currDownloadLinks
        for (const [key, link] of Object.entries(STATE.currDownloadLinks)) {
            if (key.toLowerCase().includes(selectedFileType.toLowerCase())) {
                return (
                    <div><a href={link} className="button action-btn fade-in"><span
                        className="no-side-margins">{titleDownload}</span></a></div>
                )
            }
        }
        // otherwise default to BVH file download since always available
        return (
            <div><a href={STATE.currDownloadLinks.bvhLink} className="button action-btn fade-in"><span
                className="no-side-margins">{titleDownload}</span></a></div>
        )
    }

    /////////////////////////////////////////////////////////////////////
    /// Seperate function for checking info or error Dialogs instead of
    /// Modals which may have various functionality and unique designs
    /////////////////////////////////////////////////////////////////////
    function checkForMessageDialogs() {
        // check for info/confirmation dialogs
        if (STATE.confirmDialogId === Enums.confirmDialog.customModelExists1) {
            let dialogTitle = "Discard Custom Character?"
            let msg = `We found a custom character (${STATE.animJobSettings.customModelInfo.name}) ready to be used in your next animation, selecting this option will remove it and use standard characters instead.`
            return (
                <InfoDialog
                    title={dialogTitle}
                    msg={msg}
                    label1="No, Keep Character"
                    action1={() => updateModelInfoandNavigateUser(false, true)}
                    label2="Yes, Discard Character"
                    action2={() => updateModelInfoandNavigateUser(true, true)}
                />
            )
        } else if (STATE.confirmDialogId === Enums.confirmDialog.customModelExists2) {
            let dialogTitle = "Upload New Character?"
            let msg = `We found a custom character (${STATE.animJobSettings.customModelInfo.name}) ready for your next animation, uploading a new character will replace the current one.`
            return (
                <InfoDialog
                    title={dialogTitle}
                    msg={msg}
                    label1="No, Keep Character"
                    action1={() => updateModelInfoandNavigateUser(false, true)}
                    label2="Yes, Upload New Character"
                    action2={() => updateModelInfoandNavigateUser(true, false)}
                />
            )
        }

        // next check for potential error dialogs
        if (STATE.errorDialogInfo) {
            if (STATE.errorDialogInfo.show) {
                let dialogTitle = ""
                let msg = ""
                switch (STATE.errorDialogInfo.id) {
                    case Enums.eCodes.BadRequest:
                        dialogTitle = (STATE.errorDialogInfo.title === "" ? "Bad Request" : STATE.errorDialogInfo.title)
                        msg = "Sorry we could not process your request, if the problem continues please contact DeepMotion support."
                        return (
                            <InfoDialog
                                title={dialogTitle}
                                msg={msg}
                                label1="OK"
                                action1={() => setErrorDialogInfo(false, 0, "")}
                            />
                        )
                        break
                    case Enums.eCodes.Unauthorized:
                        dialogTitle = (STATE.errorDialogInfo.title === "" ? "Unauthorized" : STATE.errorDialogInfo.title)
                        msg = "Your session has expired due to inactivity, please sign in again."
                        return (
                            <InfoDialog
                                title={dialogTitle}
                                msg={msg}
                                label1="OK"
                                action1={() => logoutUser()}
                            />
                        )
                        break
                    case Enums.eCodes.Forbidden:
                        dialogTitle = (STATE.errorDialogInfo.title === "" ? "Access Denied" : STATE.errorDialogInfo.title)
                        msg = "You do not have access to the requested resource (404 - Forbidden), you have been logged out."
                        return (
                            <InfoDialog
                                title={dialogTitle}
                                msg={msg}
                                label1="OK"
                                action1={() => logoutUser()}
                            />
                        )
                        break
                    case Enums.eCodes.RequestTimeout:
                        dialogTitle = (STATE.errorDialogInfo.title === "" ? "Request Timed Out" : STATE.errorDialogInfo.title)
                        msg = "Sorry your request has timed out, the service might be down due to an upgrade in progress. Please wait 10 minutes and try again, if the problem continues contact DeepMotion Support."
                        return (
                            <InfoDialog
                                title={dialogTitle}
                                msg={msg}
                                label1="OK"
                                action1={() => setErrorDialogInfo(false, null, "")}
                            />
                        )
                        break
                    case Enums.eCodes.NotFound:
                        dialogTitle = (STATE.errorDialogInfo.title === "" ? "Not Found" : STATE.errorDialogInfo.title)
                        msg = "The requested resource was not found (404) - the service might be down due to an upgrade in progress. Please try your request again, if the problem continues contact DeepMotion Support."
                        return (
                            <InfoDialog
                                title={dialogTitle}
                                msg={msg}
                                label1="OK"
                                action1={() => setErrorDialogInfo(false, null, "")}
                            />
                        )
                        break
                    case Enums.eCodes.OtherError:
                        dialogTitle = (STATE.errorDialogInfo.title === "" ? "Something went wrong" : STATE.errorDialogInfo.title)
                        msg = "Sorry there was a problem with the request, please try again. If the problem continues please contact DeepMotion Support."
                        return (
                            <InfoDialog
                                title={dialogTitle}
                                msg={msg}
                                label1="OK"
                                action1={() => setErrorDialogInfo(false, null, "")}
                            />
                        )
                        break
                    //--------------------
                    // SERVER SIDE ERRORS:
                    //--------------------

                    case Enums.eCodes.BadGateway:
                        dialogTitle = "Bad Gateway"
                        msg = "Sorry the server returned a 502 (Bad Gateway) error. This may be due to an upgrade in progress, please wait 5-10 mins and try your request again. If the problem continues please contact DeepMotion Support."
                        return (
                            <InfoDialog
                                title={dialogTitle}
                                msg={msg}
                                label1="OK"
                                action1={() => setErrorDialogInfo(false, null, "")}
                            />
                        )
                        break

                    case Enums.eCodes.InternalServerError:
                        dialogTitle = STATE.errorDialogInfo.title !== "" ? STATE.errorDialogInfo.title : "Sorry, something went wrong"
                        msg = STATE.errorDialogInfo.msg !== "" ? STATE.errorDialogInfo.msg : "The request could not be completed, this might be a network connectivity issue. If the problem continues please contact DeepMotion Support."
                        return (
                            <InfoDialog
                                title={dialogTitle}
                                msg={msg}
                                label1="OK"
                                action1={() => DISPATCH({dispatchType: 'resetModelsPageAndDialogInfo', payload: {}})}
                            />
                        )
                        break

                    case Enums.eCodes.ServiceUnavailable:
                    case Enums.eCodes.GatewayTimeout:
                    case Enums.eCodes.InsufficientStorage:
                        dialogTitle = "Insufficient Storage"
                        msg = "Sorry, the server reported a problem with your request. This may be due to an upgrade in progress, please wait 5-10 mins and try your request again. If the problem continues please contact DeepMotion Support."
                        return (
                            <InfoDialog
                                title={dialogTitle}
                                msg={msg}
                                label1="OK"
                                action1={() => setErrorDialogInfo(false, null, "")}
                            />
                        )
                        break

                    default:
                        dialogTitle = "Server Error"
                        msg = "Sorry, the server reported a problem with your request. This may be due to an upgrade in progress, please wait 5-10 mins and try your request again. If the problem continues please contact DeepMotion Support."
                        return (
                            <InfoDialog
                                title={dialogTitle}
                                msg={msg}
                                label1="OK"
                                action1={() => setErrorDialogInfo(false, null, "")}
                            />
                        )
                        break
                }
            }
        }
        return null
    }

    let checkForDialogs: JSX.Element | null = null
    if (STATE.confirmDialogId) {
        // TODO: merge rerun dialog into broader Enums dialogIds list
        if (rerunConfirmInfo.showModal) {
            checkForDialogs = RerunConfirmationModal(rerunConfirmInfo.jobId)
        } else {
            if (STATE.confirmDialogId.length >= Enums.minJobIdLength) {
                // DialogId=(job rid) used for deleting specific account job and its animations
                checkForDialogs = buildAnimationDeleteModal()
            } else {
                switch (STATE.confirmDialogId) {
                    case Enums.confirmDialog.standardDownload:
                        checkForDialogs = DownloadDefaultCharacterModal()
                        break
                    case Enums.confirmDialog.customMultiPersonDownload:
                        checkForDialogs = DownloadMultiPersonCharacterModal()
                        break
                    case Enums.confirmDialog.customDownload:
                        checkForDialogs = DownloadCustomCharacterModal()
                        break
                    case Enums.confirmDialog.customModelSelect:
                        checkForDialogs = buildCharacterSelectionDialog()
                        break
                    case Enums.confirmDialog.removeCustChar:
                        checkForDialogs = DeleteCustomCharacterModal()
                        break
                    case Enums.confirmDialog.VideoOrModelCopyError:
                        checkForDialogs = buildVideoOrModelCopyError()
                        break
                    case Enums.confirmDialog.InvalidVideoCodecError:
                        checkForDialogs = buildInvalidCodecError()
                        break
                    case Enums.confirmDialog.VideoValidationFailed:
                        checkForDialogs = buildVideoValidationFailed()
                        break
                    case Enums.confirmDialog.VideoProblemsSolving:
                        checkForDialogs = buildVideoProblemsSolving()
                        break
                    case Enums.confirmDialog.exitApplication:
                        checkForDialogs = buildExitApplicationModal()
                        break
                    case Enums.confirmDialog.closeUserAccount:
                        checkForDialogs = buildCloseAccountModal()
                        break
                    case Enums.confirmDialog.libraryJobSettings:
                        checkForDialogs = buildJobSettingsModal()
                        break
                    case Enums.confirmDialog.confirmNewAnimJob:
                        checkForDialogs = buildConfirmNewJobModal()
                        break
                    case Enums.confirmDialog.CheckBeforeCreateJob:
                        checkBeforeCreateJob()
                        checkForDialogs = null
                        break
                    case Enums.confirmDialog.inputFileTooLong:
                        checkForDialogs = buildInputFileTooLongModal()
                        break
                    case Enums.confirmDialog.inputFileTooBig:
                        checkForDialogs = buildInputFileTooBigModal()
                        break
                    case Enums.confirmDialog.inputMaxLengthExceeded:
                        checkForDialogs = buildInputMaxLengthExceededModal()
                        break
                    case Enums.confirmDialog.inputInvalidOrCorrupt:
                        checkForDialogs = buildInputInvalidOrCorruptModal()
                        break
                    case Enums.confirmDialog.inputFileNameInvalid:
                        checkForDialogs = buildInputFileNameInvalidModal()
                        break
                    case Enums.confirmDialog.inputFileNameRename:
                        checkForDialogs = buildInputFileRenamingModal()
                        break
                    case Enums.confirmDialog.inputFileTypeWrong:
                        checkForDialogs = buildInputFileTypeWrongModal()
                        break
                    case Enums.confirmDialog.cancelInProgressJob:
                        checkForDialogs = buildCancelInProgressJobModal()
                        break
                    case Enums.confirmDialog.reRunJobConfig:
                        checkForDialogs = buildJobReRunModal()
                        break
                    case Enums.confirmDialog.customModelExists1:
                        checkForDialogs = buildRemoveCharacterConfirmModal()
                        break
                    case Enums.confirmDialog.customModelExists2:
                        checkForDialogs = buildUploadCharacterConfirmModal()
                        break
                    case Enums.confirmDialog.confirmLoseUploadData:
                        checkForDialogs = buildWarnUploadDataLoseModal(!STATE.animJobSettings.jobType)
                        break
                    case Enums.confirmDialog.confirmInputMediaRemoval:
                        checkForDialogs = buildConfirmInputMediaRemoval(STATE.animJobSettings.jobType)
                        break
                    case Enums.confirmDialog.cancelSubscription:
                        checkForDialogs = buildCancelSubscriptionModal(subscriptionList)
                        break
                    default: {
                        // check for job processing error codes returned from the backend
                        const jobError = getErrorByCode(STATE.confirmDialogId)
                        if (jobError) {
                            checkForDialogs = JobFailedDialog({
                                jobType: STATE.animJobSettings.jobType,
                                description: jobError.description,
                                closeModal,
                                resetSelectedInputVideo
                            })
                        }
                        console.error(`Warning: Invalid confirmDialogId encountered on current render: ${STATE.confirmDialogId}`)
                        break
                    }
                }
            }
        }
    } else {
        checkForDialogs = checkForMessageDialogs()
    }

    // simple css style to not display content when loading
    const loadingStyle = LOADING.show ? {display: 'none'} : null

    // helper function for image thumbnails calculations
    function calcThumbnailRatio(img) {
        let imgRatio = ((img.currentTarget.naturalWidth / img.currentTarget.naturalHeight) > 1.3) ? "is-16by9" : "is-1by1"
        setThumbnailRatio(imgRatio)
    }

    function calcFileSelectThumbnailRatio(img) {
        let imgRatio = ((img.currentTarget.naturalWidth / img.currentTarget.naturalHeight) > 1.3) ? "is-16by9" : "is-1by1"
        setFileSelectThumbnailRatio(imgRatio)
    }

    function calcDeleteModelThumbnailRatio(img) {
        let imgRatio = ((img.currentTarget.naturalWidth / img.currentTarget.naturalHeight) > 1.3) ? "is-16by9" : "is-1by1"
        setDeleteModelThumbnailRatio(imgRatio)
    }

    //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    // Do below before render function as a workaround to
    //   DOM node insertion crash in React
    // https://github.com/facebook/react/issues/11538#issuecomment-417504600
    //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    if (typeof Node === 'function' && Node.prototype) {
        const originalRemoveChild = Node.prototype.removeChild;
        Node.prototype.removeChild = function (child) {
            if (child.parentNode !== this) {
                if (console) {
                    console.error('Cannot remove a child from a different parent', child, this);
                }
                return child;
            }
            return originalRemoveChild.apply(this, arguments);
        }

        const originalInsertBefore = Node.prototype.insertBefore;
        Node.prototype.insertBefore = function (newNode, referenceNode) {
            if (referenceNode && referenceNode.parentNode !== this) {
                if (console) {
                    console.error('Cannot insert before a reference node from a different parent', referenceNode, this);
                }
                return newNode;
            }
            return originalInsertBefore.apply(this, arguments);
        }
    }
    //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    // ----- Render Functions -----
    //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    return (
        <React.Fragment>

            <Security
                oktaAuth={oktaAuth}
                onAuthRequired={customAuthHandler}
                restoreOriginalUri={restoreOriginalUri}
            >
                <AppStateContext.Provider value={{state: STATE, dispatch: DISPATCH}}>
                    {checkForDialogs}
                    <ModalCancellationSurvey
                        showCancellationSurvey={showCancellationSurvey}
                        setShowCancellationSurvey={setShowCancellationSurvey}
                    />

                    {/* Temporarily removing: <ParticlesBackground /> */}

                    <Switch>

                        {/***************************************************************************/}
                        <SecureRoute exact path={Enums.routes.Anim3d} render={(routeProps) => {
                            return (
                                <PageTemplate {...routeProps} pageTitle={pageTitleA3DHome} STATE={STATE}
                                              DISPATCH={DISPATCH} logout={logoutUser}>
                                    {
                                        LOADING.show
                                        &&
                                        <LoadingScreen msg={LOADING.msg}/>
                                    }
                                    <div style={loadingStyle}>
                                        <Anim3DHome {...routeProps}
                                                    LOADING={LOADING}
                                                    setLOADING={setLOADING}
                                                    initializeA3DService={initializeA3DService}
                                                    getProductColorCSSClass={getProductColorCSSClass}
                                                    buildRemainingMinutesMeter={buildRemainingMinutesMeter}
                                        />
                                    </div>
                                </PageTemplate>
                            )
                        }}/>

                        {/***************************************************************************/}
                        <SecureRoute exact path={Enums.routes.Anim3dGuidedFTE} render={(routeProps) => {
                            return (
                                <PageTemplate {...routeProps} STATE={STATE} DISPATCH={DISPATCH} logout={logoutUser}>
                                    {
                                        LOADING.show
                                        &&
                                        <LoadingScreen msg={LOADING.msg}/>
                                    }
                                    <div style={loadingStyle}>
                                        <GuidedFTE {...routeProps}
                                                   setVideoTrimData={setVideoTrimData}
                                                   setVideoCropData={setVideoCropData}
                                                   LOADING={LOADING}
                                                   setLOADING={setLOADING}
                                                   pageTitle={titleFTEGuide}
                                                   initializeA3DService={initializeA3DService}
                                                   getProductColorCSSClass={getProductColorCSSClass}
                                                   buildRemainingMinutesMeter={buildRemainingMinutesMeter}
                                                   uploadOnchange={uploadOnchange}
                                                   uploadInputVideo={uploadInputVideo}
                                                   renderScreenAfterFileSelected={renderScreenAfterFileSelected}
                                                   buildMotionClipSelectedArea={buildMotionClipSelectedArea}
                                                   buildJobSettingsArea={buildJobSettingsArea}
                                                   InformationArea={InformationArea}
                                                   activeJobMenu={activeJobMenu}
                                                   checkFeatureAvailability={checkFeatureAvailability}
                                                   closeModal={closeModal}
                                                   videoTrimData={videoTrimData}
                                                   fallback={fallback}
                                        />
                                    </div>
                                </PageTemplate>
                            )
                        }}/>

                        {/***************************************************************************/}
                        <SecureRoute exact path={Enums.routes.Anim3dCreate} render={(routeProps) => {
                            return (
                                <PageTemplate {...routeProps} STATE={STATE} DISPATCH={DISPATCH} logout={logoutUser}>
                                    {
                                        LOADING.show
                                        &&
                                        <LoadingScreen msg={LOADING.msg}/>
                                    }
                                    <div style={loadingStyle}>

                                        <NewJobConfig {...routeProps}
                                                      LOADING={LOADING}
                                                      setLOADING={setLOADING}
                                                      initializeA3DService={initializeA3DService}
                                                      performBackgroundVideoUpload={performBackgroundVideoUpload}
                                                      uploadInputVideo={uploadInputVideo}
                                                      renderScreenAfterFileSelected={renderScreenAfterFileSelected}
                                                      beginAnimationJob={beginAnimationJob}
                                                      InformationArea={InformationArea}
                                                      buildJobSummaryInfoTables={buildJobSummaryInfoTables}
                                                      buildPoseFilteringSelect={buildPoseFilteringSelect}
                                                      buildEyeTrackingSensitivitySelect={buildEyeTrackingSensitivitySelect}
                                                      buildBackgroundColorSelect={buildBackgroundColorSelect}
                                                      buildSpeedMultiplierSelect={buildSpeedMultiplierSelect}
                                                      buildAddMotionClipArea={buildAddMotionClipArea}
                                                      buildCharacterSettingsArea={buildCharacterSettingsArea}
                                                      buildVideoSettingsArea={buildVideoSettingsArea}
                                                      buildAnimationJobConfigScreen={buildAnimationJobConfigScreen}
                                                      buildStaticPoseJobConfigScreen={buildStaticPoseJobConfigScreen}
                                                      buildJobTypeSelectionRow={buildJobTypeSelectionRow}

                                                      isProfessionalOrHigherPlan={isProfessionalOrHigherPlan}
                                                      activeJobMenu={activeJobMenu}
                                                      setActiveJobMenu={setActiveJobMenu}
                                                      checkFeatureAvailability={checkFeatureAvailability}
                                                      resetSelectedInputVideo={resetSelectedInputVideo}
                                                      resetDialogsData={resetDialogsData}
                                                      createNewOutputFormatsObj={createNewOutputFormatsObj}
                                                      getModelDataById={getModelDataById}
                                                      changeMP4IncludeOriginalVideo={changeMP4IncludeOriginalVideo}
                                                      changeMP4IncludeOriginalVideoReRun={changeMP4IncludeOriginalVideoReRun}
                                                      changeMp4OutputCameraMotion={changeMp4OutputCameraMotion}
                                                      changeMp4OutputCameraMotionReRun={changeMp4OutputCameraMotionReRun}
                                                      changeFootLockingSelect={changeFootLockingSelect}
                                                      changeFootLockingSelectReRun={changeFootLockingSelectReRun}
                                                      changeFallbackPoseSelect={changeFallbackPoseSelect}
                                                      changeFallbackPoseSelectReRun={changeFallbackPoseSelectReRun}
                                                      changeMp4OutputCameraAngle={changeMp4OutputCameraAngle}
                                                      changeMp4OutputCameraAngleReRun={changeMp4OutputCameraAngleReRun}
                                                      getProductColorCSSClass={getProductColorCSSClass}
                                                      handleBkgdColorChange={handleBkgdColorChange}
                                                      closeModal={closeModal}
                                                      handleHttpError={handleHttpError}
                                        />
                                    </div>
                                </PageTemplate>
                            )
                        }}/>

                        {/***************************************************************************/}
                        <SecureRoute exact path={Enums.routes.Anim3dPreview} render={(routeProps) => {
                            return (
                                <PageTemplate {...routeProps} STATE={STATE} DISPATCH={DISPATCH} logout={logoutUser}>
                                    {
                                        LOADING.show
                                        &&
                                        <LoadingScreen/>
                                    }
                                    <div style={loadingStyle}>
                                        <Previewer {...routeProps}
                                                   STATE={STATE}
                                                   DISPATCH={DISPATCH}
                                                   LOADING={LOADING}
                                                   setLOADING={setLOADING}
                                                   initializeA3DService={initializeA3DService}
                                                   openAnimationPreviewer={openAnimationPreviewer}
                                                   displayReRunModal={displayReRunModal}
                                                   jobContainsGLBDownloadLinks={jobContainsGLBDownloadLinks}
                                                   beginAnimationJob={beginAnimationJob}
                                                   closeModal={closeModal}
                                        />
                                    </div>
                                </PageTemplate>
                            )
                        }}/>

                        {/***************************************************************************/}
                        <SecureRoute exact path={Enums.routes.Anim3dLibrary} render={(routeProps) => {
                            return (
                                <PageTemplate {...routeProps} STATE={STATE} DISPATCH={DISPATCH} logout={logoutUser}>
                                    {
                                        LOADING.show
                                        &&
                                        <LoadingScreen/>
                                    }
                                    <div style={loadingStyle}>
                                        <Library {...routeProps}
                                                 STATE={STATE}
                                                 DISPATCH={DISPATCH}
                                                 LOADING={LOADING}
                                                 setLOADING={setLOADING}
                                                 DownloadDefaultCharacterModal={DownloadDefaultCharacterModal}
                                                 initializeA3DService={initializeA3DService}
                                                 sortLibraryByColumn={sortLibraryByColumn}
                                                 buildActionsDropDown={buildActionsDropDown}
                                                 getModelDataById={getModelDataById}
                                                 openAnimationPreviewer={openAnimationPreviewer}
                                        />
                                    </div>
                                </PageTemplate>
                            )
                        }}/>

                        {/***************************************************************************/}
                        <SecureRoute exact path={Enums.routes.Anim3dModelManage} render={(routeProps) => {
                            return (
                                <PageTemplate {...routeProps} STATE={STATE} DISPATCH={DISPATCH} logout={logoutUser}>
                                    {
                                        LOADING.show
                                        &&
                                        <LoadingScreen/>
                                    }
                                    <div style={loadingStyle}>
                                        <CharacterManagePage {...routeProps}
                                                             STATE={STATE}
                                                             DISPATCH={DISPATCH}
                                                             LOADING={LOADING}
                                                             setLOADING={setLOADING}
                                                             initializeCharacterManagePage={initializeCharacterManagePage}
                                                             getProductColorCSSClass={getProductColorCSSClass}
                                                             handleHttpError={handleHttpError}
                                                             getModelDataById={getModelDataById}
                                                             setErrorDialogInfo={setErrorDialogInfo}
                                        />
                                    </div>
                                </PageTemplate>
                            )
                        }}/>
                        {/***************************************************************************/}
                        <SecureRoute path={Enums.routes.Anim3dProfilePage} render={(routeProps) => {
                            return (
                                <PageTemplate {...routeProps} STATE={STATE} DISPATCH={DISPATCH} logout={logoutUser}>
                                    {
                                        LOADING.show
                                        &&
                                        <LoadingScreen/>
                                    }
                                    <div style={loadingStyle}>
                                        <ProfilePage {...routeProps}
                                                     STATE={STATE}
                                                     DISPATCH={DISPATCH}
                                                     LOADING={LOADING}
                                                     setLOADING={setLOADING}
                                                     initializeA3DService={initializeA3DService}
                                                     updateUserPlanData={updateUserPlanData}
                                                     handleHttpError={handleHttpError}
                                                     setErrorDialogInfo={setErrorDialogInfo}
                                                     setSubscriptionList={setSubscriptionList}
                                        />
                                    </div>
                                </PageTemplate>
                            )
                        }}/>
                        {/***************************************************************************/}
                        <SecureRoute exact path={Enums.routes.Contact} render={(routeProps) => {
                            return (
                                <PageTemplate {...routeProps} STATE={STATE} DISPATCH={DISPATCH}>
                                    {
                                        LOADING.show
                                        &&
                                        <LoadingScreen/>
                                    }
                                    <div style={loadingStyle}>
                                        <ContactUs {...routeProps}
                                                   DISPATCH={DISPATCH}
                                                   LOADING={LOADING}
                                                   setLOADING={setLOADING}
                                                   initializeA3DService={initializeA3DService}
                                        />
                                    </div>
                                </PageTemplate>
                            )
                        }}/>
                        {/***************************************************************************/}
                        <SecureRoute exact path={Enums.routes.SupportPage} render={(routeProps) => {
                            return (
                                <PageTemplate {...routeProps} STATE={STATE} DISPATCH={DISPATCH}>
                                    {
                                        LOADING.show
                                        &&
                                        <LoadingScreen/>
                                    }
                                    <div style={loadingStyle}>
                                        <Support {...routeProps}
                                                 DISPATCH={DISPATCH}
                                                 LOADING={LOADING}
                                                 setLOADING={setLOADING}
                                                 initializeA3DService={initializeA3DService}
                                        />
                                    </div>
                                </PageTemplate>
                            )
                        }}/>
                        {/***************************************************************************/}
                        <SecureRoute exact path={Enums.routes.FeedbackPage} render={(routeProps) => {
                            return (
                                <PageTemplate {...routeProps} STATE={STATE} DISPATCH={DISPATCH}>
                                    {
                                        LOADING.show
                                        &&
                                        <LoadingScreen/>
                                    }
                                    <div style={loadingStyle}>
                                        <FeedbackForm {...routeProps}
                                                      DISPATCH={DISPATCH}
                                                      LOADING={LOADING}
                                                      setLOADING={setLOADING}
                                                      initializeA3DService={initializeA3DService}
                                        />
                                    </div>
                                </PageTemplate>
                            )
                        }}/>
                        {/***************************************************************************/}
                        <SecureRoute exact path={Enums.routes.DMBTSdk} render={(routeProps) => {
                            return (
                                <PageTemplate {...routeProps} STATE={STATE} DISPATCH={DISPATCH}>
                                    {
                                        LOADING.show
                                        &&
                                        <LoadingScreen/>
                                    }
                                    <div style={loadingStyle}>
                                        <DMBTSdkPage {...routeProps}
                                                     pageTitle={pageTitleDashboard}
                                                     DISPATCH={DISPATCH}
                                                     LOADING={LOADING}
                                                     setLOADING={setLOADING}
                                                     initializeA3DService={initializeA3DService}
                                        />
                                    </div>
                                </PageTemplate>
                            )
                        }}/>

                        {/***************************************************************************/}
                        <SecureRoute exact path={Enums.routes.VRSdk} render={(routeProps) => {
                            return (
                                <PageTemplate {...routeProps} STATE={STATE} DISPATCH={DISPATCH}>
                                    {
                                        LOADING.show
                                        &&
                                        <LoadingScreen/>
                                    }
                                    <div style={loadingStyle}>
                                        <VRSdkPage {...routeProps}
                                                   pageTitle={pageTitleDashboard}
                                                   DISPATCH={DISPATCH}
                                                   LOADING={LOADING}
                                                   setLOADING={setLOADING}
                                                   initializeA3DService={initializeA3DService}
                                        />
                                    </div>
                                </PageTemplate>
                            )
                        }}/>
                        {/***************************************************************************/}
                        <SecureRoute exact path={Enums.routes.Admin} render={(routeProps) => {
                            return (
                                <PageTemplate {...routeProps} STATE={STATE} DISPATCH={DISPATCH}>
                                    {
                                        LOADING.show
                                        &&
                                        <LoadingScreen/>
                                    }
                                    <div style={loadingStyle}>
                                        <AdminAPIApp {...routeProps}
                                                     DISPATCH={DISPATCH}
                                                     LOADING={LOADING}
                                                     setLOADING={setLOADING}
                                                     initializeA3DService={initializeA3DService}
                                        />
                                    </div>
                                </PageTemplate>
                            )
                        }}/>

                        {/***************************************************************************/}
                        <SecureRoute exact path={Enums.routes.ActivityPage} render={(routeProps) => {
                            return (
                                <PageTemplate {...routeProps} STATE={STATE} DISPATCH={DISPATCH}>
                                    {
                                        LOADING.show
                                        &&
                                        <LoadingScreen/>
                                    }
                                    <div style={loadingStyle}>
                                        <ActivityPage {...routeProps}
                                                      STATE={STATE}
                                                      DISPATCH={DISPATCH}
                                                      LOADING={LOADING}
                                                      setLOADING={setLOADING}
                                                      logoutUser={logoutUser}
                                        />
                                    </div>
                                </PageTemplate>
                            )
                        }}/>

                        {/***************************************************************************/}
                        <SecureRoute exact path={Enums.routes.Dash} render={(routeProps) => {
                            return (
                                <PageTemplate {...routeProps} STATE={STATE} DISPATCH={DISPATCH}>
                                    {
                                        LOADING.show
                                        &&
                                        <LoadingScreen/>
                                    }
                                    <div style={loadingStyle}>
                                        <DashboardPage {...routeProps}
                                                       pageTitle={pageTitleDashboard}
                                                       DISPATCH={DISPATCH}
                                                       LOADING={LOADING}
                                                       setLOADING={setLOADING}
                                                       initializeA3DService={initializeA3DService}
                                        />
                                    </div>
                                </PageTemplate>
                            )
                        }}/>
                        {/***************************************************************************/}
                        <Route path={Enums.routes.ForgotPwdPage} render={(props) => <ForgotPwdPage {...props} />}/>
                        {/***************************************************************************
                         * - Redirect /animate-3d/sign-up requests to the signup page on the
                         *   main website
                         * ***************************************************************************/}
                        <Route exact path={Enums.routes.CreateAccount}>
                            <Route render={() => window.location.href = dmSignUpUrl}/>
                        </Route>
                        <Route path={"/signup"}>
                            <Redirect to={Enums.routes.CreateAccount}/>
                        </Route>
                        <Route path={"/sign-up"}>
                            <Redirect to={Enums.routes.CreateAccount}/>
                        </Route>
                        <Route path={"/animate3d/sign-up"}>
                            <Redirect to={Enums.routes.CreateAccount}/>
                        </Route>
                        <Route path={"/animate-3d/signup"}>
                            <Redirect to={Enums.routes.CreateAccount}/>
                        </Route>
                        <Route path={"/animate3d/signup"}>
                            <Redirect to={Enums.routes.CreateAccount}/>
                        </Route>
                        {/***************************************************************************/}
                        <Route path={Enums.routes.ClosedAccount}
                               render={(props) => <AccountClosedPage {...props} email={STATE.email}/>}/>
                        <Route path={Enums.routes.LoginCallback} component={LoginCallback}/>
                        <Route path={Enums.routes.SignIn} removeLocalStorageData={removeLocalStorageData}
                               render={() => <Login config={oktaSignInConfig}/>}/>

                        {/*TODO ? <Route path={Enums.routes.ActivityPage} render={(props) => <ActivityPage {...props} />} /> */}
                        {/***************************************************************************/}
                    </Switch>
                </AppStateContext.Provider>
            </Security>
        </React.Fragment>
    )
}
