import React, { useContext, useState, memo, useEffect } from "react";
import { AppStateContext } from "../../AppStateContext";

// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import "swiper/swiper-bundle.css";
import "../../styles/backdrop.scss";
SwiperCore.use([Navigation]);

import * as Enums from "../common/enums";

import Checker_Cloudscape from "../../images/backdrop/swiper/Checker_Cloudscape.jpg";
import Golden_Age_Glitz from "../../images/backdrop/swiper/Golden_Age_Glitz.jpg";
import Inferno_Stage from "../../images/backdrop/swiper/Inferno_Stage.jpg";
import Jungle_Grove from "../../images/backdrop/swiper/Jungle_Grove.jpg";
import Mystic_Brick from "../../images/backdrop/swiper/Mystic_Brick.jpg";
import Mystic_Concrete from "../../images/backdrop/swiper/Mystic_Concrete.jpg";
import Rainbow_Spotlight from "../../images/backdrop/swiper/Rainbow_Spotlight.jpg";
import Red_Carpet from "../../images/backdrop/swiper/Red_Carpet.jpg";
import Retro_Revue from "../../images/backdrop/swiper/Retro_Revue.jpg";
import Sakura_Dreamscape from "../../images/backdrop/swiper/Sakura_Dreamscape.jpg";
import Spotlit_Stage from "../../images/backdrop/swiper/Spotlit_Stage.jpg";
import Timbered_Retreat from "../../images/backdrop/swiper/Timbered_Retreat.jpg";
import Urban_Rooftop from "../../images/backdrop/swiper/Urban_Rooftop.jpg";

import {
  createPreviewModelImage,
  adjustColor,
  RgbaToHex,
} from "../common/utils";
const environmentsImage = [
  Jungle_Grove,
  Checker_Cloudscape,
  Golden_Age_Glitz,
  Inferno_Stage,
  Mystic_Brick,
  Mystic_Concrete,
  Rainbow_Spotlight,
  Red_Carpet,
  Retro_Revue,
  Sakura_Dreamscape,
  Spotlit_Stage,
  Timbered_Retreat,
  Urban_Rooftop,
];

interface PorpsType {
  fullWidth?: boolean;
  Rerun?: boolean;
}
const Backdrop = memo(({ fullWidth, Rerun }: PorpsType) => {
  // Swiper navigation
  const [disabledSwiperNavigation, setDisabledSwiperNavigation] = useState<
    "left" | "right" | "all" | ""
  >("all");

  const { state, dispatch } = useContext(AppStateContext);
  const { animJobSettings, rerunSettings } = state;
  const jobSettings = Rerun ? rerunSettings : animJobSettings;

  const numModels = [
    ...state.accountTotals.platformCharactersList,
    ...state.accountTotals.charactersList,
  ];

  const bgColor = "rgba(" + jobSettings.bgColor.toString() + ")";
  const color = adjustColor(RgbaToHex(bgColor), -60);

  const [modelPreviewImage, setModelPreviewImage] = useState(null);

  useEffect(() => {
    let model = numModels.find(
      (model) => model.name === Enums.defaultModelName
    );
    let modelImage =
      model.thumbImg instanceof Blob
        ? URL.createObjectURL(model.thumbImg)
        : decodeURI(model.thumb);

    if (jobSettings.customModelInfo.id !== "multiple") {
      const findModel = numModels.find(
        (item) => item.id === jobSettings.customModelInfo.id
      );
      if (findModel) {
        model = findModel;
        modelImage =
          model.thumbImg instanceof Blob
            ? URL.createObjectURL(model.thumbImg)
            : decodeURI(model.thumb);
      }
    } else {
      model = numModels.find((item) => item.name === Enums.defaultModelName);
      modelImage =
        model.thumbImg instanceof Blob
          ? URL.createObjectURL(model.thumbImg)
          : decodeURI(model.thumb);
    }

    let shadow = jobSettings.shadow ?? false;
    if (jobSettings.backdrop === Enums.Backdrop.environments) {
      shadow = false;
    }

    createPreviewModelImage(modelImage, shadow).then((res) => {
      setModelPreviewImage(res);
    });
  }, [jobSettings.backdrop, jobSettings.customModelInfo, jobSettings.shadow]);

  const initialSlide = Enums.BackdropEnv.findIndex(
    (item) => item === jobSettings.environment
  );

  const Children = () => {
    switch (jobSettings.backdrop) {
      case Enums.Backdrop.defaultWithOriginal:
        return (
          <div className="Backdrop-box defaultWithOriginal">
            <img className="img" src={modelPreviewImage} alt="" />
          </div>
        );
      case Enums.Backdrop.solid:
        return (
          <div
            className="Backdrop-box solid"
            style={{ backgroundColor: bgColor }}
          >
            <img className="img" src={modelPreviewImage} alt="" />
          </div>
        );
      case Enums.Backdrop.transparent:
        return (
          <div className="Backdrop-box transparent">
            <img className="img" src={modelPreviewImage} alt="" />
          </div>
        );
      case Enums.Backdrop.studio:
        return (
          <div className="Backdrop-box studio">
            <div
              className="after"
              style={{
                backgroundImage: `linear-gradient(
                                  180deg,
                                  ${color} 10%,
                                  ${bgColor} 50%,
                                  ${color} 90%
                                )`,
              }}
            />
            <div
              className="before"
              style={{
                backgroundImage: `radial-gradient(
                                  closest-side at 50% 50%,
                                  ${bgColor},
                                  ${color}
                                )`,
              }}
            />
            <img className="img" src={modelPreviewImage} alt="" />
          </div>
        );
      case Enums.Backdrop.environments:
        return (
          <div className="backdropSwiper">
            <div
              className={
                disabledSwiperNavigation === "right" ||
                disabledSwiperNavigation === "all"
                  ? "swiper-navigation backdrop-swiper-next fas fa-6x fa-chevron-right notEnabled"
                  : "swiper-navigation backdrop-swiper-next fas fa-6x fa-chevron-right"
              }
            />
            <div
              className={
                disabledSwiperNavigation === "left" ||
                disabledSwiperNavigation === "all"
                  ? "swiper-navigation backdrop-swiper-prev fas fa-6x fa-chevron-left notEnabled"
                  : "swiper-navigation backdrop-swiper-prev fas fa-6x fa-chevron-left"
              }
            />
            {modelPreviewImage && (
              <Swiper
                className="backdrop-swiper"
                initialSlide={initialSlide}
                spaceBetween={20}
                navigation={{
                  nextEl: ".backdrop-swiper-next",
                  prevEl: ".backdrop-swiper-prev",
                }}
                onSwiper={(swiper) => {
                  let newAnimSettings = jobSettings;
                  newAnimSettings.environment =
                    Enums.BackdropEnv[swiper.activeIndex];

                  !Rerun && dispatch({ animJobSettings: newAnimSettings });

                  if (swiper.activeIndex === 0) {
                    setDisabledSwiperNavigation("left");
                  } else if (swiper.activeIndex === environmentsImage.length) {
                    setDisabledSwiperNavigation("right");
                  } else {
                    setDisabledSwiperNavigation("");
                  }
                }}
                onSlideChange={(swiper) => {
                  let newAnimSettings = jobSettings;
                  newAnimSettings.environment =
                    Enums.BackdropEnv[swiper.activeIndex];
                  if (Rerun) {
                    dispatch({ rerunSettings: newAnimSettings });
                  } else {
                    dispatch({ animJobSettings: newAnimSettings });
                  }

                  if (swiper.activeIndex === 0) {
                    setDisabledSwiperNavigation("left");
                  } else if (
                    swiper.activeIndex ===
                    environmentsImage.length - 1
                  ) {
                    setDisabledSwiperNavigation("right");
                  } else {
                    setDisabledSwiperNavigation("");
                  }
                }}
              >
                {environmentsImage &&
                  environmentsImage.map((row, i) => {
                    return (
                      <SwiperSlide key={i}>
                        <div
                          className="Backdrop-box environments"
                          style={{ backgroundImage: "url(" + row + ")" }}
                        >
                          <img className="img" src={modelPreviewImage} alt="" />
                        </div>
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            )}
          </div>
        );
        break;
      default:
        return (
          <div className="Backdrop-box default">
            <img className="img" src={modelPreviewImage} alt="" />
          </div>
        );
    }
  };
  return (
    <div className={fullWidth ? "backdrop fullWidth" : "backdrop"}>
      {Children()}
    </div>
  );
});

export default Backdrop;
