import React, { useState } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import OktaSignInWidget from './OktaSignInWidget'
import { useOktaAuth } from '@okta/okta-react'
import queryString from 'query-string'
import * as Enums from '../common/enums'
import LoadingScreen from '../common/LoadingScreen'
import { retrieveUserProfileInfo } from '../api/apiRequests'
import { setTokens, useQuery } from '../common/hooks'

const Login = ({ config }) => {
  const { oktaAuth, authState } = useOktaAuth()
  const [query] = useQuery()
  const locationBar = useLocation()
  const queryParams = queryString.parse(locationBar.search)
  const [asyncInProgress, setAsyncInProgress] = useState(false)

  location.href = process.env.REACT_APP_COMPANY_SITE+'/launch?signIn'

  const onSuccess = (tokens) => {
    oktaAuth.handleLoginRedirect(tokens)
    setTokens(tokens)
    shouldReturnTokens(tokens)

  }
  const returnUrl = query['return_url']

  const onError = (err) => {
    console.log('error logging in', err)
  }

  function shouldReturnTokens (tokens) {
    if (returnUrl?.length) {
      if(!tokens){
        tokens = JSON.parse(localStorage.getItem(Enums.oktaTokenStorage))
      }
      window.parent.postMessage({ type: 'success', data: tokens }, '*')
      return true
    }
    return false
  }

  async function setupUserInfoInSessionStorage () {
    await retrieveUserProfileInfo() // setup user info in the session storage for forum SSO
    window.location.assign(`${process.env.REACT_APP_API_URL}/forum`)
  }

  if (!authState) return null

  if (authState.isAuthenticated) {
    if (shouldReturnTokens()) {
      return
    }

    if (queryParams.from === 'forum') {
      setupUserInfoInSessionStorage()
      return <LoadingScreen msg="Loading..."/>
    } else {
      return <Redirect to={{ pathname: Enums.routes.Dash }}/>
    }
  } else {
    if (queryParams.idToken && queryParams.accessToken) {
      if (!asyncInProgress) {
        setAsyncInProgress(true)
        let accessToken = JSON.parse(queryParams.accessToken)
        let idToken = JSON.parse(queryParams.idToken)
        const tokens = { accessToken: accessToken, idToken: idToken }
        const originalUri = oktaAuth.getOriginalUri()
        if (originalUri)
          oktaAuth.setOriginalUri(originalUri.split('?')[0])
        oktaAuth.handleLoginRedirect(tokens)
      } else {
        return <div/>
      }
    } else {
      return <OktaSignInWidget
        config={config}
        onSuccess={onSuccess}
        onError={onError}/>
    }
  }
}
export default Login
