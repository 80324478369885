import React from "react";
import ReactTooltip from "react-tooltip";
import { SketchPicker } from "react-color";
import OutsideAlerterFunc from "../common/OutsideAlerterFunc";

/***************************************************************************
 * React functional component for building DM DropDown Color Picker element,
 * currently used for the MP4 background (solid or studio) color selector
 * @prop : value - current drop down state value
 * @prop : onChange - callback function for value change
 * @prop : data - array of color data for currently selected background color
 * @prop : isDisabled - disables the dropdown with lock icon and no-cursor mouse hover
 * @prop : isRerun - is Rerun job
 * ------- OPTIONAL UI & Styling related props -------
 * @prop : label - main label, if null then no tool tip is shown either
 * @prop : isNew - if true adds branded [NEW] tag to the toggle
 * @prop : tipText - string for the info icon text, can be html if isTipHtml=true
 * @prop : isTipHtml - if true interpolates string value into html for rendering
 * @prop : rightAlign - if true right aligns the dropdown menu (default is left)
 * @prop : noMargins - if true removes side margins
 ***************************************************************************/
const DMDropDownColorPicker = (props) => {
  const activeClass = "is-active";

  // local state hook(s):
  const [cssClass, setCssClass] = React.useState("");

  let colorData = {
    r: props.data[0],
    g: props.data[1],
    b: props.data[2],
  };

  const toggleDropDownClass = (val) => {
    if (val === "close") {
      // clear the class if called with value 'close'
      setCssClass("");
    } else {
      // else flip the value between is-active and null string
      setCssClass(cssClass === "" ? activeClass : "");
    }
  };

  ///////////////////////////////////////////////////////////////////////////
  const buildDMDropDownColorPicker = () => {
    const colorStr = `rgb(${props.data[0]}, ${props.data[1]}, ${props.data[2]})`;

    let spanClass = "subtitle is-5 m-0";
    let iconClass = "icon";

    if (props.isDisabled) {
      spanClass += " disabled-text no-cursor";
      iconClass += " disabled-text";
    } else {
      spanClass += " has-text-white";
      iconClass += " dm-white";
    }

    let ddcpMargins = props.noMargins ? " mx-0 " : " ml-0 mr-4 ";

    return (
      <div>
        <OutsideAlerterFunc
          clearActiveDropDowns={() => toggleDropDownClass("close")}
        >
          <React.Fragment>
            <div
              className={
                "dropdown ml-0 " +
                cssClass +
                ddcpMargins +
                (props.rightAlign && props.data !== "N/A" && !props.isDisabled
                  ? " is-right"
                  : "")
              }
            >
              {props.isDisabled || props.data === "N/A" ? (
                props.isRerun ? (
                  <div className="dropdown-trigger no-cursor">
                    <span> N/A </span>
                  </div>
                ) : (
                  <div className="green-screen-box m-0 disabled-item no-cursor"></div>
                )
              ) : (
                <div
                  className="dropdown-trigger"
                  onClick={() => toggleDropDownClass()}
                >
                  <button
                    className="button dm-brand-font"
                    style={{ backgroundColor: colorStr, width: "5rem" }}
                    aria-haspopup="true"
                    aria-controls="dropdown-menu"
                  ></button>
                </div>
              )}
              <div
                id={props.id}
                className="dropdown-menu menu-grow has-text-left"
                role="menu"
              >
                {buildDropDownContent()}
              </div>
            </div>
            {props.label && (
              <span className={spanClass}>
                {props.label}
                <span className={iconClass} style={{ fontSize: "1rem" }}>
                  <i
                    className="fas fa-info-circle"
                    data-for={props.label.split(" ").join("") + "-tip"}
                    data-border={true}
                    data-border-color="black"
                    data-tip
                    data-text-color="#2d4e77"
                    data-background-color="white"
                  ></i>
                </span>
              </span>
            )}
            {props.label && props.tipText && (
              <ReactTooltip
                className="tip-max-w"
                id={props.label.split(" ").join("") + "-tip"}
                place="right"
                effect="solid"
              >
                <div className="subtitle is_4">
                  {props.isTipHtml ? (
                    <div dangerouslySetInnerHTML={{ __html: props.tipText }} />
                  ) : (
                    props.tipText
                  )}
                </div>
              </ReactTooltip>
            )}
          </React.Fragment>
        </OutsideAlerterFunc>
      </div>
    );
  };

  ///////////////////////////////////////////////////////////////////////////
  function buildDropDownContent() {
    // render the color picker with disabled alpha:
    return (
      <div className="dropdown-content p-0">
        <div className="dropdown-item dropdown-item-alt m-0 p-0">
          <SketchPicker
            color={colorData}
            onChangeComplete={props.onChange}
            disableAlpha
          />
        </div>
      </div>
    );
  }

  return buildDMDropDownColorPicker();
};

export default DMDropDownColorPicker;
