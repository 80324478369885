import React, { useRef, useContext, useEffect, useState } from 'react'
import { useStateWithCallbackLazy } from 'use-state-with-callback'
import { Link, Route, withRouter } from 'react-router-dom'
import { AppStateContext } from '../AppStateContext'
import queryString from 'query-string'
import HelmetPageData from './common/HelmetPageData'
import LoadingScreen from './common/LoadingScreen'
import logo from '../images/logo.png'
import SideBarMenu from './navigation/sideBarMenu'
import AnimVersionPanel from './common/AnimVersionPanel'
import ModalCancellationSurvey from './ui/DMCancellationSurvey'
// import ParticlesBackground from './common/ParticlesBackground'
import * as Enums from './common/enums'
import '../sass/App.scss'

// product images for main page
import imgAnimate3D from '../images/dashboard/animate-3d.jpg'
import prodImg1 from '../images/dashboard/animate-3d.jpg'
import prodImg2 from '../images/dashboard/body-tracking-sdk.jpg'
import prodImg3 from '../images/dashboard/vr-tracking-sdk.jpg'
import prodImg4 from '../images/dashboard/ape-sdk.jpg'
import restApiIcon from '../images/animate-3d/icon-rest-api.png'

const otherSolutions = "Other Solutions"
const learnMore = "Learn More"
const contactSales = "Contact Sales"
const noProductsAvailable = "No active products found in your account..."
const salesEmail = "mailto:sales@deepmotion.com"
const prodPageAnim3d = "https://www.deepmotion.com/animate-3d"
const prodPageDMBT = "https://www.deepmotion.com/3d-body-tracking"
const prodPageVR = "https://www.deepmotion.com/virtual-reality-tracking"
const prodPageAPE = "https://www.deepmotion.com"
const contactSalesUrl = "https://www.deepmotion.com/general-contact"
const docTitle = "Dashboard | DEEPMOTION"
const metaDesc = "Welcome to the DeepMotion Product Portal, please select a product to begin."

const bodyTrackingSdkParams = {
  id: 1,
  img: prodImg2,
  title: Enums.productInfo.DMBT_SDK.name,
  descr: Enums.productInfo.DMBT_SDK.descr,
  route: Enums.routes.DMBTSdk
}
const vrTrackingSdkParams = {
  id: 2,
  img: prodImg3,
  title: Enums.productInfo.VR_SDK.name,
  descr: Enums.productInfo.VR_SDK.descr,
  route: Enums.routes.VRSdk
}
const apeSdkParams = {
  id: 3,
  img: prodImg4,
  title: Enums.productInfo.APE_SDK.name,
  descr: Enums.productInfo.APE_SDK.descr,
  route: Enums.routes.APESdk
}
const restApiParams = {
  id: 4,
  img: restApiIcon,
  title: "REST API",
  descr: "Create animations directly from your app or service",
  route: null
}
const initialTileHovers = [false, false, false, false, false]
let contactSubtitleClass = "is-4"
let iconSizeClass = "is-medium"
let row2TitleSizes = "is-5"

/////////////////////////////////////////////////////////////////////
// DashboardPage
//
// Upon successful login all users are redirected to this dashboard
// page where they can view/access DeepMotion Products that are
// assigned to them through Okta. This is done by Okta group.
/////////////////////////////////////////////////////////////////////
export default function DashboardPage(props) {

  const [dashState, setDashState] = useStateWithCallbackLazy({
    // used to mimic componentDidMount class lifecycle
    didComponentMount: false,
    // used to match rest API tile height with main tile height
    // mainA3dTileHeight: 0,
    // array of booleans used for CSS visibility & animations
    tileHovers: initialTileHovers
  })
  const heightRef = useRef(null)
  const appStateContext = useContext(AppStateContext)

  const [initializeA3D, setInitializeA3D] = useState(false)
  // handle component mount
  useEffect(() => {
    if( !appStateContext.state.accountDataRetrieved || !appStateContext.state.anim3dInitialized ) {
      setInitializeA3D(true)
      if (!initializeA3D) {
        props.initializeA3DService().then(() => {
          setInitializeA3D(false)
        })
      }
    }
    else {
      if( props.LOADING.show ) {
        props.setLOADING({...props.LOADING, ...{show: false}})
      }
    }
  }, [appStateContext.state.accountDataRetrieved, appStateContext.state.anim3dInitialized])

  // use a seperate hook to handle component un-mount
  useEffect(() => {
    return function cleanup() {
      setDashState({
        didComponentMount: false,
        // mainA3dTileHeight: 0,
        tileHovers: initialTileHovers
      }, () => { return })
    }
  }, [])

  /////////////////////////////////////////////////////////////////////////////
  function setTileHover(id, value, cb) {
    let newHoverState = JSON.parse(JSON.stringify(dashState.tileHovers))
    if( value === true ) {
      // if setting a tile hover ensure all other hovers are disabled
      for( let i = 0; i < newHoverState.length; i++ ) {
        if( i === id ) {
          newHoverState[i] = value
        }
        else {
          newHoverState[i] = false
        }
      }
    }
    else {
      newHoverState = [false, false, false, false]
    }
    let newDashState = dashState
    newDashState.tileHovers = newHoverState
    setDashState( prevState => ({...prevState, ...newDashState }), () => {
      if( cb ) {
        cb()
      }
    })
  }

  /////////////////////////////////////////////////////////////////////////////
  // Builds the primary (Animate 3D) Product tile
  /////////////////////////////////////////////////////////////////////////////
  function buildAnimate3DProductTile() {

    let tileClass = ""
    let borderClass = " dm-brand-border-md"
    let buttonClass = " btn-brand-alt-outline"
    let titleBackClass = " dm-brand"
    let titleTextClass = " has-text-white"
    let iconClass = " dm-brand-font"

    // if( dashState.tileHovers[Enums.productInfo.DMBT_Cloud.id-1] ) {
    if( appStateContext.state.accessList[0] ) {
      tileClass = " tile-border-pulse-hover glow-on-hover"
      borderClass = " dm-brand-border-md-alt"
      buttonClass = " btn-brand-alt"
      titleBackClass = " dm-brand-2"
      titleTextClass = " dm-brand-font"
      iconClass = " dm-brand-2-font"
    }
    let startingA3dRoute = Enums.routes.Anim3d
    if( appStateContext.state.jobsData && !appStateContext.state.jobsData.length ) {
      startingA3dRoute = Enums.routes.Anim3dGuidedFTE
    }

    return (
      <Link to={startingA3dRoute} onClick={ ()=>appStateContext.dispatch({currProductSelection: Enums.productInfo.DMBT_Cloud.id}) }>
        <div className={"bShadow" + tileClass}
        >
          <div className={"columns m-0 p-0 br-4 fullwidth selection-card" + borderClass}>
            <div className="column br-4 m-0 p-0 dm-brand has-text-centered" >

              <div className={"columns m-0 is-mobile fullwidth" + titleBackClass}>
                <div className="column has-text-centered">
                  <div className={"title is-4" + titleTextClass}>
                    {Enums.productInfo.DMBT_Cloud.name}
                  </div>
                </div>
              </div>

              <div className="columns m-0 p-0 ">
                <div className="column br-4-left m-0 p-0 dm-brand has-text-centered" ref={heightRef} >
                  <div className="img-16by9-wrapper">
                    <div className="img-16by9-img">
                      <figure className="image is-16by9">
                        <img src={imgAnimate3D} className="br-4-left" />
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </Link>
    )
  }

  ////////////////////////////////////////////////////////////////////
  // Builds A3D REST API Product Tile
  ////////////////////////////////////////////////////////////////////
  function buildA3dRestApiTile(params) {

    let tileClass = ""
    let borderClass = " dm-brand-border-lg"
    let titleBackClass = " dm-brand"
    let titleTextClass = " has-text-white"
    if( dashState.tileHovers[params.id] && params.available ) {
      tileClass = " tile-border-pulse glow-on-hover"
      borderClass = " dm-brand-border-md-alt"
      titleBackClass = " dm-brand-2"
      titleTextClass = " dm-brand-font"
    }

    return (
      <div className="bShadow">

        <Link
          to={params.available ? params.route : window.location.pathname }
          onClick={ (params.available ? ()=>appStateContext.dispatch({currProductSelection: params.id}) : null)}
          className={!params.available ? "no-cursor" : ""}
          >

          <div className={"br-4" + tileClass + borderClass} >
            <div className={"columns m-0 p-0 fullwidth" + titleBackClass}>
              <div className="column">
                <div className={"title is-4" + titleTextClass}> {params.title} </div>
              </div>
            </div>

            <div className="columns fullwidth m-0 p-0 ">
              <div className="column m-0 p-0 rest-api-bkgd is-relative has-text-centered" >

                <div className="img-16by9-wrapper is-block auto-margins" style={{width: '65%'}} >
                  <div className="img-16by9-img my-3">
                    <figure className="image is-16by9">
                      <img src={restApiIcon} className="br-4-left" />
                    </figure>
                  </div>
                </div>
                {
                  !params.available
                  &&
                  <div className="columns fullwidth m-0 p-0 " >
                    <div className="column m-0 p-0">
                      <div className={"fullwidth prod-lock-pos prod-lock-bkgd p-2 "}>
                        <span className={`icon ${iconSizeClass}`}><i className="fas fa-lock fa-lg" style={{color:'#cfc8c8'}}></i></span>
                        <span className={`subtitle ${contactSubtitleClass}`} style={{color:'#cfc8c8'}}> {contactSales} </span>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </Link>
      </div>
    )
  }

  ////////////////////////////////////////////////////////////////////
  // Builds UI product card for additional solutions other than A3D
  ////////////////////////////////////////////////////////////////////
  function buildProductCard(params) {

    let tileClass = ""
    let cardClass = ""
    let borderClass = " dm-brand-border-md"
    let titleBackClass = " dm-brand"
    let titleTextClass = " has-text-white"
    if( dashState.tileHovers[params.id] && params.available ) {
      tileClass = " tile-border-pulse glow-on-hover"
      cardClass = " selection-card-active"
      borderClass = " dm-brand-border-md-alt"
      titleBackClass = " dm-brand-2"
      titleTextClass = " dm-brand-font"
    }

    let cardImage = []
    if( params.available ) {
      cardImage.push(
        <div className="card-image" key={params.title}>
          <figure className={"image " + (params.wideScreen ? "is-16by9" : "is-4by3")}>
            <img src={params.img} className="m-0 br-none" alt={params.descr.replace(/ .*/,'')} />
          </figure>
        </div>
      )
    }
    else {
      cardImage.push(
        <div className="overlay is-relative" key={params.title}>
          <div className="card-image">
            <figure className={"image " + (params.wideScreen ? "is-16by9" : "is-4by3")}>

                <img src={params.img} className="m-0 br-none" alt={params.descr.replace(/ .*/,'')} />

            </figure>
          </div>
          <div className="fullwidth prod-lock-pos prod-lock-bkgd p-2">
            <span className={`icon ${iconSizeClass}`}><i className="fas fa-lock fa-lg" style={{color:'#cfc8c8'}}></i></span>
            <span className={`subtitle ${contactSubtitleClass}`} style={{color:'#cfc8c8'}}> {contactSales} </span>
          </div>
        </div>
      )
    }

    return (
      <Link
        to={params.available ? params.route : window.location.pathname}
        onClick={ (params.available ? (()=> appStateContext.dispatch({currProductSelection : params.id }) ) : null )}
        className={!params.available ? "no-cursor" : ""}
        >
        <div className={"bShadow br-4" + tileClass + borderClass} >
          <div className={"columns m-0 p-0 fullwidth" + titleBackClass}>
            <div className="column">
              <div className={`title ${titleTextClass} ${row2TitleSizes}`}> {params.title} </div>
            </div>
          </div>
          <div className={"card dm-brand-border-md br-top-none br-4-bot has-background-white" + cardClass}>

            {cardImage}

          </div>
        </div>
      </Link>
    )
  }

  /***************************************************************************
   * buildDMProductsDashboard
   ***************************************************************************/
  function DMProductsDashboard() {

    bodyTrackingSdkParams.available = appStateContext.state.accessList[ Enums.productInfo.DMBT_SDK.id-1 ]
    vrTrackingSdkParams.available = appStateContext.state.accessList[ Enums.productInfo.VR_SDK.id-1 ]

    //TODO: Need to build landing pages for REST API and APE SDK pages, also need
    //to determine how to figure out if user has API access
    apeSdkParams.available = false //props.accessList[ Enums.productInfo.APE_SDK.id-1 ]
    restApiParams.available = false
    // restApiParams.tileHeight = dashState.mainA3dTileHeight

    // build and return the products dashboard with access enabled/disabled
    // based on access
    return (
      <div id="anim-fadein" className="column">
        <div className="columns m-0 has-text-left">
          <div className="column m-0 anim-fadein">

            <div className="columns has-text-centered">
              <div className="column">

                <div className="tile is-ancestor">
                  <div className="tile is-12 is-parent is-justify-content-center">
                    <div className="tile is-child is-6 pr-3">
                      {buildAnimate3DProductTile()}
                    </div>
                    <div className="tile is-child pl-3">
                      {buildA3dRestApiTile(restApiParams)}
                    </div>
                  </div>
                </div>
                <div className="tile is-ancestor">
                  <div className="tile is-12 is-parent">
                    <div className="tile is-child pr-3">
                      {buildProductCard(bodyTrackingSdkParams)}
                    </div>
                    <div className="tile is-child pr-3 pl-3">
                      {buildProductCard(vrTrackingSdkParams)}
                    </div>
                    <div className="tile is-child pl-3">
                      {buildProductCard(apeSdkParams)}
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    )
  }

  if( window.innerWidth <= Enums.fullHDWidth ) {
    contactSubtitleClass = "is-6"
    iconSizeClass = "is-small"
    row2TitleSizes = "is-6"
  }
  else {
    contactSubtitleClass = "is-4"
    iconSizeClass = "is-medium"
    row2TitleSizes = "is-5"
  }

  /////////////
  // render
  /////////////
  return (
    <React.Fragment>
    <AnimVersionPanel pageTitle={props.pageTitle} />
    {
      props.LOADING.show
      ?
      <LoadingScreen/>
      :
      <div className="section m-0 p-0" >
        <HelmetPageData docTitle={docTitle} metaDesc={metaDesc} />
        <DMProductsDashboard />
      </div>
    }
    </React.Fragment>
  )
}
