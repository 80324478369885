// product names and descriptions for main dashboard page
export const productInfo = Object.freeze({
  HOME: 0,  // default means no product currently selected in UI
  DMBT_Cloud: {'id': 1, name: 'Animate 3D', descr: 'Generate 3D Animations and Images from Video'},
  DMBT_SDK: {'id': 2, name: 'Body Tracking SDK', descr: 'Real time body tracking for mobile and PC'},
  VR_SDK: {'id': 3, name: 'VR Tracking SDK', descr: 'Full body VR tracking using three or more trackers'},
  APE_SDK: {'id': 4, name: 'Physics Engine SDK', descr: 'A powerful and fast physics articulation engine'},
  numProducts: 4
})

// Product app routes
export const routes = Object.freeze({
  SignIn:             '/login',
  LoginCallback:      '/login/callback',
  Dash:               '/',
  Anim3d:             '/dashboard/animate-3d',
  Anim3dGuidedFTE:    '/dashboard/animate-3d/guide',
  Anim3dCreate:       '/dashboard/animate-3d/create',
  Anim3dJobTypeSelect:'/dashboard/animate-3d/select-job-type',
  Anim3dModelSelect:  '/dashboard/animate-3d/select-model',
  Anim3dModelUpload:  '/dashboard/animate-3d/upload-model',
  Anim3dModelManage:  '/dashboard/animate-3d/custom-models',
  Anim3dLibrary:      '/dashboard/animate-3d/library',
  Anim3dPreview:      '/dashboard/animate-3d/library/preview',
  Anim3dProfilePage:  '/dashboard/animate-3d/profile',
  DMBTSdk:            '/dashboard/body-tracking-sdk',
  VRSdk:              '/dashboard/vr-tracking-sdk',
  APESdk:             '/dashboard/physics-sdk',
  ActivityPage:       '/dashboard/activity',
  Admin:              '/dashboard/admin',
  Contact:            '/dashboard/contact',
  SupportPage:        '/dashboard/contact/support',
  FeedbackPage:       '/dashboard/contact/feedback',
  ForgotPwdPage:      '/forgot',
  Logout:             '/logout',
  UserForums:         '/oauth-api/forum',
  CreateAccount:      '/animate-3d/sign-up',
  ClosedAccount:      '/account-closed'
});

export const featureLockNames = Object.freeze({
  slowMotion: 'slowMotion',
  physicsFilter: 'physicsFilter',
  faceTracking: 'faceTracking',
  peSmoothness: 'peSmoothness',
  footLocking: 'footLocking',
  maxDuration: 'maxDuration',
  maxSize: 'maxSize',
  maxFps: 'maxFps',
  maxTrackingPersons: 'maxTrackingPersons',
  maxResolution: 'maxResolution'
})

// returns feature availability info based on user's subscription plan
//
// TBD: This client side featureLocksData should be removed and rely on the backend returned feature pack
// to determine if a feature is locked for a plan
export const featureLocksData = {
  slowMotion: [false, false, true, true, true, true],
  physicsFilter: [true, true, true, true, true, true],
  faceTracking: [true, true, true, true, true, true],
  peSmoothness: [false, false, false, true, true, true],
  footLocking: [
    [true, false, false, false],
    [true, false, false, false],
    [true, true, false, false],
    [true, true, true, true],
    [true, true, true, true],
    [true, true, true, true],
  ],
  maxDuration: [20.0, 20.0, 30.0, 120.0, 360.0, 480.0],
  maxSize: [104857600, 157286400, 209715200, 419430400, 1610612736, 2147483648],
  maxFps: [30.0, 30.0, 60.0, 120.0, 240.0, 240.0],
  maxTrackingPersons: [2, 3, 4, 6, 8, 16],
  maxResolution: [{w: 1920.0, h: 1080.0}, {w: 1920.0, h: 1080.0}, {w: 1920.0, h: 1080.0}, {w: 4096.0, h: 2160.0}, {w: 8192.0, h: 4320.0}, {w: 8192.0, h: 4320.0}]
}

export const setFeatureLocksData = (data) => {
  for(let key in data){
    Object.defineProperty(featureLocksData,key,{
      value:data[key]
    })
  }
}

// Metadata for generic character models used in Previewer
export const characterModels = Object.freeze({
  "1" : {fileName: 'female-normal', uiName: 'Adult Female'},
  "2" : {fileName: 'female-thin', uiName: 'Adult Female Alt'},
  "3" : {fileName: 'male-normal', uiName: 'Adult Male'},
  "4" : {fileName: 'male-fat', uiName: 'Adult Male Alt'},
  "5" : {fileName: 'male-young', uiName: 'Young Male'},
  "6" : {fileName: 'child', uiName: 'Child'},
})

// Default character subscript
export const defaultModelName = 'Adult Female'
export const robloxModelName = 'Roblox R15 Block'

export const animFileType = Object.freeze({
  bvh: "bvh",
  fbx: "fbx",
  gif: "gif",
  mp4: "mp4",
  glb: "glb",
  gltf: "gltf",
  dmpe: "dmpe",
  jpg: "jpg",
  png: "png",
  // TODO: Remove uppercase versions and add 'is-uppercase' Bulma CSS class where needed
  BVH: "BVH",
  FBX: "FBX",
  GIF: "GIF",
  MP4: "MP4",
  GLB: "GLB",
  GLTF: "GLTF",
  DMPE: "DMPE",
  JPG: "JPG",
  PNG: "PNG"
})

export const formType = Object.freeze({
  feedback: "FEEDBACK",
  survey_unsubscription: "SURVEY_UNSUBSCRIPTION" // survey for unsubscription
})

export const acceptedVideoFormats = Object.freeze([
  "mp4",
  "mov",
  "avi"
])

export const acceptedStaticPoseFormats = Object.freeze([
  "gif",
  "jpg",
  "jpeg",
  "png",
  "bmp"
])

export const outputFormats = Object.freeze(['jpg', 'png', 'gif', 'mp4'])

export const menuItemState = Object.freeze({
  "enabled": "item-enabled",
  "disabled": "item-disabled"
})

/**********************************************************
 * Pagination constants:
 **********************************************************/
// max page buttons in library
export const MAX_PAGE_BUTTONS = 7

// rows per page
export const ROWS_PER_PAGE = [5, 10, 20, 50, 100]

// max input filename length
export const MAX_FILENAME_LENGTH = 50

// key name for temp local storage of app state
export const localStorageStateId = 'dm-app-state'

export const oktaCacheStorage = 'okta-cache-storage'
export const oktaTokenStorage = 'okta-token-storage'

// returns true if non-ascii characters found in input
export function containsNonAsciiCharacters(input) {
  return /[^\u0000-\u007F]/.test(input)
}

// public method for encoding an Uint8Array to base64
export function encodeUint8ArrayToBase64 (input) {
  var keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/="
  var output = ""
  var chr1, chr2, chr3, enc1, enc2, enc3, enc4
  var i = 0

  while (i < input.length) {
    chr1 = input[i++]
    chr2 = i < input.length ? input[i++] : Number.NaN // Not sure if the index
    chr3 = i < input.length ? input[i++] : Number.NaN // checks are needed here

    enc1 = chr1 >> 2
    enc2 = ((chr1 & 3) << 4) | (chr2 >> 4)
    enc3 = ((chr2 & 15) << 2) | (chr3 >> 6)
    enc4 = chr3 & 63

    if (isNaN(chr2)) {
      enc3 = enc4 = 64
    } else if (isNaN(chr3)) {
      enc4 = 64
    }
    output += keyStr.charAt(enc1) + keyStr.charAt(enc2) +
              keyStr.charAt(enc3) + keyStr.charAt(enc4)
  }
  return output
}

// removes illegal or reserved key words from file name
export function removeIllegalOrReservedCharactersFromFileName(input) {

  // special check against reserved filesystem paths (also fixes
  // potential security hole). Also filename cannot start with "."
  if( input === "." || input === ".." || input[0] === "." ) {
    return ""
  }

  const nonASCIIRe = /[^\x00-\x7F]/g
  const illegalRe = /[\/\?\[\]#%<>\\:\*\|= "]/g
  const controlRe = /[\x00-\x1f\x80-\x9f]/g
  const reservedRe = /^\.+$/
  const windowsReservedRe = /^(con|prn|aux|nul|com[0-9]|lpt[0-9])(\..*)?$/i
  const windowsTrailingRe = /[\. ]+$/
  const replacement = ''

  let sanitized = input
    .replace(nonASCIIRe, replacement)
    .replace(illegalRe, replacement)
    .replace(controlRe, replacement)
    .replace(reservedRe, replacement)
    .replace(windowsReservedRe, replacement)
    .replace(windowsTrailingRe, replacement)

  // return sanitized.slice(0, MAX_FILENAME_LENGTH)
  // now handling max length exceeded at caller level
  return sanitized
}

// removes illegal or reserved key words
export function removeIllegalOrReservedCharacters(input) {

  // special check against reserved filesystem paths (also fixes
  // potential security hole). Also filename cannot start with "."
  if( input === "." || input === ".." || input[0] === "." ) {
    return ""
  }

  const illegalRe = /[\/\?\[\]#%<>\\:\*\|"]/g
  const controlRe = /[\x00-\x1f\x80-\x9f]/g
  const reservedRe = /^\.+$/
  const windowsReservedRe = /^(con|prn|aux|nul|com[0-9]|lpt[0-9])(\..*)?$/i
  const windowsTrailingRe = /[\. ]+$/
  const replacement = ''

  let sanitized = input
    .replace(illegalRe, replacement)
    .replace(controlRe, replacement)
    .replace(reservedRe, replacement)
    .replace(windowsReservedRe, replacement)
    .replace(windowsTrailingRe, replacement)

  // return sanitized.slice(0, MAX_FILENAME_LENGTH)
  // now handling max length exceeded at caller level
  return sanitized
}

/**********************************************************
 * formats raw bytes count into user friendly string
 **********************************************************/
export function formatSizeUnits(bytes, numDec){
  if      (bytes >= 1073741824) { bytes = (bytes / 1073741824).toFixed( numDec !== null ? numDec : 2 ) + " GB"; }
  else if (bytes >= 1048576)    { bytes = (bytes / 1048576).toFixed( numDec !== null ? numDec : 2 ) + " MB"; }
  else if (bytes >= 1024)       { bytes = (bytes / 1024).toFixed( numDec !== null ? numDec : 2 ) + " KB"; }
  else if (bytes > 1)           { bytes = bytes + " bytes"; }
  else if (bytes == 1)          { bytes = bytes + " byte"; }
  else                          { bytes = "0 bytes"; }
  return bytes;
}

/**********************************************************
 * formats raw bytes count into user friendly string
 **********************************************************/
export function convertBytesTo(bytes, format, numDec){
  if      (format === "GB")     { bytes = (bytes / 1073741824).toFixed( numDec !== null ? numDec : 2 ) + " GB"; }
  else if (format === "MB")     { bytes = (bytes / 1048576).toFixed( numDec !== null ? numDec : 2 ) + " MB"; }
  else if (format === "KB")     { bytes = (bytes / 1024).toFixed( numDec !== null ? numDec : 2 ) + " KB"; }
  else                          { bytes = bytes + " bytes"; }
  return bytes
}

/**********************************************************
 * formats seconds into user friendly string
 **********************************************************/
export function secondsToHms(d, fullWords) {
  d = Number(d);
  let neg = d > 0 ? "" : "-"
  d = Math.abs(d)
  let h = Math.floor(d / 3600);
  let m = Math.floor(d % 3600 / 60);
  let s = Math.floor(d % 3600 % 60);
  let minText = fullWords ? " minute " : " min "
  let secText = fullWords ? " second " : " sec "
  let minsText = fullWords ? " minutes " : " mins "
  let secsText = fullWords ? " seconds " : " sec "
  let hDisplay = h > 0 ? h + (h == 1 ? " hour " : " hours ") : "";
  let mDisplay = m > 0 ? m + (m == 1 ? minText : minsText) : "";
  let sDisplay = s > 0 ? s + (s == 1 ? secText : secsText) : "";
  return neg + hDisplay + mDisplay + sDisplay;
}

export function secondsToTime(unix_timestamp) {
  let time = new Date(Math.abs(unix_timestamp) * 1000).toISOString().substr(11, 8)
  if (unix_timestamp < 0) time = "-" + time
  return time
}

/**********************************************************
 * formats date to human readable string
 *
 * FIXME:
 * This function doesn't respect locale setting, needs better solution
 **********************************************************/
export function dateConverter(UNIX_timestamp, includeYear){
  const a = new Date(UNIX_timestamp * 1000)
  const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
  const month = months[a.getMonth()]
  const date = a.getDate()
  const year = a.getFullYear()
  let time = month + ' ' + date
  if( includeYear ) {
    time += ', ' + year
  }
  return time
}

//////////////////////////////////////////////////////////////
export function convertUnixDateToStandardFormat(unix_timestamp) {
  const d = new Date(unix_timestamp)
  // convert date to human readable format
  const formattedDate = [
    d.getMonth()+1,
    d.getDate(),
    d.getFullYear()].join('/')+' '+
  [ addZero(d.getHours()),
    addZero(d.getMinutes()),
    addZero(d.getSeconds())].join(':')
  return formattedDate
}

//////////////////////////////////////////////////////////////
export function earlierThanDate(unix_timestamp, year, month, day) {
  const d = new Date(unix_timestamp)
  const tsYear = d.getFullYear()
  const tsMonth = d.getMonth() + 1
  const tsDate = d.getDate()
  console.log(`earlierThanDate: is ${tsYear}/${tsMonth}/${tsDate} earlier than ${year}/${month}/${day}`)
  if(tsYear < year) return true
  if(tsYear > year) return false
  if(tsMonth < month) return true
  if(tsMonth > month) return false
  if(tsDate < day) return true
  return false
}

export function countDecimals(value) {
  if(Math.floor(value) === value) return 0
  return value.toString().split(".")[1].length || 0
}

/**********************************************************
 * Get user's first preferred browser language. Used for
 * formatting dates to user's proper locale
 **********************************************************/
export function getNavigatorLanguage() {
  if (navigator.languages && navigator.languages.length) {
    return navigator.languages[0];
  } else {
    return navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en-US';
  }
}

/**********************************************************
 * get file extension of pass in string
 **********************************************************/
export function getFileExtension(fileName) {
  return fileName.substr(fileName.lastIndexOf('.') + 1).toLowerCase();
}
export function isFileExtensionVideoFormat(fileName) {
  const fileExt = getFileExtension(fileName.toLowerCase())
  return( acceptedVideoFormats.includes(fileExt) )
}
/**********************************************************
 * helper formatting function for date strings
 **********************************************************/
export function addZero(i) {
  if (i < 10) {
    i = "0" + i;
  }
  return i;
}

/**********************************************************
 * helper function for sorting objects by key
 **********************************************************/
export function sortObject(obj) {
  return Object.keys(obj).sort().reduce(function (result, key) {
    result[key] = obj[key];
    return result;
  }, {});
}

// IDs for specific/custom Modals across the app
export const modalId = Object.freeze({
  // client side errors
  None:                   null
});

// IDs for error/warning/info dialogs across the app
export const confirmDialog = Object.freeze({
  none:                   null,
  inputFileTooBig:          1,
  inputFileCorrupt:         2,
  inputFileTooLong:         3,
  inputFileNameInvalid:     4,
  inputFileTypeWrong:       5,
  cancelInProgressJob:      6,
  changeJobModel:           7,
  removeJobModel:           8,
  removeCustChar:           9,
  customModelExists1:       10,
  customModelExists2:       11,
  standardDownload:         12,
  customDownload:           13,
  exitApplication:          14,
  closeUserAccount:         15,
  libraryJobSettings:       16,
  reRunJobConfig:           17,
  customModelSelect:        18,
  confirmNewAnimJob:        19,
  confirmLoseUploadData:    20,
  confirmInputMediaRemoval: 21,
  inputMaxLengthExceeded:   22,
  inputInvalidOrCorrupt:    23,
  inputFileNameRename:      24,
  cancelSubscription:       25,
  customMultiPersonDownload:26,

  // Base 100: Enforcement
  MinutesBalanceTooLow:     101,
  MaxResolutionExceeded:    102,
  MaxFPSExceeded:           103,
  MaxDurationExceeded:      104,

  // Extra Fallback/Custom Handling:
  Video2AnimFailure:        150,
  VideoValidationFailed:    151,
  VideoProblemsSolving:    152,
  CheckBeforeCreateJob:    153,

  // Base 200: Asset Pre-Processing
  VideoOrModelCopyError:    201,
  InvalidVideoCodecError:   202,

  // Base 300: CLI Pipeline
  InternalCliPipelineError: 300,

  // Base 500: DMBT
  FailedToParseArgsError:   503,
  FailedToLoadDataError: 504,
  ExplosionDetectedError: 505,
  FailedToCreatePoseEstimatorError: 506,
  FailedToCreateBodyTrackerError: 507,
  PoseEstimationTrackingError: 508,
  FailedToLoadConfigError: 509,
  FailedToOpenFileForWritingError: 510,
  InterruptedError: 511,
  FailedToDetectHuman: 513,

  // Base 700: DMFT
  InternalFaceTrackingError: 701,

  // Base 900: Vector CLI
  LoadMeshFailedError: 901,
  LoadBvhFailedError: 902,
  CopyAnimFailedError: 903,
  ExportFailedError: 904,
  MeshNotProvidedError: 905,
  BlendShapesLessThanHalfError: 906,
  LoadFaceDefinitionFailedError: 907,
  LoadDMFTDataFailedError: 908,
  LoadHumanoidMapError: 909,

  // Base 1100: Render CLI
  RenderCliError: 1101,
  InvalidInputParameterError: 1102,
  FailedToLoadOrPlayInputVideoError: 1103,
  FailedToLoadInputBvhError: 1104,
  FailedToLoadInputCharacterError: 1105,
  FailedToAttachAnimToCharacterError: 1106,
  FailedToConfigureBackDropError: 1107,
  FailedToCreateGifError: 1108
})

// STRINGS for error/warning/info dialogs across the app
export const confirmDialogMsgs = Object.freeze({
  inputFileTooBig:          "TODO:",
  inputFileCorrupt:         "TODO:",
  inputFileTooLong:         "TODO:",
  inputFileNameInvalid:     "TODO:",
  inputFileTypeWrong:       "TODO:",
  cancelInProgressJob:      "TODO:",
  changeJobModel:           "TODO:",
  removeJobModel:           "TODO:",
  removeCustChar:           "TODO:",
  customModelExists1:       "TODO:",
  customModelExists2:       "TODO:",
  standardDownload:         "TODO:",
  customDownload:           "TODO:",
  exitApplication:          "TODO:",
  closeUserAccount:         "TODO:",
  libraryJobSettings:       "TODO:",
  reRunJobConfig:           "TODO:",
  customModelSelect:        "TODO:",
  confirmNewAnimJob:        "TODO:",
  confirmLoseUploadData:    "TODO:",
  confirmInputMediaRemoval: "TODO:",

  // Base 100: Enforcement
  MinutesBalanceTooLow:     "Sorry, your account doesn't have enough animation time left to complete the job. Please upgrade to a higher tier plan or wait until the next bill cycle to receive more animation time.",
  MaxResolutionExceeded:    "TODO:",
  MaxFPSExceeded:           "TODO:",
  MaxDurationExceeded:      "TODO:",

  // Extra Fallback Handling:
  Video2AnimFailure:        "TODO:",

  // Base 200: Asset Pre-Processing
  VideoOrModelCopyError:    "TODO:",
  InvalidVideoCodecError:   "TODO:",

  // Base 300: CLI Pipeline
  InternalCliPipelineError: "Sorry, we had an internal error with our processing pipeline. If the problem continues please contact Support for help.",

  // Base 500: DMBT
  FailedToParseArgsError:   "Sorry, we had an internal error processing the tracking parameters. If the problem continues please contact Support for help.",
  FailedToLoadDataError: "Sorry, we had an internal error loading character assets. Please contact Support for help.",
  ExplosionDetectedError: "Sorry, A Physics Filter incompatibility was detected when processing this video using the chosen character. Please consider turning off the Physics Filter.",
  FailedToCreatePoseEstimatorError: "Sorry, we had an internal error creating your pose estimation. Please contact Support for help.",
  FailedToCreateBodyTrackerError: "Sorry, we had an internal error while performing body tracking for your input. Please contact Support for help.",
  PoseEstimationTrackingError: "Sorry, your input video or image doesn’t meet our requirements to generate animations of good quality.<br/><br/>Please review our <a href='https://bit.ly/Capture_Guidelines' target='_blank' rel='noopener noreferrer'>video capture guidelines</a> and submit a new video that meets the guidelines. If the problem continues please contact Support and send the video or image you used.",
  FailedToLoadConfigError: "Sorry, we had an internal error loading the job configuration. If the problem continues please contact Support for help.",
  FailedToOpenFileForWritingError: "Sorry, we had an internal error trying to generate animation results. Please contact Support for help.",
  InterruptedError: "Sorry, we had an internal error and body tracking was interrupted. If the problem continues please contact Support for help.",
  FailedToDetectHuman: "Sorry, we were not able to detect a human character in your video. Please be sure your video includes a realistic human character (not a cartoon character), review our <a href='https://bit.ly/Capture_Guidelines' target='_blank' rel='noopener noreferrer'>video capture guidelines</a> and try again. If the problem continues please contact Support for help.",

  // Base 700: DMFT
  InternalFaceTrackingError: "Sorry, we had an error during face tracking. Please make sure your facial rig meets the <a target='_blank' rel='noopener noreferrer' href='https://blog.deepmotion.com/2021/07/02/animate3d-facetracking-faq'> requirements </a> or try a new custom character model that supports ARKit Blendshapes.",

  // Base 900: Vector CLI
  LoadMeshFailedError: "Sorry, we had an error loading the mesh of your custom character. Please make sure your custom character meets the <a target='_blank' rel='noopener noreferrer' href='https://blog.deepmotion.com/2020/11/19/animate-3d-custom-characters'> requirements </a> or try a new custom character model. If the problem continues please send your video, custom character model, and this error code to Support for help.",
  LoadBvhFailedError: "Sorry, we had an error while loading the BVH custom character file. Please make sure your custom character meets the <a target='_blank' rel='noopener noreferrer' href='https://blog.deepmotion.com/2020/11/19/animate-3d-custom-characters'> requirements </a> or try a new custom character model. If the problem continues please send your video, custom character model, and this error code to Support for help.",
  CopyAnimFailedError: "Sorry, we had an error copying animations onto your custom character. Please make sure your custom character meets the <a target='_blank' rel='noopener noreferrer' href='https://blog.deepmotion.com/2020/11/19/animate-3d-custom-characters'> requirements </a> or try a new custom character model. If the problem continues please send your video, custom character model, and this error code to Support for help.",
  ExportFailedError: "Sorry, we had an error exporting animations for your custom character. Please make sure your custom character meets the <a target='_blank' rel='noopener noreferrer' href='https://blog.deepmotion.com/2020/11/19/animate-3d-custom-characters'> requirements </a> or try a new custom character model. If the problem continues please send your video, custom character model, and this error code to Support for help.",
  MeshNotProvidedError: "Sorry, your custom character doesn’t include skinned mesh information. Please make sure your custom character meets the <a target='_blank' rel='noopener noreferrer' href='https://blog.deepmotion.com/2020/11/19/animate-3d-custom-characters'> requirements </a> or upload a rigged custom character model with skinned mesh in it.",
  BlendShapesLessThanHalfError: "Sorry, your character is missing more than half of the required blendshapes for Face Tracking. Please make sure your facial rig meets the <a target='_blank' rel='noopener noreferrer' href='https://blog.deepmotion.com/2021/07/02/animate3d-facetracking-faq'> requirements </a>, or upload a new custom character with the complete set of blendshapes.",
  LoadFaceDefinitionFailedError: "Sorry, we had an internal error loading facial definition for your custom character. Please make sure your facial rig meets <a target='_blank' rel='noopener noreferrer' href='https://blog.deepmotion.com/2021/07/02/animate3d-facetracking-faq'> requirements </a> or upload a custom character with the complete set of blendshapes. If the problem persists please send your video, custom character model, and this error to Support for help.",
  LoadDMFTDataFailedError: "Sorry, we had an internal error loading facial tracking data. Please make sure your facial rig meets the <a target='_blank' rel='noopener noreferrer' href='https://blog.deepmotion.com/2021/07/02/animate3d-facetracking-faq'> requirements </a> or upload a new custom character with the complete set of blendshapes. If the problem continues please send your video, custom character model, and this error to Support for help.",
  LoadHumanoidMapError: "Sorry, we had an internal error loading the metadata of your custom character. Please make sure your custom character meets the <a target='_blank' rel='noopener noreferrer' href='https://blog.deepmotion.com/2020/11/19/animate-3d-custom-characters'> requirements </a> or try a new custom character.  If the problem continues please send your video, custom character model, and this error to Support for help.",

  // Base 1100: Render CLI
  RenderCliError: "Sorry, we had an internal error while rendering the result into a video or image. Please send your video, custom character model (if any), and this error to Support for help.",
  InvalidInputParameterError: "Sorry, we had an internal error while rendering the result into a video or image. Please send your video, custom character model (if any), and this error to Support for help.",
  FailedToLoadOrPlayInputVideoError: "Sorry, we had an internal error while loading your input video or image. Please send your video, custom character model (if any), and this error to Support for help.",
  FailedToLoadInputBvhError: "Sorry, we had an internal error while loading the BVH file for video/image rendering. Please send your video, custom character model (if any), and this error to Support for help.",
  FailedToLoadInputCharacterError: "Sorry, we had an internal error while loading your character for the video/image rendering. Please send your video, custom character model (if any), and this error to Support for help.",
  FailedToAttachAnimToCharacterError: "Sorry, we had an internal error attaching your animation to the character for the video/image rendering. Please send your video, custom character model (if any), and this error to Support for help.",
  FailedToConfigureBackDropError: "Sorry, we had an internal error while configuring the backdrop for the video/image rendering. Please send your video, custom character model (if any), and this error to Support for help.",
  FailedToCreateGifError: "Sorry, we had an internal error while rendering GIF output. Please send your video, custom character model (if any), and this error to Support for help."
})

export function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value);
}

export function createMarkup(content) {
  return {__html: content};
}

export const jobMenu = Object.freeze({
  animSettings:     0,
  videoSettings:    1
})

export const browserCookieIds = Object.freeze({
  1: 'ln',
  2: 'connect.sid',
  3: '_dm_acc'
})

// Checking against some common HTTP error codes...
export const eCodes = Object.freeze({
  // client side errors
  BadRequest:               400,
  Unauthorized:             401,
  Forbidden:                403,
  NotFound:                 404,
  RequestTimeout:           408,
  ClosedNoResponse:         444,
  ClientClosedRequest:      499,
  // server side errors
  InternalServerError:      500,
  BadGateway:               502,
  ServiceUnavailable:       503,
  GatewayTimeout:           504,
  InsufficientStorage:      507,

  OtherError:               1000
})

// OKTA API error codes...
export const oktaErrorCodes = Object.freeze({
  // client side errors
  None:           '',
  AccountExists:  'E0000001',
  UnsupportedOp:  'E0000060'
});

// Custom Error Codes
export const customErrors = Object.freeze({
  FBXImporterInitializeFailure: "FBX File Error",
  FBXImportFileFailure: "Model Import Failure",
  FBXSceneIntegrityVerificationFailure: "FBX Scene Integrity Failure",
  FBXJointNameHasSpace: "Model Joint Names Have Spaces",
  FBXJointNameNotUnique: "Model Joint Names Are Not Unique",
  FBXAssetBelowGround: "Model Below Ground",
  FBXMultipleJointRoot: "Multiple Joint Roots Found",
  FBXNoRiggedMesh: "No Mesh Found in Model",
  AutoMappingMandatoryJointsNotMapped: "Required Joints Missing or Invalid",
  AutoMappingJointsNotUnique: "Model Joint Mappings Not Unique",
  CurrentPoseNotTPose: "Model's Bind Pose Must Be T-Pose",
  TemplateSceneInvalid: "Problem With FBX Scene",
  TemplateTgtMapInvalid: "Model Template Map Invalid",
  TemplateTgtMapNotMatchingMultibody: "Model Template Map Error",
  VideoToAnimProcessingError : "Error processing video2anim",
  GCPVideoCopyError : "Error in copying video file from GCS"
});

// Tool tips for various job settings & options:
export const toolTipMp4Shadow = "Renders shadows in the MP4 output when enabled, otherwise no shadows will be included in the output."
export const toolTipImageShadow = "Renders shadows in the image output when enabled, otherwise no shadows will be included in the output."
export const toolTipIncludeVid = "Shows the input video clip in the background when turned on (enabling this option disables the Custom Background option)."
export const toolTipIncludeImg = "Shows the input image in the background when turned on (enabling this option disables the Custom Background option)."
export const toolTipIncludeAud = "When enabled includes the audio of the original input video in the generated animation. Disabling this option disables audio in output animation."
export const toolTipMp4 = "Enable this option to create MP4 output files for your animations. Enabling this option significantly increases animation processing time (unavailable if FBX Output is disabled)."
export const toolTipFbx = "Enable this option to create FBX output files for your animations (automatically enabled for jobs that use a customer character, have Face Tracking enabled, or have MP4 output enabled)."
export const toolTipJpg = "Enable this option to create a JPG output file for your static pose."
export const toolTipPng = "Enable this option to create a PNG output file for your static pose."
export const toolTipGif= "Enable this option to create a GIF output file for your static pose."
export const toolTipGlb = "GLB output files are automatically generated for jobs that use a custom character and disabled for jobs that use the default character set."
export const toolTipFBXKeyFrame = "Lowers key frame frequency and converts the result into a file format that supports interpolation while also reducing file size (may not work for all inputs)."
export const toolTipPhysSim = "Reinforces stronger joint limits and attempts to remove self-collisions and clipping."
export const toolTipStaticPoseFootLocking = "<strong>Off:</strong> Choose it if the character is touching the ground. It will affect the generated position and pose.<br/><br/><strong>On:</strong> Choose it if the character is not touching the ground. This setting will also hide the ground plane in the 3D Preview and the rendered image."
export const toolTipFaceTrack = "Turn on to track basic facial expressions. Compatible with default characters, custom characters created through Animate 3D and uploaded character rigs that contain ARKit blendshapes. Enabling this option increases processing time."
export const toolTipHeadTrack = "Turn on to add additional Head Rotation Tracking sensitivity. This feature utilizes our Face Tracking technology and costs an additional .5 credits/sec of animation."
export const toolTipHandTrack = "Turn on to track basic hand and finger motions. Compatible with default characters, custom characters created through Animate 3D and uploaded character rigs that contain the proper finger rig. Enabling this option increases processing time."
export const toolTipChangeVideo = "Remove the video"
export const toolTipChangeImage = "Remove the image"
export const toolTipChangeModel = "Change the model"
export const toolTipSmoothness = 'Applies an advanced AI filter that helps remove jitter and produce smoother animations though may result in lower animation accuracy for certain frames or sequences.<br /><span class="dm-brand-font"> [Available for Professional or higher plans]</span>'
export const toolTipEyeTrackingSensitivity = 'Fine-tune the sensitivity of eye iris tracking. The higher the number, the more sensitive the iris motions.<br /><span class="dm-brand-font"> [Available when Face Tracking is enabled]</span>'
export const toolTipVideSpeedMult = 'For input videos that have been slowed down enabling this option can help improve the resulting animation quality. <br/><br/>For example, if your input video speed moves at 1/2 speed then set the speed multiplier to 2x to improve animation quality. <span class="dm-brand-font"> [Available for Innovator or higher plans]</span>'
export const toolTipRootJoint = "Legacy setting for Unreal Engine characters. Turning this on automatically selects a character with a root joint at the origin for retargeting in UE. This is a legacy setting - Unreal Engine characters are now available in the 3D Model Library."
export const toolTipUpperBodyOnly = "Only track the upper body motion or pose from the input video or image. The lower body motion or pose will be copied from the Fallback Pose configured."
export const toolTipMp4BackType = 'Choose from a variety of background options for your animation render'
export const toolTipMp4BackColor = 'Sets MP4 background color when Custom Background option is set to either <strong>Solid</strong> or <strong>Studio</strong>'
export const toolTipPoseBackType = 'Choose from a variety of background options for your pose render'
export const toolTipPoseBackColor = 'Sets Image background color when Custom Background option is set to either <strong>Solid or <strong>Studio</strong> (.png and .gif will be transparent)'
export const tooltipFootLocking = '<strong>Auto</strong>: Automatic switching between locking and gliding modes of the foot (recommended for general cases).<br/><br/><strong>Never</strong>: Force foot locking off all the time. Recommended for motions that are completely in the air or in water. <span class="dm-brand-font">[Available for Innovator or higher plans]</span><br/><br/><strong>Always</strong>: Force foot locking on all the time. Recommended when <em>Auto</em> mode cannot remove all foot gliding issues. <span class="dm-brand-font">[Available for Professional or higher plans]</span><br/><br/><strong>Grounding</strong>: Turns foot locking off however character grounding is still enforced. Recommended when foot gliding is desired or when <em>Auto</em> mode locks the feet for too long during fast leg movements. <span class="dm-brand-font">[Available for Professional or higher plans]</span>'
export const tooltipFallbackPose = '<strong>A-Pose</strong>: Fallback character pose with arms at 45-degree angles when joints are not detected.<br/><br/><strong>I-Pose</strong>:  Fallback character pose with hands rested on the back when joints are not detected.<br/><br/><strong>T-Pose</strong>: Fallback character pose with arms horizontally outstretched when joints are not detected.<br/><br/><strong>Sitting</strong>: Fallback character pose with arms at 45-degree angles and legs in sitting position when joints are not detected.'
export const tooltipMp4Camera = '<strong>Cinematic</strong>: The character is kept in the center of the frame.<br/><strong>Fixed</strong>: Camera will stay fixed relative to the background.<br/><strong>Face</strong>: Camera keeps the torso and face in the center of frame.'
export const tooltipReRun = 'Re-run job with different settings to potentially improve your animation results.<div class="notification is-info is-light mt-3"><span>Re-running an animation does <strong class="has-text-info">not</strong> deduct any additional time from your account and is intended to help improve your existing animations.</span></div>'
export const toolTipVideoCameraHorizontalAngle = 'Camera horizontal angle in degrees, where 0 means forward camera.'

// credits tips
export const animCreditsDescr = (
  <>
    <div className="subtitle is-5 dm-brand-font">
      Animation credits are used for AI animation services at the following
      rate:
    </div>
    <div className="mt-4 subtitle is-5 dm-brand-font">
      <div className="is-flex is-flex-align-center">
        <div className="title is-5 m-0 dm-brand-font">Body Animation</div>: 1 credit / second
      </div>
      <div className="is-flex is-flex-align-center">
        <div className="title is-5 m-0 dm-brand-font">+Face Tracking</div>: +0.5 credit / second
      </div>
      <div className="is-flex is-flex-align-center">
        <div className="title is-5 m-0 dm-brand-font">+Hand Tracking</div>: +0.5 credit / second
      </div>
    </div>
    <div className="subtitle is-5 py-4 dm-brand-font">OR</div>
    <div className="subtitle is-5 dm-brand-font">
      <div className="is-flex is-flex-align-center">
        <div className="title is-5 m-0 dm-brand-font">Body Pose</div>: 1 credit / pose
      </div>
      <div className="is-flex is-flex-align-center">
        <div className="title is-5 m-0 dm-brand-font">+Face Tracking</div>: +0.5 credit / pose
      </div>
      <div className="is-flex is-flex-align-center">
        <div className="title is-5 m-0 dm-brand-font">+Hand Tracking</div>: +0.5 credit / pose
      </div>
    </div>
    <div className="subtitle is-5 mt-4 dm-brand-font">
      Unused credits do not carry over to the next month.
    </div>
  </>
)

export const subscriptionAnimTimeTip = (isStudioUser, plan) => {
  const isFreemium = plan === accountPlansInfo[0].name;
  return (
    <div className="subtitle is-5 dm-brand-font">
      You currently have
      <span className="has-text-weight-semibold mx-1">
        {isStudioUser ? 'Highest Priority credits' : !isFreemium ? "High Priority credits" : "Low Priority credits"}
      </span>
      in your
      <span className="has-text-weight-semibold mx-1">{plan} Plan</span>.
      {isStudioUser
        ? `After your Highest
        Priority Credits are used, your account will switch to Low Priority for
        unlimited use.`
        : "Upgrade your plan type for higher priority credits. "}
    </div>
  );
};

export const animationLabelingCreditsTip = (
  <div className="subtitle is-5 dm-brand-font">
    <span className="has-text-weight-semibold dm-brand-font mr-1">
      DeepMotion’s Free Animation Credit Program
    </span>
    provides
    <span className="has-text-weight-semibold dm-brand-font mx-1">
      FREE credits when you label your animations!
    </span>
    The better the text label&apos;s quality, the more free credits you get.
    <div className="mt-2">
      This counter shows how many credits you&apos;ve earned to date for this ongoing
      subscription period.
    </div>
    <div className="mt-2">
      Please note: The earned credits will be added to your account at the job
      priority level of your subscription type. You can only earn back up to the
      amount of credits that were used for a job.
    </div>
  </div>
);

export const animationCorrectionCreditsTip = (
  <div className="subtitle is-5 dm-brand-font">
    <span className="has-text-weight-semibold dm-brand-font mr-1">
      DeepMotion’s Free Animation Credit Program
    </span>
    provides
    <span className="has-text-weight-semibold dm-brand-font mx-1">
      FREE credits when you correct your animation quality in our Rotoscope Pose Editor! 
    </span>
    The higher quality score,  the more free credits you get.
    <div className="mt-2">
      This counter shows how many credits you’ve earned to date for this ongoing subscription period. 
    </div>
    <div className="mt-2">
      Please note: The earned credits will be added to your account at the job priority level of your subscription type. You can only earn back up to the amount of credits that were used  for a job.
    </div>
  </div>
);

// Tool tips for motion labeling:
export const motionLabelingTips = 'Describe the motion from your video in descriptive words and be awarded <strong>FREE animation credits</strong> through our <strong>Free Animation Credit Program</strong>.<br/><br/> <strong>Highly rated descriptions through our automated scoring system will earn you more free credits.</strong> You can earn back the total amount of credits you put into any given job.<br/><br/> Recommendations for getting a higher quality rating include providing descriptions covering action type, direction, style, trajectory, emotions, and key details for thorough animation labeling. <strong>Use all 256 characters for a higher rating.</strong>'
export const poseLabelingTips = 'Describe the pose from your photo in descriptive words and be awarded <strong>FREE animation credits</strong> through our <strong>Free Animation Credit Program</strong>.<br/><br/> <strong>Highly rated descriptions through our automated scoring system will earn you more free credits.</strong> You can earn back the total amount of credits you put into any given job.<br/><br/> Recommendations for getting a higher quality rating include providing descriptions covering action type, direction, style, trajectory, emotions, and key details for thorough animation labeling. <strong>Use all 256 characters for a higher rating.</strong>'

// breakpoints for mobile and tablet devices (supported through useWindowSize.js hook)
export const mobileWidth = 767
export const tabletWidth = 1023
export const fullHDWidth = 1440
export const largeDisplayWidth = 2560

// minimum form field length requirements
export const minNameLength = 2
export const minCompanyLength = 3
export const minOtherLength = 5
export const minPwdLength = 5

// css classes for ok or missing required fields and icon spans
export const normalInputClass = "input"
export const missingInputClass = "input is-danger"
export const normalSelectClass = "select"
export const missingSelectClass = "select is-danger"
export const normalTextAreaClass = "textarea"
export const missingTextAreaClass = "textarea is-danger"
export const inputSpanHide = "icon is-small is-right hide"
export const inputSpanShow = "icon is-small is-right show"
export const inputSpanShowSuccess = "icon is-small is-right show success-icon"

// minimum character length for feedback form title and description
export const minInputLength = 3

// minimum
export const minJobIdLength = 19

// reg expression used for email input validation
// Note: eslint comment below is used to disable compiler warnings!
//eslint-disable-next-line
export const validEmailRegex = RegExp(/^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i)
export const validNameRegex = RegExp(/^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]{1,30}$/u)
// number of mins before timeout due to inactivity
export const idleTimeoutInMins = 120

//************************************************************
// Returns a new form field state object in following format:
//
//  {
//    "value": "<value>",   // any valid JS object
//    "isValid": <boolean>, // is field ready for submit?
//    "span": "<string>",   // controls form icon display
//    "iClass": "<string>"  // controls form css class
//  }
//
//************************************************************/
export const buildStateObj = ( value, isValid, span, iClass ) => {
  const newStateObj = {
    "value": value,
    "isValid": isValid,
    "span": span,
    "iClass": iClass
  }
  return newStateObj
}

//************************************************************
// Performs a deep compare of two state objects defined above
//************************************************************/
export const compareStates = ( obj1, obj2 ) => {
  if(
    obj1.value === obj2.value &&
    obj1.isValid === obj2.isValid &&
    obj1.span === obj2.span &&
    obj1.iClass === obj2.iClass
  ) {
    return true
  }
  else {
    return false
  }
}

// different states for the feedback submission form
export const FORM_STATE = Object.freeze({
  "ready":0,
  "inProgress":1,
  "success":2,
  "failure":3
})

export const pageState = Object.freeze({
  "mount":                  0,
  "init":                   1,
  "ready":                  2,
  "apiInProgress":          3,
  "renameModelDialog":      4,
  "savingModel":            5,
  "modelCreatedDialog":     6,
  "modelUploadedDialog":    7,
  "modelDeletedDialog":     8,
  "rerunAnimSettings":      9,
  "rerunVideoSettings":    10,
  "rerunInputInfo":        11
})

// Enum for library column names
export const libraryColName = Object.freeze([
  "name",
  "length",
  "size",
  "credits",
  "date"
])

// breadcrumb names for standard anim job
export const createAnimBreadCrumbs = Object.freeze([
  "Character Type",
  "3D Model",
  "Motion Video",
  "Job Settings"
])

// breadcrumb names for custom anim job
export const libraryBreadCrumbs = Object.freeze([
  "Library",
  "Preview"
])

// various ui states for creating a new job
export const uiStates = Object.freeze({
  "initial":                0,
  "fileSelected":           1,
  "jobInProgress":          2,
  "jobQueued":              3
})

export const interestOptions = [
  "Game Development",
  "Social Media Content Creation",
  "For use in Film or TV",
  "Streaming / Live Broadcast",
  "Other"
]

export const hearFromOptions = [
  '',
  'VRWorldTech',
  'Venture Beat',
  'Intel DevMesh',
  'Web Search',
  'Twitter',
  'LinkedIn',
  'Facebook',
  'Instagram',
  'YouTube',
  'Reddit',
  'Medium',
  'Word of mouth',
  'Other'
]

export const accountPlansInfo = Object.freeze([
{
  name:"Freemium",
  price:"Free",
  priceId:"",   // n/a
  productId:"", // n/a
  callToAction:"SIGN UP",
  info:"no credit card required",
  mins:"60 credits / month, for free!",
  minsInt:1,
  fps:"Max 30 FPS input videos",
  resolution:"Max Full-HD (1080p) input videos",
  models:"Store up to 3 Custom Characters",
  modelsInt:3,
  storage:"Single video upload size limit: 100 MB",
  output:"Export FBX, BVH, GLB, MP4, JPG, and PNG formats",
  vidOutput:"DeepMotion Branded MP4 video output",
  privacy:"Private uploads",
  rights:"Licensed for Personal Use",
  url:"https://portal.deepmotion.com/animate-3d/sign-up",
  tagClass:"tag is-light is-medium",
  skuColor:"rgb(250, 176, 60)"
},
{
  name:"Starter",
  price:"$19",
  price_an:"$12",
  productId: process.env.REACT_APP_PRODUCT_ANIMATION3D_STARTER_ID,
  priceId: process.env.REACT_APP_PRICE_ANIMATION3D_STARTER_ID,
  callToAction:"BUY NOW",
  info:"per user, per month",
  mins:"180 credits / month",
  minsInt:3,
  fps:"Max 30 FPS input videos",
  resolution:"Max Full-HD (1080p) input videos",
  models:"Store up to 5 Custom Characters",
  modelsInt:5,
  storage:"Single video upload size limit: 100 MB",
  output:"Export FBX, BVH, GLB, MP4, JPG, and PNG formats",
  vidOutput:"MP4 video output",
  privacy:"Private uploads",
  rights:"Licensed for Commercial Use",
  url:"/checkout",
  tagClass:"tag is-success is-medium",
  skuColor:"rgb(72, 199, 142)"
},
{
  name:"Innovator",
  price:"$48",
  price_an:"$17",
  productId: process.env.REACT_APP_PRODUCT_ANIMATION3D_INNOVATOR_ID,
  priceId: process.env.REACT_APP_PRICE_ANIMATION3D_INNOVATOR_ID,
  callToAction:"BUY NOW",
  info:"per user, per month",
  mins:"480 credits / month",
  minsInt:8,
  fps:"Max 60 FPS input videos",
  resolution:"Max Full-HD (1080p) input videos",
  models:"Store up to 10 Custom Characters",
  modelsInt:10,
  storage:"Single video upload size limit: 200 MB",
  output:"Export FBX, BVH, GLB, MP4, JPG, and PNG formats",
  vidOutput:"MP4 video output",
  privacy:"Private uploads",
  rights:"Licensed for Commercial Use",
  url:"/checkout",
  tagClass:"tag is-primary is-medium",
  skuColor:"rgb(0, 209, 178)"
},
{
  name:"Professional",
  price:"$117",
  price_an:"$39",
  productId: process.env.REACT_APP_PRODUCT_ANIMATION3D_PROFESSIONAL_ID,
  priceId: process.env.REACT_APP_PRICE_ANIMATION3D_PROFESSIONAL_ID,
  callToAction:"BUY NOW",
  info:"per user, per month",
  mins:"1500 credits / month",
  minsInt:25,
  fps:"Max 120 FPS input videos",
  resolution:"Max 4K (2160p) input videos",
  models:"Store up to 20 Custom Characters",
  modelsInt:20,
  storage:"Single video upload size limit: 400 MB",
  output:"Export FBX, BVH, GLB, MP4, JPG, PNG, and DMPE formats",
  vidOutput:"MP4 video output",
  privacy:"Private uploads",
  rights:"Licensed for Commercial Use",
  url:"/checkout",
  tagClass:"tag is-info is-medium",
  skuColor:"rgb(32, 156, 238)"
},
{
  name:"Studio",
  price:"$300",
  price_an:"$83",
  productId: process.env.REACT_APP_PRODUCT_ANIMATION3D_STUDIO_ID,
  priceId: process.env.REACT_APP_PRICE_ANIMATION3D_STUDIO_ID,
  callToAction:"BUY NOW",
  info:"per user, per month",
  mins:"7200 credits / month",
  minsInt:120,
  fps:"Max 240 FPS input videos",
  resolution:"Max 4K (2160p) input videos",
  models:"Store up to 50 Custom Characters",
  modelsInt:50,
  storage:"Single video upload size limit: 1.5GB",
  output:"Export FBX, BVH, GLB, MP4, JPG, PNG, and DMPE formats",
  vidOutput:"MP4 video output",
  privacy:"Private uploads",
  rights:"Licensed for Commercial Use",
  url:"/checkout",
  tagClass:"tag is-link is-medium",
  skuColor:"rgb(72, 95, 199)"
},
{
  name:"Enterprise",
  tagClass:"tag is-link is-medium",
  skuColor:"rgb(50, 115, 220)",
  modelsInt:100
}

])

export function sec2time(timeInSeconds) {
    var pad = function(num, size) { return ('000' + num).slice(size * -1); },
    time = parseFloat(timeInSeconds).toFixed(3),
    hours = Math.floor(time / 60 / 60),
    minutes = Math.floor(time / 60) % 60,
    seconds = Math.floor(time - minutes * 60),
    milliseconds = time.slice(-3);

    return pad(hours, 2) + ':' + pad(minutes, 2) + ':' + pad(seconds, 2) + ',' + pad(milliseconds, 3);
}

export function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

/**********************************************************
 * A3D Admin Tool values:
 **********************************************************/

export const currentDatabase = Object.freeze({
  development:    0,
  production:     1
})

export const activeTab = Object.freeze({
  apiUser:        "API Users",
  customPack:     "Custom Packs",
  portalUser:     "Portal Users",
  listJobs:       "List Jobs",
  listModels:     "List Models",
  bulkOperations: "Bulk Operations",
  generalSupport: "General Support",
  promotion: "Promotion"
})

export const packTableType = Object.freeze({
  minutePack:         "Minute Packs",
  featurePack:        "Feature Packs"
})

// Active modal Enum for API User Management Tab
export const activeModal = Object.freeze({
  none:                   null,
  createCredential:       1,
  updatePaymentPlan:      2,
  addMinutePack:          3,
  setFeaturePack:         4,
  requestBillData:        5,
  viewBillData:           6,
  viewApiUserSecret:      7,
  createMinutePack:       8,
  createFeaturePack:      9,
  packDetailView:         10,
  successModal:           11,
  portalPayStartDate:     12,
  modifyPortalPlan:       13,
  portalAddMinutePack:    14,
  portalSetFeaturePack:   15,
  portalTempMinute:       16,
  packConfirmation:       17,
  dateConfirmation:       18,
  tempMinConfirmation:    19,
  credConfirmation:       20,
  packCreateConfirmation: 21,
  success:                22,
  confirmBulkDeactivate:  23,
  loadingModal:           24,
  failureModal:           25
})

export const FTESteps = Object.freeze({
  begin:              0,
  reviewGuidelines:   1,
  addMotionClip:      2,
  selectModel:        3,
  choseJobSettings:   4
})

export const cameraMotionSettings = Object.freeze([
  'cinematic',
  'fixed',
  'face'
])

export const footLockMode = Object.freeze({
  auto:      'auto',
  never:     'never',
  always:    'always',
  grounding: 'grounding'
})

export const fallbackPose = Object.freeze({
  a_pose:    'A-Pose',
  i_pose:    'I-Pose',
  t_pose:    'T-Pose',
  sitting:   'Sitting'
})

export const Backdrop = Object.freeze({
  default: 'default',
  defaultWithOriginal: 'default With Original',
  environments: 'environments',
  solid: 'solid',
  studio: 'studio',
  transparent: 'transparent (PNG/GIF)'
})

export const BackdropEnv = Object.freeze([
  'Jungle_Grove',
  'Checker_Cloudscape',
  'Golden_Age_Glitz',
  'Inferno_Stage',
  'Mystic_Brick',
  'Mystic_Concrete',
  'Rainbow_Spotlight',
  'Red_Carpet',
  'Retro_Revue',
  'Sakura_Dreamscape',
  'Spotlit_Stage',
  'Timbered_Retreat',
  'Urban_Rooftop'
])

export const dropDownLabels = Object.freeze({
  footLockMode: 'Foot Locking',
  fallbackPose: 'Fallback Pose',
  SelectBkgdOption: 'Select Background',
  cameraMotion: 'Camera Mode',
  motionSmoothing: 'Motion Smoothing',
  eyeTrackingSensitivity: 'Eye Tracking Sensitivity',
  videoSpeedMultiplier: 'Speed Multiplier'
})

export const sliderLabels = Object.freeze({
  camHorizontalAngle: 'Camera Angle'
})

export const jobTypes = Object.freeze({
  animation: 0,
  staticPose: 1,
  animationText: "3D Animation",
  staticPoseText: "3D Pose"
})

// default anim job state object
export const defaultGSColor = [0,177,64,1]
export const robloxModelId = "Roblox R15 Block"
export const robloxModelPlatform = "roblox"
export const customModelObj = Object.freeze({
  id: null,
  name: '',
  size: null,
  modelUrl: null,
  thumbUrl: null,
  thumbImg: null
})
export const JOB_SETTINGS_TEMPLATE = Object.freeze(
  {
    backdrop: 'default',
    environment: BackdropEnv[0],
    bgColor: defaultGSColor,
    camMode: cameraMotionSettings[0],
    customModelInfo: JSON.parse(JSON.stringify(customModelObj)),
    includeAudio: true,
    faceDataType: 0,
    handDataType: 0,
    filtering: true,
    footLockMode: footLockMode.auto,
    fallbackPose: fallbackPose.a_pose,
    formats: {  // output animation format options
      'bvh':true,
      'fbx':true,
      'gif':false,
      'mp4':false,
      'jpg':false,
      'png':false,
      'dmpe':false
    },
    greenScreen: false,
    jobId: null,
    jobType: jobTypes.animation,
    keyframeReducer: false,
    physicsSim: false,
    rootJointAtOrigin: 0,
    upperBodyOnly: 0,
    sbs: true,
    shadow: true,
    poseFilteringStrength: 0.0,
    iris_turning_agility: 0.5,
    trackFace: 0,
    trackHand: 0,
    videoSpeedMultiplier: 1.0,
    camHorizontalAngle: 0.0
  }
)

export const textAcceptedClipTypes = "Accepted file types: .mp4, .mov, .avi"
export const textAcceptedImgTypes = "Accepted file types: .jpg, .png, .gif, .bmp"
export const tooltipUpgradeSubscription =
    <div>
      <div className="subtitle is-5 is-relative">To increase your subscription max, please upgrade your subscription.
        {/*<a href="[[UPGRADE_URL]]" target="_blank">here</a>. */}
      </div>
    </div>
export const tooltipVideoShouldCrop = () => {
  return (
    <div>
      <div className="subtitle is-5 is-relative">
        Maximum number of persons tracked in a single video for all plans:
      </div>
      <div className="notification is-info is-light mt-3">
        <div className="columns m-0">
          <div className="column m-0 p-1 has-text-left">
            {accountPlansInfo[0].name}
          </div>
          <div className="column m-0 p-1 has-text-right">
            {featureLocksData.maxTrackingPersons[0].toString() + ' per person'}
          </div>
        </div>
        <div className="columns m-0">
          <div className="column m-0 p-1 has-text-left">
            {accountPlansInfo[1].name}
          </div>
          <div className="column m-0 p-1 has-text-right">
            {featureLocksData.maxTrackingPersons[1].toString() + ' per person'}
          </div>
        </div>
        <div className="columns m-0">
          <div className="column m-0 p-1 has-text-left">
            {accountPlansInfo[2].name}
          </div>
          <div className="column m-0 p-1 has-text-right">
            {featureLocksData.maxTrackingPersons[2].toString() + ' per person'}
          </div>
        </div>
        <div className="columns m-0">
          <div className="column m-0 p-1 has-text-left">
            {accountPlansInfo[3].name}
          </div>
          <div className="column m-0 p-1 has-text-right">
            {featureLocksData.maxTrackingPersons[3].toString() + ' per person'}
          </div>
        </div>
        <div className="columns m-0">
          <div className="column m-0 p-1 has-text-left">
            {accountPlansInfo[4].name}
          </div>
          <div className="column m-0 p-1 has-text-right">
            {featureLocksData.maxTrackingPersons[4].toString() + ' per person'}
          </div>
        </div>
        <div className="columns m-0">
          <div className="column m-0 p-1 has-text-left">
            {accountPlansInfo[5].name}
          </div>
          <div className="column m-0 p-1 has-text-right">
            {featureLocksData.maxTrackingPersons[5].toString() + ' per person'}
          </div>
        </div>
      </div>
    </div>
  );
};

export const tooltipVideoLengthShouldTrim = () => {
    return <div>
        <div className="subtitle is-5 is-relative">Maximum single video durations for all plans:</div>
        <div className="notification is-info is-light mt-3">
          <div className="columns m-0">
            <div className="column m-0 p-1 has-text-left">
              {accountPlansInfo[0].name}
            </div>
            <div className="column m-0 p-1 has-text-right">
              {(featureLocksData.maxDuration[0]).toString() + " seconds"}
            </div>
          </div>
          <div className="columns m-0">
            <div className="column m-0 p-1 has-text-left">
              {accountPlansInfo[1].name}
            </div>
            <div className="column m-0 p-1 has-text-right">
              {(featureLocksData.maxDuration[1]).toString() + " seconds"}
            </div>
          </div>
          <div className="columns m-0">
            <div className="column m-0 p-1 has-text-left">
              {accountPlansInfo[2].name}
            </div>
            <div className="column m-0 p-1 has-text-right">
              {(featureLocksData.maxDuration[2]).toString() + " seconds"}
            </div>
          </div>
          <div className="columns m-0">
            <div className="column m-0 p-1 has-text-left">
              {accountPlansInfo[3].name}
            </div>
            <div className="column m-0 p-1 has-text-right">
              {(featureLocksData.maxDuration[3]/60).toString() + " minutes"}
            </div>
          </div>
          <div className="columns m-0">
            <div className="column m-0 p-1 has-text-left">
              {accountPlansInfo[4].name}
            </div>
            <div className="column m-0 p-1 has-text-right">
              {(featureLocksData.maxDuration[4]/60).toString() + " minutes"}
            </div>
          </div>
          <div className="columns m-0">
            <div className="column m-0 p-1 has-text-left">
              {accountPlansInfo[5].name}
            </div>
            <div className="column m-0 p-1 has-text-right">
              {(featureLocksData.maxDuration[5]/60).toString() + " minutes"}
            </div>
          </div>
        </div>
        <div>Please trim your video length, or upgrade your plan.</div>
      </div>
}

export const tooltipVideoDurationLimits = (upgradeUrl) => {
  return <div>
    <div className="subtitle is-5 is-relative">Maximum single video durations for all plans:</div>
    <div className="notification is-info is-light mt-3">
      <div className="columns m-0">
        <div className="column m-0 p-1 has-text-left">
        {accountPlansInfo[0].name}
        </div>
        <div className="column m-0 p-1 has-text-right">
        {(featureLocksData.maxDuration[0]).toString() + " seconds"}
        </div>
      </div>
      <div className="columns m-0">
        <div className="column m-0 p-1 has-text-left">
        {accountPlansInfo[1].name}
        </div>
        <div className="column m-0 p-1 has-text-right">
        {(featureLocksData.maxDuration[1]).toString() + " seconds"}
        </div>
      </div>
      <div className="columns m-0">
        <div className="column m-0 p-1 has-text-left">
        {accountPlansInfo[2].name}
        </div>
        <div className="column m-0 p-1 has-text-right">
        {(featureLocksData.maxDuration[2]).toString() + " seconds"}
        </div>
      </div>
      <div className="columns m-0">
        <div className="column m-0 p-1 has-text-left">
        {accountPlansInfo[3].name}
        </div>
        <div className="column m-0 p-1 has-text-right">
        {(featureLocksData.maxDuration[3]/60).toString() + " minutes"}
        </div>
      </div>
      <div className="columns m-0">
        <div className="column m-0 p-1 has-text-left">
        {accountPlansInfo[4].name}
        </div>
        <div className="column m-0 p-1 has-text-right">
        {(featureLocksData.maxDuration[4]/60).toString() + " minutes"}
        </div>
      </div>
      <div className="columns m-0">
        <div className="column m-0 p-1 has-text-left">
        {accountPlansInfo[5].name}
        </div>
        <div className="column m-0 p-1 has-text-right">
        {(featureLocksData.maxDuration[5]/60).toString() + " minutes"}
        </div>
      </div>
    </div>
    {/*<div><a href={upgradeUrl} target="_blank" rel="noreferrer" >Upgrade your subscription here</a></div>*/}
  </div>
}

export const tooltipVideoMaxSizeLimits = () => {
  return <div>
    <div className="subtitle is-5 is-relative">Maximum single video upload sizes for all plans:</div>
    <div className="notification is-info is-light mt-3">
      <div className="columns m-0">
        <div className="column m-0 p-1 has-text-left">
        {accountPlansInfo[0].name}
        </div>
        <div className="column m-0 p-1 has-text-right">
        {(featureLocksData.maxSize[0]/1024/1024).toString() + " MB"}
        </div>
      </div>
      <div className="columns m-0">
        <div className="column m-0 p-1 has-text-left">
        {accountPlansInfo[1].name}
        </div>
        <div className="column m-0 p-1 has-text-right">
        {(featureLocksData.maxSize[1]/1024/1024).toString() + " MB"}
        </div>
      </div>
      <div className="columns m-0">
        <div className="column m-0 p-1 has-text-left">
        {accountPlansInfo[2].name}
        </div>
        <div className="column m-0 p-1 has-text-right">
        {(featureLocksData.maxSize[2]/1024/1024).toString() + " MB"}
        </div>
      </div>
      <div className="columns m-0">
        <div className="column m-0 p-1 has-text-left">
        {accountPlansInfo[3].name}
        </div>
        <div className="column m-0 p-1 has-text-right">
        {(featureLocksData.maxSize[3]/1024/1024).toString() + " MB"}
        </div>
      </div>
      <div className="columns m-0">
        <div className="column m-0 p-1 has-text-left">
        {accountPlansInfo[4].name}
        </div>
        <div className="column m-0 p-1 has-text-right">
        {(featureLocksData.maxSize[4]/1024/1024/1024).toFixed(1).toString() + " GB"}
        </div>
      </div>
      <div className="columns m-0">
        <div className="column m-0 p-1 has-text-left">
        {accountPlansInfo[5].name}
        </div>
        <div className="column m-0 p-1 has-text-right">
        {(featureLocksData.maxSize[5]/1024/1024/1024).toFixed(1).toString() + " GB"}
        </div>
      </div>
    </div>
  </div>
}
export const tooltipVideoResolutionLimits = () => {
  return <div>
    <div className="subtitle is-5 is-relative">Maximum input video resolution for all plans:</div>
    <div className="notification is-info is-light mt-3">
      <div className="columns m-0">
        <div className="column m-0 p-1 has-text-left">
        {accountPlansInfo[0].name}
        </div>
        <div className="column m-0 p-1 has-text-right">
        {(featureLocksData.maxResolution[0].w).toString() + " x " + (featureLocksData.maxResolution[0].h).toString()}
        </div>
      </div>
      <div className="columns m-0">
        <div className="column m-0 p-1 has-text-left">
        {accountPlansInfo[1].name}
        </div>
        <div className="column m-0 p-1 has-text-right">
        {(featureLocksData.maxResolution[1].w).toString() + " x " + (featureLocksData.maxResolution[1].h).toString()}
        </div>
      </div>
      <div className="columns m-0">
        <div className="column m-0 p-1 has-text-left">
        {accountPlansInfo[2].name}
        </div>
        <div className="column m-0 p-1 has-text-right">
        {(featureLocksData.maxResolution[2].w).toString() + " x " + (featureLocksData.maxResolution[2].h).toString()}
        </div>
      </div>
      <div className="columns m-0">
        <div className="column m-0 p-1 has-text-left">
        {accountPlansInfo[3].name}
        </div>
        <div className="column m-0 p-1 has-text-right">
        {(featureLocksData.maxResolution[3].w).toString() + " x " + (featureLocksData.maxResolution[3].h).toString()}
        </div>
      </div>
      <div className="columns m-0">
        <div className="column m-0 p-1 has-text-left">
        {accountPlansInfo[4].name}
        </div>
        <div className="column m-0 p-1 has-text-right">
        {(featureLocksData.maxResolution[4].w).toString() + " x " + (featureLocksData.maxResolution[4].h).toString()}
        </div>
      </div>
      <div className="columns m-0">
        <div className="column m-0 p-1 has-text-left">
        {accountPlansInfo[5].name}
        </div>
        <div className="column m-0 p-1 has-text-right">
        {(featureLocksData.maxResolution[5].w).toString() + " x " + (featureLocksData.maxResolution[5].h).toString()}
        </div>
      </div>
    </div>
  </div>
}

export const tooltipVideoFPSLimits = () => {
  return <div>
    <div className="subtitle is-5 is-relative">Maximum Frames Per Second for all plans:</div>
    <div className="notification is-info is-light mt-3">
      <div className="columns m-0">
        <div className="column m-0 p-1 has-text-left">
        {accountPlansInfo[0].name}
        </div>
        <div className="column m-0 p-1 has-text-right">
        {(featureLocksData.maxFps[0]).toString() + " FPS"}
        </div>
      </div>
      <div className="columns m-0">
        <div className="column m-0 p-1 has-text-left">
        {accountPlansInfo[1].name}
        </div>
        <div className="column m-0 p-1 has-text-right">
        {(featureLocksData.maxFps[1]).toString() + " FPS"}
        </div>
      </div>
      <div className="columns m-0">
        <div className="column m-0 p-1 has-text-left">
        {accountPlansInfo[2].name}
        </div>
        <div className="column m-0 p-1 has-text-right">
        {(featureLocksData.maxFps[2]).toString() + " FPS"}
        </div>
      </div>
      <div className="columns m-0">
        <div className="column m-0 p-1 has-text-left">
        {accountPlansInfo[3].name}
        </div>
        <div className="column m-0 p-1 has-text-right">
        {(featureLocksData.maxFps[3]).toString() + " FPS"}
        </div>
      </div>
      <div className="columns m-0">
        <div className="column m-0 p-1 has-text-left">
        {accountPlansInfo[4].name}
        </div>
        <div className="column m-0 p-1 has-text-right">
        {(featureLocksData.maxFps[4]).toString() + " FPS"}
        </div>
      </div>
      <div className="columns m-0">
        <div className="column m-0 p-1 has-text-left">
        {accountPlansInfo[5].name}
        </div>
        <div className="column m-0 p-1 has-text-right">
        {(featureLocksData.maxFps[5]).toString() + " FPS"}
        </div>
      </div>
    </div>
  </div>
}
// list of tips to display during video upload
export const tipList = Object.freeze([
  {
    title: "Model Bind Pose",
    content: "The bind pose must be a T-pose and it must be exported in a T-pose to be used in Animate3D."
  },
  {
    title: "Model Joint Names",
    content: "Names must be unique and without any spaces."
  },
  {
    title: "Physics Filter Usage",
    content: "Your character must be sized to typical human proportions with a height of 1 to 2 meters."
  },
  {
    title: "Model File Requirements",
    content: "Only include the character mesh and rigging information."
  },
  {
    title: "Model Foot Placement",
    content: "The feet of the character must be placed on the origin plane."
  },
  {
    title: "Joint Advice",
    content: "Avoid using scale on the joints; if you need to scale up the whole skeleton apply it at a root node."
  },
  {
    title: "Model Requirements",
    content: "Only models with one (1) root joint are supported."
  },
  {
    title: "Model File Requiements",
    content: "Only one (1) character model must be in the custom character file."
  },
  {
    title: "Camera Placement for Footage",
    content: "The camera should be stationary and parallel to your subject."
  },
  {
    title: "Recording the Subject",
    content: "The entire body or the upper body (head to waist) should be visible and located 2-6 meters (6-20 feet) from the camera."
  },
  {
    title: "Subject Lighting",
    content: "Neutral lighting with high contrast between the subject and the background is recommended."
  },
  {
    title: "Recording the Subject",
    content: "The single subject should not be occluded by any objects in the video clip."
  },
  {
    title: "Before Recording",
    content: "Do not wear loose clothing or clothing that obscures key joints like knees and elbows."
  },
  {
    title: "Face Tracking",
    content: "Face Tracking is supported for full body and upper body tracking modes; for better results upper body is recommended."
  },
  {
    title: "Hand Tracking",
    content: "Hand Tracking is supported for full body and upper body tracking modes; for better results upper body is recommended."
  }
])

export const modifiedAspectRatio = 9 / 16

export const maxPreviewImageWidth = 600

export const deletedModel = {
  id: "deletedModel",
  name: "Custom Model Deleted",
  platform: "custom"
}
