import React, { useContext, useEffect, useRef } from "react";
import { AppStateContext } from "../../AppStateContext";

import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";

export default function DMDayPicker({ footer, setShowDayPick }) {
  const nowadays = new Date();
  const appStateContext = useContext(AppStateContext);

  const dayPickerBox = useRef(null);
  const range = appStateContext.state.currSortKeywordsDay;

  useEffect(() => {
    if (range?.from && range?.to) {
      console.log("day selection", range);
    }
  }, [range]);

  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutside, false);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside, false);
    };
  }, []);

  function handleClickOutside(e) {
    let result = dayPickerBox.current.contains(e.target);
    if (!result) {
      setShowDayPick(false);
    }
  }

  return (
    <div className="DayPicker" ref={dayPickerBox}>
      <DayPicker
        mode="range"
        defaultMonth={nowadays}
        selected={range}
        footer={footer}
        onSelect={(day) => {
          const days = { ...day };
          if (days.to) {
            days.to = new Date(days.to?.getTime() + 24 * 60 * 60 * 1000 - 1);
          }
          appStateContext.dispatch({ currSortKeywordsDay: days });
          if (day.from && day.to) {
            setShowDayPick(false);
          }
        }}
        toDate={nowadays}
      />
    </div>
  );
}
