import React, { useContext, useEffect } from "react";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import { useHistory, useLocation } from "react-router-dom";
import { AppStateContext } from "../../../AppStateContext";
import * as Enums from "../../common/enums";
import AnimVersionPanel from "../../common/AnimVersionPanel";
import LoadingScreen from "../../common/LoadingScreen";
import HelmetPageData from "../../common/HelmetPageData";
import DMDialog from "../../ui/DMDialog";
import DMToolTip from "../../ui/DMToolTip";

import "../../../styles/previewer.scss";

const docTitle = "3D Previewer | DEEPMOTION";
const metaDesc =
  "Preview your generated animations using our online 3d previewer.";

const textCustomModelsUnlocked =
  "Your Animation is now ready for preview and download. Custom characters have also been unlocked for this account, see the <strong>3D Models</strong> link from the side menu.";

/******************************************************************
   Previewer - used to preview selected Task result
 ******************************************************************/
export default function Previewer(props) {
  const history = useHistory();
  const location = useLocation();
  const [previewSource, setPreviewSource] = React.useState(null);
  const [eventListenersAttached, setEventListenersAttached] =
    useStateWithCallbackLazy(false);
  const appStateContext = useContext(AppStateContext);

  // using a query param sent from app.js hook to determine if FTE preview
  const queryParams = new URLSearchParams(props.location.search);
  const isFTE = queryParams.get("fte");

  /////////////////////////////////////////////////////////////////////
  function addLocalEventListeners() {
    if (!eventListenersAttached) {
      window.addEventListener("load", onLoad);
      window.addEventListener("beforeunload", onUnload);

      if (!window.previewerOnMessage) {
        window.previewerOnMessage = onMessage;
        window.addEventListener("message", window.previewerOnMessage);
      }

      setEventListenersAttached(true, () => {
        return;
      });
    }
  }
  /////////////////////////////////////////////////////////////////////
  function removeLocalEventListeners() {
    if (eventListenersAttached) {
      window.removeEventListener("load", onLoad);
      window.removeEventListener("beforeunload", onUnload);

      if (window.previewerOnMessage) {
        window.removeEventListener("message", window.previewerOnMessage);
        window.previewerOnMessage = null;
      }

      setEventListenersAttached(false, () => {
        return;
      });
    }
  }

  /////////////////////////////////////////////////////////////////////
  // React function mount/unmount
  /////////////////////////////////////////////////////////////////////
  function mountComponent() {
    addLocalEventListeners();
    if (
      !appStateContext.state.animJobLinks &&
      (!appStateContext.state.animJobId ||
        appStateContext.state.animJobId === "")
    ) {
      props.openAnimationPreviewer(appStateContext.state.animJobId);
    } else {
      let jobLinks = appStateContext.state.animJobLinks.previewLinks;
      let jsonStr = JSON.stringify(jobLinks);
      jsonStr = jsonStr.replace(
        /\b(?:&response-content-disposition\=)(.+?)(?:\%22")/gm,
        '"'
      );
      window.previewerJsonStr = Buffer.from(
        encodeURIComponent(jsonStr)
      ).toString("base64");
      setPreviewSource(`${process.env.REACT_APP_PREVIEWER_URL}`);
    }
  }
  /////////////////////////////////////////////////////////////////////
  function unmountComponent() {
    removeLocalEventListeners();
    // reset src links and preview data before un-mounting
    setPreviewSource(null);
    if (previewSource === null) {
      appStateContext.dispatch({ displayPreview: false, animJobLinks: null });
    }
  }
  /////////////////////////////////////////////////////////////////////
  // Save current animJobId to state on page/browser refresh
  /////////////////////////////////////////////////////////////////////
  function saveStateToLocalStorage(val, cb) {
    // to maintain state across page refresh we temporarily save all
    // state data to local storage, allow page refresh to happen,
    // then read back from storage and remove once complete
    let dataStr = JSON.stringify(val);
    localStorage.setItem(Enums.localStorageStateId, dataStr);
    if (cb) {
      cb();
    }
  }

  /////////////////////////////////////////////////////////////////////
  // Restores state from browser's local storage if key found
  /////////////////////////////////////////////////////////////////////
  function checkAndRestoreStateIfCached() {
    const localStorageData = localStorage.getItem(Enums.localStorageStateId);
    if (localStorageData !== null) {
      const tmpData = JSON.parse(localStorageData);
      localStorage.removeItem(Enums.localStorageStateId);
      appStateContext.dispatch(tmpData);
    }
  }

  function openDownloadModal() {
    if (appStateContext.state.animJobLinks.downloadLinks.detectionMode === 1) {
      appStateContext.dispatch({
        confirmDialogId: Enums.confirmDialog.customMultiPersonDownload,
      });
    } else {
      if (props.jobContainsGLBDownloadLinks()) {
        appStateContext.dispatch({
          confirmDialogId: Enums.confirmDialog.customDownload,
        });
      } else {
        appStateContext.dispatch({
          confirmDialogId: Enums.confirmDialog.standardDownload,
        });
      }
    }
  }

  /////////////////////////////////////////////////////////////////////
  // handling events from Previewer
  /////////////////////////////////////////////////////////////////////
  // eslint-disable-next-line no-unused-vars
  function executeSpecialRerun(param) {
    props.beginAnimationJob({
      rerun: true,
      specialRerun: true,
      rid: window.previewerRerunId,
      retry: true,
    });
  }

  function onMessage(e) {
    var data = e.data;
    if (data.func) {
      if (data.func === "getPreviewerData") {
        e.source.postMessage(
          { func: "sendConfig", param: window.previewerJsonStr },
          "*"
        );
      } else {
        eval(data.func + '("' + data.param + '")');
      }
    }
  }

  /////////////////////////////////////////////////////////////////////
  // Special handling for page un-load in progress
  /////////////////////////////////////////////////////////////////////
  function onUnload() {
    // only backup state to local storage if user on an authenticated route
    // (ie. includes '/dashboard' in the path)
    saveStateToLocalStorage(
      { animJobId: appStateContext.state.animJobId },
      () => {
        // don't return until saving to local storage complete
        return;
      }
    );
  }

  /////////////////////////////////////////////////////////////////////
  // Special handling for re-loading this component from page refresh
  /////////////////////////////////////////////////////////////////////
  function onLoad() {
    checkAndRestoreStateIfCached();
  }

  // custom react hook that handles regular mounting of this component and
  // also handles browser refresh in conjunction saving to localstorage usage
  useEffect(() => {
    //store the id of currently loaded job in previewer, since appStateContext.state.animJobId doesn't look reliable afterwards.
    window.previewerRerunId = appStateContext.state.animJobId;

    if (
      appStateContext.state.anim3dInitialized &&
      appStateContext.state.accountDataRetrieved
    ) {
      if (
        appStateContext.state.animJobLinks &&
        Object.keys(appStateContext.state.animJobLinks).length !== 0 &&
        appStateContext.state.animJobId &&
        appStateContext.state.animJobId !== ""
      ) {
        mountComponent();
      } else if (
        (appStateContext.state.animJobLinks === null ||
          Object.keys(appStateContext.state.animJobLinks).length === 0) &&
        appStateContext.state.animJobId &&
        appStateContext.state.animJobId !== ""
      ) {
        props.openAnimationPreviewer(appStateContext.state.animJobId);
      }
    }
  }, [
    appStateContext.state.animJobLinks,
    appStateContext.state.anim3dInitialized,
    appStateContext.state.accountDataRetrieved,
    appStateContext.state.animJobId,
  ]);

  // ensure loading bar does not display during preview
  useEffect(() => {
    if (previewSource) {
      props.setLOADING({ ...props.LOADING, ...{ show: false } });
    }
  }, [previewSource]);

  // hook for un-mount
  useEffect(() => () => unmountComponent(), []);

  // special hook that helps handle browser refresh on Preview route
  useEffect(() => {
    if (
      !appStateContext.state.anim3dInitialized ||
      !appStateContext.state.accountDataRetrieved
    ) {
      addLocalEventListeners();
      props.initializeA3DService();
    } else {
      if (props.LOADING.show) {
        props.setLOADING({ ...props.LOADING, ...{ show: false } });
      }
    }
  }, [
    appStateContext.state.anim3dInitialized,
    appStateContext.state.accountDataRetrieved,
  ]);

  const animLabelingLink =
    "https://www.deepmotion.com/article/animation-credit-program";

  const labelingInformation = (
    <div className="subtitle is-5">
      Describe your motion from your video in words to get{" "}
      <span className="has-text-weight-semibold">
        free animation credits through our Animation Credit Program.
      </span>
      <br />
      <br />
      Describe the motion in the video using short but detailed motion
      descriptions covering{" "}
      <span className="has-text-weight-semibold">
        action type, direction, style, trajectory, emotions, and key details for
        thorough animation labeling.
      </span>
      <br />
      <br />
      Earn credits after job is complete, lengthen your response for the
      potential of credit quality bonus.
    </div>
  );

  const labelingProgramInformation = (
    <div className="subtitle is-5">
      DeepMotion’s Free Animation Credit Program provides
      <span className="has-text-weight-semibold mx-1">
        FREE credits when you correct your animation quality in our Rotoscope
        Pose Editor!
      </span>
      The higher the quality of the animation, the more free credits you
      receive.
      <br />
      <br />
      Please note: You can only earn back the amount of credits that were used
      for the animation at the job priority that the animation was created with.
    </div>
  );

  /***************************************************************
   *** build page
   ***************************************************************/
  function buildPreviewerPage() {
    if (
      !appStateContext.state.animJobLinks ||
      !Object.keys(appStateContext.state.animJobLinks).length
    ) {
      return <div />;
    }
    return (
      <div id="anim-fadein" className="column">
        <HelmetPageData docTitle={docTitle} metaDesc={metaDesc} />
        <AnimVersionPanel pageTitle={props.pageTitle} />

        {
          /* show first anim completed dialog if FTE experience */
          isFTE && <ShowFTEDialog />
        }

        {/********************* Unity Previewer ********************/}
        <div className="container">
          <div className="columns is-paddingless">
            <div className="column has-text-centered">
              {/*****************************************/}
              {/*** Main Previewer rendered here...   ***/}
              {/*****************************************/}
              <iframe
                id="Previewer"
                width="1100"
                height="700"
                src={previewSource}
                allowFullScreen
              >
                {/* default message in case browser does not support iFrames */}
                <p>Your browser does not support iframes.</p>
              </iframe>
            </div>
          </div>
        </div>
        {/**********************************************************/}
        <div className="columns has-text-centered m-0 p-1">
          <div className="column m-0 p-0">
            <h2 className="title is-4">
              {appStateContext.state.animJobLinks.downloadLinks.fileName}
            </h2>
            <div className="buttons is-block">
              <button
                className="button download-btn is-normal"
                onClick={() => openDownloadModal()}
              >
                <span>Download</span>
                <span className="icon is-small">
                  <i className="fas fa-file-download"></i>
                </span>
              </button>
              <button
                className="button download-btn is-normal"
                onClick={() =>
                  props.displayReRunModal(appStateContext.state.animJobId)
                }
              >
                <span>Rerun</span>
                <span className="icon is-small">
                  <i className="fas fa-redo-alt"></i>
                </span>
              </button>
            </div>
          </div>
        </div>
        <div className="previewerLabeling p-4 mt-4">
          <div className="title is-4 is-flex">
            <span className="dm-brand-font m-0">
              {appStateContext.state.animCorrectionFreeCredits} Animation
              Correction Credits Earned -{" "}
              {appStateContext.state.animMaxAnimationQualityScore}% Quality
              Score
            </span>
            <DMToolTip
              text={labelingProgramInformation}
              tipId="labeling-Program-information"
              icon="fas fa-info-circle fa-md title is-4 dm-brand-font"
              cursor="cursor-pt"
            />
            <span
              className="LearnMore subtitle is-6 dm-brand-font ml-2 px-1 cursor-pt"
              onClick={() => window.open(animLabelingLink)}
            >
              Learn More
            </span>
          </div>
        </div>

        <div className="previewerLabeling p-4 mt-4">
          <div className="title is-4 is-flex">
            <span className="dm-brand-font m-0">
              {appStateContext.state.animLabelingFreeCredits} Animation Labeling
              Credits Earned - {appStateContext.state.animMaxVideoLabelingScore}
              % Quality Score
            </span>
            <DMToolTip
              text={labelingInformation}
              tipId="labeling-information"
              icon="fas fa-info-circle fa-md title is-4 dm-brand-font"
              cursor="cursor-pt"
            />
            <span
              className="LearnMore subtitle is-6 dm-brand-font ml-2 px-1 cursor-pt"
              onClick={() => window.open(animLabelingLink)}
            >
              Learn More
            </span>
          </div>
          <div className="subtitle is-6 py-4 mb-0">
            {appStateContext.state.animVideoDesc
              ? appStateContext.state.animVideoDesc
              : `It appears there is no motion description detected. To earn credits,
              remember to add a motion description before you submit your
              animation.`}
          </div>
          {appStateContext.state.animVideoDesc &&
            appStateContext.state?.animSourceJobId && (
              <div className="rerunLabel p-2">
                Please Note: Text Labels for &apos;Rerun&apos; animations cannot
                currently be edited.
              </div>
            )}
        </div>
      </div>
    );
  }

  function ShowFTEDialog() {
    const htmlMsgContent = [
      <div key="error-dialog" className="notification is-success is-light">
        <div className="columns">
          <div className="column is-flex is-1 p-1 m-1">
            <span className="icon is-success has-text-success is-large has-justify-content-center">
              <i className="fas fa-unlock-alt fa-2x"></i>
            </span>
          </div>
          <div className="column p-1 m-1">
            <div className="subtitle is-5">
              <div
                dangerouslySetInnerHTML={Enums.createMarkup(
                  textCustomModelsUnlocked
                )}
              />{" "}
            </div>
          </div>
        </div>
      </div>,
    ];
    return (
      <DMDialog
        title={"Congrats, Animation Created!"}
        content={htmlMsgContent}
        msgFormat="html"
        label1={"Awesome"}
        action1={closeCustomModelsUnlockedModal}
      />
    );
  }

  function closeCustomModelsUnlockedModal() {
    // to close reward dialog we need to remove query param from current route
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has("fte")) {
      console.log(`Found query param, attempting to delete...`);
      queryParams.delete("error");

      history.push(Enums.routes.Anim3dPreview);
      // history.replace({
      //   search: queryParams.toString(),
      // })
    } else {
      history.push(Enums.routes.Anim3dPreview);
    }
  }

  /***************************************************************
   *** return() method
   ***************************************************************/
  return (
    <React.Fragment key="previewer-page">
      {props.LOADING.show ? <LoadingScreen /> : buildPreviewerPage()}
    </React.Fragment>
  );
}
