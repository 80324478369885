import React from 'react'
import ReactTooltip from 'react-tooltip'

/*************************************************************************** 
 * React functional component for building a Yes/No slider UI element
 * @prop : label - UI text for the widget
 * @prop : value - controls the state value of the slider
 * @prop : onChangeFunc - called on value switch
 * @prop : tipText - string for the info icon text
 * @prop : tipId - string for the info icon text
 * @prop : isDisabled - if disabled renders in lighter, grayed out color
 * @prop : centerAlign - horizontally aligns elem in center if true
 * @prop : isNew - if true adds branded [NEW] tag to the toggle
 * @prop : noMargins - if true removes side margins
 ***************************************************************************/
const YesNoSlider = (props) => {

  function buildYesNoToggle() {
    let textClass = "has-text-white"
    let iconClass = "dm-white"
    if( props.isDisabled && !props.value ) {
      textClass = "disabled-text no-cursor"
      iconClass = "disabled-text"
    }
    let sideMargin = props.noMargins ? "" : "mr-4"
    let switchClass = ""
    if( props.isDisabled && props.value ) {
      switchClass = `toggle-switch disabled-switch-value-on no-cursor ${sideMargin}`
    }
    else {
      switchClass = (props.isDisabled ? `toggle-switch disabled-switch no-cursor ${sideMargin}` : `toggle-switch ${sideMargin}`)
    }
    return (
      <div className="columns has-text-left">
        <div className={"column" + (props.centerAlign ? " has-text-centered" : "")}>
          <label className="toggle" >
            {
              props.isDisabled 
              ?
              <input className="toggle-checkbox" checked={props.value} disabled type="checkbox" />
              :
              <input className="toggle-checkbox" checked={props.value} onChange={props.onChangeFunc} type="checkbox" />
            }
            <div className={switchClass}></div>

            { 
              /*** do not render title and tooltip if props.label is null or false ***/ 
              props.label
              &&
              <React.Fragment>
                <span className={"toggle-label subtitle " + textClass}>{props.label}</span>
                <span className={"icon " + iconClass}>
                  <i className="fas fa-info-circle"
                    data-for={props.tipId} 
                    data-border={true}
                    data-border-color="black"
                    data-tip
                    data-text-color="#2d4e77"
                    data-background-color="white">
                  </i>
                </span>
              </React.Fragment>
            }
          </label>
          {
            props.isNew &&
            <span className="tag dm-brand-2 has-text-white is-normal ml-2">New</span>
          }
          {
            props.label &&
            <ReactTooltip className="tip-max-w" id={props.tipId} place="right" effect="solid">
              <div className="subtitle">
                { props.isTipHtml ? <div dangerouslySetInnerHTML={{__html: props.tipText}} /> : props.tipText }
              </div>
            </ReactTooltip>            
          }
        </div>
      </div>
    )
  }

  return buildYesNoToggle()
}

export default YesNoSlider