import React, { useState, useEffect } from 'react'
import { Swiper, SwiperSlide} from 'swiper/react'
import SwiperCore, { Navigation, Pagination, EffectCube, EffectFade } from 'swiper'
import * as Enums from '../../../common/enums'
import 'swiper/swiper-bundle.css'
import imgCustom from '../../../../images/animate-3d/character-select/model-custom.jpg'

// import additional swiper modules:
SwiperCore.use([Navigation, Pagination, EffectCube, EffectFade])

let textNotAvailable = "Thumbnail not available"

export default function CharacterSwiper(props) {
  const charactersList = props.charactersList

  // local state hooks
  const [swiperSlides, setSwiperSlides] = useState([])
  const [modelThumbnailRatios, setModelThumbnailRatios] = useState([])

  // we dynamically set the thumbnail image aspect ratio since thumbnails before 
  // Jan 2022 are 16:9 and changed to 1:1 (i.e. 640x640) afterwards
  function setThumbnailRatio(index, img) {
    let ratiosList = modelThumbnailRatios
    ratiosList[index] = img.currentTarget.height < img.currentTarget.width ? "is-16by9" : "is-1by1"
    setModelThumbnailRatios(ratiosList)
  }

  function buildCharacterCard(index, thumbNail, thumb, fName, fDate) {
    let image = (thumbNail instanceof Blob) ? URL.createObjectURL(thumbNail) : thumb ? thumb : imgCustom
    return (
      <div className="card dm-brand-border-lg">
        <div className="card-image">
          <div>
          <figure className={`image ${modelThumbnailRatios[index]}`}>
            <img onLoad={(img) => setThumbnailRatio(index, img) } src={image} alt={`image-${fName}`} />
          </figure>
          </div>
          {
            image === imgCustom
            &&
            <div className="thumbnail-unavail has-text-centered">
              <div className="thumbnail-unavail-content dash-prod-btn subtitle is-4">
                {textNotAvailable}
              </div>
            </div>
          }
        </div>
        <div className="card-content dm-brand">
          <div className="media">
            <div className="media-content">
              <p className="title is-4 has-text-white">{fName}</p>
              <p className="subtitle is-6 mgBot-20 has-text-white">{fDate}</p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  // update character index state hook in parent:
  function updateCharacterSelection(index) {
    props.setCharacterIndex(index)
  }

  // dynamically build the swiper slides using props data passed in
  if( charactersList ) {
    if( modelThumbnailRatios.length !== charactersList.length ) {
      let tempList = []
      for( let i = 0; i < charactersList.length; i++ ) {
        tempList.push("")
      }
      setModelThumbnailRatios(tempList)
    }
  }

  ////////////////////////////////////////////////////////////////////
  // 
  ////////////////////////////////////////////////////////////////////
  useEffect(() => {
      if( modelThumbnailRatios && modelThumbnailRatios.length ) {
      let tempSlidesList = []
      for( let i = 0; i < charactersList.length; i++ ) {
        let content = buildCharacterCard(
          i,
          charactersList[i].thumbImg,
          charactersList[i].thumb,
          charactersList[i].name,
          "Added: " + Enums.dateConverter(charactersList[i].mtime/1000, true)
        )
        tempSlidesList.push(
          <SwiperSlide key={`slide-${i}`} className="swiper-slide-custom-models">
            {content}
          </SwiperSlide>
        )
      }
      setSwiperSlides(tempSlidesList)
    }
  }, [modelThumbnailRatios])

  //----------------------------------------------------------
  // Render the component...
  //----------------------------------------------------------
  return (

    <React.Fragment>
      {
        swiperSlides.length &&
        <Swiper id="anim-fadein" className="swiper-container-custom-models"
          observer={true}
          observeParents={true}
          navigation
          effect="fade"
          loop={true}
          pagination={{ clickable: true }}
          onSwiper={() => updateCharacterSelection(0)}
          onSlideChange={(swiper) => updateCharacterSelection(swiper.realIndex)}
        >
          {swiperSlides}

        </Swiper>
      }
    </React.Fragment>
  )
}