import React from "react";
import * as Enums from "../common/enums";
import DMDialog from "../ui/DMDialog";

const textDialogSupport = "Contact Support";
const textDialogOk = "Ok";

export default function JobFailedDialog({
  jobType,
  description,
  closeModal,
  resetSelectedInputVideo,
}) {
  const htmlMsgContent = [
    <div key="error-dialog" className="notification is-danger is-light">
      <div className="columns">
        <div className="column is-1 p-1 m-1">
          <span className="icon is-danger has-text-danger is-medium">
            <i className="fas fa-exclamation-triangle fa-lg"></i>
          </span>
        </div>
        <div className="column p-1 m-1">
          <div className="subtitle is-5">
            <div dangerouslySetInnerHTML={Enums.createMarkup(description)} />
          </div>
        </div>
      </div>
    </div>,
  ];

  function closeDialogAndOpenEmailClient() {
    window.open(`${process.env.REACT_APP_COMPANY_SITE}/support`, "_blank");
    closeModal(true);
  }

  return (
    <DMDialog
      title={
        jobType ? "Problem Creating 3D Pose" : "Problem Creating Animation"
      }
      content={htmlMsgContent}
      msgFormat="html"
      label1={textDialogSupport}
      action1={() => closeDialogAndOpenEmailClient()}
      label2={textDialogOk}
      action2={() => resetSelectedInputVideo(jobType)}
    />
  );
}
