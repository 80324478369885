import React from 'react'
import paginate from 'jw-paginate'
import CustomModal from './components/CustomModal'
import TableButton from './components/TableButton'
import SearchField from './components/SearchField'
import { uiStates } from './AdminAPIApp'
import {
  listApps,
  listUsers,
  listUserData,
  createApp,
  setBillingStartDate,
  addMinutesPack,
  addFeaturesPack,
  getBillingData } from '../../api/apiRequests'
import LoadingScreen from '../../common/LoadingScreen'
import { CredentialConfirm, PackConfirm, PayDateConfirm, LoadingModal, ErrorModal } from './components/ConfirmationDialogue'
import { Pagination, PaginationRowsPerPageDropdown } from '../../common/Pagination'
import * as Enums from '../../common/enums'
import '../../../styles/admin-tool.css'

export default function ApiUserManagementTab(props) {

  const [activeModal, setActiveModal] = React.useState(Enums.activeModal.none)
  const [tableData, setTableData] = React.useState(null)
  const [tableEntryData, setTableEntryData] = React.useState(null)
  const [oktaId, setOktaId] = React.useState(
    Enums.buildStateObj("", false)
  )
  const [appName, setAppName] = React.useState(
    Enums.buildStateObj("", false)
  )
  const [dateCredentialId, setDateCredentialId] = React.useState(
    Enums.buildStateObj("", false)
  )
  const [newDate, setDate] = React.useState(
    Enums.buildStateObj("", false)
  )
  const [newClientId, setClientId] = React.useState(
    Enums.buildStateObj("", false)
  )
  const [newPack, setNewPack] = React.useState(
    Enums.buildStateObj("", false)
  )
  const [returnedClientData, setReturnedClientData] = React.useState("")
  const [activeClientId, setActiveClientId] = React.useState("")
  const [activeOktaId, setActiveOktaId] = React.useState("")
  const [activeSecret, setActiveSecret] = React.useState("")
  const [billCycle, setBillCycle] = React.useState(
    Enums.buildStateObj(0, false)
  )
  const [billDuration, setBillDuration] = React.useState(
    Enums.buildStateObj(1, false)
  )
  const [storeBillDetails, setStoreBillDetails] = React.useState(true)
  const [billDataLoaded, setBillDataLoaded] = React.useState(false)
  const [billData, setBillData] = React.useState()
  const [requestInProgress, setRequestInProgress] = React.useState(false)
  const [uiState, setUiState] = React.useState(uiStates.mount)

  // general function buttons
  function buildButtonGroup() {
    return(
      <div className="buttons is-centered is-grouped">
        <button
          className="button"
          onClick={() => setActiveModal(Enums.activeModal.createCredential)}
        >
          Create Credential
        </button>
        <button
          className="button"
          onClick={() => setActiveModal(Enums.activeModal.updatePaymentPlan)}
        >
          Update Payment Plan Start Date
        </button>
      </div>
    )
  }

  // input validation
  const validateOktaId = event => {
    let tmpStateObj = Enums.buildStateObj(
      (event.target.value),
      oktaId.isValid
    )
    if (tmpStateObj.value && tmpStateObj.value !== "" && tmpStateObj.value.length >= parseInt(process.env.REACT_APP_OKTA_UID_LENGTH)) {
      tmpStateObj.isValid = true
    } else {
      tmpStateObj.isValid = false
      console.log("OktaID invalid length.")
    }
    // console.log(`tmpStateObj.value = ${tmpStateObj.value}\nOKTA_UID_LENGTH = ${process.env.REACT_APP_OKTA_UID_LENGTH}`)
    setOktaId(tmpStateObj)
  }

  const validateAppName = event => {
    let tmpStateObj = Enums.buildStateObj(
      (event.target.value),
      appName.isValid
    )
    if (tmpStateObj.value && tmpStateObj.value !== "") {
      tmpStateObj.isValid = true
    } else {
      tmpStateObj.isValid = false
      console.log("App name must be submitted.")
    }

    setAppName(tmpStateObj)
  }

  const validateDateCredentialId = event => {
    let tmpStateObj = Enums.buildStateObj(
      (event.target.value),
      dateCredentialId.isValid
    )
    console.log(tmpStateObj.value.length)
    if (tmpStateObj.value && tmpStateObj.value !== "" && tmpStateObj.value.length >= parseInt(process.env.REACT_APP_OKTA_UID_LENGTH, 10)) {
      tmpStateObj.isValid = true
    } else {
      tmpStateObj.isValid = false
      console.log("OktaID invalid length.")
    }

    setDateCredentialId(tmpStateObj)
  }

  const validateClientId = event => {
    let tmpStateObj = Enums.buildStateObj(
      (event.target.value),
      appName.isValid
    )
    if (tmpStateObj.value && tmpStateObj.value !== "") {
      tmpStateObj.isValid = true
    } else {
      tmpStateObj.isValid = false
      console.log("Client ID invalid length")
    }

    setClientId(tmpStateObj)
  }

  const validateDate = event => {
    let tmpStateObj = Enums.buildStateObj(
      (event.target.value),
      newDate.isValid
    )
    let dateInput = event.target.value.toString().trim()
    let dateSplit = dateInput.split('-')
    if (dateSplit.length === 3) {
      if (dateSplit[0].length === 4 && dateSplit[1].length === 2 && dateSplit[2].length === 2) {
        tmpStateObj.isValid = true
        tmpStateObj.value = new Date(parseInt(dateSplit[0]), parseInt(dateSplit[1]) - 1, parseInt(dateSplit[2])).getTime()
      } else {
        tmpStateObj.isValid = false
      }
    } else {
      tmpStateObj.isValid = false
      console.log('Please input a valid date')
      return false
    }

    setDate(tmpStateObj)
  }

  const validatePackId = event => {
    let tmpStateObj = Enums.buildStateObj(
      (event.target.value),
      minutePack.isValid
    )
    let idInput = event.target.value.toString().trim()
    if (idInput.length > 0) {
      tmpStateObj.isValid = true
    } else {
      tmpStateObj.isValid = false
      console.log('Please input a valid pack id')
      return false
    }

    tmpStateObj.value = idInput
    setNewPack(tmpStateObj)
  }

  const validateBillCycle = event => {
    let tmpStateObj = Enums.buildStateObj(
      (event.target.value),
      billCycle.isValid
    )
    if (tmpStateObj.value.toString().includes('.')) {
      let tmpVal = tmpStateObj.value.toString().split('.')[0]
      tmpStateObj.value = parseInt(tmpVal)
    }
    if (tmpStateObj.value <= 0) {
      tmpStateObj.isValid = true
    } else {
      tmpStateObj.isValid = false
      console.log("please input a negative number for the billing cycle")
      return false
    }

    setBillCycle(tmpStateObj)
  }

  const validateBillDuration = event => {
    let tmpStateObj = Enums.buildStateObj(
      (event.target.value),
      billDuration.isValid
    )
    if (tmpStateObj.value.toString().includes('.')) {
      let tmpVal = tmpStateObj.value.toString().split('.')[0]
      tmpStateObj.value = parseInt(tmpVal)
    }
    if (tmpStateObj.value > 0) {
      tmpStateObj.isValid = true
    } else {
      tmpStateObj.isValid = false
      console.log("please input a positive interger 1 or greater")
      return false
    }

    setBillDuration(tmpStateObj)
  }

  const storeGetBillDetails = event => {
    setStoreBillDetails(event.target.value)
  }

  // search function
  function performSearch(searchTerm) {
    const firstPage = 1

    if (searchTerm === "") {
      props.setTable(props.unfilteredTable)
      props.setNumPages(Math.ceil(props.unfilteredTable.length / props.numRows))
      return
    }

    let filteredData = []
    props.unfilteredTable.forEach(function(item) {
      if (item.oktaId.includes(searchTerm)) {
        filteredData.push(item)
      } else if (item.clientId.includes(searchTerm)) {
        filteredData.push(item)
      } else if (item.appName.includes(searchTerm)) {
        filteredData.push(item)
      }
    })
    props.setNumPages(Math.ceil(filteredData.length / props.numRows))
    props.setTable(filteredData)
    props.setCurrPage(firstPage)
  }

  function setActiveUserDataModal(cid, oid, secret, modal) {
    setActiveClientId(cid)
    setActiveOktaId(oid)
    setActiveSecret(secret)
    setActiveModal(modal)
  }

  function refreshData() {
    props.setTable(null)
    setTableEntryData(null)
    props.setLoadState(uiStates.mount)
    setUiState(uiStates.mount)
    performSearch("")
  }

  function submitNewCredential() {
    if (oktaId.isValid && appName.isValid) {
      setActiveModal(Enums.activeModal.loadingModal)
      createApp(oktaId.value, appName.value)
      .then((res) => {
        setActiveModal(Enums.activeModal.credConfirmation)
        setReturnedClientData(res.data)
        // setting to mount will refresh API list data
        refreshData()
      }).catch((error) => {
        props.processError(error)
        setActiveModal(Enums.activeModal.failureModal)
      })
    }
  }

  function submitNewDate() {
    if (dateCredentialId.isValid && newClientId.isValid && newDate.isValid) {
      setActiveModal(Enums.activeModal.loadingModal)
      setBillingStartDate(newClientId.value, dateCredentialId.value, newDate.value)
      .then((res) => {
        setActiveModal(Enums.activeModal.dateConfirmation)
        setTableDataLoaded(false)
      }).catch((error) => {
        console.log("Failed to set date.")
        props.processError(error)
        setActiveModal(Enums.activeModal.failureModal)
      })
    }
  }

  function submitAddMinutes() {
    if(!newPack.isValid) {
      return false
    }
    setActiveModal(Enums.activeModal.loadingModal)
    addMinutesPack(activeClientId, activeOktaId, newPack.value)
    .then((res) => {
      setActiveModal(Enums.activeModal.packConfirmation)
      props.setDataLoaded(false)
      props.setTable(null)
    }).catch((error) => {
      console.log("Failed to add new pack.")
      props.processError(error)
      setActiveModal(Enums.activeModal.failureModal)
    })
  }

  function submitSetFeaturePack() {
    if(!newPack.isValid) {
      return false
    }
    setActiveModal(Enums.activeModal.loadingModal)
    const authToken = btoa(`${process.env.REACT_APP_ADMIN_CID}:${process.env.REACT_APP_ADMIN_SECRET}`)
    let urlPath = process.env.REACT_APP_ADMIN_APP_DEV_URL
    if (props.env === "production") {
      urlPath = process.env.REACT_APP_ADMIN_APP_LIVE_URL
    }
    addFeaturesPack(activeClientId, activeOktaId, newPack.value)
    .then((res) => {
      setActiveModal(Enums.activeModal.none)
      props.setDataLoaded(false)
      props.setTable(null)
    }).catch((error) => {
      console.log("Failed to assign feature pack.")
      props.processError(error)
      setActiveModal(Enums.activeModal.failureModal)
    })
  }

  function submitGetBillData() {
    if (billCycle.isValid && billDuration.isValid) {
      setActiveModal(Enums.activeModal.loadingModal)
      getBillingData(activeClientId, billCycle.value, billDuration.value, storeBillDetails)
      .then((res) => {
        setBillData(res.data)
        setBillDataLoaded(true)
        setActiveModal(Enums.activeModal.viewBillData)
      }).catch((error) => {
        props.processError(error)
        setActiveModal(Enums.activeModal.failureModal)
        setBillDataLoaded(false)
        return false
      })
    }
  }

  function buildCredentialModal() {
    return(
      <section className="modal-card-body m-0">
        {/*input field for oktaID*/}
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">OktaID</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded">
                <input className="input" onBlur={validateOktaId} type="text" placeholder="Okta ID"></input>
              </p>
            </div>
          </div>
        </div>
        {/*input field for credential app name*/}
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">App Name</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded">
                <input className="input" onBlur={validateAppName} type="text" placeholder="appName"></input>
              </p>
            </div>
          </div>
        </div>
      </section>
    )
  }

  function buildPaymentDateModal() {
    var today = new Date()
    var placeholderDate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
    return(
      <section className="modal-card-body m-0">
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">OktaID</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded">
                <input className="input" onBlur={validateDateCredentialId} type="text" placeholder="Okta ID"></input>
              </p>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Client ID</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded">
                <input className="input" onBlur={validateClientId} type="text" placeholder="Client ID"></input>
              </p>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Billing Date</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded">
                <input className="input" onBlur={validateDate} type="text" placeholder={placeholderDate}></input>
              </p>
            </div>
          </div>
        </div>
      </section>
    )
  }

  function buildAddMinutesModal() {
    return(
      <section className="modal-card-body m-0">
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">OktaID</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded">
                <input className="input" type="text" placeholder={activeOktaId} disabled></input>
              </p>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Client ID</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded">
                <input className="input" type="text" placeholder={activeClientId} disabled></input>
              </p>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Pack ID</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded">
                <input className="input" onBlur={validatePackId} type="text" placeholder="Pack ID"></input>
              </p>
            </div>
          </div>
        </div>
      </section>
    )
  }

  function buildSetFeaturePackModal() {
    return(
      <section className="modal-card-body m-0">
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">OktaID</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded">
                <input className="input" type="text" placeholder={activeOktaId} disabled></input>
              </p>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Client ID</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded">
                <input className="input" type="text" placeholder={activeClientId} disabled></input>
              </p>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Pack ID</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded">
                <input className="input" onBlur={validatePackId} type="text" placeholder="Pack ID"></input>
              </p>
            </div>
          </div>
        </div>
      </section>
    )
  }

  function buildRequestBillModal() {
    return(
      <section className="modal-card-body m-0">
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Client ID</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded">
                <input className="input" type="text" placeholder={activeClientId} disabled></input>
              </p>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Billing Cycle</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded">
                <input className="input" onBlur={validateBillCycle} type="number" placeholder="0"></input>
              </p>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Duration in Months</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded">
                <input className="input" onBlur={validateBillDuration} type="number" placeholder="1"></input>
              </p>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Show Job Details</label>
          </div>
          <div className="field-body">
            <div className="field is-narrow">
              <div className="control">
                <div className="select is-fullwidth">
                  <select value={storeBillDetails} onChange={storeGetBillDetails}>
                    <option value="false">No</option>
                    <option value="true">Yes</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }

  function buildShowBillModal() {
    let modalBodyInfo
    if (billDataLoaded) {
      let billStartDate = new Date(billData.periodStart).toISOString().split('T')[0]
      let billEndDate = new Date(billData.periodEnd).toISOString().split('T')[0]
      modalBodyInfo =
        <div className="content">
          <h3 className="title is-3">{`Billing Period from ${billStartDate} to ${billEndDate}`}</h3>
          <ul>
            <li><b>Total size in MB:</b> {billData.totalSizeInMB}</li>
            <li><b>Minutes used:</b> {billData.totalMinutes}</li>
            <li><b>Jobs Attempted:</b> {billData.totalProcessedJobs}</li>
            <ul>
              <li><b>Successful Jobs:</b> {billData.totalSucceededJobs}</li>
              <li><b>Failed Jobs:</b> {billData.totalFailedJobs}</li>
              <li><b>Paid Jobs:</b> {billData.totalPaidJobs}</li>
            </ul>
          </ul>
        </div>
    } else {
      modalBodyInfo = <div className="content"></div>
    }

    return(
      <section className="modal-card-body m-0">
        {modalBodyInfo}
      </section>
    )
  }

  function buildSecretModal() {
    return(
      <section className="modal-card-body m-0">
        <div className="block">
          <div className="content">
            <ul>
              <li><b>Client ID:</b> {activeClientId}</li>
              <li><b>Secret:</b> {activeSecret}</li>
            </ul>
          </div>
        </div>
      </section>
    )
  }

  function getApiAppsData() {
    return new Promise((resolve, reject) => {
      listApps()
      .then( res => {
        resolve(res.data)
      }).catch( error => {
        console.log("Failed to retrieve Api app data.")
        reject(error)
      })
    })
  }

  function getApiAppsDataExtra(data) {
    return new Promise((resolve, reject) => {
      listUserData(data)
      .then( res => {
        resolve(res.data)
      }).catch( error => {
        console.log("Failed to retrieve extra data on api apps data.")
        reject(error)
      })
    })
  }

  function getUserData(tData) {
    return new Promise((resolve, reject) => {
      listUsers(tData)
      .then( res => {
        resolve(res.data)
      }).catch( error => {
        console.error(`Failed to list user data:\n${error}`)
        reject(error)
      })
    })
  }

  function buildFullTableData() {
    let userList = []
    for (let i=0; i<tableData.length; i++) {
      let psd
      let bal
      let permMin
      if (tableEntryData.length === tableData.length) {
        if (tableEntryData[i] !== "no data" && tableEntryData[i] !== "error" && tableEntryData[i]) {
          psd = new Date(parseInt(tableEntryData[i].user.paid_plan_activate_date)).toISOString().split('T')[0]
          bal = tableEntryData[i].user.minute_balance % 1 === 0 ? tableEntryData[i].user.minute_balance.toString() : tableEntryData[i].user.minute_balance.toFixed(2).toString()
          permMin = tableEntryData[i].user.minute_balance_permanent.toString()
        } else {
          psd = "--"
          bal = "--"
          permMin = "--"
        }

        userList.push({
          appName: tableData[i].app_name,
          oktaId: tableData[i].userid,
          clientId: tableData[i].clientid,
          secret: tableData[i].secret,
          payStartDate: psd,
          balance: bal,
          permanentMinutes: permMin
        })
      }
    }

    props.setNumPages(Math.ceil(userList.length / props.numRows))
    props.setTable(userList)
    props.setUnfilteredTable(userList)
    props.setDataLoaded(true)
    props.setLoadState(uiStates.ready)
    setUiState(uiStates.ready)

    return userList
  }

  function buildApiUserTableRows() {
    if (!props.table) {
      return
    }
    let userList = props.table
    let usersOnPage = []
    let index = 0
    
    for (let i = 0; i < props.numRows; i++) {
      index = i + ((props.currPage-1)*props.numRows)
      if (index > props.table.length -1) {
        break
      }

      let apiUser = userList [ index ]
      const appName = apiUser.appName
      const oktaId = apiUser.oktaId
      const clientId = apiUser.clientId
      const payStartDate = apiUser.payStartDate
      const balance = apiUser.balance
      const permanentMinutes = Enums.secondsToHms(apiUser.permanentMinutes * 60, false)

      // populate page
      usersOnPage.push(
        <tr key={clientId.toString()}>
          <td>{appName}</td>
          <td>{oktaId}</td>
          <td>{clientId}</td>
          <td>{payStartDate}</td>
          <td className="numerical-data">{balance}</td>
          <td className="numerical-data">{permanentMinutes}</td>
          <td>
            {/* Build actions drop down for each api user row: */}
            {buildApiUserActionsDropDown(apiUser)}
          </td>
        </tr>
      )
    }
      
    return(
      <tbody>
        {usersOnPage}
      </tbody>
    )
  }

  function buildApiUserActionsDropDown(apiUser) {
    if( !apiUser || !apiUser.clientId ) {
      return <div />
    }
    return (
      <div className="dropdown is-hoverable is-right">
        <div className="dropdown-trigger">
          <button className="button preview-btn" aria-haspopup="true" aria-controls="dropdown-menu">
            <span className="icon is-small"><i className="fas fa-ellipsis-v fa-lg"></i></span>
          </button>
        </div>
        <div className="dropdown-menu" id="dropdown-menu" role="menu">
          <div className="dropdown-content">
            <a href="#" onClick={()=>setActiveUserDataModal(apiUser.clientId, apiUser.oktaId, apiUser.secret, Enums.activeModal.addMinutePack)} className="dropdown-item">
              Add Minute Pack
            </a>
            <a onClick={()=>setActiveUserDataModal(apiUser.clientId, apiUser.oktaId, apiUser.secret, Enums.activeModal.setFeaturePack)} className="dropdown-item" >
              Set Feature Pack
            </a>
            <a onClick={()=>setActiveUserDataModal(apiUser.clientId, apiUser.oktaId, apiUser.secret, Enums.activeModal.requestBillData)} className="dropdown-item" >
              Generate Bill
            </a>
            <a onClick={()=>setActiveUserDataModal(apiUser.clientId, apiUser.oktaId, apiUser.secret, Enums.activeModal.viewApiUserSecret)} className="dropdown-item">
              View Client Secret
            </a>
          </div>
        </div>
      </div>
    )
  }

  function buildApiUserTable() {
    if (!props.isDataLoaded) {
      return
    }

    if (!props.table) {
      return
    }

    // calculate page range to display
    let lowRange = ((props.currPage-1)*props.numRows) + 1
    let highRange = ((props.currPage-1)*props.numRows) + props.numRows
    if (highRange > props.table.length) {
      highRange = props.table.length
    }

    return(
      <div className="section">
        <div className="table-container scroll-x" style={{paddingBottom:props.tableBottomPadding}}>
          <table className="table is-striped is-hoverable">
            <thead>
              <tr>
                <th title="appName">App Name</th>
                <th title="oktaID">Okta ID</th>
                <th title="clientAppID">Client Application ID</th>
                <th title="payStartDate">Next Billing Date</th>
                <th title="balance">Remaining Minutes</th>
                <th title="minuteCap">Permanent Minutes</th>
                <th title="actions">Actions</th>
              </tr>
            </thead>
            {/*function for building table rows from data*/}
            {buildApiUserTableRows()}
          </table>
        </div>
        <div className="columns is-mobile">
          {/* pagination pages and prev/next buttons */}
          <Pagination {...props}
            totalPages={props.numPages}
            setPageFunction={(page) => props.setCurrPage(page)}
            currTablePage={props.currPage}
            tableDataLength={props.table.length}
            rowsToDisplay={props.numRows}
          />

          {/* dropdown to select rows per page */}
          <div className="column has-text-right">
          <h5 className="subtitle is-5">Show rows: 
              <PaginationRowsPerPageDropdown {...props}
                rowsToDisplay={props.numRows}
                setRowsToDisplay={(row)=>props.setNumRows(row)}
                setNumPages={(pages)=>props.setNumPages(pages)}
                tableSize={props.table.length}
                setCurrPage={(page)=>props.setCurrPage(page)}
                currPage={props.currPage}
              />
            </h5>
          </div>

          {/* show user range of current page */}
          <div className="column has-text-left">
            <h5 className="subtitle is-5">Showing {lowRange} to {highRange} / {props.table.length}</h5>
          </div>
        </div>
      </div>
      
    )
  }

  function displayModal() {
    switch(activeModal) {
 
      case Enums.activeModal.none:
        return false

      case Enums.activeModal.createCredential:
        return(
          <CustomModal {...props}
            title="Create Credential"
            func={() => submitNewCredential()}
            modalBody={() => buildCredentialModal()}
            closeModal={() => setActiveModal(Enums.activeModal.none)}
          />
        )
      case Enums.activeModal.updatePaymentPlan:
        return(
          <CustomModal {...props}
            title="Update Payment Plan"
            func={() => submitNewDate()}
            modalBody={() => buildPaymentDateModal()}
            closeModal={() => setActiveModal(Enums.activeModal.none)}
          />
        )
      case Enums.activeModal.addMinutePack:
        return(
          <CustomModal {...props}
            title="Add Minute Pack"
            func={() => submitAddMinutes()}
            modalBody={() => buildAddMinutesModal()}
            closeModal={() => setActiveModal(Enums.activeModal.none)}
          />
        )
      case Enums.activeModal.setFeaturePack:
        return(
          <CustomModal {...props}
            title="Set Feature Pack"
            func={() => submitSetFeaturePack()}
            modalBody={() => buildSetFeaturePackModal()}
            closeModal={() => setActiveModal(Enums.activeModal.none)}
          />
        )
      case Enums.activeModal.requestBillData:
        return(
          <CustomModal {...props}
            title="Request Bill Data for User"
            func={() => submitGetBillData()}
            modalBody={() => buildRequestBillModal()}
            closeModal={() => setActiveModal(Enums.activeModal.viewBillData)}
          />
        )

      case Enums.activeModal.viewBillData:
        return(
          <CustomModal {...props}
            title="Request Bill Data for User"
            modalBody={() => buildShowBillModal()}
            closeModal={() => setActiveModal(Enums.activeModal.none)}
          />
        )

      case Enums.activeModal.viewApiUserSecret:
        return(
          <CustomModal {...props}
            title="User Client ID and Secret"
            modalBody={() => buildSecretModal()}
            closeModal={() => setActiveModal(Enums.activeModal.none)}
          />
        )

      case Enums.activeModal.packConfirmation:
        return(
          <CustomModal {...props}
            title="Pack Submitted"
            func="OK"
            modalBody={() => PackConfirm(newPack.value, activeOktaId)}
            closeModal={() => setActiveModal(Enums.activeModal.none)}
          />
        )

      case Enums.activeModal.dateConfirmation:
        return(
          <CustomModal {...props}
            title="New Date Submitted"
            func="OK"
            modalBody={() => PayDateConfirm(newDate.value, activeOktaId)}
            closeModal={() => setActiveModal(Enums.activeModal.none)}
          />
        )

      case Enums.activeModal.credConfirmation:
        return(
          <CustomModal {...props}
            title="New App Created"
            func="OK"
            modalBody={() => CredentialConfirm(oktaId.value, returnedClientData.clientid, returnedClientData.secret, appName.value)}
            closeModal={() => setActiveModal(Enums.activeModal.none)}
          />
        )

      case Enums.activeModal.loadingModal:
        return(
          <CustomModal {...props}
            title="Loading..."
            modalBody={() => LoadingModal()}
            closeModal={() => setActiveModal(Enums.activeModal.none)}
          />
        )

      case Enums.activeModal.failureModal:
        return(
          <CustomModal {...props}
            title="Error"
            modalBody={() => ErrorModal(props.errCode)}
            closeModal={() => setActiveModal(Enums.activeModal.none)}
          />
        )

      default:
        return
    }
  }

  React.useEffect(() => {
    if( props.loadState === uiStates.mount && uiState === uiStates.mount) {
      props.setLoadState(uiStates.apiInProgress)
      setUiState(uiStates.apiInProgress)
      getApiAppsData()
      .then( data => {
        setTableData(data)
        return getApiAppsDataExtra(data)
      })
      .then( data => {
        setTableEntryData(data)
      })
      .catch( error => {
        console.error(`Error during compont mount, API request failed: ${error}`)
        console.error(`${JSON.stringify(error, null, 4)}`)
      })
    }

    if( tableData && tableEntryData && 
      props.loadState === uiStates.apiInProgress && 
      uiState === uiStates.apiInProgress ) {
      buildFullTableData()
    }
  }, [
    uiState,
    activeModal,
    tableData,
    tableEntryData,
    oktaId,
    appName,
    dateCredentialId,
    newDate,
    newClientId,
    newPack,
    returnedClientData,
    activeOktaId,
    activeClientId,
    activeSecret,
    billCycle,
    billDuration,
    storeBillDetails,
    billDataLoaded,
    billData
  ])

  return(
    <div className="section">
      { props.loadState === uiStates.apiInProgress
      ? 
        <LoadingScreen />
      :
        <div>
          <div className="columns">
            <div className="column">
              <SearchField {...props}
                query={(term) => performSearch(term)}
                bFilter={true}
              />
            </div>
            <div className="column">
              {buildButtonGroup()}
            </div>            
          </div>
          <div className="columns">
            <div className="column">
              {buildApiUserTable()}
            </div>
          </div>
          {displayModal()}
        </div>
      }
    </div>
  )
} 