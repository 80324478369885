////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
// Backend API requests (using axios)
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
import { OktaAuth } from '@okta/okta-auth-js'
import { oktaAuthConfig } from '../../config'
import axios from 'axios'
import * as Enums from '../common/enums'
import { getToken } from '../common/hooks'

const oktaAuth = new OktaAuth(oktaAuthConfig)
const useCredentials = ( process.env.REACT_APP_NODE_ENV !== 'development' )
let abortController = new AbortController();
// axios interceptor to catch potential CORS/networking failures (since those)
// do not return any http response code
axios.interceptors.response.use((response) => response, (error) => {
  if (error.message !== 'canceled' && typeof error.response === 'undefined') {
    //TODO: Show branded dialog instead of native browser alert dialog?
    alert('A network error occurred. '
        + 'This could be a dropped internet connection or a potential CORS issue.')
  }
  return Promise.reject(error)
})

////////////////////////////////////////////////////////////////////////////////////
// retrieve user's account plan / subscription info
////////////////////////////////////////////////////////////////////////////////////
export const retrieveUserProfileInfo = async () => {
  const data = await oktaAuth.getUser();
  if( !data.email_verified ) {
    throw 'email address has not been verified!'
  }
  const idToken = await getToken('idToken')
  const accessToken = await getToken('accessToken')
  const reqBody = {idToken: idToken, accessToken: accessToken}
  const resp = await axios.post(`${process.env.REACT_APP_API_URL}/accountPlanInfo`, reqBody, {
    withCredentials: useCredentials
  })
  return resp
}

/////////////////////////////////////////////////////////////////////
// Retrieves a session cookie to be used in subsequent API
// requests to the backend pose estimation service
/////////////////////////////////////////////////////////////////////
export const getNewPEServiceToken = async () => {
  // read access token from browser's local storage...
  const accessToken = await getToken('accessToken')
  if( accessToken ) {
    // Use access token retrieved by okta auth SDK to authenticate against the
    // PE (Pose Estimation) backend service. Upon success a session cookie is
    // created which is used to authenticate subsequent requests to the service
    await axios.get(`${process.env.REACT_APP_PE_URL}/session/okta`, {
      headers: { 'Authorization': "Bearer " + accessToken },
      withCredentials: true
    }).catch( (error) => {
      console.error('Error -> unable to retrieve a new PE service token.')
      throw 'Error -> unable to retrieve a new PE service token : ${error}'
    })
    // success
    return 'ok'
  } else {
    // Token has been removed due to expiration or error while renewing
    console.error(`Access token expired or could not be renewed.`)
    const signout = await oktaAuth.signOut()
    return
  }
}

/////////////////////////////////////////////////////////////////////
// Retrieves all plans data
/////////////////////////////////////////////////////////////////////
export const getAllPlansData = async () => {
  try{
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/listPlans`, {
      withCredentials: useCredentials
    })
    const plansRaw = res.data.data
    let plans = {
      slowMotion: [],
      physicsFilter: [],
      faceTracking: [],
      peSmoothness: [],
      footLocking: [],
      maxDuration: [],
      maxSize: [],
      maxFps: [],
      maxTrackingPersons: [],
      maxResolution: []
    }
    const footLocking = ['auto', 'never', 'always', 'grounding']
    plansRaw.forEach(plan => {
      plans.slowMotion.push(!!plan.speed_multiplier)
      plans.physicsFilter.push(!!plan.physics_filter)
      plans.faceTracking.push(true)
      plans.peSmoothness.push(!!plan.motion_smoothing)
      plans.footLocking.push((fls => {
        let converted = []
        fls.forEach((fl) => {
          converted.push(plan.foot_locking.includes(fl))
        })
        return converted
      })(footLocking))
      plans.maxDuration.push(plan.max_video_length)
      plans.maxSize.push(plan.max_video_file_size * 1024 * 1024)
      plans.maxFps.push(plan.max_video_fps)
      plans.maxTrackingPersons.push(plan.max_tracking_persons)
      plans.maxResolution.push({ w: plan.max_video_width, h: plan.max_video_height })
    })
    return plans
  }catch (err){
    console.error(`Problem getting user plans data -> ${err}`)
    if( err.response?.data ) {
      console.error(`${err.response.data.error} : ${err.response.data.message}`)
    }
    throw err
  }
}

/////////////////////////////////////////////////////////////////////
// Retrieves the account plan data included current mins
/////////////////////////////////////////////////////////////////////
export const getUserPlanData = async (retry) => {
  const res = await axios.get(`${process.env.REACT_APP_PE_URL}/account/getUserData`, {
    withCredentials: true
  }).catch(async (error) => {
    if( error.response && error.response.status === Enums.eCodes.Unauthorized ) {
      if( retry ) {
        console.log(`Warning: PE service token has expired, attempting to renew...`)
        await getNewPEServiceToken()
        return await getUserPlanData(false)
      }
      else {
        throw `Problem getting user plan data -> ${error}`
      }
    }
    else {
      console.error(`Problem getting user plan data -> ${error}`)
      if( error.response && error.response.data ) {
        console.error(`${error.response.data.error} : ${error.response.data.message}`)
      }
      throw error
    }
  })
  return res
}

/////////////////////////////////////////////////////////////////////
// Retrieves list of account jobs for the signed in user
/////////////////////////////////////////////////////////////////////
export const getJobsDataForAccount = async (retry) => {
  const res = await axios.get(`${process.env.REACT_APP_PE_URL}/emojis/SUCCESS`, { withCredentials: true })
      .catch(async (error) => {
        if( error.response && error.response.status === Enums.eCodes.Unauthorized ) {
          if( retry ) {
            console.warn(`Warning: PE service token has expired, attempting to renew...`)
            await getNewPEServiceToken()
            return getJobsDataForAccount(false)
          }
          else {
            throw `Problem retrieving jobs data for account -> ${error}`
          }
        }
        else {
          console.error(`Problem retrieving jobs data for account -> ${error}`)
          if( error.response && error.response.data ) {
            console.error(`${error.response.data.error} : ${error.response.data.message}`)
          }
          throw error
        }
      })
  return res
}

////////////////////////////////////////////////////////////////////
// API request to retrieve custom characters info for the account
////////////////////////////////////////////////////////////////////
export const getAccountCustomCharacters = async (retry) => {
  const res = await axios.get(`${process.env.REACT_APP_PE_URL}/character/listModels`, { params: {stockModel: 'all'}, withCredentials: true })
      .catch(async (error) => {
        if( error.response && error.response.status === Enums.eCodes.Unauthorized ) {
          if( retry ) {
            console.log(`Warning: PE service token has expired, attempting to renew...`)
            await getNewPEServiceToken()
            return await getAccountCustomCharacters(false)
          }
          else {
            throw `Problem retrieving account characters data -> ${error}`
          }
        }
        else {
          console.error(`Problem retrieving account characters data -> ${error}`)
          if( error.response && error.response.data ) {
            console.error(`${error.response.data.error} : ${error.response.data.message}`)
          }
          throw error
        }
      })
  return res
}

////////////////////////////////////////////////////////////////////
// API request to check custom character's compataibility with face tracking and hand tracking
////////////////////////////////////////////////////////////////////
export const checkCustomCharacterFeatureCompatiability = async (modelId, retry) => {
  const res = await axios.get(`${process.env.REACT_APP_PE_URL}/character/checkModelcompatibility/${modelId}`, { withCredentials: true })
      .catch(async (error) => {
        if( error.response && error.response.status === Enums.eCodes.Unauthorized ) {
          if( retry ) {
            console.log(`Warning: PE service token has expired, attempting to renew...`)
            await getNewPEServiceToken()
            return await checkCustomCharacterFeatureCompatiability(modelId, false)
          }
          else {
            console.error(`Problem retrieving account characters data -> ${error}`)
            return null
          }
        }
        else {
          console.error(`Problem retrieving account characters data -> ${error}`)
          if( error.response && error.response.data ) {
            console.error(`${error.response.data.error} : ${error.response.data.message}`)
          }
          return null
        }
      })
  return res
}

////////////////////////////////////////////////////////////////////
// downloads model images (thumbs) from passed in url
////////////////////////////////////////////////////////////////////
export const downloadModelThumbnails = async (data) => {
  const modelsList = data.charactersList.concat(data.platformCharactersList);
  let thumbnail_fetchers = [];
  for( let i = 0; i < modelsList.length; i++ ) {
    const thumbnailUrl = decodeURI(modelsList[i].thumb)
    thumbnail_fetchers.push(
        axios.get(thumbnailUrl, {
          responseType: 'blob' // download PNG image modelsList as blob
        }).then(res => {
          modelsList[i].thumbImg = res.data
        }).catch( (error) => {
          // possible no thumbnail exists for certain cases such as older accounts
          // created before thumbnail feature, hence don't reject the Promise
          console.error(`Error fetching thumbnail ${thumbnailUrl} : ${error}`)
          modelsList[i].thumbImg = null
        })
    )
  }

  await Promise.all(thumbnail_fetchers);
  return {accountTotals: data}
}

////////////////////////////////////////////////////////////////////
// API request to retrieve custom characters info for the account
////////////////////////////////////////////////////////////////////
export const getLinksForJob = async (rid, retry) => {
  let res = await axios.get(`${process.env.REACT_APP_PE_URL}/download/${rid}`, { withCredentials: true})
    .catch(async (error) => {
      if( error.response && error.response.status === Enums.eCodes.Unauthorized ) {
        if( retry ) {
          console.warn(`Warning: PE service token has expired, attempting to renew...`)
          await getNewPEServiceToken()
          return await getLinksForJob(rid, false)
        }
        else {
          console.error(`Could not retrieve download links for job ${rid} -> ${error}`)
          throw error
        }
      }
      else {
        console.error(`Could not retrieve download links for job ${rid} -> ${error}`)
        if( error.response && error.response.data ) {
          console.error(`${error.response.data.error} : ${error.response.data.message}`)
        }
        throw error
      }
    })

  if (res.data == null || res.data.links == null || res.data.links.length == 0) {
    console.warn(`Warning: getLinksForJob returns empty download links.`)
    if ( retry ) {
      console.warn(`PE service token has expired, attempting to renew...`)
      await getNewPEServiceToken()
      res = await getLinksForJob(rid, false)
    }
    else {
      console.error(`Could not retrieve download links for job ${rid} -> ${JSON.stringify(res)}`)
      throw res
    }
  }
  return res
}
export const getMultiPersonJobTrackID = async (url) => {
  let res = await axios.get(url, { withCredentials: false})
  return res
}

////////////////////////////////////////////////////////////////////
// Retrieves a signed URL for model upload
////////////////////////////////////////////////////////////////////
export const getModelUploadUrl = async (fileName, retry) => {
  let reqData = {
    name: fileName,
    modelExt: "fbx" // default
  }
  // set model file extension param for custom glb and gltf uploads
  if( Enums.getFileExtension(fileName).toLowerCase() === Enums.animFileType.glb /* ||
      Enums.getFileExtension(fileName).toLowerCase() === Enums.animFileType.gltf */ ) {
    reqData.modelExt = Enums.getFileExtension(fileName)
  }
  const res = await axios.get(`${process.env.REACT_APP_PE_URL}/character/getModelUploadUrl?name=${reqData.name}&modelExt=${reqData.modelExt}`, {
    headers: {
      "Content-Type": "application/json"
    },
    withCredentials: true
  }).catch(async (error) => {
    if( error.response && error.response.status === Enums.eCodes.Unauthorized ) {
      if( retry ) {
        console.warn(`Warning: PE service token has expired, attempting to renew...`)
        await getNewPEServiceToken()
        return await getModelUploadUrl(fileName, false)
      }
      else {
        console.error(`Problem retrieving model upload url for ${fileName} -> ${error}`)
        throw error
      }
    }
    else {
      console.error(`Could not retrieve download links for job ${rid} -> ${error}`)
      if( error.response && error.response.data ) {
        console.error(`${error.response.data.error} : ${error.response.data.message}`)
      }
      throw error
    }
  })
  return res
}

////////////////////////////////////////////////////////////////////
// Store custom model (character) assets paths into backend DB
////////////////////////////////////////////////////////////////////
export const storeCharacterAssetsInDB = async (modelUrl, thumbUrl, modelName, retry) => {
  // construct the request body for the request
  const requestBody = JSON.stringify({
    modelUrl: modelUrl,
    //TODO: Add ability for user to upload valid thumbnail in future, otherwise
    //by not passing thumbUrl backend will generate a thumbnail image
    // thumbUrl: thumbUrl,
    modelName: modelName
  })
  //+++ Make the POST request to the backend:
  const res = await axios.post(`${process.env.REACT_APP_PE_URL}/character/storeModel`, requestBody, {
    headers: {
      "Content-Type": "application/json"
    },
    withCredentials: true
  })
      .catch(async (error) => {
        if( error.response && error.response.status === Enums.eCodes.Unauthorized ) {
          if( retry ) {
            console.log(`Warning: PE service token has expired, attempting to renew...`)
            await getNewPEServiceToken()
            return storeCharacterAssetsInDB(modelUrl, thumbUrl, modelName, false)
          }
          else {
            console.error(`Problem saving character assets to the cloud -> ${error}`)
            throw error
          }
        }
        else {
          console.error(`Problem saving character assets to the cloud -> ${error}`)
          if( error.response && error.response.data ) {
            console.error(`${error.response.data.error} : ${error.response.data.message}`)
          }
          throw error
        }
      })
  return res
}

////////////////////////////////////////////////////////////////////
// Calls several backend APIs to add newly created custom characters
// generated from ReadyPlayerMe to user's account
//
// @param retry : if we should retry on auth error encountered
// @param rpmCharacterUrl : Not empty -> (Ready Player Me)
// @param file : Uploading the Local model file
// @param modelParams : name and modelExt
// @param inCreateMode : Create mode or not
////////////////////////////////////////////////////////////////////
export const addNewModelToAccount = async (rpmCharacterUrl, modelName, retry, file, modelParams, inCreateMode) => {
  // STEP 1: Retrieve signed URL for model upload
  // GET {host}/character/getModelUploadUrl
  let retry_res = null
  const res = await axios.get(`${process.env.REACT_APP_PE_URL}/character/getModelUploadUrl`, {params: modelParams, withCredentials: true})
      .catch(async (error) => {
        if( error.response && error.response.status === Enums.eCodes.Unauthorized ) {
          if( retry ) {
            console.log(`Warning: PE service token has expired, attempting to renew...`)
            await getNewPEServiceToken()
            retry_res = await addNewModelToAccount(rpmCharacterUrl, modelName, false, file, modelParams, inCreateMode).catch(error => {
              console.error(`addNewModelToAccount failed after retry with error: ${error}`)
              throw error
            })
          }
          else {
            console.error(`Problem saving character assets to the cloud -> ${error}`)
            let err = {
              error: error,
              type: `cloud`
            }
            throw err
          }
        }
        else {
          console.error(`Problem saving character assets to the cloud -> ${error}`)
          if( error.response && error.response.data ) {
            console.error(`${error.response.data.error} : ${error.response.data.message}`)
          }

          let err = {
            error: error,
            type: `cloud`
          }
          throw err
        }
      })

  if (retry_res)
    return retry_res
  // successfull response should contain 'modelUrl' body parameter
  const modelUrl = res.data.modelUrl
  const thumbUrl = res.data.thumbUrl
  delete axios.defaults.headers.post["Content-Type"];
  retry_res = await axios.post(modelUrl, null, { headers: {"x-goog-resumable": "start"} }).catch( (error) => {
    console.error(`Problem retrieving signed model URI for uploading - ${error}`)
    let err = {
      error: error,
      type: `uploading`
    }
    throw err
  })
  // successful response
  const url = retry_res.headers.location
  //===> Step 2: Upload to the backend server after retrieving the url
  if (!rpmCharacterUrl) await uploadCustomModel(url, file, true)
  //===> Step 3: Store character asset paths and data into backend DB
  const CharacterUrl = rpmCharacterUrl ? rpmCharacterUrl : modelUrl
  retry_res = await storeCharacterAssetsInDB(CharacterUrl, thumbUrl, modelName, true ).catch( (error) => {
    console.error(`Problem saving character assets to the backend - ${error}`)
    let err = {
      error: error,
      type: `backend`
    }
    throw err
  })
  if (!inCreateMode) {
    return retry_res
  }
  const customCharacterData = await getAccountCustomCharacters(true)
  return customCharacterData.data
}

////////////////////////////////////////////////////////////////////
// API request to remove a given job from user's account permanently
////////////////////////////////////////////////////////////////////
export const removeCustomModelFromAccount = async (modelId, retry) => {
  const res = await axios.delete(`${process.env.REACT_APP_PE_URL}/character/deleteModel/${modelId}`, {
    withCredentials: true
  }).catch(async (error) => {
    if( error.response && error.response.status === Enums.eCodes.Unauthorized ) {
      if( retry ) {
        console.log(`Warning: PE service token has expired, attempting to renew...`)
        await getNewPEServiceToken()
        return await removeCustomModelFromAccount(modelId, false)
      }
      else {
        console.error(`Problem removing model ${modelId} from account -> ${error}`)
        throw error
      }
    }
    else {
      console.error(`Problem removing model ${modelId} from account -> ${error}`)
      if( error.response && error.response.data ) {
        console.error(`${error.response.data.error} : ${error.response.data.message}`)
      }
      throw error
    }
  })
  return res
}



////////////////////////////////////////////////////////////////////
// Uploads a custom 3d model (provided by the user)
//
// @param url : the signed gcp URL to upload the model to
// @param file : the model asset file
////////////////////////////////////////////////////////////////////
export const uploadCustomModel = async (url, file, retry) => {
  delete axios.defaults.headers.post["Content-Type"]
  const res = await axios.put( url, file, { processData: false, contentType: false })
      .catch(async (error) => {
        if( error.response && error.response.status === Enums.eCodes.Unauthorized ) {
          if( retry ) {
            console.log(`Warning: PE service token has expired, attempting to renew...`)
            await getNewPEServiceToken()
            return await uploadCustomModel(url, file, false)
          }
          else {
            console.error(`Error encountered uploading custom model -> ${error}`)
            throw error
          }
        }
        else {
          console.error(`Error encountered uploading custom model -> ${error}`)
          if( error.response && error.response.data ) {
            console.error(`${error.response.data.error} : ${error.response.data.message}`)
          }
          throw error
        }
      })
  return res
}

////////////////////////////////////////////////////////////////////
// Retrieves a signed upload url for input videos/images
//
// @param fileName : the input video or image file
////////////////////////////////////////////////////////////////////
export const uploadJobDataToBackend = async (fileName, fileData, retry) => {
  let res_retry = null
  let res = await axios.get(`${process.env.REACT_APP_PE_URL}/upload?name=${fileName}`, {withCredentials: true})
      .catch(async (error) => {
        if( error.response && error.response.status === Enums.eCodes.Unauthorized ) {
          if( retry ) {
            console.log(`Warning: PE service token has expired, attempting to renew...`)
            await getNewPEServiceToken()
            res_retry = await uploadJobDataToBackend(fileName, fileData, false)
          }
          else {
            console.error(`Error encountered uploading job input data -> ${error}`)
            throw error
          }
        }
        else {
          console.error(`Error encountered uploading job input data -> ${error}`)
          if( error.response && error.response.data ) {
            console.error(`${error.response.data.error} : ${error.response.data.message}`)
          }
          throw error
        }
      })
  if (res_retry) {
    return res_retry
  }

  const signedUploadUrl = res.data.url
  delete axios.defaults.headers.post["Content-Type"];
  res = await axios.post( signedUploadUrl, null, {
    headers: {
      "x-goog-resumable": "start"
    }
  })
  // then print response status
  const newUrl = res.headers.location
  delete axios.defaults.headers.post["Content-Type"];
  res = await axios.put( newUrl, fileData, {
    processData: false,
    contentType: false,
    signal: abortController.signal
  })
  // success
  let responseData = res
  responseData.videoStorageUrl = signedUploadUrl
  return responseData
}

export const abortRequest = () => {
  abortController.abort()
  abortController = new AbortController();
}

////////////////////////////////////////////////////////////////////
// Retrieves signed urls for peSave files
//
// @param rid : job request id
////////////////////////////////////////////////////////////////////
export const getPeSaveUrls = async (rid, retry) => {
  const res = await axios.get(`${process.env.REACT_APP_PE_URL}/pesave/getUrls/${rid}`, {withCredentials: true})
      .catch(async (error) => {
        if( error.response && error.response.status === Enums.eCodes.Unauthorized ) {
          if( retry ) {
            console.log(`Warning: PE service token has expired, attempting to renew...`)
            await getNewPEServiceToken()
            return await getPeSaveUrls(rid, false)
          }
          else {
            console.error(`Error encountered retrieving peSave Urls -> ${error}`)
            throw error
          }
        }
        else {
          console.error(`Error encountered retrieving peSave Urls -> ${error}`)
          if( error.response && error.response.data ) {
            console.error(`${error.response.data.error} : ${error.response.data.message}`)
          }
          throw error
        }
      })
  return res
}
/////////////////////////////////////////////////////////////////////
// Starts processing a new animation/state job
/////////////////////////////////////////////////////////////////////
export const startNewAnimOrPoseJob = async (requestData, retry) => {
  const res = axios.post(`${process.env.REACT_APP_PE_URL}/process`, JSON.stringify(requestData), {
    headers: {
      "Content-Type": "application/json"
    },
    withCredentials: true
  })
      .catch(async (error) => {
        if( error.response && error.response.status === Enums.eCodes.Unauthorized ) {
          if( retry ) {
            console.log(`Warning: PE service token has expired, attempting to renew...`)
            await getNewPEServiceToken()
            return await startNewAnimOrPoseJob(requestData, false)
          }
          else {
            console.error(`Error encountered trying to start new job -> ${error}`)
            throw error
          }
        }
        else {
          console.error(`Error encountered trying to start new job -> ${error}`)
          if( error.response && error.response.data ) {
            console.error(`${error.response.data.error} : ${error.response.data.message}`)
          }
          throw error
        }
      })
  return res
}

/////////////////////////////////////////////////////////////////////
// Attempts to stop an in progress job
/////////////////////////////////////////////////////////////////////
export const stopInProgressJob = async (animJobId, retry) => {
  const res = await axios.get(`${process.env.REACT_APP_PE_URL}/cancel/${animJobId}`, {
    withCredentials: true
  })
      .catch(async (error) => {
        if( error.response && error.response.status === Enums.eCodes.Unauthorized ) {
          if( retry ) {
            console.log(`Warning: PE service token has expired, attempting to renew...`)
            await getNewPEServiceToken()
            return await stopInProgressJob(animJobId, false)
          }
          else {
            console.error(`Error encountered trying to start new job -> ${error}`)
            throw error
          }
        }
        else {
          console.error(`Error encountered trying to start new job -> ${error}`)
          if( error.response && error.response.data ) {
            console.error(`${error.response.data.error} : ${error.response.data.message}`)
          }
          throw error
        }
      })
  console.log(`stopInProgressJob: cancelled jobID = ${animJobId}`)
  return res
}

////////////////////////////////////////////////////////////////////
// Invoke the /videoInfo API to retrieve video meta data
////////////////////////////////////////////////////////////////////
export const analyzeVideoInputData = async (videoStorageUrl, retry) => {
  const data = {url: videoStorageUrl}
  const res = await axios.post(`${process.env.REACT_APP_PE_URL}/videoInfo`, data, {
    withCredentials: true
  })
      .catch(async (error) => {
        if( error.response && error.response.status === Enums.eCodes.Unauthorized ) {
          if( retry ) {
            console.log(`Warning: PE service token has expired, attempting to renew...`)
            await getNewPEServiceToken()
            return await analyzeVideoInputData(videoStorageUrl, false)
          }
          else {
            console.error(`Error encountered trying to start new job -> ${error}`)
            throw error
          }
        }
        else {
          console.error(`Error encountered trying to start new job -> ${error}`)
          if( error.response && error.response.data ) {
            console.error(`${error.response.data.error} : ${error.response.data.message}`)
          }
          throw error
        }
      })
  return res
}

////////////////////////////////////////////////////////////////////
// API request to remove a given job from user's account permanently
////////////////////////////////////////////////////////////////////
export const removeJobFromAccount = async (rid, retry) => {
  const res = await axios.delete(`${process.env.REACT_APP_PE_URL}/emojis/${rid}`, {withCredentials: true})
      .catch(async (error) => {
        if( error.response && error.response.status === Enums.eCodes.Unauthorized ) {
          if( retry ) {
            console.log(`Warning: PE service token has expired, attempting to renew...`)
            await getNewPEServiceToken()
            return await removeJobFromAccount(rid, false)
          }
          else {
            console.error(`Error encountered trying to delete job ${rid} from account -> ${error}`)
            throw error
          }
        }
        else {
          console.error(`Error encountered trying to delete job ${rid} from account -> ${error}`)
          if( error.response && error.response.data ) {
            console.error(`${error.response.data.error} : ${error.response.data.message}`)
          }
          throw error
        }
      })
  return res
}

/////////////////////////////////////////////////////////////////////
// Checks for jobs that are either currently in progress or queued
/////////////////////////////////////////////////////////////////////
export const checkJobStatus = async (animJobId, retry) => {
  const res = await axios.get(`${process.env.REACT_APP_PE_URL}/status/${animJobId}`, {withCredentials: true})
      .catch(async (error) => {
        if( error.response && error.response.status === Enums.eCodes.Unauthorized ) {
          if( retry ) {
            console.log(`Warning: PE service token has expired, attempting to renew...`)
            await getNewPEServiceToken()
            return await checkJobStatus(animJobId, false)
          }
          else {
            console.error(`Error encountered checking job status for ${animJobId} -> ${error}`)
            throw error
          }
        }
        else {
          console.error(`Error encountered checking job status for ${animJobId} -> ${error}`)
          if( error.response && error.response.data ) {
            console.error(`${error.response.data.error} : ${error.response.data.message}`)
          }
          throw error
        }
      })
  return res
}


/////////////////////////////////////////////////////////////////////
// Checks for jobs that are either currently in progress or queued
/////////////////////////////////////////////////////////////////////
export const checkForInProgressOrQueuedJobs = async (retry) => {
  const res = await axios.get(`${process.env.REACT_APP_PE_URL}/emojis/PROGRESS`, {withCredentials: true})
      .catch(async (error) => {
        if( error.response && error.response.status === Enums.eCodes.Unauthorized ) {
          if( retry ) {
            console.log(`Warning: PE service token has expired, attempting to renew...`)
            await getNewPEServiceToken()
            return await checkForInProgressOrQueuedJobs(false)
          }
          else {
            console.error(`Error encountered checking for in-progress or queued jobs -> ${error}`)
            throw error
          }
        }
        else {
          console.error(`Error encountered checking for in-progress or queued jobs -> ${error}`)
          if( error.response && error.response.data ) {
            console.error(`${error.response.data.error} : ${error.response.data.message}`)
          }
          throw error
        }
      })
  return res
}

////////////////////////////////////////////////////////////////////
// API request to get customers latest subscription info
////////////////////////////////////////////////////////////////////
export const listSubscriptions = async () => {
  try {
    const token = await getToken('idToken')
    const reqBody = {idToken: token}
    const data = await axios.post(`${process.env.REACT_APP_API_URL}/list-subscriptions`, reqBody, {withCredentials: useCredentials})
        .catch( (error) => {
          console.error(`Could not retrieve subscription info -> ${error}`)
          throw(error)
        })
    return data
  }
  catch( error ) {
    console.error(`Problem reading auth token -> ${error}`)
    throw error
  }
}

////////////////////////////////////////////////////////////////////////
// API request to cancel a user's subscription
////////////////////////////////////////////////////////////////////////
export const cancelSubscription = async (subId) => {
  // get id token
  try {
    const reqBody = {subscriptionId: subId}
    const data = await axios.post(`${process.env.REACT_APP_API_URL}/cancel`, reqBody, {withCredentials: useCredentials})
    .catch( (error) => {
      console.error(`Could not cancel subscription -> ${error}`)
      throw(error)
    })
    return data
  }
  catch( error ) {
    console.error(`Problem reading auth token -> ${error}`)
    throw error
  }
}

////////////////////////////////////////////////////////////////////////
// Generates url and redirects user to their respective Customer Portal
////////////////////////////////////////////////////////////////////////
export const redirectToCustomerPortal = async showSubsHistoryOnly => {
  const token = await getToken('idToken')
  const reqBody = {
    idToken: token,
    returnUrl: window.location.origin + window.location.pathname,
    showSubsHistoryOnly
  }
  const res = await axios.post(`${process.env.REACT_APP_API_URL}/generateCustomerPortalUrl`, reqBody, {
    withCredentials: useCredentials
  })
      .catch((error) => {
        console.error(`Error attempting to redirect to customer portal -> ${error}`)
        throw error
      })
  if (res) {
    window.location.href = res.data
  }
}

////////////////////////////////////////////////////////////////////////
// Redirects user to the pricing page with the Customer Portal URL
////////////////////////////////////////////////////////////////////////
export const redirectToPricingPageWithCustomerPortal = async () => {
  window.location.href =  `${process.env.REACT_APP_COMPANY_SITE}/pricing?return_url=${encodeURIComponent(window.location.href)}`
}

////////////////////////////////////////////////////////////////////////////////////
// redirect user to the Pricing page for new purchases made by Freemium users
////////////////////////////////////////////////////////////////////////////////////
export const redirectToPricingPageForNewPurchase = async () => {
  const token = await getToken('idToken')
  window.location.href = process.env.REACT_APP_COMPANY_SITE + '/pricing?return_url=' + encodeURIComponent(window.location.href) + '&id_token=' + token
}

////////////////////////////////////////////////////////////////////////////////////
// get the url to the Pricing page
////////////////////////////////////////////////////////////////////////////////////
export const getPricingPageForNewPurchase = async () => {
  const token = await getToken('idToken')
  return  process.env.REACT_APP_COMPANY_SITE + '/pricing?return_url=' + encodeURIComponent(window.location.href) + '&id_token=' + token
}

////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////
// +++++++++ Contact/Support APIs +++++++++
////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////
// Feedback email confirmation
export const feedbackConfirmation = async () => {
  const token = await getToken('idToken')
  const reqBody = {
    idToken: token
  }
  const res = await axios.post(`${process.env.REACT_APP_API_URL}/feedback`, reqBody, {
    withCredentials: useCredentials
  }).catch((error) => {
    console.error(`Error attempting to send user feedback confirmation email - ${error}`)
    throw error
  })
  return res
}

////////////////////////////////////////////////////////////////////////////////////
// support email confirmation + submit support
////////////////////////////////////////////////////////////////////////////////////
export const supportMail = async (reqBody) => {
  const token = await getToken('idToken')
  reqBody = {...reqBody, idToken: token}
  const res = await axios.put(`${process.env.REACT_APP_API_URL}/support`, reqBody, {
    withCredentials: useCredentials
  }).catch((error) => {
    console.error(error)
    throw error
  })
  return res
}

////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////
// Generic form API.
// In the future, may replace the 2 above functions: feedbackConfirmation and supportMail
////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////
export const submitForm = async ({bizType, bizId, content}) => {
  const token = await getToken('idToken')
  const reqBody = {
    idToken: token,
    bizType, // see: enums.formType
    bizId,
    content
  }
  const res = await axios.post(`${process.env.REACT_APP_API_URL}/form`, reqBody, {
    withCredentials: useCredentials
  }).catch((error) => {
    console.error(`Error attempting to submit form - ${error}`)
    throw error
  })
  return res
}

////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////
// +++++++++ Admin APIs +++++++++
////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////
export const getUserActivty = async () => {
  const token = await getToken('idToken')
  const reqBody = {idToken: token}
  const res = await axios.get(`${process.env.REACT_APP_API_URL}/license/activities`, {params: reqBody}, {
    withCredentials: useCredentials
  }).catch((error) => {
    console.error(`Could not get user DRM logs -> ${error}`)
    throw error
  })
  return res.data
}

////////////////////////////////////////////////////////////////////
export const listApps = async () => {
  try {
    const token = await getToken('idToken')
    const reqBody = {idToken: token}
    const data = await axios.post(`${process.env.REACT_APP_API_URL}/admin/listApps`, reqBody, {withCredentials: useCredentials})
        .catch( (error) => {
          console.error(`Could not list apps -> ${error}`)
          throw error
        })
    return data
  }
  catch( error ) {
    console.error(`Problem reading auth token -> ${error}`)
    throw error
  }
}
////////////////////////////////////////////////////////////////////
export const listUserData = async (data) => {
  try {
    const token = await getToken('idToken')
    const reqBody = {idToken: token, userData: data}
    const resp = await axios.post(`${process.env.REACT_APP_API_URL}/admin/listUserData`, reqBody, {withCredentials: useCredentials})
        .catch( (error) => {
          console.error(`Could not list user data -> ${error}`)
          throw error
        })
    return resp
  }
  catch( error ) {
    console.error(`Problem reading auth token -> ${error}`)
    throw error
  }
}
////////////////////////////////////////////////////////////////////
export const listUsers = async (uid, clientId) => {
  try {
    const token = await getToken('idToken')
    const reqBody = {idToken: token, uid: uid, clientId: clientId}
    const resp = await axios.post(`${process.env.REACT_APP_API_URL}/admin/listUsers`, reqBody, {withCredentials: useCredentials})
        .catch( (error) => {
          console.error(`Could search user -> ${error}`)
          throw error
        })
    return resp
  }
  catch( error ) {
    console.error(`Problem reading auth token -> ${error}`)
    throw error
  }
}
////////////////////////////////////////////////////////////////////
export const createApp = async (oktaId, appName) => {
  try {
    const token = await getToken('idToken')
    const reqBody = {
      idToken: token,
      uid: oktaId,
      appName: appName
    }
    const data = await axios.post(`${process.env.REACT_APP_API_URL}/admin/createApp`, reqBody, {withCredentials: useCredentials})
        .catch( (error) => {
          console.error(`Could not create app -> ${error}`)
          reject(error)
        })
    return data
  }
  catch( error ) {
    console.error(`Problem reading auth token -> ${error}`)
    throw error
  }
}
////////////////////////////////////////////////////////////////////
export const setBillingStartDate = async (cid, uid, startDate) => {
  try {
    const token = await getToken('idToken')
    const reqBody = {
      idToken: token,
      "users": [{
        "clientId": cid,
        "uid": uid,
        "billingStartDate": startDate
      }]
    }
    const data = await axios.post(`${process.env.REACT_APP_API_URL}/admin/setBillingStartDate`, reqBody, {withCredentials: useCredentials})
        .catch( (error) => {
          console.error(`Could not set billing start date -> ${error}`)
          throw error
        })
    return data
  }
  catch( error ) {
    console.error(`Problem reading auth token -> ${error}`)
    throw error
  }
}
////////////////////////////////////////////////////////////////////
export const addMinutesPack = async (cid, uid, packId) => {
  try {
    const token = await getToken('idToken')
    const reqBody = {
      idToken: token,
      clientId: cid,
      uid: uid,
      packId: packId
    }
    const data = await axios.post(`${process.env.REACT_APP_API_URL}/admin/addMinutesPack`, reqBody, {withCredentials: useCredentials})
        .catch( (error) => {
          console.error(`Could not add minutes pack -> ${error}`)
          throw error
        })
    return data
  }
  catch( error ) {
    console.error(`Problem reading auth token -> ${error}`)
    throw error
  }
}
////////////////////////////////////////////////////////////////////
export const addFeaturesPack = async (cid, uid, packId) => {
  try {
    const token = await getToken('idToken')
    const reqBody = {
      idToken: token,
      clientId: cid,
      uid: uid,
      packId: packId
    }
    const data = await axios.post(`${process.env.REACT_APP_API_URL}/admin/addFeaturesPack`, reqBody, {withCredentials: useCredentials})
        .catch( (error) => {
          console.error(`Could not add features pack -> ${error}`)
          throw error
        })
    return data
  }
  catch( error ) {
    console.error(`Problem reading auth token -> ${error}`)
    throw error
  }
}
////////////////////////////////////////////////////////////////////
export const getBillingData = async (cid, cycle, duration, details) => {
  try {
    const token = await getToken('idToken')
    const reqBody = {
      idToken: token,
      clientId: cid,
      cycle: cycle,
      duration: duration,
      details: details
    }
    const data = await axios.post(`${process.env.REACT_APP_API_URL}/admin/getBillingData`, reqBody, {withCredentials: useCredentials})
        .catch( (error) => {
          console.error(`Could not get billing data -> ${error}`)
          throw error
        })
    return data
  }
  catch( error ) {
    console.error(`Problem reading auth token -> ${error}`)
    throw error
  }
}
////////////////////////////////////////////////////////////////////
export const setMinuteBalance = async (cid, uid, mins, addTo) => {
  try {
    const token = await getToken('idToken')
    const reqBody = {
      idToken: token,
      clientId: cid,
      uid: uid,
      minutes: mins,
      addToExisting: addTo
    }
    const data = await axios.post(`${process.env.REACT_APP_API_URL}/admin/setMinuteBalance`, reqBody, {withCredentials: useCredentials})
        .catch( (error) => {
          console.error(`Could not set minutes balance -> ${error}`)
          throw error
        })
    return data
  }
  catch( error ) {
    console.error(`Problem reading auth token -> ${error}`)
    throw error
  }
}
////////////////////////////////////////////////////////////////////
export const createNewFeaturePack = async (
  id,
  name,
  logo,
  maxFps,
  maxWidth,
  maxHeight,
  maxCustom,
  maxRerun,
  maxVidLength,
  physicsFilter,
  speedMultiplier,
  motionSmoothing,
  dmpe,
  footLocking
) => {

  try {
    const token = await getToken('idToken')
    const reqBody = {
      idToken: token,
      id: id,
      name: name,
      logo: logo,
      max_video_fps: maxFps,
      max_video_width: maxWidth,
      max_video_height: maxHeight,
      max_custom_character: maxCustom,
      max_rerun: maxRerun,
      max_video_length: maxVidLength,
      physics_filter: physicsFilter,
      speed_multiplier: speedMultiplier,
      motion_smoothing: motionSmoothing,
      dmpe: dmpe,
      foot_locking: footLocking
    }
    const data = await axios.post(`${process.env.REACT_APP_API_URL}/admin/createFeaturesPack`, reqBody, {withCredentials: useCredentials})
        .catch( (error) => {
          console.error(`Could not create feature pack -> ${error}`)
          throw error
        })
    return data
  }
  catch( error ) {
    console.error(`Problem reading auth token -> ${error}`)
    throw error
  }
}
////////////////////////////////////////////////////////////////////
export const createNewMinutePack = async (id, name, min, duration) => {
  try {
    const token = await getToken('idToken')
    const reqBody = {
      idToken: token,
      id: id,
      name: name,
      minutes: min,
      duration: duration
    }
    const data = await axios.post(`${process.env.REACT_APP_API_URL}/admin/createMinutesPack`, reqBody, {withCredentials: useCredentials})
        .catch((error) => {
          console.error(`Could not create minute pack -> ${error}`)
          throw error
        })
    return data
  }
  catch( error ) {
    console.error(`Problem reading auth token -> ${error}`)
    throw error
  }

}
////////////////////////////////////////////////////////////////////
export const listPacks = async (type) => {
  try {
    const token = await getToken('idToken')
    const reqBody = {
      idToken: token,
      type: type
    }
    const data = await axios.post(`${process.env.REACT_APP_API_URL}/admin/listPacks`, reqBody, {withCredentials: useCredentials})
        .catch( (error) => {
          console.error(`Could not retrieve pack data -> ${error}`)
          throw error
        })
    return data
  }
  catch( error ) {
    console.error(`Problem reading auth token -> ${error}`)
    throw error
  }
}
