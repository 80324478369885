//  - Google Analytics

import { initializeApp } from "firebase/app";
import { getAnalytics,logEvent as logAnalyticsEvent} from "firebase/analytics";
import { OktaAuth } from '@okta/okta-auth-js'
import { oktaAuthConfig } from '../../config'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_GA_APP_ID,
  measurementId: process.env.REACT_APP_GA_MEASUREMENT_ID
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const oktaAuth = new OktaAuth(oktaAuthConfig)
let userId = null

function logFirebaseEvent(evt, param){
  // add uid as event parameter anyway
  if (userId)
    param[analyticsDimention.UID] = userId

  // add app version for every event
  param[analyticsDimention.APP_VERSION] = process.env.REACT_APP_APP_VERSION

  // add GA stream
  param[analyticsDimention.GA_STREAM] = process.env.REACT_APP_GA_STREAM

  if (process.env.REACT_APP_NODE_ENV === 'development')
    console.log({event:evt,params:param})
  else
    logAnalyticsEvent(analytics,evt,param)
}

// Analytics events
export const analyticsEvent = Object.freeze({
  FTE_STEP: 'fte_step'
})

// User/event data attributes/properties/params and event quantitative measurement metrics
export const analyticsDimention = Object.freeze({
  UID: 'uid',
  APP_VERSION: 'app_version',
  GA_STREAM: 'ga_stream',
  FTE_STEP_NUMBER: 'fte_step_number',
})

// exposed for logging events
export const logEvent = (evt, param) => {
  if (!analytics){
    console.log('analytics has not been initialized')
    return;
  }

  if (!userId){
    oktaAuth.getUser()
    .then( async data => {
      userId = data.sub
      analytics.setUserId(userId)
      logFirebaseEvent(evt,param)
    })
    .catch(err => {
      logFirebaseEvent(evt,param)
    })
  }else {
    logFirebaseEvent(evt,param)
  }
}

// exposed for adding user dimentions
export const SetUserDimention = (properties) => {
  if (!analytics){
    console.log('analytics has not been initialized')
    return;
  }
  analytics.setUserProperties(properties)
}

// legacy universal analytics for page view logging
var user="";function getCookie(e){var t=("; "+document.cookie).split("; "+e+"=");if(2==t.length)return t.pop().split(";").shift()}void 0!=getCookie("ln")&&(user=getCookie("ln").replace("%40","@")),function(e,t,a,n,o,i,s){e.GoogleAnalyticsObject=o,e.ga=e.ga||function(){(e.ga.q=e.ga.q||[]).push(arguments)},e.ga.l=1*new Date,i=t.createElement(a),s=t.getElementsByTagName(a)[0],i.async=1,i.src="https://www.google-analytics.com/analytics.js",s.parentNode.insertBefore(i,s)}(window,document,"script",0,"ga"),ga("create","UA-106533167-1",{userId:user}),ga("send","pageview");
