import React, { Component } from 'react'
import axios from 'axios'
import CustomModal from './components/CustomModal'
import { LoadingModal, ErrorModal, GenericSuccess } from './components/ConfirmationDialogue'
import * as Enums from '../../common/enums'

export default function PromotionTab (props) {

  const [activeModal, setActiveModal] = React.useState(Enums.activeModal.none)

  const [promotion, setPromotion] = React.useState([])
  const startFieldValue = React.useRef('')
  const endFieldValue = React.useRef('')


  function submitPromotion() {
    setActiveModal(Enums.activeModal.loadingModal)
    axios.post(`${process.env.REACT_APP_API_URL}/admin/updatePromotion`, {
      start: (new Date(startFieldValue.current.value)).getTime(),
      end: (new Date(endFieldValue.current.value)).getTime()
    }).then((res) => {
      setActiveModal(Enums.activeModal.success)
      startFieldValue.current.value = ''
      endFieldValue.current.value = ''
    }).catch((error) => {
      console.error("Failed to update promotion.")
      props.processError(error)
      setActiveModal(Enums.activeModal.failureModal)
    })
  }

  function getPromotionData(){
    axios.post(`${process.env.REACT_APP_API_URL}/admin/listPromotion`).then((res) => {
      setPromotion(res.data)
    })
  }

  function showPromoData(){
    return (
      <div className='item-container'>
      {promotion.map((promo) => (
        <div key='{promo.id}' className='card'>
          <b>start:</b> {(new Date(promo.start)).toDateString()}<br></br><b>end:</b> {(new Date(promo.end)).toDateString()}
        </div>
      ))}
      </div>
    )
  }

  function buildPromotion() {
    return(
      <div>
        <div className="control is-expanded">
          <span>Start</span>
          <input className="input" type="date" ref={startFieldValue} ></input>
        </div>
        <div className="control is-expanded">
          <span>End</span>
          <input className="input" type="date" ref={endFieldValue}></input>
        </div>
        <br></br>
        <div className="control">
          <a className="button is-link is-selected" onClick={() => submitPromotion()}>Update</a>
        </div>
      </div>
    )
  }

  function displayModal() {
    switch(activeModal) {
      case Enums.activeModal.none:
        return

      case Enums.activeModal.success:
        return(
          <CustomModal {...props}
            title="Success"
            modalBody={() => GenericSuccess('Promotion Data Updated!')}
            closeModal={() => setActiveModal(Enums.activeModal.none)}
          />
        )

      case Enums.activeModal.loadingModal:
        return(
          <CustomModal {...props}
            title="Loading..."
            modalBody={() => LoadingModal()}
            closeModal={() => setActiveModal(Enums.activeModal.none)}
          />
        )

      case Enums.activeModal.failureModal:
        return(
          <CustomModal {...props}
            title="Error"
            modalBody={() => ErrorModal(props.errCode)}
            closeModal={() => setActiveModal(Enums.activeModal.none)}
          />
        )

      default:
        return
    }
  }

  React.useEffect(() => {
    getPromotionData()
  }, [
    activeModal,
    startFieldValue,
    endFieldValue
  ])

  return(
    <div className="section">
      <div className="container">
        <h1 className="title is-4">Current Promotion Dates</h1>
        {showPromoData()}
      </div>
      <br></br>
      <div className="container">
        <h1 className="title is-4">Update Promotion Dates</h1>
        {buildPromotion()}
      </div>
      {displayModal()}
    </div>
  )
}