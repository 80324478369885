import React from 'react'
import {
  listUsers,
  listUserData,
  setBillingStartDate,
  addMinutesPack,
  addFeaturesPack,
  setMinuteBalance} from '../../api/apiRequests'
import { uiStates } from './AdminAPIApp'
import LoadingScreen from '../../common/LoadingScreen'
import CustomModal from './components/CustomModal'
import SearchField from './components/SearchField'
import { PackConfirm, PayDateConfirm, TempMinConfirm, LoadingModal, ErrorModal } from './components/ConfirmationDialogue'
import { Pagination, PaginationRowsPerPageDropdown } from '../../common/Pagination'
import * as Enums from '../../common/enums'
import '../../../styles/admin-tool.css'

export default function PortalUserManagementTab(props) {

  const [uiState, setUiState] = React.useState(uiStates.mount)
  const [pTableData, setPTableData] = React.useState(null)
  const [activeModal, setActiveModal] = React.useState(Enums.activeModal.none)
  const [activeOktaId, setActiveOktaId] = React.useState("")
  const [activeClientId, setActiveClientId] = React.useState("")
  const [newBillDate, setNewBillDate] = React.useState(
    Enums.buildStateObj("", false)
  )
  const [newPackId, setNewPackId] = React.useState(
    Enums.buildStateObj("", false)
  )
  const [tempMintues, setTempMinutes] = React.useState(
    Enums.buildStateObj(0, false)
  )
  const [tempMinutesAdd, setTempMinutesAdd] = React.useState(0)

// input validation
  const validateNewBillDate = event => {
    let tmpStateObj = Enums.buildStateObj(
      (event.target.value),
      newBillDate.isValid
    )
    let dateInput = event.target.value.toString().trim()
    let dateSplit = dateInput.split('-')
    if (dateSplit.length === 3) {
      if (dateSplit[0].length === 4 && dateSplit[1].length === 2 && dateSplit[2].length === 2) {
        tmpStateObj.isValid = true
        tmpStateObj.value = new Date(parseInt(dateSplit[0]), parseInt(dateSplit[1]) - 1, parseInt(dateSplit[2])).getTime()
      } else {
        tmpStateObj.isValid = false
      }
    } else {
      tmpStateObj.isValid = false
      console.log('Please input a valid date')
      return false
    }

    setNewBillDate(tmpStateObj)
  }

  const validatePackId = event => {
    let tmpStateObj = Enums.buildStateObj(
      (event.target.value),
      newPackId.isValid
    )
    let idInput = event.target.value.toString().trim()
    if (idInput.length > 0) {
      tmpStateObj.isValid = true
    } else {
      tmpStateObj.isValid = false
      console.log('Please input a pack id')
      return false
    }

    tmpStateObj.value = idInput
    setNewPackId(tmpStateObj)
  }

  const validateMinuteIncrease = event => {
    let tmpStateObj = Enums.buildStateObj(
      (event.target.value),
      tempMintues.isValid
    )
    if (parseInt(tmpStateObj.value) > 0) {
      tmpStateObj.isValid = true
    } else {
      tmpStateObj.isValid = false
      console.log(`Invalid number ${tmpStateObj.value}`)
      return false
    }

    setTempMinutes(tmpStateObj)
  }

  const storeTempMinutesAdd = event => {
    setTempMinutesAdd(event.target.value)
  }

  // search function
  function performSearch(searchTerm) {
    const firstPage = 1
    if (searchTerm === "") {
      props.setTable(props.unfilteredTable)
      props.setNumPages(Math.ceil(props.unfilteredTable.length / props.numRows))
      return
    }

    let filteredData = []
    props.unfilteredTable.forEach(function(item) {
      // console.log(item)
      if (item.id.includes(searchTerm)) {
        filteredData.push(item)
      } else if (item.clientid.includes(searchTerm)) {
        filteredData.push(item)
      } else if (item.minute_pack) {
        if (item.minute_pack.includes(searchTerm)) {
          filteredData.push(item)
        }
      } else if (item.feature_pack) {
        if (item.feature_pack.includes(searchTerm)) {
          filteredData.push(item)
        }
      }
    })
    
    props.setNumPages(Math.ceil(filteredData.length / props.numRows))
    props.setTable(filteredData)
    props.setCurrPage(firstPage)
  }

  function retrieveUserbyid(uid) {
    getPortalUserDataById(uid)
    .then( data => {
      setPTableData(data)
      props.setLoadState(uiStates.apiInProgress)
      setUiState(uiStates.apiInProgress)
    })
    .catch( error => {
      console.error(`Error during compont mount, API request failed: ${error}`)
      console.error(`${JSON.stringify(error, null, 4)}`)
    })
  }

  function retrieveDetailUserData(userId) {
    props.setLoadState(uiStates.refreshInProgress)
    const filteredTableData = props.unfilteredTable.filter(item => item.id.includes(userId))

    getPortalSubData(filteredTableData)
    .then(data => {
      for ( let i = 0; i < data.length; i++ ) {
        let index = props.user2DataIndexMap.get(data[i].user.id + ":" + data[i].user.feature_pack)
        let packName = 'plan.freemium'
        if (data[i].user.isEnterpriseUser) {
          packName = 'plan.Enterprise'
        } else if (data[i] === 'error') {
          packName = '---'
        } else {
          if (data[i].userPackMinute.length !== 0) {
            if (data[i].userPackMinute[0]) {
              packName = data[i].userPackMinute[0].pack_id
            }
          }
        }
        props.unfilteredTable[index].pack_id = packName

        props.unfilteredTable[index].minute_balance_permanent = data[i].user.minute_balance_permanent
      }
      props.setLoadState(uiStates.ready)
      return
    })
  }

  // modal implimentation 
  function setActiveModalWithIdData(oktaid, clientid, modal) {
    setActiveOktaId(oktaid)
    setActiveClientId(clientid)
    setActiveModal(modal)
  }

  function buildPortalUserUpdateDateModal() {
    var today = new Date()
    var placeholderDate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
    return(
      <section className="modal-card-body">
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">OktaID</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded">
                <input className="input" type="text" placeholder={activeOktaId} disabled></input>
              </p>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Client ID</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded">
                <input className="input" type="text" placeholder={activeClientId} disabled></input>
              </p>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Billing Date</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded">
                <input className="input" onBlur={validateNewBillDate} type="text" placeholder={placeholderDate}></input>
              </p>
            </div>
          </div>
        </div>
      </section>
    )
  }

  function buildAddPackModal() {
    return(
      <section className="modal-card-body">
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">OktaID</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded">
                <input className="input" type="text" placeholder={activeOktaId} disabled></input>
              </p>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Client ID</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded">
                <input className="input" type="text" placeholder={activeClientId} disabled></input>
              </p>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Pack ID</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded">
                <input className="input" onBlur={validatePackId} type="text" placeholder="Pack ID"></input>
              </p>
            </div>
          </div>
        </div>
      </section>
    )
  }

  function buildSetFeaturePackModal() {
    return(
      <section className="modal-card-body">
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">OktaID</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded">
                <input className="input" type="text" placeholder={activeOktaId} disabled></input>
              </p>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Client ID</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded">
                <input className="input" type="text" placeholder={activeClientId} disabled></input>
              </p>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Pack ID</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded">
                <input className="input" onBlur={validatePackId} type="text" placeholder="Pack ID"></input>
              </p>
            </div>
          </div>
        </div>
      </section>
    )
  }

  function buildTempMinuteModal() {
    return(
      <section className="modal-card-body">
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">OktaID</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded">
                <input className="input" type="text" placeholder={activeOktaId} disabled></input>
              </p>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Client ID</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded">
                <input className="input" type="text" placeholder={activeClientId} disabled></input>
              </p>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Number of Minutes to Add</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded">
                <input className="input" onBlur={validateMinuteIncrease} type="number" placeholder="0 minutes"></input>
              </p>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Add to Existing</label>
          </div>
          <div className="field-body">
            <div className="field is-narrow">
              <div className="control">
                <div className="select is-fullwidth">
                  <select value={tempMinutesAdd} onChange={storeTempMinutesAdd}>
                    <option value="0">No</option>
                    <option value="1">Yes</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }

  function submitNewPaymentStartDate() {
    if(newBillDate.isValid) {
      setActiveModal(Enums.activeModal.loadingModal)
      setBillingStartDate(activeClientId, activeOktaId, newBillDate.value)
      .then((res) => {
        setActiveModal(Enums.activeModal.dateConfirmation)
        reloadTable()
      }).catch((error) => {
        props.processError(error)
        setActiveModal(Enums.activeModal.failureModal)
      })
    }
  }

  function submitNewPackToAdd() {
    if(newPackId.isValid) {
      setActiveModal(Enums.activeModal.loadingModal)
      addMinutesPack(activeClientId, activeOktaId, newPackId.value)
      .then((res) => {
        setActiveModal(Enums.activeModal.packConfirmation)
        reloadTable()
      }).catch((error) => {
        props.processError(error)
        setActiveModal(Enums.activeModal.failureModal)
      })
    }
  }

  function submitSetFeaturePack() {
    if(newPackId.isValid) {
      setActiveModal(Enums.activeModal.loadingModal)
      addFeaturesPack(activeClientId, activeOktaId, newPackId.value)
      .then((res) => {
        setActiveModal(Enums.activeModal.packConfirmation)
        reloadTable()
      }).catch((error) => {
        props.processError(error)
        setActiveModal(Enums.activeModal.failureModal)
      })
    }
  }

  function submitTempMinutes() {
    if(tempMintues.isValid) {
      setActiveModal(Enums.activeModal.loadingModal)
      setMinuteBalance(activeClientId, activeOktaId, tempMintues.value, tempMinutesAdd)
      .then((res) => {
        setActiveModal(Enums.activeModal.tempMinConfirmation)
        reloadTable()
      }).catch((error) => {
        props.processError(error)
        setActiveModal(Enums.activeModal.failureModal)
      })
    }
  }

  function reloadTable() {
    props.setDataLoaded(false)
    props.setLoadState(uiStates.mount)
    setUiState(uiStates.mount)
    setPTableData(null)
    performSearch("")
  }

  function getPortalUserData() {
    return new Promise((resolve, reject) => {
      listUsers(null, process.env.REACT_APP_DB_CID)
      .then( res => {
        resolve(res.data)
      }).catch( error => {
        console.log('Failed to get portal user data')
        reject(error)
      })
    })
  }

  function getPortalUserDataById(uid) {
    return new Promise((resolve, reject) => {
      listUsers(uid, process.env.REACT_APP_DB_CID)
      .then( res => {
        resolve(res.data)
      }).catch( error => {
        console.log('Failed to get portal user data')
        reject(error)
      })
    })
  }

  function getPortalSubData(data) {
    let miniData = []
    data.forEach(d => {
      miniData.push({
        clientid: d.clientid,
        userid: d.id
      })
    })
    return new Promise((resolve, reject) => {
      listUserData(miniData)
      .then( res => {
        resolve(res.data)
      }).catch( error => {
        console.log("Failed to retrieve extra data on portal users.")
        reject(error)
      })
    })
  }

  function buildPortalUserTableData() {
    let fullPTableData = []
    props.resetUser2DataIndexMap()
    for ( let i = 0; i < pTableData.length; i++ ) {
      props.updateUser2DataIndexMap(pTableData[i].id + ":" + pTableData[i].feature_pack, i)
      fullPTableData.push({
        id: pTableData[i].id,
        clientid: pTableData[i].clientid,
        plan_expiary_date: pTableData[i].plan_expiary_date,
        minute_balance_permanent: "",
        minute_balance: pTableData[i].minute_balance,
        pack_id: "",
        paid_plan_activate_date: pTableData[i].paid_plan_activate_date
      })
    }
    props.setTable(fullPTableData)
    props.setUnfilteredTable(fullPTableData)
    props.setNumPages(Math.ceil(fullPTableData.length / props.numRows))
    props.setDataLoaded(true)
    props.setLoadState(uiStates.ready)
    setUiState(uiStates.ready)
  }

  function buildPortalUserTable() {

    if (!props.isDataLoaded) {
      return false
    }

    // calculate page range to display
    let lowRange = ((props.currPage-1)*props.numRows) + 1
    let highRange = ((props.currPage-1)*props.numRows) + props.numRows
    if (highRange > props.table.length) {
      highRange = props.table.length
    }

    return(
      <div className="section">
        <div className="table-container" style={{paddingBottom:props.tableBottomPadding}}>
          <table className="table is-striped is-hoverable">
            <thead>
              <tr>
                <th title="oktaID">Okta ID</th>
                <th title="payStartDate">Next Billing Date</th>
                <th title="permMin">Perm Min</th>
                <th title="currBal">Balance</th>
                <th title="currPlan">Current Plan</th>
                <th title="billDate">Plan Activation Date</th>
                <th></th>
              </tr>
            </thead>
            {/*function for building table rows from data*/}
            {buildPortalUserRows()}
          </table>
        </div>
        <div className="columns is-mobile">
          {/* pagination pages and prev/next buttons */}
          <Pagination {...props}
            totalPages={props.numPages}
            setPageFunction={(page) => props.setCurrPage(page)}
            currTablePage={props.currPage}
            tableDataLength={props.table.length}
            rowsToDisplay={props.numRows}
          />

          {/* dropdown to select rows per page */}
          <div className="column has-text-right">
            <h5 className="subtitle is-5">Show rows: 
              <PaginationRowsPerPageDropdown {...props}
                rowsToDisplay={props.numRows}
                setRowsToDisplay={(row)=>props.setNumRows(row)}
                setNumPages={(pages)=>props.setNumPages(pages)}
                tableSize={props.table.length}
                setCurrPage={(page)=>props.setCurrPage(page)}
                currPage={props.currPage}
              />
            </h5>
          </div>

          {/* show user range of current page */}
          <div className="column has-text-left">
            <h5 className="subtitle is-5">Showing {lowRange} to {highRange} / {props.table.length}</h5>
          </div>
        </div>
      </div>
      
    )
  }

  function buildPortalUserRows() {
    if (!props.isDataLoaded) {
      return false
    }

    let portalUsersOnPage = []
    let index = 0
    // build portal list
    for (let i = 0; i < props.numRows; i++) {
      index = i + ((props.currPage-1)*props.numRows)
      if (index > props.table.length - 1) {
        break
      }

      let portalUser = props.table[ index ]
      const id = portalUser.id.toString()
      const clientId = portalUser.clientid.toString()
      const payStartDate = new Date(parseInt(portalUser.plan_expiary_date)).toISOString().split('T')[0]
      const permMin = portalUser.minute_balance_permanent === 0 ? "0" : !portalUser.minute_balance_permanent ? "" : Enums.secondsToHms(portalUser.minute_balance_permanent * 60, false)
      const minBalance = portalUser.minute_balance === 0 ? "0" : Enums.secondsToHms(portalUser.minute_balance * 60, false)
      let fullPackId = portalUser.pack_id.split('.')
      const subName = fullPackId[1]
      const activeDate = new Date(parseInt(portalUser.paid_plan_activate_date)).toISOString().split('T')[0]

      // populate list
      portalUsersOnPage.push(
        <tr key={id+clientId+index}>
          <td>{id}</td>
          <td>{payStartDate}</td>
          <td className="numerical-data">{permMin}</td>
          <td className="numerical-data">{minBalance}</td>
          <td>{subName}</td>
          <td>{activeDate}</td>
          <td>
            {/* Build actions drop down for each portal user row: */}
            {buildPortalUserActionsDropDown(portalUser)}
          </td>
        </tr>
      )
    }

    return(
      <tbody>
        {portalUsersOnPage}
      </tbody>
    )
  }

  function buildPortalUserActionsDropDown(portalUser) {
    if( !portalUser ) {
      return <div />
    }
    return (
      <div className="dropdown is-hoverable is-right">
        <div className="dropdown-trigger">
          <button className="button preview-btn" aria-haspopup="true" aria-controls="dropdown-menu">
            <span className="icon is-small"><i className="fas fa-ellipsis-v fa-lg"></i></span>
          </button>
        </div>
        <div className="dropdown-menu" id="dropdown-menu" role="menu">
          <div className="dropdown-content">
            <a onClick={()=> retrieveDetailUserData(portalUser.id) } className="dropdown-item">
              Retrieve The Details
            </a>
            <a href="#" onClick={()=> setActiveModalWithIdData(portalUser.id, portalUser.clientid, Enums.activeModal.portalPayStartDate) } className="dropdown-item">
              Update Next Billing Date
            </a>
            <a onClick={()=> setActiveModalWithIdData(portalUser.id, portalUser.clientid, Enums.activeModal.portalAddMinutePack) } className="dropdown-item" >
              Add Minute Pack
            </a>
            <a onClick={()=> setActiveModalWithIdData(portalUser.id, portalUser.clientid, Enums.activeModal.portalSetFeaturePack) } className="dropdown-item" >
              Set Feature Pack
            </a>
            <a onClick={()=> setActiveModalWithIdData(portalUser.id, portalUser.clientid, Enums.activeModal.portalTempMinute) } className="dropdown-item" >
              Add Temp Minutes
            </a>
          </div>
        </div>
      </div>
    )
  }

  function displayModal() {
    switch(activeModal) {
 
      case Enums.activeModal.none:
        return false

      case Enums.activeModal.portalPayStartDate:
        return(
          <CustomModal {...props}
            title="Update Payment Date"
            func={() => submitNewPaymentStartDate()}
            modalBody={() => buildPortalUserUpdateDateModal()}
            closeModal={() => setActiveModal(Enums.activeModal.none)}
          />
        )

      case Enums.activeModal.portalAddMinutePack:
        return(
          <CustomModal {...props}
            title="Add Minute Pack"
            func={() => submitNewPackToAdd()}
            modalBody={() => buildAddPackModal()}
            closeModal={() => setActiveModal(Enums.activeModal.none)}
          />
        )

      case Enums.activeModal.portalSetFeaturePack:
        return(
          <CustomModal {...props}
            title="Set Feature Pack"
            func={() => submitSetFeaturePack()}
            modalBody={() => buildSetFeaturePackModal()}
            closeModal={() => setActiveModal(Enums.activeModal.none)}
          />
        )

      case Enums.activeModal.portalTempMinute:
        return(
          <CustomModal {...props}
            title="Add Temporary Minutes"
            func={() => submitTempMinutes()}
            modalBody={() => buildTempMinuteModal()}
            closeModal={() => setActiveModal(Enums.activeModal.none)}
          />
        )

      case Enums.activeModal.packConfirmation:
        return(
          <CustomModal {...props}
            title="Pack Submitted"
            func="OK"
            modalBody={() => PackConfirm(newPackId.value, activeOktaId)}
            closeModal={() => setActiveModal(Enums.activeModal.none)}
          />
        )

      case Enums.activeModal.dateConfirmation:
        return(
          <CustomModal {...props}
            title="Pack Submitted"
            func="OK"
            modalBody={() => PayDateConfirm(newBillDate.value, activeOktaId)}
            closeModal={() => setActiveModal(Enums.activeModal.none)}
          />
        )

      case Enums.activeModal.tempMinConfirmation:
        return(
          <CustomModal {...props}
            title="Pack Submitted"
            func="OK"
            modalBody={() => TempMinConfirm(tempMintues.value, activeOktaId)}
            closeModal={() => setActiveModal(Enums.activeModal.none)}
          />
        )

      case Enums.activeModal.loadingModal:
        return(
          <CustomModal {...props}
            title="Loading..."
            modalBody={() => LoadingModal()}
            closeModal={() => setActiveModal(Enums.activeModal.none)}
          />
        )

      case Enums.activeModal.failureModal:
        return(
          <CustomModal {...props}
            title="Loading..."
            modalBody={() => ErrorModal(props.errCode)}
            closeModal={() => setActiveModal(Enums.activeModal.none)}
          />
        )

      default:
        return
    }
  }

  React.useEffect(() => {
    if( props.loadState === uiStates.mount && uiState === uiStates.mount) {
      // props.setLoadState(uiStates.apiInProgress)
      //setUiState(uiStates.apiInProgress)
      // getPortalUserData()
      // .then( data => {
      //   setPTableData(data)
      // })
      // .catch( error => {
      //   console.error(`Error during compont mount, API request failed: ${error}`)
      //   console.error(`${JSON.stringify(error, null, 4)}`)
      // })
    }

    if( pTableData && 
      props.loadState === uiStates.apiInProgress && 
      uiState === uiStates.apiInProgress ) {
      buildPortalUserTableData()
    }
  }, [
    uiState,
    activeModal,
    activeOktaId,
    activeClientId,
    newBillDate,
    newPackId,
    tempMintues,
    tempMinutesAdd,
    pTableData
  ])

  return(
    <div className="section">
      { props.loadState === uiStates.apiInProgress
      ? 
        <LoadingScreen />
      :
      <div className="container">
        <div className="columns">
          <div className="column">
            <SearchField {...props}
              query={(term) => retrieveUserbyid(term)}
              bFilter={false}
            />
          </div>
        </div>
        
        {buildPortalUserTable()}
        {displayModal()}
      </div>
      }
    </div>
  )
}