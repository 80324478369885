import React from 'react'
import ReactSlider from "react-slider"
import ReactTooltip from 'react-tooltip'

/*************************************************************************** 
 * React functional component for building a Yes/No slider UI element
 * @prop : label - UI text for the widget
 * @prop : value - controls the state value of the slider
 * @prop : onChangeFunc - called on value switch
 * @prop : tipText - string for the info icon text
 * @prop : tipId - string for the info icon text
 * @prop : isDisabled - if disabled renders in lighter, grayed out color
 * @prop : centerAlign - horizontally aligns elem in center if true
 * @prop : isNew - if true adds branded [NEW] tag to the toggle
 * @prop : noMargins - if true removes side margins
 ***************************************************************************/
const DMSlider = (props) => {

  function getMarkPos(value, min, max) {
    const sliderWidth = parseInt(getComputedStyle(document.documentElement).getPropertyValue("--slider-width"), 10);
    const handleWidth = parseInt(getComputedStyle(document.documentElement).getPropertyValue("--slider-thumb-width"), 10);
    const sliderUpperBound = sliderWidth - handleWidth;
    const range = max - min;
    const ratio = (value - min) / range;

    return (ratio * sliderUpperBound).toFixed(2);
  }

  function buildDMSlider() {
    let textClass = "has-text-white"
    if( props.darkMark) {
      textClass = "has-text-weight-semibold"
    }
    let iconClass = "dm-white"
    if( props.isDisabled && !props.value ) {
      textClass = "disabled-text no-cursor"
      iconClass = "disabled-text"
    }
    return (
        <div className="columns">
          <div className="column">
            {
              <ReactSlider
                disabled={props.isDisabled}
                min={props.minValue}
                max={props.maxValue}
                step={props.step}
                className="horizontal-slider"
                thumbClassName="slider-thumb"
                trackClassName="slider-track"
                value={props.value}
                onChange={props.onChange}
              />
            }
            {
              <div className={"toggle-label has-text-left " + textClass}
                style={{
                  top: "-5px",
                  left: getMarkPos(props.value, props.minValue, props.maxValue) + "px",
                  width: "0px"
                }}
              >
                {props.value}
              </div>
            }
            {
              <span className={"toggle-label subtitle " + textClass}
                style={{
                  top: "-30px",
                  padding: "0px 0px 0px 40px",
                  left: (getMarkPos(props.maxValue, props.minValue, props.maxValue)) + "px"
                }}
              >
                {props.label}
                {
                  props.isNew &&
                  <span className="tag dm-brand-2 has-text-white is-normal ml-2">New</span>
                }
                {
                  props.tipText
                  &&
                  <span className={"icon " + iconClass} style={{fontSize: '1rem'}}>
                    <i className="fas fa-info-circle"
                    data-for={props.tipId} 
                    data-border={true}
                    data-border-color="black"
                    data-tip
                    data-text-color="#2d4e77"
                    data-background-color="white">
                    </i>
                  </span>
                }
                {
                  props.tipText
                  &&
                  <ReactTooltip className="tip-max-w" id={props.tipId} place="right" effect="solid">
                    <div className="subtitle">
                      { props.isTipHtml ? <div dangerouslySetInnerHTML={{__html: props.tipText}} /> : props.tipText }
                    </div>
                  </ReactTooltip>            
                }
              </span>
            }
            
          </div>
        </div>
    )
  }

  return buildDMSlider()
}

export default DMSlider