import React from 'react'
import { getUserActivty } from '../api/apiRequests'
import '@fortawesome/fontawesome-free/css/all.min.css'
import { browserHistory } from 'react-router'
import { relative } from 'path'
import 'mdbreact/dist/css/mdb.css'
import { MDBDataTable } from 'mdbreact'
import LoadingScreen from '../common/LoadingScreen'
import InfoDialog from '../common/InfoDialog'
import '../../styles/activities.css'

const pageTitle = 'User Activity Logs'

/*******************************************************************
 * Displays DRM Activity logs for customers in production
 *******************************************************************/
export default function ActivityPage(props) {
  const [userLogs, setUserLogs] = React.useState({})
  const [loadFail, setLoadFail] = React.useState(false)
  const [errorVal, setErrorVal] = React.useState({})

  // call API to get user activity
  function getDRMActivity() {
    return new Promise((resolve,reject) => {
      getUserActivty(props.STATE.groups)
      .then( data => {
        resolve(data)
      }).catch(error => {
        console.log('Failed to get user activity data.')
        reject(error)
        props.setLOADING({...props.LOADING, ...{show: false}})
        setErrorVal(error)
        setLoadFail(true)
      })
    })
  }

  // build table from api response data
  function createActivityTable(info) {
    let logs = []
    info.forEach(item => {
      logs.push({
        email : item.email,
        action : item.message,
        computerName : item.computer_name,
        hardwareId: item.hardwareId,
        ip: item.ip,
        time : item.time
      })
    })
    const data = {
      columns: [
        {
          label: 'Email',
          field: 'email',
          sort: 'asc',
          width: 150
        },
        {
          label: 'Action',
          field: 'action',
          sort: 'asc',
          width: 150
        },
        {
          label: 'ComputerName',
          field: 'computerName',
          sort: 'asc',
          width: 150
        },
        {
          label: 'HardwareId',
          field: 'hardwareId',
          sort: 'asc',
          width: 150
        },
        {
          label: 'IP',
          field: 'ip',
          sort: 'asc',
          width: 150
        },
        {
          label: 'Time',
          field: 'time',
          sort: 'asc',
          width: 150
        }
      ],
      rows: logs
    }
    setUserLogs(data)
    props.setLOADING({...props.LOADING, ...{show: false}})
  }

  React.useEffect(() => {
    props.setLOADING({show: true, msg: 'Loading Activity Logs...'})
    getDRMActivity()
    .then(data => {
      return createActivityTable(data)
    })
  },[])

  React.useEffect(() => () => {
    setUserLogs({})
    setLoadFail(false)
  },[])

  //////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////
  return(
    <React.Fragment>
      {props.LOADING.show
      ?
      <div className="column is-one-third has-text-centered is-vcentered" style={{marginTop:'100px'}}>
      <LoadingScreen />
      </div>
      :
      // display error message instead of letting the loading screen hang
      // if pulling the data fails
      loadFail
      ?
      <div className="column has-text-centered" style={{marginTop:'50px'}}>
        <h1 className="title is-4 dm-brand-font">
          Failed to retrieve user DRM data.
        </h1>
        {errorVal.message}
      </div>
      :
      <div className="page-fade-in">
          <div className="column has-text-centered" style={{marginTop:'50px'}}>
            <div className="columns has-text-centered">
              <div className="column is-three-quarters">
                <h1 className="title is-4 dm-brand-font">{pageTitle}</h1>
              </div>
            </div>
            <div style={{margin: '2%'}}>
              <MDBDataTable striped bordered hover data={userLogs} />
            </div>
          </div>
        </div>  
      }
    </React.Fragment>
  )
}