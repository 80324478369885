import StackdriverErrorReporter from 'stackdriver-errors-js';
import { OktaAuth } from '@okta/okta-auth-js'
import { oktaAuthConfig } from '../../config'

let errorReporter = {}
let userId = null

if (process.env.REACT_APP_NODE_ENV === 'production' || process.env.REACT_APP_NODE_ENV === 'staging') {
    errorReporter.stackdriverErrorReporter = new StackdriverErrorReporter();
    //this will start reporting unhandled exceptions & promise rejection 
    errorReporter.stackdriverErrorReporter.start({
        key: process.env.REACT_APP_ERROR_REPORTING_API_KEY,
        projectId: process.env.REACT_APP_PROJECT_ID,
        service: 'portal-frontend-' + process.env.REACT_APP_NODE_ENV,
        version: process.env.REACT_APP_APP_VERSION
    });
    
} else {
    errorReporter.stackdriverErrorReporter = {report: console.error};
}

errorReporter.setUser = function(){
  if (!userId){
    const oktaAuth = new OktaAuth(oktaAuthConfig)
    oktaAuth.getUser()
    .then( async data => {
      userId = data.sub
      errorReporter.stackdriverErrorReporter.setUser(userId)
    })
    .catch(err => {
      console.log("no logged in user found")
    })
  }
}

// sample reporting: (optional) Skipping the two frames, for report() and for backendReport()
/*try {
    throw new Error('test error');
  } catch (error) {
    errorReporter.stackdriverErrorReporter.report(error, {skipLocalFrames: 2});
  }*/

export default errorReporter;