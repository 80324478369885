import React, { useContext } from "react";
import "../../../styles/progress-3d.css";
import * as Enums from "../../common/enums";
import { AppStateContext } from "../../../AppStateContext";

export default function ProgressDisplay() {
  /******************************************************************
   ProgressArea - displays progress of the current job
   ******************************************************************/
  const appStateContext = useContext(AppStateContext);

  ////////////////////////////////////////////////////////////////////
  function confirmCancelJob() {
    if (!appStateContext.state.animJobId) {
      return;
    }
    appStateContext.dispatch({
      confirmDialogId: Enums.confirmDialog.cancelInProgressJob,
    });
  }

  // cap the progress to less than 100% if job is still running
  const jobProgress =
    appStateContext.state.animationJobProgress >= 100
      ? "99%"
      : appStateContext.state.animationJobProgress + "%";

  const jobPositionInQueue =
    appStateContext.state?.animationJobPositionInQueue ?? 0;

  return (
    <div
      id="anim-fadein"
      className="columns rounded-corners bShadow anim-fadein"
      style={{ height: "240px", backgroundColor: "#2d4e77" }}
    >
      <div className="column">
        <div className="columns rounded-corners has-text-left">
          <div className="column has-text-left">
            <div className="columns">
              <div className="column has-text-left">
                <div
                  className={
                    "is-flex is-align-items-center pl-4" +
                    (appStateContext.state.animationJobProgress === 0 &&
                      " logo-text-blink")
                  }
                >
                  <p className="title is-3 mb-0" style={{ color: "white" }}>
                    Status:
                  </p>
                  <p className="subtitle is-3 mt-0" style={{ color: "white" }}>
                    {appStateContext.state.progressMsg}
                  </p>
                </div>
              </div>
              <div className="has-text-right pr-6">
                <div
                  className="title is-5 has-text-left pl-4"
                  style={{ color: "white" }}
                >
                  {appStateContext.state.animationJobProgress === 0 &&
                    jobPositionInQueue !== 0 &&
                    `Queue Position: ${jobPositionInQueue}`}
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column pt-5 pb-5 has-text-left">
                <div className="wrap">
                  <div className="cube">
                    <div className="front">
                      <div
                        className="progress-3d"
                        style={{ width: jobProgress }}
                      >
                        <span className="dm-brand-font has-text-weight-semibold">
                          {appStateContext.state.animationJobProgress === 0
                            ? ""
                            : `${jobProgress}`}
                        </span>
                        {appStateContext.state.animationJobProgress === 0 &&
                          appStateContext.state?.animationJobPositionInQueue ===
                            0 && <span className="ml-2">{jobProgress}</span>}
                      </div>
                    </div>
                    <div className="back">
                      <div
                        className="progress-3d"
                        style={{ width: jobProgress }}
                      >
                        <span className="dm-brand-font has-text-weight-semibold">
                          {appStateContext.state.animationJobProgress === 0
                            ? ""
                            : `${jobProgress}`}
                        </span>
                        {appStateContext.state.animationJobProgress === 0 &&
                          appStateContext.state?.animationJobPositionInQueue ===
                            0 && <span className="ml-2">{jobProgress}</span>}
                      </div>
                    </div>
                    <div className="top">
                      <div
                        className="progress-3d"
                        style={{ width: jobProgress }}
                      >
                        <span className="dm-brand-font has-text-weight-semibold">
                          {appStateContext.state.animationJobProgress === 0
                            ? ""
                            : `${jobProgress}`}
                        </span>
                        {appStateContext.state.animationJobProgress === 0 &&
                          appStateContext.state?.animationJobPositionInQueue ===
                            0 && <span className="ml-2">{jobProgress}</span>}
                      </div>
                    </div>
                    <div className="bottom">
                      <div
                        className="progress-3d"
                        style={{ width: jobProgress }}
                      >
                        <span className="dm-brand-font has-text-weight-semibold">
                          {appStateContext.state.animationJobProgress === 0
                            ? ""
                            : `${jobProgress}`}
                        </span>
                        {appStateContext.state.animationJobProgress === 0 &&
                          appStateContext.state?.animationJobPositionInQueue ===
                            0 && <span className="ml-2">{jobProgress}</span>}
                      </div>
                    </div>
                    <div className="left"></div>
                    <div className="right"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column has-text-right pr-6">
              {appStateContext.state.progressMsg === "Generating Animation" ||
              appStateContext.state.progressMsg === "Generating 3D Pose" ? (
                <button
                  type="button"
                  className="button cancel-job-btn is-normal"
                  onClick={confirmCancelJob}
                >
                  Cancel
                </button>
              ) : (
                <button
                  type="button"
                  className="button cancel-btn is-normal"
                  style={{
                    pointerEvents: "none",
                    color: "transparent",
                    backgroundColor: "transparent",
                    borderColor: "transparent",
                  }}
                >
                  Cancel
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
