import React, { useContext, useEffect, useState } from 'react'
import { format } from 'date-fns'

import { AppStateContext } from '../../../AppStateContext'
import paginate from 'jw-paginate'
import ReactTooltip from 'react-tooltip'
import '@fortawesome/fontawesome-free/css/all.min.css'
import * as Enums from '../../common/enums'
import LoadingScreen from '../../common/LoadingScreen'
import AnimVersionPanel from '../../common/AnimVersionPanel'
import HelmetPageData from '../../common/HelmetPageData'
// import ParticlesBackground from '../../common/ParticlesBackground'
import DMDayPicker from '../../ui/DMDayPicker'
import useWindowSize from '../../common/useWindowSize'
import imgLibraryHeader from '../../../images/dashboard/animate-3d.jpg'
import imgDefaultCharacter from '../../../images/animate-3d/character-select/default-characters.jpg'
import imgRobloxCharacter from '../../../images/animate-3d/character-select/roblox-r15.png'
import imgDefaultCustom from '../../../images/animate-3d/character-select/model-custom.jpg'
import jobGeneric from '../../../images/animate-3d/new-animation.jpg'
import "../../../styles/library.scss"

// Page title, meta description, and strings
const docTitle = "Library | DEEPMOTION"
const metaDesc = "Preview and download previously created animations including FBX and MP4 formats."
const textMainPageTitle = "Library"

const libraryHeroStyle = {
  backgroundImage: `url(${imgLibraryHeader})`,
  backgroundSize: `cover`,
  backgroundRepeat: `no-repeat`,
  backgroundPosition: `center center`
}

const libraryHeroJobText = "Jobs"
const libraryHeroTimeText = "Total Time"
const libraryHeroSizeText = "Total Size"

const searchFieldEmptyMsg = 'The search result is empty , Please clear the search bar'
const LibraryFieldEmptyMsg = 'Start creating animations and they will show up here...'

const iconTypeAnim = 'fas fa-film'
const iconTypePose = 'far fa-image'

// map jobId to model images
export let jobId2ModelIndexMap = new Map()

/***************************************************************
 * Library page which lists each users previously created anims
 ***************************************************************/
export default function Library(props) {
  const [jobsData, setJobsData] = useState([])
  const [totalsSize, setTotalsSize] = useState(0)
  const [totalsTime, setTotalsTime] = useState(0)
  const [showDayPick, setShowDayPick] = useState(false)
  const appStateContext = useContext(AppStateContext)
  let windowSize = useWindowSize()

  // handle component mount
  useEffect(() => {
    window.scrollTo(0, 0)
    if (!appStateContext.state.libraryInitialized || !appStateContext.state.jobsData) {
      props.initializeA3DService()
    }
    else {
      if (props.LOADING.show) {
        props.sortLibraryByColumn()
        mapCustomModelImages()
        props.setLOADING({ ...props.LOADING, ...{ show: false } })
      }
    }
    setJobsData(appStateContext.state.jobsData)

    let size = 0
    let time = 0
    appStateContext.state.jobsData && appStateContext.state.jobsData.forEach((row) => {
      size += row.sizeRaw
      time += row.lengthRaw
    })

    setTotalsSize(size)
    setTotalsTime(time)

  }, [appStateContext.state.libraryInitialized, appStateContext.state.jobsData])

  // handle component unmount
  useEffect(() => () => {
    jobId2ModelIndexMap.forEach((val) => {
      URL.revokeObjectURL(val)
    })
    jobId2ModelIndexMap.clear()
  }, []);

  // generates the custom model images and maps them
  function mapCustomModelImages() {
    if (jobsData) {
      jobsData.forEach(job => {
        let modelThumb = ""
        if (job.customModel === "standard" || job.customModel === "multiple") {
          modelThumb = imgDefaultCharacter
        }
        else if (job.customModel === Enums.robloxModelId) {
          modelThumb = imgRobloxCharacter
        }
        else {
          const customModelData = props.getModelDataById(job.customModel)
          modelThumb = customModelData && customModelData.thumbImg instanceof Blob ? URL.createObjectURL(customModelData.thumbImg) : ""

        }
        jobId2ModelIndexMap.set(job.rid, modelThumb)
      })
    }
  }

  // Updates the current page of the library
  function setLibraryPage(newPageId) {
    if (newPageId < 1 || newPageId > appStateContext.state.numPages) {
      return
    }
    appStateContext.dispatch({ currPage: newPageId })
  }

  // updates number of rows per page to display
  const setDisplayRowsPerPage = (length, value) => {
    // target value from onChange() function will be a string so 
    // make sure to convert to integer before assigning state
    const newNumPages = length ? Math.ceil(length / parseInt(value)) : 1
    appStateContext.dispatch({
      // make sure current page is not beyond new page range...
      currPage: appStateContext.state.currPage > newNumPages ? newNumPages : appStateContext.state.currPage,
      numPages: newNumPages,
      rowsPerPage: parseInt(value)
    })
  }

  // Update the direction/field of the column sorting
  function updateColumnSort(dir, field) {
    // we only update state if it has changed since updating state is an async
    // call we want to avoid when un-neccessary for performance
    if (dir !== appStateContext.state.currSortDirection && field !== appStateContext.state.currSortField) {
      appStateContext.dispatch({ currSortDirection: dir, currSortField: field })
    }
    else if (dir !== appStateContext.state.currSortDirection) {
      appStateContext.dispatch({ currSortDirection: dir })
    }
    else if (field !== appStateContext.state.currSortField) {
      appStateContext.dispatch({ currSortField: field })
    }
    else {/* nothing to update */ }
  }

  /********************************************************************* 
   * Updates library based on number of rows per page to display
   *********************************************************************/
  function buildRowsPerPageDropDown() {
    const rowsPerPage = [5, 10, 20, 50, 100] // 5 different rows per page values
    let options = []                     // holds the select's options 
    // loop through all entries in the rows array and dynamically 
    // build the select's options...
    rowsPerPage.forEach(numRows => {
      if (numRows === appStateContext.state.rowsPerPage) {
        options.push(
          <option value={(appStateContext.state.rowsPerPage).toString()} key={numRows}>{appStateContext.state.rowsPerPage}</option>
        )
      }
      else {
        options.push(
          <option value={numRows.toString()} key={numRows}>{numRows}</option>
        )
      }
    })

    // return the newly created select UI component for rendering
    return (
      <span>
        <div className="select is-small" >
          <select value={(appStateContext.state.rowsPerPage).toString()} onChange={(e) => setDisplayRowsPerPage(jobsData.length, e.target.value)}>
            {options}
          </select>
        </div>
      </span>
    )
  }

  /********************************************************************* 
   * Builds the preview button for each job row
   *********************************************************************/
  function buildPreviewButton(rid) {
    return (
      <button className="button glow-on-hover btn-shadow" onClick={() => props.openAnimationPreviewer(rid)}>
        <span className="icon-text is-flex-wrap-nowrap">
          <span className="icon is-medium"><i className="far fa-play-circle fa-lg dm-brand-font"></i></span>
          {
            windowSize.width >= Enums.fullHDWidth
            &&
            <span className="title is-6 dm-brand-font"> Preview </span>
          }
        </span>
      </button>
    )
  }


  /********************************************************************* 
   * Builds the table of jobs including pagination etc 
   *********************************************************************/
  function buildJobsTable() {
    if (!jobsData) {
      return <h1 className="subtitle is-4 dm-brand-font">Error loading jobs data!</h1>
    }

    let jobsForCurrentPage = []
    let index = 0
    let titleRowSortIcons = []
    let sortHoverClasses = []

    let titleSizeClass = "is-5"
    if (windowSize.width < Enums.fullHDWidth) {
      titleSizeClass = "is-6"
    }

    // By default all columns are sortable in the up direction except
    // for the currently selected column which will be up or down
    // based on state
    let clickDirs = ['up', 'up', 'up', 'up', 'up']
    let fieldNames = ['name', 'length', 'size', 'credits', 'date']
    //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    // ==> Loop through jobs data and find jobs info to display based
    // on the currently selected page and number of rows per page
    //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    for (let i = 0; i < appStateContext.state.rowsPerPage; i++) {
      index = i + ((appStateContext.state.currPage - 1) * appStateContext.state.rowsPerPage)
      if (index > jobsData.length - 1) {
        // break loop if we reach the last job in the account
        break
      }
      let actionsObj = {}
      actionsObj.jobId = jobsData[index].rid
      actionsObj.jobType = jobsData[index].jobType
      actionsObj.name = jobsData[index].name
      actionsObj.length = typeof (jobsData[index].length) === 'number' ? jobsData[index].length : 0
      actionsObj.size = jobsData[index].size
      actionsObj.date = jobsData[index].date
      actionsObj.dateRaw = jobsData[index].dateRaw
      actionsObj.jobThumb = jobsData[index].thumb ? jobsData[index].thumb : jobGeneric
      actionsObj.characters = ""
      actionsObj.modelThumb = jobId2ModelIndexMap.get(actionsObj.jobId)
      actionsObj.freeCredits = jobsData[index].correctionFreeCredits + jobsData[index].labelingFreeCredits

      if (jobsData[index].customModel === "standard") {
        actionsObj.characters = "default"
      }
      else if (jobsData[index].customModel === Enums.robloxModelId) {
        actionsObj.characters = "Roblox R15"
      }
      else {
        if (jobsData[index].customModel === "multiple") {
          jobsData[index].customMultiPersonModel.forEach((ModelItem, ModelIndex) => {
            const modelData = props.getModelDataById(ModelItem.modelId)
            let name = ''
            if (modelData) {
              name = modelData.name
            } else {
              name = 'Custom Model Deleted'
            }
            actionsObj.characters += ModelIndex !== 0 ? ', ' + name : name
          })
          // actionsObj.modelThumb = imgDefaultCharacter
        } else {
          const modelData = props.getModelDataById(jobsData[index].customModel)
          // if model data undefined then user may have deleted the custom character
          // this job was originally created with from their account!
          if (modelData) {
            actionsObj.characters = !modelData.name ? "" : modelData.name
            // attempt to grab model thumbnail if it was grabbed unsuccessfully the first time
            if (actionsObj.modelThumb === "") {
              let modelThumb = modelData && modelData.thumbImg instanceof Blob ? URL.createObjectURL(modelData.thumbImg) : ""
              if (modelThumb !== "") {
                actionsObj.modelThumb = modelThumb
                jobId2ModelIndexMap.set(actionsObj.jobId, modelThumb)
              }
            }
          }
          // revert to generic custom + include message that custom character is deleted
          else {
            actionsObj.characters = "Custom Model Deleted"
            actionsObj.modelThumb = imgDefaultCustom
          }
        }
      }

      let jobName = actionsObj.name
      if (jobName.includes('.')) {
        jobName = jobName.split('.').slice(0, -1).join('.')
      }
      //////////////////////////////////////////////////////////////////
      // 1. Populate jobs data array for display in table when we render
      //////////////////////////////////////////////////////////////////
      jobsForCurrentPage.push(
        <tr key={"job-id-" + index} >
          <td className="has-text-centered br-0 has-background-link-light">
            <div>
              <span className="icon is-medium">
                <i className={(actionsObj.jobType ? iconTypePose : iconTypeAnim) + " fa-3x dm-brand-font"}
                  data-for={actionsObj.dateRaw.toString()}
                  data-border={true}
                  data-border-color="black"
                  data-tip
                  data-text-color="#2d4e77"
                  data-background-color="white"
                >
                  <ReactTooltip className="tip-max-w" id={actionsObj.dateRaw.toString()} place="right" effect="solid">
                    <div className="subtitle has-text-left dm-brand-font">
                      {actionsObj.jobType ? "3D Pose" : "3D Animation"}
                    </div>
                  </ReactTooltip>
                </i>
              </span>
            </div>
          </td>
          <td className="has-text-left library-name-col br-0">
            <figure className="image is-48x48" style={{ display: 'inline-block', verticalAlign: 'middle' }}>
              <img src={actionsObj.jobThumb}></img>
            </figure>
            <div className="m-0 pl-1" style={{ display: 'inline-block' }}>
              <h5 className="title m-0 px-2 pb-1 is-5"> {jobName} </h5>
            </div>
          </td>
          <td className="has-text-centered bx-0">
            <div>
              <figure className="image is-48x48" style={{ margin: 'auto' }}>
                <img
                  src={actionsObj.modelThumb}
                  data-for={actionsObj.dateRaw.toString() + actionsObj.characters + "character"}
                  data-border={true}
                  data-border-color="black"
                  data-tip
                  data-text-color="#2d4e77"
                  data-background-color="white"
                ></img>
                <ReactTooltip className="tip-max-w" id={actionsObj.dateRaw.toString() + actionsObj.characters + "character"} place="right" effect="solid">
                  <div className="subtitle has-text-left dm-brand-font">
                    {actionsObj.characters}
                  </div>
                </ReactTooltip>
              </figure>
            </div>
          </td>
          <td className="has-text-left bx-0"><h5 className={`subtitle ${titleSizeClass}`}>{actionsObj.length.toFixed(0) + " sec"}</h5></td>
          <td className="has-text-left bx-0"><h5 className={`subtitle ${titleSizeClass}`}>{actionsObj.size}</h5></td>
          <td className="has-text-left bx-0"><h5 className={`subtitle ${titleSizeClass}`}>{actionsObj.freeCredits}</h5></td>
          <td className="has-text-left bx-0"><h5 className={`subtitle ${titleSizeClass}`} style={{ wordBreak: 'normal' }}>{actionsObj.date}</h5></td>
          <td className="has-text-centered bx-0">
            <h5 className={`subtitle ${titleSizeClass}`}>
              {buildPreviewButton(actionsObj.jobId)}
            </h5>
          </td>
          <td className="has-text-centered bl-0">
            <h5 className={`subtitle ${titleSizeClass}`}>
              {props.buildActionsDropDown(jobsData[index].rid, jobsData[index])}
            </h5>
          </td>
        </tr>
      )
    }

    //////////////////////////////////////////////////////////////////
    // 2. Calculate page data range for display at bottom of library
    //////////////////////////////////////////////////////////////////
    let lowRange = ((appStateContext.state.currPage - 1) * appStateContext.state.rowsPerPage) + 1
    let highRange = ((appStateContext.state.currPage - 1) * appStateContext.state.rowsPerPage) + appStateContext.state.rowsPerPage
    if (highRange > jobsData.length) {
      highRange = jobsData.length
    }

    //////////////////////////////////////////////////////////////////
    // 3. Handle Column Sorting - When a column is NOT sorted the default 
    // sort direction is UP which is displayed when the user hovers over 
    // that column. Otherwise for the column that is currently sorted the 
    // direction and icon will be based on value of state vars 
    // appStateContext.state.currSortDirection & currSortField
    //////////////////////////////////////////////////////////////////
    if (appStateContext.state.currSortDirection === "down") {
      for (let i = 0; i < fieldNames.length; i++) {
        if (fieldNames[i] === appStateContext.state.currSortField) {
          sortHoverClasses.push("has-background-link-light")
          titleRowSortIcons.push(<span className="icon library-table-header-icon m-0"><i onClick={() => updateColumnSort("up", fieldNames[i])} className="fas fa-arrow-circle-down fa-lg dm-brand-font"></i></span>)
        }
        else {
          // adding the sort-hover class hides the icon until it's hovered over 
          sortHoverClasses.push("sort-hover has-text-left has-background-link-light")
          titleRowSortIcons.push(<span className="icon library-table-header-icon m-0"><i onClick={() => updateColumnSort("up", fieldNames[i])} className="fas fa-arrow-up fa-lg dm-brand-font"></i></span>)
        }
      }
    }
    else {
      for (let i = 0; i < fieldNames.length; i++) {
        if (fieldNames[i] === appStateContext.state.currSortField) {
          titleRowSortIcons.push(<span className="icon library-table-header-icon m-0"><i onClick={() => updateColumnSort("down", fieldNames[i])} className="fas fa-arrow-circle-up fa-lg dm-brand-font"></i></span>)
          sortHoverClasses.push("has-background-link-light")
          clickDirs[i] = 'down'
        }
        else {
          titleRowSortIcons.push(<span className="icon library-table-header-icon m-0"><i onClick={() => updateColumnSort("up", fieldNames[i])} className="fas fa-arrow-up fa-lg dm-brand-font"></i></span>)
          sortHoverClasses.push("sort-hover has-text-left has-background-link-light")
        }
      }
    }

    // finally we return the JSX for the entire Library page
    return (
      <div className="section py-0 px-2 mb-6">

        <LibraryHeroSection />

        {LibraryTypeSelection()}

        {/* Show empty state if no existing jobs found */}
        <React.Fragment>

          {/* The actual list of animation jobs */}
          <table className="table br-4 mt-6 mb-6 bShadow is-bordered is-fullwidth is-hoverable is-info is-light" style={{ borderCollapse: 'inherit' }}>
            <thead>
              <tr>
                <th className="has-background-link-light has-text-centered p-0 br-0 library-table-header"> <h5 className={`title ${titleSizeClass}`}> Type </h5> </th>
                <th className={sortHoverClasses[0] + " py-4 px-1 br-0 library-table-header"} onClick={() => updateColumnSort(clickDirs[0], fieldNames[0])}>
                  <h5 className={`title ${titleSizeClass} m-0 mr-2 is-flex is-align-items-center`}>
                    <p>Name</p>
                    {titleRowSortIcons[0]}
                  </h5>
                </th>
                <th className="has-background-link-light has-text-centered py-4 px-1 bx-0 library-table-header"> <h5 className={`title ${titleSizeClass}`}> Model </h5> </th>
                <th className={sortHoverClasses[1] + " py-3 px-1 bx-0 library-table-header"} onClick={() => updateColumnSort(clickDirs[1], fieldNames[1])}><h5 className={`title ${titleSizeClass}`}><div className='is-flex is-align-items-center'><p>Length</p> {titleRowSortIcons[1]}</div></h5></th>
                <th className={sortHoverClasses[2] + " py-3 px-1 bx-0 library-table-header"} onClick={() => updateColumnSort(clickDirs[2], fieldNames[2])}><h5 className={`title ${titleSizeClass}`}><div className='is-flex is-align-items-center'><p>Size</p> {titleRowSortIcons[2]}</div></h5></th>
                <th className={sortHoverClasses[3] + " py-3 px-1 bx-0 library-table-header"} onClick={() => updateColumnSort(clickDirs[3], fieldNames[3])}><h5 className={`title ${titleSizeClass}`}> <div className='is-flex is-align-items-center'><p>Free Credits</p> {titleRowSortIcons[3]}</div></h5></th>
                <th className={sortHoverClasses[4] + " py-3 px-1 bx-0 library-table-header"} onClick={() => updateColumnSort(clickDirs[4], fieldNames[4])}><h5 className={`title ${titleSizeClass}`}> Date {titleRowSortIcons[4]}</h5></th>
                <th className="has-background-link-light bx-0 py-4 px-1 has-text-centered library-table-header"><h5 className={`title ${titleSizeClass}`} style={{ wordBreak: 'normal' }}>3D Preview</h5></th>
                <th className="has-background-link-light bl-0 px-1 library-table-header"><h5 className={`title ${titleSizeClass}`} style={{ wordBreak: 'normal' }}>Actions</h5></th>
              </tr>
            </thead>
            <tbody>
              {/* Display jobs for the current page */}
              {jobsForCurrentPage}
            </tbody>
          </table>
          {
            jobsData.length === 0
            &&
            <div className="section">
              <div className="columns">
                <div className="column has-text-centered notification is-warning is-light">
                  <h2 className="title is-2">Your Library is Empty</h2>
                  <h2 className="subtitle is-3">
                    {
                      appStateContext.state.currSortKeywordsName ? searchFieldEmptyMsg : LibraryFieldEmptyMsg
                    }
                  </h2>
                </div>
              </div>
            </div>
          }
          <div className="columns is-mobile">

            <div className="column is-half has-text-left">
              {/* Builds pagination controls including prev/next buttons */}
              {buildPaginationControls()}
            </div>

            {/* Results per page drop-down: */}
            <div className="column has-text-right">
              <h5 className="subtitle is-5">Show rows: {buildRowsPerPageDropDown()}</h5>
            </div>

            {/* Display current range of jobs: */}
            <div className="column has-text-left">
              <h5 className="subtitle is-5">Showing {lowRange} to {highRange} / {jobsData.length}</h5>
            </div>
          </div>
        </React.Fragment>
      </div>
    )
  }

  const sortJobsData = appStateContext.state.jobsData
  const keywordsName = appStateContext.state.currSortKeywordsName
  const currSortKeywordsDay = appStateContext.state.currSortKeywordsDay
  // state
  const hasDay = currSortKeywordsDay.from && currSortKeywordsDay.to
  useEffect(() => {
    if (hasDay) {
      handleSearchField()
    } else {
      if (keywordsName) {
        handleSearchField()
      } else {
        clearSearchField()
      }
    }
  }, [appStateContext.state.currSortKeywordsDay, appStateContext.state.currSortKeywordsName])

  function handleSearchField() {
    if (keywordsName || hasDay) {
      const res = fuzzySearch(sortJobsData, hasDay)
      setJobsData(res)

      let size = 0
      let time = 0
      res.forEach((row) => {
        size += row.sizeRaw
        time += row.lengthRaw
      })

      setTotalsSize(size)
      setTotalsTime(time)
      setDisplayRowsPerPage(res.length, appStateContext.state.rowsPerPage)
    } else {
      clearSearchField()
    }
  }

  function fuzzySearch(list, hasDay) {
    let data = []
    if (list.length === 0) return data

    if (keywordsName) {
      // Check whether the file name is included in the query condition
      const escapeKeywordsName = keywordsName.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
      let str = `\\S*${escapeKeywordsName}\\S*`
      let reg = new RegExp(str, 'i')

      // Check whether time is included in the query condition
      if (hasDay) {
        let fromDate = currSortKeywordsDay.from.getTime()
        let toDate = currSortKeywordsDay.to.getTime()
        list.map(item => {
          if (fromDate < item.dateRaw && item.dateRaw < toDate && reg.test(item.name)) {
            data.push(item)
          }
        })
      } else {
        list.map(item => {
          if (reg.test(item.name)) {
            data.push(item)
          }
        })
      }

    } else {
      // The query condition contains only time
      let fromDate = currSortKeywordsDay.from.getTime()
      let toDate = currSortKeywordsDay.to.getTime()

      list.map(item => {

        if (fromDate < item.dateRaw && item.dateRaw < toDate) {
          data.push(item)
        }
      })
    }
    return data
  }

  function clearSearchField(key = 'all') {
    if (key === 'day') {
      // Clear day
      appStateContext.dispatch({
        currSortKeywordsDay: {
          from: '',
          to: ''
        }
      })
    } else if (key === 'name') {
      // Clear name
      appStateContext.dispatch({ currSortKeywordsName: '' })
    } else if (!keywordsName && !hasDay) {
      // Clear all conditions

      setJobsData(sortJobsData)

      let size = 0
      let time = 0
      sortJobsData && sortJobsData.forEach((row) => {
        size += row.sizeRaw
        time += row.lengthRaw
      })

      setTotalsSize(size)
      setTotalsTime(time)

      setDisplayRowsPerPage(sortJobsData?.length, appStateContext.state.rowsPerPage)
    }
  }
  ////////////////////////////////////////////////////////////////////
  // Builds the Search for Library page
  ////////////////////////////////////////////////////////////////////
  function LibrarySearchFieldName() {
    return (
      <>
        <div className='m-0 LibrarySearch br-4' onClick={(event) => { event.stopPropagation() }}>
          <div className='is-flex search'>
            <svg
              className='searchBtn'
              viewBox="0 0 24 24"
              onClick={() => handleSearchField()}
              xmlns="http://www.w3.org/2000/svg">
              <path d="M20.49,19l-5.73-5.73C15.53,12.2,16,10.91,16,9.5C16,5.91,13.09,3,9.5,3S3,5.91,3,9.5C3,13.09,5.91,16,9.5,16 c1.41,0,2.7-0.47,3.77-1.24L19,20.49L20.49,19z M5,9.5C5,7.01,7.01,5,9.5,5S14,7.01,14,9.5S11.99,14,9.5,14S5,11.99,5,9.5z"></path>
              <path d="M0,0h24v24H0V0z" fill="none"></path>
            </svg>
            <input
              className='input pr-6'
              placeholder='Search Library'
              onChange={event => appStateContext.dispatch({ currSortKeywordsName: event.target.value })}
              value={appStateContext.state.currSortKeywordsName}
              onKeyUp={(event) => {
                if (event.keyCode === 13) {
                  handleSearchField()
                }
              }}
              onBlur={() => { handleSearchField() }}
            />
            <svg
              className={'clearBtn ' + (appStateContext.state.currSortKeywordsName ? 'show' : '')}
              viewBox="0 0 24 24"
              onClick={() => clearSearchField('name')}
              xmlns="http://www.w3.org/2000/svg">
              <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
              <path d="M0 0h24v24H0z" fill="none"></path>
            </svg>
          </div>
        </div>
      </>
    )
  }

  ////////////////////////////////////////////////////////////////////
  // Builds the day picker for Library page
  ////////////////////////////////////////////////////////////////////
  function LibrarySearchDayPicker() {
    let value = ''
    if (currSortKeywordsDay?.from) {
      if (!currSortKeywordsDay.to) {
        value = `${format(currSortKeywordsDay.from, 'PPP')} - ?`
      } else if (currSortKeywordsDay.to) {
        value = `${format(currSortKeywordsDay.from, 'PPP')} – ${format(currSortKeywordsDay.to, 'PPP')}`
      }
    }
    return (
      <>
        <div className='m-0 LibrarySearch day br-4 mx-4'>
          <div className='is-flex search'>
            <input
              className='input pr-6'
              placeholder='Please choose a custom date range'
              readOnly
              value={value}
              onClick={() => { setShowDayPick(true) }}
            />
            <svg
              className={'clearBtn ' + (hasDay ? 'show' : '')}
              viewBox="0 0 24 24"
              onClick={() => clearSearchField('day')}
              xmlns="http://www.w3.org/2000/svg">
              <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
              <path d="M0 0h24v24H0z" fill="none"></path>
            </svg>
          </div>
          {
            showDayPick && <DMDayPicker footer={value} setShowDayPick={setShowDayPick} />
          }
        </div>
      </>
    )
  }

  ////////////////////////////////////////////////////////////////////
  // Builds the selection for Library page
  ////////////////////////////////////////////////////////////////////
  function LibraryTypeSelection() {
    return (
      <div className='is-flex LibraryTypeSelection bShadow p-5 br-4'>
        {LibrarySearchFieldName()}
        {LibrarySearchDayPicker()}
        <button
          className="button glow-on-hover btn-shadow dm-brand-font"
          onClick={() => {
            appStateContext.dispatch({
              currSortKeywordsDay: {
                from: '',
                to: ''
              }
            })
            appStateContext.dispatch({ currSortKeywordsName: '' })
          }}
        >
          CLEAR ALL
        </button>
      </div>
    )
  }

  ////////////////////////////////////////////////////////////////////
  // Builds the Top Hero section for Library page
  ////////////////////////////////////////////////////////////////////
  function LibraryHeroSection() {
    if (!appStateContext.state.accountTotals) {
      return <div>Loading...</div>
    }
    let totalLibraryAnimTime = "00:00:00"
    if (totalsTime) {
      totalLibraryAnimTime = Enums.secondsToTime(Math.ceil(totalsTime))
    }
    if (totalLibraryAnimTime.substring(0, 3) === "00:") {
      // only show minutes and seconds if less than 1 hour of time
      totalLibraryAnimTime = totalLibraryAnimTime.substring(3, totalLibraryAnimTime.length)
    }

    return (
      <div className="m-0 p-0 fullwidth">
        <div className="columns m-0 mb-6 p-0 bShadow br-4-top-left fullwidth">

          <div className="column is-one-quarter br-4-left m-0 p-0 dm-brand-border-md has-text-centered" style={libraryHeroStyle}>
          </div>

          <div className="column m-0 p-2 br-4-right dm-brand dm-brand-border-md has-text-centered">
            <div className="columns is-mobile m-0 p-0">
              <div className="column bottom-border m-0 p-1 has-text-centered">
                <h2 className="title is-3 has-text-white"> {textMainPageTitle} </h2>
              </div>
            </div>
            <div className="m-0 p-1">
              <div className="columns m-0 is-mobile">
                <div className="column m-0 p-2">
                  <div className="mt-5">
                    <div className="columns">
                      <div className="column disp-grid box my-0 mx-2" style={{ backgroundColor: 'transparent' }}>
                        {/* <span className="icon is-medium"><i className="far fa-play-circle fa-2x"></i></span> */}
                        <div className="title dm-brand-2-font is-3">
                          {jobsData.length}
                        </div>
                        <div className="subtitle has-text-white is-5">{libraryHeroJobText}</div>
                      </div>
                      <div className="column disp-grid box my-0 mx-2" style={{ backgroundColor: 'transparent' }}>
                        {/* <span className="icon is-medium"><i className="far fa-clock fa-2x"></i></span> */}
                        <div className="title dm-brand-2-font is-3">
                          {totalLibraryAnimTime}
                        </div>
                        <div className="subtitle has-text-white is-5">
                          {libraryHeroTimeText}
                        </div>
                      </div>
                      <div className="column disp-grid box my-0 mx-2" style={{ backgroundColor: 'transparent' }}>
                        {/* <span className="icon is-medium"><i className="far fa-hdd fa-2x"></i></span> */}
                        <div className="title dm-brand-2-font is-3">
                          {Enums.formatSizeUnits(Math.ceil(totalsSize))}
                        </div>
                        <div className="subtitle has-text-white is-5">
                          {libraryHeroSizeText}
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    )
  }

  /********************************************************************* 
   * Builds the pagination [Next] & [Previous] buttons 
   *********************************************************************/
  function buildPaginationPrevNextButtons() {
    // if only 1 page disable the previous and next buttons...
    if (appStateContext.state.numPages <= 1) {
      // disable both buttons
      return (
        <div>
          <a disabled className="pagination-previous">Previous</a>
          <a disabled className="pagination-next">Next page</a>
        </div>
      )
    }
    // else there are at least 2 total pages in the library...
    else {
      if (appStateContext.state.currPage === 1) {
        // disable [Previous] button when on first page
        return (
          <div>
            <a disabled className="pagination-previous">Previous</a>
            <a className="pagination-next" onClick={() => setLibraryPage(appStateContext.state.currPage + 1)} tabIndex="10" >Next page</a>
          </div>
        )
      }
      else if (appStateContext.state.currPage === appStateContext.state.numPages) {
        // disable [Next] button when on last page
        return (
          <div>
            <a className="pagination-previous" onClick={() => setLibraryPage(appStateContext.state.currPage - 1)} tabIndex="10" >Previous</a>
            <a disabled className="pagination-next">Next page</a>
          </div>
        )
      }
      else {
        // else both buttons are enabled
        return (
          <div>
            <a className="pagination-previous" onClick={() => setLibraryPage(appStateContext.state.currPage - 1)} tabIndex="10" >Previous</a>
            <a className="pagination-next" onClick={() => setLibraryPage(appStateContext.state.currPage + 1)} tabIndex="11" >Next page</a>
          </div>
        )
      }
    }
  }

  /********************************************************************* 
   * Builds the pagination UI controls 
   *********************************************************************/
  function buildPaginationControls() {

    if (!jobsData.length) {
      return
    }
    let pages = []
    let pageId = ""
    let pageGoTo = ""

    // we start by building a list of page buttons based on the 
    // number of total pages and the currently active page...
    for (let i = 1; i <= appStateContext.state.numPages; i++) {
      pageId = "Page "
      pageGoTo = "Go To Page "
      pageId.concat((i).toString())
      pageGoTo.concat((i).toString())
      if (i === appStateContext.state.currPage) {
        // add is-current css class for currently selected page
        pages.push(
          <li key={i}>
            <a className="pagination-link is-current" onClick={() => setLibraryPage(i)} tabIndex={i + 1} aria-label={pageId} aria-current="page" key={i}>{i}</a>
          </li>
        )
      }
      else {
        // otherwise add normal page button
        pages.push(
          <li key={i}>
            <a className="pagination-link" onClick={() => setLibraryPage(i)} tabIndex={i + 1} aria-label={pageGoTo} key={i}>{i}</a>
          </li>
        )
      }
    }

    // show all pages if total <= MAX_PAGE_BUTTONS, otherwise show with ellipsis gaps...
    if (appStateContext.state.numPages <= Enums.MAX_PAGE_BUTTONS) {
      return (
        <nav className="pagination" role="navigation" aria-label="pagination">
          {buildPaginationPrevNextButtons()}
          <ul className="pagination-list">
            {pages}
          </ul>
        </nav>
      )
    }
    else {
      // else, more than MAX_PAGE_BUTTONS pages worth of jobs based on total jobs
      // and current rowsPerPage
      let paginationResult = paginate(
        jobsData.length,
        appStateContext.state.currPage,
        appStateContext.state.rowsPerPage,
        Enums.MAX_PAGE_BUTTONS
      )

      let displayList = []
      for (let i = paginationResult.pages[0]; i <= paginationResult.pages[paginationResult.pages.length - 1]; i++) {
        if (i === appStateContext.state.currPage) {
          // add is-current css class for currently selected page
          displayList.push(
            <li key={i}>
              <a className="pagination-link is-current" onClick={() => setLibraryPage(i)} aria-label={pageId} aria-current="page" key={i}>{i}</a>
            </li>
          )
        }
        else {
          // otherwise add normal page button
          displayList.push(
            <li key={i}>
              <a className="pagination-link" onClick={() => setLibraryPage(i)} aria-label={pageGoTo} key={i}>{i}</a>
            </li>
          )
        }
      }

      return (
        <nav className="pagination" role="navigation" aria-label="pagination">
          {buildPaginationPrevNextButtons()}
          <ul className="pagination-list">
            {displayList}
          </ul>
        </nav>
      )
    }
  }

  function buildLibraryPage() {
    return (
      <React.Fragment>
        <div id="anim-fadein" className="column mt-0 has-text-left">
          <HelmetPageData docTitle={docTitle} metaDesc={metaDesc} />
          <AnimVersionPanel />
          {
            props.LOADING.show
              ?
              <LoadingScreen />
              :
              <div className="pt-4 ml-2 mr-2 mb-6">
                {/***** Jobs Table *****/}
                {buildJobsTable()}
                {/**********************/}
              </div>
          }
        </div>
      </React.Fragment>
    )
  }

  // re-render library on certain state changes
  useEffect(() => {
    // add keys to map if the list of jobsData changes in size
    if (jobsData && jobsData.length !== jobId2ModelIndexMap.size) {
      mapCustomModelImages()
    }
  }, [
    jobsData,
    appStateContext.state.currPage,
    appStateContext.state.rowsPerPage,
    appStateContext.state.numPages,
    appStateContext.state.accountTotals.charactersList,
    appStateContext.state.pageState_CharacterManage
  ])

  // re-render library on sorting
  useEffect(() => {
    if (appStateContext.state.libraryInitialized) {
      props.sortLibraryByColumn()
      handleSearchField()
    }
  }, [
    appStateContext.state.currSortDirection,
    appStateContext.state.currSortField
  ])

  return (
    <React.Fragment>
      {buildLibraryPage()}
    </React.Fragment>
  )
}